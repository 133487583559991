const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');
const lineClampPlugin = require('@tailwindcss/line-clamp');
const tailwindcssVariables = require('@mertasan/tailwindcss-variables')({
  variablePrefix: 'tw',
});

const hiberColors = {
  primary: '#002D3C',
  neutral: {
    0: '#D3DEE3',
    1: '#EFF3F8',
    2: '#DDE5EE',
    3: '#B2BAC4',
    4: '#9AA8B9',
    5: '#434D54',
    6: '#1B242A',
  },
  alert: {
    red: '#E02F2F',
    orange: '#F1BE4B',
    green: '#0DD477',
  },
  turquiose: '#1EF5F0',
  roaringred: '#F54C4C',
  mauve: '#DCA5FF',
  lightturquiose: '#F2FAFF',
  darkturquiose: '#E6F5FF',
};

module.exports = {
  content: ['./src/**/*.{vue,js,ts}'],
  presets: [],
  theme: {
    ...defaultTheme,
    boxShadow: {
      ...defaultTheme.boxShadow,
      'input-error': 'inset 0 1px 10px 0 rgba(224, 47, 47, 0.2)',
      'input': 'inset 0 1px 10px 0 rgba(30, 245, 240, 0.43)',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',

      black: colors.black,
      white: colors.white,
      gray: colors.gray,
      red: colors.red,
      yellow: colors.amber,
      green: colors.emerald,
      blue: colors.blue,
      indigo: colors.indigo,
      purple: colors.violet,
      pink: colors.pink,
      ...hiberColors,
    },
    spacing: {
      ...defaultTheme.spacing,
      px: '1px',
      sm: '10px',
      0: '0px',
    },
    fontFamily: {
      lora: ['"Lora"', 'serif'],
      europa: ['"Europa"', 'sans'],
      sans: [
        '"Europa"',
        'ui-sans-serif',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: ['"Lora"', 'ui-serif', 'Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      mono: [
        'ui-monospace',
        'SFMono-Regular',
        'Menlo',
        'Monaco',
        'Consolas',
        '"Liberation Mono"',
        '"Courier New"',
        'monospace',
      ],
    },
    fontSize: {
      'small': ['14px', { lineHeight: '18px' }],
      'xs': ['0.75rem', { lineHeight: '1rem' }],
      'sm': ['0.875rem', { lineHeight: '1.25rem' }],
      'base': ['1rem', { lineHeight: '1.5rem' }],
      'lg': ['1.125rem', { lineHeight: '1.75rem' }],
      'xl': ['1.25rem', { lineHeight: '1.75rem' }],
      '2xl': ['1.5rem', { lineHeight: '2rem' }],
      '3xl': ['1.875rem', { lineHeight: '2.25rem' }],
      '4xl': ['2.25rem', { lineHeight: '2.5rem' }],
      '5xl': ['3rem', { lineHeight: '1' }],
      '6xl': ['3.75rem', { lineHeight: '1' }],
      '7xl': ['4.5rem', { lineHeight: '1' }],
      '8xl': ['6rem', { lineHeight: '1' }],
      '9xl': ['8rem', { lineHeight: '1' }],
      'header': '28px',
    },
    fontWeight: {
      thin: '100',
      extralight: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    letterSpacing: {
      tighter: '-0.3px',
      tight: '-0.025em',
      normal: '0em',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
    zIndex: {
      '-1': '-1',
      'auto': 'auto',
      '0': '0',
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      ...defaultTheme.zIndex,
    },
    gridTemplateColumns: {
      'auto-fit': 'repeat(auto-fit, minmax(0, 1fr))',
      'auto-fill': 'repeat(auto-fill, minmax(0, 1fr))',
      'kpi-box': 'repeat(auto-fit, minmax(250px, 1fr))',
      ...defaultTheme.gridTemplateColumns,
    },
    gridTemplateRows: {
      'auto-fit': 'repeat(auto-fit, minmax(0, 1fr))',
      'auto-fill': 'repeat(auto-fill, minmax(0, 1fr))',
      ...defaultTheme.gridTemplateRows,
    },
    variables: {
      DEFAULT: {
        colors: {
          ...hiberColors,
        },
      },
    },
  },
  corePlugins: {
    container: false,
    preflight: false,
  },
  plugins: [
    lineClampPlugin,
    tailwindcssVariables,
  ],
  prefix: 'tw-',
};
