// Base
import { Avatar } from 'hiber-grpc/src/customer_compiled/base';

// Organization
import {
  CreateOrganizationRequest,
  GetOrganizationAvatar_Request,
  GetOrganizationRequest,
  ListChildOrganizationsRequest,
  OrganizationServiceDefinition,
  UpdateOrganizationRequest,
  ValidateOrganizationCreationTokenRequest,
} from 'hiber-grpc/src/customer_compiled/organization';
import { getGrpcClient } from '@/services/app';

export interface IUploadFile {
  name: string,
  url?: string,
  file?: string,
  fileBytes?: Uint8Array,
  fileType?: string,
}

export function uploadOrganizationAvatar(payload: IUploadFile) {
  let avatar;

  if (payload.fileBytes && payload.fileType) {
    avatar = Avatar.fromPartial({
      urlOrImage: {
        $case: 'image',
        image: {
          name: payload.name,
          data: { bytes: payload.fileBytes },
          mediaType: payload.fileType,
        },
      },
    });
  }

  if (payload.url) {
    avatar = Avatar.fromPartial({
      urlOrImage: {
        $case: 'url',
        url: payload.url,
      },
    });
  }
  const request = UpdateOrganizationRequest.fromPartial({ avatar });
  const client = getGrpcClient<typeof OrganizationServiceDefinition>(OrganizationServiceDefinition);
  return client.update(request);
}

export function getOrganization() {
  const request = GetOrganizationRequest.fromPartial({});
  const client = getGrpcClient<typeof OrganizationServiceDefinition>(OrganizationServiceDefinition);
  return client.get(request);
}

export function getOrganizationAvatar(organizations: string[]) {
  const request = GetOrganizationAvatar_Request.fromPartial({ organizations });
  const client = getGrpcClient<typeof OrganizationServiceDefinition>(OrganizationServiceDefinition);
  return client.getAvatar(request);
}

export function listChildOrganizations(search: string, size: number, page: number) {
  const request = ListChildOrganizationsRequest.fromPartial({
    pagination: { size, page },
    selection: { search },
  });
  const client = getGrpcClient<typeof OrganizationServiceDefinition>(OrganizationServiceDefinition);
  return client.listChildOrganizations(request);
}

export function validateCreationToken(token: string) {
  const request = ValidateOrganizationCreationTokenRequest.fromPartial({ organizationCreationToken: token });
  const client = getGrpcClient<typeof OrganizationServiceDefinition>(OrganizationServiceDefinition);
  return client.validateCreationToken(request);
}

interface ContactForm {
  id: string;
  display: string;
  isBusiness: boolean;
  vat: string;
  hasAddress: boolean;
  address: {
    line: string;
    zipcode: string;
    city: string;
    state: string;
    country: string;
  },
  billingName: string;
  billingAddressSame: boolean;
  billingAddress: {
    line: string;
    zipcode: string;
    city: string;
    state: string;
    country: string;
  },
  contact: {
    name: string;
    email: string;
    phone: string;
  },
  token: string;
}

export function createOrganizationRequest(createForm: ContactForm, parentOrganization: string) {
  const request = CreateOrganizationRequest.fromPartial({
    parentOrganization: parentOrganization || undefined,
    newOrganization: createForm.id,
    displayName: createForm.display,
    isBusiness: createForm.isBusiness,
    vatNumber: createForm.vat || undefined,
    address: {
      lines: [createForm.address?.line],
      zipCode: createForm.address?.zipcode,
      city: createForm.address?.city,
      state: createForm.address?.state,
      country: createForm.address?.country,
    },
    billingName: createForm.billingName,
    billingAddress: {
      lines: createForm.billingAddressSame ? [createForm.address?.line] : [createForm.billingAddress.line],
      zipCode: createForm.billingAddressSame ? createForm.address?.zipcode : createForm.billingAddress.zipcode,
      city: createForm.billingAddressSame ? createForm.address?.city : createForm.billingAddress.city,
      state: createForm.billingAddressSame ? createForm.address?.state : createForm.billingAddress.state,
      country: createForm.billingAddressSame ? createForm.address?.country : createForm.billingAddress.country,
    },
    contact: {
      name: createForm.contact?.name,
      email: createForm.contact?.email,
      phone: createForm.contact?.phone,
    },
    organizationCreationToken: createForm.token,
  });
  const client = getGrpcClient<typeof OrganizationServiceDefinition>(OrganizationServiceDefinition);
  return client.create(request);
}
