import * as Sentry from '@sentry/vue';
import Firebase from '@/injections/firebase';

import store from '@/store';

async function authNavigationGuard(to, from, next, hooks) {
  const { currentUser } = Firebase.auth();
  // When coming from login, display loading screen
  if (from.name === 'Login' && to.name !== 'Login') {
    store.commit('User/setShowNow', false);
  }

  if (currentUser) {
    // Set Sentry info data
    Sentry.setUser({ email: currentUser.email });

    if (typeof hooks.loggedIn === 'function') {
      await hooks.loggedIn(currentUser);
    }
  } else if (typeof hooks.notLoggedIn === 'function') {
    await hooks.notLoggedIn();
  } else {
    next();
  }
}

export default authNavigationGuard;
