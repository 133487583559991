/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Asset, Asset_Type, asset_TypeFromJSON, asset_TypeToNumber } from "./asset";
import { Assignment } from "./assignment";
import {
  Avatar,
  BytesOrHex,
  Duration,
  EventType,
  eventTypeFromJSON,
  eventTypeToNumber,
  Filter_ChildOrganizations,
  Filter_ChildOrganizations_Update,
  Filter_Events,
  Filter_Events_Update,
  Filter_Modems,
  Filter_Modems_Update,
  Filter_Publishers,
  Filter_Tags,
  Filter_Tags_Update,
  Location,
  Pagination,
  Pagination_Result,
  TimeRange,
  Timestamp,
  UpdateBoolean,
  UpdateClearableString,
  UpdateOptionalDuration,
  UpdateOptionalId,
} from "./base";
import { Export } from "./export";
import { Struct } from "./google/protobuf/struct";
import { HealthLevel } from "./health";
import {
  MQTTPublisher_ContentType,
  mQTTPublisher_ContentTypeFromJSON,
  mQTTPublisher_ContentTypeToNumber,
  MQTTPublisher_Data_QoS,
  mQTTPublisher_Data_QoSFromJSON,
  mQTTPublisher_Data_QoSToNumber,
} from "./integration_mqtt";
import { ModemMessage, ModemMessage_Source, modemMessage_SourceFromJSON, modemMessage_SourceToNumber } from "./modem";
import { ModemAlarm, ModemAlarm_HealthLevelAfterResolved } from "./modem_alarm";
import { ModemMessageBodyParser, SimpleModemMessageBodyParser } from "./modem_message_body_parser";
import {
  Organization,
  Organization_Address,
  Organization_Contact,
  Organization_Feature,
  organization_FeatureFromJSON,
  organization_FeatureToNumber,
} from "./organization";
import { Publisher, Publisher_Data } from "./publisher";
import { Tag } from "./tag";
import { Token } from "./token";
import { Transfer } from "./transfer";
import { Webhook_ContentType, webhook_ContentTypeFromJSON, webhook_ContentTypeToNumber } from "./webhook";

export const protobufPackage = "hiber.event";

/**
 * Generic Event object, used in streams where events are mixed. Protobuf provides helper methods to extract
 * the contained object.
 */
export interface Event {
  /** Time of the event. */
  type: EventType;
  /** Time of the event. */
  time:
    | Timestamp
    | undefined;
  /**
   * Whether this event causes the 'Error' health level.
   * If your organization does not use the default health levels, this field will be omitted.
   * Use health_level instead.
   *
   * @deprecated
   */
  isError: boolean;
  /**
   * Whether this event causes the 'Warning' health level.
   * If your organization does not use the default health levels, this field will be omitted.
   * Use health_level instead.
   *
   * @deprecated
   */
  isWarning: boolean;
  /**
   * The health level caused by this event.
   * This health generally applies to either a device, a token or a publisher,
   * and is also applied to the organization health.
   * Not every event affects health, so this field might be empty.
   */
  healthLevel: HealthLevel | undefined;
  resolvedStatus?:
    | { $case: "resolved"; resolved: boolean }
    | { $case: "resolveIdentifier"; resolveIdentifier: string }
    | undefined;
  /** Short text describing the event. */
  title: string;
  /** Longer text describing the event in more detail. */
  description: string;
  /** The device data for this event, if it is related to a single device. */
  device:
    | Event_Device
    | undefined;
  /** The tags for this event, if any. */
  tags: Tag[];
  event?:
    | { $case: "assetCreated"; assetCreated: Event_AssetEvent_AssetCreatedEvent }
    | { $case: "assetUpdated"; assetUpdated: Event_AssetEvent_AssetUpdatedEvent }
    | { $case: "assetDeleted"; assetDeleted: Event_AssetEvent_AssetDeletedEvent }
    | { $case: "deviceCreated"; deviceCreated: Event_DeviceEvent_DeviceCreatedEvent }
    | { $case: "deviceUpdated"; deviceUpdated: Event_DeviceEvent_DeviceUpdatedEvent }
    | { $case: "deviceLocationUpdated"; deviceLocationUpdated: Event_DeviceEvent_DeviceLocationUpdatedEvent }
    | { $case: "deviceInstalled"; deviceInstalled: Event_DeviceEvent_DeviceInstalledEvent }
    | { $case: "messageReceived"; messageReceived: Event_MessageEvent_MessageReceivedEvent }
    | { $case: "messageBodyReceived"; messageBodyReceived: Event_MessageEvent_MessageBodyReceivedEvent }
    | { $case: "messageBodyParsed"; messageBodyParsed: Event_MessageEvent_MessageBodyParsedEvent }
    | { $case: "messageCannotBeParsed"; messageCannotBeParsed: Event_MessageEvent_MessageCannotBeParsedEvent }
    | { $case: "messageBodyParserCreated"; messageBodyParserCreated: Event_MessageBodyParserEvent_CreatedEvent }
    | { $case: "messageBodyParserUpdated"; messageBodyParserUpdated: Event_MessageBodyParserEvent_UpdatedEvent }
    | { $case: "messageBodyParserDeleted"; messageBodyParserDeleted: Event_MessageBodyParserEvent_DeletedEvent }
    | { $case: "alarmTriggered"; alarmTriggered: Event_AlarmEvent_AlarmTriggeredEvent }
    | { $case: "alarmCreated"; alarmCreated: Event_AlarmEvent_CreatedEvent }
    | { $case: "alarmUpdated"; alarmUpdated: Event_AlarmEvent_UpdatedEvent }
    | { $case: "alarmDeleted"; alarmDeleted: Event_AlarmEvent_DeletedEvent }
    | { $case: "assigned"; assigned: Event_AssignmentEvent_AssignedEvent }
    | { $case: "unassigned"; unassigned: Event_AssignmentEvent_UnassignedEvent }
    | { $case: "userAdded"; userAdded: Event_UserEvent_UserAddedEvent }
    | { $case: "userRemoved"; userRemoved: Event_UserEvent_UserRemovedEvent }
    | { $case: "userInvited"; userInvited: Event_UserEvent_UserInvitedEvent }
    | { $case: "userAccessRequest"; userAccessRequest: Event_UserEvent_UserAccessRequestEvent }
    | { $case: "userValidationUpdated"; userValidationUpdated: Event_UserEvent_UserValidationUpdatedEvent }
    | { $case: "transfer"; transfer: Event_TransferEvent }
    | { $case: "tokenExpiryWarning"; tokenExpiryWarning: Event_TokenEvent_TokenExpiryWarningEvent }
    | { $case: "tokenExpired"; tokenExpired: Event_TokenEvent_TokenExpiredEvent }
    | { $case: "tokenCreated"; tokenCreated: Event_TokenEvent_TokenCreatedEvent }
    | { $case: "tokenDeleted"; tokenDeleted: Event_TokenEvent_TokenDeletedEvent }
    | { $case: "organizationCreated"; organizationCreated: Event_OrganizationEvent_OrganizationCreatedEvent }
    | { $case: "organizationUpdated"; organizationUpdated: Event_OrganizationEvent_OrganizationUpdatedEvent }
    | { $case: "organizationDeleted"; organizationDeleted: Event_OrganizationEvent_OrganizationDeletedEvent }
    | { $case: "messageSummary"; messageSummary: Event_OrganizationEvent_MessageSummaryEvent }
    | {
      $case: "organizationEventConfigurationUpdated";
      organizationEventConfigurationUpdated: Event_OrganizationEvent_EventConfigurationUpdated;
    }
    | { $case: "publisherCreated"; publisherCreated: Event_PublisherEvent_CreatedEvent }
    | { $case: "publisherUpdated"; publisherUpdated: Event_PublisherEvent_UpdatedEvent }
    | { $case: "publisherDeleted"; publisherDeleted: Event_PublisherEvent_DeletedEvent }
    | { $case: "publisherAutoDisabled"; publisherAutoDisabled: Event_PublisherEvent_AutoDisabledEvent }
    | { $case: "publisherFailed"; publisherFailed: Event_PublisherEvent_FailedEvent }
    | { $case: "exportCreated"; exportCreated: Event_ExportEvent_ExportCreatedEvent }
    | { $case: "exportReady"; exportReady: Event_ExportEvent_ExportReadyEvent }
    | { $case: "exportFailed"; exportFailed: Event_ExportEvent_ExportFailedEvent }
    | undefined;
}

/** The device data for this event, if it is related to a single device. */
export interface Event_Device {
  number: string;
  name: string;
  identifier: string;
}

export interface Event_AssetEvent {
}

export interface Event_AssetEvent_AssetCreatedEvent {
  organization: string;
  assetIdentifier: string;
  asset: Asset | undefined;
  time: Timestamp | undefined;
  tags: Tag[];
  title: string;
  description: string;
}

export interface Event_AssetEvent_AssetUpdatedEvent {
  organization: string;
  updated: Event_AssetEvent_AssetUpdatedEvent_Update | undefined;
  assetIdentifier: string;
  time: Timestamp | undefined;
  tags: Tag[];
  title: string;
  description: string;
}

export interface Event_AssetEvent_AssetUpdatedEvent_Update {
  name?: string | undefined;
  description?: string | undefined;
  notes?: string | undefined;
  timeZone?: string | undefined;
  expectedTransmissionRate?: number | undefined;
  type?: Asset_Type | undefined;
  metadata?: { [key: string]: any } | undefined;
}

export interface Event_AssetEvent_AssetDeletedEvent {
  organization: string;
  deleted: string;
  time: Timestamp | undefined;
  tags: Tag[];
  title: string;
  description: string;
}

export interface Event_DeviceEvent {
}

export interface Event_DeviceEvent_DeviceCreatedEvent {
  organization: string;
  number: string;
  /** External device id for this device (e.g. a MAC address). */
  externalDeviceId: string;
  time: Timestamp | undefined;
  tags: Tag[];
  title: string;
  description: string;
}

export interface Event_DeviceEvent_DeviceUpdatedEvent {
  organization: string;
  number: string;
  /** External device id for this device (e.g. a MAC address). */
  externalDeviceId: string;
  /** @deprecated */
  displayName: UpdateClearableString | undefined;
  updatedDisplayName?: string | undefined;
  peripherals: { [key: string]: string };
  /** @deprecated */
  notes: UpdateClearableString | undefined;
  updatedNotes?: string | undefined;
  deviceType?: string | undefined;
  secureNotesUpdated: boolean;
  healthWarningPeriod: Duration | undefined;
  time: Timestamp | undefined;
  tags: Tag[];
  title: string;
  description: string;
}

export interface Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry {
  key: string;
  value: string;
}

/**
 * When a message comes in, the device's location is updated automatically. This event is generated whenever the
 * device's location is updated
 */
export interface Event_DeviceEvent_DeviceLocationUpdatedEvent {
  organization: string;
  number: string;
  /** External device id for this device (e.g. a MAC address). */
  externalDeviceId: string;
  /** The updated location for this device. */
  location:
    | Location
    | undefined;
  /** The device time of the first message with the new location. */
  updatedAt:
    | Timestamp
    | undefined;
  /** The time this event was generated on the server, after the message was received. */
  time: Timestamp | undefined;
  tags: Tag[];
  title: string;
  description: string;
}

/** When the device is marked as installed. */
export interface Event_DeviceEvent_DeviceInstalledEvent {
  organization: string;
  number: string;
  /** External device id for this device (e.g. a MAC address). */
  externalDeviceId: string;
  /** The time this device was installed. */
  time: Timestamp | undefined;
  tags: Tag[];
  title: string;
  description: string;
}

export interface Event_MessageEvent {
}

/**
 * This event is generated whenever a message comes in, after is has been decrypted
 * and parsed (if any body parsers are configured for the device).
 *
 * It contains the relevant data in the message object, including location and the
 * user-defined body.
 *
 * If any body parser(s) are configured for the device, the device message object also contains
 * a ParsedBody for each of them.
 */
export interface Event_MessageEvent_MessageReceivedEvent {
  organization: string;
  /** @deprecated */
  modemNumber: string;
  deviceNumber: string;
  /** External device id for this device (e.g. a MAC address). */
  deviceExternalDeviceId: string;
  /** The received message, including parsed body. */
  message:
    | ModemMessage
    | undefined;
  /** The device's tags. */
  tags: Tag[];
  title: string;
  description: string;
  time: Timestamp | undefined;
}

/** This lightweight event is generated whenever a message comes in, after is has been decrypted. */
export interface Event_MessageEvent_MessageBodyReceivedEvent {
  organization: string;
  deviceNumber: string;
  /** External device id for this device (e.g. a MAC address). */
  deviceExternalDeviceId: string;
  /** Id of the message that was parsed. */
  messageId: number;
  /** Time the message was sent from the device. */
  sentAt:
    | Timestamp
    | undefined;
  /** Message body convenience object. */
  body:
    | BytesOrHex
    | undefined;
  /** The device's tags. */
  tags: Tag[];
  title: string;
  description: string;
  time: Timestamp | undefined;
}

/**
 * This event is generated whenever a message is parsed successfully by an assigned body parser.
 * If multiple body parsers are assigned to a device, multiple DeviceMessageBodyParsedEvent events will
 * be produced by an incoming message.
 * For the total result of all assigned parsers, see the DeviceMessageReceivedEvent, which is produced
 * after all body parsers have been applied.
 */
export interface Event_MessageEvent_MessageBodyParsedEvent {
  organization: string;
  deviceNumber: string;
  /** External device id for this device (e.g. a MAC address). */
  deviceExternalDeviceId: string;
  /** Id of the message that was parsed. */
  messageId: number;
  /** Time the message was sent from the device. */
  sentAt:
    | Timestamp
    | undefined;
  /** The identifier of the parser that parsed the body. */
  parserIdentifier: string;
  /** The name of the parser that parsed the body. */
  parserName: string;
  /** The resulting json from parsing the message body. */
  parsedMessage:
    | { [key: string]: any }
    | undefined;
  /** The device's tags. */
  tags: Tag[];
  title: string;
  description: string;
  time: Timestamp | undefined;
}

/**
 * Triggered when a message cannot be parsed. This can have multiple reasons, for example, an invalid
 * message version.
 */
export interface Event_MessageEvent_MessageCannotBeParsedEvent {
  organization: string;
  deviceNumber: string;
  /** External device id for this device (e.g. a MAC address). */
  deviceExternalDeviceId: string;
  /**
   * The message that cannot be parsed.
   * This message may or may not be available in the system, depending one the reason it could not
   * be parsed.
   */
  messageId: number;
  /** The moment this event was triggered. */
  time:
    | Timestamp
    | undefined;
  /**
   * The reason this message could not be parsed.
   * This could be, for example:
   * - invalid message envelope, but with enough information to determine the device
   * - all body parsers failed to parse the body of the message
   */
  reason: string;
  tags: Tag[];
  title: string;
  description: string;
  /** The health level caused for the device (and organization) by this event. */
  healthLevel: HealthLevel | undefined;
  resolvedStatus?:
    | { $case: "resolved"; resolved: boolean }
    | { $case: "resolveIdentifier"; resolveIdentifier: string }
    | undefined;
}

/** Events related to MessageBodyParsers. */
export interface Event_MessageBodyParserEvent {
}

export interface Event_MessageBodyParserEvent_CreatedEvent {
  organization: string;
  created: ModemMessageBodyParser | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_MessageBodyParserEvent_UpdatedEvent {
  organization: string;
  /** @deprecated */
  updatedName: UpdateClearableString | undefined;
  name?:
    | string
    | undefined;
  /** @deprecated */
  updatedContentKsy: UpdateClearableString | undefined;
  contentKsy?: string | undefined;
  updatedSimpleParser:
    | SimpleModemMessageBodyParser
    | undefined;
  /** @deprecated */
  updatedAvailableToChildOrganizations: Filter_ChildOrganizations_Update | undefined;
  availableToChildOrganizations?: Filter_ChildOrganizations | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_MessageBodyParserEvent_DeletedEvent {
  organization: string;
  deleted: string;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_AlarmEvent {
}

export interface Event_AlarmEvent_AlarmTriggeredEvent {
  organization: string;
  deviceNumber: string;
  deviceExternalDeviceId: string;
  /** The id of the device that triggered the alarm, if any. */
  messageId: number;
  /** The identifier of the alarm. */
  alarmIdentifier: string;
  /** The description of the alarm. */
  alarmDescription: string;
  /** The filled in error message(s) for the failing checks in the alarm. */
  errorMessages: { [key: string]: string };
  /**
   * The highest health level caused by the failing checks in this alarm,
   * for the device (and organization).
   */
  healthLevel: HealthLevel | undefined;
  tags: Tag[];
  title: string;
  description: string;
  time:
    | Timestamp
    | undefined;
  /** When this alarm event was resolved. */
  resolvedAt:
    | Timestamp
    | undefined;
  /** Optional health that this alarm event causes after it has been resolved. */
  healthLevelAfterResolved:
    | HealthLevel
    | undefined;
  /** How long the optional health that this alarm event causes after it has been resolved lasts. */
  healthLevelAfterResolvedUntil: Timestamp | undefined;
}

export interface Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry {
  key: string;
  value: string;
}

export interface Event_AlarmEvent_CreatedEvent {
  organization: string;
  created: ModemAlarm | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_AlarmEvent_UpdatedEvent {
  organization: string;
  updated: Event_AlarmEvent_UpdatedEvent_Update | undefined;
  alarmIdentifier: string;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_AlarmEvent_UpdatedEvent_Update {
  /** @deprecated */
  deprecatedDescription: UpdateClearableString | undefined;
  description?: string | undefined;
  triggerCondition: string;
  checks:
    | { [key: string]: any }
    | undefined;
  /** @deprecated */
  updateDefaultHealthLevel: UpdateClearableString | undefined;
  updatedDefaultHealthLevel?: string | undefined;
  updateHealthLevelAfterResolved: ModemAlarm_HealthLevelAfterResolved | undefined;
}

export interface Event_AlarmEvent_DeletedEvent {
  organization: string;
  deleted: string;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_TransferEvent {
  organization: string;
  time: Timestamp | undefined;
  tags: Tag[];
  title: string;
  description: string;
  transfer: Transfer | undefined;
}

export interface Event_AssignmentEvent {
}

export interface Event_AssignmentEvent_AssignedEvent {
  organization: string;
  created: Assignment | undefined;
  tags: Tag[];
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_AssignmentEvent_UnassignedEvent {
  organization: string;
  removed: Assignment | undefined;
  tags: Tag[];
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_UserEvent {
}

export interface Event_UserEvent_UserAddedEvent {
  organization: string;
  user: string;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_UserEvent_UserRemovedEvent {
  organization: string;
  user: string;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_UserEvent_UserInvitedEvent {
  organization: string;
  email: string;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_UserEvent_UserAccessRequestEvent {
  organization: string;
  user: string;
  time: Timestamp | undefined;
  title: string;
  description: string;
}

export interface Event_UserEvent_UserValidationUpdatedEvent {
  organization: string;
  emailValidationRegex: string;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_PublisherEvent {
}

export interface Event_PublisherEvent_CreatedEvent {
  organization: string;
  created: Publisher | undefined;
  tags: Tag[];
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_PublisherEvent_UpdatedEvent {
  organization: string;
  /** @deprecated */
  deprecatedUpdatedDescription: UpdateClearableString | undefined;
  updatedDescription?:
    | string
    | undefined;
  /**
   * Deprecated in favor of the new updated_data field
   *
   * @deprecated
   */
  partialUpdateData: Publisher_Data | undefined;
  updatedData?:
    | { $case: "webhook"; webhook: Event_PublisherEvent_UpdatedEvent_WebhookUpdate }
    | { $case: "mqtt"; mqtt: Event_PublisherEvent_UpdatedEvent_MQTTUpdate }
    | { $case: "email"; email: Event_PublisherEvent_UpdatedEvent_EmailUpdate }
    | { $case: "slack"; slack: Event_PublisherEvent_UpdatedEvent_SlackUpdate }
    | { $case: "shellSsip"; shellSsip: Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate }
    | undefined;
  /** @deprecated */
  deprecatedUpdatedEventFilter: Filter_Events_Update | undefined;
  updatedEventFilter?:
    | Filter_Events
    | undefined;
  /** @deprecated */
  deprecatedUpdatedModemFilter: Filter_Modems_Update | undefined;
  updatedModemFilter?:
    | Filter_Modems
    | undefined;
  /** @deprecated */
  deprecatedUpdatedTagFilter: Filter_Tags_Update | undefined;
  updatedTagFilter?:
    | Filter_Tags
    | undefined;
  /** @deprecated */
  deprecatedUpdatedActiveState: UpdateBoolean | undefined;
  updatedActiveState?: boolean | undefined;
  healthWarningPeriod: Duration | undefined;
  tags: Tag[];
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_PublisherEvent_UpdatedEvent_WebhookUpdate {
  url?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedSecret: UpdateClearableString | undefined;
  secret?: string | undefined;
  contentType?:
    | Webhook_ContentType
    | undefined;
  /** @deprecated */
  deprecatedCertificateId: UpdateOptionalId | undefined;
  certificateId?: number | undefined;
}

export interface Event_PublisherEvent_UpdatedEvent_MQTTUpdate {
  url: string;
  contentType: MQTTPublisher_ContentType;
  topic: string;
  qos: MQTTPublisher_Data_QoS;
  /** @deprecated */
  deprecatedIdentifier: UpdateClearableString | undefined;
  identifier?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedUsername: UpdateClearableString | undefined;
  username?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedPassword: UpdateClearableString | undefined;
  password?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedCertificateId: UpdateOptionalId | undefined;
  certificateId?: number | undefined;
}

export interface Event_PublisherEvent_UpdatedEvent_EmailUpdate {
}

export interface Event_PublisherEvent_UpdatedEvent_SlackUpdate {
}

export interface Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate {
}

export interface Event_PublisherEvent_DeletedEvent {
  organization: string;
  deleted: Publisher | undefined;
  tags: Tag[];
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_PublisherEvent_AutoDisabledEvent {
  organization: string;
  id: number;
  tags: Tag[];
  title: string;
  description: string;
  time:
    | Timestamp
    | undefined;
  /** The health level caused for the publisher (and organization) by this event. */
  healthLevel: HealthLevel | undefined;
}

export interface Event_PublisherEvent_FailedEvent {
  organization: string;
  reason: string;
  failed: Publisher | undefined;
  tags: Tag[];
  title: string;
  description: string;
  time:
    | Timestamp
    | undefined;
  /** The health level caused for the publisher (and organization) by this event. */
  healthLevel: HealthLevel | undefined;
}

export interface Event_TokenEvent {
}

export interface Event_TokenEvent_TokenCreatedEvent {
  organization: string;
  token: Token | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_TokenEvent_TokenExpiryWarningEvent {
  organization: string;
  token: Token | undefined;
  title: string;
  description: string;
  time:
    | Timestamp
    | undefined;
  /** The health level caused for the organization by this event. */
  healthLevel: HealthLevel | undefined;
}

export interface Event_TokenEvent_TokenExpiredEvent {
  organization: string;
  token: Token | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_TokenEvent_TokenDeletedEvent {
  organization: string;
  token: Token | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_OrganizationEvent {
}

export interface Event_OrganizationEvent_OrganizationCreatedEvent {
  parentOrganization: string;
  created: Organization | undefined;
  title: string;
  description: string;
  avatar: Avatar | undefined;
  time: Timestamp | undefined;
}

export interface Event_OrganizationEvent_OrganizationUpdatedEvent {
  organization: string;
  displayNameUpdated?: string | undefined;
  vatNumberUpdated?: string | undefined;
  addressUpdated?:
    | Organization_Address
    | undefined;
  /** @deprecated */
  isBusinessUpdated: UpdateBoolean | undefined;
  businessUpdated?: boolean | undefined;
  billingNameUpdated?: string | undefined;
  billingAddressUpdated?: Organization_Address | undefined;
  contactUpdated?: Organization_Contact | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
  featuresAdded: Organization_Feature[];
  featuresRemoved: Organization_Feature[];
}

export interface Event_OrganizationEvent_OrganizationDeletedEvent {
  parentOrganization: string;
  deletedOrganization: string;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_OrganizationEvent_EventConfigurationUpdated {
  organization: string;
  messageSummaryUpdate: Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate {
  /** @deprecated */
  enabled: UpdateBoolean | undefined;
  state?: boolean | undefined;
  period?: Duration | undefined;
  alignToTime?: Timestamp | undefined;
}

/**
 * Message summary event that is sent when it has been configured for the organization.
 * The period is configurable, using the EventConfiguration.
 * This event is disabled by default.
 */
export interface Event_OrganizationEvent_MessageSummaryEvent {
  organization: string;
  messageCount: Event_OrganizationEvent_MessageSummaryEvent_MessageCount[];
  title: string;
  description: string;
  time: Timestamp | undefined;
  timeRange: TimeRange | undefined;
}

export interface Event_OrganizationEvent_MessageSummaryEvent_MessageCount {
  device: string;
  source: ModemMessage_Source;
  amount: number;
}

export interface Event_ExportEvent {
}

export interface Event_ExportEvent_ExportCreatedEvent {
  organization: string;
  export: Export | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_ExportEvent_ExportReadyEvent {
  organization: string;
  export: Export | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

export interface Event_ExportEvent_ExportFailedEvent {
  organization: string;
  export: Export | undefined;
  title: string;
  description: string;
  time: Timestamp | undefined;
}

/** A bundle/set of events, all of the same event-type. */
export interface BundledEvent {
  type: EventType;
  title: string;
  description: string;
  amount?:
    | { $case: "exactAmount"; exactAmount: BundledEvent_ExactAmount }
    | { $case: "approximatedAmount"; approximatedAmount: BundledEvent_ApproximatedAmount }
    | undefined;
  /**
   * Deprecated in favour of using the amount field that is either an ExactAmount or ApproximatedAmount
   *
   * @deprecated
   */
  deprecatedAmount: number;
  /**
   * Deprecated in favour of using the amount field that is either an ExactAmount or ApproximatedAmount.
   * since `last_event` is not available when using approximation (we don't know the actual last event),
   * this field is only present when getting an ExactAmount.
   *
   * @deprecated
   */
  deprecatedLastEvent:
    | Timestamp
    | undefined;
  /**
   * Indicates that the amount is an approximation, and not an exact value.
   * Some events will be approximated to avoid a large number of events affecting the request time.
   * Deprecated in  favour of using the oneof_amount field that is either an ExactAmount or ApproximatedAmount
   *
   * @deprecated
   */
  deprecatedApproximatedAmount: boolean;
}

/** Indicates that the amount is an exact count, and not an approximated value. */
export interface BundledEvent_ExactAmount {
  amount: number;
  lastEvent: Timestamp | undefined;
}

/**
 * Indicates that the amount is an approximation, and not an exact value.
 * Some events will be approximated to avoid a large number of events affecting the request time.
 * Because of the nature of approximation, we don't have a "last" event, so no timestamp is available.
 */
export interface BundledEvent_ApproximatedAmount {
  amount: number;
}

/**
 * Selection object for Event list and stream. Filter events on device, webhook, time and error state, and
 * paginate the list.
 * Events are returned bundled by default. this means that, instead of the details, you get a list of bundles,
 * one per event type, with the count.
 */
export interface EventSelection {
  events?: Filter_Events | undefined;
  modems?: Filter_Modems | undefined;
  publishers?: Filter_Publishers | undefined;
  tags?: Filter_Tags | undefined;
  timeRange?:
    | TimeRange
    | undefined;
  /** Filter events by actual configured health level. */
  healthLevels: string[];
  /** By default, events that have been resolved are not listed. */
  includeResolvedEvents?:
    | boolean
    | undefined;
  /**
   * When not set, no unbundled events are returned.
   * When set, returns unbundled events per type, paginated per type.
   * Deprecated: use unbundled_events on ListEventsRequest
   *
   * @deprecated
   */
  unbundledEvents?:
    | boolean
    | undefined;
  /**
   * Return only events that cause the default Error health.
   * Deprecated: use health_levels with custom health levels instead.
   *
   * @deprecated
   */
  errorsOnly?:
    | boolean
    | undefined;
  /**
   * Return only events that cause the default Warning health.
   * Deprecated: use health_levels with custom health levels instead.
   *
   * @deprecated
   */
  warningsOnly?: boolean | undefined;
}

/**
 * Lists all events matching the given criteria.
 * The frequency of event can vary greatly per type, resulting in unclear pagination and some event types overshadowing
 * others. For this reason, event are bundled by default, reducing them to a count per event type.
 * If unbundled events for certain types are required, this can be toggled in the selection object.
 * Pagination is only applied to unbundled events, and is applied per event type. This may result in a non-linear
 * timeline from page to page when selecting two types with a large difference in frequency, but makes sure you
 * see the most recent events of each type on the first page.
 */
export interface ListEventsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the events to list. Optional, when omitted or empty everything is included. */
  selection?:
    | EventSelection
    | undefined;
  /** Pagination is applied per event type on unbundled events. It is not necessary for bundled events. */
  pagination?: Pagination | undefined;
  sort?:
    | ListEventsRequest_Sort
    | undefined;
  /**
   * When not set, bundled/grouped events are returned.
   * When set, returns unbundled events per type, paginated per type.
   * Overrides unbundled_events in EventSelection message.
   */
  unbundledEvents?: boolean | undefined;
}

export enum ListEventsRequest_Sort {
  TIME_DESCENDING = "TIME_DESCENDING",
  TIME_ASCENDING = "TIME_ASCENDING",
  DEVICE_NUMBER_ASC = "DEVICE_NUMBER_ASC",
  DEVICE_NUMBER_DESC = "DEVICE_NUMBER_DESC",
  DEVICE_NUMBER_SPECIFIED = "DEVICE_NUMBER_SPECIFIED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function listEventsRequest_SortFromJSON(object: any): ListEventsRequest_Sort {
  switch (object) {
    case 0:
    case "TIME_DESCENDING":
      return ListEventsRequest_Sort.TIME_DESCENDING;
    case 1:
    case "TIME_ASCENDING":
      return ListEventsRequest_Sort.TIME_ASCENDING;
    case 2:
    case "DEVICE_NUMBER_ASC":
      return ListEventsRequest_Sort.DEVICE_NUMBER_ASC;
    case 3:
    case "DEVICE_NUMBER_DESC":
      return ListEventsRequest_Sort.DEVICE_NUMBER_DESC;
    case 4:
    case "DEVICE_NUMBER_SPECIFIED":
      return ListEventsRequest_Sort.DEVICE_NUMBER_SPECIFIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListEventsRequest_Sort.UNRECOGNIZED;
  }
}

export function listEventsRequest_SortToNumber(object: ListEventsRequest_Sort): number {
  switch (object) {
    case ListEventsRequest_Sort.TIME_DESCENDING:
      return 0;
    case ListEventsRequest_Sort.TIME_ASCENDING:
      return 1;
    case ListEventsRequest_Sort.DEVICE_NUMBER_ASC:
      return 2;
    case ListEventsRequest_Sort.DEVICE_NUMBER_DESC:
      return 3;
    case ListEventsRequest_Sort.DEVICE_NUMBER_SPECIFIED:
      return 4;
    case ListEventsRequest_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListEventsRequest_Response {
  request: ListEventsRequest | undefined;
  events: BundledEvent[];
  unbundledEvents: ListEventsRequest_Response_EventTypeResponse[];
}

export interface ListEventsRequest_Response_EventTypeResponse {
  type: EventType;
  unbundledEvents: Event[];
  pagination: Pagination_Result | undefined;
}

/**
 * Get the history of events that match the given filter, chronologically (by default, from now backwards in time).
 *
 * Only returns the Event with first-level fields set, event details are not included in the response
 * (the oneof is not set).
 */
export interface EventHistory {
}

/**
 * Selection for which events to include the response.
 * Using the EventSelection you'll be able to filter on specific types, or other properties of events.
 * The Pagination object will let you limit the number of events returned.
 */
export interface EventHistory_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the events to list. Optional, when omitted or empty everything is included. */
  selection?: EventSelection | undefined;
  pagination?: Pagination | undefined;
}

export interface EventHistory_Response {
  request: EventHistory_Request | undefined;
  events: Event[];
  pagination: Pagination_Result | undefined;
}

/**
 * Creates a stream for new events, matching the provided selection.
 *
 * Note: streams are limited to one per user due to GRPC limitations.
 */
export interface EventStreamRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the events to stream. Optional, when omitted or empty everything is included. */
  selection?:
    | EventSelection
    | undefined;
  /**
   * Include events from child organizations, if requested.
   * This is only available for streaming, listing events can only be listed per organization.
   * Keep in mind that filtering events using the selection is limited.
   * Filtering on tags or publishers, for example, can only apply to the parent organization.
   */
  childOrganizations?: Filter_ChildOrganizations | undefined;
}

/**
 * Get the list of events that affect device health, chronologically (by default, from now backwards in time).
 *
 * Only returns the Event with first-level fields set, event details are not included in the response
 * (the oneof is not set).
 */
export interface ModemHealthEvents {
}

export interface ModemHealthEvents_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the events to list. Optional, when omitted or empty everything is included. */
  selection?: EventSelection | undefined;
  pagination?: Pagination | undefined;
}

export interface ModemHealthEvents_Response {
  request: ModemHealthEvents_Request | undefined;
  events: Event[];
  pagination: Pagination_Result | undefined;
}

/** Resolve a resolvable event using its resolve_identifier. */
export interface ResolveEvent {
}

export interface ResolveEvent_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The resolve identifier of the event you wish to resolve. */
  resolveIdentifier: string;
}

export interface ResolveEvent_Response {
  event: Event | undefined;
}

/** Mark a selection of events as resolved. */
export interface MarkEventsResolved {
}

export interface MarkEventsResolved_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Selection of events to resolve. */
  selection: EventSelection | undefined;
}

export interface MarkEventsResolved_Response {
  /** The amount of events that were resolved. */
  resolved: number;
  /** The original request, echoed back with any applied corrections. */
  request: MarkEventsResolved_Request | undefined;
}

/**
 * Configuration for configurable events.
 * Some events are disable by default, or can be configured in different ways. Their configuration is available here.
 */
export interface EventConfiguration {
  organization: string;
  messageSummaryConfiguration:
    | EventConfiguration_MessageSummaryConfiguration
    | undefined;
  /** List of event-types that have a health-level associated with them. Ignored when updating. */
  eventHealthLevelConfiguration: EventConfiguration_EventHealthLevelConfiguration[];
}

/**
 * When enabled, this sends a MessageSummaryEvent periodically.
 * For example, you can configure the message summary to be a daily message, aligned to midnight in your time zone.
 */
export interface EventConfiguration_MessageSummaryConfiguration {
  /** The message summary event is disabled by default. */
  enabled: boolean;
  /** The period to send the message summary event for. The event can be sent every hour, day, etc. */
  period:
    | Duration
    | undefined;
  /**
   * Align the period to given time.
   * For example, an hourly period sends can be aligned to 0:00 to send every whole hour.
   * A weekly period can be aligned to send at a specific time on Monday by aligning to a timestamp on a Monday.
   * If a textual time zone is configured in this timestamp, it is used as well and should shift with changes
   * like daylight saving time automatically.
   */
  alignToTime:
    | Timestamp
    | undefined;
  /** Send an event, even if the summary does not contain any information */
  includeEmptySummaries: boolean;
}

/**
 * Details the health level configuration for an event-type.
 * Health of the system can be influenced by multiple things, among which certain events.
 * When an event that has health associated with it,
 * said health level will be applied to the part of the system it refers to.
 * For instance, an event about not being able to parse device messages, will influence device health.
 * An event about a failing parser, will affect parser health.
 *
 * In general, when multiple events affect the health level of a subsystem,
 * the most severe health will be applied.
 *
 * When an expiry duration is set, events will no longer affect health of the subsystem after the configured period.
 * Health levels reset to the least severe applicable level, typically "Ok".
 *
 * For instance, an event might not be relevant anymore after 5 days.
 * By setting expiry to "5d", the event will resolve automatically 5 days after the last event happened.
 * Depending on other factors that influence device health,
 * the health level will be reset to the least severe health level available.
 *
 * If there is no expiry duration, events need to be resolved manually.
 *
 * To update, use EventService/UpdateEventHealthConfiguration(UpdateEventHealthConfiguration.Request)
 */
export interface EventConfiguration_EventHealthLevelConfiguration {
  /** The event type for which this configuration is applicable. */
  type: EventType;
  /** The health level to produce when this event happens. */
  level:
    | HealthLevel
    | undefined;
  /** If set, the duration for which the health level is applied. Afterward this event has no effect on health. */
  expiry:
    | Duration
    | undefined;
  /** When this configuration item was created. */
  createdAt:
    | Timestamp
    | undefined;
  /** The most recent time this configuration item was updated. */
  updatedAt: Timestamp | undefined;
}

export interface EventConfiguration_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
}

export interface UpdateEventHealthConfiguration {
}

/**
 * Updates health-level configuration for a specific event-type.
 * To find available health-level names, use HealthService/List(ListHealthLevels.Request).
 * To clear an existing expiry, set `updated` true and leave `duration` to be the default value.
 */
export interface UpdateEventHealthConfiguration_UpdateEventHealth {
  /** When set, events of this type will affect the system with the configured health level. */
  type: EventType;
  /** The health level to produce when the event happens, by name. */
  level: string;
  /** After the expiry duration, the event will not affect health anymore. Clear for manual resolving of events. */
  expiry?:
    | Duration
    | undefined;
  /** @deprecated */
  deprecatedExpiry: UpdateOptionalDuration | undefined;
}

export interface UpdateEventHealthConfiguration_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** List of event-types to update health for. When listing same event twice, behaviour is undefined. */
  updates: UpdateEventHealthConfiguration_UpdateEventHealth[];
}

export interface UpdateEventHealthConfiguration_Response {
  /** The original request, echoed back. */
  request:
    | UpdateEventHealthConfiguration_Request
    | undefined;
  /** The updated event configuration. */
  eventConfiguration: EventConfiguration | undefined;
}

function createBaseEvent(): Event {
  return {
    type: EventType.DEFAULT,
    time: undefined,
    isError: false,
    isWarning: false,
    healthLevel: undefined,
    resolvedStatus: undefined,
    title: "",
    description: "",
    device: undefined,
    tags: [],
    event: undefined,
  };
}

export const Event = {
  encode(message: Event, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== EventType.DEFAULT) {
      writer.uint32(784).int32(eventTypeToNumber(message.type));
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(794).fork()).ldelim();
    }
    if (message.isError === true) {
      writer.uint32(8).bool(message.isError);
    }
    if (message.isWarning === true) {
      writer.uint32(800).bool(message.isWarning);
    }
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(810).fork()).ldelim();
    }
    switch (message.resolvedStatus?.$case) {
      case "resolved":
        writer.uint32(848).bool(message.resolvedStatus.resolved);
        break;
      case "resolveIdentifier":
        writer.uint32(858).string(message.resolvedStatus.resolveIdentifier);
        break;
    }
    if (message.title !== "") {
      writer.uint32(818).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(826).string(message.description);
    }
    if (message.device !== undefined) {
      Event_Device.encode(message.device, writer.uint32(834).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(842).fork()).ldelim();
    }
    switch (message.event?.$case) {
      case "assetCreated":
        Event_AssetEvent_AssetCreatedEvent.encode(message.event.assetCreated, writer.uint32(562).fork()).ldelim();
        break;
      case "assetUpdated":
        Event_AssetEvent_AssetUpdatedEvent.encode(message.event.assetUpdated, writer.uint32(570).fork()).ldelim();
        break;
      case "assetDeleted":
        Event_AssetEvent_AssetDeletedEvent.encode(message.event.assetDeleted, writer.uint32(578).fork()).ldelim();
        break;
      case "deviceCreated":
        Event_DeviceEvent_DeviceCreatedEvent.encode(message.event.deviceCreated, writer.uint32(442).fork()).ldelim();
        break;
      case "deviceUpdated":
        Event_DeviceEvent_DeviceUpdatedEvent.encode(message.event.deviceUpdated, writer.uint32(314).fork()).ldelim();
        break;
      case "deviceLocationUpdated":
        Event_DeviceEvent_DeviceLocationUpdatedEvent.encode(
          message.event.deviceLocationUpdated,
          writer.uint32(18).fork(),
        ).ldelim();
        break;
      case "deviceInstalled":
        Event_DeviceEvent_DeviceInstalledEvent.encode(message.event.deviceInstalled, writer.uint32(290).fork())
          .ldelim();
        break;
      case "messageReceived":
        Event_MessageEvent_MessageReceivedEvent.encode(message.event.messageReceived, writer.uint32(34).fork())
          .ldelim();
        break;
      case "messageBodyReceived":
        Event_MessageEvent_MessageBodyReceivedEvent.encode(message.event.messageBodyReceived, writer.uint32(354).fork())
          .ldelim();
        break;
      case "messageBodyParsed":
        Event_MessageEvent_MessageBodyParsedEvent.encode(message.event.messageBodyParsed, writer.uint32(362).fork())
          .ldelim();
        break;
      case "messageCannotBeParsed":
        Event_MessageEvent_MessageCannotBeParsedEvent.encode(
          message.event.messageCannotBeParsed,
          writer.uint32(58).fork(),
        ).ldelim();
        break;
      case "messageBodyParserCreated":
        Event_MessageBodyParserEvent_CreatedEvent.encode(
          message.event.messageBodyParserCreated,
          writer.uint32(370).fork(),
        ).ldelim();
        break;
      case "messageBodyParserUpdated":
        Event_MessageBodyParserEvent_UpdatedEvent.encode(
          message.event.messageBodyParserUpdated,
          writer.uint32(378).fork(),
        ).ldelim();
        break;
      case "messageBodyParserDeleted":
        Event_MessageBodyParserEvent_DeletedEvent.encode(
          message.event.messageBodyParserDeleted,
          writer.uint32(386).fork(),
        ).ldelim();
        break;
      case "alarmTriggered":
        Event_AlarmEvent_AlarmTriggeredEvent.encode(message.event.alarmTriggered, writer.uint32(450).fork()).ldelim();
        break;
      case "alarmCreated":
        Event_AlarmEvent_CreatedEvent.encode(message.event.alarmCreated, writer.uint32(458).fork()).ldelim();
        break;
      case "alarmUpdated":
        Event_AlarmEvent_UpdatedEvent.encode(message.event.alarmUpdated, writer.uint32(466).fork()).ldelim();
        break;
      case "alarmDeleted":
        Event_AlarmEvent_DeletedEvent.encode(message.event.alarmDeleted, writer.uint32(474).fork()).ldelim();
        break;
      case "assigned":
        Event_AssignmentEvent_AssignedEvent.encode(message.event.assigned, writer.uint32(546).fork()).ldelim();
        break;
      case "unassigned":
        Event_AssignmentEvent_UnassignedEvent.encode(message.event.unassigned, writer.uint32(554).fork()).ldelim();
        break;
      case "userAdded":
        Event_UserEvent_UserAddedEvent.encode(message.event.userAdded, writer.uint32(66).fork()).ldelim();
        break;
      case "userRemoved":
        Event_UserEvent_UserRemovedEvent.encode(message.event.userRemoved, writer.uint32(74).fork()).ldelim();
        break;
      case "userInvited":
        Event_UserEvent_UserInvitedEvent.encode(message.event.userInvited, writer.uint32(330).fork()).ldelim();
        break;
      case "userAccessRequest":
        Event_UserEvent_UserAccessRequestEvent.encode(message.event.userAccessRequest, writer.uint32(82).fork())
          .ldelim();
        break;
      case "userValidationUpdated":
        Event_UserEvent_UserValidationUpdatedEvent.encode(
          message.event.userValidationUpdated,
          writer.uint32(434).fork(),
        ).ldelim();
        break;
      case "transfer":
        Event_TransferEvent.encode(message.event.transfer, writer.uint32(138).fork()).ldelim();
        break;
      case "tokenExpiryWarning":
        Event_TokenEvent_TokenExpiryWarningEvent.encode(message.event.tokenExpiryWarning, writer.uint32(186).fork())
          .ldelim();
        break;
      case "tokenExpired":
        Event_TokenEvent_TokenExpiredEvent.encode(message.event.tokenExpired, writer.uint32(194).fork()).ldelim();
        break;
      case "tokenCreated":
        Event_TokenEvent_TokenCreatedEvent.encode(message.event.tokenCreated, writer.uint32(242).fork()).ldelim();
        break;
      case "tokenDeleted":
        Event_TokenEvent_TokenDeletedEvent.encode(message.event.tokenDeleted, writer.uint32(282).fork()).ldelim();
        break;
      case "organizationCreated":
        Event_OrganizationEvent_OrganizationCreatedEvent.encode(
          message.event.organizationCreated,
          writer.uint32(298).fork(),
        ).ldelim();
        break;
      case "organizationUpdated":
        Event_OrganizationEvent_OrganizationUpdatedEvent.encode(
          message.event.organizationUpdated,
          writer.uint32(202).fork(),
        ).ldelim();
        break;
      case "organizationDeleted":
        Event_OrganizationEvent_OrganizationDeletedEvent.encode(
          message.event.organizationDeleted,
          writer.uint32(306).fork(),
        ).ldelim();
        break;
      case "messageSummary":
        Event_OrganizationEvent_MessageSummaryEvent.encode(message.event.messageSummary, writer.uint32(338).fork())
          .ldelim();
        break;
      case "organizationEventConfigurationUpdated":
        Event_OrganizationEvent_EventConfigurationUpdated.encode(
          message.event.organizationEventConfigurationUpdated,
          writer.uint32(346).fork(),
        ).ldelim();
        break;
      case "publisherCreated":
        Event_PublisherEvent_CreatedEvent.encode(message.event.publisherCreated, writer.uint32(250).fork()).ldelim();
        break;
      case "publisherUpdated":
        Event_PublisherEvent_UpdatedEvent.encode(message.event.publisherUpdated, writer.uint32(258).fork()).ldelim();
        break;
      case "publisherDeleted":
        Event_PublisherEvent_DeletedEvent.encode(message.event.publisherDeleted, writer.uint32(266).fork()).ldelim();
        break;
      case "publisherAutoDisabled":
        Event_PublisherEvent_AutoDisabledEvent.encode(message.event.publisherAutoDisabled, writer.uint32(322).fork())
          .ldelim();
        break;
      case "publisherFailed":
        Event_PublisherEvent_FailedEvent.encode(message.event.publisherFailed, writer.uint32(274).fork()).ldelim();
        break;
      case "exportCreated":
        Event_ExportEvent_ExportCreatedEvent.encode(message.event.exportCreated, writer.uint32(522).fork()).ldelim();
        break;
      case "exportReady":
        Event_ExportEvent_ExportReadyEvent.encode(message.event.exportReady, writer.uint32(530).fork()).ldelim();
        break;
      case "exportFailed":
        Event_ExportEvent_ExportFailedEvent.encode(message.event.exportFailed, writer.uint32(538).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 98:
          if (tag !== 784) {
            break;
          }

          message.type = eventTypeFromJSON(reader.int32());
          continue;
        case 99:
          if (tag !== 794) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isError = reader.bool();
          continue;
        case 100:
          if (tag !== 800) {
            break;
          }

          message.isWarning = reader.bool();
          continue;
        case 101:
          if (tag !== 810) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 106:
          if (tag !== 848) {
            break;
          }

          message.resolvedStatus = { $case: "resolved", resolved: reader.bool() };
          continue;
        case 107:
          if (tag !== 858) {
            break;
          }

          message.resolvedStatus = { $case: "resolveIdentifier", resolveIdentifier: reader.string() };
          continue;
        case 102:
          if (tag !== 818) {
            break;
          }

          message.title = reader.string();
          continue;
        case 103:
          if (tag !== 826) {
            break;
          }

          message.description = reader.string();
          continue;
        case 104:
          if (tag !== 834) {
            break;
          }

          message.device = Event_Device.decode(reader, reader.uint32());
          continue;
        case 105:
          if (tag !== 842) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 70:
          if (tag !== 562) {
            break;
          }

          message.event = {
            $case: "assetCreated",
            assetCreated: Event_AssetEvent_AssetCreatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 71:
          if (tag !== 570) {
            break;
          }

          message.event = {
            $case: "assetUpdated",
            assetUpdated: Event_AssetEvent_AssetUpdatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 72:
          if (tag !== 578) {
            break;
          }

          message.event = {
            $case: "assetDeleted",
            assetDeleted: Event_AssetEvent_AssetDeletedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.event = {
            $case: "deviceCreated",
            deviceCreated: Event_DeviceEvent_DeviceCreatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.event = {
            $case: "deviceUpdated",
            deviceUpdated: Event_DeviceEvent_DeviceUpdatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.event = {
            $case: "deviceLocationUpdated",
            deviceLocationUpdated: Event_DeviceEvent_DeviceLocationUpdatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.event = {
            $case: "deviceInstalled",
            deviceInstalled: Event_DeviceEvent_DeviceInstalledEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.event = {
            $case: "messageReceived",
            messageReceived: Event_MessageEvent_MessageReceivedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.event = {
            $case: "messageBodyReceived",
            messageBodyReceived: Event_MessageEvent_MessageBodyReceivedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.event = {
            $case: "messageBodyParsed",
            messageBodyParsed: Event_MessageEvent_MessageBodyParsedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.event = {
            $case: "messageCannotBeParsed",
            messageCannotBeParsed: Event_MessageEvent_MessageCannotBeParsedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.event = {
            $case: "messageBodyParserCreated",
            messageBodyParserCreated: Event_MessageBodyParserEvent_CreatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.event = {
            $case: "messageBodyParserUpdated",
            messageBodyParserUpdated: Event_MessageBodyParserEvent_UpdatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.event = {
            $case: "messageBodyParserDeleted",
            messageBodyParserDeleted: Event_MessageBodyParserEvent_DeletedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 56:
          if (tag !== 450) {
            break;
          }

          message.event = {
            $case: "alarmTriggered",
            alarmTriggered: Event_AlarmEvent_AlarmTriggeredEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 57:
          if (tag !== 458) {
            break;
          }

          message.event = {
            $case: "alarmCreated",
            alarmCreated: Event_AlarmEvent_CreatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 58:
          if (tag !== 466) {
            break;
          }

          message.event = {
            $case: "alarmUpdated",
            alarmUpdated: Event_AlarmEvent_UpdatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.event = {
            $case: "alarmDeleted",
            alarmDeleted: Event_AlarmEvent_DeletedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 68:
          if (tag !== 546) {
            break;
          }

          message.event = {
            $case: "assigned",
            assigned: Event_AssignmentEvent_AssignedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 69:
          if (tag !== 554) {
            break;
          }

          message.event = {
            $case: "unassigned",
            unassigned: Event_AssignmentEvent_UnassignedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.event = {
            $case: "userAdded",
            userAdded: Event_UserEvent_UserAddedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.event = {
            $case: "userRemoved",
            userRemoved: Event_UserEvent_UserRemovedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.event = {
            $case: "userInvited",
            userInvited: Event_UserEvent_UserInvitedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.event = {
            $case: "userAccessRequest",
            userAccessRequest: Event_UserEvent_UserAccessRequestEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 54:
          if (tag !== 434) {
            break;
          }

          message.event = {
            $case: "userValidationUpdated",
            userValidationUpdated: Event_UserEvent_UserValidationUpdatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.event = { $case: "transfer", transfer: Event_TransferEvent.decode(reader, reader.uint32()) };
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.event = {
            $case: "tokenExpiryWarning",
            tokenExpiryWarning: Event_TokenEvent_TokenExpiryWarningEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.event = {
            $case: "tokenExpired",
            tokenExpired: Event_TokenEvent_TokenExpiredEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.event = {
            $case: "tokenCreated",
            tokenCreated: Event_TokenEvent_TokenCreatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.event = {
            $case: "tokenDeleted",
            tokenDeleted: Event_TokenEvent_TokenDeletedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.event = {
            $case: "organizationCreated",
            organizationCreated: Event_OrganizationEvent_OrganizationCreatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.event = {
            $case: "organizationUpdated",
            organizationUpdated: Event_OrganizationEvent_OrganizationUpdatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.event = {
            $case: "organizationDeleted",
            organizationDeleted: Event_OrganizationEvent_OrganizationDeletedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.event = {
            $case: "messageSummary",
            messageSummary: Event_OrganizationEvent_MessageSummaryEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.event = {
            $case: "organizationEventConfigurationUpdated",
            organizationEventConfigurationUpdated: Event_OrganizationEvent_EventConfigurationUpdated.decode(
              reader,
              reader.uint32(),
            ),
          };
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.event = {
            $case: "publisherCreated",
            publisherCreated: Event_PublisherEvent_CreatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.event = {
            $case: "publisherUpdated",
            publisherUpdated: Event_PublisherEvent_UpdatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.event = {
            $case: "publisherDeleted",
            publisherDeleted: Event_PublisherEvent_DeletedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.event = {
            $case: "publisherAutoDisabled",
            publisherAutoDisabled: Event_PublisherEvent_AutoDisabledEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.event = {
            $case: "publisherFailed",
            publisherFailed: Event_PublisherEvent_FailedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 65:
          if (tag !== 522) {
            break;
          }

          message.event = {
            $case: "exportCreated",
            exportCreated: Event_ExportEvent_ExportCreatedEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 66:
          if (tag !== 530) {
            break;
          }

          message.event = {
            $case: "exportReady",
            exportReady: Event_ExportEvent_ExportReadyEvent.decode(reader, reader.uint32()),
          };
          continue;
        case 67:
          if (tag !== 538) {
            break;
          }

          message.event = {
            $case: "exportFailed",
            exportFailed: Event_ExportEvent_ExportFailedEvent.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event>): Event {
    return Event.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event>): Event {
    const message = createBaseEvent();
    message.type = object.type ?? EventType.DEFAULT;
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.isError = object.isError ?? false;
    message.isWarning = object.isWarning ?? false;
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    if (
      object.resolvedStatus?.$case === "resolved" &&
      object.resolvedStatus?.resolved !== undefined &&
      object.resolvedStatus?.resolved !== null
    ) {
      message.resolvedStatus = { $case: "resolved", resolved: object.resolvedStatus.resolved };
    }
    if (
      object.resolvedStatus?.$case === "resolveIdentifier" &&
      object.resolvedStatus?.resolveIdentifier !== undefined &&
      object.resolvedStatus?.resolveIdentifier !== null
    ) {
      message.resolvedStatus = {
        $case: "resolveIdentifier",
        resolveIdentifier: object.resolvedStatus.resolveIdentifier,
      };
    }
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.device = (object.device !== undefined && object.device !== null)
      ? Event_Device.fromPartial(object.device)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    if (
      object.event?.$case === "assetCreated" &&
      object.event?.assetCreated !== undefined &&
      object.event?.assetCreated !== null
    ) {
      message.event = {
        $case: "assetCreated",
        assetCreated: Event_AssetEvent_AssetCreatedEvent.fromPartial(object.event.assetCreated),
      };
    }
    if (
      object.event?.$case === "assetUpdated" &&
      object.event?.assetUpdated !== undefined &&
      object.event?.assetUpdated !== null
    ) {
      message.event = {
        $case: "assetUpdated",
        assetUpdated: Event_AssetEvent_AssetUpdatedEvent.fromPartial(object.event.assetUpdated),
      };
    }
    if (
      object.event?.$case === "assetDeleted" &&
      object.event?.assetDeleted !== undefined &&
      object.event?.assetDeleted !== null
    ) {
      message.event = {
        $case: "assetDeleted",
        assetDeleted: Event_AssetEvent_AssetDeletedEvent.fromPartial(object.event.assetDeleted),
      };
    }
    if (
      object.event?.$case === "deviceCreated" &&
      object.event?.deviceCreated !== undefined &&
      object.event?.deviceCreated !== null
    ) {
      message.event = {
        $case: "deviceCreated",
        deviceCreated: Event_DeviceEvent_DeviceCreatedEvent.fromPartial(object.event.deviceCreated),
      };
    }
    if (
      object.event?.$case === "deviceUpdated" &&
      object.event?.deviceUpdated !== undefined &&
      object.event?.deviceUpdated !== null
    ) {
      message.event = {
        $case: "deviceUpdated",
        deviceUpdated: Event_DeviceEvent_DeviceUpdatedEvent.fromPartial(object.event.deviceUpdated),
      };
    }
    if (
      object.event?.$case === "deviceLocationUpdated" &&
      object.event?.deviceLocationUpdated !== undefined &&
      object.event?.deviceLocationUpdated !== null
    ) {
      message.event = {
        $case: "deviceLocationUpdated",
        deviceLocationUpdated: Event_DeviceEvent_DeviceLocationUpdatedEvent.fromPartial(
          object.event.deviceLocationUpdated,
        ),
      };
    }
    if (
      object.event?.$case === "deviceInstalled" &&
      object.event?.deviceInstalled !== undefined &&
      object.event?.deviceInstalled !== null
    ) {
      message.event = {
        $case: "deviceInstalled",
        deviceInstalled: Event_DeviceEvent_DeviceInstalledEvent.fromPartial(object.event.deviceInstalled),
      };
    }
    if (
      object.event?.$case === "messageReceived" &&
      object.event?.messageReceived !== undefined &&
      object.event?.messageReceived !== null
    ) {
      message.event = {
        $case: "messageReceived",
        messageReceived: Event_MessageEvent_MessageReceivedEvent.fromPartial(object.event.messageReceived),
      };
    }
    if (
      object.event?.$case === "messageBodyReceived" &&
      object.event?.messageBodyReceived !== undefined &&
      object.event?.messageBodyReceived !== null
    ) {
      message.event = {
        $case: "messageBodyReceived",
        messageBodyReceived: Event_MessageEvent_MessageBodyReceivedEvent.fromPartial(object.event.messageBodyReceived),
      };
    }
    if (
      object.event?.$case === "messageBodyParsed" &&
      object.event?.messageBodyParsed !== undefined &&
      object.event?.messageBodyParsed !== null
    ) {
      message.event = {
        $case: "messageBodyParsed",
        messageBodyParsed: Event_MessageEvent_MessageBodyParsedEvent.fromPartial(object.event.messageBodyParsed),
      };
    }
    if (
      object.event?.$case === "messageCannotBeParsed" &&
      object.event?.messageCannotBeParsed !== undefined &&
      object.event?.messageCannotBeParsed !== null
    ) {
      message.event = {
        $case: "messageCannotBeParsed",
        messageCannotBeParsed: Event_MessageEvent_MessageCannotBeParsedEvent.fromPartial(
          object.event.messageCannotBeParsed,
        ),
      };
    }
    if (
      object.event?.$case === "messageBodyParserCreated" &&
      object.event?.messageBodyParserCreated !== undefined &&
      object.event?.messageBodyParserCreated !== null
    ) {
      message.event = {
        $case: "messageBodyParserCreated",
        messageBodyParserCreated: Event_MessageBodyParserEvent_CreatedEvent.fromPartial(
          object.event.messageBodyParserCreated,
        ),
      };
    }
    if (
      object.event?.$case === "messageBodyParserUpdated" &&
      object.event?.messageBodyParserUpdated !== undefined &&
      object.event?.messageBodyParserUpdated !== null
    ) {
      message.event = {
        $case: "messageBodyParserUpdated",
        messageBodyParserUpdated: Event_MessageBodyParserEvent_UpdatedEvent.fromPartial(
          object.event.messageBodyParserUpdated,
        ),
      };
    }
    if (
      object.event?.$case === "messageBodyParserDeleted" &&
      object.event?.messageBodyParserDeleted !== undefined &&
      object.event?.messageBodyParserDeleted !== null
    ) {
      message.event = {
        $case: "messageBodyParserDeleted",
        messageBodyParserDeleted: Event_MessageBodyParserEvent_DeletedEvent.fromPartial(
          object.event.messageBodyParserDeleted,
        ),
      };
    }
    if (
      object.event?.$case === "alarmTriggered" &&
      object.event?.alarmTriggered !== undefined &&
      object.event?.alarmTriggered !== null
    ) {
      message.event = {
        $case: "alarmTriggered",
        alarmTriggered: Event_AlarmEvent_AlarmTriggeredEvent.fromPartial(object.event.alarmTriggered),
      };
    }
    if (
      object.event?.$case === "alarmCreated" &&
      object.event?.alarmCreated !== undefined &&
      object.event?.alarmCreated !== null
    ) {
      message.event = {
        $case: "alarmCreated",
        alarmCreated: Event_AlarmEvent_CreatedEvent.fromPartial(object.event.alarmCreated),
      };
    }
    if (
      object.event?.$case === "alarmUpdated" &&
      object.event?.alarmUpdated !== undefined &&
      object.event?.alarmUpdated !== null
    ) {
      message.event = {
        $case: "alarmUpdated",
        alarmUpdated: Event_AlarmEvent_UpdatedEvent.fromPartial(object.event.alarmUpdated),
      };
    }
    if (
      object.event?.$case === "alarmDeleted" &&
      object.event?.alarmDeleted !== undefined &&
      object.event?.alarmDeleted !== null
    ) {
      message.event = {
        $case: "alarmDeleted",
        alarmDeleted: Event_AlarmEvent_DeletedEvent.fromPartial(object.event.alarmDeleted),
      };
    }
    if (object.event?.$case === "assigned" && object.event?.assigned !== undefined && object.event?.assigned !== null) {
      message.event = {
        $case: "assigned",
        assigned: Event_AssignmentEvent_AssignedEvent.fromPartial(object.event.assigned),
      };
    }
    if (
      object.event?.$case === "unassigned" &&
      object.event?.unassigned !== undefined &&
      object.event?.unassigned !== null
    ) {
      message.event = {
        $case: "unassigned",
        unassigned: Event_AssignmentEvent_UnassignedEvent.fromPartial(object.event.unassigned),
      };
    }
    if (
      object.event?.$case === "userAdded" && object.event?.userAdded !== undefined && object.event?.userAdded !== null
    ) {
      message.event = {
        $case: "userAdded",
        userAdded: Event_UserEvent_UserAddedEvent.fromPartial(object.event.userAdded),
      };
    }
    if (
      object.event?.$case === "userRemoved" &&
      object.event?.userRemoved !== undefined &&
      object.event?.userRemoved !== null
    ) {
      message.event = {
        $case: "userRemoved",
        userRemoved: Event_UserEvent_UserRemovedEvent.fromPartial(object.event.userRemoved),
      };
    }
    if (
      object.event?.$case === "userInvited" &&
      object.event?.userInvited !== undefined &&
      object.event?.userInvited !== null
    ) {
      message.event = {
        $case: "userInvited",
        userInvited: Event_UserEvent_UserInvitedEvent.fromPartial(object.event.userInvited),
      };
    }
    if (
      object.event?.$case === "userAccessRequest" &&
      object.event?.userAccessRequest !== undefined &&
      object.event?.userAccessRequest !== null
    ) {
      message.event = {
        $case: "userAccessRequest",
        userAccessRequest: Event_UserEvent_UserAccessRequestEvent.fromPartial(object.event.userAccessRequest),
      };
    }
    if (
      object.event?.$case === "userValidationUpdated" &&
      object.event?.userValidationUpdated !== undefined &&
      object.event?.userValidationUpdated !== null
    ) {
      message.event = {
        $case: "userValidationUpdated",
        userValidationUpdated: Event_UserEvent_UserValidationUpdatedEvent.fromPartial(
          object.event.userValidationUpdated,
        ),
      };
    }
    if (object.event?.$case === "transfer" && object.event?.transfer !== undefined && object.event?.transfer !== null) {
      message.event = { $case: "transfer", transfer: Event_TransferEvent.fromPartial(object.event.transfer) };
    }
    if (
      object.event?.$case === "tokenExpiryWarning" &&
      object.event?.tokenExpiryWarning !== undefined &&
      object.event?.tokenExpiryWarning !== null
    ) {
      message.event = {
        $case: "tokenExpiryWarning",
        tokenExpiryWarning: Event_TokenEvent_TokenExpiryWarningEvent.fromPartial(object.event.tokenExpiryWarning),
      };
    }
    if (
      object.event?.$case === "tokenExpired" &&
      object.event?.tokenExpired !== undefined &&
      object.event?.tokenExpired !== null
    ) {
      message.event = {
        $case: "tokenExpired",
        tokenExpired: Event_TokenEvent_TokenExpiredEvent.fromPartial(object.event.tokenExpired),
      };
    }
    if (
      object.event?.$case === "tokenCreated" &&
      object.event?.tokenCreated !== undefined &&
      object.event?.tokenCreated !== null
    ) {
      message.event = {
        $case: "tokenCreated",
        tokenCreated: Event_TokenEvent_TokenCreatedEvent.fromPartial(object.event.tokenCreated),
      };
    }
    if (
      object.event?.$case === "tokenDeleted" &&
      object.event?.tokenDeleted !== undefined &&
      object.event?.tokenDeleted !== null
    ) {
      message.event = {
        $case: "tokenDeleted",
        tokenDeleted: Event_TokenEvent_TokenDeletedEvent.fromPartial(object.event.tokenDeleted),
      };
    }
    if (
      object.event?.$case === "organizationCreated" &&
      object.event?.organizationCreated !== undefined &&
      object.event?.organizationCreated !== null
    ) {
      message.event = {
        $case: "organizationCreated",
        organizationCreated: Event_OrganizationEvent_OrganizationCreatedEvent.fromPartial(
          object.event.organizationCreated,
        ),
      };
    }
    if (
      object.event?.$case === "organizationUpdated" &&
      object.event?.organizationUpdated !== undefined &&
      object.event?.organizationUpdated !== null
    ) {
      message.event = {
        $case: "organizationUpdated",
        organizationUpdated: Event_OrganizationEvent_OrganizationUpdatedEvent.fromPartial(
          object.event.organizationUpdated,
        ),
      };
    }
    if (
      object.event?.$case === "organizationDeleted" &&
      object.event?.organizationDeleted !== undefined &&
      object.event?.organizationDeleted !== null
    ) {
      message.event = {
        $case: "organizationDeleted",
        organizationDeleted: Event_OrganizationEvent_OrganizationDeletedEvent.fromPartial(
          object.event.organizationDeleted,
        ),
      };
    }
    if (
      object.event?.$case === "messageSummary" &&
      object.event?.messageSummary !== undefined &&
      object.event?.messageSummary !== null
    ) {
      message.event = {
        $case: "messageSummary",
        messageSummary: Event_OrganizationEvent_MessageSummaryEvent.fromPartial(object.event.messageSummary),
      };
    }
    if (
      object.event?.$case === "organizationEventConfigurationUpdated" &&
      object.event?.organizationEventConfigurationUpdated !== undefined &&
      object.event?.organizationEventConfigurationUpdated !== null
    ) {
      message.event = {
        $case: "organizationEventConfigurationUpdated",
        organizationEventConfigurationUpdated: Event_OrganizationEvent_EventConfigurationUpdated.fromPartial(
          object.event.organizationEventConfigurationUpdated,
        ),
      };
    }
    if (
      object.event?.$case === "publisherCreated" &&
      object.event?.publisherCreated !== undefined &&
      object.event?.publisherCreated !== null
    ) {
      message.event = {
        $case: "publisherCreated",
        publisherCreated: Event_PublisherEvent_CreatedEvent.fromPartial(object.event.publisherCreated),
      };
    }
    if (
      object.event?.$case === "publisherUpdated" &&
      object.event?.publisherUpdated !== undefined &&
      object.event?.publisherUpdated !== null
    ) {
      message.event = {
        $case: "publisherUpdated",
        publisherUpdated: Event_PublisherEvent_UpdatedEvent.fromPartial(object.event.publisherUpdated),
      };
    }
    if (
      object.event?.$case === "publisherDeleted" &&
      object.event?.publisherDeleted !== undefined &&
      object.event?.publisherDeleted !== null
    ) {
      message.event = {
        $case: "publisherDeleted",
        publisherDeleted: Event_PublisherEvent_DeletedEvent.fromPartial(object.event.publisherDeleted),
      };
    }
    if (
      object.event?.$case === "publisherAutoDisabled" &&
      object.event?.publisherAutoDisabled !== undefined &&
      object.event?.publisherAutoDisabled !== null
    ) {
      message.event = {
        $case: "publisherAutoDisabled",
        publisherAutoDisabled: Event_PublisherEvent_AutoDisabledEvent.fromPartial(object.event.publisherAutoDisabled),
      };
    }
    if (
      object.event?.$case === "publisherFailed" &&
      object.event?.publisherFailed !== undefined &&
      object.event?.publisherFailed !== null
    ) {
      message.event = {
        $case: "publisherFailed",
        publisherFailed: Event_PublisherEvent_FailedEvent.fromPartial(object.event.publisherFailed),
      };
    }
    if (
      object.event?.$case === "exportCreated" &&
      object.event?.exportCreated !== undefined &&
      object.event?.exportCreated !== null
    ) {
      message.event = {
        $case: "exportCreated",
        exportCreated: Event_ExportEvent_ExportCreatedEvent.fromPartial(object.event.exportCreated),
      };
    }
    if (
      object.event?.$case === "exportReady" &&
      object.event?.exportReady !== undefined &&
      object.event?.exportReady !== null
    ) {
      message.event = {
        $case: "exportReady",
        exportReady: Event_ExportEvent_ExportReadyEvent.fromPartial(object.event.exportReady),
      };
    }
    if (
      object.event?.$case === "exportFailed" &&
      object.event?.exportFailed !== undefined &&
      object.event?.exportFailed !== null
    ) {
      message.event = {
        $case: "exportFailed",
        exportFailed: Event_ExportEvent_ExportFailedEvent.fromPartial(object.event.exportFailed),
      };
    }
    return message;
  },
};

function createBaseEvent_Device(): Event_Device {
  return { number: "", name: "", identifier: "" };
}

export const Event_Device = {
  encode(message: Event_Device, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== "") {
      writer.uint32(10).string(message.number);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.identifier !== "") {
      writer.uint32(26).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_Device {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_Device();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_Device>): Event_Device {
    return Event_Device.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_Device>): Event_Device {
    const message = createBaseEvent_Device();
    message.number = object.number ?? "";
    message.name = object.name ?? "";
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseEvent_AssetEvent(): Event_AssetEvent {
  return {};
}

export const Event_AssetEvent = {
  encode(_: Event_AssetEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AssetEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AssetEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AssetEvent>): Event_AssetEvent {
    return Event_AssetEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_AssetEvent>): Event_AssetEvent {
    const message = createBaseEvent_AssetEvent();
    return message;
  },
};

function createBaseEvent_AssetEvent_AssetCreatedEvent(): Event_AssetEvent_AssetCreatedEvent {
  return {
    organization: "",
    assetIdentifier: "",
    asset: undefined,
    time: undefined,
    tags: [],
    title: "",
    description: "",
  };
}

export const Event_AssetEvent_AssetCreatedEvent = {
  encode(message: Event_AssetEvent_AssetCreatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.assetIdentifier !== "") {
      writer.uint32(18).string(message.assetIdentifier);
    }
    if (message.asset !== undefined) {
      Asset.encode(message.asset, writer.uint32(26).fork()).ldelim();
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(50).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AssetEvent_AssetCreatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AssetEvent_AssetCreatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assetIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.asset = Asset.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.title = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AssetEvent_AssetCreatedEvent>): Event_AssetEvent_AssetCreatedEvent {
    return Event_AssetEvent_AssetCreatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AssetEvent_AssetCreatedEvent>): Event_AssetEvent_AssetCreatedEvent {
    const message = createBaseEvent_AssetEvent_AssetCreatedEvent();
    message.organization = object.organization ?? "";
    message.assetIdentifier = object.assetIdentifier ?? "";
    message.asset = (object.asset !== undefined && object.asset !== null) ? Asset.fromPartial(object.asset) : undefined;
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseEvent_AssetEvent_AssetUpdatedEvent(): Event_AssetEvent_AssetUpdatedEvent {
  return {
    organization: "",
    updated: undefined,
    assetIdentifier: "",
    time: undefined,
    tags: [],
    title: "",
    description: "",
  };
}

export const Event_AssetEvent_AssetUpdatedEvent = {
  encode(message: Event_AssetEvent_AssetUpdatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.updated !== undefined) {
      Event_AssetEvent_AssetUpdatedEvent_Update.encode(message.updated, writer.uint32(18).fork()).ldelim();
    }
    if (message.assetIdentifier !== "") {
      writer.uint32(26).string(message.assetIdentifier);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(50).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AssetEvent_AssetUpdatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AssetEvent_AssetUpdatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updated = Event_AssetEvent_AssetUpdatedEvent_Update.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.assetIdentifier = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.title = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AssetEvent_AssetUpdatedEvent>): Event_AssetEvent_AssetUpdatedEvent {
    return Event_AssetEvent_AssetUpdatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AssetEvent_AssetUpdatedEvent>): Event_AssetEvent_AssetUpdatedEvent {
    const message = createBaseEvent_AssetEvent_AssetUpdatedEvent();
    message.organization = object.organization ?? "";
    message.updated = (object.updated !== undefined && object.updated !== null)
      ? Event_AssetEvent_AssetUpdatedEvent_Update.fromPartial(object.updated)
      : undefined;
    message.assetIdentifier = object.assetIdentifier ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseEvent_AssetEvent_AssetUpdatedEvent_Update(): Event_AssetEvent_AssetUpdatedEvent_Update {
  return {
    name: undefined,
    description: undefined,
    notes: undefined,
    timeZone: undefined,
    expectedTransmissionRate: undefined,
    type: undefined,
    metadata: undefined,
  };
}

export const Event_AssetEvent_AssetUpdatedEvent_Update = {
  encode(message: Event_AssetEvent_AssetUpdatedEvent_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.notes !== undefined) {
      writer.uint32(26).string(message.notes);
    }
    if (message.timeZone !== undefined) {
      writer.uint32(34).string(message.timeZone);
    }
    if (message.expectedTransmissionRate !== undefined) {
      writer.uint32(40).uint32(message.expectedTransmissionRate);
    }
    if (message.type !== undefined) {
      writer.uint32(48).int32(asset_TypeToNumber(message.type));
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AssetEvent_AssetUpdatedEvent_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AssetEvent_AssetUpdatedEvent_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.expectedTransmissionRate = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.type = asset_TypeFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AssetEvent_AssetUpdatedEvent_Update>): Event_AssetEvent_AssetUpdatedEvent_Update {
    return Event_AssetEvent_AssetUpdatedEvent_Update.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_AssetEvent_AssetUpdatedEvent_Update>,
  ): Event_AssetEvent_AssetUpdatedEvent_Update {
    const message = createBaseEvent_AssetEvent_AssetUpdatedEvent_Update();
    message.name = object.name ?? undefined;
    message.description = object.description ?? undefined;
    message.notes = object.notes ?? undefined;
    message.timeZone = object.timeZone ?? undefined;
    message.expectedTransmissionRate = object.expectedTransmissionRate ?? undefined;
    message.type = object.type ?? undefined;
    message.metadata = object.metadata ?? undefined;
    return message;
  },
};

function createBaseEvent_AssetEvent_AssetDeletedEvent(): Event_AssetEvent_AssetDeletedEvent {
  return { organization: "", deleted: "", time: undefined, tags: [], title: "", description: "" };
}

export const Event_AssetEvent_AssetDeletedEvent = {
  encode(message: Event_AssetEvent_AssetDeletedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deleted !== "") {
      writer.uint32(18).string(message.deleted);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AssetEvent_AssetDeletedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AssetEvent_AssetDeletedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deleted = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AssetEvent_AssetDeletedEvent>): Event_AssetEvent_AssetDeletedEvent {
    return Event_AssetEvent_AssetDeletedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AssetEvent_AssetDeletedEvent>): Event_AssetEvent_AssetDeletedEvent {
    const message = createBaseEvent_AssetEvent_AssetDeletedEvent();
    message.organization = object.organization ?? "";
    message.deleted = object.deleted ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseEvent_DeviceEvent(): Event_DeviceEvent {
  return {};
}

export const Event_DeviceEvent = {
  encode(_: Event_DeviceEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_DeviceEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_DeviceEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_DeviceEvent>): Event_DeviceEvent {
    return Event_DeviceEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_DeviceEvent>): Event_DeviceEvent {
    const message = createBaseEvent_DeviceEvent();
    return message;
  },
};

function createBaseEvent_DeviceEvent_DeviceCreatedEvent(): Event_DeviceEvent_DeviceCreatedEvent {
  return { organization: "", number: "", externalDeviceId: "", time: undefined, tags: [], title: "", description: "" };
}

export const Event_DeviceEvent_DeviceCreatedEvent = {
  encode(message: Event_DeviceEvent_DeviceCreatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.number !== "") {
      writer.uint32(18).string(message.number);
    }
    if (message.externalDeviceId !== "") {
      writer.uint32(162).string(message.externalDeviceId);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(50).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_DeviceEvent_DeviceCreatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_DeviceEvent_DeviceCreatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.number = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.externalDeviceId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.title = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_DeviceEvent_DeviceCreatedEvent>): Event_DeviceEvent_DeviceCreatedEvent {
    return Event_DeviceEvent_DeviceCreatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_DeviceEvent_DeviceCreatedEvent>): Event_DeviceEvent_DeviceCreatedEvent {
    const message = createBaseEvent_DeviceEvent_DeviceCreatedEvent();
    message.organization = object.organization ?? "";
    message.number = object.number ?? "";
    message.externalDeviceId = object.externalDeviceId ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseEvent_DeviceEvent_DeviceUpdatedEvent(): Event_DeviceEvent_DeviceUpdatedEvent {
  return {
    organization: "",
    number: "",
    externalDeviceId: "",
    displayName: undefined,
    updatedDisplayName: undefined,
    peripherals: {},
    notes: undefined,
    updatedNotes: undefined,
    deviceType: undefined,
    secureNotesUpdated: false,
    healthWarningPeriod: undefined,
    time: undefined,
    tags: [],
    title: "",
    description: "",
  };
}

export const Event_DeviceEvent_DeviceUpdatedEvent = {
  encode(message: Event_DeviceEvent_DeviceUpdatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.number !== "") {
      writer.uint32(18).string(message.number);
    }
    if (message.externalDeviceId !== "") {
      writer.uint32(162).string(message.externalDeviceId);
    }
    if (message.displayName !== undefined) {
      UpdateClearableString.encode(message.displayName, writer.uint32(82).fork()).ldelim();
    }
    if (message.updatedDisplayName !== undefined) {
      writer.uint32(178).string(message.updatedDisplayName);
    }
    Object.entries(message.peripherals).forEach(([key, value]) => {
      Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry.encode(
        { key: key as any, value },
        writer.uint32(122).fork(),
      ).ldelim();
    });
    if (message.notes !== undefined) {
      UpdateClearableString.encode(message.notes, writer.uint32(130).fork()).ldelim();
    }
    if (message.updatedNotes !== undefined) {
      writer.uint32(186).string(message.updatedNotes);
    }
    if (message.deviceType !== undefined) {
      writer.uint32(170).string(message.deviceType);
    }
    if (message.secureNotesUpdated === true) {
      writer.uint32(136).bool(message.secureNotesUpdated);
    }
    if (message.healthWarningPeriod !== undefined) {
      Duration.encode(message.healthWarningPeriod, writer.uint32(154).fork()).ldelim();
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(50).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_DeviceEvent_DeviceUpdatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_DeviceEvent_DeviceUpdatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.number = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.externalDeviceId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.displayName = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.updatedDisplayName = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          const entry15 = Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry.decode(reader, reader.uint32());
          if (entry15.value !== undefined) {
            message.peripherals[entry15.key] = entry15.value;
          }
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.notes = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.updatedNotes = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.deviceType = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.secureNotesUpdated = reader.bool();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.healthWarningPeriod = Duration.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.title = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_DeviceEvent_DeviceUpdatedEvent>): Event_DeviceEvent_DeviceUpdatedEvent {
    return Event_DeviceEvent_DeviceUpdatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_DeviceEvent_DeviceUpdatedEvent>): Event_DeviceEvent_DeviceUpdatedEvent {
    const message = createBaseEvent_DeviceEvent_DeviceUpdatedEvent();
    message.organization = object.organization ?? "";
    message.number = object.number ?? "";
    message.externalDeviceId = object.externalDeviceId ?? "";
    message.displayName = (object.displayName !== undefined && object.displayName !== null)
      ? UpdateClearableString.fromPartial(object.displayName)
      : undefined;
    message.updatedDisplayName = object.updatedDisplayName ?? undefined;
    message.peripherals = Object.entries(object.peripherals ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.notes = (object.notes !== undefined && object.notes !== null)
      ? UpdateClearableString.fromPartial(object.notes)
      : undefined;
    message.updatedNotes = object.updatedNotes ?? undefined;
    message.deviceType = object.deviceType ?? undefined;
    message.secureNotesUpdated = object.secureNotesUpdated ?? false;
    message.healthWarningPeriod = (object.healthWarningPeriod !== undefined && object.healthWarningPeriod !== null)
      ? Duration.fromPartial(object.healthWarningPeriod)
      : undefined;
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseEvent_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry(): Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry {
  return { key: "", value: "" };
}

export const Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry = {
  encode(
    message: Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry>,
  ): Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry {
    return Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry>,
  ): Event_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry {
    const message = createBaseEvent_DeviceEvent_DeviceUpdatedEvent_PeripheralsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseEvent_DeviceEvent_DeviceLocationUpdatedEvent(): Event_DeviceEvent_DeviceLocationUpdatedEvent {
  return {
    organization: "",
    number: "",
    externalDeviceId: "",
    location: undefined,
    updatedAt: undefined,
    time: undefined,
    tags: [],
    title: "",
    description: "",
  };
}

export const Event_DeviceEvent_DeviceLocationUpdatedEvent = {
  encode(message: Event_DeviceEvent_DeviceLocationUpdatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.number !== "") {
      writer.uint32(18).string(message.number);
    }
    if (message.externalDeviceId !== "") {
      writer.uint32(66).string(message.externalDeviceId);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(26).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(74).fork()).ldelim();
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(50).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_DeviceEvent_DeviceLocationUpdatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_DeviceEvent_DeviceLocationUpdatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.number = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.externalDeviceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.title = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_DeviceEvent_DeviceLocationUpdatedEvent>,
  ): Event_DeviceEvent_DeviceLocationUpdatedEvent {
    return Event_DeviceEvent_DeviceLocationUpdatedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_DeviceEvent_DeviceLocationUpdatedEvent>,
  ): Event_DeviceEvent_DeviceLocationUpdatedEvent {
    const message = createBaseEvent_DeviceEvent_DeviceLocationUpdatedEvent();
    message.organization = object.organization ?? "";
    message.number = object.number ?? "";
    message.externalDeviceId = object.externalDeviceId ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.updatedAt = (object.updatedAt !== undefined && object.updatedAt !== null)
      ? Timestamp.fromPartial(object.updatedAt)
      : undefined;
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseEvent_DeviceEvent_DeviceInstalledEvent(): Event_DeviceEvent_DeviceInstalledEvent {
  return { organization: "", number: "", externalDeviceId: "", time: undefined, tags: [], title: "", description: "" };
}

export const Event_DeviceEvent_DeviceInstalledEvent = {
  encode(message: Event_DeviceEvent_DeviceInstalledEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.number !== "") {
      writer.uint32(18).string(message.number);
    }
    if (message.externalDeviceId !== "") {
      writer.uint32(90).string(message.externalDeviceId);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(66).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(74).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_DeviceEvent_DeviceInstalledEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_DeviceEvent_DeviceInstalledEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.number = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.externalDeviceId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.title = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_DeviceEvent_DeviceInstalledEvent>): Event_DeviceEvent_DeviceInstalledEvent {
    return Event_DeviceEvent_DeviceInstalledEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_DeviceEvent_DeviceInstalledEvent>): Event_DeviceEvent_DeviceInstalledEvent {
    const message = createBaseEvent_DeviceEvent_DeviceInstalledEvent();
    message.organization = object.organization ?? "";
    message.number = object.number ?? "";
    message.externalDeviceId = object.externalDeviceId ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseEvent_MessageEvent(): Event_MessageEvent {
  return {};
}

export const Event_MessageEvent = {
  encode(_: Event_MessageEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_MessageEvent>): Event_MessageEvent {
    return Event_MessageEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_MessageEvent>): Event_MessageEvent {
    const message = createBaseEvent_MessageEvent();
    return message;
  },
};

function createBaseEvent_MessageEvent_MessageReceivedEvent(): Event_MessageEvent_MessageReceivedEvent {
  return {
    organization: "",
    modemNumber: "",
    deviceNumber: "",
    deviceExternalDeviceId: "",
    message: undefined,
    tags: [],
    title: "",
    description: "",
    time: undefined,
  };
}

export const Event_MessageEvent_MessageReceivedEvent = {
  encode(message: Event_MessageEvent_MessageReceivedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.modemNumber !== "") {
      writer.uint32(18).string(message.modemNumber);
    }
    if (message.deviceNumber !== "") {
      writer.uint32(74).string(message.deviceNumber);
    }
    if (message.deviceExternalDeviceId !== "") {
      writer.uint32(66).string(message.deviceExternalDeviceId);
    }
    if (message.message !== undefined) {
      ModemMessage.encode(message.message, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageEvent_MessageReceivedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageEvent_MessageReceivedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deviceNumber = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.deviceExternalDeviceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = ModemMessage.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_MessageEvent_MessageReceivedEvent>): Event_MessageEvent_MessageReceivedEvent {
    return Event_MessageEvent_MessageReceivedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_MessageEvent_MessageReceivedEvent>): Event_MessageEvent_MessageReceivedEvent {
    const message = createBaseEvent_MessageEvent_MessageReceivedEvent();
    message.organization = object.organization ?? "";
    message.modemNumber = object.modemNumber ?? "";
    message.deviceNumber = object.deviceNumber ?? "";
    message.deviceExternalDeviceId = object.deviceExternalDeviceId ?? "";
    message.message = (object.message !== undefined && object.message !== null)
      ? ModemMessage.fromPartial(object.message)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_MessageEvent_MessageBodyReceivedEvent(): Event_MessageEvent_MessageBodyReceivedEvent {
  return {
    organization: "",
    deviceNumber: "",
    deviceExternalDeviceId: "",
    messageId: 0,
    sentAt: undefined,
    body: undefined,
    tags: [],
    title: "",
    description: "",
    time: undefined,
  };
}

export const Event_MessageEvent_MessageBodyReceivedEvent = {
  encode(message: Event_MessageEvent_MessageBodyReceivedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deviceNumber !== "") {
      writer.uint32(18).string(message.deviceNumber);
    }
    if (message.deviceExternalDeviceId !== "") {
      writer.uint32(26).string(message.deviceExternalDeviceId);
    }
    if (message.messageId !== 0) {
      writer.uint32(32).uint64(message.messageId);
    }
    if (message.sentAt !== undefined) {
      Timestamp.encode(message.sentAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.body !== undefined) {
      BytesOrHex.encode(message.body, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(66).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(74).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageEvent_MessageBodyReceivedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageEvent_MessageBodyReceivedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceExternalDeviceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.messageId = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sentAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.body = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.title = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.description = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_MessageEvent_MessageBodyReceivedEvent>): Event_MessageEvent_MessageBodyReceivedEvent {
    return Event_MessageEvent_MessageBodyReceivedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_MessageEvent_MessageBodyReceivedEvent>,
  ): Event_MessageEvent_MessageBodyReceivedEvent {
    const message = createBaseEvent_MessageEvent_MessageBodyReceivedEvent();
    message.organization = object.organization ?? "";
    message.deviceNumber = object.deviceNumber ?? "";
    message.deviceExternalDeviceId = object.deviceExternalDeviceId ?? "";
    message.messageId = object.messageId ?? 0;
    message.sentAt = (object.sentAt !== undefined && object.sentAt !== null)
      ? Timestamp.fromPartial(object.sentAt)
      : undefined;
    message.body = (object.body !== undefined && object.body !== null)
      ? BytesOrHex.fromPartial(object.body)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_MessageEvent_MessageBodyParsedEvent(): Event_MessageEvent_MessageBodyParsedEvent {
  return {
    organization: "",
    deviceNumber: "",
    deviceExternalDeviceId: "",
    messageId: 0,
    sentAt: undefined,
    parserIdentifier: "",
    parserName: "",
    parsedMessage: undefined,
    tags: [],
    title: "",
    description: "",
    time: undefined,
  };
}

export const Event_MessageEvent_MessageBodyParsedEvent = {
  encode(message: Event_MessageEvent_MessageBodyParsedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deviceNumber !== "") {
      writer.uint32(18).string(message.deviceNumber);
    }
    if (message.deviceExternalDeviceId !== "") {
      writer.uint32(26).string(message.deviceExternalDeviceId);
    }
    if (message.messageId !== 0) {
      writer.uint32(32).uint64(message.messageId);
    }
    if (message.sentAt !== undefined) {
      Timestamp.encode(message.sentAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.parserIdentifier !== "") {
      writer.uint32(50).string(message.parserIdentifier);
    }
    if (message.parserName !== "") {
      writer.uint32(58).string(message.parserName);
    }
    if (message.parsedMessage !== undefined) {
      Struct.encode(Struct.wrap(message.parsedMessage), writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(82).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(90).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageEvent_MessageBodyParsedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageEvent_MessageBodyParsedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceExternalDeviceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.messageId = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sentAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.parserIdentifier = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.parserName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.parsedMessage = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.title = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.description = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_MessageEvent_MessageBodyParsedEvent>): Event_MessageEvent_MessageBodyParsedEvent {
    return Event_MessageEvent_MessageBodyParsedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_MessageEvent_MessageBodyParsedEvent>,
  ): Event_MessageEvent_MessageBodyParsedEvent {
    const message = createBaseEvent_MessageEvent_MessageBodyParsedEvent();
    message.organization = object.organization ?? "";
    message.deviceNumber = object.deviceNumber ?? "";
    message.deviceExternalDeviceId = object.deviceExternalDeviceId ?? "";
    message.messageId = object.messageId ?? 0;
    message.sentAt = (object.sentAt !== undefined && object.sentAt !== null)
      ? Timestamp.fromPartial(object.sentAt)
      : undefined;
    message.parserIdentifier = object.parserIdentifier ?? "";
    message.parserName = object.parserName ?? "";
    message.parsedMessage = object.parsedMessage ?? undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_MessageEvent_MessageCannotBeParsedEvent(): Event_MessageEvent_MessageCannotBeParsedEvent {
  return {
    organization: "",
    deviceNumber: "",
    deviceExternalDeviceId: "",
    messageId: 0,
    time: undefined,
    reason: "",
    tags: [],
    title: "",
    description: "",
    healthLevel: undefined,
    resolvedStatus: undefined,
  };
}

export const Event_MessageEvent_MessageCannotBeParsedEvent = {
  encode(message: Event_MessageEvent_MessageCannotBeParsedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deviceNumber !== "") {
      writer.uint32(18).string(message.deviceNumber);
    }
    if (message.deviceExternalDeviceId !== "") {
      writer.uint32(74).string(message.deviceExternalDeviceId);
    }
    if (message.messageId !== 0) {
      writer.uint32(24).uint64(message.messageId);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(34).fork()).ldelim();
    }
    if (message.reason !== "") {
      writer.uint32(42).string(message.reason);
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(58).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(66).string(message.description);
    }
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(82).fork()).ldelim();
    }
    switch (message.resolvedStatus?.$case) {
      case "resolved":
        writer.uint32(88).bool(message.resolvedStatus.resolved);
        break;
      case "resolveIdentifier":
        writer.uint32(98).string(message.resolvedStatus.resolveIdentifier);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageEvent_MessageCannotBeParsedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageEvent_MessageCannotBeParsedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceNumber = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deviceExternalDeviceId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.messageId = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.reason = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.title = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.description = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.resolvedStatus = { $case: "resolved", resolved: reader.bool() };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.resolvedStatus = { $case: "resolveIdentifier", resolveIdentifier: reader.string() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_MessageEvent_MessageCannotBeParsedEvent>,
  ): Event_MessageEvent_MessageCannotBeParsedEvent {
    return Event_MessageEvent_MessageCannotBeParsedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_MessageEvent_MessageCannotBeParsedEvent>,
  ): Event_MessageEvent_MessageCannotBeParsedEvent {
    const message = createBaseEvent_MessageEvent_MessageCannotBeParsedEvent();
    message.organization = object.organization ?? "";
    message.deviceNumber = object.deviceNumber ?? "";
    message.deviceExternalDeviceId = object.deviceExternalDeviceId ?? "";
    message.messageId = object.messageId ?? 0;
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.reason = object.reason ?? "";
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    if (
      object.resolvedStatus?.$case === "resolved" &&
      object.resolvedStatus?.resolved !== undefined &&
      object.resolvedStatus?.resolved !== null
    ) {
      message.resolvedStatus = { $case: "resolved", resolved: object.resolvedStatus.resolved };
    }
    if (
      object.resolvedStatus?.$case === "resolveIdentifier" &&
      object.resolvedStatus?.resolveIdentifier !== undefined &&
      object.resolvedStatus?.resolveIdentifier !== null
    ) {
      message.resolvedStatus = {
        $case: "resolveIdentifier",
        resolveIdentifier: object.resolvedStatus.resolveIdentifier,
      };
    }
    return message;
  },
};

function createBaseEvent_MessageBodyParserEvent(): Event_MessageBodyParserEvent {
  return {};
}

export const Event_MessageBodyParserEvent = {
  encode(_: Event_MessageBodyParserEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageBodyParserEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageBodyParserEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_MessageBodyParserEvent>): Event_MessageBodyParserEvent {
    return Event_MessageBodyParserEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_MessageBodyParserEvent>): Event_MessageBodyParserEvent {
    const message = createBaseEvent_MessageBodyParserEvent();
    return message;
  },
};

function createBaseEvent_MessageBodyParserEvent_CreatedEvent(): Event_MessageBodyParserEvent_CreatedEvent {
  return { organization: "", created: undefined, title: "", description: "", time: undefined };
}

export const Event_MessageBodyParserEvent_CreatedEvent = {
  encode(message: Event_MessageBodyParserEvent_CreatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.created !== undefined) {
      ModemMessageBodyParser.encode(message.created, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageBodyParserEvent_CreatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageBodyParserEvent_CreatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = ModemMessageBodyParser.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_MessageBodyParserEvent_CreatedEvent>): Event_MessageBodyParserEvent_CreatedEvent {
    return Event_MessageBodyParserEvent_CreatedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_MessageBodyParserEvent_CreatedEvent>,
  ): Event_MessageBodyParserEvent_CreatedEvent {
    const message = createBaseEvent_MessageBodyParserEvent_CreatedEvent();
    message.organization = object.organization ?? "";
    message.created = (object.created !== undefined && object.created !== null)
      ? ModemMessageBodyParser.fromPartial(object.created)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_MessageBodyParserEvent_UpdatedEvent(): Event_MessageBodyParserEvent_UpdatedEvent {
  return {
    organization: "",
    updatedName: undefined,
    name: undefined,
    updatedContentKsy: undefined,
    contentKsy: undefined,
    updatedSimpleParser: undefined,
    updatedAvailableToChildOrganizations: undefined,
    availableToChildOrganizations: undefined,
    title: "",
    description: "",
    time: undefined,
  };
}

export const Event_MessageBodyParserEvent_UpdatedEvent = {
  encode(message: Event_MessageBodyParserEvent_UpdatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.updatedName !== undefined) {
      UpdateClearableString.encode(message.updatedName, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(74).string(message.name);
    }
    if (message.updatedContentKsy !== undefined) {
      UpdateClearableString.encode(message.updatedContentKsy, writer.uint32(26).fork()).ldelim();
    }
    if (message.contentKsy !== undefined) {
      writer.uint32(82).string(message.contentKsy);
    }
    if (message.updatedSimpleParser !== undefined) {
      SimpleModemMessageBodyParser.encode(message.updatedSimpleParser, writer.uint32(34).fork()).ldelim();
    }
    if (message.updatedAvailableToChildOrganizations !== undefined) {
      Filter_ChildOrganizations_Update.encode(message.updatedAvailableToChildOrganizations, writer.uint32(42).fork())
        .ldelim();
    }
    if (message.availableToChildOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.availableToChildOrganizations, writer.uint32(90).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(50).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageBodyParserEvent_UpdatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageBodyParserEvent_UpdatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updatedName = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updatedContentKsy = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.contentKsy = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updatedSimpleParser = SimpleModemMessageBodyParser.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updatedAvailableToChildOrganizations = Filter_ChildOrganizations_Update.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.availableToChildOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.title = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_MessageBodyParserEvent_UpdatedEvent>): Event_MessageBodyParserEvent_UpdatedEvent {
    return Event_MessageBodyParserEvent_UpdatedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_MessageBodyParserEvent_UpdatedEvent>,
  ): Event_MessageBodyParserEvent_UpdatedEvent {
    const message = createBaseEvent_MessageBodyParserEvent_UpdatedEvent();
    message.organization = object.organization ?? "";
    message.updatedName = (object.updatedName !== undefined && object.updatedName !== null)
      ? UpdateClearableString.fromPartial(object.updatedName)
      : undefined;
    message.name = object.name ?? undefined;
    message.updatedContentKsy = (object.updatedContentKsy !== undefined && object.updatedContentKsy !== null)
      ? UpdateClearableString.fromPartial(object.updatedContentKsy)
      : undefined;
    message.contentKsy = object.contentKsy ?? undefined;
    message.updatedSimpleParser = (object.updatedSimpleParser !== undefined && object.updatedSimpleParser !== null)
      ? SimpleModemMessageBodyParser.fromPartial(object.updatedSimpleParser)
      : undefined;
    message.updatedAvailableToChildOrganizations =
      (object.updatedAvailableToChildOrganizations !== undefined &&
          object.updatedAvailableToChildOrganizations !== null)
        ? Filter_ChildOrganizations_Update.fromPartial(object.updatedAvailableToChildOrganizations)
        : undefined;
    message.availableToChildOrganizations =
      (object.availableToChildOrganizations !== undefined && object.availableToChildOrganizations !== null)
        ? Filter_ChildOrganizations.fromPartial(object.availableToChildOrganizations)
        : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_MessageBodyParserEvent_DeletedEvent(): Event_MessageBodyParserEvent_DeletedEvent {
  return { organization: "", deleted: "", title: "", description: "", time: undefined };
}

export const Event_MessageBodyParserEvent_DeletedEvent = {
  encode(message: Event_MessageBodyParserEvent_DeletedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deleted !== "") {
      writer.uint32(18).string(message.deleted);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_MessageBodyParserEvent_DeletedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_MessageBodyParserEvent_DeletedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deleted = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_MessageBodyParserEvent_DeletedEvent>): Event_MessageBodyParserEvent_DeletedEvent {
    return Event_MessageBodyParserEvent_DeletedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_MessageBodyParserEvent_DeletedEvent>,
  ): Event_MessageBodyParserEvent_DeletedEvent {
    const message = createBaseEvent_MessageBodyParserEvent_DeletedEvent();
    message.organization = object.organization ?? "";
    message.deleted = object.deleted ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_AlarmEvent(): Event_AlarmEvent {
  return {};
}

export const Event_AlarmEvent = {
  encode(_: Event_AlarmEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AlarmEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AlarmEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AlarmEvent>): Event_AlarmEvent {
    return Event_AlarmEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_AlarmEvent>): Event_AlarmEvent {
    const message = createBaseEvent_AlarmEvent();
    return message;
  },
};

function createBaseEvent_AlarmEvent_AlarmTriggeredEvent(): Event_AlarmEvent_AlarmTriggeredEvent {
  return {
    organization: "",
    deviceNumber: "",
    deviceExternalDeviceId: "",
    messageId: 0,
    alarmIdentifier: "",
    alarmDescription: "",
    errorMessages: {},
    healthLevel: undefined,
    tags: [],
    title: "",
    description: "",
    time: undefined,
    resolvedAt: undefined,
    healthLevelAfterResolved: undefined,
    healthLevelAfterResolvedUntil: undefined,
  };
}

export const Event_AlarmEvent_AlarmTriggeredEvent = {
  encode(message: Event_AlarmEvent_AlarmTriggeredEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deviceNumber !== "") {
      writer.uint32(18).string(message.deviceNumber);
    }
    if (message.deviceExternalDeviceId !== "") {
      writer.uint32(26).string(message.deviceExternalDeviceId);
    }
    if (message.messageId !== 0) {
      writer.uint32(32).uint64(message.messageId);
    }
    if (message.alarmIdentifier !== "") {
      writer.uint32(42).string(message.alarmIdentifier);
    }
    if (message.alarmDescription !== "") {
      writer.uint32(50).string(message.alarmDescription);
    }
    Object.entries(message.errorMessages).forEach(([key, value]) => {
      Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry.encode(
        { key: key as any, value },
        writer.uint32(58).fork(),
      ).ldelim();
    });
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(74).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(82).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(90).fork()).ldelim();
    }
    if (message.resolvedAt !== undefined) {
      Timestamp.encode(message.resolvedAt, writer.uint32(106).fork()).ldelim();
    }
    if (message.healthLevelAfterResolved !== undefined) {
      HealthLevel.encode(message.healthLevelAfterResolved, writer.uint32(114).fork()).ldelim();
    }
    if (message.healthLevelAfterResolvedUntil !== undefined) {
      Timestamp.encode(message.healthLevelAfterResolvedUntil, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AlarmEvent_AlarmTriggeredEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AlarmEvent_AlarmTriggeredEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceExternalDeviceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.messageId = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.alarmIdentifier = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.alarmDescription = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.errorMessages[entry7.key] = entry7.value;
          }
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.title = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.description = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.resolvedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.healthLevelAfterResolved = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.healthLevelAfterResolvedUntil = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AlarmEvent_AlarmTriggeredEvent>): Event_AlarmEvent_AlarmTriggeredEvent {
    return Event_AlarmEvent_AlarmTriggeredEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AlarmEvent_AlarmTriggeredEvent>): Event_AlarmEvent_AlarmTriggeredEvent {
    const message = createBaseEvent_AlarmEvent_AlarmTriggeredEvent();
    message.organization = object.organization ?? "";
    message.deviceNumber = object.deviceNumber ?? "";
    message.deviceExternalDeviceId = object.deviceExternalDeviceId ?? "";
    message.messageId = object.messageId ?? 0;
    message.alarmIdentifier = object.alarmIdentifier ?? "";
    message.alarmDescription = object.alarmDescription ?? "";
    message.errorMessages = Object.entries(object.errorMessages ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.resolvedAt = (object.resolvedAt !== undefined && object.resolvedAt !== null)
      ? Timestamp.fromPartial(object.resolvedAt)
      : undefined;
    message.healthLevelAfterResolved =
      (object.healthLevelAfterResolved !== undefined && object.healthLevelAfterResolved !== null)
        ? HealthLevel.fromPartial(object.healthLevelAfterResolved)
        : undefined;
    message.healthLevelAfterResolvedUntil =
      (object.healthLevelAfterResolvedUntil !== undefined && object.healthLevelAfterResolvedUntil !== null)
        ? Timestamp.fromPartial(object.healthLevelAfterResolvedUntil)
        : undefined;
    return message;
  },
};

function createBaseEvent_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry(): Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry {
  return { key: "", value: "" };
}

export const Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry = {
  encode(
    message: Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry>,
  ): Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry {
    return Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry>,
  ): Event_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry {
    const message = createBaseEvent_AlarmEvent_AlarmTriggeredEvent_ErrorMessagesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseEvent_AlarmEvent_CreatedEvent(): Event_AlarmEvent_CreatedEvent {
  return { organization: "", created: undefined, title: "", description: "", time: undefined };
}

export const Event_AlarmEvent_CreatedEvent = {
  encode(message: Event_AlarmEvent_CreatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.created !== undefined) {
      ModemAlarm.encode(message.created, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AlarmEvent_CreatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AlarmEvent_CreatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = ModemAlarm.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AlarmEvent_CreatedEvent>): Event_AlarmEvent_CreatedEvent {
    return Event_AlarmEvent_CreatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AlarmEvent_CreatedEvent>): Event_AlarmEvent_CreatedEvent {
    const message = createBaseEvent_AlarmEvent_CreatedEvent();
    message.organization = object.organization ?? "";
    message.created = (object.created !== undefined && object.created !== null)
      ? ModemAlarm.fromPartial(object.created)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_AlarmEvent_UpdatedEvent(): Event_AlarmEvent_UpdatedEvent {
  return { organization: "", updated: undefined, alarmIdentifier: "", title: "", description: "", time: undefined };
}

export const Event_AlarmEvent_UpdatedEvent = {
  encode(message: Event_AlarmEvent_UpdatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.updated !== undefined) {
      Event_AlarmEvent_UpdatedEvent_Update.encode(message.updated, writer.uint32(18).fork()).ldelim();
    }
    if (message.alarmIdentifier !== "") {
      writer.uint32(26).string(message.alarmIdentifier);
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AlarmEvent_UpdatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AlarmEvent_UpdatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updated = Event_AlarmEvent_UpdatedEvent_Update.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.alarmIdentifier = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AlarmEvent_UpdatedEvent>): Event_AlarmEvent_UpdatedEvent {
    return Event_AlarmEvent_UpdatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AlarmEvent_UpdatedEvent>): Event_AlarmEvent_UpdatedEvent {
    const message = createBaseEvent_AlarmEvent_UpdatedEvent();
    message.organization = object.organization ?? "";
    message.updated = (object.updated !== undefined && object.updated !== null)
      ? Event_AlarmEvent_UpdatedEvent_Update.fromPartial(object.updated)
      : undefined;
    message.alarmIdentifier = object.alarmIdentifier ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_AlarmEvent_UpdatedEvent_Update(): Event_AlarmEvent_UpdatedEvent_Update {
  return {
    deprecatedDescription: undefined,
    description: undefined,
    triggerCondition: "",
    checks: undefined,
    updateDefaultHealthLevel: undefined,
    updatedDefaultHealthLevel: undefined,
    updateHealthLevelAfterResolved: undefined,
  };
}

export const Event_AlarmEvent_UpdatedEvent_Update = {
  encode(message: Event_AlarmEvent_UpdatedEvent_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deprecatedDescription !== undefined) {
      UpdateClearableString.encode(message.deprecatedDescription, writer.uint32(10).fork()).ldelim();
    }
    if (message.description !== undefined) {
      writer.uint32(58).string(message.description);
    }
    if (message.triggerCondition !== "") {
      writer.uint32(18).string(message.triggerCondition);
    }
    if (message.checks !== undefined) {
      Struct.encode(Struct.wrap(message.checks), writer.uint32(34).fork()).ldelim();
    }
    if (message.updateDefaultHealthLevel !== undefined) {
      UpdateClearableString.encode(message.updateDefaultHealthLevel, writer.uint32(42).fork()).ldelim();
    }
    if (message.updatedDefaultHealthLevel !== undefined) {
      writer.uint32(66).string(message.updatedDefaultHealthLevel);
    }
    if (message.updateHealthLevelAfterResolved !== undefined) {
      ModemAlarm_HealthLevelAfterResolved.encode(message.updateHealthLevelAfterResolved, writer.uint32(50).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AlarmEvent_UpdatedEvent_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AlarmEvent_UpdatedEvent_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deprecatedDescription = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.triggerCondition = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.checks = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updateDefaultHealthLevel = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.updatedDefaultHealthLevel = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updateHealthLevelAfterResolved = ModemAlarm_HealthLevelAfterResolved.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AlarmEvent_UpdatedEvent_Update>): Event_AlarmEvent_UpdatedEvent_Update {
    return Event_AlarmEvent_UpdatedEvent_Update.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AlarmEvent_UpdatedEvent_Update>): Event_AlarmEvent_UpdatedEvent_Update {
    const message = createBaseEvent_AlarmEvent_UpdatedEvent_Update();
    message.deprecatedDescription =
      (object.deprecatedDescription !== undefined && object.deprecatedDescription !== null)
        ? UpdateClearableString.fromPartial(object.deprecatedDescription)
        : undefined;
    message.description = object.description ?? undefined;
    message.triggerCondition = object.triggerCondition ?? "";
    message.checks = object.checks ?? undefined;
    message.updateDefaultHealthLevel =
      (object.updateDefaultHealthLevel !== undefined && object.updateDefaultHealthLevel !== null)
        ? UpdateClearableString.fromPartial(object.updateDefaultHealthLevel)
        : undefined;
    message.updatedDefaultHealthLevel = object.updatedDefaultHealthLevel ?? undefined;
    message.updateHealthLevelAfterResolved =
      (object.updateHealthLevelAfterResolved !== undefined && object.updateHealthLevelAfterResolved !== null)
        ? ModemAlarm_HealthLevelAfterResolved.fromPartial(object.updateHealthLevelAfterResolved)
        : undefined;
    return message;
  },
};

function createBaseEvent_AlarmEvent_DeletedEvent(): Event_AlarmEvent_DeletedEvent {
  return { organization: "", deleted: "", title: "", description: "", time: undefined };
}

export const Event_AlarmEvent_DeletedEvent = {
  encode(message: Event_AlarmEvent_DeletedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deleted !== "") {
      writer.uint32(18).string(message.deleted);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AlarmEvent_DeletedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AlarmEvent_DeletedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deleted = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AlarmEvent_DeletedEvent>): Event_AlarmEvent_DeletedEvent {
    return Event_AlarmEvent_DeletedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AlarmEvent_DeletedEvent>): Event_AlarmEvent_DeletedEvent {
    const message = createBaseEvent_AlarmEvent_DeletedEvent();
    message.organization = object.organization ?? "";
    message.deleted = object.deleted ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_TransferEvent(): Event_TransferEvent {
  return { organization: "", time: undefined, tags: [], title: "", description: "", transfer: undefined };
}

export const Event_TransferEvent = {
  encode(message: Event_TransferEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.transfer !== undefined) {
      Transfer.encode(message.transfer, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_TransferEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_TransferEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.transfer = Transfer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_TransferEvent>): Event_TransferEvent {
    return Event_TransferEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_TransferEvent>): Event_TransferEvent {
    const message = createBaseEvent_TransferEvent();
    message.organization = object.organization ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.transfer = (object.transfer !== undefined && object.transfer !== null)
      ? Transfer.fromPartial(object.transfer)
      : undefined;
    return message;
  },
};

function createBaseEvent_AssignmentEvent(): Event_AssignmentEvent {
  return {};
}

export const Event_AssignmentEvent = {
  encode(_: Event_AssignmentEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AssignmentEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AssignmentEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AssignmentEvent>): Event_AssignmentEvent {
    return Event_AssignmentEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_AssignmentEvent>): Event_AssignmentEvent {
    const message = createBaseEvent_AssignmentEvent();
    return message;
  },
};

function createBaseEvent_AssignmentEvent_AssignedEvent(): Event_AssignmentEvent_AssignedEvent {
  return { organization: "", created: undefined, tags: [], title: "", description: "", time: undefined };
}

export const Event_AssignmentEvent_AssignedEvent = {
  encode(message: Event_AssignmentEvent_AssignedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.created !== undefined) {
      Assignment.encode(message.created, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AssignmentEvent_AssignedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AssignmentEvent_AssignedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = Assignment.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AssignmentEvent_AssignedEvent>): Event_AssignmentEvent_AssignedEvent {
    return Event_AssignmentEvent_AssignedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AssignmentEvent_AssignedEvent>): Event_AssignmentEvent_AssignedEvent {
    const message = createBaseEvent_AssignmentEvent_AssignedEvent();
    message.organization = object.organization ?? "";
    message.created = (object.created !== undefined && object.created !== null)
      ? Assignment.fromPartial(object.created)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_AssignmentEvent_UnassignedEvent(): Event_AssignmentEvent_UnassignedEvent {
  return { organization: "", removed: undefined, tags: [], title: "", description: "", time: undefined };
}

export const Event_AssignmentEvent_UnassignedEvent = {
  encode(message: Event_AssignmentEvent_UnassignedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.removed !== undefined) {
      Assignment.encode(message.removed, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_AssignmentEvent_UnassignedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_AssignmentEvent_UnassignedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.removed = Assignment.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_AssignmentEvent_UnassignedEvent>): Event_AssignmentEvent_UnassignedEvent {
    return Event_AssignmentEvent_UnassignedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_AssignmentEvent_UnassignedEvent>): Event_AssignmentEvent_UnassignedEvent {
    const message = createBaseEvent_AssignmentEvent_UnassignedEvent();
    message.organization = object.organization ?? "";
    message.removed = (object.removed !== undefined && object.removed !== null)
      ? Assignment.fromPartial(object.removed)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_UserEvent(): Event_UserEvent {
  return {};
}

export const Event_UserEvent = {
  encode(_: Event_UserEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_UserEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_UserEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_UserEvent>): Event_UserEvent {
    return Event_UserEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_UserEvent>): Event_UserEvent {
    const message = createBaseEvent_UserEvent();
    return message;
  },
};

function createBaseEvent_UserEvent_UserAddedEvent(): Event_UserEvent_UserAddedEvent {
  return { organization: "", user: "", title: "", description: "", time: undefined };
}

export const Event_UserEvent_UserAddedEvent = {
  encode(message: Event_UserEvent_UserAddedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.user !== "") {
      writer.uint32(18).string(message.user);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_UserEvent_UserAddedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_UserEvent_UserAddedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_UserEvent_UserAddedEvent>): Event_UserEvent_UserAddedEvent {
    return Event_UserEvent_UserAddedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_UserEvent_UserAddedEvent>): Event_UserEvent_UserAddedEvent {
    const message = createBaseEvent_UserEvent_UserAddedEvent();
    message.organization = object.organization ?? "";
    message.user = object.user ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_UserEvent_UserRemovedEvent(): Event_UserEvent_UserRemovedEvent {
  return { organization: "", user: "", title: "", description: "", time: undefined };
}

export const Event_UserEvent_UserRemovedEvent = {
  encode(message: Event_UserEvent_UserRemovedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.user !== "") {
      writer.uint32(18).string(message.user);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_UserEvent_UserRemovedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_UserEvent_UserRemovedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_UserEvent_UserRemovedEvent>): Event_UserEvent_UserRemovedEvent {
    return Event_UserEvent_UserRemovedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_UserEvent_UserRemovedEvent>): Event_UserEvent_UserRemovedEvent {
    const message = createBaseEvent_UserEvent_UserRemovedEvent();
    message.organization = object.organization ?? "";
    message.user = object.user ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_UserEvent_UserInvitedEvent(): Event_UserEvent_UserInvitedEvent {
  return { organization: "", email: "", title: "", description: "", time: undefined };
}

export const Event_UserEvent_UserInvitedEvent = {
  encode(message: Event_UserEvent_UserInvitedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_UserEvent_UserInvitedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_UserEvent_UserInvitedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_UserEvent_UserInvitedEvent>): Event_UserEvent_UserInvitedEvent {
    return Event_UserEvent_UserInvitedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_UserEvent_UserInvitedEvent>): Event_UserEvent_UserInvitedEvent {
    const message = createBaseEvent_UserEvent_UserInvitedEvent();
    message.organization = object.organization ?? "";
    message.email = object.email ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_UserEvent_UserAccessRequestEvent(): Event_UserEvent_UserAccessRequestEvent {
  return { organization: "", user: "", time: undefined, title: "", description: "" };
}

export const Event_UserEvent_UserAccessRequestEvent = {
  encode(message: Event_UserEvent_UserAccessRequestEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.user !== "") {
      writer.uint32(18).string(message.user);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(26).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_UserEvent_UserAccessRequestEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_UserEvent_UserAccessRequestEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_UserEvent_UserAccessRequestEvent>): Event_UserEvent_UserAccessRequestEvent {
    return Event_UserEvent_UserAccessRequestEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_UserEvent_UserAccessRequestEvent>): Event_UserEvent_UserAccessRequestEvent {
    const message = createBaseEvent_UserEvent_UserAccessRequestEvent();
    message.organization = object.organization ?? "";
    message.user = object.user ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseEvent_UserEvent_UserValidationUpdatedEvent(): Event_UserEvent_UserValidationUpdatedEvent {
  return { organization: "", emailValidationRegex: "", title: "", description: "", time: undefined };
}

export const Event_UserEvent_UserValidationUpdatedEvent = {
  encode(message: Event_UserEvent_UserValidationUpdatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.emailValidationRegex !== "") {
      writer.uint32(18).string(message.emailValidationRegex);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_UserEvent_UserValidationUpdatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_UserEvent_UserValidationUpdatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.emailValidationRegex = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_UserEvent_UserValidationUpdatedEvent>): Event_UserEvent_UserValidationUpdatedEvent {
    return Event_UserEvent_UserValidationUpdatedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_UserEvent_UserValidationUpdatedEvent>,
  ): Event_UserEvent_UserValidationUpdatedEvent {
    const message = createBaseEvent_UserEvent_UserValidationUpdatedEvent();
    message.organization = object.organization ?? "";
    message.emailValidationRegex = object.emailValidationRegex ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_PublisherEvent(): Event_PublisherEvent {
  return {};
}

export const Event_PublisherEvent = {
  encode(_: Event_PublisherEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_PublisherEvent>): Event_PublisherEvent {
    return Event_PublisherEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_PublisherEvent>): Event_PublisherEvent {
    const message = createBaseEvent_PublisherEvent();
    return message;
  },
};

function createBaseEvent_PublisherEvent_CreatedEvent(): Event_PublisherEvent_CreatedEvent {
  return { organization: "", created: undefined, tags: [], title: "", description: "", time: undefined };
}

export const Event_PublisherEvent_CreatedEvent = {
  encode(message: Event_PublisherEvent_CreatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.created !== undefined) {
      Publisher.encode(message.created, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_CreatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_CreatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = Publisher.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_PublisherEvent_CreatedEvent>): Event_PublisherEvent_CreatedEvent {
    return Event_PublisherEvent_CreatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_PublisherEvent_CreatedEvent>): Event_PublisherEvent_CreatedEvent {
    const message = createBaseEvent_PublisherEvent_CreatedEvent();
    message.organization = object.organization ?? "";
    message.created = (object.created !== undefined && object.created !== null)
      ? Publisher.fromPartial(object.created)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_PublisherEvent_UpdatedEvent(): Event_PublisherEvent_UpdatedEvent {
  return {
    organization: "",
    deprecatedUpdatedDescription: undefined,
    updatedDescription: undefined,
    partialUpdateData: undefined,
    updatedData: undefined,
    deprecatedUpdatedEventFilter: undefined,
    updatedEventFilter: undefined,
    deprecatedUpdatedModemFilter: undefined,
    updatedModemFilter: undefined,
    deprecatedUpdatedTagFilter: undefined,
    updatedTagFilter: undefined,
    deprecatedUpdatedActiveState: undefined,
    updatedActiveState: undefined,
    healthWarningPeriod: undefined,
    tags: [],
    title: "",
    description: "",
    time: undefined,
  };
}

export const Event_PublisherEvent_UpdatedEvent = {
  encode(message: Event_PublisherEvent_UpdatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deprecatedUpdatedDescription !== undefined) {
      UpdateClearableString.encode(message.deprecatedUpdatedDescription, writer.uint32(18).fork()).ldelim();
    }
    if (message.updatedDescription !== undefined) {
      writer.uint32(194).string(message.updatedDescription);
    }
    if (message.partialUpdateData !== undefined) {
      Publisher_Data.encode(message.partialUpdateData, writer.uint32(26).fork()).ldelim();
    }
    switch (message.updatedData?.$case) {
      case "webhook":
        Event_PublisherEvent_UpdatedEvent_WebhookUpdate.encode(message.updatedData.webhook, writer.uint32(122).fork())
          .ldelim();
        break;
      case "mqtt":
        Event_PublisherEvent_UpdatedEvent_MQTTUpdate.encode(message.updatedData.mqtt, writer.uint32(130).fork())
          .ldelim();
        break;
      case "email":
        Event_PublisherEvent_UpdatedEvent_EmailUpdate.encode(message.updatedData.email, writer.uint32(146).fork())
          .ldelim();
        break;
      case "slack":
        Event_PublisherEvent_UpdatedEvent_SlackUpdate.encode(message.updatedData.slack, writer.uint32(178).fork())
          .ldelim();
        break;
      case "shellSsip":
        Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate.encode(
          message.updatedData.shellSsip,
          writer.uint32(186).fork(),
        ).ldelim();
        break;
    }
    if (message.deprecatedUpdatedEventFilter !== undefined) {
      Filter_Events_Update.encode(message.deprecatedUpdatedEventFilter, writer.uint32(34).fork()).ldelim();
    }
    if (message.updatedEventFilter !== undefined) {
      Filter_Events.encode(message.updatedEventFilter, writer.uint32(202).fork()).ldelim();
    }
    if (message.deprecatedUpdatedModemFilter !== undefined) {
      Filter_Modems_Update.encode(message.deprecatedUpdatedModemFilter, writer.uint32(42).fork()).ldelim();
    }
    if (message.updatedModemFilter !== undefined) {
      Filter_Modems.encode(message.updatedModemFilter, writer.uint32(210).fork()).ldelim();
    }
    if (message.deprecatedUpdatedTagFilter !== undefined) {
      Filter_Tags_Update.encode(message.deprecatedUpdatedTagFilter, writer.uint32(50).fork()).ldelim();
    }
    if (message.updatedTagFilter !== undefined) {
      Filter_Tags.encode(message.updatedTagFilter, writer.uint32(218).fork()).ldelim();
    }
    if (message.deprecatedUpdatedActiveState !== undefined) {
      UpdateBoolean.encode(message.deprecatedUpdatedActiveState, writer.uint32(58).fork()).ldelim();
    }
    if (message.updatedActiveState !== undefined) {
      writer.uint32(224).bool(message.updatedActiveState);
    }
    if (message.healthWarningPeriod !== undefined) {
      Duration.encode(message.healthWarningPeriod, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(74).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(82).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_UpdatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_UpdatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deprecatedUpdatedDescription = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.updatedDescription = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partialUpdateData = Publisher_Data.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.updatedData = {
            $case: "webhook",
            webhook: Event_PublisherEvent_UpdatedEvent_WebhookUpdate.decode(reader, reader.uint32()),
          };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.updatedData = {
            $case: "mqtt",
            mqtt: Event_PublisherEvent_UpdatedEvent_MQTTUpdate.decode(reader, reader.uint32()),
          };
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.updatedData = {
            $case: "email",
            email: Event_PublisherEvent_UpdatedEvent_EmailUpdate.decode(reader, reader.uint32()),
          };
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.updatedData = {
            $case: "slack",
            slack: Event_PublisherEvent_UpdatedEvent_SlackUpdate.decode(reader, reader.uint32()),
          };
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.updatedData = {
            $case: "shellSsip",
            shellSsip: Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deprecatedUpdatedEventFilter = Filter_Events_Update.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.updatedEventFilter = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedUpdatedModemFilter = Filter_Modems_Update.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.updatedModemFilter = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deprecatedUpdatedTagFilter = Filter_Tags_Update.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.updatedTagFilter = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.deprecatedUpdatedActiveState = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.updatedActiveState = reader.bool();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.healthWarningPeriod = Duration.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.title = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.description = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_PublisherEvent_UpdatedEvent>): Event_PublisherEvent_UpdatedEvent {
    return Event_PublisherEvent_UpdatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_PublisherEvent_UpdatedEvent>): Event_PublisherEvent_UpdatedEvent {
    const message = createBaseEvent_PublisherEvent_UpdatedEvent();
    message.organization = object.organization ?? "";
    message.deprecatedUpdatedDescription =
      (object.deprecatedUpdatedDescription !== undefined && object.deprecatedUpdatedDescription !== null)
        ? UpdateClearableString.fromPartial(object.deprecatedUpdatedDescription)
        : undefined;
    message.updatedDescription = object.updatedDescription ?? undefined;
    message.partialUpdateData = (object.partialUpdateData !== undefined && object.partialUpdateData !== null)
      ? Publisher_Data.fromPartial(object.partialUpdateData)
      : undefined;
    if (
      object.updatedData?.$case === "webhook" &&
      object.updatedData?.webhook !== undefined &&
      object.updatedData?.webhook !== null
    ) {
      message.updatedData = {
        $case: "webhook",
        webhook: Event_PublisherEvent_UpdatedEvent_WebhookUpdate.fromPartial(object.updatedData.webhook),
      };
    }
    if (
      object.updatedData?.$case === "mqtt" &&
      object.updatedData?.mqtt !== undefined &&
      object.updatedData?.mqtt !== null
    ) {
      message.updatedData = {
        $case: "mqtt",
        mqtt: Event_PublisherEvent_UpdatedEvent_MQTTUpdate.fromPartial(object.updatedData.mqtt),
      };
    }
    if (
      object.updatedData?.$case === "email" &&
      object.updatedData?.email !== undefined &&
      object.updatedData?.email !== null
    ) {
      message.updatedData = {
        $case: "email",
        email: Event_PublisherEvent_UpdatedEvent_EmailUpdate.fromPartial(object.updatedData.email),
      };
    }
    if (
      object.updatedData?.$case === "slack" &&
      object.updatedData?.slack !== undefined &&
      object.updatedData?.slack !== null
    ) {
      message.updatedData = {
        $case: "slack",
        slack: Event_PublisherEvent_UpdatedEvent_SlackUpdate.fromPartial(object.updatedData.slack),
      };
    }
    if (
      object.updatedData?.$case === "shellSsip" &&
      object.updatedData?.shellSsip !== undefined &&
      object.updatedData?.shellSsip !== null
    ) {
      message.updatedData = {
        $case: "shellSsip",
        shellSsip: Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate.fromPartial(object.updatedData.shellSsip),
      };
    }
    message.deprecatedUpdatedEventFilter =
      (object.deprecatedUpdatedEventFilter !== undefined && object.deprecatedUpdatedEventFilter !== null)
        ? Filter_Events_Update.fromPartial(object.deprecatedUpdatedEventFilter)
        : undefined;
    message.updatedEventFilter = (object.updatedEventFilter !== undefined && object.updatedEventFilter !== null)
      ? Filter_Events.fromPartial(object.updatedEventFilter)
      : undefined;
    message.deprecatedUpdatedModemFilter =
      (object.deprecatedUpdatedModemFilter !== undefined && object.deprecatedUpdatedModemFilter !== null)
        ? Filter_Modems_Update.fromPartial(object.deprecatedUpdatedModemFilter)
        : undefined;
    message.updatedModemFilter = (object.updatedModemFilter !== undefined && object.updatedModemFilter !== null)
      ? Filter_Modems.fromPartial(object.updatedModemFilter)
      : undefined;
    message.deprecatedUpdatedTagFilter =
      (object.deprecatedUpdatedTagFilter !== undefined && object.deprecatedUpdatedTagFilter !== null)
        ? Filter_Tags_Update.fromPartial(object.deprecatedUpdatedTagFilter)
        : undefined;
    message.updatedTagFilter = (object.updatedTagFilter !== undefined && object.updatedTagFilter !== null)
      ? Filter_Tags.fromPartial(object.updatedTagFilter)
      : undefined;
    message.deprecatedUpdatedActiveState =
      (object.deprecatedUpdatedActiveState !== undefined && object.deprecatedUpdatedActiveState !== null)
        ? UpdateBoolean.fromPartial(object.deprecatedUpdatedActiveState)
        : undefined;
    message.updatedActiveState = object.updatedActiveState ?? undefined;
    message.healthWarningPeriod = (object.healthWarningPeriod !== undefined && object.healthWarningPeriod !== null)
      ? Duration.fromPartial(object.healthWarningPeriod)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_PublisherEvent_UpdatedEvent_WebhookUpdate(): Event_PublisherEvent_UpdatedEvent_WebhookUpdate {
  return {
    url: undefined,
    deprecatedSecret: undefined,
    secret: undefined,
    contentType: undefined,
    deprecatedCertificateId: undefined,
    certificateId: undefined,
  };
}

export const Event_PublisherEvent_UpdatedEvent_WebhookUpdate = {
  encode(
    message: Event_PublisherEvent_UpdatedEvent_WebhookUpdate,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.url !== undefined) {
      writer.uint32(10).string(message.url);
    }
    if (message.deprecatedSecret !== undefined) {
      UpdateClearableString.encode(message.deprecatedSecret, writer.uint32(18).fork()).ldelim();
    }
    if (message.secret !== undefined) {
      writer.uint32(34).string(message.secret);
    }
    if (message.contentType !== undefined) {
      writer.uint32(24).int32(webhook_ContentTypeToNumber(message.contentType));
    }
    if (message.deprecatedCertificateId !== undefined) {
      UpdateOptionalId.encode(message.deprecatedCertificateId, writer.uint32(74).fork()).ldelim();
    }
    if (message.certificateId !== undefined) {
      writer.uint32(40).int64(message.certificateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_UpdatedEvent_WebhookUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_WebhookUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deprecatedSecret = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.secret = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.contentType = webhook_ContentTypeFromJSON(reader.int32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deprecatedCertificateId = UpdateOptionalId.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_PublisherEvent_UpdatedEvent_WebhookUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_WebhookUpdate {
    return Event_PublisherEvent_UpdatedEvent_WebhookUpdate.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_PublisherEvent_UpdatedEvent_WebhookUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_WebhookUpdate {
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_WebhookUpdate();
    message.url = object.url ?? undefined;
    message.deprecatedSecret = (object.deprecatedSecret !== undefined && object.deprecatedSecret !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedSecret)
      : undefined;
    message.secret = object.secret ?? undefined;
    message.contentType = object.contentType ?? undefined;
    message.deprecatedCertificateId =
      (object.deprecatedCertificateId !== undefined && object.deprecatedCertificateId !== null)
        ? UpdateOptionalId.fromPartial(object.deprecatedCertificateId)
        : undefined;
    message.certificateId = object.certificateId ?? undefined;
    return message;
  },
};

function createBaseEvent_PublisherEvent_UpdatedEvent_MQTTUpdate(): Event_PublisherEvent_UpdatedEvent_MQTTUpdate {
  return {
    url: "",
    contentType: MQTTPublisher_ContentType.DEFAULT,
    topic: "",
    qos: MQTTPublisher_Data_QoS.DEFAULT,
    deprecatedIdentifier: undefined,
    identifier: undefined,
    deprecatedUsername: undefined,
    username: undefined,
    deprecatedPassword: undefined,
    password: undefined,
    deprecatedCertificateId: undefined,
    certificateId: undefined,
  };
}

export const Event_PublisherEvent_UpdatedEvent_MQTTUpdate = {
  encode(message: Event_PublisherEvent_UpdatedEvent_MQTTUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.contentType !== MQTTPublisher_ContentType.DEFAULT) {
      writer.uint32(16).int32(mQTTPublisher_ContentTypeToNumber(message.contentType));
    }
    if (message.topic !== "") {
      writer.uint32(26).string(message.topic);
    }
    if (message.qos !== MQTTPublisher_Data_QoS.DEFAULT) {
      writer.uint32(32).int32(mQTTPublisher_Data_QoSToNumber(message.qos));
    }
    if (message.deprecatedIdentifier !== undefined) {
      UpdateClearableString.encode(message.deprecatedIdentifier, writer.uint32(42).fork()).ldelim();
    }
    if (message.identifier !== undefined) {
      writer.uint32(74).string(message.identifier);
    }
    if (message.deprecatedUsername !== undefined) {
      UpdateClearableString.encode(message.deprecatedUsername, writer.uint32(50).fork()).ldelim();
    }
    if (message.username !== undefined) {
      writer.uint32(82).string(message.username);
    }
    if (message.deprecatedPassword !== undefined) {
      UpdateClearableString.encode(message.deprecatedPassword, writer.uint32(58).fork()).ldelim();
    }
    if (message.password !== undefined) {
      writer.uint32(90).string(message.password);
    }
    if (message.deprecatedCertificateId !== undefined) {
      UpdateOptionalId.encode(message.deprecatedCertificateId, writer.uint32(66).fork()).ldelim();
    }
    if (message.certificateId !== undefined) {
      writer.uint32(96).int64(message.certificateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_UpdatedEvent_MQTTUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_MQTTUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.contentType = mQTTPublisher_ContentTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.topic = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.qos = mQTTPublisher_Data_QoSFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedIdentifier = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deprecatedUsername = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.username = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.deprecatedPassword = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.password = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.deprecatedCertificateId = UpdateOptionalId.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_PublisherEvent_UpdatedEvent_MQTTUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_MQTTUpdate {
    return Event_PublisherEvent_UpdatedEvent_MQTTUpdate.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_PublisherEvent_UpdatedEvent_MQTTUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_MQTTUpdate {
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_MQTTUpdate();
    message.url = object.url ?? "";
    message.contentType = object.contentType ?? MQTTPublisher_ContentType.DEFAULT;
    message.topic = object.topic ?? "";
    message.qos = object.qos ?? MQTTPublisher_Data_QoS.DEFAULT;
    message.deprecatedIdentifier = (object.deprecatedIdentifier !== undefined && object.deprecatedIdentifier !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedIdentifier)
      : undefined;
    message.identifier = object.identifier ?? undefined;
    message.deprecatedUsername = (object.deprecatedUsername !== undefined && object.deprecatedUsername !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedUsername)
      : undefined;
    message.username = object.username ?? undefined;
    message.deprecatedPassword = (object.deprecatedPassword !== undefined && object.deprecatedPassword !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedPassword)
      : undefined;
    message.password = object.password ?? undefined;
    message.deprecatedCertificateId =
      (object.deprecatedCertificateId !== undefined && object.deprecatedCertificateId !== null)
        ? UpdateOptionalId.fromPartial(object.deprecatedCertificateId)
        : undefined;
    message.certificateId = object.certificateId ?? undefined;
    return message;
  },
};

function createBaseEvent_PublisherEvent_UpdatedEvent_EmailUpdate(): Event_PublisherEvent_UpdatedEvent_EmailUpdate {
  return {};
}

export const Event_PublisherEvent_UpdatedEvent_EmailUpdate = {
  encode(_: Event_PublisherEvent_UpdatedEvent_EmailUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_UpdatedEvent_EmailUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_EmailUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_PublisherEvent_UpdatedEvent_EmailUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_EmailUpdate {
    return Event_PublisherEvent_UpdatedEvent_EmailUpdate.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<Event_PublisherEvent_UpdatedEvent_EmailUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_EmailUpdate {
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_EmailUpdate();
    return message;
  },
};

function createBaseEvent_PublisherEvent_UpdatedEvent_SlackUpdate(): Event_PublisherEvent_UpdatedEvent_SlackUpdate {
  return {};
}

export const Event_PublisherEvent_UpdatedEvent_SlackUpdate = {
  encode(_: Event_PublisherEvent_UpdatedEvent_SlackUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_UpdatedEvent_SlackUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_SlackUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_PublisherEvent_UpdatedEvent_SlackUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_SlackUpdate {
    return Event_PublisherEvent_UpdatedEvent_SlackUpdate.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<Event_PublisherEvent_UpdatedEvent_SlackUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_SlackUpdate {
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_SlackUpdate();
    return message;
  },
};

function createBaseEvent_PublisherEvent_UpdatedEvent_ShellSsipUpdate(): Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate {
  return {};
}

export const Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate = {
  encode(_: Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_ShellSsipUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate {
    return Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate>,
  ): Event_PublisherEvent_UpdatedEvent_ShellSsipUpdate {
    const message = createBaseEvent_PublisherEvent_UpdatedEvent_ShellSsipUpdate();
    return message;
  },
};

function createBaseEvent_PublisherEvent_DeletedEvent(): Event_PublisherEvent_DeletedEvent {
  return { organization: "", deleted: undefined, tags: [], title: "", description: "", time: undefined };
}

export const Event_PublisherEvent_DeletedEvent = {
  encode(message: Event_PublisherEvent_DeletedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.deleted !== undefined) {
      Publisher.encode(message.deleted, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_DeletedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_DeletedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deleted = Publisher.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_PublisherEvent_DeletedEvent>): Event_PublisherEvent_DeletedEvent {
    return Event_PublisherEvent_DeletedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_PublisherEvent_DeletedEvent>): Event_PublisherEvent_DeletedEvent {
    const message = createBaseEvent_PublisherEvent_DeletedEvent();
    message.organization = object.organization ?? "";
    message.deleted = (object.deleted !== undefined && object.deleted !== null)
      ? Publisher.fromPartial(object.deleted)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_PublisherEvent_AutoDisabledEvent(): Event_PublisherEvent_AutoDisabledEvent {
  return { organization: "", id: 0, tags: [], title: "", description: "", time: undefined, healthLevel: undefined };
}

export const Event_PublisherEvent_AutoDisabledEvent = {
  encode(message: Event_PublisherEvent_AutoDisabledEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(50).fork()).ldelim();
    }
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_AutoDisabledEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_AutoDisabledEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_PublisherEvent_AutoDisabledEvent>): Event_PublisherEvent_AutoDisabledEvent {
    return Event_PublisherEvent_AutoDisabledEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_PublisherEvent_AutoDisabledEvent>): Event_PublisherEvent_AutoDisabledEvent {
    const message = createBaseEvent_PublisherEvent_AutoDisabledEvent();
    message.organization = object.organization ?? "";
    message.id = object.id ?? 0;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    return message;
  },
};

function createBaseEvent_PublisherEvent_FailedEvent(): Event_PublisherEvent_FailedEvent {
  return {
    organization: "",
    reason: "",
    failed: undefined,
    tags: [],
    title: "",
    description: "",
    time: undefined,
    healthLevel: undefined,
  };
}

export const Event_PublisherEvent_FailedEvent = {
  encode(message: Event_PublisherEvent_FailedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.reason !== "") {
      writer.uint32(18).string(message.reason);
    }
    if (message.failed !== undefined) {
      Publisher.encode(message.failed, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(58).fork()).ldelim();
    }
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_PublisherEvent_FailedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_PublisherEvent_FailedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reason = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.failed = Publisher.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_PublisherEvent_FailedEvent>): Event_PublisherEvent_FailedEvent {
    return Event_PublisherEvent_FailedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_PublisherEvent_FailedEvent>): Event_PublisherEvent_FailedEvent {
    const message = createBaseEvent_PublisherEvent_FailedEvent();
    message.organization = object.organization ?? "";
    message.reason = object.reason ?? "";
    message.failed = (object.failed !== undefined && object.failed !== null)
      ? Publisher.fromPartial(object.failed)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    return message;
  },
};

function createBaseEvent_TokenEvent(): Event_TokenEvent {
  return {};
}

export const Event_TokenEvent = {
  encode(_: Event_TokenEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_TokenEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_TokenEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_TokenEvent>): Event_TokenEvent {
    return Event_TokenEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_TokenEvent>): Event_TokenEvent {
    const message = createBaseEvent_TokenEvent();
    return message;
  },
};

function createBaseEvent_TokenEvent_TokenCreatedEvent(): Event_TokenEvent_TokenCreatedEvent {
  return { organization: "", token: undefined, title: "", description: "", time: undefined };
}

export const Event_TokenEvent_TokenCreatedEvent = {
  encode(message: Event_TokenEvent_TokenCreatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.token !== undefined) {
      Token.encode(message.token, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_TokenEvent_TokenCreatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_TokenEvent_TokenCreatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.token = Token.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_TokenEvent_TokenCreatedEvent>): Event_TokenEvent_TokenCreatedEvent {
    return Event_TokenEvent_TokenCreatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_TokenEvent_TokenCreatedEvent>): Event_TokenEvent_TokenCreatedEvent {
    const message = createBaseEvent_TokenEvent_TokenCreatedEvent();
    message.organization = object.organization ?? "";
    message.token = (object.token !== undefined && object.token !== null) ? Token.fromPartial(object.token) : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_TokenEvent_TokenExpiryWarningEvent(): Event_TokenEvent_TokenExpiryWarningEvent {
  return { organization: "", token: undefined, title: "", description: "", time: undefined, healthLevel: undefined };
}

export const Event_TokenEvent_TokenExpiryWarningEvent = {
  encode(message: Event_TokenEvent_TokenExpiryWarningEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.token !== undefined) {
      Token.encode(message.token, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_TokenEvent_TokenExpiryWarningEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_TokenEvent_TokenExpiryWarningEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.token = Token.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_TokenEvent_TokenExpiryWarningEvent>): Event_TokenEvent_TokenExpiryWarningEvent {
    return Event_TokenEvent_TokenExpiryWarningEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_TokenEvent_TokenExpiryWarningEvent>): Event_TokenEvent_TokenExpiryWarningEvent {
    const message = createBaseEvent_TokenEvent_TokenExpiryWarningEvent();
    message.organization = object.organization ?? "";
    message.token = (object.token !== undefined && object.token !== null) ? Token.fromPartial(object.token) : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    return message;
  },
};

function createBaseEvent_TokenEvent_TokenExpiredEvent(): Event_TokenEvent_TokenExpiredEvent {
  return { organization: "", token: undefined, title: "", description: "", time: undefined };
}

export const Event_TokenEvent_TokenExpiredEvent = {
  encode(message: Event_TokenEvent_TokenExpiredEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.token !== undefined) {
      Token.encode(message.token, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_TokenEvent_TokenExpiredEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_TokenEvent_TokenExpiredEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.token = Token.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_TokenEvent_TokenExpiredEvent>): Event_TokenEvent_TokenExpiredEvent {
    return Event_TokenEvent_TokenExpiredEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_TokenEvent_TokenExpiredEvent>): Event_TokenEvent_TokenExpiredEvent {
    const message = createBaseEvent_TokenEvent_TokenExpiredEvent();
    message.organization = object.organization ?? "";
    message.token = (object.token !== undefined && object.token !== null) ? Token.fromPartial(object.token) : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_TokenEvent_TokenDeletedEvent(): Event_TokenEvent_TokenDeletedEvent {
  return { organization: "", token: undefined, title: "", description: "", time: undefined };
}

export const Event_TokenEvent_TokenDeletedEvent = {
  encode(message: Event_TokenEvent_TokenDeletedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.token !== undefined) {
      Token.encode(message.token, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_TokenEvent_TokenDeletedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_TokenEvent_TokenDeletedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.token = Token.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_TokenEvent_TokenDeletedEvent>): Event_TokenEvent_TokenDeletedEvent {
    return Event_TokenEvent_TokenDeletedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_TokenEvent_TokenDeletedEvent>): Event_TokenEvent_TokenDeletedEvent {
    const message = createBaseEvent_TokenEvent_TokenDeletedEvent();
    message.organization = object.organization ?? "";
    message.token = (object.token !== undefined && object.token !== null) ? Token.fromPartial(object.token) : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_OrganizationEvent(): Event_OrganizationEvent {
  return {};
}

export const Event_OrganizationEvent = {
  encode(_: Event_OrganizationEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_OrganizationEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_OrganizationEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_OrganizationEvent>): Event_OrganizationEvent {
    return Event_OrganizationEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_OrganizationEvent>): Event_OrganizationEvent {
    const message = createBaseEvent_OrganizationEvent();
    return message;
  },
};

function createBaseEvent_OrganizationEvent_OrganizationCreatedEvent(): Event_OrganizationEvent_OrganizationCreatedEvent {
  return { parentOrganization: "", created: undefined, title: "", description: "", avatar: undefined, time: undefined };
}

export const Event_OrganizationEvent_OrganizationCreatedEvent = {
  encode(
    message: Event_OrganizationEvent_OrganizationCreatedEvent,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.parentOrganization !== "") {
      writer.uint32(10).string(message.parentOrganization);
    }
    if (message.created !== undefined) {
      Organization.encode(message.created, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(74).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(82).string(message.description);
    }
    if (message.avatar !== undefined) {
      Avatar.encode(message.avatar, writer.uint32(98).fork()).ldelim();
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_OrganizationEvent_OrganizationCreatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_OrganizationEvent_OrganizationCreatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parentOrganization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = Organization.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.title = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.description = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.avatar = Avatar.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_OrganizationEvent_OrganizationCreatedEvent>,
  ): Event_OrganizationEvent_OrganizationCreatedEvent {
    return Event_OrganizationEvent_OrganizationCreatedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_OrganizationEvent_OrganizationCreatedEvent>,
  ): Event_OrganizationEvent_OrganizationCreatedEvent {
    const message = createBaseEvent_OrganizationEvent_OrganizationCreatedEvent();
    message.parentOrganization = object.parentOrganization ?? "";
    message.created = (object.created !== undefined && object.created !== null)
      ? Organization.fromPartial(object.created)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.avatar = (object.avatar !== undefined && object.avatar !== null)
      ? Avatar.fromPartial(object.avatar)
      : undefined;
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_OrganizationEvent_OrganizationUpdatedEvent(): Event_OrganizationEvent_OrganizationUpdatedEvent {
  return {
    organization: "",
    displayNameUpdated: undefined,
    vatNumberUpdated: undefined,
    addressUpdated: undefined,
    isBusinessUpdated: undefined,
    businessUpdated: undefined,
    billingNameUpdated: undefined,
    billingAddressUpdated: undefined,
    contactUpdated: undefined,
    title: "",
    description: "",
    time: undefined,
    featuresAdded: [],
    featuresRemoved: [],
  };
}

export const Event_OrganizationEvent_OrganizationUpdatedEvent = {
  encode(
    message: Event_OrganizationEvent_OrganizationUpdatedEvent,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.displayNameUpdated !== undefined) {
      writer.uint32(18).string(message.displayNameUpdated);
    }
    if (message.vatNumberUpdated !== undefined) {
      writer.uint32(26).string(message.vatNumberUpdated);
    }
    if (message.addressUpdated !== undefined) {
      Organization_Address.encode(message.addressUpdated, writer.uint32(34).fork()).ldelim();
    }
    if (message.isBusinessUpdated !== undefined) {
      UpdateBoolean.encode(message.isBusinessUpdated, writer.uint32(42).fork()).ldelim();
    }
    if (message.businessUpdated !== undefined) {
      writer.uint32(112).bool(message.businessUpdated);
    }
    if (message.billingNameUpdated !== undefined) {
      writer.uint32(50).string(message.billingNameUpdated);
    }
    if (message.billingAddressUpdated !== undefined) {
      Organization_Address.encode(message.billingAddressUpdated, writer.uint32(58).fork()).ldelim();
    }
    if (message.contactUpdated !== undefined) {
      Organization_Contact.encode(message.contactUpdated, writer.uint32(66).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(74).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(82).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(90).fork()).ldelim();
    }
    writer.uint32(98).fork();
    for (const v of message.featuresAdded) {
      writer.int32(organization_FeatureToNumber(v));
    }
    writer.ldelim();
    writer.uint32(106).fork();
    for (const v of message.featuresRemoved) {
      writer.int32(organization_FeatureToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_OrganizationEvent_OrganizationUpdatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_OrganizationEvent_OrganizationUpdatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayNameUpdated = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vatNumberUpdated = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.addressUpdated = Organization_Address.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.isBusinessUpdated = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.businessUpdated = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.billingNameUpdated = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.billingAddressUpdated = Organization_Address.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.contactUpdated = Organization_Contact.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.title = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.description = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag === 96) {
            message.featuresAdded.push(organization_FeatureFromJSON(reader.int32()));

            continue;
          }

          if (tag === 98) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.featuresAdded.push(organization_FeatureFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 13:
          if (tag === 104) {
            message.featuresRemoved.push(organization_FeatureFromJSON(reader.int32()));

            continue;
          }

          if (tag === 106) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.featuresRemoved.push(organization_FeatureFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_OrganizationEvent_OrganizationUpdatedEvent>,
  ): Event_OrganizationEvent_OrganizationUpdatedEvent {
    return Event_OrganizationEvent_OrganizationUpdatedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_OrganizationEvent_OrganizationUpdatedEvent>,
  ): Event_OrganizationEvent_OrganizationUpdatedEvent {
    const message = createBaseEvent_OrganizationEvent_OrganizationUpdatedEvent();
    message.organization = object.organization ?? "";
    message.displayNameUpdated = object.displayNameUpdated ?? undefined;
    message.vatNumberUpdated = object.vatNumberUpdated ?? undefined;
    message.addressUpdated = (object.addressUpdated !== undefined && object.addressUpdated !== null)
      ? Organization_Address.fromPartial(object.addressUpdated)
      : undefined;
    message.isBusinessUpdated = (object.isBusinessUpdated !== undefined && object.isBusinessUpdated !== null)
      ? UpdateBoolean.fromPartial(object.isBusinessUpdated)
      : undefined;
    message.businessUpdated = object.businessUpdated ?? undefined;
    message.billingNameUpdated = object.billingNameUpdated ?? undefined;
    message.billingAddressUpdated =
      (object.billingAddressUpdated !== undefined && object.billingAddressUpdated !== null)
        ? Organization_Address.fromPartial(object.billingAddressUpdated)
        : undefined;
    message.contactUpdated = (object.contactUpdated !== undefined && object.contactUpdated !== null)
      ? Organization_Contact.fromPartial(object.contactUpdated)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.featuresAdded = object.featuresAdded?.map((e) => e) || [];
    message.featuresRemoved = object.featuresRemoved?.map((e) => e) || [];
    return message;
  },
};

function createBaseEvent_OrganizationEvent_OrganizationDeletedEvent(): Event_OrganizationEvent_OrganizationDeletedEvent {
  return { parentOrganization: "", deletedOrganization: "", title: "", description: "", time: undefined };
}

export const Event_OrganizationEvent_OrganizationDeletedEvent = {
  encode(
    message: Event_OrganizationEvent_OrganizationDeletedEvent,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.parentOrganization !== "") {
      writer.uint32(10).string(message.parentOrganization);
    }
    if (message.deletedOrganization !== "") {
      writer.uint32(18).string(message.deletedOrganization);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_OrganizationEvent_OrganizationDeletedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_OrganizationEvent_OrganizationDeletedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parentOrganization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deletedOrganization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_OrganizationEvent_OrganizationDeletedEvent>,
  ): Event_OrganizationEvent_OrganizationDeletedEvent {
    return Event_OrganizationEvent_OrganizationDeletedEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_OrganizationEvent_OrganizationDeletedEvent>,
  ): Event_OrganizationEvent_OrganizationDeletedEvent {
    const message = createBaseEvent_OrganizationEvent_OrganizationDeletedEvent();
    message.parentOrganization = object.parentOrganization ?? "";
    message.deletedOrganization = object.deletedOrganization ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_OrganizationEvent_EventConfigurationUpdated(): Event_OrganizationEvent_EventConfigurationUpdated {
  return { organization: "", messageSummaryUpdate: undefined, title: "", description: "", time: undefined };
}

export const Event_OrganizationEvent_EventConfigurationUpdated = {
  encode(
    message: Event_OrganizationEvent_EventConfigurationUpdated,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.messageSummaryUpdate !== undefined) {
      Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate.encode(
        message.messageSummaryUpdate,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_OrganizationEvent_EventConfigurationUpdated {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_OrganizationEvent_EventConfigurationUpdated();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.messageSummaryUpdate = Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_OrganizationEvent_EventConfigurationUpdated>,
  ): Event_OrganizationEvent_EventConfigurationUpdated {
    return Event_OrganizationEvent_EventConfigurationUpdated.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_OrganizationEvent_EventConfigurationUpdated>,
  ): Event_OrganizationEvent_EventConfigurationUpdated {
    const message = createBaseEvent_OrganizationEvent_EventConfigurationUpdated();
    message.organization = object.organization ?? "";
    message.messageSummaryUpdate = (object.messageSummaryUpdate !== undefined && object.messageSummaryUpdate !== null)
      ? Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate.fromPartial(object.messageSummaryUpdate)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate(): Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate {
  return { enabled: undefined, state: undefined, period: undefined, alignToTime: undefined };
}

export const Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate = {
  encode(
    message: Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.enabled !== undefined) {
      UpdateBoolean.encode(message.enabled, writer.uint32(10).fork()).ldelim();
    }
    if (message.state !== undefined) {
      writer.uint32(32).bool(message.state);
    }
    if (message.period !== undefined) {
      Duration.encode(message.period, writer.uint32(18).fork()).ldelim();
    }
    if (message.alignToTime !== undefined) {
      Timestamp.encode(message.alignToTime, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.enabled = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.state = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.period = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.alignToTime = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate>,
  ): Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate {
    return Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate>,
  ): Event_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate {
    const message = createBaseEvent_OrganizationEvent_EventConfigurationUpdated_MessageSummaryUpdate();
    message.enabled = (object.enabled !== undefined && object.enabled !== null)
      ? UpdateBoolean.fromPartial(object.enabled)
      : undefined;
    message.state = object.state ?? undefined;
    message.period = (object.period !== undefined && object.period !== null)
      ? Duration.fromPartial(object.period)
      : undefined;
    message.alignToTime = (object.alignToTime !== undefined && object.alignToTime !== null)
      ? Timestamp.fromPartial(object.alignToTime)
      : undefined;
    return message;
  },
};

function createBaseEvent_OrganizationEvent_MessageSummaryEvent(): Event_OrganizationEvent_MessageSummaryEvent {
  return { organization: "", messageCount: [], title: "", description: "", time: undefined, timeRange: undefined };
}

export const Event_OrganizationEvent_MessageSummaryEvent = {
  encode(message: Event_OrganizationEvent_MessageSummaryEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.messageCount) {
      Event_OrganizationEvent_MessageSummaryEvent_MessageCount.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(42).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(58).fork()).ldelim();
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_OrganizationEvent_MessageSummaryEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_OrganizationEvent_MessageSummaryEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.messageCount.push(
            Event_OrganizationEvent_MessageSummaryEvent_MessageCount.decode(reader, reader.uint32()),
          );
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.title = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_OrganizationEvent_MessageSummaryEvent>): Event_OrganizationEvent_MessageSummaryEvent {
    return Event_OrganizationEvent_MessageSummaryEvent.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_OrganizationEvent_MessageSummaryEvent>,
  ): Event_OrganizationEvent_MessageSummaryEvent {
    const message = createBaseEvent_OrganizationEvent_MessageSummaryEvent();
    message.organization = object.organization ?? "";
    message.messageCount =
      object.messageCount?.map((e) => Event_OrganizationEvent_MessageSummaryEvent_MessageCount.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    return message;
  },
};

function createBaseEvent_OrganizationEvent_MessageSummaryEvent_MessageCount(): Event_OrganizationEvent_MessageSummaryEvent_MessageCount {
  return { device: "", source: ModemMessage_Source.HIBERBAND, amount: 0 };
}

export const Event_OrganizationEvent_MessageSummaryEvent_MessageCount = {
  encode(
    message: Event_OrganizationEvent_MessageSummaryEvent_MessageCount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.device !== "") {
      writer.uint32(10).string(message.device);
    }
    if (message.source !== ModemMessage_Source.HIBERBAND) {
      writer.uint32(16).int32(modemMessage_SourceToNumber(message.source));
    }
    if (message.amount !== 0) {
      writer.uint32(24).int32(message.amount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_OrganizationEvent_MessageSummaryEvent_MessageCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_OrganizationEvent_MessageSummaryEvent_MessageCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.device = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.source = modemMessage_SourceFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.amount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Event_OrganizationEvent_MessageSummaryEvent_MessageCount>,
  ): Event_OrganizationEvent_MessageSummaryEvent_MessageCount {
    return Event_OrganizationEvent_MessageSummaryEvent_MessageCount.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Event_OrganizationEvent_MessageSummaryEvent_MessageCount>,
  ): Event_OrganizationEvent_MessageSummaryEvent_MessageCount {
    const message = createBaseEvent_OrganizationEvent_MessageSummaryEvent_MessageCount();
    message.device = object.device ?? "";
    message.source = object.source ?? ModemMessage_Source.HIBERBAND;
    message.amount = object.amount ?? 0;
    return message;
  },
};

function createBaseEvent_ExportEvent(): Event_ExportEvent {
  return {};
}

export const Event_ExportEvent = {
  encode(_: Event_ExportEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_ExportEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_ExportEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_ExportEvent>): Event_ExportEvent {
    return Event_ExportEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Event_ExportEvent>): Event_ExportEvent {
    const message = createBaseEvent_ExportEvent();
    return message;
  },
};

function createBaseEvent_ExportEvent_ExportCreatedEvent(): Event_ExportEvent_ExportCreatedEvent {
  return { organization: "", export: undefined, title: "", description: "", time: undefined };
}

export const Event_ExportEvent_ExportCreatedEvent = {
  encode(message: Event_ExportEvent_ExportCreatedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.export !== undefined) {
      Export.encode(message.export, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_ExportEvent_ExportCreatedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_ExportEvent_ExportCreatedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.export = Export.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_ExportEvent_ExportCreatedEvent>): Event_ExportEvent_ExportCreatedEvent {
    return Event_ExportEvent_ExportCreatedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_ExportEvent_ExportCreatedEvent>): Event_ExportEvent_ExportCreatedEvent {
    const message = createBaseEvent_ExportEvent_ExportCreatedEvent();
    message.organization = object.organization ?? "";
    message.export = (object.export !== undefined && object.export !== null)
      ? Export.fromPartial(object.export)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_ExportEvent_ExportReadyEvent(): Event_ExportEvent_ExportReadyEvent {
  return { organization: "", export: undefined, title: "", description: "", time: undefined };
}

export const Event_ExportEvent_ExportReadyEvent = {
  encode(message: Event_ExportEvent_ExportReadyEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.export !== undefined) {
      Export.encode(message.export, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_ExportEvent_ExportReadyEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_ExportEvent_ExportReadyEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.export = Export.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_ExportEvent_ExportReadyEvent>): Event_ExportEvent_ExportReadyEvent {
    return Event_ExportEvent_ExportReadyEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_ExportEvent_ExportReadyEvent>): Event_ExportEvent_ExportReadyEvent {
    const message = createBaseEvent_ExportEvent_ExportReadyEvent();
    message.organization = object.organization ?? "";
    message.export = (object.export !== undefined && object.export !== null)
      ? Export.fromPartial(object.export)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseEvent_ExportEvent_ExportFailedEvent(): Event_ExportEvent_ExportFailedEvent {
  return { organization: "", export: undefined, title: "", description: "", time: undefined };
}

export const Event_ExportEvent_ExportFailedEvent = {
  encode(message: Event_ExportEvent_ExportFailedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.export !== undefined) {
      Export.encode(message.export, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_ExportEvent_ExportFailedEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_ExportEvent_ExportFailedEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.export = Export.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Event_ExportEvent_ExportFailedEvent>): Event_ExportEvent_ExportFailedEvent {
    return Event_ExportEvent_ExportFailedEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Event_ExportEvent_ExportFailedEvent>): Event_ExportEvent_ExportFailedEvent {
    const message = createBaseEvent_ExportEvent_ExportFailedEvent();
    message.organization = object.organization ?? "";
    message.export = (object.export !== undefined && object.export !== null)
      ? Export.fromPartial(object.export)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    return message;
  },
};

function createBaseBundledEvent(): BundledEvent {
  return {
    type: EventType.DEFAULT,
    title: "",
    description: "",
    amount: undefined,
    deprecatedAmount: 0,
    deprecatedLastEvent: undefined,
    deprecatedApproximatedAmount: false,
  };
}

export const BundledEvent = {
  encode(message: BundledEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== EventType.DEFAULT) {
      writer.uint32(8).int32(eventTypeToNumber(message.type));
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    switch (message.amount?.$case) {
      case "exactAmount":
        BundledEvent_ExactAmount.encode(message.amount.exactAmount, writer.uint32(58).fork()).ldelim();
        break;
      case "approximatedAmount":
        BundledEvent_ApproximatedAmount.encode(message.amount.approximatedAmount, writer.uint32(66).fork()).ldelim();
        break;
    }
    if (message.deprecatedAmount !== 0) {
      writer.uint32(16).int32(message.deprecatedAmount);
    }
    if (message.deprecatedLastEvent !== undefined) {
      Timestamp.encode(message.deprecatedLastEvent, writer.uint32(42).fork()).ldelim();
    }
    if (message.deprecatedApproximatedAmount === true) {
      writer.uint32(48).bool(message.deprecatedApproximatedAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BundledEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBundledEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = eventTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.amount = {
            $case: "exactAmount",
            exactAmount: BundledEvent_ExactAmount.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.amount = {
            $case: "approximatedAmount",
            approximatedAmount: BundledEvent_ApproximatedAmount.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deprecatedAmount = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedLastEvent = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.deprecatedApproximatedAmount = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<BundledEvent>): BundledEvent {
    return BundledEvent.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BundledEvent>): BundledEvent {
    const message = createBaseBundledEvent();
    message.type = object.type ?? EventType.DEFAULT;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    if (
      object.amount?.$case === "exactAmount" &&
      object.amount?.exactAmount !== undefined &&
      object.amount?.exactAmount !== null
    ) {
      message.amount = {
        $case: "exactAmount",
        exactAmount: BundledEvent_ExactAmount.fromPartial(object.amount.exactAmount),
      };
    }
    if (
      object.amount?.$case === "approximatedAmount" &&
      object.amount?.approximatedAmount !== undefined &&
      object.amount?.approximatedAmount !== null
    ) {
      message.amount = {
        $case: "approximatedAmount",
        approximatedAmount: BundledEvent_ApproximatedAmount.fromPartial(object.amount.approximatedAmount),
      };
    }
    message.deprecatedAmount = object.deprecatedAmount ?? 0;
    message.deprecatedLastEvent = (object.deprecatedLastEvent !== undefined && object.deprecatedLastEvent !== null)
      ? Timestamp.fromPartial(object.deprecatedLastEvent)
      : undefined;
    message.deprecatedApproximatedAmount = object.deprecatedApproximatedAmount ?? false;
    return message;
  },
};

function createBaseBundledEvent_ExactAmount(): BundledEvent_ExactAmount {
  return { amount: 0, lastEvent: undefined };
}

export const BundledEvent_ExactAmount = {
  encode(message: BundledEvent_ExactAmount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).int32(message.amount);
    }
    if (message.lastEvent !== undefined) {
      Timestamp.encode(message.lastEvent, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BundledEvent_ExactAmount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBundledEvent_ExactAmount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amount = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastEvent = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<BundledEvent_ExactAmount>): BundledEvent_ExactAmount {
    return BundledEvent_ExactAmount.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BundledEvent_ExactAmount>): BundledEvent_ExactAmount {
    const message = createBaseBundledEvent_ExactAmount();
    message.amount = object.amount ?? 0;
    message.lastEvent = (object.lastEvent !== undefined && object.lastEvent !== null)
      ? Timestamp.fromPartial(object.lastEvent)
      : undefined;
    return message;
  },
};

function createBaseBundledEvent_ApproximatedAmount(): BundledEvent_ApproximatedAmount {
  return { amount: 0 };
}

export const BundledEvent_ApproximatedAmount = {
  encode(message: BundledEvent_ApproximatedAmount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).int32(message.amount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BundledEvent_ApproximatedAmount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBundledEvent_ApproximatedAmount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<BundledEvent_ApproximatedAmount>): BundledEvent_ApproximatedAmount {
    return BundledEvent_ApproximatedAmount.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BundledEvent_ApproximatedAmount>): BundledEvent_ApproximatedAmount {
    const message = createBaseBundledEvent_ApproximatedAmount();
    message.amount = object.amount ?? 0;
    return message;
  },
};

function createBaseEventSelection(): EventSelection {
  return {
    events: undefined,
    modems: undefined,
    publishers: undefined,
    tags: undefined,
    timeRange: undefined,
    healthLevels: [],
    includeResolvedEvents: undefined,
    unbundledEvents: undefined,
    errorsOnly: undefined,
    warningsOnly: undefined,
  };
}

export const EventSelection = {
  encode(message: EventSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.events !== undefined) {
      Filter_Events.encode(message.events, writer.uint32(10).fork()).ldelim();
    }
    if (message.modems !== undefined) {
      Filter_Modems.encode(message.modems, writer.uint32(18).fork()).ldelim();
    }
    if (message.publishers !== undefined) {
      Filter_Publishers.encode(message.publishers, writer.uint32(26).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      Filter_Tags.encode(message.tags, writer.uint32(34).fork()).ldelim();
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.healthLevels) {
      writer.uint32(90).string(v!);
    }
    if (message.includeResolvedEvents !== undefined) {
      writer.uint32(72).bool(message.includeResolvedEvents);
    }
    if (message.unbundledEvents !== undefined) {
      writer.uint32(64).bool(message.unbundledEvents);
    }
    if (message.errorsOnly !== undefined) {
      writer.uint32(56).bool(message.errorsOnly);
    }
    if (message.warningsOnly !== undefined) {
      writer.uint32(80).bool(message.warningsOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.events = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modems = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.publishers = Filter_Publishers.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.healthLevels.push(reader.string());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.includeResolvedEvents = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.unbundledEvents = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.errorsOnly = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.warningsOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EventSelection>): EventSelection {
    return EventSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EventSelection>): EventSelection {
    const message = createBaseEventSelection();
    message.events = (object.events !== undefined && object.events !== null)
      ? Filter_Events.fromPartial(object.events)
      : undefined;
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? Filter_Modems.fromPartial(object.modems)
      : undefined;
    message.publishers = (object.publishers !== undefined && object.publishers !== null)
      ? Filter_Publishers.fromPartial(object.publishers)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? Filter_Tags.fromPartial(object.tags)
      : undefined;
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.healthLevels = object.healthLevels?.map((e) => e) || [];
    message.includeResolvedEvents = object.includeResolvedEvents ?? undefined;
    message.unbundledEvents = object.unbundledEvents ?? undefined;
    message.errorsOnly = object.errorsOnly ?? undefined;
    message.warningsOnly = object.warningsOnly ?? undefined;
    return message;
  },
};

function createBaseListEventsRequest(): ListEventsRequest {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    sort: undefined,
    unbundledEvents: undefined,
  };
}

export const ListEventsRequest = {
  encode(message: ListEventsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      EventSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.sort !== undefined) {
      writer.uint32(32).int32(listEventsRequest_SortToNumber(message.sort));
    }
    if (message.unbundledEvents !== undefined) {
      writer.uint32(40).bool(message.unbundledEvents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListEventsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListEventsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = EventSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sort = listEventsRequest_SortFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.unbundledEvents = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListEventsRequest>): ListEventsRequest {
    return ListEventsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListEventsRequest>): ListEventsRequest {
    const message = createBaseListEventsRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? EventSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort ?? undefined;
    message.unbundledEvents = object.unbundledEvents ?? undefined;
    return message;
  },
};

function createBaseListEventsRequest_Response(): ListEventsRequest_Response {
  return { request: undefined, events: [], unbundledEvents: [] };
}

export const ListEventsRequest_Response = {
  encode(message: ListEventsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      ListEventsRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.events) {
      BundledEvent.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.unbundledEvents) {
      ListEventsRequest_Response_EventTypeResponse.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListEventsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListEventsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListEventsRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.events.push(BundledEvent.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.unbundledEvents.push(ListEventsRequest_Response_EventTypeResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListEventsRequest_Response>): ListEventsRequest_Response {
    return ListEventsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListEventsRequest_Response>): ListEventsRequest_Response {
    const message = createBaseListEventsRequest_Response();
    message.request = (object.request !== undefined && object.request !== null)
      ? ListEventsRequest.fromPartial(object.request)
      : undefined;
    message.events = object.events?.map((e) => BundledEvent.fromPartial(e)) || [];
    message.unbundledEvents =
      object.unbundledEvents?.map((e) => ListEventsRequest_Response_EventTypeResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListEventsRequest_Response_EventTypeResponse(): ListEventsRequest_Response_EventTypeResponse {
  return { type: EventType.DEFAULT, unbundledEvents: [], pagination: undefined };
}

export const ListEventsRequest_Response_EventTypeResponse = {
  encode(message: ListEventsRequest_Response_EventTypeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== EventType.DEFAULT) {
      writer.uint32(8).int32(eventTypeToNumber(message.type));
    }
    for (const v of message.unbundledEvents) {
      Event.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListEventsRequest_Response_EventTypeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListEventsRequest_Response_EventTypeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = eventTypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.unbundledEvents.push(Event.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListEventsRequest_Response_EventTypeResponse>,
  ): ListEventsRequest_Response_EventTypeResponse {
    return ListEventsRequest_Response_EventTypeResponse.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListEventsRequest_Response_EventTypeResponse>,
  ): ListEventsRequest_Response_EventTypeResponse {
    const message = createBaseListEventsRequest_Response_EventTypeResponse();
    message.type = object.type ?? EventType.DEFAULT;
    message.unbundledEvents = object.unbundledEvents?.map((e) => Event.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseEventHistory(): EventHistory {
  return {};
}

export const EventHistory = {
  encode(_: EventHistory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventHistory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventHistory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EventHistory>): EventHistory {
    return EventHistory.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<EventHistory>): EventHistory {
    const message = createBaseEventHistory();
    return message;
  },
};

function createBaseEventHistory_Request(): EventHistory_Request {
  return { organization: undefined, selection: undefined, pagination: undefined };
}

export const EventHistory_Request = {
  encode(message: EventHistory_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      EventSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventHistory_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventHistory_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = EventSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EventHistory_Request>): EventHistory_Request {
    return EventHistory_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EventHistory_Request>): EventHistory_Request {
    const message = createBaseEventHistory_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? EventSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseEventHistory_Response(): EventHistory_Response {
  return { request: undefined, events: [], pagination: undefined };
}

export const EventHistory_Response = {
  encode(message: EventHistory_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      EventHistory_Request.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.events) {
      Event.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventHistory_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventHistory_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = EventHistory_Request.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.events.push(Event.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EventHistory_Response>): EventHistory_Response {
    return EventHistory_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EventHistory_Response>): EventHistory_Response {
    const message = createBaseEventHistory_Response();
    message.request = (object.request !== undefined && object.request !== null)
      ? EventHistory_Request.fromPartial(object.request)
      : undefined;
    message.events = object.events?.map((e) => Event.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseEventStreamRequest(): EventStreamRequest {
  return { organization: undefined, selection: undefined, childOrganizations: undefined };
}

export const EventStreamRequest = {
  encode(message: EventStreamRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      EventSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.childOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.childOrganizations, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventStreamRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventStreamRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = EventSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.childOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EventStreamRequest>): EventStreamRequest {
    return EventStreamRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EventStreamRequest>): EventStreamRequest {
    const message = createBaseEventStreamRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? EventSelection.fromPartial(object.selection)
      : undefined;
    message.childOrganizations = (object.childOrganizations !== undefined && object.childOrganizations !== null)
      ? Filter_ChildOrganizations.fromPartial(object.childOrganizations)
      : undefined;
    return message;
  },
};

function createBaseModemHealthEvents(): ModemHealthEvents {
  return {};
}

export const ModemHealthEvents = {
  encode(_: ModemHealthEvents, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemHealthEvents {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemHealthEvents();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemHealthEvents>): ModemHealthEvents {
    return ModemHealthEvents.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ModemHealthEvents>): ModemHealthEvents {
    const message = createBaseModemHealthEvents();
    return message;
  },
};

function createBaseModemHealthEvents_Request(): ModemHealthEvents_Request {
  return { organization: undefined, selection: undefined, pagination: undefined };
}

export const ModemHealthEvents_Request = {
  encode(message: ModemHealthEvents_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      EventSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemHealthEvents_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemHealthEvents_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = EventSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemHealthEvents_Request>): ModemHealthEvents_Request {
    return ModemHealthEvents_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemHealthEvents_Request>): ModemHealthEvents_Request {
    const message = createBaseModemHealthEvents_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? EventSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseModemHealthEvents_Response(): ModemHealthEvents_Response {
  return { request: undefined, events: [], pagination: undefined };
}

export const ModemHealthEvents_Response = {
  encode(message: ModemHealthEvents_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      ModemHealthEvents_Request.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.events) {
      Event.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemHealthEvents_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemHealthEvents_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = ModemHealthEvents_Request.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.events.push(Event.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemHealthEvents_Response>): ModemHealthEvents_Response {
    return ModemHealthEvents_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemHealthEvents_Response>): ModemHealthEvents_Response {
    const message = createBaseModemHealthEvents_Response();
    message.request = (object.request !== undefined && object.request !== null)
      ? ModemHealthEvents_Request.fromPartial(object.request)
      : undefined;
    message.events = object.events?.map((e) => Event.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseResolveEvent(): ResolveEvent {
  return {};
}

export const ResolveEvent = {
  encode(_: ResolveEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResolveEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResolveEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ResolveEvent>): ResolveEvent {
    return ResolveEvent.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ResolveEvent>): ResolveEvent {
    const message = createBaseResolveEvent();
    return message;
  },
};

function createBaseResolveEvent_Request(): ResolveEvent_Request {
  return { organization: undefined, resolveIdentifier: "" };
}

export const ResolveEvent_Request = {
  encode(message: ResolveEvent_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.resolveIdentifier !== "") {
      writer.uint32(18).string(message.resolveIdentifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResolveEvent_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResolveEvent_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.resolveIdentifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ResolveEvent_Request>): ResolveEvent_Request {
    return ResolveEvent_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ResolveEvent_Request>): ResolveEvent_Request {
    const message = createBaseResolveEvent_Request();
    message.organization = object.organization ?? undefined;
    message.resolveIdentifier = object.resolveIdentifier ?? "";
    return message;
  },
};

function createBaseResolveEvent_Response(): ResolveEvent_Response {
  return { event: undefined };
}

export const ResolveEvent_Response = {
  encode(message: ResolveEvent_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.event !== undefined) {
      Event.encode(message.event, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResolveEvent_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResolveEvent_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.event = Event.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ResolveEvent_Response>): ResolveEvent_Response {
    return ResolveEvent_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ResolveEvent_Response>): ResolveEvent_Response {
    const message = createBaseResolveEvent_Response();
    message.event = (object.event !== undefined && object.event !== null) ? Event.fromPartial(object.event) : undefined;
    return message;
  },
};

function createBaseMarkEventsResolved(): MarkEventsResolved {
  return {};
}

export const MarkEventsResolved = {
  encode(_: MarkEventsResolved, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarkEventsResolved {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkEventsResolved();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MarkEventsResolved>): MarkEventsResolved {
    return MarkEventsResolved.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<MarkEventsResolved>): MarkEventsResolved {
    const message = createBaseMarkEventsResolved();
    return message;
  },
};

function createBaseMarkEventsResolved_Request(): MarkEventsResolved_Request {
  return { organization: undefined, selection: undefined };
}

export const MarkEventsResolved_Request = {
  encode(message: MarkEventsResolved_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      EventSelection.encode(message.selection, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarkEventsResolved_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkEventsResolved_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selection = EventSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MarkEventsResolved_Request>): MarkEventsResolved_Request {
    return MarkEventsResolved_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MarkEventsResolved_Request>): MarkEventsResolved_Request {
    const message = createBaseMarkEventsResolved_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? EventSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseMarkEventsResolved_Response(): MarkEventsResolved_Response {
  return { resolved: 0, request: undefined };
}

export const MarkEventsResolved_Response = {
  encode(message: MarkEventsResolved_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resolved !== 0) {
      writer.uint32(8).uint32(message.resolved);
    }
    if (message.request !== undefined) {
      MarkEventsResolved_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarkEventsResolved_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkEventsResolved_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resolved = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = MarkEventsResolved_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MarkEventsResolved_Response>): MarkEventsResolved_Response {
    return MarkEventsResolved_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MarkEventsResolved_Response>): MarkEventsResolved_Response {
    const message = createBaseMarkEventsResolved_Response();
    message.resolved = object.resolved ?? 0;
    message.request = (object.request !== undefined && object.request !== null)
      ? MarkEventsResolved_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseEventConfiguration(): EventConfiguration {
  return { organization: "", messageSummaryConfiguration: undefined, eventHealthLevelConfiguration: [] };
}

export const EventConfiguration = {
  encode(message: EventConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.messageSummaryConfiguration !== undefined) {
      EventConfiguration_MessageSummaryConfiguration.encode(
        message.messageSummaryConfiguration,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    for (const v of message.eventHealthLevelConfiguration) {
      EventConfiguration_EventHealthLevelConfiguration.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.messageSummaryConfiguration = EventConfiguration_MessageSummaryConfiguration.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.eventHealthLevelConfiguration.push(
            EventConfiguration_EventHealthLevelConfiguration.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EventConfiguration>): EventConfiguration {
    return EventConfiguration.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EventConfiguration>): EventConfiguration {
    const message = createBaseEventConfiguration();
    message.organization = object.organization ?? "";
    message.messageSummaryConfiguration =
      (object.messageSummaryConfiguration !== undefined && object.messageSummaryConfiguration !== null)
        ? EventConfiguration_MessageSummaryConfiguration.fromPartial(object.messageSummaryConfiguration)
        : undefined;
    message.eventHealthLevelConfiguration =
      object.eventHealthLevelConfiguration?.map((e) =>
        EventConfiguration_EventHealthLevelConfiguration.fromPartial(e)
      ) || [];
    return message;
  },
};

function createBaseEventConfiguration_MessageSummaryConfiguration(): EventConfiguration_MessageSummaryConfiguration {
  return { enabled: false, period: undefined, alignToTime: undefined, includeEmptySummaries: false };
}

export const EventConfiguration_MessageSummaryConfiguration = {
  encode(
    message: EventConfiguration_MessageSummaryConfiguration,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.enabled === true) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.period !== undefined) {
      Duration.encode(message.period, writer.uint32(18).fork()).ldelim();
    }
    if (message.alignToTime !== undefined) {
      Timestamp.encode(message.alignToTime, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeEmptySummaries === true) {
      writer.uint32(32).bool(message.includeEmptySummaries);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventConfiguration_MessageSummaryConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventConfiguration_MessageSummaryConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.period = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.alignToTime = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeEmptySummaries = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<EventConfiguration_MessageSummaryConfiguration>,
  ): EventConfiguration_MessageSummaryConfiguration {
    return EventConfiguration_MessageSummaryConfiguration.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<EventConfiguration_MessageSummaryConfiguration>,
  ): EventConfiguration_MessageSummaryConfiguration {
    const message = createBaseEventConfiguration_MessageSummaryConfiguration();
    message.enabled = object.enabled ?? false;
    message.period = (object.period !== undefined && object.period !== null)
      ? Duration.fromPartial(object.period)
      : undefined;
    message.alignToTime = (object.alignToTime !== undefined && object.alignToTime !== null)
      ? Timestamp.fromPartial(object.alignToTime)
      : undefined;
    message.includeEmptySummaries = object.includeEmptySummaries ?? false;
    return message;
  },
};

function createBaseEventConfiguration_EventHealthLevelConfiguration(): EventConfiguration_EventHealthLevelConfiguration {
  return { type: EventType.DEFAULT, level: undefined, expiry: undefined, createdAt: undefined, updatedAt: undefined };
}

export const EventConfiguration_EventHealthLevelConfiguration = {
  encode(
    message: EventConfiguration_EventHealthLevelConfiguration,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== EventType.DEFAULT) {
      writer.uint32(8).int32(eventTypeToNumber(message.type));
    }
    if (message.level !== undefined) {
      HealthLevel.encode(message.level, writer.uint32(18).fork()).ldelim();
    }
    if (message.expiry !== undefined) {
      Duration.encode(message.expiry, writer.uint32(26).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(34).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventConfiguration_EventHealthLevelConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventConfiguration_EventHealthLevelConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = eventTypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.level = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expiry = Duration.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<EventConfiguration_EventHealthLevelConfiguration>,
  ): EventConfiguration_EventHealthLevelConfiguration {
    return EventConfiguration_EventHealthLevelConfiguration.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<EventConfiguration_EventHealthLevelConfiguration>,
  ): EventConfiguration_EventHealthLevelConfiguration {
    const message = createBaseEventConfiguration_EventHealthLevelConfiguration();
    message.type = object.type ?? EventType.DEFAULT;
    message.level = (object.level !== undefined && object.level !== null)
      ? HealthLevel.fromPartial(object.level)
      : undefined;
    message.expiry = (object.expiry !== undefined && object.expiry !== null)
      ? Duration.fromPartial(object.expiry)
      : undefined;
    message.createdAt = (object.createdAt !== undefined && object.createdAt !== null)
      ? Timestamp.fromPartial(object.createdAt)
      : undefined;
    message.updatedAt = (object.updatedAt !== undefined && object.updatedAt !== null)
      ? Timestamp.fromPartial(object.updatedAt)
      : undefined;
    return message;
  },
};

function createBaseEventConfiguration_Request(): EventConfiguration_Request {
  return { organization: undefined };
}

export const EventConfiguration_Request = {
  encode(message: EventConfiguration_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EventConfiguration_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEventConfiguration_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EventConfiguration_Request>): EventConfiguration_Request {
    return EventConfiguration_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EventConfiguration_Request>): EventConfiguration_Request {
    const message = createBaseEventConfiguration_Request();
    message.organization = object.organization ?? undefined;
    return message;
  },
};

function createBaseUpdateEventHealthConfiguration(): UpdateEventHealthConfiguration {
  return {};
}

export const UpdateEventHealthConfiguration = {
  encode(_: UpdateEventHealthConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEventHealthConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEventHealthConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateEventHealthConfiguration>): UpdateEventHealthConfiguration {
    return UpdateEventHealthConfiguration.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateEventHealthConfiguration>): UpdateEventHealthConfiguration {
    const message = createBaseUpdateEventHealthConfiguration();
    return message;
  },
};

function createBaseUpdateEventHealthConfiguration_UpdateEventHealth(): UpdateEventHealthConfiguration_UpdateEventHealth {
  return { type: EventType.DEFAULT, level: "", expiry: undefined, deprecatedExpiry: undefined };
}

export const UpdateEventHealthConfiguration_UpdateEventHealth = {
  encode(
    message: UpdateEventHealthConfiguration_UpdateEventHealth,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== EventType.DEFAULT) {
      writer.uint32(8).int32(eventTypeToNumber(message.type));
    }
    if (message.level !== "") {
      writer.uint32(18).string(message.level);
    }
    if (message.expiry !== undefined) {
      Duration.encode(message.expiry, writer.uint32(34).fork()).ldelim();
    }
    if (message.deprecatedExpiry !== undefined) {
      UpdateOptionalDuration.encode(message.deprecatedExpiry, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEventHealthConfiguration_UpdateEventHealth {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEventHealthConfiguration_UpdateEventHealth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = eventTypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.level = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.expiry = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedExpiry = UpdateOptionalDuration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateEventHealthConfiguration_UpdateEventHealth>,
  ): UpdateEventHealthConfiguration_UpdateEventHealth {
    return UpdateEventHealthConfiguration_UpdateEventHealth.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateEventHealthConfiguration_UpdateEventHealth>,
  ): UpdateEventHealthConfiguration_UpdateEventHealth {
    const message = createBaseUpdateEventHealthConfiguration_UpdateEventHealth();
    message.type = object.type ?? EventType.DEFAULT;
    message.level = object.level ?? "";
    message.expiry = (object.expiry !== undefined && object.expiry !== null)
      ? Duration.fromPartial(object.expiry)
      : undefined;
    message.deprecatedExpiry = (object.deprecatedExpiry !== undefined && object.deprecatedExpiry !== null)
      ? UpdateOptionalDuration.fromPartial(object.deprecatedExpiry)
      : undefined;
    return message;
  },
};

function createBaseUpdateEventHealthConfiguration_Request(): UpdateEventHealthConfiguration_Request {
  return { organization: undefined, updates: [] };
}

export const UpdateEventHealthConfiguration_Request = {
  encode(message: UpdateEventHealthConfiguration_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.updates) {
      UpdateEventHealthConfiguration_UpdateEventHealth.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEventHealthConfiguration_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEventHealthConfiguration_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updates.push(UpdateEventHealthConfiguration_UpdateEventHealth.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateEventHealthConfiguration_Request>): UpdateEventHealthConfiguration_Request {
    return UpdateEventHealthConfiguration_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateEventHealthConfiguration_Request>): UpdateEventHealthConfiguration_Request {
    const message = createBaseUpdateEventHealthConfiguration_Request();
    message.organization = object.organization ?? undefined;
    message.updates = object.updates?.map((e) => UpdateEventHealthConfiguration_UpdateEventHealth.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateEventHealthConfiguration_Response(): UpdateEventHealthConfiguration_Response {
  return { request: undefined, eventConfiguration: undefined };
}

export const UpdateEventHealthConfiguration_Response = {
  encode(message: UpdateEventHealthConfiguration_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      UpdateEventHealthConfiguration_Request.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    if (message.eventConfiguration !== undefined) {
      EventConfiguration.encode(message.eventConfiguration, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEventHealthConfiguration_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEventHealthConfiguration_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = UpdateEventHealthConfiguration_Request.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.eventConfiguration = EventConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateEventHealthConfiguration_Response>): UpdateEventHealthConfiguration_Response {
    return UpdateEventHealthConfiguration_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateEventHealthConfiguration_Response>): UpdateEventHealthConfiguration_Response {
    const message = createBaseUpdateEventHealthConfiguration_Response();
    message.request = (object.request !== undefined && object.request !== null)
      ? UpdateEventHealthConfiguration_Request.fromPartial(object.request)
      : undefined;
    message.eventConfiguration = (object.eventConfiguration !== undefined && object.eventConfiguration !== null)
      ? EventConfiguration.fromPartial(object.eventConfiguration)
      : undefined;
    return message;
  },
};

/**
 * Events are used in a number of ways in the api. With this service you can
 * search, list and stream them for your own purposes.
 *
 * Events are filtered by permission, i.e. if you cannot access the users, you would not see user-related events.
 * Requesting user-related events explicitly if you cannot access the users will return an error.
 */
export type EventServiceDefinition = typeof EventServiceDefinition;
export const EventServiceDefinition = {
  name: "EventService",
  fullName: "hiber.event.EventService",
  methods: {
    list: {
      name: "List",
      requestType: ListEventsRequest,
      requestStream: false,
      responseType: ListEventsRequest_Response,
      responseStream: false,
      options: {},
    },
    stream: {
      name: "Stream",
      requestType: EventStreamRequest,
      requestStream: false,
      responseType: Event,
      responseStream: true,
      options: {},
    },
    history: {
      name: "History",
      requestType: EventHistory_Request,
      requestStream: false,
      responseType: EventHistory_Response,
      responseStream: false,
      options: {},
    },
    modemHealth: {
      name: "ModemHealth",
      requestType: ModemHealthEvents_Request,
      requestStream: false,
      responseType: ModemHealthEvents_Response,
      responseStream: false,
      options: {},
    },
    resolve: {
      name: "Resolve",
      requestType: ResolveEvent_Request,
      requestStream: false,
      responseType: ResolveEvent_Response,
      responseStream: false,
      options: {},
    },
    markResolved: {
      name: "MarkResolved",
      requestType: MarkEventsResolved_Request,
      requestStream: false,
      responseType: MarkEventsResolved_Response,
      responseStream: false,
      options: {},
    },
    getEventConfiguration: {
      name: "GetEventConfiguration",
      requestType: EventConfiguration_Request,
      requestStream: false,
      responseType: EventConfiguration,
      responseStream: false,
      options: {},
    },
    updateEventConfiguration: {
      name: "UpdateEventConfiguration",
      requestType: EventConfiguration,
      requestStream: false,
      responseType: EventConfiguration,
      responseStream: false,
      options: {},
    },
    updateEventHealthConfiguration: {
      name: "UpdateEventHealthConfiguration",
      requestType: UpdateEventHealthConfiguration_Request,
      requestStream: false,
      responseType: UpdateEventHealthConfiguration_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
