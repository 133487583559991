// CurrentUser
import {
  AcceptOrganizationInviteRequest,
  AcceptTermsAndConditionsRequest,
  AccessibleOrganizationsRequest,
  CurrentUserRequest,
  CurrentUserServiceDefinition,
  ListOrganizationInvitesRequest,
  RequestAccessRequest,
  UpdateMissionControlSettingsRequest,
} from 'hiber-grpc/src/customer_compiled/currentuser';
import {
  ApproveUserRequest,
  CreateUserRequest,
  InviteUserRequest,
  ListAccessRequestsRequest,
  ListInvitationsRequest,
  ListUsersRequest,
  RemoveUserRequest,
  UserServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/user';
import { getGrpcClient } from './app';

export function getAccessibleOrganizations(size: number, page: number, search: string = '') {
  const request = AccessibleOrganizationsRequest.fromPartial({
    pagination: { size, page },
    search,
  });
  const client = getGrpcClient<typeof CurrentUserServiceDefinition>(CurrentUserServiceDefinition);
  return client.accessibleOrganizations(request);
}

export function getCurrentUser() {
  const request = CurrentUserRequest.fromPartial({});
  const client = getGrpcClient<typeof CurrentUserServiceDefinition>(CurrentUserServiceDefinition);
  return client.currentUser(request);
}

export function getOrganizationInvites() {
  const request = ListOrganizationInvitesRequest.fromPartial({});
  const client = getGrpcClient<typeof CurrentUserServiceDefinition>(CurrentUserServiceDefinition);
  return client.listOrganizationInvites(request);
}

export function acceptOrganizationInvite(organization: string, setAsDefault: boolean) {
  const request = AcceptOrganizationInviteRequest.fromPartial({
    organization,
    defaultOrganization: setAsDefault,
  });
  const client = getGrpcClient<typeof CurrentUserServiceDefinition>(CurrentUserServiceDefinition);
  return client.acceptOrganizationInvite(request);
}

export function getUsers(search: string, size: number, page: number) {
  const request = ListUsersRequest.fromPartial({
    pagination: { size, page },
    selection: { search },
  });
  const client = getGrpcClient<typeof UserServiceDefinition>(UserServiceDefinition);
  return client.list(request);
}

export function createUser(
  name: string,
  email: string,
  password: string,
  roles:[],
  sendPasswordResetMail = false,
  sendVerificationMail = true,
) {
  const request = CreateUserRequest.fromPartial({
    name,
    email,
    password,
    sendPasswordResetMail,
    sendVerificationMail,
    roles: { include: roles },
    // overrideAllowNoPermissions: true,
  });
  const client = getGrpcClient<typeof UserServiceDefinition>(UserServiceDefinition);
  return client.createUser(request);
}

export function inviteUser(
  email: string,
  roles: string[],
) {
  const request = InviteUserRequest.fromPartial({
    email,
    roles: { include: roles },
    // overrideAllowNoPermissions: true,
    retry: true,
  });
  const client = getGrpcClient<typeof UserServiceDefinition>(UserServiceDefinition);
  return client.invite(request);
}

export function getInvitedUsers() {
  const request = ListInvitationsRequest.fromPartial({});
  const client = getGrpcClient<typeof UserServiceDefinition>(UserServiceDefinition);
  return client.listInvitations(request);
}

export function requestUserAccessRequest(organizationId: string) {
  const request = RequestAccessRequest.fromPartial({ organization: organizationId });
  const client = getGrpcClient<typeof CurrentUserServiceDefinition>(CurrentUserServiceDefinition);
  return client.requestAccess(request);
}

export function approveRequestedUsers(
  organizationId: string,
  userIds: Array<string>,
  roles:Array<string>,
) {
  const request = ApproveUserRequest.fromPartial({
    organization: organizationId,
    userIds,
    roles: { include: roles },
  });
  const client = getGrpcClient<typeof UserServiceDefinition>(UserServiceDefinition);
  return client.approve(request);
}

export function declineRequestedUsers(userIds: Array<string>) {
  const request = RemoveUserRequest.fromPartial({ userIds });
  const client = getGrpcClient<typeof UserServiceDefinition>(UserServiceDefinition);
  return client.remove(request);
}

export function getRequestedUsers() {
  const request = ListAccessRequestsRequest.fromPartial({});
  const client = getGrpcClient<typeof UserServiceDefinition>(UserServiceDefinition);
  return client.listAccessRequests(request);
}

export function updateUserSettings(settings: any) {
  const request = UpdateMissionControlSettingsRequest.fromPartial({
    update: JSON.stringify(settings),
  });
  const client = getGrpcClient<typeof CurrentUserServiceDefinition>(CurrentUserServiceDefinition);
  return client.updateMissionControlSettings(request);
}

export function acceptTermsAndConditions(accepted: boolean) {
  const request = AcceptTermsAndConditionsRequest.fromPartial({ acceptTac: accepted });
  const client = getGrpcClient<typeof CurrentUserServiceDefinition>(CurrentUserServiceDefinition);
  return client.acceptTermsAndConditions(request);
}
