const alerts = {
  namespaced: true,
  state: {
    assets: null,
    isOpen: false,
    alerts: null,
  },
  mutations: {
    setAssets(state, res) {
      state.assets = res;
      state.isOpen = true;
    },
    closeAlerts(state) {
      state.isOpen = false;
    },
  },
  actions: {
  },
};

export default alerts;
