/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { BytesOrHex, Pagination, Pagination_Result } from "./base";

export const protobufPackage = "hiber.certificate";

export interface Certificate {
  id: number;
  name: string;
  certificate: BytesOrHex | undefined;
  hasPrivateKey: boolean;
  caCertificateName?: string | undefined;
  caCertificateId?: number | undefined;
}

export interface CertificateSelection {
  certificateIds: number[];
  search?: string | undefined;
  caCertificateIds: number[];
}

export interface ListCertificatesRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the certificates to list. Optional, when omitted or empty everything is included. */
  selection?: CertificateSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Include the actual certificate content in the response. */
  includeCertificateContentInResponse?: boolean | undefined;
}

export interface ListCertificatesRequest_Response {
  certificates: Certificate[];
  request: ListCertificatesRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface UploadCertificateRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  uploadCertificate: UploadCertificateRequest_UploadCertificate | undefined;
  caCertificate?:
    | { $case: "uploadCaCertificate"; uploadCaCertificate: UploadCertificateRequest_UploadCertificate }
    | { $case: "caCertificateId"; caCertificateId: number }
    | undefined;
  /** Include the actual certificate content in the response. */
  includeCertificateContentInResponse?: boolean | undefined;
}

export interface UploadCertificateRequest_Response {
  certificate: Certificate | undefined;
  request: UploadCertificateRequest | undefined;
}

export interface UploadCertificateRequest_UploadCertificate {
  name: string;
  certificate:
    | BytesOrHex
    | undefined;
  /** Private key is optional, and will not be retrievable from the API */
  privateKey: BytesOrHex | undefined;
}

export interface RenameCertificateRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection: CertificateSelection | undefined;
  newName: string;
  /** Include the actual certificate content in the response. */
  includeCertificateContentInResponse?: boolean | undefined;
}

export interface RenameCertificateRequest_Response {
  certificates: Certificate[];
  request: RenameCertificateRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface DeleteCertificateRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection: CertificateSelection | undefined;
}

export interface DeleteCertificateRequest_Response {
  deletedCertificateIds: number[];
  request: DeleteCertificateRequest | undefined;
}

function createBaseCertificate(): Certificate {
  return {
    id: 0,
    name: "",
    certificate: undefined,
    hasPrivateKey: false,
    caCertificateName: undefined,
    caCertificateId: undefined,
  };
}

export const Certificate = {
  encode(message: Certificate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.certificate !== undefined) {
      BytesOrHex.encode(message.certificate, writer.uint32(26).fork()).ldelim();
    }
    if (message.hasPrivateKey === true) {
      writer.uint32(32).bool(message.hasPrivateKey);
    }
    if (message.caCertificateName !== undefined) {
      writer.uint32(42).string(message.caCertificateName);
    }
    if (message.caCertificateId !== undefined) {
      writer.uint32(48).int64(message.caCertificateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Certificate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCertificate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.certificate = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hasPrivateKey = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.caCertificateName = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.caCertificateId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Certificate>): Certificate {
    return Certificate.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Certificate>): Certificate {
    const message = createBaseCertificate();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? BytesOrHex.fromPartial(object.certificate)
      : undefined;
    message.hasPrivateKey = object.hasPrivateKey ?? false;
    message.caCertificateName = object.caCertificateName ?? undefined;
    message.caCertificateId = object.caCertificateId ?? undefined;
    return message;
  },
};

function createBaseCertificateSelection(): CertificateSelection {
  return { certificateIds: [], search: undefined, caCertificateIds: [] };
}

export const CertificateSelection = {
  encode(message: CertificateSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.certificateIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.search !== undefined) {
      writer.uint32(18).string(message.search);
    }
    writer.uint32(26).fork();
    for (const v of message.caCertificateIds) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CertificateSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCertificateSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.certificateIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.certificateIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.search = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.caCertificateIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.caCertificateIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CertificateSelection>): CertificateSelection {
    return CertificateSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CertificateSelection>): CertificateSelection {
    const message = createBaseCertificateSelection();
    message.certificateIds = object.certificateIds?.map((e) => e) || [];
    message.search = object.search ?? undefined;
    message.caCertificateIds = object.caCertificateIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseListCertificatesRequest(): ListCertificatesRequest {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    includeCertificateContentInResponse: undefined,
  };
}

export const ListCertificatesRequest = {
  encode(message: ListCertificatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      CertificateSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeCertificateContentInResponse !== undefined) {
      writer.uint32(32).bool(message.includeCertificateContentInResponse);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCertificatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCertificatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = CertificateSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeCertificateContentInResponse = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListCertificatesRequest>): ListCertificatesRequest {
    return ListCertificatesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListCertificatesRequest>): ListCertificatesRequest {
    const message = createBaseListCertificatesRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? CertificateSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeCertificateContentInResponse = object.includeCertificateContentInResponse ?? undefined;
    return message;
  },
};

function createBaseListCertificatesRequest_Response(): ListCertificatesRequest_Response {
  return { certificates: [], request: undefined, pagination: undefined };
}

export const ListCertificatesRequest_Response = {
  encode(message: ListCertificatesRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.certificates) {
      Certificate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListCertificatesRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCertificatesRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCertificatesRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificates.push(Certificate.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListCertificatesRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListCertificatesRequest_Response>): ListCertificatesRequest_Response {
    return ListCertificatesRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListCertificatesRequest_Response>): ListCertificatesRequest_Response {
    const message = createBaseListCertificatesRequest_Response();
    message.certificates = object.certificates?.map((e) => Certificate.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListCertificatesRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseUploadCertificateRequest(): UploadCertificateRequest {
  return {
    organization: undefined,
    uploadCertificate: undefined,
    caCertificate: undefined,
    includeCertificateContentInResponse: undefined,
  };
}

export const UploadCertificateRequest = {
  encode(message: UploadCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.uploadCertificate !== undefined) {
      UploadCertificateRequest_UploadCertificate.encode(message.uploadCertificate, writer.uint32(18).fork()).ldelim();
    }
    switch (message.caCertificate?.$case) {
      case "uploadCaCertificate":
        UploadCertificateRequest_UploadCertificate.encode(
          message.caCertificate.uploadCaCertificate,
          writer.uint32(26).fork(),
        ).ldelim();
        break;
      case "caCertificateId":
        writer.uint32(32).int64(message.caCertificate.caCertificateId);
        break;
    }
    if (message.includeCertificateContentInResponse !== undefined) {
      writer.uint32(40).bool(message.includeCertificateContentInResponse);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uploadCertificate = UploadCertificateRequest_UploadCertificate.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.caCertificate = {
            $case: "uploadCaCertificate",
            uploadCaCertificate: UploadCertificateRequest_UploadCertificate.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.caCertificate = { $case: "caCertificateId", caCertificateId: longToNumber(reader.int64() as Long) };
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeCertificateContentInResponse = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UploadCertificateRequest>): UploadCertificateRequest {
    return UploadCertificateRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UploadCertificateRequest>): UploadCertificateRequest {
    const message = createBaseUploadCertificateRequest();
    message.organization = object.organization ?? undefined;
    message.uploadCertificate = (object.uploadCertificate !== undefined && object.uploadCertificate !== null)
      ? UploadCertificateRequest_UploadCertificate.fromPartial(object.uploadCertificate)
      : undefined;
    if (
      object.caCertificate?.$case === "uploadCaCertificate" &&
      object.caCertificate?.uploadCaCertificate !== undefined &&
      object.caCertificate?.uploadCaCertificate !== null
    ) {
      message.caCertificate = {
        $case: "uploadCaCertificate",
        uploadCaCertificate: UploadCertificateRequest_UploadCertificate.fromPartial(
          object.caCertificate.uploadCaCertificate,
        ),
      };
    }
    if (
      object.caCertificate?.$case === "caCertificateId" &&
      object.caCertificate?.caCertificateId !== undefined &&
      object.caCertificate?.caCertificateId !== null
    ) {
      message.caCertificate = { $case: "caCertificateId", caCertificateId: object.caCertificate.caCertificateId };
    }
    message.includeCertificateContentInResponse = object.includeCertificateContentInResponse ?? undefined;
    return message;
  },
};

function createBaseUploadCertificateRequest_Response(): UploadCertificateRequest_Response {
  return { certificate: undefined, request: undefined };
}

export const UploadCertificateRequest_Response = {
  encode(message: UploadCertificateRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificate !== undefined) {
      Certificate.encode(message.certificate, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UploadCertificateRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadCertificateRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadCertificateRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificate = Certificate.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = UploadCertificateRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UploadCertificateRequest_Response>): UploadCertificateRequest_Response {
    return UploadCertificateRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UploadCertificateRequest_Response>): UploadCertificateRequest_Response {
    const message = createBaseUploadCertificateRequest_Response();
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? Certificate.fromPartial(object.certificate)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? UploadCertificateRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUploadCertificateRequest_UploadCertificate(): UploadCertificateRequest_UploadCertificate {
  return { name: "", certificate: undefined, privateKey: undefined };
}

export const UploadCertificateRequest_UploadCertificate = {
  encode(message: UploadCertificateRequest_UploadCertificate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.certificate !== undefined) {
      BytesOrHex.encode(message.certificate, writer.uint32(18).fork()).ldelim();
    }
    if (message.privateKey !== undefined) {
      BytesOrHex.encode(message.privateKey, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadCertificateRequest_UploadCertificate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadCertificateRequest_UploadCertificate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.certificate = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.privateKey = BytesOrHex.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UploadCertificateRequest_UploadCertificate>): UploadCertificateRequest_UploadCertificate {
    return UploadCertificateRequest_UploadCertificate.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UploadCertificateRequest_UploadCertificate>,
  ): UploadCertificateRequest_UploadCertificate {
    const message = createBaseUploadCertificateRequest_UploadCertificate();
    message.name = object.name ?? "";
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? BytesOrHex.fromPartial(object.certificate)
      : undefined;
    message.privateKey = (object.privateKey !== undefined && object.privateKey !== null)
      ? BytesOrHex.fromPartial(object.privateKey)
      : undefined;
    return message;
  },
};

function createBaseRenameCertificateRequest(): RenameCertificateRequest {
  return { organization: undefined, selection: undefined, newName: "", includeCertificateContentInResponse: undefined };
}

export const RenameCertificateRequest = {
  encode(message: RenameCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      CertificateSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.newName !== "") {
      writer.uint32(26).string(message.newName);
    }
    if (message.includeCertificateContentInResponse !== undefined) {
      writer.uint32(32).bool(message.includeCertificateContentInResponse);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RenameCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = CertificateSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.newName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeCertificateContentInResponse = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RenameCertificateRequest>): RenameCertificateRequest {
    return RenameCertificateRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameCertificateRequest>): RenameCertificateRequest {
    const message = createBaseRenameCertificateRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? CertificateSelection.fromPartial(object.selection)
      : undefined;
    message.newName = object.newName ?? "";
    message.includeCertificateContentInResponse = object.includeCertificateContentInResponse ?? undefined;
    return message;
  },
};

function createBaseRenameCertificateRequest_Response(): RenameCertificateRequest_Response {
  return { certificates: [], request: undefined, pagination: undefined };
}

export const RenameCertificateRequest_Response = {
  encode(message: RenameCertificateRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.certificates) {
      Certificate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      RenameCertificateRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RenameCertificateRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameCertificateRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificates.push(Certificate.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = RenameCertificateRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RenameCertificateRequest_Response>): RenameCertificateRequest_Response {
    return RenameCertificateRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameCertificateRequest_Response>): RenameCertificateRequest_Response {
    const message = createBaseRenameCertificateRequest_Response();
    message.certificates = object.certificates?.map((e) => Certificate.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? RenameCertificateRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseDeleteCertificateRequest(): DeleteCertificateRequest {
  return { organization: undefined, selection: undefined };
}

export const DeleteCertificateRequest = {
  encode(message: DeleteCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      CertificateSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = CertificateSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteCertificateRequest>): DeleteCertificateRequest {
    return DeleteCertificateRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteCertificateRequest>): DeleteCertificateRequest {
    const message = createBaseDeleteCertificateRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? CertificateSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseDeleteCertificateRequest_Response(): DeleteCertificateRequest_Response {
  return { deletedCertificateIds: [], request: undefined };
}

export const DeleteCertificateRequest_Response = {
  encode(message: DeleteCertificateRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.deletedCertificateIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.request !== undefined) {
      DeleteCertificateRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteCertificateRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCertificateRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.deletedCertificateIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.deletedCertificateIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = DeleteCertificateRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteCertificateRequest_Response>): DeleteCertificateRequest_Response {
    return DeleteCertificateRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteCertificateRequest_Response>): DeleteCertificateRequest_Response {
    const message = createBaseDeleteCertificateRequest_Response();
    message.deletedCertificateIds = object.deletedCertificateIds?.map((e) => e) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? DeleteCertificateRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

export type CertificateServiceDefinition = typeof CertificateServiceDefinition;
export const CertificateServiceDefinition = {
  name: "CertificateService",
  fullName: "hiber.certificate.CertificateService",
  methods: {
    list: {
      name: "List",
      requestType: ListCertificatesRequest,
      requestStream: false,
      responseType: ListCertificatesRequest_Response,
      responseStream: false,
      options: {},
    },
    rename: {
      name: "Rename",
      requestType: RenameCertificateRequest,
      requestStream: false,
      responseType: RenameCertificateRequest_Response,
      responseStream: false,
      options: {},
    },
    upload: {
      name: "Upload",
      requestType: UploadCertificateRequest,
      requestStream: false,
      responseType: UploadCertificateRequest_Response,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteCertificateRequest,
      requestStream: false,
      responseType: DeleteCertificateRequest_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
