/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'webhook': {
    width: 48,
    height: 48,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.656 2.344a5.038 5.038 0 00-4 2.5c-1.207 2.09-.71 4.71 1.032 6.25L7 14a1.986 1.986 0 00-1.719 1A1.982 1.982 0 006 17.719 1.98 1.98 0 008.719 17a2.008 2.008 0 000-2l2.656-4.563-.875-.5a2.995 2.995 0 113-5.187 2.995 2.995 0 011.094 4.094l1.75 1C17.723 7.457 16.887 4.379 14.5 3a4.986 4.986 0 00-2.844-.656zm.094 3a1.98 1.98 0 00-.75.25c-.957.55-1.27 1.793-.719 2.75.367.64 1.031.996 1.719 1l2.625 4.562.875-.5a2.995 2.995 0 113 5.188 2.995 2.995 0 01-4.094-1.094l-1.75 1c1.38 2.387 4.457 3.223 6.844 1.844 2.387-1.38 3.223-4.457 1.844-6.844-1.207-2.09-3.73-2.988-5.938-2.25L13.72 8.344a2.008 2.008 0 000-2 1.991 1.991 0 00-1.969-1zM7 11c-2.758 0-5 2.242-5 5s2.242 5 5 5a5.019 5.019 0 004.906-4h3.375A1.99 1.99 0 0019 16a1.99 1.99 0 00-3.719-1H10v1c0 1.652-1.348 3-3 3s-3-1.348-3-3 1.348-3 3-3z"/>'
  }
})
