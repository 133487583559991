import { defaultErrorHandler } from '@/store/core/utils';
import {
  assignAlarmsRequest,
  fetchModemsAssignments,
  unassignAlarmsRequest,
  assignParsersRequest,
  unassignParsersRequest,
} from '@/services/assignment';

const Assignment = {
  namespaced: true,
  state: {},
  actions: {
    fetchModemsAssignments(context, target) {
      return new Promise((resolve, reject) => {
        const modemNrs = target.modemNrs || [];
        const include = target.include;
        const organization = target.organization;

        fetchModemsAssignments(modemNrs, include, organization)
          .then((res) => resolve(res)).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },

    assignAlarmsToModems(context, payload) {
      return new Promise((resolve, reject) => {
        const { modems, alarms } = payload;

        assignAlarmsRequest(modems, alarms)
          .then(res => resolve(res))
          .catch((res) => defaultErrorHandler(res, reject, context));
      });
    },

    unassignAlarmsFromModems(context, payload) {
      return new Promise((resolve, reject) => {
        const { modems, alarms } = payload;

        unassignAlarmsRequest(modems, alarms)
          .then(res => resolve(res))
          .catch((res) => defaultErrorHandler(res, reject, context));
      });
    },

    assignParsersToModems(context, { modems, parsers }) {
      return assignParsersRequest(modems, parsers)
        .catch((error) => defaultErrorHandler(error, Promise.reject, context));
    },

    unassignParsersFromModems(context, { modems, parsers }) {
      return unassignParsersRequest(modems, parsers)
        .catch((error) => defaultErrorHandler(error, Promise.reject, context));
    },

    changeParserAssignments(context, { modems, assigned, unassigned }) {
      const promises = [];

      if (assigned.length) {
        promises.push(context.dispatch('assignParsersToModems', {
          modems,
          parsers: assigned,
        }));
      }

      if (unassigned.length) {
        promises.push(context.dispatch('unassignParsersFromModems', {
          modems,
          parsers: unassigned,
        }));
      }

      if (promises.length) {
        return Promise.all(promises);
      }

      return Promise.reject(new Error('No parser has been selected'));
    },

    changeAlarmsAssignments(context, payload) {
      const { modems, assigned, unassigned } = payload;

      return new Promise(((resolve, reject) => {
        if (assigned.length && unassigned.length) {
          Promise.all(
            [
              context.dispatch('assignAlarmsToModems', { modems, alarms: assigned }),
              context.dispatch('unassignAlarmsFromModems', { modems, alarms: unassigned }),
            ],
          )
            .then(() => resolve())
            .catch(() => reject());
          return;
        }

        if (assigned.length) {
          context.dispatch('assignAlarmsToModems', {
            modems,
            alarms: assigned,
          })
            .then(() => resolve())
            .catch(() => reject());
          return;
        }

        if (unassigned.length) {
          context.dispatch('unassignAlarmsFromModems', {
            modems,
            alarms: unassigned,
          })
            .then(() => resolve())
            .catch(() => reject());
          return;
        }
        // eslint-disable-next-line no-console
        console.error('No Alarms were selected to perform the actions');
        reject();
      }));
    },
  },
  mutations: {},
  getters: {},
};

export default Assignment;
