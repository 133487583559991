import { getGrpcClient } from '@/services/app';

// SSO
import { SingleSignOnServiceDefinition, ZendeskTokenRequest } from 'hiber-grpc/src/customer_compiled/sso';

export function zendeskToken() {
  const request = ZendeskTokenRequest.fromPartial({});
  const client = getGrpcClient<typeof SingleSignOnServiceDefinition>(SingleSignOnServiceDefinition);
  return client.zendesk(request);
}
