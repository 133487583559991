<template>
  <button
    class='h-default-btn'
    :class='buttonClasses'
    v-on='$listeners'>
    <span class='span-inner-btn'><slot /></span>
    <span
      v-if='hasNotificationDot'
      class='h-notification-dot' />
  </button>
</template>

<script>
/* variant refer to action: save is positive, delete is negative */
const availableVariants = ['positive', 'negative', 'positiveBackground', 'negativeBackground'];

export default {
  props: {
    icon: {
      type: Boolean,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'positive',
      validator: val => availableVariants.includes(val),
    },
    hasNotificationDot: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      const classes = [];
      if (this.icon) {
        classes.push('h-icon-btn');
      }
      classes.push(`h-${this.variant}-btn`);
      return classes;
    },
  },
};
</script>
<style lang='scss'>

.h-default-btn {
  @apply tw-text-primary tw-text-center tw-no-underline tw-uppercase;
  @apply tw-border tw-border-neutral-2;
  @apply tw-bg-white;
  @apply tw-flex tw-justify-center tw-items-center;
  @apply tw-whitespace-nowrap;
  @apply tw-cursor-pointer;
  @apply tw-select-none;
  @apply tw-outline-none;
  @apply tw-relative;

  min-width: 92px;
  padding-right: 17px;
  padding-left: 17px;
  height: 46px;
  border-radius: 5px;
  transition: all ease-in-out .15s;

  &.h-icon-btn {
    width: 46px;
    min-width: 46px;

    svg {
      @apply tw-block;
    }
  }
}

.h-notification-dot {
  @apply tw-absolute;
  @apply tw-bg-roaringred;
  top: 9px;
  right: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.span-inner-btn {
  @apply tw-flex tw-justify-center tw-items-center;
  @apply tw-space-y-1.5;
  @apply tw-font-europa;
  @apply tw-text-sm tw-font-bold;
  @apply tw-relative;

  column-gap: 15px;
  letter-spacing: 0.46px;
}

.h-positiveBackground-btn:not([disabled]) {
  @apply tw-bg-primary;
  @apply tw-text-turquiose;
  @apply tw-border-primary;
}

.h-negativeBackground-btn:not([disabled]) {
  @apply tw-bg-roaringred;
  @apply tw-text-white;
  @apply tw-border-roaringred;
}

.h-positive-btn:hover:not([disabled]) {
  @apply tw-bg-primary;
  @apply tw-text-turquiose;
  @apply tw-border-primary;
}

.h-negative-btn:hover:not([disabled]) {
  @apply tw-bg-roaringred;
  @apply tw-text-white;
  @apply tw-border-roaringred;
}

:disabled {
  @apply tw-text-neutral-2;
  @apply tw-cursor-not-allowed;
}
</style>
