import { getGrpcClient } from '@/services/app';
import {
  FieldServiceDefinition,
  ListFields_Request,
  ListFieldsForModem_Request,
} from 'hiber-grpc/src/customer_compiled/field_service';

export function listFields() {
  const request = ListFields_Request.fromPartial({});
  const client = getGrpcClient<typeof FieldServiceDefinition>(FieldServiceDefinition);
  return client.list(request);
}

export function fetchFieldsForModems(modems: string[], applyUnitPreferences = true) {
  const request = ListFieldsForModem_Request.fromPartial({
    applyUnitPreferences,
    modemSelection: { modems: { include: modems } },
  });
  const client = getGrpcClient<typeof FieldServiceDefinition>(FieldServiceDefinition);
  return client.forModem(request);
}
