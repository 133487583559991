const Developers = {
  namespaced: true,
  state: {
  },
  actions: {
  },
  mutations: {
  },
  getters: {
  },
};

export default Developers;
