<template>
  <div
    class='group-label'
    :class='$attrs.class'>
    <span class='label'>{{ label }}:</span>
    <div
      :class='["tw-flex tw-items-end tw-flex-1", displayClass]'>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputGroupLabel',
  props: {
    label: { type: String, default: '' },
    column: { type: Boolean, default: false },
  },
  computed: {
    displayClass() {
      return this.column ? 'tw-flex-col tw-space-y-2' : 'tw-gap-6';
    },
  },
};
</script>

<style lang='scss' scoped>
.group-label {
  @apply tw-flex tw-flex-wrap;
  @apply tw-min-h-[52px] tw-items-center;

  textarea {
    @apply tw-min-w-[200px];
  }

  span.label {
    @apply tw-text-neutral-3 tw-italic tw-font-normal;
    @apply tw-font-lora tw-text-[14px] tw-text-left tw-tracking-tighter;
    @apply tw-min-w-[200px];
  }
}

</style>
