/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'list': {
    width: 25,
    height: 20,
    viewBox: '0 0 25 20',
    data: '<path pid="0" d="M3.2 0H.8a.8.8 0 00-.8.8v2.4a.8.8 0 00.8.8h2.4a.8.8 0 00.8-.8V.8a.8.8 0 00-.8-.8zm0 8H.8a.8.8 0 00-.8.8v2.4a.8.8 0 00.8.8h2.4a.8.8 0 00.8-.8V8.8a.8.8 0 00-.8-.8zm0 8H.8a.8.8 0 00-.8.8v2.4a.8.8 0 00.8.8h2.4a.8.8 0 00.8-.8v-2.4a.8.8 0 00-.8-.8zM24 17.2H7.2a.4.4 0 00-.4.4v.8c0 .22.18.4.4.4H24a.4.4 0 00.4-.4v-.8a.4.4 0 00-.4-.4zm0-16H7.2a.4.4 0 00-.4.4v.8c0 .22.18.4.4.4H24a.4.4 0 00.4-.4v-.8a.4.4 0 00-.4-.4zm0 8H7.2a.4.4 0 00-.4.4v.8c0 .22.18.4.4.4H24a.4.4 0 00.4-.4v-.8a.4.4 0 00-.4-.4z" _fill="#1EF5F0" fill-rule="nonzero"/>'
  }
})
