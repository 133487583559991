import { getCurrentInstance, onMounted } from 'vue';
import { CurrentUserService } from 'hiber-grpc';
import { getGrpcClient } from '@/services/app';

export default function useCheckMaintenanceMode() {
  const instance = getCurrentInstance();
  const message = 'We are currently busy with maintenance. No changes are temporarily possible.\n'
    + 'You still can view old data, all new values will be buffered and become visible later.';

  onMounted(async () => {
    try {
      await CurrentUserService.getInstance(getGrpcClient).updateUserSettings('');
    } catch (e) {
      if (!e) return;
      const isInReadOnlyMode = e.details?.toLowerCase()?.includes('read-only');
      const store = instance?.proxy?.$store;
      if (store && isInReadOnlyMode) {
        store.dispatch(
          'App/log',
          { message,
            type: 'error',
            duration: -1 },
          { root: true },
        );
      }
    }
  });
}
