/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'google': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M17.88 9.205c0-.639-.058-1.252-.165-1.841H9.136v3.481h4.902a4.138 4.138 0 01-1.817 2.716v2.259h2.943c1.723-1.567 2.716-3.875 2.716-6.615z" _fill="#4285f4"/><path pid="1" d="M9.136 18c2.46 0 4.521-.806 6.028-2.18l-2.943-2.259c-.816.54-1.86.86-3.085.86-2.372 0-4.38-1.584-5.097-3.711H.996v2.332A9.117 9.117 0 009.136 18z" _fill="#34a853"/><path pid="2" d="M4.04 10.71c-.183-.54-.286-1.117-.286-1.71s.103-1.17.285-1.71V4.958H.996a8.909 8.909 0 000 8.084z" _fill="#fbbc05"/><path pid="3" d="M9.136 3.58c1.337 0 2.538.454 3.482 1.345l2.613-2.58C13.653.891 11.59 0 9.136 0a9.117 9.117 0 00-8.14 4.958L4.039 7.29c.716-2.127 2.725-3.71 5.097-3.71z" _fill="#ea4335"/></g>'
  }
})
