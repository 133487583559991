<template>
  <button
    class='btn'
    :data-action-name='dataActionName'
    @click.stop='onClick()'>
    <span><slot name='default' /></span>
  </button>
</template>

<script>
export default {
  props: {
    dataActionName: {
      type: String,
      default: '',
    },
  },
  computed: {
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style lang='scss'>
</style>
