/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "support.device.type";

/** Preconfigured device type. */
export interface DeviceType {
  identifier: DeviceType_DeviceTypeIdentifier;
  displayIdentifier: string;
  brand: string;
  application: string;
  category: string;
  version: string;
  description: string;
}

export enum DeviceType_DeviceTypeIdentifier {
  GATEWAY = "GATEWAY",
  PRESSURE_SENSOR_1_AS = "PRESSURE_SENSOR_1_AS",
  PRESSURE_SENSOR_1_AU = "PRESSURE_SENSOR_1_AU",
  PRESSURE_SENSOR_1_EU = "PRESSURE_SENSOR_1_EU",
  PRESSURE_SENSOR_1_US = "PRESSURE_SENSOR_1_US",
  TEMPERATURE_SENSOR_1_AS = "TEMPERATURE_SENSOR_1_AS",
  TEMPERATURE_SENSOR_1_AU = "TEMPERATURE_SENSOR_1_AU",
  TEMPERATURE_SENSOR_1_EU = "TEMPERATURE_SENSOR_1_EU",
  TEMPERATURE_SENSOR_1_US = "TEMPERATURE_SENSOR_1_US",
  PRESSURE_AND_TEMPERATURE_SENSOR_1_AS = "PRESSURE_AND_TEMPERATURE_SENSOR_1_AS",
  PRESSURE_AND_TEMPERATURE_SENSOR_1_AU = "PRESSURE_AND_TEMPERATURE_SENSOR_1_AU",
  PRESSURE_AND_TEMPERATURE_SENSOR_1_EU = "PRESSURE_AND_TEMPERATURE_SENSOR_1_EU",
  PRESSURE_AND_TEMPERATURE_SENSOR_1_US = "PRESSURE_AND_TEMPERATURE_SENSOR_1_US",
  DEMO_ALL_VALUES = "DEMO_ALL_VALUES",
  GATEWAY_V2 = "GATEWAY_V2",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function deviceType_DeviceTypeIdentifierFromJSON(object: any): DeviceType_DeviceTypeIdentifier {
  switch (object) {
    case 0:
    case "GATEWAY":
      return DeviceType_DeviceTypeIdentifier.GATEWAY;
    case 1:
    case "PRESSURE_SENSOR_1_AS":
      return DeviceType_DeviceTypeIdentifier.PRESSURE_SENSOR_1_AS;
    case 2:
    case "PRESSURE_SENSOR_1_AU":
      return DeviceType_DeviceTypeIdentifier.PRESSURE_SENSOR_1_AU;
    case 3:
    case "PRESSURE_SENSOR_1_EU":
      return DeviceType_DeviceTypeIdentifier.PRESSURE_SENSOR_1_EU;
    case 4:
    case "PRESSURE_SENSOR_1_US":
      return DeviceType_DeviceTypeIdentifier.PRESSURE_SENSOR_1_US;
    case 5:
    case "TEMPERATURE_SENSOR_1_AS":
      return DeviceType_DeviceTypeIdentifier.TEMPERATURE_SENSOR_1_AS;
    case 6:
    case "TEMPERATURE_SENSOR_1_AU":
      return DeviceType_DeviceTypeIdentifier.TEMPERATURE_SENSOR_1_AU;
    case 7:
    case "TEMPERATURE_SENSOR_1_EU":
      return DeviceType_DeviceTypeIdentifier.TEMPERATURE_SENSOR_1_EU;
    case 8:
    case "TEMPERATURE_SENSOR_1_US":
      return DeviceType_DeviceTypeIdentifier.TEMPERATURE_SENSOR_1_US;
    case 9:
    case "PRESSURE_AND_TEMPERATURE_SENSOR_1_AS":
      return DeviceType_DeviceTypeIdentifier.PRESSURE_AND_TEMPERATURE_SENSOR_1_AS;
    case 10:
    case "PRESSURE_AND_TEMPERATURE_SENSOR_1_AU":
      return DeviceType_DeviceTypeIdentifier.PRESSURE_AND_TEMPERATURE_SENSOR_1_AU;
    case 11:
    case "PRESSURE_AND_TEMPERATURE_SENSOR_1_EU":
      return DeviceType_DeviceTypeIdentifier.PRESSURE_AND_TEMPERATURE_SENSOR_1_EU;
    case 12:
    case "PRESSURE_AND_TEMPERATURE_SENSOR_1_US":
      return DeviceType_DeviceTypeIdentifier.PRESSURE_AND_TEMPERATURE_SENSOR_1_US;
    case 13:
    case "DEMO_ALL_VALUES":
      return DeviceType_DeviceTypeIdentifier.DEMO_ALL_VALUES;
    case 14:
    case "GATEWAY_V2":
      return DeviceType_DeviceTypeIdentifier.GATEWAY_V2;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DeviceType_DeviceTypeIdentifier.UNRECOGNIZED;
  }
}

export function deviceType_DeviceTypeIdentifierToNumber(object: DeviceType_DeviceTypeIdentifier): number {
  switch (object) {
    case DeviceType_DeviceTypeIdentifier.GATEWAY:
      return 0;
    case DeviceType_DeviceTypeIdentifier.PRESSURE_SENSOR_1_AS:
      return 1;
    case DeviceType_DeviceTypeIdentifier.PRESSURE_SENSOR_1_AU:
      return 2;
    case DeviceType_DeviceTypeIdentifier.PRESSURE_SENSOR_1_EU:
      return 3;
    case DeviceType_DeviceTypeIdentifier.PRESSURE_SENSOR_1_US:
      return 4;
    case DeviceType_DeviceTypeIdentifier.TEMPERATURE_SENSOR_1_AS:
      return 5;
    case DeviceType_DeviceTypeIdentifier.TEMPERATURE_SENSOR_1_AU:
      return 6;
    case DeviceType_DeviceTypeIdentifier.TEMPERATURE_SENSOR_1_EU:
      return 7;
    case DeviceType_DeviceTypeIdentifier.TEMPERATURE_SENSOR_1_US:
      return 8;
    case DeviceType_DeviceTypeIdentifier.PRESSURE_AND_TEMPERATURE_SENSOR_1_AS:
      return 9;
    case DeviceType_DeviceTypeIdentifier.PRESSURE_AND_TEMPERATURE_SENSOR_1_AU:
      return 10;
    case DeviceType_DeviceTypeIdentifier.PRESSURE_AND_TEMPERATURE_SENSOR_1_EU:
      return 11;
    case DeviceType_DeviceTypeIdentifier.PRESSURE_AND_TEMPERATURE_SENSOR_1_US:
      return 12;
    case DeviceType_DeviceTypeIdentifier.DEMO_ALL_VALUES:
      return 13;
    case DeviceType_DeviceTypeIdentifier.GATEWAY_V2:
      return 14;
    case DeviceType_DeviceTypeIdentifier.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Selection object for device types. */
export interface DeviceTypeSelection {
  search?: string | undefined;
}

export interface ListDeviceTypes {
}

export enum ListDeviceTypes_Sort {
  IDENTIFIER_ASC = "IDENTIFIER_ASC",
  IDENTIFIER_DESC = "IDENTIFIER_DESC",
  BRAND_ASC = "BRAND_ASC",
  BRAND_DESC = "BRAND_DESC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function listDeviceTypes_SortFromJSON(object: any): ListDeviceTypes_Sort {
  switch (object) {
    case 0:
    case "IDENTIFIER_ASC":
      return ListDeviceTypes_Sort.IDENTIFIER_ASC;
    case 1:
    case "IDENTIFIER_DESC":
      return ListDeviceTypes_Sort.IDENTIFIER_DESC;
    case 2:
    case "BRAND_ASC":
      return ListDeviceTypes_Sort.BRAND_ASC;
    case 3:
    case "BRAND_DESC":
      return ListDeviceTypes_Sort.BRAND_DESC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListDeviceTypes_Sort.UNRECOGNIZED;
  }
}

export function listDeviceTypes_SortToNumber(object: ListDeviceTypes_Sort): number {
  switch (object) {
    case ListDeviceTypes_Sort.IDENTIFIER_ASC:
      return 0;
    case ListDeviceTypes_Sort.IDENTIFIER_DESC:
      return 1;
    case ListDeviceTypes_Sort.BRAND_ASC:
      return 2;
    case ListDeviceTypes_Sort.BRAND_DESC:
      return 3;
    case ListDeviceTypes_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListDeviceTypes_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which device types to return. */
  selection?:
    | DeviceTypeSelection
    | undefined;
  /** Sort the devices with the given sort options. */
  sortBy: ListDeviceTypes_Sort[];
}

export interface ListDeviceTypes_Response {
  deviceTypes: DeviceType[];
  request: ListDeviceTypes_Request | undefined;
}

export interface AssignDeviceType {
}

export interface AssignDeviceType_Request {
  /** The device types (display identifiers) for the device type to assign. */
  deviceType?:
    | string
    | undefined;
  /** The identifier for the device type to assign. */
  deviceTypeIdentifier?:
    | DeviceType_DeviceTypeIdentifier
    | undefined;
  /** The modems to assign the device type to. */
  modems: string[];
  /**
   * Force device type assignment.
   * Un-assigns any currently assigned parsers.
   * Only use this when prompted by the API, and if you are sure that this is what you want.
   */
  force?:
    | boolean
    | undefined;
  /**
   * Gateway device types can only be applied to... gateways.
   * Gateways are usually automatically recognised by the fact that they send Gateway messages.
   * To be able to apply Gateway device type to a device before it has sent any messages,
   * we need to force the device to be a gateway.
   * This is typically only an issue during provisioning.
   */
  forceGateway?: boolean | undefined;
}

export interface AssignDeviceType_Response {
  request: AssignDeviceType_Request | undefined;
}

export interface RemoveDeviceType {
}

export interface RemoveDeviceType_Request {
  /** The modems to unassign the device type from. */
  modems: string[];
}

export interface RemoveDeviceType_Response {
  request: RemoveDeviceType_Request | undefined;
}

function createBaseDeviceType(): DeviceType {
  return {
    identifier: DeviceType_DeviceTypeIdentifier.GATEWAY,
    displayIdentifier: "",
    brand: "",
    application: "",
    category: "",
    version: "",
    description: "",
  };
}

export const DeviceType = {
  encode(message: DeviceType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== DeviceType_DeviceTypeIdentifier.GATEWAY) {
      writer.uint32(56).int32(deviceType_DeviceTypeIdentifierToNumber(message.identifier));
    }
    if (message.displayIdentifier !== "") {
      writer.uint32(10).string(message.displayIdentifier);
    }
    if (message.brand !== "") {
      writer.uint32(18).string(message.brand);
    }
    if (message.application !== "") {
      writer.uint32(26).string(message.application);
    }
    if (message.category !== "") {
      writer.uint32(34).string(message.category);
    }
    if (message.version !== "") {
      writer.uint32(42).string(message.version);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 7:
          if (tag !== 56) {
            break;
          }

          message.identifier = deviceType_DeviceTypeIdentifierFromJSON(reader.int32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displayIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brand = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.application = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.category = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.version = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeviceType>): DeviceType {
    return DeviceType.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceType>): DeviceType {
    const message = createBaseDeviceType();
    message.identifier = object.identifier ?? DeviceType_DeviceTypeIdentifier.GATEWAY;
    message.displayIdentifier = object.displayIdentifier ?? "";
    message.brand = object.brand ?? "";
    message.application = object.application ?? "";
    message.category = object.category ?? "";
    message.version = object.version ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseDeviceTypeSelection(): DeviceTypeSelection {
  return { search: undefined };
}

export const DeviceTypeSelection = {
  encode(message: DeviceTypeSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceTypeSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceTypeSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeviceTypeSelection>): DeviceTypeSelection {
    return DeviceTypeSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceTypeSelection>): DeviceTypeSelection {
    const message = createBaseDeviceTypeSelection();
    message.search = object.search ?? undefined;
    return message;
  },
};

function createBaseListDeviceTypes(): ListDeviceTypes {
  return {};
}

export const ListDeviceTypes = {
  encode(_: ListDeviceTypes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDeviceTypes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDeviceTypes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListDeviceTypes>): ListDeviceTypes {
    return ListDeviceTypes.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListDeviceTypes>): ListDeviceTypes {
    const message = createBaseListDeviceTypes();
    return message;
  },
};

function createBaseListDeviceTypes_Request(): ListDeviceTypes_Request {
  return { organization: undefined, selection: undefined, sortBy: [] };
}

export const ListDeviceTypes_Request = {
  encode(message: ListDeviceTypes_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      DeviceTypeSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.sortBy) {
      writer.int32(listDeviceTypes_SortToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDeviceTypes_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDeviceTypes_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = DeviceTypeSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.sortBy.push(listDeviceTypes_SortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sortBy.push(listDeviceTypes_SortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListDeviceTypes_Request>): ListDeviceTypes_Request {
    return ListDeviceTypes_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDeviceTypes_Request>): ListDeviceTypes_Request {
    const message = createBaseListDeviceTypes_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? DeviceTypeSelection.fromPartial(object.selection)
      : undefined;
    message.sortBy = object.sortBy?.map((e) => e) || [];
    return message;
  },
};

function createBaseListDeviceTypes_Response(): ListDeviceTypes_Response {
  return { deviceTypes: [], request: undefined };
}

export const ListDeviceTypes_Response = {
  encode(message: ListDeviceTypes_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.deviceTypes) {
      DeviceType.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListDeviceTypes_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDeviceTypes_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDeviceTypes_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceTypes.push(DeviceType.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListDeviceTypes_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListDeviceTypes_Response>): ListDeviceTypes_Response {
    return ListDeviceTypes_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDeviceTypes_Response>): ListDeviceTypes_Response {
    const message = createBaseListDeviceTypes_Response();
    message.deviceTypes = object.deviceTypes?.map((e) => DeviceType.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListDeviceTypes_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseAssignDeviceType(): AssignDeviceType {
  return {};
}

export const AssignDeviceType = {
  encode(_: AssignDeviceType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignDeviceType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignDeviceType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignDeviceType>): AssignDeviceType {
    return AssignDeviceType.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AssignDeviceType>): AssignDeviceType {
    const message = createBaseAssignDeviceType();
    return message;
  },
};

function createBaseAssignDeviceType_Request(): AssignDeviceType_Request {
  return {
    deviceType: undefined,
    deviceTypeIdentifier: undefined,
    modems: [],
    force: undefined,
    forceGateway: undefined,
  };
}

export const AssignDeviceType_Request = {
  encode(message: AssignDeviceType_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceType !== undefined) {
      writer.uint32(10).string(message.deviceType);
    }
    if (message.deviceTypeIdentifier !== undefined) {
      writer.uint32(32).int32(deviceType_DeviceTypeIdentifierToNumber(message.deviceTypeIdentifier));
    }
    for (const v of message.modems) {
      writer.uint32(18).string(v!);
    }
    if (message.force !== undefined) {
      writer.uint32(24).bool(message.force);
    }
    if (message.forceGateway !== undefined) {
      writer.uint32(40).bool(message.forceGateway);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignDeviceType_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignDeviceType_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceType = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.deviceTypeIdentifier = deviceType_DeviceTypeIdentifierFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modems.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.force = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.forceGateway = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignDeviceType_Request>): AssignDeviceType_Request {
    return AssignDeviceType_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignDeviceType_Request>): AssignDeviceType_Request {
    const message = createBaseAssignDeviceType_Request();
    message.deviceType = object.deviceType ?? undefined;
    message.deviceTypeIdentifier = object.deviceTypeIdentifier ?? undefined;
    message.modems = object.modems?.map((e) => e) || [];
    message.force = object.force ?? undefined;
    message.forceGateway = object.forceGateway ?? undefined;
    return message;
  },
};

function createBaseAssignDeviceType_Response(): AssignDeviceType_Response {
  return { request: undefined };
}

export const AssignDeviceType_Response = {
  encode(message: AssignDeviceType_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      AssignDeviceType_Request.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignDeviceType_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignDeviceType_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = AssignDeviceType_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignDeviceType_Response>): AssignDeviceType_Response {
    return AssignDeviceType_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignDeviceType_Response>): AssignDeviceType_Response {
    const message = createBaseAssignDeviceType_Response();
    message.request = (object.request !== undefined && object.request !== null)
      ? AssignDeviceType_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseRemoveDeviceType(): RemoveDeviceType {
  return {};
}

export const RemoveDeviceType = {
  encode(_: RemoveDeviceType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDeviceType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDeviceType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RemoveDeviceType>): RemoveDeviceType {
    return RemoveDeviceType.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<RemoveDeviceType>): RemoveDeviceType {
    const message = createBaseRemoveDeviceType();
    return message;
  },
};

function createBaseRemoveDeviceType_Request(): RemoveDeviceType_Request {
  return { modems: [] };
}

export const RemoveDeviceType_Request = {
  encode(message: RemoveDeviceType_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modems) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDeviceType_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDeviceType_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RemoveDeviceType_Request>): RemoveDeviceType_Request {
    return RemoveDeviceType_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RemoveDeviceType_Request>): RemoveDeviceType_Request {
    const message = createBaseRemoveDeviceType_Request();
    message.modems = object.modems?.map((e) => e) || [];
    return message;
  },
};

function createBaseRemoveDeviceType_Response(): RemoveDeviceType_Response {
  return { request: undefined };
}

export const RemoveDeviceType_Response = {
  encode(message: RemoveDeviceType_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      RemoveDeviceType_Request.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDeviceType_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDeviceType_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = RemoveDeviceType_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RemoveDeviceType_Response>): RemoveDeviceType_Response {
    return RemoveDeviceType_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RemoveDeviceType_Response>): RemoveDeviceType_Response {
    const message = createBaseRemoveDeviceType_Response();
    message.request = (object.request !== undefined && object.request !== null)
      ? RemoveDeviceType_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

export type DeviceTypeServiceDefinition = typeof DeviceTypeServiceDefinition;
export const DeviceTypeServiceDefinition = {
  name: "DeviceTypeService",
  fullName: "support.device.type.DeviceTypeService",
  methods: {
    list: {
      name: "List",
      requestType: ListDeviceTypes_Request,
      requestStream: false,
      responseType: ListDeviceTypes_Response,
      responseStream: false,
      options: {},
    },
    assign: {
      name: "Assign",
      requestType: AssignDeviceType_Request,
      requestStream: false,
      responseType: AssignDeviceType_Response,
      responseStream: false,
      options: {},
    },
    remove: {
      name: "Remove",
      requestType: RemoveDeviceType_Request,
      requestStream: false,
      responseType: RemoveDeviceType_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
