import {
  Value_Numeric_Flow_FlowUnit,
  Value_Numeric_Pressure_PressureUnit,
  Value_Numeric_Temperature_TemperatureUnit,
} from 'hiber-grpc/src/customer_compiled/value';
import {
  UnitPreferences_Request,
  UnitPreferencesServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/unit_preferences_service';
import { camelCase, sentenceCase } from 'change-case';
import { getGrpcClient } from './app';

export const NO_PREFERENCE_VALUE = -1;
export const NO_PREFERENCE_OPTION = { name: 'No preference', type: 'Option', value: NO_PREFERENCE_VALUE };

// convert units to dropdown options and add a 'no preference' option
const unitsToOptions = (units) => [NO_PREFERENCE_OPTION].concat(Object.keys(units)
  .filter(el => el !== 'UNRECOGNIZED').map((key) => ({
    name: sentenceCase(key),
    value: units[key],
    type: 'Option',
  })));

export const UNIT_TO_OPTIONS = {
  pressure: { options: unitsToOptions(Value_Numeric_Pressure_PressureUnit), selected: null },
  temperature: { options: unitsToOptions(Value_Numeric_Temperature_TemperatureUnit), selected: null },
  flow: { options: unitsToOptions(Value_Numeric_Flow_FlowUnit), selected: null },
};

export function unitPreferences(payload?) {
  let request;

  if (!payload) {
    request = UnitPreferences_Request.fromPartial({});
  } else {
    const { unit, value } = payload;
    const $case: any = value !== NO_PREFERENCE_VALUE
      ? camelCase(`update_${unit}_preference`) : camelCase(`remove_${unit}_preference`);

    request = UnitPreferences_Request.fromPartial({
      [camelCase(unit)]: {
        $case,
        [$case]: { preference: value },
      },
    });
  }
  const client = getGrpcClient<typeof UnitPreferencesServiceDefinition>(UnitPreferencesServiceDefinition);
  return client.unitPreferences(request);
}
