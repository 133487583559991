import {
  CreateNamedLocations_CreateNamedLocation,
  CreateNamedLocations_Request,
  DeleteNamedLocation_Request,
  ListNamedLocations_Request,
  NamedLocationServiceDefinition,
  UpdateNamedLocation_Request,
} from 'hiber-grpc/src/customer_compiled/named_location';
import { getGrpcClient } from '@/services/app';
import { Shape } from 'hiber-grpc/src/customer_compiled/base';

function mapToLocation(name, path) {
  return CreateNamedLocations_CreateNamedLocation.fromPartial(
    {
      name,
      definition: {
        $case: 'shape',
        shape: { path },
      },
    },
  );
}

export function fetchNamedLocations(params: { pagination?: { size: number; page: number; }; }) {
  const { pagination = { size: 50, page: 0 } } = params;
  const request = ListNamedLocations_Request.fromPartial({ pagination });
  const client = getGrpcClient<typeof NamedLocationServiceDefinition>(NamedLocationServiceDefinition);
  return client.list(request);
}

export function deleteNamedLocation(params: { name: string }) {
  const { name } = params;
  const request = DeleteNamedLocation_Request.fromPartial({ name });
  const client = getGrpcClient<typeof NamedLocationServiceDefinition>(NamedLocationServiceDefinition);
  return client.delete(request);
}

export function createNamedLocation(params: { name: string, path: { latitude: number, longitude: number }[] }) {
  const { name, path } = params;
  const request = CreateNamedLocations_Request.fromPartial({
    create: [mapToLocation(name, path)],
  });
  const client = getGrpcClient<typeof NamedLocationServiceDefinition>(NamedLocationServiceDefinition);
  return client.create(request);
}

export function updateNamedLocation(params: {
  name: string,
  updated: {
    name: string,
    path: { latitude: number, longitude: number }[]
  }
}) {
  const { name, updated } = params;
  const request = UpdateNamedLocation_Request.fromPartial({
    name,
    updated: {
      updateName: updated.name,
      replaceDefinition: {
        $case: 'shape',
        shape: Shape.fromPartial({
          path: updated.path,
        }),
      },
    },
  });
  const client = getGrpcClient<typeof NamedLocationServiceDefinition>(NamedLocationServiceDefinition);
  return client.update(request);
}
