import Vue from 'vue';
import Oruga from '@oruga-ui/oruga';

Vue.use(Oruga, {
  iconPack: 'fal',
  iconComponent: 'font-awesome-icon',
  field: {
    messageClass: 'o-field__hiber',
  },
  button: {
    rootClass: 'hiber-btn',
  },
});
