/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { BytesOrHex, Filter_ChildOrganizations, Pagination, Pagination_Result, UpdateClearableString } from "./base";
import { Field } from "./field";
import { Struct, Value } from "./google/protobuf/struct";
import { ModemMessage_ParsedBody, ModemSelection } from "./modem";

export const protobufPackage = "hiber.modem.message.bodyparser";

/**
 * Modem message body parsers management.
 *
 * Modem message body parsers (short: parsers) are small scripts defined using the Kaitai Struct specification
 * to parse binary data.
 * Parsers are assigned to modems to parse the body of their messages dynamically.
 *
 * Parsers can be written manually as a .ksy file and uploaded through the API, or the simplified API implementation
 * can be used to create a parser.
 *
 * For more information on Kaitai Struct, see [http://kaitai.io/](http://kaitai.io/),
 * where you can find documentation, examples and a web IDE.
 */

/**
 * Modem message body parser. This defines a parsing strategy for the body of a message.
 * A parser can be defined in two ways: using a .ksy (Kaitai struct https://kaitai.io/) file or using the simple parser.
 */
export interface ModemMessageBodyParser {
  /** The globally (across organizations) unique identifier for this parser. */
  identifier: string;
  /** The organization that created the parser. */
  organization: string;
  /** The name for this parser. */
  name: string;
  /**
   * The content of this parsers script.
   * If simple_parser is set, this content is generated from that definition.
   * This field may be omitted by the list call to save data.
   */
  contentKsy: string;
  /**
   * The simple parser this .ksy was generated from, if it was generated from a simple parser.
   * This field may be omitted on demand to save data in the list call.
   */
  simpleParser:
    | SimpleModemMessageBodyParser
    | undefined;
  /**
   * Fields in the parsed result that contain data.
   * Data fields are cached for efficient retrieval and allow all kinds of processing.
   */
  dataFields: Field[];
  /** Fields in the parsed result that contain metadata, and special things like a location. */
  metadataFields:
    | ModemMessageBodyParser_MetadataFields
    | undefined;
  /** If set, this parser is available to your child organizations, as a Provided parser. */
  availableToChildOrganizations:
    | ModemMessageBodyParser_AvailableToChildOrganizations
    | undefined;
  /**
   * The list of post-processing steps applied to the result of this parser.
   *
   * @deprecated
   */
  postProcessing: ModemMessageBodyParser_PostProcessing[];
  /**
   * In order to use this parser on a message,
   * the metadata on the message must match the given requirement here.
   * The key of the map is the json-path to look for in the message metadata,
   * the value of the map is the json to expect at that json-path.
   * Deprecated: use metadata_fields.require_message_metadata
   *
   * @deprecated
   */
  requireMessageMetadata: { [key: string]: any | undefined };
}

/** The type of post-processing to be applied to the result of this parser. */
export enum ModemMessageBodyParser_PostProcessing {
  NOTHING = "NOTHING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function modemMessageBodyParser_PostProcessingFromJSON(object: any): ModemMessageBodyParser_PostProcessing {
  switch (object) {
    case 0:
    case "NOTHING":
      return ModemMessageBodyParser_PostProcessing.NOTHING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ModemMessageBodyParser_PostProcessing.UNRECOGNIZED;
  }
}

export function modemMessageBodyParser_PostProcessingToNumber(object: ModemMessageBodyParser_PostProcessing): number {
  switch (object) {
    case ModemMessageBodyParser_PostProcessing.NOTHING:
      return 0;
    case ModemMessageBodyParser_PostProcessing.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * A modem message body parser with this object is available to child organizations.
 * This means the child organization can use it, but not update or delete it.
 */
export interface ModemMessageBodyParser_AvailableToChildOrganizations {
  childOrganizations: Filter_ChildOrganizations | undefined;
}

/**
 * Fields in the parsed result that match common things that can be processed by the system,
 * like a location or battery percentage.
 */
export interface ModemMessageBodyParser_MetadataFields {
  /**
   * Specify a pair of fields in the message body that contain location data,
   * which will update the modem location and the map.
   */
  locationFields:
    | ModemMessageBodyParser_MetadataFields_LocationFields
    | undefined;
  /** Custom metadata fields, which will be added to the message metadata json. */
  messageMetadataFields: string[];
  /** Custom metadata fields, which will be added to the modem metadata json. */
  modemMetadataFields: string[];
  measuredAtField?:
    | { $case: "measuredAtTimeField"; measuredAtTimeField: string }
    | { $case: "measuredAtOffsetField"; measuredAtOffsetField: string }
    | undefined;
  /**
   * In order to use this parser on a message,
   * the metadata on the message must match the given requirement here.
   * The key of the map is the json-path to look for in the message metadata,
   * the value of the map is the json to expect at that json-path.
   */
  requireMessageMetadata: { [key: string]: any | undefined };
}

export interface ModemMessageBodyParser_MetadataFields_LocationFields {
  latitude: string;
  longitude: string;
}

export interface ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry {
  key: string;
  value: any | undefined;
}

export interface ModemMessageBodyParser_RequireMessageMetadataEntry {
  key: string;
  value: any | undefined;
}

/** Simple modem message body parser. This can be used a convenient way to create a .ksy parser. */
export interface SimpleModemMessageBodyParser {
  /**
   * Parser name.
   * When this structure is used as a custom type inside a parser, this name is a reduced to
   * a value containing only lowercase letters, numbers and underscores, like field names.
   */
  name: string;
  /** A short, single line description of the field. */
  documentation: string;
  /** The fields in this object. */
  fields: SimpleModemMessageBodyParser_Field[];
  /** Sets a default endianness for this parser's fields and any fields in custom types. */
  defaultEndian: SimpleModemMessageBodyParser_Endian;
  /** Sets a default string encoding for this parser's fields and any fields in custom types. */
  defaultTextEncoding: string;
  /**
   * A list of custom types used for custom types fields.
   * Each is effectively a simple parser, and can have its own custom types again, which can only be used within
   * that parser structure.
   * The parser name is used for custom type fields.
   */
  customTypes: SimpleModemMessageBodyParser[];
}

export enum SimpleModemMessageBodyParser_Endian {
  DEFAULT = "DEFAULT",
  LITTLE_ENDIAN = "LITTLE_ENDIAN",
  BIG_ENDIAN = "BIG_ENDIAN",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function simpleModemMessageBodyParser_EndianFromJSON(object: any): SimpleModemMessageBodyParser_Endian {
  switch (object) {
    case 0:
    case "DEFAULT":
      return SimpleModemMessageBodyParser_Endian.DEFAULT;
    case 1:
    case "LITTLE_ENDIAN":
      return SimpleModemMessageBodyParser_Endian.LITTLE_ENDIAN;
    case 2:
    case "BIG_ENDIAN":
      return SimpleModemMessageBodyParser_Endian.BIG_ENDIAN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SimpleModemMessageBodyParser_Endian.UNRECOGNIZED;
  }
}

export function simpleModemMessageBodyParser_EndianToNumber(object: SimpleModemMessageBodyParser_Endian): number {
  switch (object) {
    case SimpleModemMessageBodyParser_Endian.DEFAULT:
      return 0;
    case SimpleModemMessageBodyParser_Endian.LITTLE_ENDIAN:
      return 1;
    case SimpleModemMessageBodyParser_Endian.BIG_ENDIAN:
      return 2;
    case SimpleModemMessageBodyParser_Endian.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface SimpleModemMessageBodyParser_Field {
  /** Field name. This is a reduced to a value containing only lowercase letters, numbers and underscores. */
  name: string;
  /** A short, single line description of the field. */
  documentation: string;
  type?:
    | { $case: "integer"; integer: SimpleModemMessageBodyParser_Field_Integer }
    | { $case: "float"; float: SimpleModemMessageBodyParser_Field_Float }
    | { $case: "bytes"; bytes: SimpleModemMessageBodyParser_Field_Bytes }
    | { $case: "string"; string: SimpleModemMessageBodyParser_Field_String }
    | { $case: "magic"; magic: Uint8Array }
    | { $case: "customType"; customType: string }
    | undefined;
}

export interface SimpleModemMessageBodyParser_Field_Integer {
  /** Specifies the size of this integer in bytes. */
  sizeBytes: number;
  /** Specifies whether this integer is signed or unsigned. */
  signed: boolean;
  /** Specifies endian encoding for this field. This can be omitted if default endianness specified. */
  endian: SimpleModemMessageBodyParser_Endian;
}

export interface SimpleModemMessageBodyParser_Field_Float {
  /** Specifies the size of this floating point number in bytes. */
  sizeBytes: number;
  /** Specifies endian encoding for this field. This can be omitted if default endianness specified. */
  endian: SimpleModemMessageBodyParser_Endian;
}

export interface SimpleModemMessageBodyParser_Field_Bytes {
  size: SimpleModemMessageBodyParser_DelimitedSize | undefined;
}

export interface SimpleModemMessageBodyParser_Field_String {
  size:
    | SimpleModemMessageBodyParser_DelimitedSize
    | undefined;
  /** Specifies encoding for the string. This can be omitted if default encoding specified. */
  encoding: string;
}

/** Delimited size definition, used for Bytes and Text. */
export interface SimpleModemMessageBodyParser_DelimitedSize {
  size?:
    | { $case: "sizeBytes"; sizeBytes: number }
    | { $case: "endOfStream"; endOfStream: boolean }
    | { $case: "fieldName"; fieldName: string }
    | undefined;
  /**
   * Specify a terminator that marks the point the field terminates, optionally,
   * and we should continue to the next field. This is typically a single byte (i.e. 0x00).
   * This can be combined with a size to limit the amounts of bytes that are parsed.
   */
  terminator: Uint8Array;
}

/** Selection object for parsers. Used to select which parser to list, assign, etc. */
export interface ModemMessageBodyParserSelection {
  /** Filter parsers by their identifiers. */
  identifiers: string[];
  /** Find parsers by searching for this text in the name, content_ksy, data fields or simple parser definition. */
  search?:
    | string
    | undefined;
  /** Only return simple parsers, created using SimpleModemMessageBodyParser. */
  onlySimple?:
    | boolean
    | undefined;
  /** Only return parsers created from an uploaded .ksy file. */
  onlyKsyFile?:
    | boolean
    | undefined;
  /** Exclude parsers that are not owned by your organization (those that you did not create yourself). */
  onlyOwnedParsers?:
    | boolean
    | undefined;
  /** Only return parsers that were created by the given parent organizations. */
  ownerOrganizations: string[];
  /**
   * Only return parsers that have one of the given data fields
   * This only works when the fields are marked explicitly using the data fields option.
   */
  hasDataFields: string[];
}

/**
 * List the parser your organizations has access to. This may include inherited parsers.
 *
 * If include_content is not set, this call may omit parser specifications, like the ksy, since those are
 * typically larger multi-line strings.
 */
export interface ListModemMessageBodyParsersRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the parsers to list. Optional, when omitted or empty everything is included. */
  selection?: ModemMessageBodyParserSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Whether to omit the content in the resulting ModemMessageBodyParsers. */
  excludeContent?: boolean | undefined;
}

export interface ListModemMessageBodyParsersRequest_Response {
  parsers: ModemMessageBodyParser[];
  pagination: Pagination_Result | undefined;
  request: ListModemMessageBodyParsersRequest | undefined;
}

/** Upload a new body parser from a .ksy file. */
export interface UploadModemMessageBodyParserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** A descriptive name for this parser. */
  name: string;
  /** The ksy definition for this parser. */
  contentKsy: string;
  /**
   * Fields in the parsed result that contain data.
   * This can be useful to track which fields could be plotted, etc.
   */
  dataFields: Field[];
  /** Fields in the parsed result that match special things that can be processed by the system, like a location. */
  metadataFields?: ModemMessageBodyParser_MetadataFields | undefined;
  postProcessing: ModemMessageBodyParser_PostProcessing[];
  /**
   * In order to use this parser on a message,
   * the metadata on the message must match the given requirement here.
   * The key of the map is the json-path to look for in the message metadata,
   * the value of the map is the json to expect at that json-path.
   */
  requireMessageMetadata: { [key: string]: any | undefined };
}

export interface UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry {
  key: string;
  value: any | undefined;
}

/** Upload an updated body parser from a .ksy file, replacing the previous file. */
export interface UpdateUploadedModemMessageBodyParserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the parser that should be updated. */
  identifier: string;
  /** If set, changes the name of the parser. */
  name?:
    | string
    | undefined;
  /** The new ksy definition for this parser. */
  contentKsy?:
    | string
    | undefined;
  /** Add fields to the data fields list. */
  addDataFields: Field[];
  /** Remove fields from the data fields list. */
  removeDataFields: string[];
  /** Fields in the parsed result that match special things that can be processed by the system, like a location. */
  metadataFields?:
    | UpdateUploadedModemMessageBodyParserRequest_MetadataFields
    | undefined;
  /**
   * Add a post-processing step to the result of this parser.
   *
   * @deprecated
   */
  addPostProcessing: ModemMessageBodyParser_PostProcessing[];
  /**
   * Remove a post-processing step to the result of this parser.
   *
   * @deprecated
   */
  removePostProcessing: ModemMessageBodyParser_PostProcessing[];
}

export interface UpdateUploadedModemMessageBodyParserRequest_MetadataFields {
  /** Update the location fields. */
  updateLocationFields?:
    | ModemMessageBodyParser_MetadataFields_LocationFields
    | undefined;
  /** Add fields to the message metadata fields list. */
  addMessageMetadataFields: string[];
  /** Remove fields from the message metadata fields list. */
  removeMessageMetadataFields: string[];
  /** Replace the message metadata fields list. */
  replaceMessageMetadataFields: string[];
  /** Add fields to the modem metadata fields list. */
  addModemMetadataFields: string[];
  /** Remove fields from the modem metadata fields list. */
  removeModemMetadataFields: string[];
  /** Replace the modem metadata fields list. */
  replaceModemMetadataFields: string[];
  measuredAtField?:
    | { $case: "measuredAtTimeField"; measuredAtTimeField: string }
    | { $case: "deprecatedMeasuredAtTimeField"; deprecatedMeasuredAtTimeField: UpdateClearableString }
    | { $case: "measuredAtOffsetField"; measuredAtOffsetField: string }
    | { $case: "deprecatedMeasuredAtOffsetField"; deprecatedMeasuredAtOffsetField: UpdateClearableString }
    | undefined;
  /**
   * In order to use this parser on a message,
   * the metadata on the message must match the given requirement here.
   * The key of the map is the json-path to look for in the message metadata,
   * the value of the map is the json to expect at that json-path.
   */
  addRequireMessageMetadata: { [key: string]: any | undefined };
  /** Remove a requirement for the metadata. Remove by listing the json-path here. */
  removeRequireMessageMetadata: string[];
  /** Replaces the entire configuration for required message metadata. */
  replaceRequireMessageMetadata: { [key: string]: any | undefined };
}

export interface UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry {
  key: string;
  value: any | undefined;
}

export interface UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry {
  key: string;
  value: any | undefined;
}

/** Create a simple modem message parser, which generates a .ksy specification. */
export interface CreateSimpleModemMessageBodyParserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  parser: SimpleModemMessageBodyParser | undefined;
}

/** Update a simple modem message parser, updating the generated .ksy specification. */
export interface UpdateSimpleModemMessageBodyParserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the parser that should be updated. */
  identifier: string;
  parser: SimpleModemMessageBodyParser | undefined;
}

export interface RenameModemMessageBodyParserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the parser that should be updated. */
  identifier: string;
  /** The new name for this parser. */
  name: string;
}

/**
 * Update the child availability for a parser.
 * Parsers can be made available to child organizations, which means that they can be viewed in,
 * and assigned to modems in, child organizations.
 */
export interface UpdateChildOrganizationAvailabilityRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the parser that should be updated. */
  identifier: string;
  /** The new child organization availability filter for this parser. */
  availableToChildOrganizations: Filter_ChildOrganizations | undefined;
}

/**
 * Make this parser available to a specific child organizations.
 *
 * This will
 * - when the organization is on the exclude list, remove it
 * - when the includeAll flag is false, add it to the include list (if not already present)
 */
export interface MakeModemMessageBodyParserAvailableToChildOrganizationRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the parser that should be updated. */
  identifier: string;
  /** The child organization(s) that the parser should be available to. */
  availableTo: string[];
}

/**
 * Make this parser unavailable to a specific child organizations.
 *
 * This will
 * - when the organization is on the include list, remove it
 * - when the includeAll flag is true, add it to the exclude list (if not already present)
 */
export interface MakeModemMessageBodyParserUnavailableToChildOrganizationRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the parser that should be updated. */
  identifier: string;
  /** The child organization(s) that the parser should be unavailable to. */
  unavailableTo: string[];
}

export interface DeleteModemMessageBodyParserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** A selection of parsers to be deleted. */
  selection: ModemMessageBodyParserSelection | undefined;
}

export interface DeleteModemMessageBodyParserRequest_Response {
}

/**
 * Test a parsers on a message.
 *
 * This method provides a number of options and can be used in multiple ways:
 * - Test a real message or an uploaded message body.
 * - Test using an existing parser, an ksy definition or a simple parser definition.
 *
 * This can be used to test a parser with a modem's past messages, before assigning it,
 * or while developing a parser to validate it with an expected body.
 */
export interface TestModemMessageBodyParserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  parser?: { $case: "identifier"; identifier: string } | { $case: "contentKsy"; contentKsy: string } | {
    $case: "simpleParser";
    simpleParser: SimpleModemMessageBodyParser;
  } | undefined;
  data?:
    | { $case: "parseBytes"; parseBytes: BytesOrHex }
    | { $case: "modemMessageId"; modemMessageId: number }
    | undefined;
}

export interface TestModemMessageBodyParserRequest_Response {
  result?:
    | { $case: "parsed"; parsed: { [key: string]: any } | undefined }
    | { $case: "error"; error: string }
    | undefined;
}

/**
 * Retry a message, parsing it with all the currently assigned parsers that are in the given selection.
 * If the message was previously parsed by any of those parsers, the previous result is replaced.
 */
export interface RetryModemMessageBodyParsing {
}

export interface RetryModemMessageBodyParsing_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The messages to parse. */
  modemMessageIds: number[];
  /** A selection of parsers to apply, if they are assigned. */
  parserSelection:
    | ModemMessageBodyParserSelection
    | undefined;
  /** Re-parsing messages causes new events to be sent out. Set suppress_events to true to prevent that. */
  suppressEvents: boolean;
}

export interface RetryModemMessageBodyParsing_Response {
  parsedMessages: ModemMessage_ParsedBody[];
}

/** Directly assigned modem message parser to a modem. */
export interface AssignedModemMessageBodyParser {
  parserIdentifier: string;
  modemNumber: string;
}

/**
 * Add direct assignments.
 * If an overlapping assignment using a rule exists, it is shadowed by the direct assignment.
 *
 * Simplified version of assign.AssignDirectly.
 */
export interface AssignModemMessageBodyParsers {
}

export interface AssignModemMessageBodyParsers_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  assignParsers: ModemMessageBodyParserSelection | undefined;
  toModems: ModemSelection | undefined;
}

export interface AssignModemMessageBodyParsers_Response {
  created: AssignedModemMessageBodyParser[];
  request: AssignModemMessageBodyParsers_Request | undefined;
}

/**
 * Remove a direct assignment.
 * If an overlapping assignment using a rule exists, it is not affected, except that it is longer shadowed.
 *
 * Simplified version of assign.UnassignDirectly.
 */
export interface UnassignModemMessageBodyParsers {
}

export interface UnassignModemMessageBodyParsers_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  unassignParsers: ModemMessageBodyParserSelection | undefined;
  fromModems: ModemSelection | undefined;
}

export interface UnassignModemMessageBodyParsers_Response {
  removedDirectAssignments: AssignedModemMessageBodyParser[];
  request: UnassignModemMessageBodyParsers_Request | undefined;
}

function createBaseModemMessageBodyParser(): ModemMessageBodyParser {
  return {
    identifier: "",
    organization: "",
    name: "",
    contentKsy: "",
    simpleParser: undefined,
    dataFields: [],
    metadataFields: undefined,
    availableToChildOrganizations: undefined,
    postProcessing: [],
    requireMessageMetadata: {},
  };
}

export const ModemMessageBodyParser = {
  encode(message: ModemMessageBodyParser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(58).string(message.identifier);
    }
    if (message.organization !== "") {
      writer.uint32(66).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.contentKsy !== "") {
      writer.uint32(26).string(message.contentKsy);
    }
    if (message.simpleParser !== undefined) {
      SimpleModemMessageBodyParser.encode(message.simpleParser, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.dataFields) {
      Field.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.metadataFields !== undefined) {
      ModemMessageBodyParser_MetadataFields.encode(message.metadataFields, writer.uint32(82).fork()).ldelim();
    }
    if (message.availableToChildOrganizations !== undefined) {
      ModemMessageBodyParser_AvailableToChildOrganizations.encode(
        message.availableToChildOrganizations,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    writer.uint32(106).fork();
    for (const v of message.postProcessing) {
      writer.int32(modemMessageBodyParser_PostProcessingToNumber(v));
    }
    writer.ldelim();
    Object.entries(message.requireMessageMetadata).forEach(([key, value]) => {
      if (value !== undefined) {
        ModemMessageBodyParser_RequireMessageMetadataEntry.encode({ key: key as any, value }, writer.uint32(114).fork())
          .ldelim();
      }
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessageBodyParser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageBodyParser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 7:
          if (tag !== 58) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contentKsy = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.simpleParser = SimpleModemMessageBodyParser.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.dataFields.push(Field.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadataFields = ModemMessageBodyParser_MetadataFields.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.availableToChildOrganizations = ModemMessageBodyParser_AvailableToChildOrganizations.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 13:
          if (tag === 104) {
            message.postProcessing.push(modemMessageBodyParser_PostProcessingFromJSON(reader.int32()));

            continue;
          }

          if (tag === 106) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.postProcessing.push(modemMessageBodyParser_PostProcessingFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 14:
          if (tag !== 114) {
            break;
          }

          const entry14 = ModemMessageBodyParser_RequireMessageMetadataEntry.decode(reader, reader.uint32());
          if (entry14.value !== undefined) {
            message.requireMessageMetadata[entry14.key] = entry14.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemMessageBodyParser>): ModemMessageBodyParser {
    return ModemMessageBodyParser.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemMessageBodyParser>): ModemMessageBodyParser {
    const message = createBaseModemMessageBodyParser();
    message.identifier = object.identifier ?? "";
    message.organization = object.organization ?? "";
    message.name = object.name ?? "";
    message.contentKsy = object.contentKsy ?? "";
    message.simpleParser = (object.simpleParser !== undefined && object.simpleParser !== null)
      ? SimpleModemMessageBodyParser.fromPartial(object.simpleParser)
      : undefined;
    message.dataFields = object.dataFields?.map((e) => Field.fromPartial(e)) || [];
    message.metadataFields = (object.metadataFields !== undefined && object.metadataFields !== null)
      ? ModemMessageBodyParser_MetadataFields.fromPartial(object.metadataFields)
      : undefined;
    message.availableToChildOrganizations =
      (object.availableToChildOrganizations !== undefined && object.availableToChildOrganizations !== null)
        ? ModemMessageBodyParser_AvailableToChildOrganizations.fromPartial(object.availableToChildOrganizations)
        : undefined;
    message.postProcessing = object.postProcessing?.map((e) => e) || [];
    message.requireMessageMetadata = Object.entries(object.requireMessageMetadata ?? {}).reduce<
      { [key: string]: any | undefined }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseModemMessageBodyParser_AvailableToChildOrganizations(): ModemMessageBodyParser_AvailableToChildOrganizations {
  return { childOrganizations: undefined };
}

export const ModemMessageBodyParser_AvailableToChildOrganizations = {
  encode(
    message: ModemMessageBodyParser_AvailableToChildOrganizations,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.childOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.childOrganizations, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessageBodyParser_AvailableToChildOrganizations {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageBodyParser_AvailableToChildOrganizations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.childOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ModemMessageBodyParser_AvailableToChildOrganizations>,
  ): ModemMessageBodyParser_AvailableToChildOrganizations {
    return ModemMessageBodyParser_AvailableToChildOrganizations.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ModemMessageBodyParser_AvailableToChildOrganizations>,
  ): ModemMessageBodyParser_AvailableToChildOrganizations {
    const message = createBaseModemMessageBodyParser_AvailableToChildOrganizations();
    message.childOrganizations = (object.childOrganizations !== undefined && object.childOrganizations !== null)
      ? Filter_ChildOrganizations.fromPartial(object.childOrganizations)
      : undefined;
    return message;
  },
};

function createBaseModemMessageBodyParser_MetadataFields(): ModemMessageBodyParser_MetadataFields {
  return {
    locationFields: undefined,
    messageMetadataFields: [],
    modemMetadataFields: [],
    measuredAtField: undefined,
    requireMessageMetadata: {},
  };
}

export const ModemMessageBodyParser_MetadataFields = {
  encode(message: ModemMessageBodyParser_MetadataFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationFields !== undefined) {
      ModemMessageBodyParser_MetadataFields_LocationFields.encode(message.locationFields, writer.uint32(18).fork())
        .ldelim();
    }
    for (const v of message.messageMetadataFields) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.modemMetadataFields) {
      writer.uint32(26).string(v!);
    }
    switch (message.measuredAtField?.$case) {
      case "measuredAtTimeField":
        writer.uint32(34).string(message.measuredAtField.measuredAtTimeField);
        break;
      case "measuredAtOffsetField":
        writer.uint32(42).string(message.measuredAtField.measuredAtOffsetField);
        break;
    }
    Object.entries(message.requireMessageMetadata).forEach(([key, value]) => {
      if (value !== undefined) {
        ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry.encode(
          { key: key as any, value },
          writer.uint32(50).fork(),
        ).ldelim();
      }
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessageBodyParser_MetadataFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageBodyParser_MetadataFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationFields = ModemMessageBodyParser_MetadataFields_LocationFields.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messageMetadataFields.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modemMetadataFields.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.measuredAtField = { $case: "measuredAtTimeField", measuredAtTimeField: reader.string() };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.measuredAtField = { $case: "measuredAtOffsetField", measuredAtOffsetField: reader.string() };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry6.value !== undefined) {
            message.requireMessageMetadata[entry6.key] = entry6.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemMessageBodyParser_MetadataFields>): ModemMessageBodyParser_MetadataFields {
    return ModemMessageBodyParser_MetadataFields.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemMessageBodyParser_MetadataFields>): ModemMessageBodyParser_MetadataFields {
    const message = createBaseModemMessageBodyParser_MetadataFields();
    message.locationFields = (object.locationFields !== undefined && object.locationFields !== null)
      ? ModemMessageBodyParser_MetadataFields_LocationFields.fromPartial(object.locationFields)
      : undefined;
    message.messageMetadataFields = object.messageMetadataFields?.map((e) => e) || [];
    message.modemMetadataFields = object.modemMetadataFields?.map((e) => e) || [];
    if (
      object.measuredAtField?.$case === "measuredAtTimeField" &&
      object.measuredAtField?.measuredAtTimeField !== undefined &&
      object.measuredAtField?.measuredAtTimeField !== null
    ) {
      message.measuredAtField = {
        $case: "measuredAtTimeField",
        measuredAtTimeField: object.measuredAtField.measuredAtTimeField,
      };
    }
    if (
      object.measuredAtField?.$case === "measuredAtOffsetField" &&
      object.measuredAtField?.measuredAtOffsetField !== undefined &&
      object.measuredAtField?.measuredAtOffsetField !== null
    ) {
      message.measuredAtField = {
        $case: "measuredAtOffsetField",
        measuredAtOffsetField: object.measuredAtField.measuredAtOffsetField,
      };
    }
    message.requireMessageMetadata = Object.entries(object.requireMessageMetadata ?? {}).reduce<
      { [key: string]: any | undefined }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseModemMessageBodyParser_MetadataFields_LocationFields(): ModemMessageBodyParser_MetadataFields_LocationFields {
  return { latitude: "", longitude: "" };
}

export const ModemMessageBodyParser_MetadataFields_LocationFields = {
  encode(
    message: ModemMessageBodyParser_MetadataFields_LocationFields,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.latitude !== "") {
      writer.uint32(10).string(message.latitude);
    }
    if (message.longitude !== "") {
      writer.uint32(18).string(message.longitude);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessageBodyParser_MetadataFields_LocationFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageBodyParser_MetadataFields_LocationFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.latitude = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.longitude = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ModemMessageBodyParser_MetadataFields_LocationFields>,
  ): ModemMessageBodyParser_MetadataFields_LocationFields {
    return ModemMessageBodyParser_MetadataFields_LocationFields.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ModemMessageBodyParser_MetadataFields_LocationFields>,
  ): ModemMessageBodyParser_MetadataFields_LocationFields {
    const message = createBaseModemMessageBodyParser_MetadataFields_LocationFields();
    message.latitude = object.latitude ?? "";
    message.longitude = object.longitude ?? "";
    return message;
  },
};

function createBaseModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry(): ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry {
  return { key: "", value: undefined };
}

export const ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry = {
  encode(
    message: ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry>,
  ): ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry {
    return ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry>,
  ): ModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry {
    const message = createBaseModemMessageBodyParser_MetadataFields_RequireMessageMetadataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseModemMessageBodyParser_RequireMessageMetadataEntry(): ModemMessageBodyParser_RequireMessageMetadataEntry {
  return { key: "", value: undefined };
}

export const ModemMessageBodyParser_RequireMessageMetadataEntry = {
  encode(
    message: ModemMessageBodyParser_RequireMessageMetadataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessageBodyParser_RequireMessageMetadataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageBodyParser_RequireMessageMetadataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ModemMessageBodyParser_RequireMessageMetadataEntry>,
  ): ModemMessageBodyParser_RequireMessageMetadataEntry {
    return ModemMessageBodyParser_RequireMessageMetadataEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ModemMessageBodyParser_RequireMessageMetadataEntry>,
  ): ModemMessageBodyParser_RequireMessageMetadataEntry {
    const message = createBaseModemMessageBodyParser_RequireMessageMetadataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseSimpleModemMessageBodyParser(): SimpleModemMessageBodyParser {
  return {
    name: "",
    documentation: "",
    fields: [],
    defaultEndian: SimpleModemMessageBodyParser_Endian.DEFAULT,
    defaultTextEncoding: "",
    customTypes: [],
  };
}

export const SimpleModemMessageBodyParser = {
  encode(message: SimpleModemMessageBodyParser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.documentation !== "") {
      writer.uint32(18).string(message.documentation);
    }
    for (const v of message.fields) {
      SimpleModemMessageBodyParser_Field.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.defaultEndian !== SimpleModemMessageBodyParser_Endian.DEFAULT) {
      writer.uint32(32).int32(simpleModemMessageBodyParser_EndianToNumber(message.defaultEndian));
    }
    if (message.defaultTextEncoding !== "") {
      writer.uint32(42).string(message.defaultTextEncoding);
    }
    for (const v of message.customTypes) {
      SimpleModemMessageBodyParser.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleModemMessageBodyParser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleModemMessageBodyParser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.documentation = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fields.push(SimpleModemMessageBodyParser_Field.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.defaultEndian = simpleModemMessageBodyParser_EndianFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.defaultTextEncoding = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.customTypes.push(SimpleModemMessageBodyParser.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SimpleModemMessageBodyParser>): SimpleModemMessageBodyParser {
    return SimpleModemMessageBodyParser.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SimpleModemMessageBodyParser>): SimpleModemMessageBodyParser {
    const message = createBaseSimpleModemMessageBodyParser();
    message.name = object.name ?? "";
    message.documentation = object.documentation ?? "";
    message.fields = object.fields?.map((e) => SimpleModemMessageBodyParser_Field.fromPartial(e)) || [];
    message.defaultEndian = object.defaultEndian ?? SimpleModemMessageBodyParser_Endian.DEFAULT;
    message.defaultTextEncoding = object.defaultTextEncoding ?? "";
    message.customTypes = object.customTypes?.map((e) => SimpleModemMessageBodyParser.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSimpleModemMessageBodyParser_Field(): SimpleModemMessageBodyParser_Field {
  return { name: "", documentation: "", type: undefined };
}

export const SimpleModemMessageBodyParser_Field = {
  encode(message: SimpleModemMessageBodyParser_Field, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.documentation !== "") {
      writer.uint32(18).string(message.documentation);
    }
    switch (message.type?.$case) {
      case "integer":
        SimpleModemMessageBodyParser_Field_Integer.encode(message.type.integer, writer.uint32(26).fork()).ldelim();
        break;
      case "float":
        SimpleModemMessageBodyParser_Field_Float.encode(message.type.float, writer.uint32(34).fork()).ldelim();
        break;
      case "bytes":
        SimpleModemMessageBodyParser_Field_Bytes.encode(message.type.bytes, writer.uint32(42).fork()).ldelim();
        break;
      case "string":
        SimpleModemMessageBodyParser_Field_String.encode(message.type.string, writer.uint32(50).fork()).ldelim();
        break;
      case "magic":
        writer.uint32(58).bytes(message.type.magic);
        break;
      case "customType":
        writer.uint32(66).string(message.type.customType);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleModemMessageBodyParser_Field {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleModemMessageBodyParser_Field();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.documentation = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = {
            $case: "integer",
            integer: SimpleModemMessageBodyParser_Field_Integer.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = {
            $case: "float",
            float: SimpleModemMessageBodyParser_Field_Float.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.type = {
            $case: "bytes",
            bytes: SimpleModemMessageBodyParser_Field_Bytes.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.type = {
            $case: "string",
            string: SimpleModemMessageBodyParser_Field_String.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.type = { $case: "magic", magic: reader.bytes() };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.type = { $case: "customType", customType: reader.string() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SimpleModemMessageBodyParser_Field>): SimpleModemMessageBodyParser_Field {
    return SimpleModemMessageBodyParser_Field.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SimpleModemMessageBodyParser_Field>): SimpleModemMessageBodyParser_Field {
    const message = createBaseSimpleModemMessageBodyParser_Field();
    message.name = object.name ?? "";
    message.documentation = object.documentation ?? "";
    if (object.type?.$case === "integer" && object.type?.integer !== undefined && object.type?.integer !== null) {
      message.type = {
        $case: "integer",
        integer: SimpleModemMessageBodyParser_Field_Integer.fromPartial(object.type.integer),
      };
    }
    if (object.type?.$case === "float" && object.type?.float !== undefined && object.type?.float !== null) {
      message.type = { $case: "float", float: SimpleModemMessageBodyParser_Field_Float.fromPartial(object.type.float) };
    }
    if (object.type?.$case === "bytes" && object.type?.bytes !== undefined && object.type?.bytes !== null) {
      message.type = { $case: "bytes", bytes: SimpleModemMessageBodyParser_Field_Bytes.fromPartial(object.type.bytes) };
    }
    if (object.type?.$case === "string" && object.type?.string !== undefined && object.type?.string !== null) {
      message.type = {
        $case: "string",
        string: SimpleModemMessageBodyParser_Field_String.fromPartial(object.type.string),
      };
    }
    if (object.type?.$case === "magic" && object.type?.magic !== undefined && object.type?.magic !== null) {
      message.type = { $case: "magic", magic: object.type.magic };
    }
    if (
      object.type?.$case === "customType" && object.type?.customType !== undefined && object.type?.customType !== null
    ) {
      message.type = { $case: "customType", customType: object.type.customType };
    }
    return message;
  },
};

function createBaseSimpleModemMessageBodyParser_Field_Integer(): SimpleModemMessageBodyParser_Field_Integer {
  return { sizeBytes: 0, signed: false, endian: SimpleModemMessageBodyParser_Endian.DEFAULT };
}

export const SimpleModemMessageBodyParser_Field_Integer = {
  encode(message: SimpleModemMessageBodyParser_Field_Integer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sizeBytes !== 0) {
      writer.uint32(24).int32(message.sizeBytes);
    }
    if (message.signed === true) {
      writer.uint32(32).bool(message.signed);
    }
    if (message.endian !== SimpleModemMessageBodyParser_Endian.DEFAULT) {
      writer.uint32(40).int32(simpleModemMessageBodyParser_EndianToNumber(message.endian));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleModemMessageBodyParser_Field_Integer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleModemMessageBodyParser_Field_Integer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sizeBytes = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.signed = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.endian = simpleModemMessageBodyParser_EndianFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SimpleModemMessageBodyParser_Field_Integer>): SimpleModemMessageBodyParser_Field_Integer {
    return SimpleModemMessageBodyParser_Field_Integer.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<SimpleModemMessageBodyParser_Field_Integer>,
  ): SimpleModemMessageBodyParser_Field_Integer {
    const message = createBaseSimpleModemMessageBodyParser_Field_Integer();
    message.sizeBytes = object.sizeBytes ?? 0;
    message.signed = object.signed ?? false;
    message.endian = object.endian ?? SimpleModemMessageBodyParser_Endian.DEFAULT;
    return message;
  },
};

function createBaseSimpleModemMessageBodyParser_Field_Float(): SimpleModemMessageBodyParser_Field_Float {
  return { sizeBytes: 0, endian: SimpleModemMessageBodyParser_Endian.DEFAULT };
}

export const SimpleModemMessageBodyParser_Field_Float = {
  encode(message: SimpleModemMessageBodyParser_Field_Float, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sizeBytes !== 0) {
      writer.uint32(24).int32(message.sizeBytes);
    }
    if (message.endian !== SimpleModemMessageBodyParser_Endian.DEFAULT) {
      writer.uint32(40).int32(simpleModemMessageBodyParser_EndianToNumber(message.endian));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleModemMessageBodyParser_Field_Float {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleModemMessageBodyParser_Field_Float();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sizeBytes = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.endian = simpleModemMessageBodyParser_EndianFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SimpleModemMessageBodyParser_Field_Float>): SimpleModemMessageBodyParser_Field_Float {
    return SimpleModemMessageBodyParser_Field_Float.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SimpleModemMessageBodyParser_Field_Float>): SimpleModemMessageBodyParser_Field_Float {
    const message = createBaseSimpleModemMessageBodyParser_Field_Float();
    message.sizeBytes = object.sizeBytes ?? 0;
    message.endian = object.endian ?? SimpleModemMessageBodyParser_Endian.DEFAULT;
    return message;
  },
};

function createBaseSimpleModemMessageBodyParser_Field_Bytes(): SimpleModemMessageBodyParser_Field_Bytes {
  return { size: undefined };
}

export const SimpleModemMessageBodyParser_Field_Bytes = {
  encode(message: SimpleModemMessageBodyParser_Field_Bytes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.size !== undefined) {
      SimpleModemMessageBodyParser_DelimitedSize.encode(message.size, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleModemMessageBodyParser_Field_Bytes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleModemMessageBodyParser_Field_Bytes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.size = SimpleModemMessageBodyParser_DelimitedSize.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SimpleModemMessageBodyParser_Field_Bytes>): SimpleModemMessageBodyParser_Field_Bytes {
    return SimpleModemMessageBodyParser_Field_Bytes.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SimpleModemMessageBodyParser_Field_Bytes>): SimpleModemMessageBodyParser_Field_Bytes {
    const message = createBaseSimpleModemMessageBodyParser_Field_Bytes();
    message.size = (object.size !== undefined && object.size !== null)
      ? SimpleModemMessageBodyParser_DelimitedSize.fromPartial(object.size)
      : undefined;
    return message;
  },
};

function createBaseSimpleModemMessageBodyParser_Field_String(): SimpleModemMessageBodyParser_Field_String {
  return { size: undefined, encoding: "" };
}

export const SimpleModemMessageBodyParser_Field_String = {
  encode(message: SimpleModemMessageBodyParser_Field_String, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.size !== undefined) {
      SimpleModemMessageBodyParser_DelimitedSize.encode(message.size, writer.uint32(26).fork()).ldelim();
    }
    if (message.encoding !== "") {
      writer.uint32(34).string(message.encoding);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleModemMessageBodyParser_Field_String {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleModemMessageBodyParser_Field_String();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.size = SimpleModemMessageBodyParser_DelimitedSize.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.encoding = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SimpleModemMessageBodyParser_Field_String>): SimpleModemMessageBodyParser_Field_String {
    return SimpleModemMessageBodyParser_Field_String.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<SimpleModemMessageBodyParser_Field_String>,
  ): SimpleModemMessageBodyParser_Field_String {
    const message = createBaseSimpleModemMessageBodyParser_Field_String();
    message.size = (object.size !== undefined && object.size !== null)
      ? SimpleModemMessageBodyParser_DelimitedSize.fromPartial(object.size)
      : undefined;
    message.encoding = object.encoding ?? "";
    return message;
  },
};

function createBaseSimpleModemMessageBodyParser_DelimitedSize(): SimpleModemMessageBodyParser_DelimitedSize {
  return { size: undefined, terminator: new Uint8Array(0) };
}

export const SimpleModemMessageBodyParser_DelimitedSize = {
  encode(message: SimpleModemMessageBodyParser_DelimitedSize, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.size?.$case) {
      case "sizeBytes":
        writer.uint32(8).int32(message.size.sizeBytes);
        break;
      case "endOfStream":
        writer.uint32(16).bool(message.size.endOfStream);
        break;
      case "fieldName":
        writer.uint32(26).string(message.size.fieldName);
        break;
    }
    if (message.terminator.length !== 0) {
      writer.uint32(34).bytes(message.terminator);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleModemMessageBodyParser_DelimitedSize {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleModemMessageBodyParser_DelimitedSize();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.size = { $case: "sizeBytes", sizeBytes: reader.int32() };
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.size = { $case: "endOfStream", endOfStream: reader.bool() };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.size = { $case: "fieldName", fieldName: reader.string() };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.terminator = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SimpleModemMessageBodyParser_DelimitedSize>): SimpleModemMessageBodyParser_DelimitedSize {
    return SimpleModemMessageBodyParser_DelimitedSize.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<SimpleModemMessageBodyParser_DelimitedSize>,
  ): SimpleModemMessageBodyParser_DelimitedSize {
    const message = createBaseSimpleModemMessageBodyParser_DelimitedSize();
    if (object.size?.$case === "sizeBytes" && object.size?.sizeBytes !== undefined && object.size?.sizeBytes !== null) {
      message.size = { $case: "sizeBytes", sizeBytes: object.size.sizeBytes };
    }
    if (
      object.size?.$case === "endOfStream" &&
      object.size?.endOfStream !== undefined &&
      object.size?.endOfStream !== null
    ) {
      message.size = { $case: "endOfStream", endOfStream: object.size.endOfStream };
    }
    if (object.size?.$case === "fieldName" && object.size?.fieldName !== undefined && object.size?.fieldName !== null) {
      message.size = { $case: "fieldName", fieldName: object.size.fieldName };
    }
    message.terminator = object.terminator ?? new Uint8Array(0);
    return message;
  },
};

function createBaseModemMessageBodyParserSelection(): ModemMessageBodyParserSelection {
  return {
    identifiers: [],
    search: undefined,
    onlySimple: undefined,
    onlyKsyFile: undefined,
    onlyOwnedParsers: undefined,
    ownerOrganizations: [],
    hasDataFields: [],
  };
}

export const ModemMessageBodyParserSelection = {
  encode(message: ModemMessageBodyParserSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.identifiers) {
      writer.uint32(66).string(v!);
    }
    if (message.search !== undefined) {
      writer.uint32(18).string(message.search);
    }
    if (message.onlySimple !== undefined) {
      writer.uint32(24).bool(message.onlySimple);
    }
    if (message.onlyKsyFile !== undefined) {
      writer.uint32(32).bool(message.onlyKsyFile);
    }
    if (message.onlyOwnedParsers !== undefined) {
      writer.uint32(40).bool(message.onlyOwnedParsers);
    }
    for (const v of message.ownerOrganizations) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.hasDataFields) {
      writer.uint32(74).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemMessageBodyParserSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemMessageBodyParserSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 8:
          if (tag !== 66) {
            break;
          }

          message.identifiers.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.search = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.onlySimple = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.onlyKsyFile = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.onlyOwnedParsers = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ownerOrganizations.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.hasDataFields.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemMessageBodyParserSelection>): ModemMessageBodyParserSelection {
    return ModemMessageBodyParserSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemMessageBodyParserSelection>): ModemMessageBodyParserSelection {
    const message = createBaseModemMessageBodyParserSelection();
    message.identifiers = object.identifiers?.map((e) => e) || [];
    message.search = object.search ?? undefined;
    message.onlySimple = object.onlySimple ?? undefined;
    message.onlyKsyFile = object.onlyKsyFile ?? undefined;
    message.onlyOwnedParsers = object.onlyOwnedParsers ?? undefined;
    message.ownerOrganizations = object.ownerOrganizations?.map((e) => e) || [];
    message.hasDataFields = object.hasDataFields?.map((e) => e) || [];
    return message;
  },
};

function createBaseListModemMessageBodyParsersRequest(): ListModemMessageBodyParsersRequest {
  return { organization: undefined, selection: undefined, pagination: undefined, excludeContent: undefined };
}

export const ListModemMessageBodyParsersRequest = {
  encode(message: ListModemMessageBodyParsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      ModemMessageBodyParserSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.excludeContent !== undefined) {
      writer.uint32(32).bool(message.excludeContent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemMessageBodyParsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemMessageBodyParsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = ModemMessageBodyParserSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.excludeContent = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemMessageBodyParsersRequest>): ListModemMessageBodyParsersRequest {
    return ListModemMessageBodyParsersRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemMessageBodyParsersRequest>): ListModemMessageBodyParsersRequest {
    const message = createBaseListModemMessageBodyParsersRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ModemMessageBodyParserSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.excludeContent = object.excludeContent ?? undefined;
    return message;
  },
};

function createBaseListModemMessageBodyParsersRequest_Response(): ListModemMessageBodyParsersRequest_Response {
  return { parsers: [], pagination: undefined, request: undefined };
}

export const ListModemMessageBodyParsersRequest_Response = {
  encode(message: ListModemMessageBodyParsersRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.parsers) {
      ModemMessageBodyParser.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListModemMessageBodyParsersRequest.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemMessageBodyParsersRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemMessageBodyParsersRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parsers.push(ModemMessageBodyParser.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = ListModemMessageBodyParsersRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemMessageBodyParsersRequest_Response>): ListModemMessageBodyParsersRequest_Response {
    return ListModemMessageBodyParsersRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListModemMessageBodyParsersRequest_Response>,
  ): ListModemMessageBodyParsersRequest_Response {
    const message = createBaseListModemMessageBodyParsersRequest_Response();
    message.parsers = object.parsers?.map((e) => ModemMessageBodyParser.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? ListModemMessageBodyParsersRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUploadModemMessageBodyParserRequest(): UploadModemMessageBodyParserRequest {
  return {
    organization: undefined,
    name: "",
    contentKsy: "",
    dataFields: [],
    metadataFields: undefined,
    postProcessing: [],
    requireMessageMetadata: {},
  };
}

export const UploadModemMessageBodyParserRequest = {
  encode(message: UploadModemMessageBodyParserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.contentKsy !== "") {
      writer.uint32(26).string(message.contentKsy);
    }
    for (const v of message.dataFields) {
      Field.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.metadataFields !== undefined) {
      ModemMessageBodyParser_MetadataFields.encode(message.metadataFields, writer.uint32(82).fork()).ldelim();
    }
    writer.uint32(50).fork();
    for (const v of message.postProcessing) {
      writer.int32(modemMessageBodyParser_PostProcessingToNumber(v));
    }
    writer.ldelim();
    Object.entries(message.requireMessageMetadata).forEach(([key, value]) => {
      if (value !== undefined) {
        UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry.encode(
          { key: key as any, value },
          writer.uint32(58).fork(),
        ).ldelim();
      }
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadModemMessageBodyParserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadModemMessageBodyParserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contentKsy = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dataFields.push(Field.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadataFields = ModemMessageBodyParser_MetadataFields.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag === 48) {
            message.postProcessing.push(modemMessageBodyParser_PostProcessingFromJSON(reader.int32()));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.postProcessing.push(modemMessageBodyParser_PostProcessingFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry7.value !== undefined) {
            message.requireMessageMetadata[entry7.key] = entry7.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UploadModemMessageBodyParserRequest>): UploadModemMessageBodyParserRequest {
    return UploadModemMessageBodyParserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UploadModemMessageBodyParserRequest>): UploadModemMessageBodyParserRequest {
    const message = createBaseUploadModemMessageBodyParserRequest();
    message.organization = object.organization ?? undefined;
    message.name = object.name ?? "";
    message.contentKsy = object.contentKsy ?? "";
    message.dataFields = object.dataFields?.map((e) => Field.fromPartial(e)) || [];
    message.metadataFields = (object.metadataFields !== undefined && object.metadataFields !== null)
      ? ModemMessageBodyParser_MetadataFields.fromPartial(object.metadataFields)
      : undefined;
    message.postProcessing = object.postProcessing?.map((e) => e) || [];
    message.requireMessageMetadata = Object.entries(object.requireMessageMetadata ?? {}).reduce<
      { [key: string]: any | undefined }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseUploadModemMessageBodyParserRequest_RequireMessageMetadataEntry(): UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry {
  return { key: "", value: undefined };
}

export const UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry = {
  encode(
    message: UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadModemMessageBodyParserRequest_RequireMessageMetadataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry>,
  ): UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry {
    return UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry>,
  ): UploadModemMessageBodyParserRequest_RequireMessageMetadataEntry {
    const message = createBaseUploadModemMessageBodyParserRequest_RequireMessageMetadataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseUpdateUploadedModemMessageBodyParserRequest(): UpdateUploadedModemMessageBodyParserRequest {
  return {
    organization: undefined,
    identifier: "",
    name: undefined,
    contentKsy: undefined,
    addDataFields: [],
    removeDataFields: [],
    metadataFields: undefined,
    addPostProcessing: [],
    removePostProcessing: [],
  };
}

export const UpdateUploadedModemMessageBodyParserRequest = {
  encode(message: UpdateUploadedModemMessageBodyParserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(34).string(message.identifier);
    }
    if (message.name !== undefined) {
      writer.uint32(106).string(message.name);
    }
    if (message.contentKsy !== undefined) {
      writer.uint32(26).string(message.contentKsy);
    }
    for (const v of message.addDataFields) {
      Field.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.removeDataFields) {
      writer.uint32(50).string(v!);
    }
    if (message.metadataFields !== undefined) {
      UpdateUploadedModemMessageBodyParserRequest_MetadataFields.encode(
        message.metadataFields,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    writer.uint32(66).fork();
    for (const v of message.addPostProcessing) {
      writer.int32(modemMessageBodyParser_PostProcessingToNumber(v));
    }
    writer.ldelim();
    writer.uint32(74).fork();
    for (const v of message.removePostProcessing) {
      writer.int32(modemMessageBodyParser_PostProcessingToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUploadedModemMessageBodyParserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUploadedModemMessageBodyParserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contentKsy = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.addDataFields.push(Field.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.removeDataFields.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadataFields = UpdateUploadedModemMessageBodyParserRequest_MetadataFields.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 8:
          if (tag === 64) {
            message.addPostProcessing.push(modemMessageBodyParser_PostProcessingFromJSON(reader.int32()));

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.addPostProcessing.push(modemMessageBodyParser_PostProcessingFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 9:
          if (tag === 72) {
            message.removePostProcessing.push(modemMessageBodyParser_PostProcessingFromJSON(reader.int32()));

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.removePostProcessing.push(modemMessageBodyParser_PostProcessingFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateUploadedModemMessageBodyParserRequest>): UpdateUploadedModemMessageBodyParserRequest {
    return UpdateUploadedModemMessageBodyParserRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateUploadedModemMessageBodyParserRequest>,
  ): UpdateUploadedModemMessageBodyParserRequest {
    const message = createBaseUpdateUploadedModemMessageBodyParserRequest();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    message.name = object.name ?? undefined;
    message.contentKsy = object.contentKsy ?? undefined;
    message.addDataFields = object.addDataFields?.map((e) => Field.fromPartial(e)) || [];
    message.removeDataFields = object.removeDataFields?.map((e) => e) || [];
    message.metadataFields = (object.metadataFields !== undefined && object.metadataFields !== null)
      ? UpdateUploadedModemMessageBodyParserRequest_MetadataFields.fromPartial(object.metadataFields)
      : undefined;
    message.addPostProcessing = object.addPostProcessing?.map((e) => e) || [];
    message.removePostProcessing = object.removePostProcessing?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields(): UpdateUploadedModemMessageBodyParserRequest_MetadataFields {
  return {
    updateLocationFields: undefined,
    addMessageMetadataFields: [],
    removeMessageMetadataFields: [],
    replaceMessageMetadataFields: [],
    addModemMetadataFields: [],
    removeModemMetadataFields: [],
    replaceModemMetadataFields: [],
    measuredAtField: undefined,
    addRequireMessageMetadata: {},
    removeRequireMessageMetadata: [],
    replaceRequireMessageMetadata: {},
  };
}

export const UpdateUploadedModemMessageBodyParserRequest_MetadataFields = {
  encode(
    message: UpdateUploadedModemMessageBodyParserRequest_MetadataFields,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.updateLocationFields !== undefined) {
      ModemMessageBodyParser_MetadataFields_LocationFields.encode(
        message.updateLocationFields,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    for (const v of message.addMessageMetadataFields) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.removeMessageMetadataFields) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.replaceMessageMetadataFields) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.addModemMetadataFields) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.removeModemMetadataFields) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.replaceModemMetadataFields) {
      writer.uint32(58).string(v!);
    }
    switch (message.measuredAtField?.$case) {
      case "measuredAtTimeField":
        writer.uint32(106).string(message.measuredAtField.measuredAtTimeField);
        break;
      case "deprecatedMeasuredAtTimeField":
        UpdateClearableString.encode(message.measuredAtField.deprecatedMeasuredAtTimeField, writer.uint32(66).fork())
          .ldelim();
        break;
      case "measuredAtOffsetField":
        writer.uint32(114).string(message.measuredAtField.measuredAtOffsetField);
        break;
      case "deprecatedMeasuredAtOffsetField":
        UpdateClearableString.encode(message.measuredAtField.deprecatedMeasuredAtOffsetField, writer.uint32(74).fork())
          .ldelim();
        break;
    }
    Object.entries(message.addRequireMessageMetadata).forEach(([key, value]) => {
      if (value !== undefined) {
        UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry.encode({
          key: key as any,
          value,
        }, writer.uint32(82).fork()).ldelim();
      }
    });
    for (const v of message.removeRequireMessageMetadata) {
      writer.uint32(90).string(v!);
    }
    Object.entries(message.replaceRequireMessageMetadata).forEach(([key, value]) => {
      if (value !== undefined) {
        UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry.encode({
          key: key as any,
          value,
        }, writer.uint32(98).fork()).ldelim();
      }
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUploadedModemMessageBodyParserRequest_MetadataFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updateLocationFields = ModemMessageBodyParser_MetadataFields_LocationFields.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.addMessageMetadataFields.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.removeMessageMetadataFields.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.replaceMessageMetadataFields.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.addModemMetadataFields.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.removeModemMetadataFields.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.replaceModemMetadataFields.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.measuredAtField = { $case: "measuredAtTimeField", measuredAtTimeField: reader.string() };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.measuredAtField = {
            $case: "deprecatedMeasuredAtTimeField",
            deprecatedMeasuredAtTimeField: UpdateClearableString.decode(reader, reader.uint32()),
          };
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.measuredAtField = { $case: "measuredAtOffsetField", measuredAtOffsetField: reader.string() };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.measuredAtField = {
            $case: "deprecatedMeasuredAtOffsetField",
            deprecatedMeasuredAtOffsetField: UpdateClearableString.decode(reader, reader.uint32()),
          };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          const entry10 = UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry
            .decode(reader, reader.uint32());
          if (entry10.value !== undefined) {
            message.addRequireMessageMetadata[entry10.key] = entry10.value;
          }
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.removeRequireMessageMetadata.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          const entry12 = UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry
            .decode(reader, reader.uint32());
          if (entry12.value !== undefined) {
            message.replaceRequireMessageMetadata[entry12.key] = entry12.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateUploadedModemMessageBodyParserRequest_MetadataFields>,
  ): UpdateUploadedModemMessageBodyParserRequest_MetadataFields {
    return UpdateUploadedModemMessageBodyParserRequest_MetadataFields.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateUploadedModemMessageBodyParserRequest_MetadataFields>,
  ): UpdateUploadedModemMessageBodyParserRequest_MetadataFields {
    const message = createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields();
    message.updateLocationFields = (object.updateLocationFields !== undefined && object.updateLocationFields !== null)
      ? ModemMessageBodyParser_MetadataFields_LocationFields.fromPartial(object.updateLocationFields)
      : undefined;
    message.addMessageMetadataFields = object.addMessageMetadataFields?.map((e) => e) || [];
    message.removeMessageMetadataFields = object.removeMessageMetadataFields?.map((e) => e) || [];
    message.replaceMessageMetadataFields = object.replaceMessageMetadataFields?.map((e) => e) || [];
    message.addModemMetadataFields = object.addModemMetadataFields?.map((e) => e) || [];
    message.removeModemMetadataFields = object.removeModemMetadataFields?.map((e) => e) || [];
    message.replaceModemMetadataFields = object.replaceModemMetadataFields?.map((e) => e) || [];
    if (
      object.measuredAtField?.$case === "measuredAtTimeField" &&
      object.measuredAtField?.measuredAtTimeField !== undefined &&
      object.measuredAtField?.measuredAtTimeField !== null
    ) {
      message.measuredAtField = {
        $case: "measuredAtTimeField",
        measuredAtTimeField: object.measuredAtField.measuredAtTimeField,
      };
    }
    if (
      object.measuredAtField?.$case === "deprecatedMeasuredAtTimeField" &&
      object.measuredAtField?.deprecatedMeasuredAtTimeField !== undefined &&
      object.measuredAtField?.deprecatedMeasuredAtTimeField !== null
    ) {
      message.measuredAtField = {
        $case: "deprecatedMeasuredAtTimeField",
        deprecatedMeasuredAtTimeField: UpdateClearableString.fromPartial(
          object.measuredAtField.deprecatedMeasuredAtTimeField,
        ),
      };
    }
    if (
      object.measuredAtField?.$case === "measuredAtOffsetField" &&
      object.measuredAtField?.measuredAtOffsetField !== undefined &&
      object.measuredAtField?.measuredAtOffsetField !== null
    ) {
      message.measuredAtField = {
        $case: "measuredAtOffsetField",
        measuredAtOffsetField: object.measuredAtField.measuredAtOffsetField,
      };
    }
    if (
      object.measuredAtField?.$case === "deprecatedMeasuredAtOffsetField" &&
      object.measuredAtField?.deprecatedMeasuredAtOffsetField !== undefined &&
      object.measuredAtField?.deprecatedMeasuredAtOffsetField !== null
    ) {
      message.measuredAtField = {
        $case: "deprecatedMeasuredAtOffsetField",
        deprecatedMeasuredAtOffsetField: UpdateClearableString.fromPartial(
          object.measuredAtField.deprecatedMeasuredAtOffsetField,
        ),
      };
    }
    message.addRequireMessageMetadata = Object.entries(object.addRequireMessageMetadata ?? {}).reduce<
      { [key: string]: any | undefined }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    message.removeRequireMessageMetadata = object.removeRequireMessageMetadata?.map((e) => e) || [];
    message.replaceRequireMessageMetadata = Object.entries(object.replaceRequireMessageMetadata ?? {}).reduce<
      { [key: string]: any | undefined }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry(): UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry {
  return { key: "", value: undefined };
}

export const UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry = {
  encode(
    message: UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry>,
  ): UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry {
    return UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry.fromPartial(
      base ?? {},
    );
  },
  fromPartial(
    object: DeepPartial<UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry>,
  ): UpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry {
    const message =
      createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields_AddRequireMessageMetadataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry(): UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry {
  return { key: "", value: undefined };
}

export const UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry = {
  encode(
    message: UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry>,
  ): UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry {
    return UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry.fromPartial(
      base ?? {},
    );
  },
  fromPartial(
    object: DeepPartial<UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry>,
  ): UpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry {
    const message =
      createBaseUpdateUploadedModemMessageBodyParserRequest_MetadataFields_ReplaceRequireMessageMetadataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseCreateSimpleModemMessageBodyParserRequest(): CreateSimpleModemMessageBodyParserRequest {
  return { organization: undefined, parser: undefined };
}

export const CreateSimpleModemMessageBodyParserRequest = {
  encode(message: CreateSimpleModemMessageBodyParserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.parser !== undefined) {
      SimpleModemMessageBodyParser.encode(message.parser, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSimpleModemMessageBodyParserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSimpleModemMessageBodyParserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parser = SimpleModemMessageBodyParser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateSimpleModemMessageBodyParserRequest>): CreateSimpleModemMessageBodyParserRequest {
    return CreateSimpleModemMessageBodyParserRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<CreateSimpleModemMessageBodyParserRequest>,
  ): CreateSimpleModemMessageBodyParserRequest {
    const message = createBaseCreateSimpleModemMessageBodyParserRequest();
    message.organization = object.organization ?? undefined;
    message.parser = (object.parser !== undefined && object.parser !== null)
      ? SimpleModemMessageBodyParser.fromPartial(object.parser)
      : undefined;
    return message;
  },
};

function createBaseUpdateSimpleModemMessageBodyParserRequest(): UpdateSimpleModemMessageBodyParserRequest {
  return { organization: undefined, identifier: "", parser: undefined };
}

export const UpdateSimpleModemMessageBodyParserRequest = {
  encode(message: UpdateSimpleModemMessageBodyParserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(34).string(message.identifier);
    }
    if (message.parser !== undefined) {
      SimpleModemMessageBodyParser.encode(message.parser, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSimpleModemMessageBodyParserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSimpleModemMessageBodyParserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parser = SimpleModemMessageBodyParser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateSimpleModemMessageBodyParserRequest>): UpdateSimpleModemMessageBodyParserRequest {
    return UpdateSimpleModemMessageBodyParserRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateSimpleModemMessageBodyParserRequest>,
  ): UpdateSimpleModemMessageBodyParserRequest {
    const message = createBaseUpdateSimpleModemMessageBodyParserRequest();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    message.parser = (object.parser !== undefined && object.parser !== null)
      ? SimpleModemMessageBodyParser.fromPartial(object.parser)
      : undefined;
    return message;
  },
};

function createBaseRenameModemMessageBodyParserRequest(): RenameModemMessageBodyParserRequest {
  return { organization: undefined, identifier: "", name: "" };
}

export const RenameModemMessageBodyParserRequest = {
  encode(message: RenameModemMessageBodyParserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(34).string(message.identifier);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RenameModemMessageBodyParserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameModemMessageBodyParserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RenameModemMessageBodyParserRequest>): RenameModemMessageBodyParserRequest {
    return RenameModemMessageBodyParserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameModemMessageBodyParserRequest>): RenameModemMessageBodyParserRequest {
    const message = createBaseRenameModemMessageBodyParserRequest();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseUpdateChildOrganizationAvailabilityRequest(): UpdateChildOrganizationAvailabilityRequest {
  return { organization: undefined, identifier: "", availableToChildOrganizations: undefined };
}

export const UpdateChildOrganizationAvailabilityRequest = {
  encode(message: UpdateChildOrganizationAvailabilityRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(34).string(message.identifier);
    }
    if (message.availableToChildOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.availableToChildOrganizations, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateChildOrganizationAvailabilityRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateChildOrganizationAvailabilityRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.availableToChildOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateChildOrganizationAvailabilityRequest>): UpdateChildOrganizationAvailabilityRequest {
    return UpdateChildOrganizationAvailabilityRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateChildOrganizationAvailabilityRequest>,
  ): UpdateChildOrganizationAvailabilityRequest {
    const message = createBaseUpdateChildOrganizationAvailabilityRequest();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    message.availableToChildOrganizations =
      (object.availableToChildOrganizations !== undefined && object.availableToChildOrganizations !== null)
        ? Filter_ChildOrganizations.fromPartial(object.availableToChildOrganizations)
        : undefined;
    return message;
  },
};

function createBaseMakeModemMessageBodyParserAvailableToChildOrganizationRequest(): MakeModemMessageBodyParserAvailableToChildOrganizationRequest {
  return { organization: undefined, identifier: "", availableTo: [] };
}

export const MakeModemMessageBodyParserAvailableToChildOrganizationRequest = {
  encode(
    message: MakeModemMessageBodyParserAvailableToChildOrganizationRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    for (const v of message.availableTo) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MakeModemMessageBodyParserAvailableToChildOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMakeModemMessageBodyParserAvailableToChildOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.availableTo.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<MakeModemMessageBodyParserAvailableToChildOrganizationRequest>,
  ): MakeModemMessageBodyParserAvailableToChildOrganizationRequest {
    return MakeModemMessageBodyParserAvailableToChildOrganizationRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<MakeModemMessageBodyParserAvailableToChildOrganizationRequest>,
  ): MakeModemMessageBodyParserAvailableToChildOrganizationRequest {
    const message = createBaseMakeModemMessageBodyParserAvailableToChildOrganizationRequest();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    message.availableTo = object.availableTo?.map((e) => e) || [];
    return message;
  },
};

function createBaseMakeModemMessageBodyParserUnavailableToChildOrganizationRequest(): MakeModemMessageBodyParserUnavailableToChildOrganizationRequest {
  return { organization: undefined, identifier: "", unavailableTo: [] };
}

export const MakeModemMessageBodyParserUnavailableToChildOrganizationRequest = {
  encode(
    message: MakeModemMessageBodyParserUnavailableToChildOrganizationRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    for (const v of message.unavailableTo) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MakeModemMessageBodyParserUnavailableToChildOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMakeModemMessageBodyParserUnavailableToChildOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.unavailableTo.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<MakeModemMessageBodyParserUnavailableToChildOrganizationRequest>,
  ): MakeModemMessageBodyParserUnavailableToChildOrganizationRequest {
    return MakeModemMessageBodyParserUnavailableToChildOrganizationRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<MakeModemMessageBodyParserUnavailableToChildOrganizationRequest>,
  ): MakeModemMessageBodyParserUnavailableToChildOrganizationRequest {
    const message = createBaseMakeModemMessageBodyParserUnavailableToChildOrganizationRequest();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    message.unavailableTo = object.unavailableTo?.map((e) => e) || [];
    return message;
  },
};

function createBaseDeleteModemMessageBodyParserRequest(): DeleteModemMessageBodyParserRequest {
  return { organization: undefined, selection: undefined };
}

export const DeleteModemMessageBodyParserRequest = {
  encode(message: DeleteModemMessageBodyParserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      ModemMessageBodyParserSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteModemMessageBodyParserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteModemMessageBodyParserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = ModemMessageBodyParserSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteModemMessageBodyParserRequest>): DeleteModemMessageBodyParserRequest {
    return DeleteModemMessageBodyParserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteModemMessageBodyParserRequest>): DeleteModemMessageBodyParserRequest {
    const message = createBaseDeleteModemMessageBodyParserRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ModemMessageBodyParserSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseDeleteModemMessageBodyParserRequest_Response(): DeleteModemMessageBodyParserRequest_Response {
  return {};
}

export const DeleteModemMessageBodyParserRequest_Response = {
  encode(_: DeleteModemMessageBodyParserRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteModemMessageBodyParserRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteModemMessageBodyParserRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<DeleteModemMessageBodyParserRequest_Response>,
  ): DeleteModemMessageBodyParserRequest_Response {
    return DeleteModemMessageBodyParserRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<DeleteModemMessageBodyParserRequest_Response>,
  ): DeleteModemMessageBodyParserRequest_Response {
    const message = createBaseDeleteModemMessageBodyParserRequest_Response();
    return message;
  },
};

function createBaseTestModemMessageBodyParserRequest(): TestModemMessageBodyParserRequest {
  return { organization: undefined, parser: undefined, data: undefined };
}

export const TestModemMessageBodyParserRequest = {
  encode(message: TestModemMessageBodyParserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    switch (message.parser?.$case) {
      case "identifier":
        writer.uint32(58).string(message.parser.identifier);
        break;
      case "contentKsy":
        writer.uint32(18).string(message.parser.contentKsy);
        break;
      case "simpleParser":
        SimpleModemMessageBodyParser.encode(message.parser.simpleParser, writer.uint32(26).fork()).ldelim();
        break;
    }
    switch (message.data?.$case) {
      case "parseBytes":
        BytesOrHex.encode(message.data.parseBytes, writer.uint32(34).fork()).ldelim();
        break;
      case "modemMessageId":
        writer.uint32(40).uint64(message.data.modemMessageId);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestModemMessageBodyParserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestModemMessageBodyParserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.parser = { $case: "identifier", identifier: reader.string() };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parser = { $case: "contentKsy", contentKsy: reader.string() };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parser = {
            $case: "simpleParser",
            simpleParser: SimpleModemMessageBodyParser.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.data = { $case: "parseBytes", parseBytes: BytesOrHex.decode(reader, reader.uint32()) };
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.data = { $case: "modemMessageId", modemMessageId: longToNumber(reader.uint64() as Long) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TestModemMessageBodyParserRequest>): TestModemMessageBodyParserRequest {
    return TestModemMessageBodyParserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TestModemMessageBodyParserRequest>): TestModemMessageBodyParserRequest {
    const message = createBaseTestModemMessageBodyParserRequest();
    message.organization = object.organization ?? undefined;
    if (
      object.parser?.$case === "identifier" &&
      object.parser?.identifier !== undefined &&
      object.parser?.identifier !== null
    ) {
      message.parser = { $case: "identifier", identifier: object.parser.identifier };
    }
    if (
      object.parser?.$case === "contentKsy" &&
      object.parser?.contentKsy !== undefined &&
      object.parser?.contentKsy !== null
    ) {
      message.parser = { $case: "contentKsy", contentKsy: object.parser.contentKsy };
    }
    if (
      object.parser?.$case === "simpleParser" &&
      object.parser?.simpleParser !== undefined &&
      object.parser?.simpleParser !== null
    ) {
      message.parser = {
        $case: "simpleParser",
        simpleParser: SimpleModemMessageBodyParser.fromPartial(object.parser.simpleParser),
      };
    }
    if (
      object.data?.$case === "parseBytes" && object.data?.parseBytes !== undefined && object.data?.parseBytes !== null
    ) {
      message.data = { $case: "parseBytes", parseBytes: BytesOrHex.fromPartial(object.data.parseBytes) };
    }
    if (
      object.data?.$case === "modemMessageId" &&
      object.data?.modemMessageId !== undefined &&
      object.data?.modemMessageId !== null
    ) {
      message.data = { $case: "modemMessageId", modemMessageId: object.data.modemMessageId };
    }
    return message;
  },
};

function createBaseTestModemMessageBodyParserRequest_Response(): TestModemMessageBodyParserRequest_Response {
  return { result: undefined };
}

export const TestModemMessageBodyParserRequest_Response = {
  encode(message: TestModemMessageBodyParserRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.result?.$case) {
      case "parsed":
        Struct.encode(Struct.wrap(message.result.parsed), writer.uint32(10).fork()).ldelim();
        break;
      case "error":
        writer.uint32(18).string(message.result.error);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestModemMessageBodyParserRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestModemMessageBodyParserRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.result = { $case: "parsed", parsed: Struct.unwrap(Struct.decode(reader, reader.uint32())) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.result = { $case: "error", error: reader.string() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TestModemMessageBodyParserRequest_Response>): TestModemMessageBodyParserRequest_Response {
    return TestModemMessageBodyParserRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<TestModemMessageBodyParserRequest_Response>,
  ): TestModemMessageBodyParserRequest_Response {
    const message = createBaseTestModemMessageBodyParserRequest_Response();
    if (object.result?.$case === "parsed" && object.result?.parsed !== undefined && object.result?.parsed !== null) {
      message.result = { $case: "parsed", parsed: object.result.parsed };
    }
    if (object.result?.$case === "error" && object.result?.error !== undefined && object.result?.error !== null) {
      message.result = { $case: "error", error: object.result.error };
    }
    return message;
  },
};

function createBaseRetryModemMessageBodyParsing(): RetryModemMessageBodyParsing {
  return {};
}

export const RetryModemMessageBodyParsing = {
  encode(_: RetryModemMessageBodyParsing, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RetryModemMessageBodyParsing {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRetryModemMessageBodyParsing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RetryModemMessageBodyParsing>): RetryModemMessageBodyParsing {
    return RetryModemMessageBodyParsing.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<RetryModemMessageBodyParsing>): RetryModemMessageBodyParsing {
    const message = createBaseRetryModemMessageBodyParsing();
    return message;
  },
};

function createBaseRetryModemMessageBodyParsing_Request(): RetryModemMessageBodyParsing_Request {
  return { organization: undefined, modemMessageIds: [], parserSelection: undefined, suppressEvents: false };
}

export const RetryModemMessageBodyParsing_Request = {
  encode(message: RetryModemMessageBodyParsing_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    writer.uint32(18).fork();
    for (const v of message.modemMessageIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    if (message.parserSelection !== undefined) {
      ModemMessageBodyParserSelection.encode(message.parserSelection, writer.uint32(26).fork()).ldelim();
    }
    if (message.suppressEvents === true) {
      writer.uint32(32).bool(message.suppressEvents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RetryModemMessageBodyParsing_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRetryModemMessageBodyParsing_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.modemMessageIds.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.modemMessageIds.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parserSelection = ModemMessageBodyParserSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.suppressEvents = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RetryModemMessageBodyParsing_Request>): RetryModemMessageBodyParsing_Request {
    return RetryModemMessageBodyParsing_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RetryModemMessageBodyParsing_Request>): RetryModemMessageBodyParsing_Request {
    const message = createBaseRetryModemMessageBodyParsing_Request();
    message.organization = object.organization ?? undefined;
    message.modemMessageIds = object.modemMessageIds?.map((e) => e) || [];
    message.parserSelection = (object.parserSelection !== undefined && object.parserSelection !== null)
      ? ModemMessageBodyParserSelection.fromPartial(object.parserSelection)
      : undefined;
    message.suppressEvents = object.suppressEvents ?? false;
    return message;
  },
};

function createBaseRetryModemMessageBodyParsing_Response(): RetryModemMessageBodyParsing_Response {
  return { parsedMessages: [] };
}

export const RetryModemMessageBodyParsing_Response = {
  encode(message: RetryModemMessageBodyParsing_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.parsedMessages) {
      ModemMessage_ParsedBody.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RetryModemMessageBodyParsing_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRetryModemMessageBodyParsing_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parsedMessages.push(ModemMessage_ParsedBody.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RetryModemMessageBodyParsing_Response>): RetryModemMessageBodyParsing_Response {
    return RetryModemMessageBodyParsing_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RetryModemMessageBodyParsing_Response>): RetryModemMessageBodyParsing_Response {
    const message = createBaseRetryModemMessageBodyParsing_Response();
    message.parsedMessages = object.parsedMessages?.map((e) => ModemMessage_ParsedBody.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAssignedModemMessageBodyParser(): AssignedModemMessageBodyParser {
  return { parserIdentifier: "", modemNumber: "" };
}

export const AssignedModemMessageBodyParser = {
  encode(message: AssignedModemMessageBodyParser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parserIdentifier !== "") {
      writer.uint32(10).string(message.parserIdentifier);
    }
    if (message.modemNumber !== "") {
      writer.uint32(18).string(message.modemNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignedModemMessageBodyParser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignedModemMessageBodyParser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parserIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignedModemMessageBodyParser>): AssignedModemMessageBodyParser {
    return AssignedModemMessageBodyParser.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignedModemMessageBodyParser>): AssignedModemMessageBodyParser {
    const message = createBaseAssignedModemMessageBodyParser();
    message.parserIdentifier = object.parserIdentifier ?? "";
    message.modemNumber = object.modemNumber ?? "";
    return message;
  },
};

function createBaseAssignModemMessageBodyParsers(): AssignModemMessageBodyParsers {
  return {};
}

export const AssignModemMessageBodyParsers = {
  encode(_: AssignModemMessageBodyParsers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignModemMessageBodyParsers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignModemMessageBodyParsers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignModemMessageBodyParsers>): AssignModemMessageBodyParsers {
    return AssignModemMessageBodyParsers.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AssignModemMessageBodyParsers>): AssignModemMessageBodyParsers {
    const message = createBaseAssignModemMessageBodyParsers();
    return message;
  },
};

function createBaseAssignModemMessageBodyParsers_Request(): AssignModemMessageBodyParsers_Request {
  return { organization: undefined, assignParsers: undefined, toModems: undefined };
}

export const AssignModemMessageBodyParsers_Request = {
  encode(message: AssignModemMessageBodyParsers_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.assignParsers !== undefined) {
      ModemMessageBodyParserSelection.encode(message.assignParsers, writer.uint32(18).fork()).ldelim();
    }
    if (message.toModems !== undefined) {
      ModemSelection.encode(message.toModems, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignModemMessageBodyParsers_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignModemMessageBodyParsers_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assignParsers = ModemMessageBodyParserSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.toModems = ModemSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignModemMessageBodyParsers_Request>): AssignModemMessageBodyParsers_Request {
    return AssignModemMessageBodyParsers_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignModemMessageBodyParsers_Request>): AssignModemMessageBodyParsers_Request {
    const message = createBaseAssignModemMessageBodyParsers_Request();
    message.organization = object.organization ?? undefined;
    message.assignParsers = (object.assignParsers !== undefined && object.assignParsers !== null)
      ? ModemMessageBodyParserSelection.fromPartial(object.assignParsers)
      : undefined;
    message.toModems = (object.toModems !== undefined && object.toModems !== null)
      ? ModemSelection.fromPartial(object.toModems)
      : undefined;
    return message;
  },
};

function createBaseAssignModemMessageBodyParsers_Response(): AssignModemMessageBodyParsers_Response {
  return { created: [], request: undefined };
}

export const AssignModemMessageBodyParsers_Response = {
  encode(message: AssignModemMessageBodyParsers_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.created) {
      AssignedModemMessageBodyParser.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      AssignModemMessageBodyParsers_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignModemMessageBodyParsers_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignModemMessageBodyParsers_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.created.push(AssignedModemMessageBodyParser.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = AssignModemMessageBodyParsers_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignModemMessageBodyParsers_Response>): AssignModemMessageBodyParsers_Response {
    return AssignModemMessageBodyParsers_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignModemMessageBodyParsers_Response>): AssignModemMessageBodyParsers_Response {
    const message = createBaseAssignModemMessageBodyParsers_Response();
    message.created = object.created?.map((e) => AssignedModemMessageBodyParser.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? AssignModemMessageBodyParsers_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUnassignModemMessageBodyParsers(): UnassignModemMessageBodyParsers {
  return {};
}

export const UnassignModemMessageBodyParsers = {
  encode(_: UnassignModemMessageBodyParsers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignModemMessageBodyParsers {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignModemMessageBodyParsers();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignModemMessageBodyParsers>): UnassignModemMessageBodyParsers {
    return UnassignModemMessageBodyParsers.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UnassignModemMessageBodyParsers>): UnassignModemMessageBodyParsers {
    const message = createBaseUnassignModemMessageBodyParsers();
    return message;
  },
};

function createBaseUnassignModemMessageBodyParsers_Request(): UnassignModemMessageBodyParsers_Request {
  return { organization: undefined, unassignParsers: undefined, fromModems: undefined };
}

export const UnassignModemMessageBodyParsers_Request = {
  encode(message: UnassignModemMessageBodyParsers_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.unassignParsers !== undefined) {
      ModemMessageBodyParserSelection.encode(message.unassignParsers, writer.uint32(18).fork()).ldelim();
    }
    if (message.fromModems !== undefined) {
      ModemSelection.encode(message.fromModems, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignModemMessageBodyParsers_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignModemMessageBodyParsers_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.unassignParsers = ModemMessageBodyParserSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fromModems = ModemSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignModemMessageBodyParsers_Request>): UnassignModemMessageBodyParsers_Request {
    return UnassignModemMessageBodyParsers_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnassignModemMessageBodyParsers_Request>): UnassignModemMessageBodyParsers_Request {
    const message = createBaseUnassignModemMessageBodyParsers_Request();
    message.organization = object.organization ?? undefined;
    message.unassignParsers = (object.unassignParsers !== undefined && object.unassignParsers !== null)
      ? ModemMessageBodyParserSelection.fromPartial(object.unassignParsers)
      : undefined;
    message.fromModems = (object.fromModems !== undefined && object.fromModems !== null)
      ? ModemSelection.fromPartial(object.fromModems)
      : undefined;
    return message;
  },
};

function createBaseUnassignModemMessageBodyParsers_Response(): UnassignModemMessageBodyParsers_Response {
  return { removedDirectAssignments: [], request: undefined };
}

export const UnassignModemMessageBodyParsers_Response = {
  encode(message: UnassignModemMessageBodyParsers_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.removedDirectAssignments) {
      AssignedModemMessageBodyParser.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UnassignModemMessageBodyParsers_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignModemMessageBodyParsers_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignModemMessageBodyParsers_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.removedDirectAssignments.push(AssignedModemMessageBodyParser.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = UnassignModemMessageBodyParsers_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignModemMessageBodyParsers_Response>): UnassignModemMessageBodyParsers_Response {
    return UnassignModemMessageBodyParsers_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnassignModemMessageBodyParsers_Response>): UnassignModemMessageBodyParsers_Response {
    const message = createBaseUnassignModemMessageBodyParsers_Response();
    message.removedDirectAssignments =
      object.removedDirectAssignments?.map((e) => AssignedModemMessageBodyParser.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? UnassignModemMessageBodyParsers_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

export type ModemMessageBodyParserServiceDefinition = typeof ModemMessageBodyParserServiceDefinition;
export const ModemMessageBodyParserServiceDefinition = {
  name: "ModemMessageBodyParserService",
  fullName: "hiber.modem.message.bodyparser.ModemMessageBodyParserService",
  methods: {
    /** List available message body parsers (including inherited from your parent organizations). */
    list: {
      name: "List",
      requestType: ListModemMessageBodyParsersRequest,
      requestStream: false,
      responseType: ListModemMessageBodyParsersRequest_Response,
      responseStream: false,
      options: {},
    },
    /** Upload a .ksy file as a message body parser. */
    upload: {
      name: "Upload",
      requestType: UploadModemMessageBodyParserRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    /** Re-upload a .ksy file to update a message body parser, if you are the owner. */
    updateUploaded: {
      name: "UpdateUploaded",
      requestType: UpdateUploadedModemMessageBodyParserRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    /** Create a simple message body parser using API elements. */
    createSimple: {
      name: "CreateSimple",
      requestType: CreateSimpleModemMessageBodyParserRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    /** Update a simple message body parser created using API elements, if you are the owner. */
    updateSimple: {
      name: "UpdateSimple",
      requestType: UpdateSimpleModemMessageBodyParserRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    /** Rename a message body parser, if you are the owner. */
    rename: {
      name: "Rename",
      requestType: RenameModemMessageBodyParserRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    /** Make a message body parser (un)available to (a selection of) child organizations. */
    updateChildOrganizationAvailability: {
      name: "UpdateChildOrganizationAvailability",
      requestType: UpdateChildOrganizationAvailabilityRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    /** Make a message body parser available to a child organization. */
    makeAvailableToChildOrganization: {
      name: "MakeAvailableToChildOrganization",
      requestType: MakeModemMessageBodyParserAvailableToChildOrganizationRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    /** Make a message body parser unavailable to a child organization. */
    makeUnavailableToChildOrganization: {
      name: "MakeUnavailableToChildOrganization",
      requestType: MakeModemMessageBodyParserUnavailableToChildOrganizationRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    /** Delete a message body parser, if you are the owner. */
    delete: {
      name: "Delete",
      requestType: DeleteModemMessageBodyParserRequest,
      requestStream: false,
      responseType: DeleteModemMessageBodyParserRequest_Response,
      responseStream: false,
      options: {},
    },
    /**
     * Test a message body parser with an existing message or provided binary message body.
     * This can also be used without an existing parser by uploading a .ksy file or specifying a simple parser.
     */
    test: {
      name: "Test",
      requestType: TestModemMessageBodyParserRequest,
      requestStream: false,
      responseType: TestModemMessageBodyParserRequest_Response,
      responseStream: false,
      options: {},
    },
    /** Retry parsing messages with a message body parser (for example after updating the parser). */
    retry: {
      name: "Retry",
      requestType: RetryModemMessageBodyParsing_Request,
      requestStream: false,
      responseType: RetryModemMessageBodyParsing_Response,
      responseStream: false,
      options: {},
    },
    /** Assign a parser to a modem. */
    assign: {
      name: "Assign",
      requestType: AssignModemMessageBodyParsers_Request,
      requestStream: false,
      responseType: AssignModemMessageBodyParsers_Response,
      responseStream: false,
      options: {},
    },
    /** Unassign a parser from a modem. */
    unassign: {
      name: "Unassign",
      requestType: UnassignModemMessageBodyParsers_Request,
      requestStream: false,
      responseType: UnassignModemMessageBodyParsers_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
