import * as Firebase from 'firebase/app';
import 'firebase/auth';

// This allows us to bypass Firebase when using Cypress
const firebaseCheck = () => {
  if (process.env.NODE_ENV) {
    if (window.Cypress) {
      // - Overwrite Firebase in case we're in a test environment
      // - This is no longer necessary if you're using the docker-db
      return Firebase;
      /*
      return {
        auth: () => ({
          currentUser: {
            uid: 'cypress',
            email: 'cypress@hiber.global',
            emailVerified: true,
            getIdToken: () => new Promise(resolve => resolve('cypress-auth-bearer-token')),
          },
          signOut: () => {},
        }),
      };
      */
    }
  }

  return Firebase;
};

const firebase = firebaseCheck();

export default firebase;
