import { getGrpcClient } from '@/services/app';
import {
  CreateModemAlarm_Request,
  DeleteModemAlarm_Request,
  ListModemAlarms_Request,
  ModemAlarm_Check,
  ModemAlarm_TriggerCondition,
  ModemAlarmServiceDefinition,
  UpdateModemAlarm_Request,
} from 'hiber-grpc/src/customer_compiled/modem_alarm';
import { Pagination } from 'hiber-grpc/src/customer_compiled/base';

// this is an "ui" id to keep track of alarms checks items in a list
export const UI_IDENTIFIER_PREFIX = 'ui_';

export function fetchModemAlarms(params: {
  pagination?: { size: number; page: number; };
}) {
  const {
    pagination = { size: 50, page: 0 },
  } = params;
  const req = ListModemAlarms_Request.fromPartial({
    pagination: Pagination.fromPartial({ size: pagination.size, page: pagination.page }),
  });
  const client = getGrpcClient<typeof ModemAlarmServiceDefinition>(ModemAlarmServiceDefinition);
  return client.list(req);
}

export function updateModemAlarm(params: {
  identifier: string,
  name: string,
  description: string,
  triggerCondition: any,
  deletedChecks: string[],
  createdChecks: ModemAlarm_Check[],
  updatedChecks: ModemAlarm_Check[],
}) {
  const {
    name,
    description,
    identifier,
    triggerCondition,
    deletedChecks = [],
    createdChecks,
    updatedChecks,
  } = params;

  const request = UpdateModemAlarm_Request.fromPartial({
    identifier,
    updateName: { updated: true, value: name },
    updateDescription: { updated: true, value: description },
    updateTriggerCondition: triggerCondition,
    addChecks: createdChecks.map(check => ({
      ...check,
      name: !check.name?.startsWith(UI_IDENTIFIER_PREFIX) ? check.name : '',
    })),
    deleteChecks: deletedChecks,
    updateChecks: updatedChecks.reduce((acc, check) => ({
      ...acc,
      [check.name]: check,
    }), {}),
  });
  const client = getGrpcClient<typeof ModemAlarmServiceDefinition>(ModemAlarmServiceDefinition);
  return client.update(request);
}

export function createModemAlarm(params: {
  name: string,
  description: string,
  triggerCondition: any,
  checks: ModemAlarm_Check[]
}) {
  const {
    name,
    description,
    triggerCondition = ModemAlarm_TriggerCondition.ANY_CHECK_FAILED,
    checks = [],
  } = params;

  const request = CreateModemAlarm_Request.fromPartial({
    name,
    description,
    triggerCondition,
    checks: checks.map((check) => ({
      ...check,
      name: '',
    })),
  });
  const client = getGrpcClient<typeof ModemAlarmServiceDefinition>(ModemAlarmServiceDefinition);
  return client.create(request);
}

export function deleteModemAlarm(params: { identifier: string }) {
  const { identifier } = params;

  const request = DeleteModemAlarm_Request.fromPartial({
    identifier,
  });

  const client = getGrpcClient<typeof ModemAlarmServiceDefinition>(ModemAlarmServiceDefinition);
  return client.delete(request);
}
