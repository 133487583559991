<script setup>
import Logger from '@/components/global/Logger';
import useSwitchOffline from 'ui/composables/useSwitchOffline';
import useCheckMaintenanceMode from '@/helpers/useCheckMaintenanceMode';
import { useRolesQuery } from '@/helpers/useRolesQuery';
import useStore from '@/store/useStore';
import { watch } from 'vue';

const { $store } = useStore();

useSwitchOffline();
useCheckMaintenanceMode();
const rolesQuery = useRolesQuery();

watch(() => rolesQuery.data.value?.roles, (newVal) => {
  if (newVal && $store.value) $store.value.dispatch('User/setRoles', newVal);
});

watch(() => $store.value.state.Organization?.organization?.organization, (newVal) => {
  if (newVal) {
    rolesQuery.refetch();
  }
}, { immediate: true });
</script>

<template>
  <div
    id='app'
    class='tw-h-full'>
    <Logger />
    <router-view />
  </div>
</template>

<style lang='scss'>
@import 'scss/style.scss';
</style>
