import { defaultErrorHandler, successHandler } from '@/store/core/utils';
import {
  viewEmailPreferences,
  updateEmailPreferences,
  disableEmailNotifications,
} from '@/services/email_notifications';

const EmailNotifications = {
  namespaced: true,
  state: {
    viewPreferences: {},
  },
  actions: {
    viewEmailPreferences(context) {
      return new Promise((resolve, reject) => {
        viewEmailPreferences().then((res) => {
          context.commit('viewEmailPreferences', res);
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    updateEmailPreferences(context, update) {
      const payload = {
        ...update,
      };
      const logMessage = {
        category: 'Email preferences',
        name: '',
        action: 'updated',
      };

      return new Promise((resolve, reject) => {
        updateEmailPreferences(
          payload.filterEvents,
        ).then((res) => {
          context.dispatch('viewEmailPreferences');
          successHandler(res, resolve, context, logMessage);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    disableEmailNotifications(context) {
      return new Promise((resolve, reject) => {
        disableEmailNotifications().then(() => {
          context.dispatch('viewEmailPreferences');
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    viewEmailPreferences(state, res) {
      state.viewPreferences = res;
    },
  },
  getters: {
  },
};

export default EmailNotifications;
