<template>
  <div
    :class='[(`logger--` + filter)]'
    class='logger'>
    <h-log
      v-for='log in logs'
      :key='log.id'
      :log='log' />
  </div>
</template>

<script>
import Log from './Log';

export default {
  components: {
    'h-log': Log,
  },
  props: {
    filter: {
      type: String,
      default: 'global',
    },
  },
  computed: {
    logs() {
      return (this.filter === 'all')
        ? this.$store.state.App.logs
        : this.$store.state.App.logs.filter(log => log.filter === this.filter);
    },
  },
};
</script>

<style lang='scss'>
</style>
