/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Area, Filter_ChildOrganizations, Location, LocationSelection, TimeRange, Timestamp } from "./base";
import { HealthLevel } from "./health";
import { ModemSelection } from "./modem";

export const protobufPackage = "hiber.map";

/**
 * Show modems on a map, using the calculated state of the map.
 *
 * Uses a tile-based system where the map is split up into tiles, and modems can be efficiently grouped into the tiles.
 * The tiles are based on the Google Maps output, which allows us to draw thousands of markers on a map efficiently.
 *
 * This works as follows:
 * Assume the world is a grid of 256x256 tiles, and every point can be represented as an x (from lon) and y (from lat)
 * coordinate, for example:
 * - (0,0) maps to world coordinate (128.0, 128.0)
 * - (13.24,122.04) maps roughly to (214.78, 118.49)
 * - (-84.27,175.36) maps roughly to (252.70, 250.05)
 * Keep in mind that the order is reversed, lat -> -y, long -> x, to get a grid that goes
 * from 0,0 at the bottom left of the map to 256,256 at the top right.
 *
 * Now that we have a simple numeric model of the world, we can start grouping numerically as well.
 * If we want 256 horizontal and 256 vertical tiles, all we have to do is `floor` the values to determine their tile.
 * Then, we can use the remainder to calculate their position within the tile on another 256x256 grid.
 * For example:
 * - (0,0) is in tile (128, 128) at (0.0,0.0)
 * - (13.24,122.04) is in tile (214, 118)
 * - (-84.27,175.36) is in tile (252, 250)
 *
 * While 65k tiles seems like a lot, it's not enough for the world, of course. So, we're using Google Maps' map levels
 * to have more detail. These increase in size exponentially:
 * - Level 0  : 1x1 : a single tile for the whole world, containing all of the modems
 * - Level 1  : 2x2 : 4 tiles for the whole world.
 * - Level 2  : 4x4 : 16 tiles for the whole world.
 * - Level 3  : 8x8 : 64 tiles for the whole world.
 * - Level 4  : 16x16 : 256 tiles for the whole world.
 * - Level 5  : 32x32 : 1024 tiles for the whole world.
 * - Level 6  : 64x64 : 4096 tiles for the whole world.
 * - Level 7  : 128x128 : 16 384 tiles for the whole world.
 * - Level 8  : 256x256 : 65 536 tiles for the whole world.
 * - Level 9  : 512x512 : 262 144 tiles for the whole world.
 * - Level 10 : 1024x1024 : 1 048 576 tiles for the whole world.
 * - Level 11 : 2048x2048 : 4 194 304 tiles for the whole world.
 * - Level 12 : 4096x4096 : 16 777 216 tiles for the whole world.
 * - Level 13 : 8192x8192 : 67 108 864 tiles for the whole world.
 * - Level 14 : 16384x16384 : 268 435 456 tiles for the whole world.
 * - Level 15 : 32768x32768 : 1 073 741 824 tiles for the whole world.
 * - Level 16 : 65536x65536 : 4 294 967 296 tiles for the whole world.
 * - Level 17 : 131072x131072 : 17 179 869 184 tiles for the whole world.
 * - Level 18 : 262144x262144 : 68 719 476 736 tiles for the whole world.
 * - Level 19 : 524288x524288 : 274 877 906 944 tiles for the whole world.
 * - Level 20 : 1048576x1048576 : 1 099 511 627 776 tiles for the whole world.
 * - Level 21 : 2097152x2097152 : 4 398 046 511 104 tiles for the whole world.
 *
 * Finally, we can do the same `floor`-based grouping within the tile. Using the TileMapRequest.Density, we decide
 * how many groups to split our tiles into. This means we can still align our tiles to Google Maps, but allow for
 * a controlled amount of groups.
 */

export interface TileCoordinate {
  x: number;
  y: number;
}

export interface MapTileItem {
  /** Google maps tile coordinates, counted from the top left of the map. */
  tile:
    | TileCoordinate
    | undefined;
  /** Google maps in-tile coordinate, x axis, using a 256x256 grid in the tile. */
  inTile: TileCoordinate | undefined;
  modems?:
    | { $case: "modem"; modem: MapTileItem_Modem }
    | { $case: "modemGroup"; modemGroup: MapTileItem_Group }
    | undefined;
}

export interface MapTileItem_Modem {
  number: string;
  location: Location | undefined;
  healthLevel: HealthLevel | undefined;
}

export interface MapTileItem_Group {
  count: number;
  /** Area that the group covers. Modems are somewhere in this area. More details can be requested from the modem list or by zooming in, */
  area:
    | Area
    | undefined;
  /** The most severe health for the modems in this group. */
  mostSevereHealthLevel: HealthLevel | undefined;
}

export interface TileMapRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Visible part of the map. */
  selection?:
    | LocationSelection
    | undefined;
  /** Google Maps zoom level, from 0 to 21, where is a view of the whole world and 21 is zoomed in as possible. */
  level: number;
  /** Selection to filter the modems on the map. */
  modemSelection?:
    | ModemSelection
    | undefined;
  /** The icon density on the map. */
  density: TileMapRequest_Density;
  expanded?: { $case: "childOrganizations"; childOrganizations: Filter_ChildOrganizations } | {
    $case: "includeHealth";
    includeHealth: boolean;
  } | undefined;
}

export enum TileMapRequest_Density {
  /** DEFAULT - Fills a tile with 8x8 icons. */
  DEFAULT = "DEFAULT",
  /** DENSE - Fills a tile with 16x16 icons. */
  DENSE = "DENSE",
  /** SPARSE - Fills a tile with 4x4 icons. */
  SPARSE = "SPARSE",
  /** VERY_SPARSE - Fills a tile with 2x2 icons. */
  VERY_SPARSE = "VERY_SPARSE",
  /** SINGLE - Fills a tile with 1 icon. */
  SINGLE = "SINGLE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function tileMapRequest_DensityFromJSON(object: any): TileMapRequest_Density {
  switch (object) {
    case 0:
    case "DEFAULT":
      return TileMapRequest_Density.DEFAULT;
    case 1:
    case "DENSE":
      return TileMapRequest_Density.DENSE;
    case 2:
    case "SPARSE":
      return TileMapRequest_Density.SPARSE;
    case 3:
    case "VERY_SPARSE":
      return TileMapRequest_Density.VERY_SPARSE;
    case 4:
    case "SINGLE":
      return TileMapRequest_Density.SINGLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TileMapRequest_Density.UNRECOGNIZED;
  }
}

export function tileMapRequest_DensityToNumber(object: TileMapRequest_Density): number {
  switch (object) {
    case TileMapRequest_Density.DEFAULT:
      return 0;
    case TileMapRequest_Density.DENSE:
      return 1;
    case TileMapRequest_Density.SPARSE:
      return 2;
    case TileMapRequest_Density.VERY_SPARSE:
      return 3;
    case TileMapRequest_Density.SINGLE:
      return 4;
    case TileMapRequest_Density.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface TileMapRequest_Response {
  mapItems: MapTileItem[];
  request: TileMapRequest | undefined;
}

/**
 * List the location history for a modem.
 * This is typically used to plot a path on a map based on the locations the modem sent.
 *
 * Depending on the map zoom level, the data set is reduced to reduce the number of points on the map, using the
 * Ramer–Douglas–Peucker algorithm.
 */
export interface LocationHistory {
  /** The location for this point in the history. */
  location: Location | undefined;
  when?: { $case: "time"; time: Timestamp } | { $case: "timeRange"; timeRange: TimeRange } | undefined;
}

export interface LocationHistory_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The modem to get the location history for. */
  modemNumber: string;
  /** The time the history should be in. Defaults to 7 days ago to now. */
  timeRange:
    | TimeRange
    | undefined;
  /** Google Maps zoom level, from 0 to 21, where 0 is a view of the whole world and 21 is zoomed in as far as possible. */
  level: number;
}

export interface LocationHistory_Response {
  history: LocationHistory[];
  request: LocationHistory_Request | undefined;
}

function createBaseTileCoordinate(): TileCoordinate {
  return { x: 0, y: 0 };
}

export const TileCoordinate = {
  encode(message: TileCoordinate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(8).int32(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(16).int32(message.y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TileCoordinate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTileCoordinate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.x = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.y = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TileCoordinate>): TileCoordinate {
    return TileCoordinate.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TileCoordinate>): TileCoordinate {
    const message = createBaseTileCoordinate();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBaseMapTileItem(): MapTileItem {
  return { tile: undefined, inTile: undefined, modems: undefined };
}

export const MapTileItem = {
  encode(message: MapTileItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tile !== undefined) {
      TileCoordinate.encode(message.tile, writer.uint32(10).fork()).ldelim();
    }
    if (message.inTile !== undefined) {
      TileCoordinate.encode(message.inTile, writer.uint32(18).fork()).ldelim();
    }
    switch (message.modems?.$case) {
      case "modem":
        MapTileItem_Modem.encode(message.modems.modem, writer.uint32(26).fork()).ldelim();
        break;
      case "modemGroup":
        MapTileItem_Group.encode(message.modems.modemGroup, writer.uint32(34).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapTileItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapTileItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tile = TileCoordinate.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.inTile = TileCoordinate.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modems = { $case: "modem", modem: MapTileItem_Modem.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.modems = { $case: "modemGroup", modemGroup: MapTileItem_Group.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MapTileItem>): MapTileItem {
    return MapTileItem.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MapTileItem>): MapTileItem {
    const message = createBaseMapTileItem();
    message.tile = (object.tile !== undefined && object.tile !== null)
      ? TileCoordinate.fromPartial(object.tile)
      : undefined;
    message.inTile = (object.inTile !== undefined && object.inTile !== null)
      ? TileCoordinate.fromPartial(object.inTile)
      : undefined;
    if (object.modems?.$case === "modem" && object.modems?.modem !== undefined && object.modems?.modem !== null) {
      message.modems = { $case: "modem", modem: MapTileItem_Modem.fromPartial(object.modems.modem) };
    }
    if (
      object.modems?.$case === "modemGroup" &&
      object.modems?.modemGroup !== undefined &&
      object.modems?.modemGroup !== null
    ) {
      message.modems = { $case: "modemGroup", modemGroup: MapTileItem_Group.fromPartial(object.modems.modemGroup) };
    }
    return message;
  },
};

function createBaseMapTileItem_Modem(): MapTileItem_Modem {
  return { number: "", location: undefined, healthLevel: undefined };
}

export const MapTileItem_Modem = {
  encode(message: MapTileItem_Modem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== "") {
      writer.uint32(10).string(message.number);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(18).fork()).ldelim();
    }
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapTileItem_Modem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapTileItem_Modem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MapTileItem_Modem>): MapTileItem_Modem {
    return MapTileItem_Modem.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MapTileItem_Modem>): MapTileItem_Modem {
    const message = createBaseMapTileItem_Modem();
    message.number = object.number ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    return message;
  },
};

function createBaseMapTileItem_Group(): MapTileItem_Group {
  return { count: 0, area: undefined, mostSevereHealthLevel: undefined };
}

export const MapTileItem_Group = {
  encode(message: MapTileItem_Group, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(8).int32(message.count);
    }
    if (message.area !== undefined) {
      Area.encode(message.area, writer.uint32(18).fork()).ldelim();
    }
    if (message.mostSevereHealthLevel !== undefined) {
      HealthLevel.encode(message.mostSevereHealthLevel, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapTileItem_Group {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapTileItem_Group();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.count = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.area = Area.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mostSevereHealthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MapTileItem_Group>): MapTileItem_Group {
    return MapTileItem_Group.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MapTileItem_Group>): MapTileItem_Group {
    const message = createBaseMapTileItem_Group();
    message.count = object.count ?? 0;
    message.area = (object.area !== undefined && object.area !== null) ? Area.fromPartial(object.area) : undefined;
    message.mostSevereHealthLevel =
      (object.mostSevereHealthLevel !== undefined && object.mostSevereHealthLevel !== null)
        ? HealthLevel.fromPartial(object.mostSevereHealthLevel)
        : undefined;
    return message;
  },
};

function createBaseTileMapRequest(): TileMapRequest {
  return {
    organization: undefined,
    selection: undefined,
    level: 0,
    modemSelection: undefined,
    density: TileMapRequest_Density.DEFAULT,
    expanded: undefined,
  };
}

export const TileMapRequest = {
  encode(message: TileMapRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      LocationSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.level !== 0) {
      writer.uint32(24).int32(message.level);
    }
    if (message.modemSelection !== undefined) {
      ModemSelection.encode(message.modemSelection, writer.uint32(34).fork()).ldelim();
    }
    if (message.density !== TileMapRequest_Density.DEFAULT) {
      writer.uint32(56).int32(tileMapRequest_DensityToNumber(message.density));
    }
    switch (message.expanded?.$case) {
      case "childOrganizations":
        Filter_ChildOrganizations.encode(message.expanded.childOrganizations, writer.uint32(66).fork()).ldelim();
        break;
      case "includeHealth":
        writer.uint32(72).bool(message.expanded.includeHealth);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TileMapRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTileMapRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = LocationSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.level = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.modemSelection = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.density = tileMapRequest_DensityFromJSON(reader.int32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.expanded = {
            $case: "childOrganizations",
            childOrganizations: Filter_ChildOrganizations.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.expanded = { $case: "includeHealth", includeHealth: reader.bool() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TileMapRequest>): TileMapRequest {
    return TileMapRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TileMapRequest>): TileMapRequest {
    const message = createBaseTileMapRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? LocationSelection.fromPartial(object.selection)
      : undefined;
    message.level = object.level ?? 0;
    message.modemSelection = (object.modemSelection !== undefined && object.modemSelection !== null)
      ? ModemSelection.fromPartial(object.modemSelection)
      : undefined;
    message.density = object.density ?? TileMapRequest_Density.DEFAULT;
    if (
      object.expanded?.$case === "childOrganizations" &&
      object.expanded?.childOrganizations !== undefined &&
      object.expanded?.childOrganizations !== null
    ) {
      message.expanded = {
        $case: "childOrganizations",
        childOrganizations: Filter_ChildOrganizations.fromPartial(object.expanded.childOrganizations),
      };
    }
    if (
      object.expanded?.$case === "includeHealth" &&
      object.expanded?.includeHealth !== undefined &&
      object.expanded?.includeHealth !== null
    ) {
      message.expanded = { $case: "includeHealth", includeHealth: object.expanded.includeHealth };
    }
    return message;
  },
};

function createBaseTileMapRequest_Response(): TileMapRequest_Response {
  return { mapItems: [], request: undefined };
}

export const TileMapRequest_Response = {
  encode(message: TileMapRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.mapItems) {
      MapTileItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      TileMapRequest.encode(message.request, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TileMapRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTileMapRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mapItems.push(MapTileItem.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.request = TileMapRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TileMapRequest_Response>): TileMapRequest_Response {
    return TileMapRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TileMapRequest_Response>): TileMapRequest_Response {
    const message = createBaseTileMapRequest_Response();
    message.mapItems = object.mapItems?.map((e) => MapTileItem.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? TileMapRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseLocationHistory(): LocationHistory {
  return { location: undefined, when: undefined };
}

export const LocationHistory = {
  encode(message: LocationHistory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(10).fork()).ldelim();
    }
    switch (message.when?.$case) {
      case "time":
        Timestamp.encode(message.when.time, writer.uint32(18).fork()).ldelim();
        break;
      case "timeRange":
        TimeRange.encode(message.when.timeRange, writer.uint32(26).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationHistory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationHistory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.when = { $case: "time", time: Timestamp.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.when = { $case: "timeRange", timeRange: TimeRange.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LocationHistory>): LocationHistory {
    return LocationHistory.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LocationHistory>): LocationHistory {
    const message = createBaseLocationHistory();
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    if (object.when?.$case === "time" && object.when?.time !== undefined && object.when?.time !== null) {
      message.when = { $case: "time", time: Timestamp.fromPartial(object.when.time) };
    }
    if (object.when?.$case === "timeRange" && object.when?.timeRange !== undefined && object.when?.timeRange !== null) {
      message.when = { $case: "timeRange", timeRange: TimeRange.fromPartial(object.when.timeRange) };
    }
    return message;
  },
};

function createBaseLocationHistory_Request(): LocationHistory_Request {
  return { organization: undefined, modemNumber: "", timeRange: undefined, level: 0 };
}

export const LocationHistory_Request = {
  encode(message: LocationHistory_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.modemNumber !== "") {
      writer.uint32(18).string(message.modemNumber);
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(26).fork()).ldelim();
    }
    if (message.level !== 0) {
      writer.uint32(32).int32(message.level);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationHistory_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationHistory_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.level = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LocationHistory_Request>): LocationHistory_Request {
    return LocationHistory_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LocationHistory_Request>): LocationHistory_Request {
    const message = createBaseLocationHistory_Request();
    message.organization = object.organization ?? undefined;
    message.modemNumber = object.modemNumber ?? "";
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.level = object.level ?? 0;
    return message;
  },
};

function createBaseLocationHistory_Response(): LocationHistory_Response {
  return { history: [], request: undefined };
}

export const LocationHistory_Response = {
  encode(message: LocationHistory_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.history) {
      LocationHistory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      LocationHistory_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationHistory_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationHistory_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.history.push(LocationHistory.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = LocationHistory_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LocationHistory_Response>): LocationHistory_Response {
    return LocationHistory_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LocationHistory_Response>): LocationHistory_Response {
    const message = createBaseLocationHistory_Response();
    message.history = object.history?.map((e) => LocationHistory.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? LocationHistory_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

/** Map of modems, in different map levels to enable data to be displayed efficiently */
export type MapServiceDefinition = typeof MapServiceDefinition;
export const MapServiceDefinition = {
  name: "MapService",
  fullName: "hiber.map.MapService",
  methods: {
    /** Show the selected modems on the map, in a selected area. */
    tileMap: {
      name: "TileMap",
      requestType: TileMapRequest,
      requestStream: false,
      responseType: TileMapRequest_Response,
      responseStream: false,
      options: {},
    },
    /** List the location history for a modem. */
    locationHistory: {
      name: "LocationHistory",
      requestType: LocationHistory_Request,
      requestStream: false,
      responseType: LocationHistory_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
