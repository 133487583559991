/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./base";

export const protobufPackage = "hiber.transfer";

/**
 * Service to transfer data from one organization to another.
 *
 * To transfer data to another organization, you must have access to both organizations,
 * and must have the TRANSFERS permissions in the sender organization.
 */

export interface Transfer {
  /** The identifier for the transfer. */
  identifier: string;
  /** The organization that sent the transfer. */
  senderOrganization: string;
  /** The organization that received the transfer. */
  recipientOrganization: string;
  /** Optional comment for the transfer (i.e. reason, tracking information, etc). */
  comment: string;
  createdAt:
    | Timestamp
    | undefined;
  /** The type of data transferred to the recipient organization. */
  type: Transfer_Type;
  typed?: { $case: "devices"; devices: Transfer_Devices } | undefined;
}

/** The type of data transferred to the recipient organization. */
export enum Transfer_Type {
  DEVICES = "DEVICES",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function transfer_TypeFromJSON(object: any): Transfer_Type {
  switch (object) {
    case 0:
    case "DEVICES":
      return Transfer_Type.DEVICES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Transfer_Type.UNRECOGNIZED;
  }
}

export function transfer_TypeToNumber(object: Transfer_Type): number {
  switch (object) {
    case Transfer_Type.DEVICES:
      return 0;
    case Transfer_Type.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The devices that were transferred from the sender to the recipient organization. */
export interface Transfer_Devices {
  /** The numbers of the devices that were transferred from the sender to the recipient organization. */
  numbers: string[];
}

function createBaseTransfer(): Transfer {
  return {
    identifier: "",
    senderOrganization: "",
    recipientOrganization: "",
    comment: "",
    createdAt: undefined,
    type: Transfer_Type.DEVICES,
    typed: undefined,
  };
}

export const Transfer = {
  encode(message: Transfer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.senderOrganization !== "") {
      writer.uint32(18).string(message.senderOrganization);
    }
    if (message.recipientOrganization !== "") {
      writer.uint32(26).string(message.recipientOrganization);
    }
    if (message.comment !== "") {
      writer.uint32(34).string(message.comment);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.type !== Transfer_Type.DEVICES) {
      writer.uint32(48).int32(transfer_TypeToNumber(message.type));
    }
    switch (message.typed?.$case) {
      case "devices":
        Transfer_Devices.encode(message.typed.devices, writer.uint32(58).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Transfer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransfer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.senderOrganization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.recipientOrganization = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.type = transfer_TypeFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.typed = { $case: "devices", devices: Transfer_Devices.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Transfer>): Transfer {
    return Transfer.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Transfer>): Transfer {
    const message = createBaseTransfer();
    message.identifier = object.identifier ?? "";
    message.senderOrganization = object.senderOrganization ?? "";
    message.recipientOrganization = object.recipientOrganization ?? "";
    message.comment = object.comment ?? "";
    message.createdAt = (object.createdAt !== undefined && object.createdAt !== null)
      ? Timestamp.fromPartial(object.createdAt)
      : undefined;
    message.type = object.type ?? Transfer_Type.DEVICES;
    if (object.typed?.$case === "devices" && object.typed?.devices !== undefined && object.typed?.devices !== null) {
      message.typed = { $case: "devices", devices: Transfer_Devices.fromPartial(object.typed.devices) };
    }
    return message;
  },
};

function createBaseTransfer_Devices(): Transfer_Devices {
  return { numbers: [] };
}

export const Transfer_Devices = {
  encode(message: Transfer_Devices, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.numbers) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Transfer_Devices {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransfer_Devices();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.numbers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Transfer_Devices>): Transfer_Devices {
    return Transfer_Devices.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Transfer_Devices>): Transfer_Devices {
    const message = createBaseTransfer_Devices();
    message.numbers = object.numbers?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
