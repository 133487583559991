import {
  DeepPartial,
  DeviceServiceDefinition,
  ListDevice_Request,
  UpdateDevice,
  UpdateDevice_Request,
  UpdateDevice_Response,
} from '../customer_compiled/device_service';
import { GrpcClientFactoryType } from './types';
import { Client } from 'nice-grpc-web';

export default class DeviceService {
  private static instance: DeviceService;
  private readonly client: Client<typeof DeviceServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof DeviceServiceDefinition>(
      DeviceServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!DeviceService.instance) {
      DeviceService.instance = new DeviceService(grpcClientFactory);
    }

    return DeviceService.instance;
  }

  listDevices(payload: DeepPartial<ListDevice_Request>) {
    const request = ListDevice_Request.fromPartial(payload);
    return this.client.list(request);
  }

  updateDevices(payload: UpdateDevice[], organization='') {
    try {
      const request = UpdateDevice_Request.fromPartial({
        organization,
        devices: payload,
      });
      return this.client.update(request);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  update(payload: DeepPartial<UpdateDevice_Request>): Promise<UpdateDevice_Response> {
    const request = UpdateDevice_Request.fromPartial(payload);
    return this.client.update(request);
  }
}
