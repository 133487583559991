import { getGrpcClient } from '@/services/app';
import {
  EmailNotificationPreferencesServiceDefinition,
  RemoveAllEmailNotificationPreferencesRequest,
  UpdateEmailNotificationPreferencesRequest,
  ViewEmailNotificationPreferencesRequest,
} from 'hiber-grpc/src/customer_compiled/email_notifications';
import { EventType } from 'hiber-grpc/src/customer_compiled/base';

export function viewEmailPreferences() {
  const request = ViewEmailNotificationPreferencesRequest.fromPartial({});
  const client = getGrpcClient<typeof EmailNotificationPreferencesServiceDefinition>(
    EmailNotificationPreferencesServiceDefinition,
  );
  return client.view(request);
}

export function updateEmailPreferences(events: EventType[]) {
  const request = UpdateEmailNotificationPreferencesRequest.fromPartial({
    enabledNotifications: { include: events },
  });
  const client = getGrpcClient<typeof EmailNotificationPreferencesServiceDefinition>(
    EmailNotificationPreferencesServiceDefinition,
  );
  return client.update(request);
}

export function disableEmailNotifications() {
  const request = RemoveAllEmailNotificationPreferencesRequest.fromPartial({});
  const client = getGrpcClient<typeof EmailNotificationPreferencesServiceDefinition>(
    EmailNotificationPreferencesServiceDefinition,
  );
  return client.delete(request);
}
