import { getGrpcClient } from '@/services/app';

// Certificates
import {
  CertificateServiceDefinition,
  DeleteCertificateRequest,
  ListCertificatesRequest,
  UploadCertificateRequest,
} from 'hiber-grpc/src/customer_compiled/certificate';

export function getCertificatesList(size: number, page: number) {
  const req = ListCertificatesRequest.fromPartial({
    pagination: { size, page },
  });
  const client = getGrpcClient<typeof CertificateServiceDefinition>(CertificateServiceDefinition);
  return client.list(req);
}

export interface IUploadCertificate {
  name: string,
  caid?: number,
  certificate: string,
  certificateBytes: Uint8Array,
}

export function uploadCertificateRequest(payload: IUploadCertificate) {
  const req = UploadCertificateRequest.fromPartial({
    uploadCertificate: {
      name: payload.name,
      certificate: { bytes: payload.certificateBytes },
    },
    caCertificate: {
      $case: 'caCertificateId',
      caCertificateId: payload.caid || undefined,
    },
  });
  const client = getGrpcClient<typeof CertificateServiceDefinition>(CertificateServiceDefinition);
  return client.upload(req);
}

export function deleteCertificateRequest(id: number) {
  const req = DeleteCertificateRequest.fromPartial({
    selection: { certificateIds: [id] },
  });
  const client = getGrpcClient<typeof CertificateServiceDefinition>(CertificateServiceDefinition);
  return client.delete(req);
}
