// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'sync-scroll';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.min.css';
import '@oruga-ui/oruga/dist/oruga-full-vars.css';

import './icons';
import { Organization_Feature } from 'hiber-grpc/src/customer_compiled/organization';

import Vue from 'vue';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment-timezone';
import numeral from 'numeral';
import Button from '@/components/global/Button';
import TheButton from '@/components/global/nano/Button';
import Input from '@/components/global/nano/Input';
import VueClipboard from 'vue-clipboard-plus';
import { VueQueryPlugin } from '@tanstack/vue-query';
import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import VueShortkey from 'vue-shortkey';
import * as svgicon from 'vue-svgicon';
import VTooltip from 'v-tooltip';

import { MENU_ITEMS } from '@/constants';

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import TextField from '@/components/global/nano/TextField';
import InputGroupLabel from '@/components/global/nano/InputGroupLabel';
import Radio from '@/components/global/nano/Radio';
import ConfirmationDialog from '@/components/global/ConfirmationDialog';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserCog } from '@fortawesome/pro-light-svg-icons/faUserCog';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faSort } from '@fortawesome/pro-light-svg-icons/faSort';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSatelliteDish } from '@fortawesome/pro-light-svg-icons/faSatelliteDish';
import { faSatellite } from '@fortawesome/pro-light-svg-icons/faSatellite';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faMapMarkedAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkedAlt';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faDolly } from '@fortawesome/pro-light-svg-icons/faDolly';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faBracketsCurly } from '@fortawesome/pro-light-svg-icons/faBracketsCurly';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faBoxOpen } from '@fortawesome/pro-light-svg-icons/faBoxOpen';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faShare } from '@fortawesome/pro-light-svg-icons/faShare';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons/faCalendarWeek';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock';
import { faAnalytics } from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faExchangeAlt } from '@fortawesome/pro-light-svg-icons/faExchangeAlt';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation';
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faMinus as farMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch';
import { faArrowsUpDown } from '@fortawesome/pro-light-svg-icons/faArrowsUpDown';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faRocketLaunch } from '@fortawesome/pro-light-svg-icons/faRocketLaunch';

import { healthToNumber } from 'hiber-grpc/src/customer_compiled/base';
import { Modem_Lifecycle, modem_LifecycleToNumber } from 'hiber-grpc/src/customer_compiled/modem';
import config from '../config/domainconfig';
import store from './store';
import router from './router';
import App from './App';

import './oruga-config';

Vue.use(VueQueryPlugin);

const cloneDeep = require('lodash.clonedeep');

/* add icons to the fontawesome library  */
library.add(
  faChevronRight,
  faMapMarkedAlt,
  faBell,
  faDolly,
  faBracketsCurly,
  faServer,
  faHome,
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
  faUserCog,
  faCog,
  faBars,
  faLock,
  faTimes,
  faSearch,
  faSatellite,
  faSatelliteDish,
  faCaretDown,
  faCaretUp,
  faSync,
  farPlus,
  farMinus,
  faSort,
  faEdit,
  faTrash,
  faCopy,
  faDownload,
  faExchangeAlt,
  faEnvelope,
  faPlusCircle,
  faSortDown,
  faSortUp,
  faCheck,
  faAnalytics,
  faAlarmClock,
  faCalendarDay,
  faCalendarWeek,
  faPen,
  faHistory,
  faCalendarAlt,
  faArrowLeft,
  faArrowRight,
  faShare,
  faFilter,
  faBoxOpen,
  faFolderOpen,
  faPlus,
  faPencil,
  faEye,
  faEyeSlash,
  faUser,
  faExclamationCircle,
  faAngleDown,
  faAngleUp,
  faCircleNotch,
  faArrowsUpDown,
  faChartLine,
  faListUl,
  faTriangleExclamation,
  faRocketLaunch,
);

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

// eslint-disable-next-line import/prefer-default-export
export const CONFIRMATION_DIALOG_NAME = 'confirmation-dialog';

Vue.config.productionTip = false;

// Fix errors only being logged to Sentry, not to dev console
// Inspired by but customized from https://github.com/vuejs/vue/issues/8433
const ravenErrorHandler = Vue.config.errorHandler;
Vue.config.errorHandler = (error, vm, info) => {
  if (process.env.NODE_ENV === 'development') {
    Vue.util.warn(`Error in ${info}: "${error.toString()}"`, vm);
    // eslint-disable-next-line no-console
    console.error(error, vm, info);
  }
  ravenErrorHandler(error, vm, info);
};

// Initialize Firebase
Firebase.initializeApp(config);

// Vue custom directives
Vue.directive('select-input-multiple', {
  bind(el) {
    const input = el;
    input.onfocus = () => {
      if (input.value === 'Various...' && !input.readOnly) {
        input.select();
      }
    };
  },
});

Vue.directive('bulk-edit-model', {
  inserted(el, bindings, vnode) {
    const isStringOrNumber = ['string', 'number'].includes(typeof bindings.value);
    const allEqual = arr => arr.every(v => v === arr[0]);

    const isMultiple = Array.isArray(bindings.value) && bindings.value.length > 1 && !allEqual(bindings.value);
    const inputNodes = [];
    if (el.tagName === 'input' || el.tagName === 'textarea') {
      inputNodes.push(el);
    } else {
      let matchedInputs = el.getElementsByTagName('input');
      if (matchedInputs.length > 0) {
        inputNodes.push(...matchedInputs);
      } else {
        matchedInputs = el.getElementsByTagName('textarea');
        inputNodes.push(...matchedInputs);
      }
    }
    inputNodes.forEach((inputNode) => {
      if (isStringOrNumber) {
        inputNode.value = bindings.value;
      } else if (isMultiple) {
        inputNode.placeholder = 'Various...';
        inputNode.value = '';
        inputNode.setAttribute('data-multiple', 'true');
      } else if (bindings.value[0]) {
        inputNode.value = bindings.value[0];
      }
      inputNode.dispatchEvent(new Event('input'));
    });

    // go max 2 levels to find a $v to reset $dirty state
    if (vnode.context && vnode.context.$v) {
      vnode.context.$v.$reset();
    } else {
      const $vnode = vnode?.context?.$vnode;
      $vnode?.context?.$v?.$reset();
    }
  },
});

/* eslint no-param-reassign: "error" */
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

// Vue mixins
Vue.mixin({
  methods: {
    // Applies an object to a Vue Component's data property (e.g. to quickly reset data)
    $spreadObjectToData(obj) {
      try {
        const deepCopy = cloneDeep(obj);
        Object.keys(deepCopy).forEach((key) => {
          this[key] = deepCopy[key];
        });
      } catch {
        // eslint-disable-next-line no-console
        console.error('$spreadObjectToData: Unable to parse as JSON.', obj);
      }
    },
    $getItemHealth(health, lifecycle = Modem_Lifecycle.INSTALLED, lastMessageId = 1, text = false) {
      // FIXME This needs to be refactored asap. Health should not be based on its status at all.

      // if lifecycle is ACCEPTANCE_TESTING, it's not yet available to the customer
      // if lastMessageId is 0, there haven't been any mesasges yet (but test messages do give an id)...
      // if health is 0, it's never been active either, therefore, it's pending
      const lifecycleNumber = modem_LifecycleToNumber(lifecycle);
      const inactiveNumber = modem_LifecycleToNumber(Modem_Lifecycle.ACCEPTANCE_TESTING);
      if ((lifecycleNumber === inactiveNumber || lastMessageId === 0) && health === 0) {
        return text ? 'Pending' : 'dot--pending';
      }

      // if status isn't 0, it's deployed/in the field in some manner or another
      // if lastMessage id isn't 0, it's sent a message
      if (lifecycleNumber !== 0 && lastMessageId !== 0) {
        if (health === 1) {
          return text ? 'Warning' : 'dot--warning';
        }
        if (health === 2) {
          return text ? 'Error' : 'dot--error';
        }
        if (health === 0) {
          return text ? 'Success' : 'dot--success';
        }
      }

      // this gets here, it's likely because it's activates (lifecycle is not ACCEPTANCE_TESTING)
      // but there isn't any messages yet, lastMessageId.
      // theortically if ((status !== 0 && lastMessageId === 0))
      return text ? 'Pending' : 'dot--pending';
    },
    $healthToNumber(health) {
      return healthToNumber(health);
    },
    clearSelected(targetName) {
      this.$store.dispatch('Tables/setSelection', { name: targetName, rows: [], data: {} });
      this.$store.dispatch('Tables/setAllSelected', { name: targetName, state: false });
    },

    $clearSelectedModems(targetTable = 'list/modems') {
      this.clearSelected(targetTable);
    },

    $clearSelectedWebhooks() {
      this.clearSelected('integrations/webhooks');
    },

    $clearSelectedTokens() {
      this.clearSelected('integrations/tokens');
    },

    $clearSelectedSlack() {
      this.clearSelected('integrations/slacks');
    },

    $clearSelectedUsers() {
      this.clearSelected('users/approved');
    },

    $clearSelectedMQTT() {
      this.clearSelected('integrations/mqtts');
    },

    $clearSelectedCertificates() {
      this.clearSelected('integrations/certificates');
    },

    $convertLargeNumber(number, format, threshold = 1000) {
      if (format === 'dot') {
        if (number >= threshold) {
          return `~${numeral(number).format('0[.]0a')}`;
        }

        return numeral(number).format('0,0');
      }
      if (format === 'comma') {
        return numeral(number).format('0,0');
      }

      return 'invalid format';
    },
    $formatModemName(string) {
      // Uppercase and adds a space every four characters
      return string
        .replace(/[^\dA-Z]/gi, '')
        .toUpperCase()
        .replace(/(.{4})/g, '$1 ')
        .trim();
    },
    $convertTime(time) {
      if (time.length) {
        return moment(time).tz(this.$store.state.App.timezone).format(this.$store.state.App.timestamp);
      }
      if (time._isAMomentObject) {
        return time.format(this.$store.state.App.timestamp);
      }

      return 'Unknown';
    },
    $mountedCheck() {
      const { currentUser } = Firebase.auth();

      if (currentUser) {
        if (Object.keys(this.$store.state.Organization.organization).length === 0) {
          this.$store.dispatch('App/getBase');
        }
      }
    },
    // this function exist, because bracket notation isn't supported by v-if
    // and dot notation isn't yet supported by vue getters
    // see for PR we're waiting on https://github.com/vuejs/vuex/pull/1364
    $userHasPermission(permissionId) {
      return this.$store.getters['User/hasOrganizationPermissionId'](permissionId);
    },

    $userHasSupportPermission(permissionId) {
      return this.$store.getters['User/hasSupportPermissionId'](permissionId);
    },

    $userHasPermissions(permissionIds) {
      return this.$store.getters['User/hasOrganizationPermissionIds'](permissionIds);
    },

    $hasExperimental() {
      const features = this.$store.state.Organization?.organization?.features;
      return features?.includes(Organization_Feature.EXPERIMENTAL);
    },

    $hasEarlyAccess() {
      const features = this.$store.state.Organization?.organization?.features;
      return features?.includes(Organization_Feature.EARLY_ACCESS);
    },

    $isGPT() {
      return false;
    },

    $isHilo() {
      const features = this.$store.state.Organization?.organization?.features;
      return (
        this.$store.getters['User/hasOrganizationPermissionIds'](MENU_ITEMS.HILO.permissions)
        && features?.includes(MENU_ITEMS.HILO.feature)
      );
    },

    $modalUrl(direction, modalName) {
      if (direction === 'open' && !this.$route.query.modal) {
        this.$router.push({
          query: {
            ...this.$route.query,
            modal: modalName,
          },
        }).catch(() => {});
      } else if (direction === 'close') {
        this.$router.push({
          query: {
            ...this.$route.query,
            modal: undefined,
          },
        }).catch(() => {});
      }
    },

    $showConfirmDialog({ componentProps, modalProps, modalEvents }) {
      this.$modal.show(
        ConfirmationDialog,
        componentProps,
        {
          clickToClose: true,
          adaptive: true,
          minWidth: 960,
          minHeight: 330,
          name: CONFIRMATION_DIALOG_NAME,
          ...modalProps,
        },
        modalEvents,
      );
    },

    $closeConfirmDialog() {
      this.$modal.hide(CONFIRMATION_DIALOG_NAME);
    },

    $toggleBodyScroll() {
      document.querySelector('body').classList.toggle('tw-overflow-hidden');
    },
  },
});

Vue.component('HInput', Input);
Vue.component('HButton', Button);
Vue.component('HTheBtn', TheButton);
Vue.component('HTextField', TextField);
Vue.component('HRadio', Radio);
Vue.component('HInputGroupLabel', InputGroupLabel);
Vue.use(PerfectScrollbar);
Vue.use(VueClipboard);
Vue.use(Vuelidate);
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: { clickToClose: false },
});
Vue.use(VueShortkey, { prevent: ['input', 'textarea'] });
Vue.use(svgicon, {
  tagName: 'svgicon',
});
Vue.use(VTooltip);

// Wrap the vue instance in a Firebase onAuthStateChanged method
// This stops the execution of the navigation guard 'beforeEach'
// method until the Firebase initialization ends
let app;

Sentry.init({
  Vue,
  enabled: process.env.NODE_ENV === 'production',
  dsn: config.sentryUrl,
  environment: config.environment,
  release: store.state.version,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [/.+(hiber.cloud)/],
    }),
  ],
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.25,
});

Firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});

if (module.hot) {
  module.hot.accept();
}
