// Global functions
import store from '@/store/index';
import moment from 'moment';
import { ClientError } from 'nice-grpc-web';
import { Status } from 'nice-grpc-common';

const internalErrorCodes = [
  Status.CANCELLED,
  Status.UNKNOWN,
  Status.ABORTED,
  Status.UNIMPLEMENTED,
  Status.INTERNAL,
  Status.UNAVAILABLE,
  Status.DATA_LOSS,
];

const isJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export function defaultErrorHandler(res, reject, context) {
  let generatedMessage = res?.details;

  if (res instanceof ClientError) {
    if (res.details) {
      if (internalErrorCodes.includes(res.code)) {
        generatedMessage = 'Internal connection error: contact support if the problem persists.';
      }
      if (isJson(res.details)) {
        const details = JSON.parse(res.details);
        if (details && Object.prototype.hasOwnProperty.call(details, 'message')) {
          generatedMessage = details.message;
        }
      } else {
        generatedMessage = res.details;
      }
    } else {
      generatedMessage = 'An error has occurred, but there is no message.';
    }
  }

  context.dispatch('App/log', { message: generatedMessage, type: 'error' }, { root: true });
  reject(res);
}

export function successHandler(res, resolve, context, logMessage) {
  const message = `${`${logMessage.category} ${logMessage.name} ${logMessage.action}`}`;
  context.dispatch('App/log', { message, type: 'success' }, { root: true });
  resolve(res);
}

export function createUint8Array(string) {
  const contentBytes = [];
  for (let i = 0; i < string.length; i += 1) {
    contentBytes.push(string.charCodeAt(i));
  }

  return new Uint8Array(contentBytes);
}

export function convertHexToString(str1) {
  const hex = str1.toString().replace(/\s+/g, '');
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}

export function convertTimeToIso(time) {
  return moment(time).toISOString(true);
}

export function permissionGroupOrganizationPermissionFilter(permissionName) {
  return store.state.User.permissionGroups[permissionName] || {};
}

export function getRemainingAfterDelete(ids, data) {
  const remainingItems = {};
  for (const [key, val] of Object.entries(data)) {
    if (!ids.includes(val.id)) {
      remainingItems[key] = val;
    }
  }
  return Object.keys(remainingItems).map((item) => remainingItems[item]);
}

export function checkFocusExists() {
  if (document.visibilityState === 'visible') {
    return document.hasFocus();
  }

  return false;
}

// eslint-disable-next-line no-bitwise
export const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length);
