import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { getMenuItems } from '@/helpers/menu';
import { REDIRECT_MAPPING } from '@/constants';
import authNavigationGuard from '@/router/authNavigationGuard';
import routes from './routes';

const whiteListedRouteNames = ['OrganizationInvites', 'Changelog', 'User', 'Zendesk', 'ZendeskOut'];

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

function nextWithOrgQuery(to, from, next) {
  if (!to.query.organization && from.query.organization) {
    next({ ...to, query: { ...to.query, organization: from.query.organization } });
  } else {
    next();
  }
}

function getUserDefaultScreen(user, menuItems) {
  const defaultscreen = store.getters['User/getDefaultUserView'];
  // fallback to first item in menu; also check for menuItems update
  const redirectToUserSetting = defaultscreen && menuItems.some(el => el.name === defaultscreen)
    ? menuItems.find(el => el.name === defaultscreen) : menuItems[0];
  return `/${redirectToUserSetting.route}`;
}

router.beforeEach(async (to, from, next) => {
  await authNavigationGuard(to, from, next, {
    // If currentUser
    loggedIn: async (firebaseUser) => {
      const user = await store.dispatch('User/getCurrentUser');
      const termsAndConditionsAccepted = user.acceptedTac;
      const emailVerified = firebaseUser.emailVerified;

      let redirect = '';
      const isWhiteListedPage = whiteListedRouteNames.includes(to.name);

      // the order of the if statements is important
      // the latter has the higher priority in redirect
      if (!store.getters['User/hasUserGotOrganizations'] && to.name !== 'OrganizationInvites') {
        redirect = '/organization-request';
      }
      if (!emailVerified) {
        redirect = '/email-verification';
      }
      if (!termsAndConditionsAccepted) {
        redirect = '/terms-and-conditions';
      }

      if (redirect) {
        if (redirect !== to.path) {
          next(redirect);
        } else {
          next();
        }
      } else if (isWhiteListedPage) {
        nextWithOrgQuery(to, from, next);
      } else {
        try {
          if (to.query.organization) {
            store.commit('Organization/setOrganizationToImpersonate', to.query.organization);
          }
          await store.dispatch('Organization/getOrganization');
          store.dispatch('Events/getEvents');
          const menuItems = getMenuItems();
          const matchedRoute = menuItems.find(({ route }) => to.fullPath.startsWith(`/${route}`));

          // if the org doesn't have access to the path from url
          // find an appropriate redirect path
          if (!matchedRoute) {
            const redirectMappingMatch = REDIRECT_MAPPING.find((item) => to.fullPath.startsWith(`/${item.from}`));
            const redirectMatch = redirectMappingMatch
              ?.to?.find(({ route }) => !!menuItems.find(item => item.route === route));

            const newRoute = redirectMatch ? `/${redirectMatch.route}` : getUserDefaultScreen(user, menuItems);
            next({ path: newRoute || '/list', query: to.query });
          } else {
            nextWithOrgQuery(to, from, next);
          }
        } catch (e) {
          await store.dispatch('Organization/changeOrganization', user.currentOrganization);
          next({ name: 'List' });
        }
      }
    },
    // Authentication failed
    notLoggedIn: () => {
      // reset impersonation when logged out of the app
      store.dispatch('Organization/changeOrganization', '');
      store.dispatch('App/resetData');
      store.commit('User/setShowNow', null);

      if (to.name !== 'Login') {
        store.commit('Auth/saveRedirect', to.fullPath);
      }

      // Redirect if needed, otherwise continue and show the content
      if (to.name === 'Login') {
        next();
      } else {
        next('/login');
      }
    },
  });
});

export default router;
