/**
 * Keeps track of all displayed data with tables. That includes selected states.
 * 'name' in the params refers to the tableName used in "initTable"-mutation.
 */
import CoreModule from '@/store/core/modules/tables';
import Table from '@/store/core/Table';

export const tablesObject = {
  // Please declare used table names here as a 'new Table()'. This is to get Vuex reactivity working.
  // For an overview of what will be dynamically added to a table object, see "initTable"-mutation
  'mojo/inbound': new Table(),
  'mojo/outbound-pending': new Table(),
  'mojo/outbound': new Table(),
  'mojo/lorawan': new Table(),
  'lorawan/configuration': new Table(),
  'events/list': new Table(),
  'list/modems': new Table(),
  'list/groups': new Table(),
  'users/approved': new Table(),
  'users/requests': new Table(),
  'users/invites': new Table(),
  'integrations/webhooks': new Table(),
  'integrations/webhooks-history': new Table(),
  'integrations/tokens': new Table(),
  'integrations/slacks': new Table(),
  'integrations/mqtts': new Table(),
  'integrations/certificates': new Table(),
  'debug/list': new Table(),
  'organization/list': new Table(),
  'ep/assets': new Table(),
  'ep/groups': new Table(),
  'ep/named-locations': new Table(),
  'ep/modem-alarms': new Table(),
};

const Tables = {
  namespaced: true,
  state: {
    ...CoreModule.state,
    ...tablesObject,
  },
  actions: {
    ...CoreModule.actions,
  },
  mutations: {
    ...CoreModule.mutations,
  },
  getters: {
    ...CoreModule.getters,
  },
};

export default Tables;
