/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  Filter_Events,
  Filter_Events_Update,
  Filter_Modems,
  Filter_Modems_Update,
  Filter_Tags,
  Filter_Tags_Update,
  Pagination,
  Pagination_Result,
  TimeRange,
  Timestamp,
} from "./base";

export const protobufPackage = "hiber.email";

export interface EmailNotificationPreferences {
  /** The organization that owns this publisher */
  organization: string;
  /** Events to receive by email. */
  enabledNotifications:
    | Filter_Events
    | undefined;
  /** Filter events by modems. */
  filterModems:
    | Filter_Modems
    | undefined;
  /** Filter events by tags. */
  filterTags:
    | Filter_Tags
    | undefined;
  /** Filter events by health level caused. */
  filterHealthLevels: string[];
  recipient?:
    | { $case: "userId"; userId: string }
    | { $case: "custom"; custom: EmailNotificationPreferences_CustomRecipient }
    | undefined;
  /** Whether email is active for this user/address. */
  active: boolean;
}

export interface EmailNotificationPreferences_CustomRecipient {
  email: string;
  name: string;
}

/** List the custom recipients and their preferences. Optionally, personal email preferences can be included. */
export interface ListEmailNotificationPreferencesRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Search for a (partial) custom email address. */
  searchEmail?:
    | string
    | undefined;
  /**
   * By default, this request only returns the email preferences for custom emails,
   * but you can choose to view user-specific preferences as well.
   */
  includePersonalEmailPreferences?: boolean | undefined;
  pagination?: Pagination | undefined;
}

export interface ListEmailNotificationPreferencesRequest_Response {
  emailPreferences: EmailNotificationPreferences[];
  request: ListEmailNotificationPreferencesRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface ViewEmailNotificationPreferencesRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /**
   * View the configuration for any email publisher with a custom email address.
   * Optional, should only be used if you want to get the email preferences for a custom recipient.
   * When not set, your personal email preferences are returned.
   */
  email?: string | undefined;
}

export interface UpdateEmailNotificationPreferencesRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /**
   * Custom email address.
   * Only set this if you want to send notification to an email address not associated with your user.
   */
  custom?:
    | EmailNotificationPreferences_CustomRecipient
    | undefined;
  /** Events to receive by email. An empty value enables email for all events. */
  enabledNotifications?:
    | Filter_Events
    | undefined;
  /** @deprecated */
  deprecatedEnabledNotifications?:
    | Filter_Events_Update
    | undefined;
  /** Filter events by modems. An empty value enables email for every modem's events. */
  filterModems?:
    | Filter_Modems
    | undefined;
  /** @deprecated */
  deprecatedFilterModems?:
    | Filter_Modems_Update
    | undefined;
  /** Filter events by tags. An empty value enables email for all tags. */
  filterTags?:
    | Filter_Tags
    | undefined;
  /** @deprecated */
  deprecatedFilterTags?:
    | Filter_Tags_Update
    | undefined;
  /** Add health levels to the health levels filter. */
  addHealthLevelsToFilter: string[];
  /** Remove health levels from the health levels filter. */
  removeHealthLevelsFromFilter: string[];
}

export interface RemoveAllEmailNotificationPreferencesRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Custom email address preferences to disable. */
  custom?: EmailNotificationPreferences_CustomRecipient | undefined;
}

export interface RemoveAllEmailNotificationPreferencesRequest_Response {
}

export interface EmailHistorySelection {
  timeRange?: TimeRange | undefined;
  onlyFailures?: boolean | undefined;
}

export interface EmailNotificationHistoryRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Custom email address preferences to get the history for. If not set, your email is used. */
  custom?: EmailNotificationPreferences_CustomRecipient | undefined;
  selection?: EmailHistorySelection | undefined;
  pagination?: Pagination | undefined;
}

export interface EmailNotificationHistoryRequest_Response {
  emails: EmailNotificationHistoryRequest_Response_Email[];
  request: EmailNotificationHistoryRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface EmailNotificationHistoryRequest_Response_Email {
  time: Timestamp | undefined;
  title: string;
  successful: boolean;
  error: string;
}

function createBaseEmailNotificationPreferences(): EmailNotificationPreferences {
  return {
    organization: "",
    enabledNotifications: undefined,
    filterModems: undefined,
    filterTags: undefined,
    filterHealthLevels: [],
    recipient: undefined,
    active: false,
  };
}

export const EmailNotificationPreferences = {
  encode(message: EmailNotificationPreferences, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.enabledNotifications !== undefined) {
      Filter_Events.encode(message.enabledNotifications, writer.uint32(18).fork()).ldelim();
    }
    if (message.filterModems !== undefined) {
      Filter_Modems.encode(message.filterModems, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterTags !== undefined) {
      Filter_Tags.encode(message.filterTags, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.filterHealthLevels) {
      writer.uint32(66).string(v!);
    }
    switch (message.recipient?.$case) {
      case "userId":
        writer.uint32(42).string(message.recipient.userId);
        break;
      case "custom":
        EmailNotificationPreferences_CustomRecipient.encode(message.recipient.custom, writer.uint32(50).fork())
          .ldelim();
        break;
    }
    if (message.active === true) {
      writer.uint32(56).bool(message.active);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailNotificationPreferences {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailNotificationPreferences();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.enabledNotifications = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterModems = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filterTags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.filterHealthLevels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.recipient = { $case: "userId", userId: reader.string() };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.recipient = {
            $case: "custom",
            custom: EmailNotificationPreferences_CustomRecipient.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.active = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EmailNotificationPreferences>): EmailNotificationPreferences {
    return EmailNotificationPreferences.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EmailNotificationPreferences>): EmailNotificationPreferences {
    const message = createBaseEmailNotificationPreferences();
    message.organization = object.organization ?? "";
    message.enabledNotifications = (object.enabledNotifications !== undefined && object.enabledNotifications !== null)
      ? Filter_Events.fromPartial(object.enabledNotifications)
      : undefined;
    message.filterModems = (object.filterModems !== undefined && object.filterModems !== null)
      ? Filter_Modems.fromPartial(object.filterModems)
      : undefined;
    message.filterTags = (object.filterTags !== undefined && object.filterTags !== null)
      ? Filter_Tags.fromPartial(object.filterTags)
      : undefined;
    message.filterHealthLevels = object.filterHealthLevels?.map((e) => e) || [];
    if (
      object.recipient?.$case === "userId" &&
      object.recipient?.userId !== undefined &&
      object.recipient?.userId !== null
    ) {
      message.recipient = { $case: "userId", userId: object.recipient.userId };
    }
    if (
      object.recipient?.$case === "custom" &&
      object.recipient?.custom !== undefined &&
      object.recipient?.custom !== null
    ) {
      message.recipient = {
        $case: "custom",
        custom: EmailNotificationPreferences_CustomRecipient.fromPartial(object.recipient.custom),
      };
    }
    message.active = object.active ?? false;
    return message;
  },
};

function createBaseEmailNotificationPreferences_CustomRecipient(): EmailNotificationPreferences_CustomRecipient {
  return { email: "", name: "" };
}

export const EmailNotificationPreferences_CustomRecipient = {
  encode(message: EmailNotificationPreferences_CustomRecipient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailNotificationPreferences_CustomRecipient {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailNotificationPreferences_CustomRecipient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<EmailNotificationPreferences_CustomRecipient>,
  ): EmailNotificationPreferences_CustomRecipient {
    return EmailNotificationPreferences_CustomRecipient.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<EmailNotificationPreferences_CustomRecipient>,
  ): EmailNotificationPreferences_CustomRecipient {
    const message = createBaseEmailNotificationPreferences_CustomRecipient();
    message.email = object.email ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListEmailNotificationPreferencesRequest(): ListEmailNotificationPreferencesRequest {
  return {
    organization: undefined,
    searchEmail: undefined,
    includePersonalEmailPreferences: undefined,
    pagination: undefined,
  };
}

export const ListEmailNotificationPreferencesRequest = {
  encode(message: ListEmailNotificationPreferencesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.searchEmail !== undefined) {
      writer.uint32(18).string(message.searchEmail);
    }
    if (message.includePersonalEmailPreferences !== undefined) {
      writer.uint32(24).bool(message.includePersonalEmailPreferences);
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListEmailNotificationPreferencesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListEmailNotificationPreferencesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.searchEmail = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includePersonalEmailPreferences = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListEmailNotificationPreferencesRequest>): ListEmailNotificationPreferencesRequest {
    return ListEmailNotificationPreferencesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListEmailNotificationPreferencesRequest>): ListEmailNotificationPreferencesRequest {
    const message = createBaseListEmailNotificationPreferencesRequest();
    message.organization = object.organization ?? undefined;
    message.searchEmail = object.searchEmail ?? undefined;
    message.includePersonalEmailPreferences = object.includePersonalEmailPreferences ?? undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListEmailNotificationPreferencesRequest_Response(): ListEmailNotificationPreferencesRequest_Response {
  return { emailPreferences: [], request: undefined, pagination: undefined };
}

export const ListEmailNotificationPreferencesRequest_Response = {
  encode(
    message: ListEmailNotificationPreferencesRequest_Response,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.emailPreferences) {
      EmailNotificationPreferences.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListEmailNotificationPreferencesRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListEmailNotificationPreferencesRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListEmailNotificationPreferencesRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emailPreferences.push(EmailNotificationPreferences.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListEmailNotificationPreferencesRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListEmailNotificationPreferencesRequest_Response>,
  ): ListEmailNotificationPreferencesRequest_Response {
    return ListEmailNotificationPreferencesRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListEmailNotificationPreferencesRequest_Response>,
  ): ListEmailNotificationPreferencesRequest_Response {
    const message = createBaseListEmailNotificationPreferencesRequest_Response();
    message.emailPreferences = object.emailPreferences?.map((e) => EmailNotificationPreferences.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListEmailNotificationPreferencesRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseViewEmailNotificationPreferencesRequest(): ViewEmailNotificationPreferencesRequest {
  return { organization: undefined, email: undefined };
}

export const ViewEmailNotificationPreferencesRequest = {
  encode(message: ViewEmailNotificationPreferencesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.email !== undefined) {
      writer.uint32(18).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ViewEmailNotificationPreferencesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseViewEmailNotificationPreferencesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ViewEmailNotificationPreferencesRequest>): ViewEmailNotificationPreferencesRequest {
    return ViewEmailNotificationPreferencesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ViewEmailNotificationPreferencesRequest>): ViewEmailNotificationPreferencesRequest {
    const message = createBaseViewEmailNotificationPreferencesRequest();
    message.organization = object.organization ?? undefined;
    message.email = object.email ?? undefined;
    return message;
  },
};

function createBaseUpdateEmailNotificationPreferencesRequest(): UpdateEmailNotificationPreferencesRequest {
  return {
    organization: undefined,
    custom: undefined,
    enabledNotifications: undefined,
    deprecatedEnabledNotifications: undefined,
    filterModems: undefined,
    deprecatedFilterModems: undefined,
    filterTags: undefined,
    deprecatedFilterTags: undefined,
    addHealthLevelsToFilter: [],
    removeHealthLevelsFromFilter: [],
  };
}

export const UpdateEmailNotificationPreferencesRequest = {
  encode(message: UpdateEmailNotificationPreferencesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.custom !== undefined) {
      EmailNotificationPreferences_CustomRecipient.encode(message.custom, writer.uint32(18).fork()).ldelim();
    }
    if (message.enabledNotifications !== undefined) {
      Filter_Events.encode(message.enabledNotifications, writer.uint32(66).fork()).ldelim();
    }
    if (message.deprecatedEnabledNotifications !== undefined) {
      Filter_Events_Update.encode(message.deprecatedEnabledNotifications, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterModems !== undefined) {
      Filter_Modems.encode(message.filterModems, writer.uint32(74).fork()).ldelim();
    }
    if (message.deprecatedFilterModems !== undefined) {
      Filter_Modems_Update.encode(message.deprecatedFilterModems, writer.uint32(34).fork()).ldelim();
    }
    if (message.filterTags !== undefined) {
      Filter_Tags.encode(message.filterTags, writer.uint32(82).fork()).ldelim();
    }
    if (message.deprecatedFilterTags !== undefined) {
      Filter_Tags_Update.encode(message.deprecatedFilterTags, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.addHealthLevelsToFilter) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.removeHealthLevelsFromFilter) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEmailNotificationPreferencesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEmailNotificationPreferencesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.custom = EmailNotificationPreferences_CustomRecipient.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.enabledNotifications = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedEnabledNotifications = Filter_Events_Update.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.filterModems = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deprecatedFilterModems = Filter_Modems_Update.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.filterTags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedFilterTags = Filter_Tags_Update.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.addHealthLevelsToFilter.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.removeHealthLevelsFromFilter.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateEmailNotificationPreferencesRequest>): UpdateEmailNotificationPreferencesRequest {
    return UpdateEmailNotificationPreferencesRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateEmailNotificationPreferencesRequest>,
  ): UpdateEmailNotificationPreferencesRequest {
    const message = createBaseUpdateEmailNotificationPreferencesRequest();
    message.organization = object.organization ?? undefined;
    message.custom = (object.custom !== undefined && object.custom !== null)
      ? EmailNotificationPreferences_CustomRecipient.fromPartial(object.custom)
      : undefined;
    message.enabledNotifications = (object.enabledNotifications !== undefined && object.enabledNotifications !== null)
      ? Filter_Events.fromPartial(object.enabledNotifications)
      : undefined;
    message.deprecatedEnabledNotifications =
      (object.deprecatedEnabledNotifications !== undefined && object.deprecatedEnabledNotifications !== null)
        ? Filter_Events_Update.fromPartial(object.deprecatedEnabledNotifications)
        : undefined;
    message.filterModems = (object.filterModems !== undefined && object.filterModems !== null)
      ? Filter_Modems.fromPartial(object.filterModems)
      : undefined;
    message.deprecatedFilterModems =
      (object.deprecatedFilterModems !== undefined && object.deprecatedFilterModems !== null)
        ? Filter_Modems_Update.fromPartial(object.deprecatedFilterModems)
        : undefined;
    message.filterTags = (object.filterTags !== undefined && object.filterTags !== null)
      ? Filter_Tags.fromPartial(object.filterTags)
      : undefined;
    message.deprecatedFilterTags = (object.deprecatedFilterTags !== undefined && object.deprecatedFilterTags !== null)
      ? Filter_Tags_Update.fromPartial(object.deprecatedFilterTags)
      : undefined;
    message.addHealthLevelsToFilter = object.addHealthLevelsToFilter?.map((e) => e) || [];
    message.removeHealthLevelsFromFilter = object.removeHealthLevelsFromFilter?.map((e) => e) || [];
    return message;
  },
};

function createBaseRemoveAllEmailNotificationPreferencesRequest(): RemoveAllEmailNotificationPreferencesRequest {
  return { organization: undefined, custom: undefined };
}

export const RemoveAllEmailNotificationPreferencesRequest = {
  encode(message: RemoveAllEmailNotificationPreferencesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.custom !== undefined) {
      EmailNotificationPreferences_CustomRecipient.encode(message.custom, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveAllEmailNotificationPreferencesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveAllEmailNotificationPreferencesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.custom = EmailNotificationPreferences_CustomRecipient.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<RemoveAllEmailNotificationPreferencesRequest>,
  ): RemoveAllEmailNotificationPreferencesRequest {
    return RemoveAllEmailNotificationPreferencesRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<RemoveAllEmailNotificationPreferencesRequest>,
  ): RemoveAllEmailNotificationPreferencesRequest {
    const message = createBaseRemoveAllEmailNotificationPreferencesRequest();
    message.organization = object.organization ?? undefined;
    message.custom = (object.custom !== undefined && object.custom !== null)
      ? EmailNotificationPreferences_CustomRecipient.fromPartial(object.custom)
      : undefined;
    return message;
  },
};

function createBaseRemoveAllEmailNotificationPreferencesRequest_Response(): RemoveAllEmailNotificationPreferencesRequest_Response {
  return {};
}

export const RemoveAllEmailNotificationPreferencesRequest_Response = {
  encode(
    _: RemoveAllEmailNotificationPreferencesRequest_Response,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveAllEmailNotificationPreferencesRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveAllEmailNotificationPreferencesRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<RemoveAllEmailNotificationPreferencesRequest_Response>,
  ): RemoveAllEmailNotificationPreferencesRequest_Response {
    return RemoveAllEmailNotificationPreferencesRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<RemoveAllEmailNotificationPreferencesRequest_Response>,
  ): RemoveAllEmailNotificationPreferencesRequest_Response {
    const message = createBaseRemoveAllEmailNotificationPreferencesRequest_Response();
    return message;
  },
};

function createBaseEmailHistorySelection(): EmailHistorySelection {
  return { timeRange: undefined, onlyFailures: undefined };
}

export const EmailHistorySelection = {
  encode(message: EmailHistorySelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(10).fork()).ldelim();
    }
    if (message.onlyFailures !== undefined) {
      writer.uint32(16).bool(message.onlyFailures);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailHistorySelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailHistorySelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.onlyFailures = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EmailHistorySelection>): EmailHistorySelection {
    return EmailHistorySelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EmailHistorySelection>): EmailHistorySelection {
    const message = createBaseEmailHistorySelection();
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.onlyFailures = object.onlyFailures ?? undefined;
    return message;
  },
};

function createBaseEmailNotificationHistoryRequest(): EmailNotificationHistoryRequest {
  return { organization: undefined, custom: undefined, selection: undefined, pagination: undefined };
}

export const EmailNotificationHistoryRequest = {
  encode(message: EmailNotificationHistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.custom !== undefined) {
      EmailNotificationPreferences_CustomRecipient.encode(message.custom, writer.uint32(18).fork()).ldelim();
    }
    if (message.selection !== undefined) {
      EmailHistorySelection.encode(message.selection, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailNotificationHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailNotificationHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.custom = EmailNotificationPreferences_CustomRecipient.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selection = EmailHistorySelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EmailNotificationHistoryRequest>): EmailNotificationHistoryRequest {
    return EmailNotificationHistoryRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EmailNotificationHistoryRequest>): EmailNotificationHistoryRequest {
    const message = createBaseEmailNotificationHistoryRequest();
    message.organization = object.organization ?? undefined;
    message.custom = (object.custom !== undefined && object.custom !== null)
      ? EmailNotificationPreferences_CustomRecipient.fromPartial(object.custom)
      : undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? EmailHistorySelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseEmailNotificationHistoryRequest_Response(): EmailNotificationHistoryRequest_Response {
  return { emails: [], request: undefined, pagination: undefined };
}

export const EmailNotificationHistoryRequest_Response = {
  encode(message: EmailNotificationHistoryRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.emails) {
      EmailNotificationHistoryRequest_Response_Email.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      EmailNotificationHistoryRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailNotificationHistoryRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailNotificationHistoryRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emails.push(EmailNotificationHistoryRequest_Response_Email.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = EmailNotificationHistoryRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EmailNotificationHistoryRequest_Response>): EmailNotificationHistoryRequest_Response {
    return EmailNotificationHistoryRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EmailNotificationHistoryRequest_Response>): EmailNotificationHistoryRequest_Response {
    const message = createBaseEmailNotificationHistoryRequest_Response();
    message.emails = object.emails?.map((e) => EmailNotificationHistoryRequest_Response_Email.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? EmailNotificationHistoryRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseEmailNotificationHistoryRequest_Response_Email(): EmailNotificationHistoryRequest_Response_Email {
  return { time: undefined, title: "", successful: false, error: "" };
}

export const EmailNotificationHistoryRequest_Response_Email = {
  encode(
    message: EmailNotificationHistoryRequest_Response_Email,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(10).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.successful === true) {
      writer.uint32(32).bool(message.successful);
    }
    if (message.error !== "") {
      writer.uint32(42).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmailNotificationHistoryRequest_Response_Email {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmailNotificationHistoryRequest_Response_Email();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.successful = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.error = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<EmailNotificationHistoryRequest_Response_Email>,
  ): EmailNotificationHistoryRequest_Response_Email {
    return EmailNotificationHistoryRequest_Response_Email.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<EmailNotificationHistoryRequest_Response_Email>,
  ): EmailNotificationHistoryRequest_Response_Email {
    const message = createBaseEmailNotificationHistoryRequest_Response_Email();
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.title = object.title ?? "";
    message.successful = object.successful ?? false;
    message.error = object.error ?? "";
    return message;
  },
};

export type EmailNotificationPreferencesServiceDefinition = typeof EmailNotificationPreferencesServiceDefinition;
export const EmailNotificationPreferencesServiceDefinition = {
  name: "EmailNotificationPreferencesService",
  fullName: "hiber.email.EmailNotificationPreferencesService",
  methods: {
    list: {
      name: "List",
      requestType: ListEmailNotificationPreferencesRequest,
      requestStream: false,
      responseType: ListEmailNotificationPreferencesRequest_Response,
      responseStream: false,
      options: {},
    },
    view: {
      name: "View",
      requestType: ViewEmailNotificationPreferencesRequest,
      requestStream: false,
      responseType: EmailNotificationPreferences,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: UpdateEmailNotificationPreferencesRequest,
      requestStream: false,
      responseType: EmailNotificationPreferences,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: RemoveAllEmailNotificationPreferencesRequest,
      requestStream: false,
      responseType: RemoveAllEmailNotificationPreferencesRequest_Response,
      responseStream: false,
      options: {},
    },
    history: {
      name: "History",
      requestType: EmailNotificationHistoryRequest,
      requestStream: false,
      responseType: EmailNotificationHistoryRequest_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
