/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Duration, Pagination, Pagination_Result, TimeRange, Timestamp } from "./base";

export const protobufPackage = "hiber.export";

/** An export created by this organization. */
export interface Export {
  /** Identifier for this export. */
  identifier: string;
  /** The name given for this export. */
  name: string;
  /** Status of this export. Whether it's available, expired or still processing. */
  status: Export_Status;
  /**
   * When the status is READY, the downloadable file(s) in the export.
   * The file may be a single url, or may be split into multiple parts, each with a unique url.
   */
  urls: Export_File[];
  /** When the export expires. Only set when the status is READY or EXPIRED. */
  expiresAt:
    | Timestamp
    | undefined;
  /** The format for the export. */
  format:
    | Export_Format
    | undefined;
  /** Specific configuration for the export. */
  configuration:
    | Export_Configuration
    | undefined;
  /** When the status is FAILED, this error message contains details on the reason of the failure. */
  error: string;
}

export enum Export_Status {
  /** READY - Files can be downloaded now */
  READY = "READY",
  /** EXPIRED - Export has expired and is no longer available. */
  EXPIRED = "EXPIRED",
  /** PROCESSING - Data is still being processed to create the export. */
  PROCESSING = "PROCESSING",
  /** FAILED - The export failed. See the error message for more details. */
  FAILED = "FAILED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function export_StatusFromJSON(object: any): Export_Status {
  switch (object) {
    case 0:
    case "READY":
      return Export_Status.READY;
    case 1:
    case "EXPIRED":
      return Export_Status.EXPIRED;
    case 2:
    case "PROCESSING":
      return Export_Status.PROCESSING;
    case 3:
    case "FAILED":
      return Export_Status.FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Export_Status.UNRECOGNIZED;
  }
}

export function export_StatusToNumber(object: Export_Status): number {
  switch (object) {
    case Export_Status.READY:
      return 0;
    case Export_Status.EXPIRED:
      return 1;
    case Export_Status.PROCESSING:
      return 2;
    case Export_Status.FAILED:
      return 3;
    case Export_Status.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Downloadable file. */
export interface Export_File {
  name: string;
  url: string;
}

/** The format for the export. */
export interface Export_Format {
  type: Export_Format_Type;
  configuration?: { $case: "json"; json: Export_Format_Json } | { $case: "csv"; csv: Export_Format_Csv } | undefined;
}

export enum Export_Format_Type {
  DEFAULT = "DEFAULT",
  JSON = "JSON",
  CSV = "CSV",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function export_Format_TypeFromJSON(object: any): Export_Format_Type {
  switch (object) {
    case 0:
    case "DEFAULT":
      return Export_Format_Type.DEFAULT;
    case 1:
    case "JSON":
      return Export_Format_Type.JSON;
    case 2:
    case "CSV":
      return Export_Format_Type.CSV;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Export_Format_Type.UNRECOGNIZED;
  }
}

export function export_Format_TypeToNumber(object: Export_Format_Type): number {
  switch (object) {
    case Export_Format_Type.DEFAULT:
      return 0;
    case Export_Format_Type.JSON:
      return 1;
    case Export_Format_Type.CSV:
      return 2;
    case Export_Format_Type.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Json format for export. */
export interface Export_Format_Json {
  /** Custom fields mapping, if a reduced json is desired. */
  customFields: { [key: string]: string };
  /** Whether the json should be formatted or as compact as possible. */
  compact: boolean;
}

export interface Export_Format_Json_CustomFieldsEntry {
  key: string;
  value: string;
}

/** CSV (comma separated values) format for export. */
export interface Export_Format_Csv {
  /**
   * The columns in the CSV, with their mapping.
   * Columns in CSV are a flat structure, while the data is generally nested, so a mapping is required
   * to flatten it to simple columns.
   */
  columns: Export_Format_Csv_Column[];
  /** The separator character to use in the CSV. Default: , */
  separator?:
    | string
    | undefined;
  /** The quote character to use in the CSV. Default: " */
  quote?:
    | string
    | undefined;
  /** The escape character to use in the CSV. Default: " */
  escape?:
    | string
    | undefined;
  /** The newlines to use in the CSV. Default: \n */
  newline?: string | undefined;
}

export interface Export_Format_Csv_Column {
  /** Column name. */
  name: string;
  /** Column mapping from the nested structure to a value. */
  jsonPath: string;
}

/** The configuration for the export, which determines what data is exported. */
export interface Export_Configuration {
  /** The time urls will be available after the moment the export has the READY status. */
  expiresIn: Duration | undefined;
  type?: { $case: "modemMessage"; modemMessage: Export_Configuration_ModemMessages } | {
    $case: "assetValues";
    assetValues: Export_Configuration_AssetValues;
  } | undefined;
}

/** Export modem messages for a set of modems. */
export interface Export_Configuration_ModemMessages {
  modems: string[];
  timeRange: TimeRange | undefined;
}

/** Export values for a set of assets. */
export interface Export_Configuration_AssetValues {
  assetIdentifiers: string[];
  timeRange: TimeRange | undefined;
}

export interface ExportSelection {
  /** Select by identifiers */
  identifiers: string[];
  /** Search through identifiers and urls */
  search?:
    | string
    | undefined;
  /** Whether to include expired exports. */
  includeExpired?: boolean | undefined;
}

export interface ListExports {
}

export interface ListExports_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the exports to list. Optional, when omitted or empty everything is included. */
  selection?: ExportSelection | undefined;
  pagination?: Pagination | undefined;
}

export interface ListExports_Response {
  exports: Export[];
  pagination: Pagination_Result | undefined;
  request: ListExports_Request | undefined;
}

export interface CreateExport {
}

export interface CreateExport_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  name: string;
  /** The format for the export. */
  format:
    | Export_Format
    | undefined;
  /** Specific configuration for the export. */
  configuration:
    | Export_Configuration
    | undefined;
  /** Subscribe to email updates to get an email with links when the export is ready. */
  emailSubscribe?: boolean | undefined;
}

export interface CreateExport_Response {
  created: Export | undefined;
  request: CreateExport_Request | undefined;
}

export interface AvailableFieldsForExport {
}

export interface AvailableFieldsForExport_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  configuration?: { $case: "modemMessage"; modemMessage: Export_Configuration_ModemMessages } | {
    $case: "assetValues";
    assetValues: Export_Configuration_AssetValues;
  } | undefined;
}

export interface AvailableFieldsForExport_Response {
  /** All json paths that are available for json field mapping. */
  jsonPaths: string[];
  /** Available CSV columns, pre-named with suggested names. */
  csvColumns: Export_Format_Csv_Column[];
}

export interface ExtendExportDownloadExpiry {
}

export interface ExtendExportDownloadExpiry_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Export identifier */
  identifier: string;
  /** The time to extend the expiry with. */
  extendBy:
    | Duration
    | undefined;
  /** Extend the expiry for a specific file. If empty, all files are extended. */
  file: string[];
}

export interface ExtendExportDownloadExpiry_Response {
  export: Export | undefined;
}

export interface DeleteExport {
}

export interface DeleteExport_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  identifier: string;
}

export interface DeleteExport_Response {
}

function createBaseExport(): Export {
  return {
    identifier: "",
    name: "",
    status: Export_Status.READY,
    urls: [],
    expiresAt: undefined,
    format: undefined,
    configuration: undefined,
    error: "",
  };
}

export const Export = {
  encode(message: Export, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.status !== Export_Status.READY) {
      writer.uint32(24).int32(export_StatusToNumber(message.status));
    }
    for (const v of message.urls) {
      Export_File.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.format !== undefined) {
      Export_Format.encode(message.format, writer.uint32(50).fork()).ldelim();
    }
    if (message.configuration !== undefined) {
      Export_Configuration.encode(message.configuration, writer.uint32(58).fork()).ldelim();
    }
    if (message.error !== "") {
      writer.uint32(66).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = export_StatusFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.urls.push(Export_File.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.format = Export_Format.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.configuration = Export_Configuration.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.error = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export>): Export {
    return Export.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export>): Export {
    const message = createBaseExport();
    message.identifier = object.identifier ?? "";
    message.name = object.name ?? "";
    message.status = object.status ?? Export_Status.READY;
    message.urls = object.urls?.map((e) => Export_File.fromPartial(e)) || [];
    message.expiresAt = (object.expiresAt !== undefined && object.expiresAt !== null)
      ? Timestamp.fromPartial(object.expiresAt)
      : undefined;
    message.format = (object.format !== undefined && object.format !== null)
      ? Export_Format.fromPartial(object.format)
      : undefined;
    message.configuration = (object.configuration !== undefined && object.configuration !== null)
      ? Export_Configuration.fromPartial(object.configuration)
      : undefined;
    message.error = object.error ?? "";
    return message;
  },
};

function createBaseExport_File(): Export_File {
  return { name: "", url: "" };
}

export const Export_File = {
  encode(message: Export_File, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_File {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_File();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_File>): Export_File {
    return Export_File.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_File>): Export_File {
    const message = createBaseExport_File();
    message.name = object.name ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseExport_Format(): Export_Format {
  return { type: Export_Format_Type.DEFAULT, configuration: undefined };
}

export const Export_Format = {
  encode(message: Export_Format, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== Export_Format_Type.DEFAULT) {
      writer.uint32(8).int32(export_Format_TypeToNumber(message.type));
    }
    switch (message.configuration?.$case) {
      case "json":
        Export_Format_Json.encode(message.configuration.json, writer.uint32(18).fork()).ldelim();
        break;
      case "csv":
        Export_Format_Csv.encode(message.configuration.csv, writer.uint32(26).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_Format {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_Format();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = export_Format_TypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.configuration = { $case: "json", json: Export_Format_Json.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.configuration = { $case: "csv", csv: Export_Format_Csv.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_Format>): Export_Format {
    return Export_Format.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_Format>): Export_Format {
    const message = createBaseExport_Format();
    message.type = object.type ?? Export_Format_Type.DEFAULT;
    if (
      object.configuration?.$case === "json" &&
      object.configuration?.json !== undefined &&
      object.configuration?.json !== null
    ) {
      message.configuration = { $case: "json", json: Export_Format_Json.fromPartial(object.configuration.json) };
    }
    if (
      object.configuration?.$case === "csv" &&
      object.configuration?.csv !== undefined &&
      object.configuration?.csv !== null
    ) {
      message.configuration = { $case: "csv", csv: Export_Format_Csv.fromPartial(object.configuration.csv) };
    }
    return message;
  },
};

function createBaseExport_Format_Json(): Export_Format_Json {
  return { customFields: {}, compact: false };
}

export const Export_Format_Json = {
  encode(message: Export_Format_Json, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.customFields).forEach(([key, value]) => {
      Export_Format_Json_CustomFieldsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.compact === true) {
      writer.uint32(16).bool(message.compact);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_Format_Json {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_Format_Json();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Export_Format_Json_CustomFieldsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.customFields[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.compact = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_Format_Json>): Export_Format_Json {
    return Export_Format_Json.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_Format_Json>): Export_Format_Json {
    const message = createBaseExport_Format_Json();
    message.customFields = Object.entries(object.customFields ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.compact = object.compact ?? false;
    return message;
  },
};

function createBaseExport_Format_Json_CustomFieldsEntry(): Export_Format_Json_CustomFieldsEntry {
  return { key: "", value: "" };
}

export const Export_Format_Json_CustomFieldsEntry = {
  encode(message: Export_Format_Json_CustomFieldsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_Format_Json_CustomFieldsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_Format_Json_CustomFieldsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_Format_Json_CustomFieldsEntry>): Export_Format_Json_CustomFieldsEntry {
    return Export_Format_Json_CustomFieldsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_Format_Json_CustomFieldsEntry>): Export_Format_Json_CustomFieldsEntry {
    const message = createBaseExport_Format_Json_CustomFieldsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseExport_Format_Csv(): Export_Format_Csv {
  return { columns: [], separator: undefined, quote: undefined, escape: undefined, newline: undefined };
}

export const Export_Format_Csv = {
  encode(message: Export_Format_Csv, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.columns) {
      Export_Format_Csv_Column.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.separator !== undefined) {
      writer.uint32(18).string(message.separator);
    }
    if (message.quote !== undefined) {
      writer.uint32(26).string(message.quote);
    }
    if (message.escape !== undefined) {
      writer.uint32(34).string(message.escape);
    }
    if (message.newline !== undefined) {
      writer.uint32(42).string(message.newline);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_Format_Csv {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_Format_Csv();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.columns.push(Export_Format_Csv_Column.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.separator = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.quote = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.escape = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.newline = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_Format_Csv>): Export_Format_Csv {
    return Export_Format_Csv.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_Format_Csv>): Export_Format_Csv {
    const message = createBaseExport_Format_Csv();
    message.columns = object.columns?.map((e) => Export_Format_Csv_Column.fromPartial(e)) || [];
    message.separator = object.separator ?? undefined;
    message.quote = object.quote ?? undefined;
    message.escape = object.escape ?? undefined;
    message.newline = object.newline ?? undefined;
    return message;
  },
};

function createBaseExport_Format_Csv_Column(): Export_Format_Csv_Column {
  return { name: "", jsonPath: "" };
}

export const Export_Format_Csv_Column = {
  encode(message: Export_Format_Csv_Column, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.jsonPath !== "") {
      writer.uint32(18).string(message.jsonPath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_Format_Csv_Column {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_Format_Csv_Column();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.jsonPath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_Format_Csv_Column>): Export_Format_Csv_Column {
    return Export_Format_Csv_Column.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_Format_Csv_Column>): Export_Format_Csv_Column {
    const message = createBaseExport_Format_Csv_Column();
    message.name = object.name ?? "";
    message.jsonPath = object.jsonPath ?? "";
    return message;
  },
};

function createBaseExport_Configuration(): Export_Configuration {
  return { expiresIn: undefined, type: undefined };
}

export const Export_Configuration = {
  encode(message: Export_Configuration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expiresIn !== undefined) {
      Duration.encode(message.expiresIn, writer.uint32(10).fork()).ldelim();
    }
    switch (message.type?.$case) {
      case "modemMessage":
        Export_Configuration_ModemMessages.encode(message.type.modemMessage, writer.uint32(18).fork()).ldelim();
        break;
      case "assetValues":
        Export_Configuration_AssetValues.encode(message.type.assetValues, writer.uint32(26).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_Configuration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_Configuration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expiresIn = Duration.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = {
            $case: "modemMessage",
            modemMessage: Export_Configuration_ModemMessages.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = {
            $case: "assetValues",
            assetValues: Export_Configuration_AssetValues.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_Configuration>): Export_Configuration {
    return Export_Configuration.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_Configuration>): Export_Configuration {
    const message = createBaseExport_Configuration();
    message.expiresIn = (object.expiresIn !== undefined && object.expiresIn !== null)
      ? Duration.fromPartial(object.expiresIn)
      : undefined;
    if (
      object.type?.$case === "modemMessage" &&
      object.type?.modemMessage !== undefined &&
      object.type?.modemMessage !== null
    ) {
      message.type = {
        $case: "modemMessage",
        modemMessage: Export_Configuration_ModemMessages.fromPartial(object.type.modemMessage),
      };
    }
    if (
      object.type?.$case === "assetValues" &&
      object.type?.assetValues !== undefined &&
      object.type?.assetValues !== null
    ) {
      message.type = {
        $case: "assetValues",
        assetValues: Export_Configuration_AssetValues.fromPartial(object.type.assetValues),
      };
    }
    return message;
  },
};

function createBaseExport_Configuration_ModemMessages(): Export_Configuration_ModemMessages {
  return { modems: [], timeRange: undefined };
}

export const Export_Configuration_ModemMessages = {
  encode(message: Export_Configuration_ModemMessages, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modems) {
      writer.uint32(10).string(v!);
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_Configuration_ModemMessages {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_Configuration_ModemMessages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_Configuration_ModemMessages>): Export_Configuration_ModemMessages {
    return Export_Configuration_ModemMessages.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_Configuration_ModemMessages>): Export_Configuration_ModemMessages {
    const message = createBaseExport_Configuration_ModemMessages();
    message.modems = object.modems?.map((e) => e) || [];
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    return message;
  },
};

function createBaseExport_Configuration_AssetValues(): Export_Configuration_AssetValues {
  return { assetIdentifiers: [], timeRange: undefined };
}

export const Export_Configuration_AssetValues = {
  encode(message: Export_Configuration_AssetValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assetIdentifiers) {
      writer.uint32(10).string(v!);
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Export_Configuration_AssetValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExport_Configuration_AssetValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assetIdentifiers.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Export_Configuration_AssetValues>): Export_Configuration_AssetValues {
    return Export_Configuration_AssetValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Export_Configuration_AssetValues>): Export_Configuration_AssetValues {
    const message = createBaseExport_Configuration_AssetValues();
    message.assetIdentifiers = object.assetIdentifiers?.map((e) => e) || [];
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    return message;
  },
};

function createBaseExportSelection(): ExportSelection {
  return { identifiers: [], search: undefined, includeExpired: undefined };
}

export const ExportSelection = {
  encode(message: ExportSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.identifiers) {
      writer.uint32(10).string(v!);
    }
    if (message.search !== undefined) {
      writer.uint32(18).string(message.search);
    }
    if (message.includeExpired !== undefined) {
      writer.uint32(24).bool(message.includeExpired);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExportSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifiers.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.search = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeExpired = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ExportSelection>): ExportSelection {
    return ExportSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ExportSelection>): ExportSelection {
    const message = createBaseExportSelection();
    message.identifiers = object.identifiers?.map((e) => e) || [];
    message.search = object.search ?? undefined;
    message.includeExpired = object.includeExpired ?? undefined;
    return message;
  },
};

function createBaseListExports(): ListExports {
  return {};
}

export const ListExports = {
  encode(_: ListExports, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListExports {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListExports();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListExports>): ListExports {
    return ListExports.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListExports>): ListExports {
    const message = createBaseListExports();
    return message;
  },
};

function createBaseListExports_Request(): ListExports_Request {
  return { organization: undefined, selection: undefined, pagination: undefined };
}

export const ListExports_Request = {
  encode(message: ListExports_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      ExportSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListExports_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListExports_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = ExportSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListExports_Request>): ListExports_Request {
    return ListExports_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListExports_Request>): ListExports_Request {
    const message = createBaseListExports_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ExportSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListExports_Response(): ListExports_Response {
  return { exports: [], pagination: undefined, request: undefined };
}

export const ListExports_Response = {
  encode(message: ListExports_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.exports) {
      Export.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListExports_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListExports_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListExports_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.exports.push(Export.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = ListExports_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListExports_Response>): ListExports_Response {
    return ListExports_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListExports_Response>): ListExports_Response {
    const message = createBaseListExports_Response();
    message.exports = object.exports?.map((e) => Export.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? ListExports_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseCreateExport(): CreateExport {
  return {};
}

export const CreateExport = {
  encode(_: CreateExport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateExport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateExport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateExport>): CreateExport {
    return CreateExport.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<CreateExport>): CreateExport {
    const message = createBaseCreateExport();
    return message;
  },
};

function createBaseCreateExport_Request(): CreateExport_Request {
  return { organization: undefined, name: "", format: undefined, configuration: undefined, emailSubscribe: undefined };
}

export const CreateExport_Request = {
  encode(message: CreateExport_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.format !== undefined) {
      Export_Format.encode(message.format, writer.uint32(26).fork()).ldelim();
    }
    if (message.configuration !== undefined) {
      Export_Configuration.encode(message.configuration, writer.uint32(34).fork()).ldelim();
    }
    if (message.emailSubscribe !== undefined) {
      writer.uint32(40).bool(message.emailSubscribe);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateExport_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateExport_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.format = Export_Format.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.configuration = Export_Configuration.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.emailSubscribe = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateExport_Request>): CreateExport_Request {
    return CreateExport_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateExport_Request>): CreateExport_Request {
    const message = createBaseCreateExport_Request();
    message.organization = object.organization ?? undefined;
    message.name = object.name ?? "";
    message.format = (object.format !== undefined && object.format !== null)
      ? Export_Format.fromPartial(object.format)
      : undefined;
    message.configuration = (object.configuration !== undefined && object.configuration !== null)
      ? Export_Configuration.fromPartial(object.configuration)
      : undefined;
    message.emailSubscribe = object.emailSubscribe ?? undefined;
    return message;
  },
};

function createBaseCreateExport_Response(): CreateExport_Response {
  return { created: undefined, request: undefined };
}

export const CreateExport_Response = {
  encode(message: CreateExport_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.created !== undefined) {
      Export.encode(message.created, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      CreateExport_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateExport_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateExport_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.created = Export.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = CreateExport_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateExport_Response>): CreateExport_Response {
    return CreateExport_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateExport_Response>): CreateExport_Response {
    const message = createBaseCreateExport_Response();
    message.created = (object.created !== undefined && object.created !== null)
      ? Export.fromPartial(object.created)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? CreateExport_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseAvailableFieldsForExport(): AvailableFieldsForExport {
  return {};
}

export const AvailableFieldsForExport = {
  encode(_: AvailableFieldsForExport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableFieldsForExport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableFieldsForExport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AvailableFieldsForExport>): AvailableFieldsForExport {
    return AvailableFieldsForExport.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AvailableFieldsForExport>): AvailableFieldsForExport {
    const message = createBaseAvailableFieldsForExport();
    return message;
  },
};

function createBaseAvailableFieldsForExport_Request(): AvailableFieldsForExport_Request {
  return { organization: undefined, configuration: undefined };
}

export const AvailableFieldsForExport_Request = {
  encode(message: AvailableFieldsForExport_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    switch (message.configuration?.$case) {
      case "modemMessage":
        Export_Configuration_ModemMessages.encode(message.configuration.modemMessage, writer.uint32(26).fork())
          .ldelim();
        break;
      case "assetValues":
        Export_Configuration_AssetValues.encode(message.configuration.assetValues, writer.uint32(34).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableFieldsForExport_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableFieldsForExport_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.configuration = {
            $case: "modemMessage",
            modemMessage: Export_Configuration_ModemMessages.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.configuration = {
            $case: "assetValues",
            assetValues: Export_Configuration_AssetValues.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AvailableFieldsForExport_Request>): AvailableFieldsForExport_Request {
    return AvailableFieldsForExport_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AvailableFieldsForExport_Request>): AvailableFieldsForExport_Request {
    const message = createBaseAvailableFieldsForExport_Request();
    message.organization = object.organization ?? undefined;
    if (
      object.configuration?.$case === "modemMessage" &&
      object.configuration?.modemMessage !== undefined &&
      object.configuration?.modemMessage !== null
    ) {
      message.configuration = {
        $case: "modemMessage",
        modemMessage: Export_Configuration_ModemMessages.fromPartial(object.configuration.modemMessage),
      };
    }
    if (
      object.configuration?.$case === "assetValues" &&
      object.configuration?.assetValues !== undefined &&
      object.configuration?.assetValues !== null
    ) {
      message.configuration = {
        $case: "assetValues",
        assetValues: Export_Configuration_AssetValues.fromPartial(object.configuration.assetValues),
      };
    }
    return message;
  },
};

function createBaseAvailableFieldsForExport_Response(): AvailableFieldsForExport_Response {
  return { jsonPaths: [], csvColumns: [] };
}

export const AvailableFieldsForExport_Response = {
  encode(message: AvailableFieldsForExport_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.jsonPaths) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.csvColumns) {
      Export_Format_Csv_Column.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableFieldsForExport_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableFieldsForExport_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jsonPaths.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.csvColumns.push(Export_Format_Csv_Column.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AvailableFieldsForExport_Response>): AvailableFieldsForExport_Response {
    return AvailableFieldsForExport_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AvailableFieldsForExport_Response>): AvailableFieldsForExport_Response {
    const message = createBaseAvailableFieldsForExport_Response();
    message.jsonPaths = object.jsonPaths?.map((e) => e) || [];
    message.csvColumns = object.csvColumns?.map((e) => Export_Format_Csv_Column.fromPartial(e)) || [];
    return message;
  },
};

function createBaseExtendExportDownloadExpiry(): ExtendExportDownloadExpiry {
  return {};
}

export const ExtendExportDownloadExpiry = {
  encode(_: ExtendExportDownloadExpiry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtendExportDownloadExpiry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtendExportDownloadExpiry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ExtendExportDownloadExpiry>): ExtendExportDownloadExpiry {
    return ExtendExportDownloadExpiry.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ExtendExportDownloadExpiry>): ExtendExportDownloadExpiry {
    const message = createBaseExtendExportDownloadExpiry();
    return message;
  },
};

function createBaseExtendExportDownloadExpiry_Request(): ExtendExportDownloadExpiry_Request {
  return { organization: undefined, identifier: "", extendBy: undefined, file: [] };
}

export const ExtendExportDownloadExpiry_Request = {
  encode(message: ExtendExportDownloadExpiry_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    if (message.extendBy !== undefined) {
      Duration.encode(message.extendBy, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.file) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtendExportDownloadExpiry_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtendExportDownloadExpiry_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.extendBy = Duration.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.file.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ExtendExportDownloadExpiry_Request>): ExtendExportDownloadExpiry_Request {
    return ExtendExportDownloadExpiry_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ExtendExportDownloadExpiry_Request>): ExtendExportDownloadExpiry_Request {
    const message = createBaseExtendExportDownloadExpiry_Request();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    message.extendBy = (object.extendBy !== undefined && object.extendBy !== null)
      ? Duration.fromPartial(object.extendBy)
      : undefined;
    message.file = object.file?.map((e) => e) || [];
    return message;
  },
};

function createBaseExtendExportDownloadExpiry_Response(): ExtendExportDownloadExpiry_Response {
  return { export: undefined };
}

export const ExtendExportDownloadExpiry_Response = {
  encode(message: ExtendExportDownloadExpiry_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.export !== undefined) {
      Export.encode(message.export, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExtendExportDownloadExpiry_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExtendExportDownloadExpiry_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.export = Export.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ExtendExportDownloadExpiry_Response>): ExtendExportDownloadExpiry_Response {
    return ExtendExportDownloadExpiry_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ExtendExportDownloadExpiry_Response>): ExtendExportDownloadExpiry_Response {
    const message = createBaseExtendExportDownloadExpiry_Response();
    message.export = (object.export !== undefined && object.export !== null)
      ? Export.fromPartial(object.export)
      : undefined;
    return message;
  },
};

function createBaseDeleteExport(): DeleteExport {
  return {};
}

export const DeleteExport = {
  encode(_: DeleteExport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteExport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteExport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteExport>): DeleteExport {
    return DeleteExport.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteExport>): DeleteExport {
    const message = createBaseDeleteExport();
    return message;
  },
};

function createBaseDeleteExport_Request(): DeleteExport_Request {
  return { organization: undefined, identifier: "" };
}

export const DeleteExport_Request = {
  encode(message: DeleteExport_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteExport_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteExport_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteExport_Request>): DeleteExport_Request {
    return DeleteExport_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteExport_Request>): DeleteExport_Request {
    const message = createBaseDeleteExport_Request();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseDeleteExport_Response(): DeleteExport_Response {
  return {};
}

export const DeleteExport_Response = {
  encode(_: DeleteExport_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteExport_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteExport_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteExport_Response>): DeleteExport_Response {
    return DeleteExport_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteExport_Response>): DeleteExport_Response {
    const message = createBaseDeleteExport_Response();
    return message;
  },
};

export type ExportServiceDefinition = typeof ExportServiceDefinition;
export const ExportServiceDefinition = {
  name: "ExportService",
  fullName: "hiber.export.ExportService",
  methods: {
    list: {
      name: "List",
      requestType: ListExports_Request,
      requestStream: false,
      responseType: ListExports_Response,
      responseStream: false,
      options: {},
    },
    create: {
      name: "Create",
      requestType: CreateExport_Request,
      requestStream: false,
      responseType: CreateExport_Response,
      responseStream: false,
      options: {},
    },
    availableFields: {
      name: "AvailableFields",
      requestType: AvailableFieldsForExport_Request,
      requestStream: false,
      responseType: AvailableFieldsForExport_Response,
      responseStream: false,
      options: {},
    },
    extend: {
      name: "Extend",
      requestType: ExtendExportDownloadExpiry_Request,
      requestStream: false,
      responseType: ExtendExportDownloadExpiry_Response,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteExport_Request,
      requestStream: false,
      responseType: DeleteExport_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
