/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'filled-arrow-down': {
    width: 11,
    height: 6,
    viewBox: '0 0 11 6',
    data: '<path pid="0" d="M5.56 5.646L.253.6h10.614z" _fill="#00b3c8" fill-rule="evenodd"/>'
  }
})
