import { defaultErrorHandler } from '@/store/core/utils';
import { getHealthLevelsList } from '@/services/health';

const Health = {
  namespaced: true,
  state: {
    healthLevelsList: [],
  },

  actions: {
    getHealthLevelsList(context) {
      return new Promise((resolve, reject) => {
        getHealthLevelsList().then((res) => {
          context.commit('setHealthLevelsList', res.healthLevels);
          const filteredLevels = res.healthLevels;
          resolve(filteredLevels);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },

  mutations: {
    setHealthLevelsList(state, list) {
      state.healthLevelsList = list;
    },
  },

  getters: {
    getHealthLevelsList(state) {
      return state.healthLevelsList.sort((a, b) => a.severity > b.severity);
    },
  },
};

export default Health;
