/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Duration, LocationSelection, Pagination, Pagination_Result } from "./base";
import { Device, DeviceSelection, Sort, sortFromJSON, sortToNumber } from "./device";
import { Modem_Lifecycle, modem_LifecycleFromJSON, modem_LifecycleToNumber } from "./modem";
import { Value_Numeric_Rate } from "./value";

export const protobufPackage = "hiber.device";

/**
 * Device management.
 *
 * Devices are anything capable of sending messages to the system.
 * They have a unique device (previously modem) number, used to identify them.
 */

/**
 * Update request for devices.
 * When updating a device, all optional fields will be handled as follows:
 * - when not set (omitted), nothing will change
 * - when set to default value (i.e. empty object), setting will be cleared
 * - when set to anything containing actual value, settings will be updated to those values.
 */
export interface UpdateDevice {
  selection:
    | DeviceSelection
    | undefined;
  /**
   * Change the name for the selected devices.
   * To prevent confusion, the selection can only effectively target 1 device when changing the name.
   * Unless you specifically set `allow_bulk_rename`.
   * You'd be left with multiple devices with the same name (technically allowed),
   * but probably something you don't want.
   */
  name?:
    | string
    | undefined;
  /**
   * Allow a rename that results in multiple devices with the same name.
   * Could be useful if you have different sites with devices that fulfill
   * a similar job but are located at different sites.
   * It is advised to make sure those devices have different tags/groups.
   */
  allowBulkRename?:
    | boolean
    | undefined;
  /** Change the notes for the selected devices. */
  notes?:
    | string
    | undefined;
  /**
   * Allow changing notes in bulk if the notes are different.
   * Must set this explicitly to prevent accidental loss of notes.
   */
  allowBulkNotes?:
    | boolean
    | undefined;
  /** Change the notes for the selected devices. */
  secureNotes?:
    | string
    | undefined;
  /**
   * Allow changing notes in bulk if the notes are different.
   * Must set this explicitly to prevent accidental loss of notes.
   */
  allowBulkSecureNotes?:
    | boolean
    | undefined;
  /** Updates the devices peripherals, by adding, removing or replacing. */
  peripherals?:
    | UpdateDevice_UpdatePeripherals
    | undefined;
  /** Set the timezone the device is located in. */
  timeZone?:
    | string
    | undefined;
  /** Update the lifecycle for this device. */
  lifecycle?:
    | Modem_Lifecycle
    | undefined;
  /**
   * Sets the interval this devices is transmitting on.
   * Mainly useful for devices that have a regular interval for transmissions.
   */
  transmissionInterval?:
    | Duration
    | undefined;
  /**
   * Update the expected transmission rate.
   * Expected transmission rate differs from transmission interval in that the device might be sending every hour,
   * but we might just expect a transmission every six hours because network unreliability.
   * This can help with reaching SLA goals, where if we want to have 1 message per day as per the SLA,
   * the device sends every hour to make sure we meet the SLA.
   */
  expectedTransmissionRate?: Value_Numeric_Rate | undefined;
}

/**
 * When updating peripherals, you can choose to add and delete peripherals,
 * or to do a wholesome replace of all peripherals.
 */
export interface UpdateDevice_UpdatePeripherals {
  replace?: { $case: "partialUpdate"; partialUpdate: UpdateDevice_UpdatePeripherals_Partial } | {
    $case: "fullReplace";
    fullReplace: UpdateDevice_UpdatePeripherals_Replace;
  } | undefined;
}

export interface UpdateDevice_UpdatePeripherals_Partial {
  /** Removes peripherals by name in this list. Leaves other peripherals untouched. */
  removePeripherals: string[];
  /** Adds peripherals by name-value from this mapping. Leaves other peripherals untouched. */
  addPeripherals: { [key: string]: string };
}

export interface UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry {
  key: string;
  value: string;
}

export interface UpdateDevice_UpdatePeripherals_Replace {
  /** Replaces the entire set of peripherals. All peripherals not named in this map will be removed. */
  replacePeripherals: { [key: string]: string };
}

export interface UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry {
  key: string;
  value: string;
}

export interface UpdateDevice_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /**
   * Multiple different updates can be made.
   * Every update contains a device selection (historically modem selection)
   * which targets the devices that should be updated.
   */
  devices: UpdateDevice[];
}

export interface UpdateDevice_Response {
  devices: Device[];
  request: UpdateDevice_Request | undefined;
}

export interface ListDevice {
}

export interface ListDevice_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which devices to return. Optional, when omitted or empty everything is included. */
  selection?:
    | DeviceSelection
    | undefined;
  /** Paginate through results. */
  pagination?:
    | Pagination
    | undefined;
  /** Sort the devices with the given sort options. */
  sortBy: Sort[];
  /** Filter devices by location. */
  locationSelection?:
    | LocationSelection
    | undefined;
  /**
   * Set this to true to populate the gateways field in the response.
   * This will be populated with missing gateways for the the devices on this page.
   * Any gateway that is on the current page is not included in this list to avoid duplicate data.
   */
  includeMissingGateways?: boolean | undefined;
}

export interface ListDevice_Response {
  devices: Device[];
  request: ListDevice_Request | undefined;
  pagination: Pagination_Result | undefined;
  sortedBy: Sort[];
  /**
   * This will be populated with missing gateways for the the devices on this page.
   * Any gateway that is on the current page is not included in this list to avoid duplicate data.
   * Only set when include_missing_gateways is true in the request.
   */
  gateways: Device[];
}

function createBaseUpdateDevice(): UpdateDevice {
  return {
    selection: undefined,
    name: undefined,
    allowBulkRename: undefined,
    notes: undefined,
    allowBulkNotes: undefined,
    secureNotes: undefined,
    allowBulkSecureNotes: undefined,
    peripherals: undefined,
    timeZone: undefined,
    lifecycle: undefined,
    transmissionInterval: undefined,
    expectedTransmissionRate: undefined,
  };
}

export const UpdateDevice = {
  encode(message: UpdateDevice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.selection !== undefined) {
      DeviceSelection.encode(message.selection, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.allowBulkRename !== undefined) {
      writer.uint32(24).bool(message.allowBulkRename);
    }
    if (message.notes !== undefined) {
      writer.uint32(34).string(message.notes);
    }
    if (message.allowBulkNotes !== undefined) {
      writer.uint32(40).bool(message.allowBulkNotes);
    }
    if (message.secureNotes !== undefined) {
      writer.uint32(50).string(message.secureNotes);
    }
    if (message.allowBulkSecureNotes !== undefined) {
      writer.uint32(56).bool(message.allowBulkSecureNotes);
    }
    if (message.peripherals !== undefined) {
      UpdateDevice_UpdatePeripherals.encode(message.peripherals, writer.uint32(66).fork()).ldelim();
    }
    if (message.timeZone !== undefined) {
      writer.uint32(74).string(message.timeZone);
    }
    if (message.lifecycle !== undefined) {
      writer.uint32(80).int32(modem_LifecycleToNumber(message.lifecycle));
    }
    if (message.transmissionInterval !== undefined) {
      Duration.encode(message.transmissionInterval, writer.uint32(90).fork()).ldelim();
    }
    if (message.expectedTransmissionRate !== undefined) {
      Value_Numeric_Rate.encode(message.expectedTransmissionRate, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDevice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selection = DeviceSelection.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.allowBulkRename = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.allowBulkNotes = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.secureNotes = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.allowBulkSecureNotes = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.peripherals = UpdateDevice_UpdatePeripherals.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.lifecycle = modem_LifecycleFromJSON(reader.int32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.transmissionInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.expectedTransmissionRate = Value_Numeric_Rate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateDevice>): UpdateDevice {
    return UpdateDevice.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDevice>): UpdateDevice {
    const message = createBaseUpdateDevice();
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? DeviceSelection.fromPartial(object.selection)
      : undefined;
    message.name = object.name ?? undefined;
    message.allowBulkRename = object.allowBulkRename ?? undefined;
    message.notes = object.notes ?? undefined;
    message.allowBulkNotes = object.allowBulkNotes ?? undefined;
    message.secureNotes = object.secureNotes ?? undefined;
    message.allowBulkSecureNotes = object.allowBulkSecureNotes ?? undefined;
    message.peripherals = (object.peripherals !== undefined && object.peripherals !== null)
      ? UpdateDevice_UpdatePeripherals.fromPartial(object.peripherals)
      : undefined;
    message.timeZone = object.timeZone ?? undefined;
    message.lifecycle = object.lifecycle ?? undefined;
    message.transmissionInterval = (object.transmissionInterval !== undefined && object.transmissionInterval !== null)
      ? Duration.fromPartial(object.transmissionInterval)
      : undefined;
    message.expectedTransmissionRate =
      (object.expectedTransmissionRate !== undefined && object.expectedTransmissionRate !== null)
        ? Value_Numeric_Rate.fromPartial(object.expectedTransmissionRate)
        : undefined;
    return message;
  },
};

function createBaseUpdateDevice_UpdatePeripherals(): UpdateDevice_UpdatePeripherals {
  return { replace: undefined };
}

export const UpdateDevice_UpdatePeripherals = {
  encode(message: UpdateDevice_UpdatePeripherals, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.replace?.$case) {
      case "partialUpdate":
        UpdateDevice_UpdatePeripherals_Partial.encode(message.replace.partialUpdate, writer.uint32(10).fork()).ldelim();
        break;
      case "fullReplace":
        UpdateDevice_UpdatePeripherals_Replace.encode(message.replace.fullReplace, writer.uint32(18).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDevice_UpdatePeripherals {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDevice_UpdatePeripherals();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.replace = {
            $case: "partialUpdate",
            partialUpdate: UpdateDevice_UpdatePeripherals_Partial.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.replace = {
            $case: "fullReplace",
            fullReplace: UpdateDevice_UpdatePeripherals_Replace.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateDevice_UpdatePeripherals>): UpdateDevice_UpdatePeripherals {
    return UpdateDevice_UpdatePeripherals.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDevice_UpdatePeripherals>): UpdateDevice_UpdatePeripherals {
    const message = createBaseUpdateDevice_UpdatePeripherals();
    if (
      object.replace?.$case === "partialUpdate" &&
      object.replace?.partialUpdate !== undefined &&
      object.replace?.partialUpdate !== null
    ) {
      message.replace = {
        $case: "partialUpdate",
        partialUpdate: UpdateDevice_UpdatePeripherals_Partial.fromPartial(object.replace.partialUpdate),
      };
    }
    if (
      object.replace?.$case === "fullReplace" &&
      object.replace?.fullReplace !== undefined &&
      object.replace?.fullReplace !== null
    ) {
      message.replace = {
        $case: "fullReplace",
        fullReplace: UpdateDevice_UpdatePeripherals_Replace.fromPartial(object.replace.fullReplace),
      };
    }
    return message;
  },
};

function createBaseUpdateDevice_UpdatePeripherals_Partial(): UpdateDevice_UpdatePeripherals_Partial {
  return { removePeripherals: [], addPeripherals: {} };
}

export const UpdateDevice_UpdatePeripherals_Partial = {
  encode(message: UpdateDevice_UpdatePeripherals_Partial, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.removePeripherals) {
      writer.uint32(10).string(v!);
    }
    Object.entries(message.addPeripherals).forEach(([key, value]) => {
      UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDevice_UpdatePeripherals_Partial {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDevice_UpdatePeripherals_Partial();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.removePeripherals.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.addPeripherals[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateDevice_UpdatePeripherals_Partial>): UpdateDevice_UpdatePeripherals_Partial {
    return UpdateDevice_UpdatePeripherals_Partial.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDevice_UpdatePeripherals_Partial>): UpdateDevice_UpdatePeripherals_Partial {
    const message = createBaseUpdateDevice_UpdatePeripherals_Partial();
    message.removePeripherals = object.removePeripherals?.map((e) => e) || [];
    message.addPeripherals = Object.entries(object.addPeripherals ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry(): UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry {
  return { key: "", value: "" };
}

export const UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry = {
  encode(
    message: UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry>,
  ): UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry {
    return UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry>,
  ): UpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry {
    const message = createBaseUpdateDevice_UpdatePeripherals_Partial_AddPeripheralsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUpdateDevice_UpdatePeripherals_Replace(): UpdateDevice_UpdatePeripherals_Replace {
  return { replacePeripherals: {} };
}

export const UpdateDevice_UpdatePeripherals_Replace = {
  encode(message: UpdateDevice_UpdatePeripherals_Replace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.replacePeripherals).forEach(([key, value]) => {
      UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDevice_UpdatePeripherals_Replace {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDevice_UpdatePeripherals_Replace();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.replacePeripherals[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateDevice_UpdatePeripherals_Replace>): UpdateDevice_UpdatePeripherals_Replace {
    return UpdateDevice_UpdatePeripherals_Replace.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDevice_UpdatePeripherals_Replace>): UpdateDevice_UpdatePeripherals_Replace {
    const message = createBaseUpdateDevice_UpdatePeripherals_Replace();
    message.replacePeripherals = Object.entries(object.replacePeripherals ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry(): UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry {
  return { key: "", value: "" };
}

export const UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry = {
  encode(
    message: UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry>,
  ): UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry {
    return UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry>,
  ): UpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry {
    const message = createBaseUpdateDevice_UpdatePeripherals_Replace_ReplacePeripheralsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUpdateDevice_Request(): UpdateDevice_Request {
  return { organization: undefined, devices: [] };
}

export const UpdateDevice_Request = {
  encode(message: UpdateDevice_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.devices) {
      UpdateDevice.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDevice_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDevice_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.devices.push(UpdateDevice.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateDevice_Request>): UpdateDevice_Request {
    return UpdateDevice_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDevice_Request>): UpdateDevice_Request {
    const message = createBaseUpdateDevice_Request();
    message.organization = object.organization ?? undefined;
    message.devices = object.devices?.map((e) => UpdateDevice.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateDevice_Response(): UpdateDevice_Response {
  return { devices: [], request: undefined };
}

export const UpdateDevice_Response = {
  encode(message: UpdateDevice_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.devices) {
      Device.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UpdateDevice_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDevice_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDevice_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.devices.push(Device.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = UpdateDevice_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateDevice_Response>): UpdateDevice_Response {
    return UpdateDevice_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDevice_Response>): UpdateDevice_Response {
    const message = createBaseUpdateDevice_Response();
    message.devices = object.devices?.map((e) => Device.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? UpdateDevice_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseListDevice(): ListDevice {
  return {};
}

export const ListDevice = {
  encode(_: ListDevice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDevice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListDevice>): ListDevice {
    return ListDevice.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListDevice>): ListDevice {
    const message = createBaseListDevice();
    return message;
  },
};

function createBaseListDevice_Request(): ListDevice_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    sortBy: [],
    locationSelection: undefined,
    includeMissingGateways: undefined,
  };
}

export const ListDevice_Request = {
  encode(message: ListDevice_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      DeviceSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sortBy) {
      writer.int32(sortToNumber(v));
    }
    writer.ldelim();
    if (message.locationSelection !== undefined) {
      LocationSelection.encode(message.locationSelection, writer.uint32(42).fork()).ldelim();
    }
    if (message.includeMissingGateways !== undefined) {
      writer.uint32(48).bool(message.includeMissingGateways);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDevice_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDevice_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = DeviceSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sortBy.push(sortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sortBy.push(sortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.locationSelection = LocationSelection.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.includeMissingGateways = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListDevice_Request>): ListDevice_Request {
    return ListDevice_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDevice_Request>): ListDevice_Request {
    const message = createBaseListDevice_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? DeviceSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sortBy = object.sortBy?.map((e) => e) || [];
    message.locationSelection = (object.locationSelection !== undefined && object.locationSelection !== null)
      ? LocationSelection.fromPartial(object.locationSelection)
      : undefined;
    message.includeMissingGateways = object.includeMissingGateways ?? undefined;
    return message;
  },
};

function createBaseListDevice_Response(): ListDevice_Response {
  return { devices: [], request: undefined, pagination: undefined, sortedBy: [], gateways: [] };
}

export const ListDevice_Response = {
  encode(message: ListDevice_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.devices) {
      Device.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListDevice_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sortedBy) {
      writer.int32(sortToNumber(v));
    }
    writer.ldelim();
    for (const v of message.gateways) {
      Device.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDevice_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDevice_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.devices.push(Device.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListDevice_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sortedBy.push(sortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sortedBy.push(sortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.gateways.push(Device.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListDevice_Response>): ListDevice_Response {
    return ListDevice_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDevice_Response>): ListDevice_Response {
    const message = createBaseListDevice_Response();
    message.devices = object.devices?.map((e) => Device.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListDevice_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.sortedBy = object.sortedBy?.map((e) => e) || [];
    message.gateways = object.gateways?.map((e) => Device.fromPartial(e)) || [];
    return message;
  },
};

/**
 * At the core of the Hiber system, devices are the network nodes that send information and user data.
 * This service contains calls to list and manage devices.
 */
export type DeviceServiceDefinition = typeof DeviceServiceDefinition;
export const DeviceServiceDefinition = {
  name: "DeviceService",
  fullName: "hiber.device.DeviceService",
  methods: {
    /** List the devices in your organization, and, optionally, its child organizations. */
    list: {
      name: "List",
      requestType: ListDevice_Request,
      requestStream: false,
      responseType: ListDevice_Response,
      responseStream: false,
      options: {},
    },
    /** Update a device. */
    update: {
      name: "Update",
      requestType: UpdateDevice_Request,
      requestStream: false,
      responseType: UpdateDevice_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
