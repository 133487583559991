import {
  CreateTagRequest, DeleteTagRequest, ListTagsRequest, TagServiceDefinition, UpdateTagRequest,
} from 'hiber-grpc/src/customer_compiled/tag';
import { getGrpcClient } from '@/services/app';

export function updateTag(args: { name: string; tagId: number; type: string }) {
  const { name, tagId, type } = args;
  const request = UpdateTagRequest.fromPartial({
    id: tagId,
    update: { name, type },
  });
  const client = getGrpcClient<typeof TagServiceDefinition>(TagServiceDefinition);
  return client.update(request);
}

export function createTag(args: { name: string; type: string }) {
  const { name, type } = args;
  const request = CreateTagRequest.fromPartial({ create: { name, type } });
  const client = getGrpcClient<typeof TagServiceDefinition>(TagServiceDefinition);
  return client.create(request);
}

export function removeTag(args: { tagId: number; }) {
  const { tagId } = args;
  const request = DeleteTagRequest.fromPartial({ id: tagId });
  const client = getGrpcClient<typeof TagServiceDefinition>(TagServiceDefinition);
  return client.delete(request);
}

export function fetchTags() {
  const request = ListTagsRequest.fromPartial({ modemCount: true });
  const client = getGrpcClient<typeof TagServiceDefinition>(TagServiceDefinition);
  return client.list(request);
}
