import {
  ListModemMessageBodyParsersRequest,
  ModemMessageBodyParserServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/modem_message_body_parser';
import { getGrpcClient } from '@/services/app';

export function fetchParsers(pagination = { size: 50, page: 0 }) { // eslint-disable-line import/prefer-default-export
  const request = ListModemMessageBodyParsersRequest.fromPartial({ pagination });
  const client = getGrpcClient<typeof ModemMessageBodyParserServiceDefinition>(ModemMessageBodyParserServiceDefinition);
  return client.list(request);
}
