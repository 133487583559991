/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'analytics': {
    width: 18,
    height: 16,
    viewBox: '0 0 16 18',
    data: '<path pid="0" d="M2.5 11h-2c-.277 0-.5.223-.5.5v4c0 .277.223.5.5.5h2c.277 0 .5-.223.5-.5v-4c0-.277-.223-.5-.5-.5zM2 15H1v-3h1zm15.5-9h-2c-.277 0-.5.223-.5.5v9c0 .277.223.5.5.5h2c.277 0 .5-.223.5-.5v-9c0-.277-.223-.5-.5-.5zm-.5 9h-1V7h1zM15.71 2.77c.231.144.5.23.79.23A1.5 1.5 0 1015 1.5c0 .172.035.336.09.488l-2.8 2.242A1.48 1.48 0 0011.5 4c-.29 0-.559.086-.79.23l-2.8-2.242c.055-.152.09-.316.09-.488a1.5 1.5 0 00-3 0c0 .23.055.45.148.645L2.145 5.148A1.488 1.488 0 001.5 5 1.5 1.5 0 103 6.5c0-.23-.055-.45-.148-.645l3.003-3.003A1.48 1.48 0 007.29 2.77l2.8 2.242c-.054.152-.089.316-.089.488a1.5 1.5 0 003 0c0-.172-.035-.336-.09-.488zM16.5 1c.277 0 .5.223.5.5s-.223.5-.5.5a.498.498 0 01-.5-.5c0-.277.223-.5.5-.5zm-15 6a.498.498 0 01-.5-.5c0-.277.223-.5.5-.5s.5.223.5.5-.223.5-.5.5zm5-5a.498.498 0 01-.5-.5c0-.277.223-.5.5-.5s.5.223.5.5-.223.5-.5.5zm5 4a.498.498 0 01-.5-.5c0-.277.223-.5.5-.5s.5.223.5.5-.223.5-.5.5zm-4 0h-2c-.277 0-.5.223-.5.5v9c0 .277.223.5.5.5h2c.277 0 .5-.223.5-.5v-9c0-.277-.223-.5-.5-.5zM7 15H6V7h1zm5.5-5h-2c-.277 0-.5.223-.5.5v5c0 .277.223.5.5.5h2c.277 0 .5-.223.5-.5v-5c0-.277-.223-.5-.5-.5zm-.5 5h-1v-4h1zm0 0"/>'
  }
})
