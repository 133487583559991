/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-right': {
    width: 5,
    height: 11,
    viewBox: '0 0 5 11',
    data: '<path pid="0" d="M.325 10.903c.108.065.25.097.393.097a.771.771 0 00.607-.291l3.568-4.851a.645.645 0 000-.712L1.325.295C1.11.004.647-.093.325.101c-.32.194-.428.614-.214.906L3.43 5.502.11 9.997c-.214.291-.107.712.214.906z" _fill="#002d3c"/>'
  }
})
