import { defaultErrorHandler } from '@/store/core/utils';
import { fetchHistory } from '@/services/message';
import * as moment from 'moment';

const Message = {
  namespaced: true,
  state: {},
  actions: {
    fetchHistory(context, payload) {
      const { modems, dateRange, fields } = payload;
      return new Promise((resolve, reject) => {
        fetchHistory(
          modems,
          {
            start: moment(dateRange.start).startOf('day').toISOString(true),
            end: moment(dateRange.end).add(1, 'day').startOf('day').toISOString(true),
          },
          fields,
        ).then(res => {
          resolve(res);
        })
          .catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {},
  getters: {},
};

export default Message;
