import { defaultErrorHandler } from '@/store/core/utils';
import { createTag, fetchTags, removeTag, updateTag } from '@/services/tags';

function mapTagResponse(res) {
  return res.tags
    .map((tag) => ({
      tagId: tag.id,
      name: tag.label.name,
      type: tag.label.type,
      amountOfAssets: res.tagModemCount[tag.id],
    }));
}

const Tags = {
  namespaced: true,
  state: {
    tags: [],
  },
  actions: {
    getTags(context) {
      return new Promise((resolve, reject) => {
        fetchTags().then((res) => {
          const tags = mapTagResponse(res);

          context.commit('setTags', tags);
          resolve(tags);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    createTag(context, payload) {
      return new Promise((resolve, reject) => {
        createTag({ name: payload.tagName, type: payload.tagType })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => defaultErrorHandler(err, reject, context));
      });
    },
    updateTag(context, payload) {
      return new Promise((resolve, reject) => {
        updateTag({ name: payload.name, tagId: payload.tagId, type: payload.tagType })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => defaultErrorHandler(err, reject, context));
      });
    },
    deleteTag(context, payload) {
      return new Promise((resolve, reject) => {
        const { tagIds } = payload;
        Promise.all(tagIds.map(tagId => removeTag({ tagId })))
          .then((res) => {
            resolve(res);
          })
          .catch((err) => defaultErrorHandler(err, reject, context));
      });
    },
  },
  mutations: {
    setTags(state, tags) {
      state.tags = tags;
    },
  },
  getters: {
    tags(state) {
      return state.tags;
    },
    tag(state) {
      return (id) => state.tags.find(tag => tag.tagId === id);
    },
  },
};

export default Tags;
