import { fetchFieldsForModems, listFields } from '@/services/field';
import { defaultErrorHandler } from '@/store/core/utils';

const Field = {
  namespaced: true,
  state: {},

  actions: {
    fetchFieldList(context) {
      return new Promise((resolve, reject) => {
        listFields().then((response) => resolve(response))
          .catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    fetchFieldsForModems(context, payload) {
      const { modems, applyUnitPreferences } = payload;

      return new Promise((resolve, reject) => {
        fetchFieldsForModems(modems, applyUnitPreferences).then((response) => resolve(response))
          .catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
};
export default Field;
