// A-Z all the way down
import routesList from '@/router/routesList';

const routeParser = (importedJson) => importedJson.map((route => {
  const paramsList = route.params ? Object.keys(route.params).map(p => `:${p}?/`) : '';

  let routePath = route.path;

  if (paramsList.length) {
    routePath = `${route.path}/${paramsList.join('')}`;
  }

  let routeComponent = route.name;

  if (route.component) {
    routeComponent = route.component;
  }

  const children = route.children?.length && routeParser(route.children);
  return {
    ...route,
    path: routePath,
    meta: {
      requiresAuth: true,
      ...route.meta,
    },
    query: {
      ...route.query,
    },
    children,
    // template literals don't resolve correctly
    // eslint-disable-next-line prefer-template
    component: () => import('@/pages/' + routeComponent + '.vue'),
  };
}));

const routesComposed = routeParser(routesList);

export default [
  ...routesComposed,
];
