/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { UnitOfMeasurement, unitOfMeasurementFromJSON, unitOfMeasurementToNumber } from "./base";
import { Duration } from "./google/protobuf/duration";

export const protobufPackage = "hiber.value";

/**
 * Get an aggregated value for the selected data (e.g. average).
 * Text fields can only use the LAST aggregation.
 * Enum fields support a subset of aggregations:
 *   - DEFAULT and LAST return the last value;
 *   - MINIMUM and MAXIMUM return the lowest or highest value (respectively) based on the enum value order;
 *   - AVERAGE and SUM are not supported.
 */
export enum ValueAggregation {
  DEFAULT = "DEFAULT",
  /**
   * AVERAGE - Return the average value.
   * Not supported for textual and enum fields. When used with these fields, LAST is used instead.
   */
  AVERAGE = "AVERAGE",
  /**
   * SUM - Return the sum all values.
   * Not supported for textual and enum fields. When used with these fields, LAST is used instead.
   */
  SUM = "SUM",
  /** LAST - Just take the last value. */
  LAST = "LAST",
  /**
   * MINIMUM - Return the lowest value.
   * For enum fields, the order of values is used to determine the MINIMUM.
   * Not supported for textual fields. When used with these fields, LAST is used instead.
   */
  MINIMUM = "MINIMUM",
  /**
   * MAXIMUM - Return the highest value.
   * For enum fields, the order of values is used to determine the MAXIMUM.
   * Not supported for textual fields. When used with these fields, LAST is used instead.
   */
  MAXIMUM = "MAXIMUM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function valueAggregationFromJSON(object: any): ValueAggregation {
  switch (object) {
    case 0:
    case "DEFAULT":
      return ValueAggregation.DEFAULT;
    case 1:
    case "AVERAGE":
      return ValueAggregation.AVERAGE;
    case 2:
    case "SUM":
      return ValueAggregation.SUM;
    case 3:
    case "LAST":
      return ValueAggregation.LAST;
    case 4:
    case "MINIMUM":
      return ValueAggregation.MINIMUM;
    case 5:
    case "MAXIMUM":
      return ValueAggregation.MAXIMUM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ValueAggregation.UNRECOGNIZED;
  }
}

export function valueAggregationToNumber(object: ValueAggregation): number {
  switch (object) {
    case ValueAggregation.DEFAULT:
      return 0;
    case ValueAggregation.AVERAGE:
      return 1;
    case ValueAggregation.SUM:
      return 2;
    case ValueAggregation.LAST:
      return 3;
    case ValueAggregation.MINIMUM:
      return 4;
    case ValueAggregation.MAXIMUM:
      return 5;
    case ValueAggregation.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Transform the values into a derived value. */
export enum ValueTransformation {
  /**
   * DURATION - Instead of returning the value, return the amount of time a value was active.
   * Aggregation (if applicable) is applied afterwards on the duration value.
   */
  DURATION = "DURATION",
  /**
   * DELTA - Instead of returning the value, return the difference between the value and the previous value.
   * Aggregation (if applicable) is applied before the delta is calculated.
   */
  DELTA = "DELTA",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function valueTransformationFromJSON(object: any): ValueTransformation {
  switch (object) {
    case 0:
    case "DURATION":
      return ValueTransformation.DURATION;
    case 1:
    case "DELTA":
      return ValueTransformation.DELTA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ValueTransformation.UNRECOGNIZED;
  }
}

export function valueTransformationToNumber(object: ValueTransformation): number {
  switch (object) {
    case ValueTransformation.DURATION:
      return 0;
    case ValueTransformation.DELTA:
      return 1;
    case ValueTransformation.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Value {
  type: Value_Type;
  value?: { $case: "numeric"; numeric: Value_Numeric } | { $case: "text"; text: string } | {
    $case: "enum";
    enum: Value_Enum;
  } | undefined;
}

/** The type of value that is represented. */
export enum Value_Type {
  OTHER = "OTHER",
  /** NUMERIC - This field contains numeric values, with an optional unit of measurement defined below. */
  NUMERIC = "NUMERIC",
  /** TEXT - This field contains text to be displayed. */
  TEXT = "TEXT",
  /** ENUM - This field switches between several predefined values. Typically used for status fields. */
  ENUM = "ENUM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_TypeFromJSON(object: any): Value_Type {
  switch (object) {
    case 0:
    case "OTHER":
      return Value_Type.OTHER;
    case 1:
    case "NUMERIC":
      return Value_Type.NUMERIC;
    case 2:
    case "TEXT":
      return Value_Type.TEXT;
    case 3:
    case "ENUM":
      return Value_Type.ENUM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Type.UNRECOGNIZED;
  }
}

export function value_TypeToNumber(object: Value_Type): number {
  switch (object) {
    case Value_Type.OTHER:
      return 0;
    case Value_Type.NUMERIC:
      return 1;
    case Value_Type.TEXT:
      return 2;
    case Value_Type.ENUM:
      return 3;
    case Value_Type.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** If the value is numeric, this specifies the unit, value, etc. */
export interface Value_Numeric {
  type: Value_Numeric_Type;
  typedValue?:
    | { $case: "batteryLevel"; batteryLevel: Value_Numeric_BatteryLevel }
    | { $case: "distance"; distance: Value_Numeric_Distance }
    | { $case: "duration"; duration: Value_Numeric_Duration }
    | { $case: "fuelEfficiency"; fuelEfficiency: Value_Numeric_FuelEfficiency }
    | { $case: "percentage"; percentage: Value_Numeric_Percentage }
    | { $case: "pressure"; pressure: Value_Numeric_Pressure }
    | { $case: "speed"; speed: Value_Numeric_Speed }
    | { $case: "temperature"; temperature: Value_Numeric_Temperature }
    | { $case: "voltage"; voltage: Value_Numeric_Voltage }
    | { $case: "volume"; volume: Value_Numeric_Volume }
    | { $case: "mass"; mass: Value_Numeric_Mass }
    | { $case: "flow"; flow: Value_Numeric_Flow }
    | { $case: "rotationSpeed"; rotationSpeed: Value_Numeric_RotationSpeed }
    | { $case: "rate"; rate: Value_Numeric_Rate }
    | { $case: "unknown"; unknown: number }
    | undefined;
  value: number;
  /** Textual representation including unit symbol, rounded based on the user preferences and field config. */
  textual: string;
  /** Unit of the value, based on the user preferences. */
  unit: UnitOfMeasurement;
  /** Display string of the unit symbol, based on the unit of the value (which is based on user preferences). */
  unitSymbol: string;
  /** The original unit, iff this value was converted from another unit because of user preferences. */
  convertedFrom?: UnitOfMeasurement | undefined;
}

/**
 * The type of numeric value that is represented.
 * Supported types will automatically convert to the preferred unit (based on the user settings).
 */
export enum Value_Numeric_Type {
  TYPE_UNKNOWN = "TYPE_UNKNOWN",
  PERCENTAGE = "PERCENTAGE",
  TEMPERATURE = "TEMPERATURE",
  DISTANCE = "DISTANCE",
  PRESSURE = "PRESSURE",
  VOLTAGE = "VOLTAGE",
  SPEED = "SPEED",
  VOLUME = "VOLUME",
  DURATION = "DURATION",
  FUEL_EFFICIENCY = "FUEL_EFFICIENCY",
  MASS = "MASS",
  BATTERY_LEVEL = "BATTERY_LEVEL",
  FLOW = "FLOW",
  ROTATION_SPEED = "ROTATION_SPEED",
  RATE = "RATE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_TypeFromJSON(object: any): Value_Numeric_Type {
  switch (object) {
    case 0:
    case "TYPE_UNKNOWN":
      return Value_Numeric_Type.TYPE_UNKNOWN;
    case 1:
    case "PERCENTAGE":
      return Value_Numeric_Type.PERCENTAGE;
    case 2:
    case "TEMPERATURE":
      return Value_Numeric_Type.TEMPERATURE;
    case 3:
    case "DISTANCE":
      return Value_Numeric_Type.DISTANCE;
    case 4:
    case "PRESSURE":
      return Value_Numeric_Type.PRESSURE;
    case 5:
    case "VOLTAGE":
      return Value_Numeric_Type.VOLTAGE;
    case 6:
    case "SPEED":
      return Value_Numeric_Type.SPEED;
    case 7:
    case "VOLUME":
      return Value_Numeric_Type.VOLUME;
    case 8:
    case "DURATION":
      return Value_Numeric_Type.DURATION;
    case 9:
    case "FUEL_EFFICIENCY":
      return Value_Numeric_Type.FUEL_EFFICIENCY;
    case 10:
    case "MASS":
      return Value_Numeric_Type.MASS;
    case 11:
    case "BATTERY_LEVEL":
      return Value_Numeric_Type.BATTERY_LEVEL;
    case 12:
    case "FLOW":
      return Value_Numeric_Type.FLOW;
    case 13:
    case "ROTATION_SPEED":
      return Value_Numeric_Type.ROTATION_SPEED;
    case 14:
    case "RATE":
      return Value_Numeric_Type.RATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Type.UNRECOGNIZED;
  }
}

export function value_Numeric_TypeToNumber(object: Value_Numeric_Type): number {
  switch (object) {
    case Value_Numeric_Type.TYPE_UNKNOWN:
      return 0;
    case Value_Numeric_Type.PERCENTAGE:
      return 1;
    case Value_Numeric_Type.TEMPERATURE:
      return 2;
    case Value_Numeric_Type.DISTANCE:
      return 3;
    case Value_Numeric_Type.PRESSURE:
      return 4;
    case Value_Numeric_Type.VOLTAGE:
      return 5;
    case Value_Numeric_Type.SPEED:
      return 6;
    case Value_Numeric_Type.VOLUME:
      return 7;
    case Value_Numeric_Type.DURATION:
      return 8;
    case Value_Numeric_Type.FUEL_EFFICIENCY:
      return 9;
    case Value_Numeric_Type.MASS:
      return 10;
    case Value_Numeric_Type.BATTERY_LEVEL:
      return 11;
    case Value_Numeric_Type.FLOW:
      return 12;
    case Value_Numeric_Type.ROTATION_SPEED:
      return 13;
    case Value_Numeric_Type.RATE:
      return 14;
    case Value_Numeric_Type.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * Special case for battery level, since it can be provided in many units.
 * Not included in the UnitPreferences, since it cannot be converted.
 */
export interface Value_Numeric_BatteryLevel {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_BatteryLevel_BatteryLevelUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_BatteryLevel_BatteryLevelUnit | undefined;
}

/** Convenience type for battery level. Other units may be added here later, like voltage. */
export enum Value_Numeric_BatteryLevel_BatteryLevelUnit {
  /** PERCENT - Battery level as a percentage (technically not a unit). */
  PERCENT = "PERCENT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_BatteryLevel_BatteryLevelUnitFromJSON(
  object: any,
): Value_Numeric_BatteryLevel_BatteryLevelUnit {
  switch (object) {
    case 0:
    case "PERCENT":
      return Value_Numeric_BatteryLevel_BatteryLevelUnit.PERCENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_BatteryLevel_BatteryLevelUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_BatteryLevel_BatteryLevelUnitToNumber(
  object: Value_Numeric_BatteryLevel_BatteryLevelUnit,
): number {
  switch (object) {
    case Value_Numeric_BatteryLevel_BatteryLevelUnit.PERCENT:
      return 0;
    case Value_Numeric_BatteryLevel_BatteryLevelUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * The value for rotation speed. The only value is revolutions per minute (RPM), therefore it is not included in
 * unit preferences.
 */
export interface Value_Numeric_RotationSpeed {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_RotationSpeed_RotationSpeedUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_RotationSpeed_RotationSpeedUnit | undefined;
}

export enum Value_Numeric_RotationSpeed_RotationSpeedUnit {
  /** REVOLUTIONS_PER_MINUTE - rpm */
  REVOLUTIONS_PER_MINUTE = "REVOLUTIONS_PER_MINUTE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_RotationSpeed_RotationSpeedUnitFromJSON(
  object: any,
): Value_Numeric_RotationSpeed_RotationSpeedUnit {
  switch (object) {
    case 0:
    case "REVOLUTIONS_PER_MINUTE":
      return Value_Numeric_RotationSpeed_RotationSpeedUnit.REVOLUTIONS_PER_MINUTE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_RotationSpeed_RotationSpeedUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_RotationSpeed_RotationSpeedUnitToNumber(
  object: Value_Numeric_RotationSpeed_RotationSpeedUnit,
): number {
  switch (object) {
    case Value_Numeric_RotationSpeed_RotationSpeedUnit.REVOLUTIONS_PER_MINUTE:
      return 0;
    case Value_Numeric_RotationSpeed_RotationSpeedUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a distance value, converted to your preferred distance unit. */
export interface Value_Numeric_Distance {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Distance_DistanceUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Distance_DistanceUnit | undefined;
}

/** Unit of distance */
export enum Value_Numeric_Distance_DistanceUnit {
  /** METER - m */
  METER = "METER",
  /** MILLIMETER - mm */
  MILLIMETER = "MILLIMETER",
  /** CENTIMETER - cm */
  CENTIMETER = "CENTIMETER",
  /** KILOMETER - km */
  KILOMETER = "KILOMETER",
  /** YARD - yd */
  YARD = "YARD",
  /** MILE - mi */
  MILE = "MILE",
  /** FOOT - ' */
  FOOT = "FOOT",
  /** INCH - ″ */
  INCH = "INCH",
  /** NAUTICAL_MILE - NM. This is a special case unit and may not be auto-converted to your UnitPreference. */
  NAUTICAL_MILE = "NAUTICAL_MILE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Distance_DistanceUnitFromJSON(object: any): Value_Numeric_Distance_DistanceUnit {
  switch (object) {
    case 0:
    case "METER":
      return Value_Numeric_Distance_DistanceUnit.METER;
    case 1:
    case "MILLIMETER":
      return Value_Numeric_Distance_DistanceUnit.MILLIMETER;
    case 2:
    case "CENTIMETER":
      return Value_Numeric_Distance_DistanceUnit.CENTIMETER;
    case 3:
    case "KILOMETER":
      return Value_Numeric_Distance_DistanceUnit.KILOMETER;
    case 5:
    case "YARD":
      return Value_Numeric_Distance_DistanceUnit.YARD;
    case 4:
    case "MILE":
      return Value_Numeric_Distance_DistanceUnit.MILE;
    case 6:
    case "FOOT":
      return Value_Numeric_Distance_DistanceUnit.FOOT;
    case 7:
    case "INCH":
      return Value_Numeric_Distance_DistanceUnit.INCH;
    case 8:
    case "NAUTICAL_MILE":
      return Value_Numeric_Distance_DistanceUnit.NAUTICAL_MILE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Distance_DistanceUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Distance_DistanceUnitToNumber(object: Value_Numeric_Distance_DistanceUnit): number {
  switch (object) {
    case Value_Numeric_Distance_DistanceUnit.METER:
      return 0;
    case Value_Numeric_Distance_DistanceUnit.MILLIMETER:
      return 1;
    case Value_Numeric_Distance_DistanceUnit.CENTIMETER:
      return 2;
    case Value_Numeric_Distance_DistanceUnit.KILOMETER:
      return 3;
    case Value_Numeric_Distance_DistanceUnit.YARD:
      return 5;
    case Value_Numeric_Distance_DistanceUnit.MILE:
      return 4;
    case Value_Numeric_Distance_DistanceUnit.FOOT:
      return 6;
    case Value_Numeric_Distance_DistanceUnit.INCH:
      return 7;
    case Value_Numeric_Distance_DistanceUnit.NAUTICAL_MILE:
      return 8;
    case Value_Numeric_Distance_DistanceUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a distance value, converted to your preferred distance unit. */
export interface Value_Numeric_FuelEfficiency {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_FuelEfficiency_FuelEfficiencyUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_FuelEfficiency_FuelEfficiencyUnit | undefined;
}

/** Unit of fuel efficiency */
export enum Value_Numeric_FuelEfficiency_FuelEfficiencyUnit {
  /** LITER_PER_100_KILOMETER - l/100km */
  LITER_PER_100_KILOMETER = "LITER_PER_100_KILOMETER",
  /** KILOMETER_PER_LITER - km/l */
  KILOMETER_PER_LITER = "KILOMETER_PER_LITER",
  /** KILOMETER_PER_GALLON - km/gal (US) */
  KILOMETER_PER_GALLON = "KILOMETER_PER_GALLON",
  /** KILOMETER_PER_IMPERIAL_GALLON - km/gal (imp) */
  KILOMETER_PER_IMPERIAL_GALLON = "KILOMETER_PER_IMPERIAL_GALLON",
  /** MILES_PER_GALLON - mpg (US) */
  MILES_PER_GALLON = "MILES_PER_GALLON",
  /** MILES_PER_IMPERIAL_GALLON - mpg (imp) */
  MILES_PER_IMPERIAL_GALLON = "MILES_PER_IMPERIAL_GALLON",
  /** MILES_PER_LITER - mpl */
  MILES_PER_LITER = "MILES_PER_LITER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_FuelEfficiency_FuelEfficiencyUnitFromJSON(
  object: any,
): Value_Numeric_FuelEfficiency_FuelEfficiencyUnit {
  switch (object) {
    case 0:
    case "LITER_PER_100_KILOMETER":
      return Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.LITER_PER_100_KILOMETER;
    case 1:
    case "KILOMETER_PER_LITER":
      return Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.KILOMETER_PER_LITER;
    case 2:
    case "KILOMETER_PER_GALLON":
      return Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.KILOMETER_PER_GALLON;
    case 3:
    case "KILOMETER_PER_IMPERIAL_GALLON":
      return Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.KILOMETER_PER_IMPERIAL_GALLON;
    case 4:
    case "MILES_PER_GALLON":
      return Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.MILES_PER_GALLON;
    case 5:
    case "MILES_PER_IMPERIAL_GALLON":
      return Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.MILES_PER_IMPERIAL_GALLON;
    case 6:
    case "MILES_PER_LITER":
      return Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.MILES_PER_LITER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_FuelEfficiency_FuelEfficiencyUnitToNumber(
  object: Value_Numeric_FuelEfficiency_FuelEfficiencyUnit,
): number {
  switch (object) {
    case Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.LITER_PER_100_KILOMETER:
      return 0;
    case Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.KILOMETER_PER_LITER:
      return 1;
    case Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.KILOMETER_PER_GALLON:
      return 2;
    case Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.KILOMETER_PER_IMPERIAL_GALLON:
      return 3;
    case Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.MILES_PER_GALLON:
      return 4;
    case Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.MILES_PER_IMPERIAL_GALLON:
      return 5;
    case Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.MILES_PER_LITER:
      return 6;
    case Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a percentage. */
export interface Value_Numeric_Percentage {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Percentage_PercentageUnit;
  /** Textual representation with % symbol, rounded based on the user preferences and field config. */
  textual: string;
}

/** Convenience type for percentage. Technically not a unit, but for consistency, we've added it here. */
export enum Value_Numeric_Percentage_PercentageUnit {
  /** PERCENT - % */
  PERCENT = "PERCENT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Percentage_PercentageUnitFromJSON(object: any): Value_Numeric_Percentage_PercentageUnit {
  switch (object) {
    case 0:
    case "PERCENT":
      return Value_Numeric_Percentage_PercentageUnit.PERCENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Percentage_PercentageUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Percentage_PercentageUnitToNumber(
  object: Value_Numeric_Percentage_PercentageUnit,
): number {
  switch (object) {
    case Value_Numeric_Percentage_PercentageUnit.PERCENT:
      return 0;
    case Value_Numeric_Percentage_PercentageUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a pressure value, converted to your preferred pressure unit. */
export interface Value_Numeric_Pressure {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Pressure_PressureUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Pressure_PressureUnit | undefined;
}

/** Unit of pressure. */
export enum Value_Numeric_Pressure_PressureUnit {
  /** BAR - bar */
  BAR = "BAR",
  /** PSI - psi */
  PSI = "PSI",
  /** K_PA - kPa */
  K_PA = "K_PA",
  /** KILOGRAM_PER_CENTIMETER_SQUARED - kg/cm² */
  KILOGRAM_PER_CENTIMETER_SQUARED = "KILOGRAM_PER_CENTIMETER_SQUARED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Pressure_PressureUnitFromJSON(object: any): Value_Numeric_Pressure_PressureUnit {
  switch (object) {
    case 0:
    case "BAR":
      return Value_Numeric_Pressure_PressureUnit.BAR;
    case 1:
    case "PSI":
      return Value_Numeric_Pressure_PressureUnit.PSI;
    case 2:
    case "K_PA":
      return Value_Numeric_Pressure_PressureUnit.K_PA;
    case 3:
    case "KILOGRAM_PER_CENTIMETER_SQUARED":
      return Value_Numeric_Pressure_PressureUnit.KILOGRAM_PER_CENTIMETER_SQUARED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Pressure_PressureUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Pressure_PressureUnitToNumber(object: Value_Numeric_Pressure_PressureUnit): number {
  switch (object) {
    case Value_Numeric_Pressure_PressureUnit.BAR:
      return 0;
    case Value_Numeric_Pressure_PressureUnit.PSI:
      return 1;
    case Value_Numeric_Pressure_PressureUnit.K_PA:
      return 2;
    case Value_Numeric_Pressure_PressureUnit.KILOGRAM_PER_CENTIMETER_SQUARED:
      return 3;
    case Value_Numeric_Pressure_PressureUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a speed value, converted to your preferred speed unit. */
export interface Value_Numeric_Speed {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Speed_SpeedUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Speed_SpeedUnit | undefined;
}

/** Unit of speed. */
export enum Value_Numeric_Speed_SpeedUnit {
  /** KILOMETERS_PER_HOUR - km/h */
  KILOMETERS_PER_HOUR = "KILOMETERS_PER_HOUR",
  /** KNOTS - kn. This is a special case unit and may not be auto-converted to your UnitPreference. */
  KNOTS = "KNOTS",
  /** METERS_PER_SECOND - m/s */
  METERS_PER_SECOND = "METERS_PER_SECOND",
  /** MILES_PER_HOUR - mph */
  MILES_PER_HOUR = "MILES_PER_HOUR",
  /** FEET_PER_SECOND - ft/s */
  FEET_PER_SECOND = "FEET_PER_SECOND",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Speed_SpeedUnitFromJSON(object: any): Value_Numeric_Speed_SpeedUnit {
  switch (object) {
    case 0:
    case "KILOMETERS_PER_HOUR":
      return Value_Numeric_Speed_SpeedUnit.KILOMETERS_PER_HOUR;
    case 1:
    case "KNOTS":
      return Value_Numeric_Speed_SpeedUnit.KNOTS;
    case 2:
    case "METERS_PER_SECOND":
      return Value_Numeric_Speed_SpeedUnit.METERS_PER_SECOND;
    case 3:
    case "MILES_PER_HOUR":
      return Value_Numeric_Speed_SpeedUnit.MILES_PER_HOUR;
    case 4:
    case "FEET_PER_SECOND":
      return Value_Numeric_Speed_SpeedUnit.FEET_PER_SECOND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Speed_SpeedUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Speed_SpeedUnitToNumber(object: Value_Numeric_Speed_SpeedUnit): number {
  switch (object) {
    case Value_Numeric_Speed_SpeedUnit.KILOMETERS_PER_HOUR:
      return 0;
    case Value_Numeric_Speed_SpeedUnit.KNOTS:
      return 1;
    case Value_Numeric_Speed_SpeedUnit.METERS_PER_SECOND:
      return 2;
    case Value_Numeric_Speed_SpeedUnit.MILES_PER_HOUR:
      return 3;
    case Value_Numeric_Speed_SpeedUnit.FEET_PER_SECOND:
      return 4;
    case Value_Numeric_Speed_SpeedUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a temperature, converted to your preferred temperature unit. */
export interface Value_Numeric_Temperature {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Temperature_TemperatureUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Temperature_TemperatureUnit | undefined;
}

/** Unit of temperature. */
export enum Value_Numeric_Temperature_TemperatureUnit {
  /** KELVIN - K */
  KELVIN = "KELVIN",
  /** DEGREES_CELSIUS - °C */
  DEGREES_CELSIUS = "DEGREES_CELSIUS",
  /** DEGREES_FAHRENHEIT - °F */
  DEGREES_FAHRENHEIT = "DEGREES_FAHRENHEIT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Temperature_TemperatureUnitFromJSON(
  object: any,
): Value_Numeric_Temperature_TemperatureUnit {
  switch (object) {
    case 0:
    case "KELVIN":
      return Value_Numeric_Temperature_TemperatureUnit.KELVIN;
    case 1:
    case "DEGREES_CELSIUS":
      return Value_Numeric_Temperature_TemperatureUnit.DEGREES_CELSIUS;
    case 2:
    case "DEGREES_FAHRENHEIT":
      return Value_Numeric_Temperature_TemperatureUnit.DEGREES_FAHRENHEIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Temperature_TemperatureUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Temperature_TemperatureUnitToNumber(
  object: Value_Numeric_Temperature_TemperatureUnit,
): number {
  switch (object) {
    case Value_Numeric_Temperature_TemperatureUnit.KELVIN:
      return 0;
    case Value_Numeric_Temperature_TemperatureUnit.DEGREES_CELSIUS:
      return 1;
    case Value_Numeric_Temperature_TemperatureUnit.DEGREES_FAHRENHEIT:
      return 2;
    case Value_Numeric_Temperature_TemperatureUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a voltage, converted to your preferred voltage unit. */
export interface Value_Numeric_Voltage {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Voltage_VoltageUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Voltage_VoltageUnit | undefined;
}

/** Unit of voltage. */
export enum Value_Numeric_Voltage_VoltageUnit {
  /** MILLIVOLT - mV */
  MILLIVOLT = "MILLIVOLT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Voltage_VoltageUnitFromJSON(object: any): Value_Numeric_Voltage_VoltageUnit {
  switch (object) {
    case 0:
    case "MILLIVOLT":
      return Value_Numeric_Voltage_VoltageUnit.MILLIVOLT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Voltage_VoltageUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Voltage_VoltageUnitToNumber(object: Value_Numeric_Voltage_VoltageUnit): number {
  switch (object) {
    case Value_Numeric_Voltage_VoltageUnit.MILLIVOLT:
      return 0;
    case Value_Numeric_Voltage_VoltageUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a volume value, converted to your preferred volume unit. */
export interface Value_Numeric_Volume {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Volume_VolumeUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Volume_VolumeUnit | undefined;
}

/** Unit of volume. */
export enum Value_Numeric_Volume_VolumeUnit {
  /** LITER - l */
  LITER = "LITER",
  /** GALLON_US - gal (US) */
  GALLON_US = "GALLON_US",
  /** GALLON_IMPERIAL - gal (imp) */
  GALLON_IMPERIAL = "GALLON_IMPERIAL",
  /** CUBIC_METER - m³ */
  CUBIC_METER = "CUBIC_METER",
  /** CUBIC_FEET - ft³ */
  CUBIC_FEET = "CUBIC_FEET",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Volume_VolumeUnitFromJSON(object: any): Value_Numeric_Volume_VolumeUnit {
  switch (object) {
    case 0:
    case "LITER":
      return Value_Numeric_Volume_VolumeUnit.LITER;
    case 1:
    case "GALLON_US":
      return Value_Numeric_Volume_VolumeUnit.GALLON_US;
    case 2:
    case "GALLON_IMPERIAL":
      return Value_Numeric_Volume_VolumeUnit.GALLON_IMPERIAL;
    case 3:
    case "CUBIC_METER":
      return Value_Numeric_Volume_VolumeUnit.CUBIC_METER;
    case 4:
    case "CUBIC_FEET":
      return Value_Numeric_Volume_VolumeUnit.CUBIC_FEET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Volume_VolumeUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Volume_VolumeUnitToNumber(object: Value_Numeric_Volume_VolumeUnit): number {
  switch (object) {
    case Value_Numeric_Volume_VolumeUnit.LITER:
      return 0;
    case Value_Numeric_Volume_VolumeUnit.GALLON_US:
      return 1;
    case Value_Numeric_Volume_VolumeUnit.GALLON_IMPERIAL:
      return 2;
    case Value_Numeric_Volume_VolumeUnit.CUBIC_METER:
      return 3;
    case Value_Numeric_Volume_VolumeUnit.CUBIC_FEET:
      return 4;
    case Value_Numeric_Volume_VolumeUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** The value is a volume value, converted to your preferred volume unit. */
export interface Value_Numeric_Mass {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Mass_MassUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Mass_MassUnit | undefined;
}

/** Unit of mass. */
export enum Value_Numeric_Mass_MassUnit {
  /** KILOGRAMS - kg */
  KILOGRAMS = "KILOGRAMS",
  /** POUNDS - lb */
  POUNDS = "POUNDS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Mass_MassUnitFromJSON(object: any): Value_Numeric_Mass_MassUnit {
  switch (object) {
    case 0:
    case "KILOGRAMS":
      return Value_Numeric_Mass_MassUnit.KILOGRAMS;
    case 1:
    case "POUNDS":
      return Value_Numeric_Mass_MassUnit.POUNDS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Mass_MassUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Mass_MassUnitToNumber(object: Value_Numeric_Mass_MassUnit): number {
  switch (object) {
    case Value_Numeric_Mass_MassUnit.KILOGRAMS:
      return 0;
    case Value_Numeric_Mass_MassUnit.POUNDS:
      return 1;
    case Value_Numeric_Mass_MassUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Value_Numeric_Flow {
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Flow_FlowUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Flow_FlowUnit | undefined;
}

/** Unit of volume per time. */
export enum Value_Numeric_Flow_FlowUnit {
  /** CUBIC_METER_PER_HOUR - m³/h */
  CUBIC_METER_PER_HOUR = "CUBIC_METER_PER_HOUR",
  /** BARRELS_PER_DAY - bbl/d */
  BARRELS_PER_DAY = "BARRELS_PER_DAY",
  /** CUBIC_METER_PER_SECOND - m³/s */
  CUBIC_METER_PER_SECOND = "CUBIC_METER_PER_SECOND",
  /** CUBIC_FEET_PER_HOUR - ft³/h */
  CUBIC_FEET_PER_HOUR = "CUBIC_FEET_PER_HOUR",
  /** CUBIC_FEET_PER_SECOND - ft³/s */
  CUBIC_FEET_PER_SECOND = "CUBIC_FEET_PER_SECOND",
  /** LITER_PER_HOUR - l/h */
  LITER_PER_HOUR = "LITER_PER_HOUR",
  /** LITER_PER_SECOND - l/s */
  LITER_PER_SECOND = "LITER_PER_SECOND",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Flow_FlowUnitFromJSON(object: any): Value_Numeric_Flow_FlowUnit {
  switch (object) {
    case 0:
    case "CUBIC_METER_PER_HOUR":
      return Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_HOUR;
    case 1:
    case "BARRELS_PER_DAY":
      return Value_Numeric_Flow_FlowUnit.BARRELS_PER_DAY;
    case 2:
    case "CUBIC_METER_PER_SECOND":
      return Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_SECOND;
    case 3:
    case "CUBIC_FEET_PER_HOUR":
      return Value_Numeric_Flow_FlowUnit.CUBIC_FEET_PER_HOUR;
    case 4:
    case "CUBIC_FEET_PER_SECOND":
      return Value_Numeric_Flow_FlowUnit.CUBIC_FEET_PER_SECOND;
    case 5:
    case "LITER_PER_HOUR":
      return Value_Numeric_Flow_FlowUnit.LITER_PER_HOUR;
    case 6:
    case "LITER_PER_SECOND":
      return Value_Numeric_Flow_FlowUnit.LITER_PER_SECOND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Flow_FlowUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Flow_FlowUnitToNumber(object: Value_Numeric_Flow_FlowUnit): number {
  switch (object) {
    case Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_HOUR:
      return 0;
    case Value_Numeric_Flow_FlowUnit.BARRELS_PER_DAY:
      return 1;
    case Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_SECOND:
      return 2;
    case Value_Numeric_Flow_FlowUnit.CUBIC_FEET_PER_HOUR:
      return 3;
    case Value_Numeric_Flow_FlowUnit.CUBIC_FEET_PER_SECOND:
      return 4;
    case Value_Numeric_Flow_FlowUnit.LITER_PER_HOUR:
      return 5;
    case Value_Numeric_Flow_FlowUnit.LITER_PER_SECOND:
      return 6;
    case Value_Numeric_Flow_FlowUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Value_Numeric_Rate {
  value: number;
  unit: Value_Numeric_Rate_RateUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Rate_RateUnit | undefined;
}

export enum Value_Numeric_Rate_RateUnit {
  /** ITEMS_PER_24_HOURS - The amount of items counted in a 24 hour window. */
  ITEMS_PER_24_HOURS = "ITEMS_PER_24_HOURS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Rate_RateUnitFromJSON(object: any): Value_Numeric_Rate_RateUnit {
  switch (object) {
    case 0:
    case "ITEMS_PER_24_HOURS":
      return Value_Numeric_Rate_RateUnit.ITEMS_PER_24_HOURS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Rate_RateUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Rate_RateUnitToNumber(object: Value_Numeric_Rate_RateUnit): number {
  switch (object) {
    case Value_Numeric_Rate_RateUnit.ITEMS_PER_24_HOURS:
      return 0;
    case Value_Numeric_Rate_RateUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Value_Numeric_Duration {
  duration:
    | Duration
    | undefined;
  /**
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  value: number;
  unit: Value_Numeric_Duration_DurationUnit;
  /**
   * Textual representation including unit symbol, rounded based on the user preferences and field config.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  textual: string;
  /**
   * The original unit, iff this value was converted from another unit because of user preferences.
   * Deprecated in favor of the field in Value.Numeric.
   *
   * @deprecated
   */
  convertedFrom?: Value_Numeric_Duration_DurationUnit | undefined;
}

/** Unit of duration. */
export enum Value_Numeric_Duration_DurationUnit {
  /** MILLISECONDS - ms */
  MILLISECONDS = "MILLISECONDS",
  /** SECONDS - s */
  SECONDS = "SECONDS",
  /** MINUTES - min */
  MINUTES = "MINUTES",
  /** HOURS - h */
  HOURS = "HOURS",
  /** DAYS - d */
  DAYS = "DAYS",
  /** WEEKS - w */
  WEEKS = "WEEKS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function value_Numeric_Duration_DurationUnitFromJSON(object: any): Value_Numeric_Duration_DurationUnit {
  switch (object) {
    case 0:
    case "MILLISECONDS":
      return Value_Numeric_Duration_DurationUnit.MILLISECONDS;
    case 1:
    case "SECONDS":
      return Value_Numeric_Duration_DurationUnit.SECONDS;
    case 2:
    case "MINUTES":
      return Value_Numeric_Duration_DurationUnit.MINUTES;
    case 3:
    case "HOURS":
      return Value_Numeric_Duration_DurationUnit.HOURS;
    case 4:
    case "DAYS":
      return Value_Numeric_Duration_DurationUnit.DAYS;
    case 5:
    case "WEEKS":
      return Value_Numeric_Duration_DurationUnit.WEEKS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Value_Numeric_Duration_DurationUnit.UNRECOGNIZED;
  }
}

export function value_Numeric_Duration_DurationUnitToNumber(object: Value_Numeric_Duration_DurationUnit): number {
  switch (object) {
    case Value_Numeric_Duration_DurationUnit.MILLISECONDS:
      return 0;
    case Value_Numeric_Duration_DurationUnit.SECONDS:
      return 1;
    case Value_Numeric_Duration_DurationUnit.MINUTES:
      return 2;
    case Value_Numeric_Duration_DurationUnit.HOURS:
      return 3;
    case Value_Numeric_Duration_DurationUnit.DAYS:
      return 4;
    case Value_Numeric_Duration_DurationUnit.WEEKS:
      return 5;
    case Value_Numeric_Duration_DurationUnit.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** If this value is an enum, this specifies the value, display name and color for this enum value. */
export interface Value_Enum {
  /** The enum value. This might be a cryptic value, see the display_name and description for more information. */
  value: string;
  /** User-facing name for this value. */
  displayName: string;
  /** More details for this enum value. */
  description: string;
  /** (Optional) color for this enum value. */
  color: string;
  /** Priority of the value, typically used for ordering. */
  priority: number;
}

function createBaseValue(): Value {
  return { type: Value_Type.OTHER, value: undefined };
}

export const Value = {
  encode(message: Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== Value_Type.OTHER) {
      writer.uint32(8).int32(value_TypeToNumber(message.type));
    }
    switch (message.value?.$case) {
      case "numeric":
        Value_Numeric.encode(message.value.numeric, writer.uint32(18).fork()).ldelim();
        break;
      case "text":
        writer.uint32(26).string(message.value.text);
        break;
      case "enum":
        Value_Enum.encode(message.value.enum, writer.uint32(34).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = value_TypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = { $case: "numeric", numeric: Value_Numeric.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = { $case: "text", text: reader.string() };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = { $case: "enum", enum: Value_Enum.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value>): Value {
    return Value.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value>): Value {
    const message = createBaseValue();
    message.type = object.type ?? Value_Type.OTHER;
    if (object.value?.$case === "numeric" && object.value?.numeric !== undefined && object.value?.numeric !== null) {
      message.value = { $case: "numeric", numeric: Value_Numeric.fromPartial(object.value.numeric) };
    }
    if (object.value?.$case === "text" && object.value?.text !== undefined && object.value?.text !== null) {
      message.value = { $case: "text", text: object.value.text };
    }
    if (object.value?.$case === "enum" && object.value?.enum !== undefined && object.value?.enum !== null) {
      message.value = { $case: "enum", enum: Value_Enum.fromPartial(object.value.enum) };
    }
    return message;
  },
};

function createBaseValue_Numeric(): Value_Numeric {
  return {
    type: Value_Numeric_Type.TYPE_UNKNOWN,
    typedValue: undefined,
    value: 0,
    textual: "",
    unit: UnitOfMeasurement.UNIT_UNKNOWN,
    unitSymbol: "",
    convertedFrom: undefined,
  };
}

export const Value_Numeric = {
  encode(message: Value_Numeric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== Value_Numeric_Type.TYPE_UNKNOWN) {
      writer.uint32(8).int32(value_Numeric_TypeToNumber(message.type));
    }
    switch (message.typedValue?.$case) {
      case "batteryLevel":
        Value_Numeric_BatteryLevel.encode(message.typedValue.batteryLevel, writer.uint32(90).fork()).ldelim();
        break;
      case "distance":
        Value_Numeric_Distance.encode(message.typedValue.distance, writer.uint32(26).fork()).ldelim();
        break;
      case "duration":
        Value_Numeric_Duration.encode(message.typedValue.duration, writer.uint32(34).fork()).ldelim();
        break;
      case "fuelEfficiency":
        Value_Numeric_FuelEfficiency.encode(message.typedValue.fuelEfficiency, writer.uint32(114).fork()).ldelim();
        break;
      case "percentage":
        Value_Numeric_Percentage.encode(message.typedValue.percentage, writer.uint32(42).fork()).ldelim();
        break;
      case "pressure":
        Value_Numeric_Pressure.encode(message.typedValue.pressure, writer.uint32(50).fork()).ldelim();
        break;
      case "speed":
        Value_Numeric_Speed.encode(message.typedValue.speed, writer.uint32(58).fork()).ldelim();
        break;
      case "temperature":
        Value_Numeric_Temperature.encode(message.typedValue.temperature, writer.uint32(66).fork()).ldelim();
        break;
      case "voltage":
        Value_Numeric_Voltage.encode(message.typedValue.voltage, writer.uint32(74).fork()).ldelim();
        break;
      case "volume":
        Value_Numeric_Volume.encode(message.typedValue.volume, writer.uint32(82).fork()).ldelim();
        break;
      case "mass":
        Value_Numeric_Mass.encode(message.typedValue.mass, writer.uint32(98).fork()).ldelim();
        break;
      case "flow":
        Value_Numeric_Flow.encode(message.typedValue.flow, writer.uint32(106).fork()).ldelim();
        break;
      case "rotationSpeed":
        Value_Numeric_RotationSpeed.encode(message.typedValue.rotationSpeed, writer.uint32(130).fork()).ldelim();
        break;
      case "rate":
        Value_Numeric_Rate.encode(message.typedValue.rate, writer.uint32(138).fork()).ldelim();
        break;
      case "unknown":
        writer.uint32(121).double(message.typedValue.unknown);
        break;
    }
    if (message.value !== 0) {
      writer.uint32(17).double(message.value);
    }
    if (message.textual !== "") {
      writer.uint32(146).string(message.textual);
    }
    if (message.unit !== UnitOfMeasurement.UNIT_UNKNOWN) {
      writer.uint32(152).int32(unitOfMeasurementToNumber(message.unit));
    }
    if (message.unitSymbol !== "") {
      writer.uint32(170).string(message.unitSymbol);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(160).int32(unitOfMeasurementToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = value_Numeric_TypeFromJSON(reader.int32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.typedValue = {
            $case: "batteryLevel",
            batteryLevel: Value_Numeric_BatteryLevel.decode(reader, reader.uint32()),
          };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.typedValue = { $case: "distance", distance: Value_Numeric_Distance.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.typedValue = { $case: "duration", duration: Value_Numeric_Duration.decode(reader, reader.uint32()) };
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.typedValue = {
            $case: "fuelEfficiency",
            fuelEfficiency: Value_Numeric_FuelEfficiency.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.typedValue = {
            $case: "percentage",
            percentage: Value_Numeric_Percentage.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.typedValue = { $case: "pressure", pressure: Value_Numeric_Pressure.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.typedValue = { $case: "speed", speed: Value_Numeric_Speed.decode(reader, reader.uint32()) };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.typedValue = {
            $case: "temperature",
            temperature: Value_Numeric_Temperature.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.typedValue = { $case: "voltage", voltage: Value_Numeric_Voltage.decode(reader, reader.uint32()) };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.typedValue = { $case: "volume", volume: Value_Numeric_Volume.decode(reader, reader.uint32()) };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.typedValue = { $case: "mass", mass: Value_Numeric_Mass.decode(reader, reader.uint32()) };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.typedValue = { $case: "flow", flow: Value_Numeric_Flow.decode(reader, reader.uint32()) };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.typedValue = {
            $case: "rotationSpeed",
            rotationSpeed: Value_Numeric_RotationSpeed.decode(reader, reader.uint32()),
          };
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.typedValue = { $case: "rate", rate: Value_Numeric_Rate.decode(reader, reader.uint32()) };
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.typedValue = { $case: "unknown", unknown: reader.double() };
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.unit = unitOfMeasurementFromJSON(reader.int32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.unitSymbol = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.convertedFrom = unitOfMeasurementFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric>): Value_Numeric {
    return Value_Numeric.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric>): Value_Numeric {
    const message = createBaseValue_Numeric();
    message.type = object.type ?? Value_Numeric_Type.TYPE_UNKNOWN;
    if (
      object.typedValue?.$case === "batteryLevel" &&
      object.typedValue?.batteryLevel !== undefined &&
      object.typedValue?.batteryLevel !== null
    ) {
      message.typedValue = {
        $case: "batteryLevel",
        batteryLevel: Value_Numeric_BatteryLevel.fromPartial(object.typedValue.batteryLevel),
      };
    }
    if (
      object.typedValue?.$case === "distance" &&
      object.typedValue?.distance !== undefined &&
      object.typedValue?.distance !== null
    ) {
      message.typedValue = {
        $case: "distance",
        distance: Value_Numeric_Distance.fromPartial(object.typedValue.distance),
      };
    }
    if (
      object.typedValue?.$case === "duration" &&
      object.typedValue?.duration !== undefined &&
      object.typedValue?.duration !== null
    ) {
      message.typedValue = {
        $case: "duration",
        duration: Value_Numeric_Duration.fromPartial(object.typedValue.duration),
      };
    }
    if (
      object.typedValue?.$case === "fuelEfficiency" &&
      object.typedValue?.fuelEfficiency !== undefined &&
      object.typedValue?.fuelEfficiency !== null
    ) {
      message.typedValue = {
        $case: "fuelEfficiency",
        fuelEfficiency: Value_Numeric_FuelEfficiency.fromPartial(object.typedValue.fuelEfficiency),
      };
    }
    if (
      object.typedValue?.$case === "percentage" &&
      object.typedValue?.percentage !== undefined &&
      object.typedValue?.percentage !== null
    ) {
      message.typedValue = {
        $case: "percentage",
        percentage: Value_Numeric_Percentage.fromPartial(object.typedValue.percentage),
      };
    }
    if (
      object.typedValue?.$case === "pressure" &&
      object.typedValue?.pressure !== undefined &&
      object.typedValue?.pressure !== null
    ) {
      message.typedValue = {
        $case: "pressure",
        pressure: Value_Numeric_Pressure.fromPartial(object.typedValue.pressure),
      };
    }
    if (
      object.typedValue?.$case === "speed" &&
      object.typedValue?.speed !== undefined &&
      object.typedValue?.speed !== null
    ) {
      message.typedValue = { $case: "speed", speed: Value_Numeric_Speed.fromPartial(object.typedValue.speed) };
    }
    if (
      object.typedValue?.$case === "temperature" &&
      object.typedValue?.temperature !== undefined &&
      object.typedValue?.temperature !== null
    ) {
      message.typedValue = {
        $case: "temperature",
        temperature: Value_Numeric_Temperature.fromPartial(object.typedValue.temperature),
      };
    }
    if (
      object.typedValue?.$case === "voltage" &&
      object.typedValue?.voltage !== undefined &&
      object.typedValue?.voltage !== null
    ) {
      message.typedValue = { $case: "voltage", voltage: Value_Numeric_Voltage.fromPartial(object.typedValue.voltage) };
    }
    if (
      object.typedValue?.$case === "volume" &&
      object.typedValue?.volume !== undefined &&
      object.typedValue?.volume !== null
    ) {
      message.typedValue = { $case: "volume", volume: Value_Numeric_Volume.fromPartial(object.typedValue.volume) };
    }
    if (
      object.typedValue?.$case === "mass" && object.typedValue?.mass !== undefined && object.typedValue?.mass !== null
    ) {
      message.typedValue = { $case: "mass", mass: Value_Numeric_Mass.fromPartial(object.typedValue.mass) };
    }
    if (
      object.typedValue?.$case === "flow" && object.typedValue?.flow !== undefined && object.typedValue?.flow !== null
    ) {
      message.typedValue = { $case: "flow", flow: Value_Numeric_Flow.fromPartial(object.typedValue.flow) };
    }
    if (
      object.typedValue?.$case === "rotationSpeed" &&
      object.typedValue?.rotationSpeed !== undefined &&
      object.typedValue?.rotationSpeed !== null
    ) {
      message.typedValue = {
        $case: "rotationSpeed",
        rotationSpeed: Value_Numeric_RotationSpeed.fromPartial(object.typedValue.rotationSpeed),
      };
    }
    if (
      object.typedValue?.$case === "rate" && object.typedValue?.rate !== undefined && object.typedValue?.rate !== null
    ) {
      message.typedValue = { $case: "rate", rate: Value_Numeric_Rate.fromPartial(object.typedValue.rate) };
    }
    if (
      object.typedValue?.$case === "unknown" &&
      object.typedValue?.unknown !== undefined &&
      object.typedValue?.unknown !== null
    ) {
      message.typedValue = { $case: "unknown", unknown: object.typedValue.unknown };
    }
    message.value = object.value ?? 0;
    message.textual = object.textual ?? "";
    message.unit = object.unit ?? UnitOfMeasurement.UNIT_UNKNOWN;
    message.unitSymbol = object.unitSymbol ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_BatteryLevel(): Value_Numeric_BatteryLevel {
  return { value: 0, unit: Value_Numeric_BatteryLevel_BatteryLevelUnit.PERCENT, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_BatteryLevel = {
  encode(message: Value_Numeric_BatteryLevel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_BatteryLevel_BatteryLevelUnit.PERCENT) {
      writer.uint32(16).int32(value_Numeric_BatteryLevel_BatteryLevelUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_BatteryLevel_BatteryLevelUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_BatteryLevel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_BatteryLevel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_BatteryLevel_BatteryLevelUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_BatteryLevel_BatteryLevelUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_BatteryLevel>): Value_Numeric_BatteryLevel {
    return Value_Numeric_BatteryLevel.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_BatteryLevel>): Value_Numeric_BatteryLevel {
    const message = createBaseValue_Numeric_BatteryLevel();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_BatteryLevel_BatteryLevelUnit.PERCENT;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_RotationSpeed(): Value_Numeric_RotationSpeed {
  return {
    value: 0,
    unit: Value_Numeric_RotationSpeed_RotationSpeedUnit.REVOLUTIONS_PER_MINUTE,
    textual: "",
    convertedFrom: undefined,
  };
}

export const Value_Numeric_RotationSpeed = {
  encode(message: Value_Numeric_RotationSpeed, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_RotationSpeed_RotationSpeedUnit.REVOLUTIONS_PER_MINUTE) {
      writer.uint32(16).int32(value_Numeric_RotationSpeed_RotationSpeedUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_RotationSpeed_RotationSpeedUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_RotationSpeed {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_RotationSpeed();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_RotationSpeed_RotationSpeedUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_RotationSpeed_RotationSpeedUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_RotationSpeed>): Value_Numeric_RotationSpeed {
    return Value_Numeric_RotationSpeed.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_RotationSpeed>): Value_Numeric_RotationSpeed {
    const message = createBaseValue_Numeric_RotationSpeed();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_RotationSpeed_RotationSpeedUnit.REVOLUTIONS_PER_MINUTE;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Distance(): Value_Numeric_Distance {
  return { value: 0, unit: Value_Numeric_Distance_DistanceUnit.METER, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Distance = {
  encode(message: Value_Numeric_Distance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_Distance_DistanceUnit.METER) {
      writer.uint32(16).int32(value_Numeric_Distance_DistanceUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Distance_DistanceUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Distance {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Distance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Distance_DistanceUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Distance_DistanceUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Distance>): Value_Numeric_Distance {
    return Value_Numeric_Distance.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Distance>): Value_Numeric_Distance {
    const message = createBaseValue_Numeric_Distance();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Distance_DistanceUnit.METER;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_FuelEfficiency(): Value_Numeric_FuelEfficiency {
  return {
    value: 0,
    unit: Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.LITER_PER_100_KILOMETER,
    textual: "",
    convertedFrom: undefined,
  };
}

export const Value_Numeric_FuelEfficiency = {
  encode(message: Value_Numeric_FuelEfficiency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.LITER_PER_100_KILOMETER) {
      writer.uint32(16).int32(value_Numeric_FuelEfficiency_FuelEfficiencyUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_FuelEfficiency_FuelEfficiencyUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_FuelEfficiency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_FuelEfficiency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_FuelEfficiency_FuelEfficiencyUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_FuelEfficiency_FuelEfficiencyUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_FuelEfficiency>): Value_Numeric_FuelEfficiency {
    return Value_Numeric_FuelEfficiency.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_FuelEfficiency>): Value_Numeric_FuelEfficiency {
    const message = createBaseValue_Numeric_FuelEfficiency();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.LITER_PER_100_KILOMETER;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Percentage(): Value_Numeric_Percentage {
  return { value: 0, unit: Value_Numeric_Percentage_PercentageUnit.PERCENT, textual: "" };
}

export const Value_Numeric_Percentage = {
  encode(message: Value_Numeric_Percentage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(13).float(message.value);
    }
    if (message.unit !== Value_Numeric_Percentage_PercentageUnit.PERCENT) {
      writer.uint32(24).int32(value_Numeric_Percentage_PercentageUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(18).string(message.textual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Percentage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Percentage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.value = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.unit = value_Numeric_Percentage_PercentageUnitFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.textual = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Percentage>): Value_Numeric_Percentage {
    return Value_Numeric_Percentage.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Percentage>): Value_Numeric_Percentage {
    const message = createBaseValue_Numeric_Percentage();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Percentage_PercentageUnit.PERCENT;
    message.textual = object.textual ?? "";
    return message;
  },
};

function createBaseValue_Numeric_Pressure(): Value_Numeric_Pressure {
  return { value: 0, unit: Value_Numeric_Pressure_PressureUnit.BAR, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Pressure = {
  encode(message: Value_Numeric_Pressure, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_Pressure_PressureUnit.BAR) {
      writer.uint32(16).int32(value_Numeric_Pressure_PressureUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Pressure_PressureUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Pressure {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Pressure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Pressure_PressureUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Pressure_PressureUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Pressure>): Value_Numeric_Pressure {
    return Value_Numeric_Pressure.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Pressure>): Value_Numeric_Pressure {
    const message = createBaseValue_Numeric_Pressure();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Pressure_PressureUnit.BAR;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Speed(): Value_Numeric_Speed {
  return { value: 0, unit: Value_Numeric_Speed_SpeedUnit.KILOMETERS_PER_HOUR, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Speed = {
  encode(message: Value_Numeric_Speed, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_Speed_SpeedUnit.KILOMETERS_PER_HOUR) {
      writer.uint32(16).int32(value_Numeric_Speed_SpeedUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Speed_SpeedUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Speed {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Speed();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Speed_SpeedUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Speed_SpeedUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Speed>): Value_Numeric_Speed {
    return Value_Numeric_Speed.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Speed>): Value_Numeric_Speed {
    const message = createBaseValue_Numeric_Speed();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Speed_SpeedUnit.KILOMETERS_PER_HOUR;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Temperature(): Value_Numeric_Temperature {
  return { value: 0, unit: Value_Numeric_Temperature_TemperatureUnit.KELVIN, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Temperature = {
  encode(message: Value_Numeric_Temperature, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_Temperature_TemperatureUnit.KELVIN) {
      writer.uint32(16).int32(value_Numeric_Temperature_TemperatureUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Temperature_TemperatureUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Temperature {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Temperature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Temperature_TemperatureUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Temperature_TemperatureUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Temperature>): Value_Numeric_Temperature {
    return Value_Numeric_Temperature.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Temperature>): Value_Numeric_Temperature {
    const message = createBaseValue_Numeric_Temperature();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Temperature_TemperatureUnit.KELVIN;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Voltage(): Value_Numeric_Voltage {
  return { value: 0, unit: Value_Numeric_Voltage_VoltageUnit.MILLIVOLT, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Voltage = {
  encode(message: Value_Numeric_Voltage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_Voltage_VoltageUnit.MILLIVOLT) {
      writer.uint32(16).int32(value_Numeric_Voltage_VoltageUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Voltage_VoltageUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Voltage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Voltage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Voltage_VoltageUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Voltage_VoltageUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Voltage>): Value_Numeric_Voltage {
    return Value_Numeric_Voltage.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Voltage>): Value_Numeric_Voltage {
    const message = createBaseValue_Numeric_Voltage();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Voltage_VoltageUnit.MILLIVOLT;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Volume(): Value_Numeric_Volume {
  return { value: 0, unit: Value_Numeric_Volume_VolumeUnit.LITER, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Volume = {
  encode(message: Value_Numeric_Volume, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_Volume_VolumeUnit.LITER) {
      writer.uint32(16).int32(value_Numeric_Volume_VolumeUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Volume_VolumeUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Volume {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Volume();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Volume_VolumeUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Volume_VolumeUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Volume>): Value_Numeric_Volume {
    return Value_Numeric_Volume.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Volume>): Value_Numeric_Volume {
    const message = createBaseValue_Numeric_Volume();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Volume_VolumeUnit.LITER;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Mass(): Value_Numeric_Mass {
  return { value: 0, unit: Value_Numeric_Mass_MassUnit.KILOGRAMS, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Mass = {
  encode(message: Value_Numeric_Mass, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_Mass_MassUnit.KILOGRAMS) {
      writer.uint32(16).int32(value_Numeric_Mass_MassUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Mass_MassUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Mass {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Mass();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Mass_MassUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Mass_MassUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Mass>): Value_Numeric_Mass {
    return Value_Numeric_Mass.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Mass>): Value_Numeric_Mass {
    const message = createBaseValue_Numeric_Mass();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Mass_MassUnit.KILOGRAMS;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Flow(): Value_Numeric_Flow {
  return { value: 0, unit: Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_HOUR, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Flow = {
  encode(message: Value_Numeric_Flow, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    if (message.unit !== Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_HOUR) {
      writer.uint32(16).int32(value_Numeric_Flow_FlowUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Flow_FlowUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Flow {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Flow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Flow_FlowUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Flow_FlowUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Flow>): Value_Numeric_Flow {
    return Value_Numeric_Flow.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Flow>): Value_Numeric_Flow {
    const message = createBaseValue_Numeric_Flow();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_HOUR;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Rate(): Value_Numeric_Rate {
  return { value: 0, unit: Value_Numeric_Rate_RateUnit.ITEMS_PER_24_HOURS, textual: "", convertedFrom: undefined };
}

export const Value_Numeric_Rate = {
  encode(message: Value_Numeric_Rate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(8).uint32(message.value);
    }
    if (message.unit !== Value_Numeric_Rate_RateUnit.ITEMS_PER_24_HOURS) {
      writer.uint32(16).int32(value_Numeric_Rate_RateUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(26).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(32).int32(value_Numeric_Rate_RateUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Rate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Rate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = value_Numeric_Rate_RateUnitFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.convertedFrom = value_Numeric_Rate_RateUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Rate>): Value_Numeric_Rate {
    return Value_Numeric_Rate.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Rate>): Value_Numeric_Rate {
    const message = createBaseValue_Numeric_Rate();
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Rate_RateUnit.ITEMS_PER_24_HOURS;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Numeric_Duration(): Value_Numeric_Duration {
  return {
    duration: undefined,
    value: 0,
    unit: Value_Numeric_Duration_DurationUnit.MILLISECONDS,
    textual: "",
    convertedFrom: undefined,
  };
}

export const Value_Numeric_Duration = {
  encode(message: Value_Numeric_Duration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.duration !== undefined) {
      Duration.encode(message.duration, writer.uint32(10).fork()).ldelim();
    }
    if (message.value !== 0) {
      writer.uint32(25).double(message.value);
    }
    if (message.unit !== Value_Numeric_Duration_DurationUnit.MILLISECONDS) {
      writer.uint32(32).int32(value_Numeric_Duration_DurationUnitToNumber(message.unit));
    }
    if (message.textual !== "") {
      writer.uint32(18).string(message.textual);
    }
    if (message.convertedFrom !== undefined) {
      writer.uint32(40).int32(value_Numeric_Duration_DurationUnitToNumber(message.convertedFrom));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Numeric_Duration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Numeric_Duration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.duration = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.value = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.unit = value_Numeric_Duration_DurationUnitFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.textual = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.convertedFrom = value_Numeric_Duration_DurationUnitFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Numeric_Duration>): Value_Numeric_Duration {
    return Value_Numeric_Duration.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Numeric_Duration>): Value_Numeric_Duration {
    const message = createBaseValue_Numeric_Duration();
    message.duration = (object.duration !== undefined && object.duration !== null)
      ? Duration.fromPartial(object.duration)
      : undefined;
    message.value = object.value ?? 0;
    message.unit = object.unit ?? Value_Numeric_Duration_DurationUnit.MILLISECONDS;
    message.textual = object.textual ?? "";
    message.convertedFrom = object.convertedFrom ?? undefined;
    return message;
  },
};

function createBaseValue_Enum(): Value_Enum {
  return { value: "", displayName: "", description: "", color: "", priority: 0 };
}

export const Value_Enum = {
  encode(message: Value_Enum, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.color !== "") {
      writer.uint32(34).string(message.color);
    }
    if (message.priority !== 0) {
      writer.uint32(40).int32(message.priority);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value_Enum {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue_Enum();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.color = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.priority = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value_Enum>): Value_Enum {
    return Value_Enum.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Value_Enum>): Value_Enum {
    const message = createBaseValue_Enum();
    message.value = object.value ?? "";
    message.displayName = object.displayName ?? "";
    message.description = object.description ?? "";
    message.color = object.color ?? "";
    message.priority = object.priority ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
