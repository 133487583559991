/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "hiber.sso";

export interface ZendeskTokenRequest {
}

export interface ZendeskTokenRequest_Response {
  token: string;
}

function createBaseZendeskTokenRequest(): ZendeskTokenRequest {
  return {};
}

export const ZendeskTokenRequest = {
  encode(_: ZendeskTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZendeskTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZendeskTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ZendeskTokenRequest>): ZendeskTokenRequest {
    return ZendeskTokenRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ZendeskTokenRequest>): ZendeskTokenRequest {
    const message = createBaseZendeskTokenRequest();
    return message;
  },
};

function createBaseZendeskTokenRequest_Response(): ZendeskTokenRequest_Response {
  return { token: "" };
}

export const ZendeskTokenRequest_Response = {
  encode(message: ZendeskTokenRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZendeskTokenRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZendeskTokenRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ZendeskTokenRequest_Response>): ZendeskTokenRequest_Response {
    return ZendeskTokenRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ZendeskTokenRequest_Response>): ZendeskTokenRequest_Response {
    const message = createBaseZendeskTokenRequest_Response();
    message.token = object.token ?? "";
    return message;
  },
};

/** Single sign on support service. */
export type SingleSignOnServiceDefinition = typeof SingleSignOnServiceDefinition;
export const SingleSignOnServiceDefinition = {
  name: "SingleSignOnService",
  fullName: "hiber.sso.SingleSignOnService",
  methods: {
    /** Zendesk SSO using custom JWT */
    zendesk: {
      name: "Zendesk",
      requestType: ZendeskTokenRequest,
      requestStream: false,
      responseType: ZendeskTokenRequest_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
