const routes = [
  {
    key: 'landing',
    name: 'Landing',
    path: '/',
    children: [
      {
        key: 'changelog',
        name: 'Changelog',
        path: '/changelog',
        params: {
          tabs: [
            {
              key: 'changelog-api',
              path: 'api',
            },
            {
              key: 'changelog-missioncontrol',
              path: 'missioncontrol',
            },
          ],
        },
      },
      {
        key: 'developer',
        name: 'Developers',
        path: '/developers',
        params: {
          tabs: [
            {
              key: 'webhook-list',
              path: 'integrations',
            },
            {
              key: 'token-list',
              path: 'integrations',
              query: [
                {
                  key: 'token-create-modal',
                  path: '?token=true&name={name}&expiry={expiry}',
                },
              ],
            },
            {
              key: 'slack-list',
              path: 'integrations',
            },
          ],
        },
      },
      {
        key: 'event',
        name: 'Events',
        path: '/events',
        params: {
          tabs: [
            {
              key: 'event-modem',
              path: 'modems',
            },
            {
              key: 'event-admin',
              path: 'admin',
            },
            {
              key: 'event-integration',
              path: 'integrations',
            },
            {
              key: 'event-transfer',
              path: 'transfers',
            },
          ],
          query: [
            {
              key: 'event-type',
              path: '?eventId={id}',
            },
          ],
        },
      },
      {
        key: 'modem-transfer',
        name: 'Journey',
        path: '/transfers',
        params: {
          tabs: [
            {
              key: 'modem-transfer-list-inbound',
              path: 'inbound',
            },
            {
              key: 'modem-transfer-list-outbound',
              path: 'outbound',
            },
            {
              key: 'modem-transfer-help',
              path: 'introduction',
            },
          ],
        },
      },
      {
        key: 'modem',
        name: 'List',
        path: '/list',
        params: {
          view: [
            {
              key: 'modem-map-dashboard',
              path: 'map',
            },
            {
              key: 'modem-list',
              path: 'modems',
            },
          ],
          tabs: [
            {
              key: 'all',
              path: 'all',
            },
            {
              key: 'groups',
              path: 'groups',
            },
          ],
          query: [
            {
              key: 'modem-search',
              path: '?search={search}',
            },
            {
              key: 'transfer-search',
              path: '?transferids={id}',
            },
          ],
        },
      },
      {
        key: 'controlcenter',
        name: 'Overview',
        path: '/hiberhilo',
      },
      {
        name: 'Well',
        path: '/hiberhilo/well/:id',
        props: route => ({
          id: route.params.id,
          startDate: new Date(route.query.startDate),
          endDate: new Date(route.query.endDate),
          tab: route.query.tab,
        }),
      },
      {
        key: 'user',
        name: 'User',
        path: '/user',
        params: {
          tabs: [
            {
              key: 'user-info',
              path: 'personal',
            },
            {
              key: 'user-list',
              path: 'accounts',
            },
            {
              key: 'organization-list',
              path: 'organizations',
            },
            {
              key: 'organization-info',
              path: 'company',
            },
            {
              key: 'user-notification-preferences',
              path: 'emailPreferences',
            },
          ],
        },
      },
      {
        key: 'settings',
        name: 'Settings',
        path: 'settings',
        params: {
          tabs: [
            {
              key: 'tags',
              path: 'tags',
            },
            {
              key: 'locations',
              path: 'locations',
            },
          ],
        },
      },
      {
        key: 'settings-manage-tags',
        name: 'TagCrud',
        path: 'settings/tags',
        params: {
          action: [
            {
              key: 'tag-create',
              path: 'create',
            },
            {
              key: 'tag-edit',
              path: 'edit?tagId={tagId}',
            },
          ],
        },
      },
      {
        key: 'settings-manage-location',
        name: 'EasypulseLocationCrud',
        path: '/settings/locations',
        params: {
          action: [
            {
              key: 'location-create',
              path: 'create',
            },
            {
              key: 'location-edit',
              path: 'edit?name={name}',
            },
          ],
        },
      },
      {
        key: 'settings-manage-modem-alarms',
        name: 'ModemAlarmsCrud',
        path: 'settings/alarms',
        params: {
          action: [
            {
              key: 'modem-alarms-create',
              path: 'create',
            },
            {
              key: 'modem-alarms-edit',
              path: 'edit?alarmId={alarmId}',
            },
          ],
        },
      },
    ],
  },
  {
    key: 'login',
    name: 'Login',
    path: '/login',
    meta: {
      requiresNoCurrentUser: true,
      requiresAuth: false,
    },
    params: {
      view: [
        {
          key: 'login-create-user',
          path: 'create',
        },
        {
          key: 'login-new-user',
          path: 'new',
        },
        {
          key: 'login-forgot-pass',
          path: 'forgotpass',
        },
        {
          key: 'authentication',
          path: 'authentication',
        },
      ],
    },
    component: 'Login/Login',
  },
  { name: 'TermsAndConditions', path: '/terms-and-conditions', component: 'Login/TermsAndConditions' },
  { name: 'EmailVerification', path: '/email-verification', component: 'Login/EmailVerification' },
  {
    key: 'organization-request',
    name: 'OrganizationRequest',
    path: '/organization-request',
    meta: {
      requiresUserToBeWithoutOrganizations: true,
    },
    component: 'Login/OrganizationRequest',
  },
  {
    key: 'organization-invites',
    name: 'OrganizationInvites',
    path: '/invites',
    meta: {
      requiresUserToBeWithoutOrganizations: true,
    },
    component: 'Login/OrganizationInvites',
  },
  {
    key: 'zendesk-sign-in',
    name: 'Zendesk',
    component: 'Login/Zendesk',
    path: '/zendesk',
  },
  {
    key: 'zendesk-sign-out',
    name: 'ZendeskOut',
    component: 'Login/ZendeskOut',
    path: '/zendeskout',
  },
  {
    name: '*',
    path: '*',
    redirect: '/',
  },
];
export default routes;
