import { getCurrentInstance, ref, watch } from 'vue';

export default function useStore() {
  const $store = ref();
  const instance = getCurrentInstance();

  watch(() => instance?.proxy, () => {
    $store.value = instance?.proxy?.$store;
  }, { immediate: true });

  return { $store };
}
