import Vue from 'vue';
import moment from 'moment-timezone';
import {
  convertHexToString,
  convertTimeToIso,
  createUint8Array,
  defaultErrorHandler,
  successHandler,
} from '@/store/core/utils';
import { getModemMessages, getModems, pushModemTestMessages, updateTagsOnModems } from '@/services/modem';
import { fetchTags } from '@/services/tags';
import { ListModemsRequest_Sort } from 'hiber-grpc/src/customer_compiled/modem';
import { PushModemMessagesRequest_MessageToPush } from 'hiber-grpc/src/customer_compiled/testing';
import Cookies from 'js-cookie';

export const defaultMessagesDaysAmount = 2;
export const defaultFilters = {
  search: '',
  activatedRange: {
    startDate: '',
    endDate: '',
    active: false,
  },
  lastMessageRange: {
    startDate: '',
    endDate: '',
    active: false,
  },
  types: {
    active: false,
    include: [],
  },
  health: {
    active: false,
    include: [],
  },
  tags: {
    active: false,
    include: [],
    exclude: [],
  },
  gatewaysOnly: {
    active: false,
  },
  gatewayDevices: {
    search: '',
    active: false,
  },
  transferIds: {
    ids: [],
    active: false,
  },
  includeChildOrgData: {
    include: [],
    exclude: [],
    active: false,
  },
  includeInbound: {
    active: false,
  },
  includeOutbound: {
    active: false,
  },
};

const defaultModems = {
  pagination: {
    size: 300,
    page: 0,
  },
};
const defaultModemGroups = {
  pagination: {
    size: 300,
    page: 0,
  },
};
const defaultSort = {
  number: ListModemsRequest_Sort.MODEM_NUMBER_ASC,
};

const shownColumnsCookie = 'h-list-shown-columns';
const shownColumns = JSON.parse(Cookies.get(shownColumnsCookie) || '[]');

const getColumnVisibility = (key, fallback = false) => {
  const foundSavedSetting = shownColumns.find(cookie => cookie.key === key);
  if (foundSavedSetting) {
    return foundSavedSetting.show;
  }
  return fallback;
};

const defaultModemQuery = {
  groups: {
    pagination: { size: 300, page: 0 },
    filters: {
      ...JSON.parse(JSON.stringify(defaultFilters)),
    },
    sortBy: [],
  },
  content: {
    pagination: { size: 300, page: 0 },
    filters: {
      ...JSON.parse(JSON.stringify(defaultFilters)),
    },
    sortBy: [ListModemsRequest_Sort.MODEM_NUMBER_ASC],
  },
  allowAnyGroupParent: true,
};

const Modem = {
  namespaced: true,
  state: {
    modems: {
      modems: [],
      ...defaultModems,
    },
    modemGroups: {
      groups: [],
      ...defaultModemGroups,
    },
    modemSort: { ...defaultSort },
    filters: JSON.parse(JSON.stringify(defaultFilters)),
    modemQuery: JSON.parse(JSON.stringify(defaultModemQuery)),
    listAsGroups: true,
    listIsHierarchical: true,
    detailsView: {
      modem: false,
      expandModemEvents: false,
      expandModemMessages: false,
    },
    tags: {
      tags: [],
    },
    normalizedTags: [],
    tagTotals: [],
    modemMessage: {
      messages: [
        {
          modemNumber: '',
        },
      ],
    },
    messagesTimerange: {
      startDate: moment().subtract(defaultMessagesDaysAmount, 'days').startOf('day'),
      endDate: moment().endOf('day'),
    },
    showFilters: false,
    shownDataColumns: [
      { key: 'device', show: getColumnVisibility('device', true) },
      { key: 'name', show: getColumnVisibility('name', true) },
      { key: 'number', show: getColumnVisibility('number') },
      { key: 'well', show: getColumnVisibility('well', true) },
      { key: 'site', show: getColumnVisibility('site') },
      { key: 'production-area', show: getColumnVisibility('production-area') },
      { key: 'device-type', show: getColumnVisibility('device-type', true) },
      { key: 'brand', show: getColumnVisibility('brand') },
      { key: 'application', show: getColumnVisibility('application') },
      { key: 'category', show: getColumnVisibility('category') },
      { key: 'version', show: getColumnVisibility('version') },
      { key: 'lifecycle', show: getColumnVisibility('lifecycle') },
      { key: 'organization', show: getColumnVisibility('organization') },
      { key: 'connected', show: getColumnVisibility('connected', true) },
      { key: 'battery-type', show: getColumnVisibility('battery-type') },
      { key: 'notes', show: getColumnVisibility('notes', true) },
      { key: 'external-device', show: getColumnVisibility('external-device') },
      { key: 'transmission-interval', show: getColumnVisibility('transmission-interval') },
      { key: 'last-timestamp', show: getColumnVisibility('last-timestamp', true) },
      { key: 'health', show: getColumnVisibility('health', true) },
    ],
    availableModemMessageFields: [],
  },
  actions: {
    getTags(context) {
      return new Promise((resolve, reject) => {
        fetchTags().then((res) => {
          context.commit('getTags', res);
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    getModems(context, target) {
      return new Promise((resolve, reject) => {
        const settings = {
          pagination: {
            size: 300,
            page: 0,
          },
          sortBy: context.state.modemSort.number,
          filters: JSON.parse(JSON.stringify(context.state.modemQuery.groups.filters)),
          ...target,
        };

        getModems(settings).then((res) => {
          context.commit('getModems', res);
          context.commit('setListAsGroups', false);
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    updateTagsOnModems(context, update) {
      const payload = {
        tagIdsToAdd: [],
        tagIdsToRemove: [],
        tagsToCreateFromLabels: [],
        modemNrs: [],
        ...update,
      };
      const logMessage = {
        category: 'Tags of',
        name: payload.modemNrs.join(', '),
        action: 'updated',
      };

      return new Promise((resolve, reject) => {
        updateTagsOnModems(
          payload.tagIdsToAdd,
          payload.tagIdsToRemove,
          payload.tagsToCreateFromLabels,
          payload.modemNrs,
        ).then((res) => {
          context.commit('updateTagsOnModems', res);
          context.dispatch('getTags')
            .then(() => {
              successHandler(res, resolve, context, logMessage);
            })
            .catch(err => defaultErrorHandler(err, reject, context));
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    getModemMessages(context, target) {
      let pagination = { size: 1000, page: 0 };
      if (target.pagination) {
        pagination = target.pagination;
      }

      const modemNrs = target.modemNrs || [];
      const organization = target.organization || '';

      // Set only the start range so we query from the last event on
      const timerange = { start: {}, end: {} };

      const testMessages = target.testMessages || true;

      if (target.timerange) {
        timerange.start = target.timerange.start;
        timerange.end = target.timerange.end;
      } else {
        const daysBeforeNow = target.daysBeforeNow || 30;
        // Start of the 'last modem events'
        timerange.start = convertTimeToIso(moment().subtract(daysBeforeNow, 'days').toDate());
      }

      return new Promise((resolve, reject) => {
        getModemMessages(
          modemNrs,
          organization,
          timerange,
          testMessages,
          pagination.size,
        ).then((res) => {
          context.commit('getModemMessages', res);
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    getLatestModemMessage(context, target) {
      const hiberFounding = '2016-06-01T00:00:00Z';
      const timerange = { start: { textual: hiberFounding } };

      return new Promise((resolve, reject) => {
        const modemNrs = target.modemNrs || [];
        const organization = target.organization || '';
        const testMessages = target.testMessages || true;
        getModemMessages(modemNrs, organization, timerange, testMessages).then((res) => {
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    pushModemTestMessages(context, payload) {
      const logMessage = {
        category: 'Test message',
        name: '',
        action: 'sent',
      };
      return new Promise((resolve, reject) => {
        const requests = [];
        if (payload.length) {
          payload.forEach((messageToPush) => {
            let content;
            if (messageToPush.messageType === 2) { // 2 is string
              content = createUint8Array(messageToPush.content);
            } else if (messageToPush.messageType === 1) { // 1 is hex
              content = createUint8Array(convertHexToString(messageToPush.content));
            }
            const pushRequest = PushModemMessagesRequest_MessageToPush.fromPartial({
              modemNumber: messageToPush.modemNumber,
              time: { textual: messageToPush.time },
              content,
              location: messageToPush.location
                ? {
                  latitude: messageToPush.location.latitude,
                  longitude: messageToPush.location.longitude,
                } : undefined,
            });
            requests.push(pushRequest);
          });

          pushModemTestMessages(requests).then((res) => {
            context.commit('pushModemTestMessages', res);

            // Refresh
            setTimeout(() => {
              context.dispatch('Events/getEvents', null, { root: true });
            }, 1000);
            successHandler(res, resolve, context, logMessage);
          }).catch((res) => defaultErrorHandler(res, reject, context));
        } else {
          defaultErrorHandler({ statusMessage: 'No messages in payload' }, reject, context);
        }
      });
    },
    setShownDataColumns(context, payload) {
      return new Promise((resolve) => {
        context.commit('setShownDataColumns', payload);
        resolve();
      });
    },
  },
  mutations: {
    setAvailableModemMessageFields(state, payload) {
      state.availableModemMessageFields = payload;
    },

    resetModemQuery(state) {
      state.modemQuery = JSON.parse(JSON.stringify(defaultModemQuery));
    },
    setModemQuery(state, payload) {
      state.modemQuery = payload;
    },
    // { groups: { filterName: newFilterValue },
    //   content: { filter2: newFilter2Value } }
    // OR
    // { filterForBoth: newFilterValue },
    updateFilters(state, _pay) {
      // For each target: groups || content
      if (typeof _pay === 'object') {
        let payload;
        // If setting groups / content specifically, apply that directly
        if (_pay.groups || _pay.content) {
          payload = _pay;
        } else {
          // If groups / content not separately defined, apply to both
          // Backwards compatible + allows easy editing of both
          payload = { groups: _pay, content: _pay };
        }

        // For each target (group / content), update the filters
        Object.keys(payload).forEach((target) => {
          if (state.modemQuery[target] && typeof payload[target] === 'object') {
            Object.keys(payload[target]).forEach((filter) => {
              const currentState = state.modemQuery[target].filters[filter];
              const newState = payload[target][filter];

              if (typeof newState === 'object') {
                // Apply newState on top of current filter state
                Vue.set(state.modemQuery[target].filters, filter, {
                  ...currentState,
                  ...newState,
                });
              } else {
                // Not an object, so directly overwrite
                Vue.set(state.modemQuery[target].filters, filter, newState);
              }
            });
          }
        });
      }
    },
    setListAsGroups(state, res) {
      state.listAsGroups = res;
    },
    clearFilters(state) {
      state.filters = JSON.parse(JSON.stringify(defaultFilters));
    },
    setShowFilters(state, res) {
      state.showFilters = res;
    },
    setModemPreview(state, res) {
      state.detailsView.modem = res;
    },
    setDetailsView(state, res) {
      state.detailsView[res.view] = res.value;
    },
    setModemSortNumber(state, res) {
      state.modemSort.number = res;
    },
    getTags(state, res) {
      state.tags = res;
    },
    getModems(state, res) {
      state.modems = res;
    },
    getModemsGrouped(state, res) {
      state.modemGroups = res;
    },
    updateTagsOnModems() {
      // Empty on purpose -- lists the action in Vue Devtools.
      // Actual data is loaded in subsequent getModems() call.
    },
    getModemMessages(state, res) {
      state.modemMessage = res;
    },
    updatePeripheralsOnModems() {
      // Empty on purpose -- lists the action in Vue Devtools.
      // Actual data is loaded in subsequent getModems() call.
    },
    renameModem() {
      // Empty on purpose -- lists the action in Vue Devtools.
    },
    setModemStatus() {
      // Empty on purpose -- lists the action in Vue Devtools.
    },
    updateNote() {
      // Empty on purpose -- lists the action in Vue Devtools.
    },
    pushModemTestMessages() {
      // Empty on purpose -- lists the action in Vue Devtools.
    },
    setShownDataColumns(state, res) {
      // Note, res is NOT deep-copied, as sometimes we'd need to deep copy objects within the array
      // For example, [{ key: 'test' }] would need to be JSON.parse(JSON.stringify())-ed.
      // Do this outside of the mutation
      state.shownDataColumns = res;
    },
    setMessagesTimerange(state, res) {
      state.messagesTimerange = res;
    },
    setNormalizedTags(state, res) {
      state.normalizedTags = res;
    },
    setNormalizedTagTotals(state, res) {
      state.tagTotals = res;
    },
  },
  getters: {
    tags(state) {
      return state.tags.tags;
    },

    modemHasTag() {
      return (m, label) => {
        let hasTag = false;
        m.tags.forEach((tag) => {
          if (tag.label.name === label) {
            hasTag = true;
          }
        });
        return hasTag;
      };
    },
    getTagByLabel(state) {
      return (label) => {
        let tag = {};
        state.tags.tags.forEach((obj) => {
          if (obj.label.name === label) {
            tag = obj;
          }
        });
        return tag.id ? tag : false;
      };
    },
    getMessagesTimeRange(state) {
      return state.messagesTimerange;
    },
  },
};

export default Modem;
