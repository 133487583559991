import Vue from 'vue';
import { defaultErrorHandler, successHandler } from '@/store/core/utils';
import {
  createMQTTIntegration,
  createSlackIntegrationRequest,
  createToken,
  createWebhook,
  deleteMQTT,
  deleteSlackRequest,
  deleteToken,
  deleteWebhook,
  enableWebhook,
  fetchMQTT,
  fetchSlackList,
  fetchTokens,
  updateWebhook,
} from '@/services/integrations';
import { fetchWebhookHistory, fetchWebhooks } from '@/services/webhooks';

const Integrations = {
  namespaced: true,
  state: {
    tokens: {
      tokens: [],
      pagination: {
        size: 100,
        page: 0,
      },
    },
    webhooks: {
      isDirty: false,
      pagination: {
        size: 100,
        page: 0,
      },
    },
    slack: {
      publishers: [],
      pagination: {
        size: 100,
        page: 0,
      },
    },
    mqtt: {
      publishers: [],
      pagination: {
        size: 100,
        page: 0,
      },
    },
    generatedToken: '',
    undo: {
      tokens: {
        ids: [],
      },
      webhooks: {
        ids: [],
      },
      slacks: {
        ids: [],
      },
      mqtts: {
        ids: [],
      },
    },
  },
  actions: {
    getTokensList(context, target) {
      return new Promise((resolve, reject) => {
        fetchTokens(
          target ? target.pagination.size : 100,
          target ? target.pagination.page : 0,
        ).then((res) => {
          context.commit('getTokensList', res);
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    createTokenRequest(context, form, expire) {
      const payload = {
        ...form,
        ...expire,
      };
      const logMessage = {
        category: 'Token',
        name: payload.form.name,
        action: 'created',
      };

      return new Promise((resolve, reject) => {
        createToken(
          payload.form.name,
          payload.expire,
          payload.form.permissions.organization,
          payload.form.permissions.user,
          payload.form.tokenType,
          payload.form.email,
        ).then((res) => {
          context.commit('setGeneratedToken', res);
          successHandler(res, resolve, context, logMessage);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    enableWebhookRequest(context, id) {
      const payload = {
        ...id,
      };
      const logMessage = {
        category: 'Webhook',
        name: payload.id,
        action: 'enabled',
      };

      return new Promise((resolve, reject) => {
        enableWebhook(
          payload.id,
        ).then((res) => {
          resolve();
          successHandler(res, resolve, context, logMessage);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    deleteTokenRequest(context, id) {
      const payload = {
        ...id,
      };
      return new Promise((resolve, reject) => {
        deleteToken(
          payload.id,
        ).then(() => {
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },

    getWebhookList(context, target) {
      return new Promise((resolve, reject) => {
        fetchWebhooks(
          target ? target.pagination.size : 100,
          target ? target.pagination.page : 0,
        ).then((response) => {
          const { webhooks, pagination } = response;

          context.commit('clearWebhookDirty');
          context.commit('getWebhookPagination', pagination);
          resolve(webhooks);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },

    getWebhookHistory(context, { id, target }) {
      return new Promise((resolve, reject) => {
        fetchWebhookHistory(
          id,
          target ? target.pagination.size : 100,
          target ? target.pagination.page : 0,
        ).then((response) => {
          resolve(response.calls);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },

    createWebhookRequest(context, payload) {
      const settings = {
        ...payload.form,
      };
      const logMessage = {
        category: 'Webhook',
        name: payload.form.name,
        action: 'created',
      };

      return new Promise((resolve, reject) => {
        createWebhook(
          settings,
        ).then((res) => {
          context.commit('setWebhookDirty');
          successHandler(res, resolve, context, logMessage);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    updateWebhookRequest(context, form, id) {
      const payload = {
        ...form,
        ...id,
      };
      const logMessage = {
        category: payload.form.name === 'Multiple...' ? 'Multiple' : 'Webhook',
        name: payload.form.name === 'Multiple...' ? 'Webhooks' : payload.form.name,
        action: 'updated',
      };

      return new Promise((resolve, reject) => {
        updateWebhook(
          { ...payload.form },
          payload.id,
        ).then((res) => {
          context.commit('setWebhookDirty');
          successHandler(res, resolve, context, logMessage);
          context.dispatch('getWebhookList');
        }).catch(res => {
          defaultErrorHandler(res, reject, context);
        });
      });
    },
    deleteWebhookRequest(context, id) {
      const payload = {
        ...id,
      };
      return new Promise((resolve, reject) => {
        deleteWebhook(payload.id).then(() => {
          context.commit('setWebhookDirty');
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    forceWebhookRefresh(context) {
      context.commit('setWebhookDirty');
    },
    getSlackList(context, target) {
      return new Promise((resolve, reject) => {
        fetchSlackList(
          target ? target.pagination.size : 100,
          target ? target.pagination.page : 0,
        ).then((res) => {
          context.commit('getSlackList', res);
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    deleteSlackRequest(context, id) {
      const payload = {
        ...id,
      };
      return new Promise((resolve, reject) => {
        deleteSlackRequest(payload.id).then(() => {
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    createSlackIntegrationRequest(context, form) {
      const payload = {
        ...form,
      };
      const logMessage = {
        category: 'Slack integration',
        name: payload.form.name,
        action: 'created',
      };

      return new Promise((resolve, reject) => {
        createSlackIntegrationRequest(
          payload.form,
        ).then((res) => {
          successHandler(res, resolve, context, logMessage);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    getMQTTList(context, target) {
      return new Promise((resolve, reject) => {
        fetchMQTT(
          target ? target.pagination.size : 100,
          target ? target.pagination.page : 0,
        ).then((res) => {
          context.commit('getMQTTList', res);
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    createMQTTIntegrationRequest(context, form) {
      const payload = {
        ...form,
      };

      return new Promise((resolve, reject) => {
        createMQTTIntegration(
          payload.form,
        ).then(() => {
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    deleteMQTTRequest(context, id) {
      const payload = {
        ...id,
      };
      return new Promise((resolve, reject) => {
        deleteMQTT(payload.id).then(() => {
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    getTokensList(state, res) {
      state.tokens = res;
    },
    clearWebhookDirty(state) {
      Vue.set(state.webhooks, 'isDirty', false);
    },
    setWebhookDirty(state) {
      Vue.set(state.webhooks, 'isDirty', true);
    },
    getWebhookPagination(state, res) {
      Vue.set(state.webhooks, 'pagination', res);
    },
    getSlackList(state, res) {
      state.slack = res;
    },
    setGeneratedToken(state, res) {
      state.generatedToken = res.token;
    },
    resetGeneratedToken(state) {
      state.generatedToken = '';
    },
    getMQTTList(state, res) {
      state.mqtt = res;
    },
    temporarilyRemoveItems(state, payload) {
      state.undo[payload.category].ids.push(payload.id);
    },
    clearTemporarilyRemovedItems(state, payload) {
      const index = state.undo[payload.category].ids.indexOf(payload.id);
      if (index > -1) {
        state.undo[payload.category].ids.splice(index, 1);
      }
    },
    resetTemporarilyRemovedItems(state) {
      state.undo = {
        tokens: {
          ids: [],
        },
        webhooks: {
          ids: [],
        },
        slacks: {
          ids: [],
        },
        mqtts: {
          ids: [],
        },
      };
    },
  },
  getters: {
    webhookPagination(state) {
      return state.webhooks.pagination;
    },

    webhookIsDirty(state) {
      return state.webhooks.isDirty;
    },

    webhookUndo(state) {
      return state.undo.webhooks;
    },
  },
};

export default Integrations;
