import { GrpcClientFactoryType } from './types';
import { Client } from 'nice-grpc-web';
import {
  AssignDeviceType_Request,
  AssignDeviceType_Response,
  DeepPartial,
  DeviceTypeServiceDefinition
} from '../support_compiled/device_type';

export default class DeviceTypeSupportService {
  private static instance: DeviceTypeSupportService;
  private readonly client: Client<typeof DeviceTypeServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof DeviceTypeServiceDefinition>(
      DeviceTypeServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!DeviceTypeSupportService.instance) {
      DeviceTypeSupportService.instance = new DeviceTypeSupportService(grpcClientFactory);
    }

    return DeviceTypeSupportService.instance;
  }


  assignDeviceType(payload: DeepPartial<AssignDeviceType_Request>): Promise<AssignDeviceType_Response> {
    const request = AssignDeviceType_Request.fromPartial(payload);
    return this.client.assign(request);
  }
}
