<template>
  <label class='tw-flex'>
    <input
      class='tw-hidden tw-relative'
      type='radio'
      :disabled='disabled'
      :checked='isChecked'
      :value='value'
      @change='$emit("change", $event.target.value)'>
    <span
      class='radio'
      :style='style' />
    <span
      class='tw-px-2 tw-font-light'
      :class='{"tw-text-neutral-3":disabled}'>{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'Radio',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, required: false, default: false },
    label: { type: String, required: true },
    modelValue: { type: [String, Number, Boolean], default: '' },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  computed: {
    // Required for v-model to work on <h-input />. See Vue docs
    isChecked() {
      // eslint-disable-next-line
      return this.modelValue == this.value;
    },
    style() {
      return { '--color': this.disabled ? '#DDE5EE' : '#002D3C' };
    },
  },
};
</script>

<style lang='scss' scoped>

input:checked ~ .radio:before {
  @apply tw-block;
}

.radio {
  @apply tw-relative tw-cursor-pointer;
  @apply tw-transition-opacity tw-ease-in-out tw-duration-150;
  @apply tw-bg-white;
  @apply tw-border tw-border-solid tw-rounded-full tw-border-neutral-2;

  width: 18px;
  height: 18px;

  &:before {
    content: "";
    width: 12px;
    height: 12px;

    @apply tw-absolute tw-hidden;
    @apply tw-left-1/2 tw-top-1/2;
    @apply tw-transform tw--translate-x-1/2 tw--translate-y-1/2;
    @apply tw-rounded-full;
    @apply tw-bg-[var(--color)];
  }
}
</style>
