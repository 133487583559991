import Firebase from 'firebase/app';
import 'firebase/auth';
import latinize from 'latinize';
import {
  acceptOrganizationInvite,
  acceptTermsAndConditions,
  approveRequestedUsers,
  createUser,
  declineRequestedUsers,
  getCurrentUser,
  getInvitedUsers,
  getOrganizationInvites,
  getRequestedUsers,
  getUsers,
  inviteUser,
  requestUserAccessRequest,
  updateUserSettings,
} from '@/services/user';
import { defaultErrorHandler, successHandler } from '@/store/core/utils';
import { OrganizationPermission } from 'hiber-grpc/src/customer_compiled/permission';

const User = {
  namespaced: true,
  state: {
    requestedUsers: {
      users: [],
      pagination: {
        size: 100,
        page: 0,
      },
    },
    invitedUsers: {
      emails: [],
    },
    usersSearch: '',
    users: {
      users: [],
      pagination: {
        size: 100,
        page: 0,
      },
    },
    currentUser: {
      currentOrganizationPermissions: {},
      organizations: [],
      defaultOrganization: '',
      email: '',
      id: '',
      name: '',
    },
    organizationInvites: {
      organizations: [],
    },
    userInitials: '',
    user: {},
    usersOrgPermissions: {},
    permissionGroups: {
      // has all the permissions, simply set the includeAll flag
      // automatically gets all new permissions
      admin: {
        name: 'Admin',
        includeAll: true,
        include: [],
      },
      // has almost all permissions, just limited by some admin-only things, like deleting organizations
      dev: {
        name: 'Developer',
        includeAll: false,
        include: [
          OrganizationPermission.ORGANIZATION_UPDATE,
          OrganizationPermission.MODEMS,
          OrganizationPermission.MODEMS_CREATE,
          OrganizationPermission.MODEMS_UPDATE,
          OrganizationPermission.MODEMS_LICENSE_KEYS,
          OrganizationPermission.MODEMS_MESSAGE_BODY_PARSERS,
          OrganizationPermission.MODEMS_SECURE_NOTES,
          OrganizationPermission.MODEMS_ALARMS,
          OrganizationPermission.MODEM_VALUES,
          OrganizationPermission.MODEM_MESSAGES,
          OrganizationPermission.MODEM_MESSAGES_SEND_TEST_MESSAGES,
          OrganizationPermission.MODEM_MESSAGES_SEND_REAL_MESSAGES,
          OrganizationPermission.MODEM_DOWNLINK_MESSAGES,
          OrganizationPermission.TRANSFERS,
          OrganizationPermission.TRANSFERS_SEND,
          OrganizationPermission.USERS,
          OrganizationPermission.PUBLISHERS,
          OrganizationPermission.TOKENS,
          OrganizationPermission.CERTIFICATES,
          OrganizationPermission.CERTIFICATES_MANAGE,
          OrganizationPermission.ASSIGNMENTS,
          OrganizationPermission.SIMULATION_MANAGE,
          OrganizationPermission.HEALTH_MANAGE,
          OrganizationPermission.LOCATIONS_MANAGE,
          OrganizationPermission.EXPORT,
        ],
      },
      // typical user permissions to see things and make some limited changes
      member: {
        name: 'Member',
        includeAll: false,
        include: [
          OrganizationPermission.MODEMS,
          OrganizationPermission.MODEMS_UPDATE,
          OrganizationPermission.MODEM_VALUES,
          OrganizationPermission.MODEM_MESSAGES,
          OrganizationPermission.TRANSFERS,
          OrganizationPermission.TRANSFERS_SEND,
          OrganizationPermission.ASSIGNMENTS,
          OrganizationPermission.USERS,
          OrganizationPermission.EXPORT,
        ],
      },
      // read only basic information
      guest: {
        name: 'Guest',
        includeAll: false,
        include: [
          OrganizationPermission.MODEMS,
          OrganizationPermission.MODEM_VALUES,
          OrganizationPermission.MODEM_MESSAGES,
          OrganizationPermission.TRANSFERS,
          OrganizationPermission.USERS,
        ],
      },
    },
    serviceSettings: {
      lang: 'en',
      rangeFormat: 'MMMM Y',
      dateFormat: 'D MMM \'YY',
    },
    login: {
      showNow: null,
    },
    roles: [],
  },
  actions: {
    setUser(context) {
      return new Promise((resolve) => {
        const firebaseUser = Firebase.auth().currentUser;
        context.commit('setUser', firebaseUser);
        resolve();
      });
    },
    setRoles(context, roles) {
      context.commit('setRoles', roles);
    },
    getCurrentUser(context, forceReload) {
      return new Promise((resolve, reject) => {
        const stateUserId = context.state.currentUser.id;
        const firebaseUserId = Firebase.auth().currentUser.uid;
        if ((stateUserId !== '' && stateUserId === firebaseUserId) && !forceReload) {
          // Already loaded current user
          resolve(context.state.currentUser);
        } else {
          getCurrentUser().then((res) => {
            context.commit('getCurrentUser', res);
            context.dispatch('getUsersOrgPermissions');
            context.dispatch('getUserInitials');

            if (res.missionControlSettings) {
              const parse = JSON.parse(res.missionControlSettings);
              context.dispatch('applyUserSettings', parse);
            }
            resolve(res);
          }).catch(res => defaultErrorHandler(res, reject, context));
        }
      });
    },
    getOrganizationInvites(context) {
      return new Promise((resolve, reject) => {
        getOrganizationInvites().then((res) => {
          context.commit('getOrganizationInvites', res);
          resolve(res);
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    acceptOrganizationInvite(context, payload) {
      return new Promise((resolve, reject) => {
        acceptOrganizationInvite(
          payload.organization,
          payload.setAsDefault || false,
        ).then((res) => {
          context.commit('acceptOrganizationInvite');
          resolve(res);
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    getUserInitials(context) {
      let res = '';
      const name = latinize(context.state.currentUser.name);

      if (name.length) {
        const initials = name.match(/\b\w/g) || [];
        res = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      } else {
        res = context.state.currentUser.email.charAt(0).toUpperCase();
      }

      context.commit('getUserInitials', res);
    },
    requestOrganizationRequest(context, organization) {
      const logMessage = {
        category: 'Access for',
        name: organization.organization,
        action: 'requested',
      };
      const payload = {
        ...organization,
      };

      return new Promise((resolve, reject) => {
        requestUserAccessRequest(payload.organization).then((res) => {
          successHandler(res, resolve, context, logMessage);
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    getUsers(context, target) {
      return new Promise((resolve, reject) => {
        getUsers(
          context.state.usersSearch,
          target ? target.pagination.size : 100,
          target ? target.pagination.page : 0,
        ).then((res) => {
          context.commit('getUsers', res);
          resolve();
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    searchUsers(context, target) {
      const payload = {
        ...target,
      };

      return new Promise((resolve, reject) => {
        getUsers(
          payload.search,
        ).then((res) => {
          resolve(res);
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    createUser(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.name && payload.email && payload.roles) {
          createUser(
            payload.name,
            payload.email,
            payload.password,
            payload.roles,
            payload.sendPasswordResetMail,
            payload.sendVerificationMail,
          ).then((res) => {
            context.commit('createUser', res);
            resolve(res);
          }).catch(res => defaultErrorHandler(res, reject, context));
        } else {
          defaultErrorHandler({ statusMessage: 'Invalid submission' }, reject, context);
        }
      });
    },
    inviteUser(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.email && payload.roles) {
          inviteUser(
            payload.email,
            payload.roles,
          ).then((res) => {
            context.commit('inviteUser', res);
            resolve(res);
          }).catch(res => defaultErrorHandler(res, reject, context));
        } else {
          defaultErrorHandler({ statusMessage: 'Invalid submission' }, reject, context);
        }
      });
    },
    getInvitedUsers(context) {
      return new Promise((resolve, reject) => {
        getInvitedUsers().then((res) => {
          context.commit('getInvitedUsers', res);
          resolve();
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    getRequestedUsers(context) {
      return new Promise((resolve, reject) => {
        getRequestedUsers().then((res) => {
          context.commit('getRequestedUsers', res);
          resolve();
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    approveUserRequests(context, organization, users, roles) {
      const payload = {
        ...organization,
        ...users,
        ...roles,
      };

      return new Promise((resolve, reject) => {
        approveRequestedUsers(
          payload.organization,
          payload.users,
          payload.roles,
        ).then(() => {
          // context.commit('approveUserRequests', res);
          resolve();
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    declineUserRequests(context, id) {
      return new Promise((resolve, reject) => {
        declineRequestedUsers(id).then(() => {
          resolve();
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    getUsersOrgPermissions(context) {
      return new Promise((resolve) => {
        const result = {
          includeAll: context.state.currentUser.currentOrganizationPermissions.includeAll,
          include: context.state.currentUser.currentOrganizationPermissions.include,
          exclude: context.state.currentUser.currentOrganizationPermissions.exclude,
        };

        context.commit('setUsersOrgPermissions', result);
        resolve(result);
      });
    },
    updateUserSettings(context, payload) {
      const { hideNotification, ...rest } = payload;
      const logMessage = {
        category: 'User settings',
        name: '',
        action: 'updated',
      };
      return new Promise((resolve, reject) => {
        updateUserSettings(rest).then((res) => {
          context.dispatch('applyUserSettings', rest);
          if (!hideNotification) {
            successHandler(res, resolve, context, logMessage);
          }
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    acceptTermsAndConditions(context, accepted) {
      return new Promise((resolve, reject) => {
        acceptTermsAndConditions(accepted).then((res) => {
          resolve(res);
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    applyUserSettings(context, payload) {
      if (payload.settings) {
        let time = 'HH:mm';
        if (payload.settings.timeformat) {
          if (payload.settings.timeformat === 'twelve') {
            time = 'hh:mm a';
          } else if (payload.settings.timeformat === 'twentyfour') {
            time = 'HH:mm';
          }
        }

        let date = 'D MMM, \'YY';
        if (payload.settings.dateformat) {
          if (payload.settings.dateformat === 'daymonth') {
            date = 'D MMM, \'YY';
          } else if (payload.settings.dateformat === 'monthday') {
            date = 'MMM D, \'YY';
          }
        }

        const newTimestamp = `${date} ${time}`;
        context.commit('App/updateTimestamp', newTimestamp, { root: true });
        context.commit('updateUserSettings', payload);
      }
    },
  },
  mutations: {
    setShowNow(state, res) {
      state.login.showNow = res;
    },
    setUser(state, res) {
      state.user = res;
    },
    setUserToken(state, res) {
      state.userToken = res;
    },
    createUser() {
      // Logging only
    },
    updateUserPermissions() {
      // Logging only
    },
    inviteUser() {
      // Logging only
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    getCurrentUser(state, res) {
      state.currentUser = res;
    },
    getOrganizationInvites(state, res) {
      state.organizationInvites = res;
    },
    acceptOrganizationInvite() {
      // Logging only
    },
    getUserInitials(state, res) {
      state.userInitials = res;
    },
    updateUsersSearch(state, res) {
      state.usersSearch = res;
    },
    getUsers(state, res) {
      state.users = res;
    },
    getInvitedUsers(state, res) {
      state.invitedUsers = res;
    },
    getRequestedUsers(state, res) {
      state.requestedUsers = res;
    },
    setUsersOrgPermissions(state, res) {
      state.usersOrgPermissions = res;
    },
    updateUserSettings(state, res) {
      // Use dispatch to save the setting
      state.missionControlSettings = { ...state.missionControlSettings, ...res };
    },
  },
  getters: {
    getUserById(state) {
      return (id) => {
        if (typeof state.users.users === 'object') {
          const userById = state.users.users.find(u => u.id === id);
          if (userById) {
            return userById;
          }
        }
        return false;
      };
    },
    hasUserGotOrganizations(state) {
      return state.currentUser.organizations.length >= 1;
    },

    hasSupportPermissionId(state) {
      return (permissionId) => {
        const grantedSupportPermissions = state.currentUser.supportPermissions;

        const included = grantedSupportPermissions.include?.includes(permissionId);
        const excluded = grantedSupportPermissions.exclude?.includes(permissionId);

        return (grantedSupportPermissions.includeAll || included) && !excluded;
      };
    },

    hasOrganizationPermissionId(state) {
      return (permissionId) => {
        const grantedOrganizationPermissions = state.usersOrgPermissions;

        const included = grantedOrganizationPermissions.include?.includes(permissionId);
        const excluded = grantedOrganizationPermissions.exclude?.includes(permissionId);

        return (grantedOrganizationPermissions.includeAll || included) && !excluded;
      };
    },
    hasOrganizationPermissionIds(state) {
      return (permissionIds) => {
        // always true for en empty set
        if (permissionIds === undefined || !permissionIds.length) {
          return true;
        }

        const grantedOrganizationPermissions = state.usersOrgPermissions;

        const included = permissionIds.every(val => grantedOrganizationPermissions.include?.includes(val));
        const excluded = permissionIds.some(val => grantedOrganizationPermissions.exclude?.includes(val));

        return (grantedOrganizationPermissions.includeAll || included) && !excluded;
      };
    },
    getSettingsValue(state) {
      return (key) => {
        let returnValue;

        if (Object.keys(state.missionControlSettings.settings).length) {
          if (typeof state.missionControlSettings.settings[key] === 'string'
            || typeof state.missionControlSettings.settings[key] === 'number') {
            returnValue = state.missionControlSettings.settings[key];
          } else {
            returnValue = state.defaultUserSettings[key];
          }
        } else {
          returnValue = state.defaultUserSettings[key];
        }

        return returnValue;
      };
    },
    getSettingsBool(state) {
      return (key, value) => {
        let returnBool;

        if (Object.keys(state.missionControlSettings.settings).length) {
          if (typeof state.missionControlSettings.settings[key] === 'string') {
            returnBool = value === state.missionControlSettings.settings[key];
          } else if (key === 'defaultscreen') {
            const currentOrg = state.currentUser.currentOrganization;
            returnBool = value === state.missionControlSettings.settings.defaultscreen[currentOrg];
          } else {
            returnBool = value === state.defaultUserSettings[key];
          }
        }

        return returnBool;
      };
    },
    getDefaultUserView(state) {
      const currentOrg = state.currentUser.currentOrganization;
      return state.missionControlSettings?.settings?.defaultscreen?.[currentOrg] || null;
    },
  },
};

export default User;
