import { defaultErrorHandler } from '@/store/core/utils';
import { NO_PREFERENCE_OPTION, UNIT_TO_OPTIONS, unitPreferences } from '@/services/unit_preferences';

const findSelectedOption = (unit, value) => UNIT_TO_OPTIONS[unit].options.find((o) => o.value === value);
const UnitPreferences = {
  namespaced: true,
  actions: {
    getUnitPreferences(context) {
      return new Promise((resolve, reject) => {
        unitPreferences().then((res) => {
          const { preferences } = res;
          const response = Object.keys(UNIT_TO_OPTIONS).reduce((acc, unit) => {
            let selected = NO_PREFERENCE_OPTION;

            const selectedPreferenceObj = preferences[`${unit}Preference`]; // the key in the response has "Preference" suffix
            if (selectedPreferenceObj?.preference) {
              selected = findSelectedOption(unit, selectedPreferenceObj.preference);
            }

            return {
              ...acc,
              [unit]: {
                ...UNIT_TO_OPTIONS[unit],
                selected,
              },
            };
          }, {});
          resolve(response);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    updateUnit(context, payload) {
      return new Promise((resolve, reject) => {
        unitPreferences(payload).then((res) => {
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
};

export default UnitPreferences;
