/**
 * This is a helper class for table getters.
 * This has a lot of often used methods ready, but does not include things that would mutate the store.
 * To mutate the store, call the tables.js actions directly.
 *
 * Usage:
 *  const table = this.$store.getters['Tables/get']('table-name');
 * This returns a Table instance with the 'table-name' state ready to use.
 *
 * From there on out, you can call things from 'table' variable
 *  table.getSelectionAmount()
 *  table.isSelected();
 *  etc.
 */

class Table {
  hasBeenInitialised: boolean = false;

  name: string = '';

  rowUid: string = '';

  selected: string[] = [];

  selectedAll: boolean = false;

  selectedData: Object = {}; // Stores data by rowUid

  constructor(name = '') {
    this.name = name;
  }

  // Apply a vuex-stored state
  __applyState(state: any) {
    this.hasBeenInitialised = true;

    this.rowUid = state.rowUid;
    this.selected = state.selected;
    this.selectedAll = state.selectedAll;
    this.selectedData = state.selectedData;
  }

  /**
   * Tables store only the 'rowUid' of the rows. To get the data, use this function.
   * Usage:
   *  const table = this.$store.getters['Tables/get']('mojo/journey'); (for instance as computed property)
   *  const originData = this.$store.state.Modem.modems.modems; (used in creating the rows of the table)
   *
   *  const rowsToGetDataFrom = ['0005 0D77', '0006 7D19']; // OPTIONAL - By default will use table selection
   *  const rowUid = 'number'; // OPTIONAL - By default will use table settings
   *
   *  const rowData = table.getDataFromOrigin(originData, (rowsToGetDataFrom?), (rowUid?));
   */
  getDataFromOrigin(origin: any[], uids?: string[], rowUidName?: string) {
    const selection = uids || this.selected;
    const key = rowUidName || this.rowUid;
    // Go through the origin list. Filter the rows where the row[key] is in the uids array (index >= 0).
    return origin.filter((row: any) => selection.findIndex((el: any) => el === row[key]) >= 0);
  }

  // If rowUid is selected
  isSelected(rowQueryUid: string) {
    const rowIndex = this.selected.findIndex((rowUid: string) => rowUid === rowQueryUid);

    // If selected, rowIndex >= 0, and it's also selected when everything is.
    return rowIndex !== -1 || this.selectedAll;
  }

  // If anything is selected, incl. selectedAll
  hasSelection() {
    return (this.selected.length > 0 || this.selectedAll);
  }

  // Returns the amount of selected items, or 'all' if everything is selected
  getSelectionAmount() {
    if (this.selectedAll) {
      return 'all';
    }
    return this.selected.length;
  }

  // Modifies a term based on selection amount
  getTermPluralisedBySelection(term: string) {
    if (this.selectedAll) {
      return `${term}s`;
    }
    return this.selected.length !== 1 ? `${term}s` : term;
  }
}

export default Table;
