/* eslint-disable */

export const protobufPackage = "hiber";

/** Permissions limit what a user can do through the API. */

/**
 * OrganizationPermissions limit what a user can do in an Organization.
 * By default, everyone who has access to an organization, can access basic organization data.
 */
export enum OrganizationPermission {
  PERMISSION_DEFAULT = "PERMISSION_DEFAULT",
  /** ORGANIZATION_CREATE - Create a new child organization. */
  ORGANIZATION_CREATE = "ORGANIZATION_CREATE",
  /** ORGANIZATION_UPDATE - Update the organizations data, such as billing information, address, and contact. */
  ORGANIZATION_UPDATE = "ORGANIZATION_UPDATE",
  /**
   * ORGANIZATION_DELETE - Delete child organizations.
   * You cannot delete your own organization. To delete your organization, contact support.
   */
  ORGANIZATION_DELETE = "ORGANIZATION_DELETE",
  /** ASSETS - List assets, see their details */
  ASSETS = "ASSETS",
  /** ASSETS_UPDATE - Create and update assets, such as their display name and tags. Includes ASSETS permission. */
  ASSETS_UPDATE = "ASSETS_UPDATE",
  /** ASSETS_VALUES - Read asset values, i.e. pressure measurements. */
  ASSETS_VALUES = "ASSETS_VALUES",
  /** MODEMS - List modems, see their details and health */
  MODEMS = "MODEMS",
  /** MODEMS_CREATE - Create new modems. Includes MODEMS permission. */
  MODEMS_CREATE = "MODEMS_CREATE",
  /** MODEMS_UPDATE - Update modems, such as their peripherals, display name and tags. Includes MODEMS permission. */
  MODEMS_UPDATE = "MODEMS_UPDATE",
  /** MODEMS_LICENSE_KEYS - Show and regenerate license keys. Includes MODEMS permission. */
  MODEMS_LICENSE_KEYS = "MODEMS_LICENSE_KEYS",
  /** MODEMS_MESSAGE_BODY_PARSERS - Manage and assign message body parsers. Includes MODEMS permission. */
  MODEMS_MESSAGE_BODY_PARSERS = "MODEMS_MESSAGE_BODY_PARSERS",
  /** MODEMS_SECURE_NOTES - Show and update secure notes. Includes MODEMS permission. */
  MODEMS_SECURE_NOTES = "MODEMS_SECURE_NOTES",
  /** MODEMS_ALARMS - View and assign (with the ASSIGNMENTS permission) alarms. Includes MODEMS permission. */
  MODEMS_ALARMS = "MODEMS_ALARMS",
  /** MODEMS_ALARMS_UPDATE - Manage alarms. Includes MODEMS permission. */
  MODEMS_ALARMS_UPDATE = "MODEMS_ALARMS_UPDATE",
  /** MODEM_VALUES - Read modem values. */
  MODEM_VALUES = "MODEM_VALUES",
  /** MODEM_MESSAGES - Read modem messages. */
  MODEM_MESSAGES = "MODEM_MESSAGES",
  /** MODEM_MESSAGES_SEND_TEST_MESSAGES - Send modem messages using the TestingService. Does not include MODEMS or MESSAGES permission. */
  MODEM_MESSAGES_SEND_TEST_MESSAGES = "MODEM_MESSAGES_SEND_TEST_MESSAGES",
  /** MODEM_MESSAGES_SEND_REAL_MESSAGES - Send modem messages using real message sources (i.e. gateway). Does not include MODEMS or MESSAGES permission. */
  MODEM_MESSAGES_SEND_REAL_MESSAGES = "MODEM_MESSAGES_SEND_REAL_MESSAGES",
  /** MODEM_DOWNLINK_MESSAGES - Send modem downlink messages. */
  MODEM_DOWNLINK_MESSAGES = "MODEM_DOWNLINK_MESSAGES",
  /** TRANSFERS - See the transfers to/from other organizations. */
  TRANSFERS = "TRANSFERS",
  /** TRANSFERS_SEND - Transfer devices to another organization. */
  TRANSFERS_SEND = "TRANSFERS_SEND",
  /** USERS - List all users, see their names and email addresses. */
  USERS = "USERS",
  /** USERS_MANAGE - Approve or create new users, remove users from the organization. Includes users permission. */
  USERS_MANAGE = "USERS_MANAGE",
  /** PUBLISHERS - Manage publishers: webhooks, MQTT integration and custom email publishers. */
  PUBLISHERS = "PUBLISHERS",
  /** TOKENS - Manage tokens. */
  TOKENS = "TOKENS",
  /** CERTIFICATES - Read and use uploaded certificates (i.e. for publishers). */
  CERTIFICATES = "CERTIFICATES",
  /** CERTIFICATES_MANAGE - Upload certificates, and update or delete uploaded certificates. */
  CERTIFICATES_MANAGE = "CERTIFICATES_MANAGE",
  /** ASSIGNMENTS - Manage assignments using the assignment services and individual calls on, for example, ModemService. */
  ASSIGNMENTS = "ASSIGNMENTS",
  /** SIMULATION_MANAGE - Manage simulations, like modem message simulation. */
  SIMULATION_MANAGE = "SIMULATION_MANAGE",
  /** HEALTH_MANAGE - Manage custom health levels. */
  HEALTH_MANAGE = "HEALTH_MANAGE",
  /** LOCATIONS_MANAGE - Manage saved locations using the OrganizationLocationService. */
  LOCATIONS_MANAGE = "LOCATIONS_MANAGE",
  /** EXPORT - Create, access and download exports. */
  EXPORT = "EXPORT",
  /** TAGS_MANAGE - Create, edit and delete tags. Viewing tags is always allowed. */
  TAGS_MANAGE = "TAGS_MANAGE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function organizationPermissionFromJSON(object: any): OrganizationPermission {
  switch (object) {
    case 0:
    case "PERMISSION_DEFAULT":
      return OrganizationPermission.PERMISSION_DEFAULT;
    case 1:
    case "ORGANIZATION_CREATE":
      return OrganizationPermission.ORGANIZATION_CREATE;
    case 2:
    case "ORGANIZATION_UPDATE":
      return OrganizationPermission.ORGANIZATION_UPDATE;
    case 3:
    case "ORGANIZATION_DELETE":
      return OrganizationPermission.ORGANIZATION_DELETE;
    case 53:
    case "ASSETS":
      return OrganizationPermission.ASSETS;
    case 54:
    case "ASSETS_UPDATE":
      return OrganizationPermission.ASSETS_UPDATE;
    case 55:
    case "ASSETS_VALUES":
      return OrganizationPermission.ASSETS_VALUES;
    case 10:
    case "MODEMS":
      return OrganizationPermission.MODEMS;
    case 50:
    case "MODEMS_CREATE":
      return OrganizationPermission.MODEMS_CREATE;
    case 11:
    case "MODEMS_UPDATE":
      return OrganizationPermission.MODEMS_UPDATE;
    case 12:
    case "MODEMS_LICENSE_KEYS":
      return OrganizationPermission.MODEMS_LICENSE_KEYS;
    case 43:
    case "MODEMS_MESSAGE_BODY_PARSERS":
      return OrganizationPermission.MODEMS_MESSAGE_BODY_PARSERS;
    case 42:
    case "MODEMS_SECURE_NOTES":
      return OrganizationPermission.MODEMS_SECURE_NOTES;
    case 44:
    case "MODEMS_ALARMS":
      return OrganizationPermission.MODEMS_ALARMS;
    case 56:
    case "MODEMS_ALARMS_UPDATE":
      return OrganizationPermission.MODEMS_ALARMS_UPDATE;
    case 17:
    case "MODEM_VALUES":
      return OrganizationPermission.MODEM_VALUES;
    case 15:
    case "MODEM_MESSAGES":
      return OrganizationPermission.MODEM_MESSAGES;
    case 16:
    case "MODEM_MESSAGES_SEND_TEST_MESSAGES":
      return OrganizationPermission.MODEM_MESSAGES_SEND_TEST_MESSAGES;
    case 41:
    case "MODEM_MESSAGES_SEND_REAL_MESSAGES":
      return OrganizationPermission.MODEM_MESSAGES_SEND_REAL_MESSAGES;
    case 51:
    case "MODEM_DOWNLINK_MESSAGES":
      return OrganizationPermission.MODEM_DOWNLINK_MESSAGES;
    case 20:
    case "TRANSFERS":
      return OrganizationPermission.TRANSFERS;
    case 21:
    case "TRANSFERS_SEND":
      return OrganizationPermission.TRANSFERS_SEND;
    case 30:
    case "USERS":
      return OrganizationPermission.USERS;
    case 31:
    case "USERS_MANAGE":
      return OrganizationPermission.USERS_MANAGE;
    case 35:
    case "PUBLISHERS":
      return OrganizationPermission.PUBLISHERS;
    case 36:
    case "TOKENS":
      return OrganizationPermission.TOKENS;
    case 38:
    case "CERTIFICATES":
      return OrganizationPermission.CERTIFICATES;
    case 39:
    case "CERTIFICATES_MANAGE":
      return OrganizationPermission.CERTIFICATES_MANAGE;
    case 45:
    case "ASSIGNMENTS":
      return OrganizationPermission.ASSIGNMENTS;
    case 46:
    case "SIMULATION_MANAGE":
      return OrganizationPermission.SIMULATION_MANAGE;
    case 47:
    case "HEALTH_MANAGE":
      return OrganizationPermission.HEALTH_MANAGE;
    case 48:
    case "LOCATIONS_MANAGE":
      return OrganizationPermission.LOCATIONS_MANAGE;
    case 49:
    case "EXPORT":
      return OrganizationPermission.EXPORT;
    case 52:
    case "TAGS_MANAGE":
      return OrganizationPermission.TAGS_MANAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrganizationPermission.UNRECOGNIZED;
  }
}

export function organizationPermissionToNumber(object: OrganizationPermission): number {
  switch (object) {
    case OrganizationPermission.PERMISSION_DEFAULT:
      return 0;
    case OrganizationPermission.ORGANIZATION_CREATE:
      return 1;
    case OrganizationPermission.ORGANIZATION_UPDATE:
      return 2;
    case OrganizationPermission.ORGANIZATION_DELETE:
      return 3;
    case OrganizationPermission.ASSETS:
      return 53;
    case OrganizationPermission.ASSETS_UPDATE:
      return 54;
    case OrganizationPermission.ASSETS_VALUES:
      return 55;
    case OrganizationPermission.MODEMS:
      return 10;
    case OrganizationPermission.MODEMS_CREATE:
      return 50;
    case OrganizationPermission.MODEMS_UPDATE:
      return 11;
    case OrganizationPermission.MODEMS_LICENSE_KEYS:
      return 12;
    case OrganizationPermission.MODEMS_MESSAGE_BODY_PARSERS:
      return 43;
    case OrganizationPermission.MODEMS_SECURE_NOTES:
      return 42;
    case OrganizationPermission.MODEMS_ALARMS:
      return 44;
    case OrganizationPermission.MODEMS_ALARMS_UPDATE:
      return 56;
    case OrganizationPermission.MODEM_VALUES:
      return 17;
    case OrganizationPermission.MODEM_MESSAGES:
      return 15;
    case OrganizationPermission.MODEM_MESSAGES_SEND_TEST_MESSAGES:
      return 16;
    case OrganizationPermission.MODEM_MESSAGES_SEND_REAL_MESSAGES:
      return 41;
    case OrganizationPermission.MODEM_DOWNLINK_MESSAGES:
      return 51;
    case OrganizationPermission.TRANSFERS:
      return 20;
    case OrganizationPermission.TRANSFERS_SEND:
      return 21;
    case OrganizationPermission.USERS:
      return 30;
    case OrganizationPermission.USERS_MANAGE:
      return 31;
    case OrganizationPermission.PUBLISHERS:
      return 35;
    case OrganizationPermission.TOKENS:
      return 36;
    case OrganizationPermission.CERTIFICATES:
      return 38;
    case OrganizationPermission.CERTIFICATES_MANAGE:
      return 39;
    case OrganizationPermission.ASSIGNMENTS:
      return 45;
    case OrganizationPermission.SIMULATION_MANAGE:
      return 46;
    case OrganizationPermission.HEALTH_MANAGE:
      return 47;
    case OrganizationPermission.LOCATIONS_MANAGE:
      return 48;
    case OrganizationPermission.EXPORT:
      return 49;
    case OrganizationPermission.TAGS_MANAGE:
      return 52;
    case OrganizationPermission.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * UserPermissions are generally used to limit what a token can do to its user.
 * By default, a user has all UserPermissions, which apply only to himself.
 * When creating a token, however, the token typically does not need permission to affect the user at all.
 */
export enum UserPermission {
  /** READ - Read your name, email, linked organizations and mission-control settings. */
  READ = "READ",
  /** UPDATE - Update your personal information, mission-control settings and default organization. Includes read permission. */
  UPDATE = "UPDATE",
  /** REQUEST_ACCESS - Request access to an organization and cancel open requests. Does not include read permission. */
  REQUEST_ACCESS = "REQUEST_ACCESS",
  /** DELETE - Delete your user account permanently. Includes read permission. */
  DELETE = "DELETE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function userPermissionFromJSON(object: any): UserPermission {
  switch (object) {
    case 0:
    case "READ":
      return UserPermission.READ;
    case 1:
    case "UPDATE":
      return UserPermission.UPDATE;
    case 2:
    case "REQUEST_ACCESS":
      return UserPermission.REQUEST_ACCESS;
    case 3:
    case "DELETE":
      return UserPermission.DELETE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserPermission.UNRECOGNIZED;
  }
}

export function userPermissionToNumber(object: UserPermission): number {
  switch (object) {
    case UserPermission.READ:
      return 0;
    case UserPermission.UPDATE:
      return 1;
    case UserPermission.REQUEST_ACCESS:
      return 2;
    case UserPermission.DELETE:
      return 3;
    case UserPermission.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * SupportPermissions are used for features typically reserved for customer support, or that behave differently
 * when used by a customer support operator.
 */
export enum SupportPermission {
  /** CUSTOMER_SUPPORT - Allow Customer Support functions. */
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
  /** MANAGE_ORGANIZATION_FEATURE - Manage organization feature flags. */
  MANAGE_ORGANIZATION_FEATURE = "MANAGE_ORGANIZATION_FEATURE",
  /** MANAGE_DEVICE_TYPE - Change the device type for devices. */
  MANAGE_DEVICE_TYPE = "MANAGE_DEVICE_TYPE",
  /** CUSTOM_TAGS - Create and assign custom tags. */
  CUSTOM_TAGS = "CUSTOM_TAGS",
  /** INTEGRATION_SHELL_SSIP - Manage Shell SSIP integration. */
  INTEGRATION_SHELL_SSIP = "INTEGRATION_SHELL_SSIP",
  /** MANAGE_DEVICE_TRANSMISSION_INTERVAL - Manage device transmission interval (actual interval). */
  MANAGE_DEVICE_TRANSMISSION_INTERVAL = "MANAGE_DEVICE_TRANSMISSION_INTERVAL",
  /** MANAGE_DEVICE_SLA - Manage device SLA values (minimum messages per day). */
  MANAGE_DEVICE_SLA = "MANAGE_DEVICE_SLA",
  /** DEVICE_VIEW_ALL_LIFECYCLES - View all lifecycles. Without this permission, you can only see INSTALLED and PAUSED. */
  DEVICE_VIEW_ALL_LIFECYCLES = "DEVICE_VIEW_ALL_LIFECYCLES",
  /** MANAGE_DEVICE_NOTES - Assign device notes. */
  MANAGE_DEVICE_NOTES = "MANAGE_DEVICE_NOTES",
  /** MANAGE_DEVICE_TIME_ZONE - Assign device time zones. */
  MANAGE_DEVICE_TIME_ZONE = "MANAGE_DEVICE_TIME_ZONE",
  /** MANAGE_ORGANIZATION_CONTRACT - Manage organization contract info. */
  MANAGE_ORGANIZATION_CONTRACT = "MANAGE_ORGANIZATION_CONTRACT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function supportPermissionFromJSON(object: any): SupportPermission {
  switch (object) {
    case 0:
    case "CUSTOMER_SUPPORT":
      return SupportPermission.CUSTOMER_SUPPORT;
    case 1:
    case "MANAGE_ORGANIZATION_FEATURE":
      return SupportPermission.MANAGE_ORGANIZATION_FEATURE;
    case 2:
    case "MANAGE_DEVICE_TYPE":
      return SupportPermission.MANAGE_DEVICE_TYPE;
    case 3:
    case "CUSTOM_TAGS":
      return SupportPermission.CUSTOM_TAGS;
    case 4:
    case "INTEGRATION_SHELL_SSIP":
      return SupportPermission.INTEGRATION_SHELL_SSIP;
    case 5:
    case "MANAGE_DEVICE_TRANSMISSION_INTERVAL":
      return SupportPermission.MANAGE_DEVICE_TRANSMISSION_INTERVAL;
    case 6:
    case "MANAGE_DEVICE_SLA":
      return SupportPermission.MANAGE_DEVICE_SLA;
    case 7:
    case "DEVICE_VIEW_ALL_LIFECYCLES":
      return SupportPermission.DEVICE_VIEW_ALL_LIFECYCLES;
    case 8:
    case "MANAGE_DEVICE_NOTES":
      return SupportPermission.MANAGE_DEVICE_NOTES;
    case 9:
    case "MANAGE_DEVICE_TIME_ZONE":
      return SupportPermission.MANAGE_DEVICE_TIME_ZONE;
    case 10:
    case "MANAGE_ORGANIZATION_CONTRACT":
      return SupportPermission.MANAGE_ORGANIZATION_CONTRACT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SupportPermission.UNRECOGNIZED;
  }
}

export function supportPermissionToNumber(object: SupportPermission): number {
  switch (object) {
    case SupportPermission.CUSTOMER_SUPPORT:
      return 0;
    case SupportPermission.MANAGE_ORGANIZATION_FEATURE:
      return 1;
    case SupportPermission.MANAGE_DEVICE_TYPE:
      return 2;
    case SupportPermission.CUSTOM_TAGS:
      return 3;
    case SupportPermission.INTEGRATION_SHELL_SSIP:
      return 4;
    case SupportPermission.MANAGE_DEVICE_TRANSMISSION_INTERVAL:
      return 5;
    case SupportPermission.MANAGE_DEVICE_SLA:
      return 6;
    case SupportPermission.DEVICE_VIEW_ALL_LIFECYCLES:
      return 7;
    case SupportPermission.MANAGE_DEVICE_NOTES:
      return 8;
    case SupportPermission.MANAGE_DEVICE_TIME_ZONE:
      return 9;
    case SupportPermission.MANAGE_ORGANIZATION_CONTRACT:
      return 10;
    case SupportPermission.UNRECOGNIZED:
    default:
      return -1;
  }
}
