/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Area, Location, Pagination, Pagination_Result, Shape, Timestamp } from "./base";

export const protobufPackage = "hiber.organization.location";

/** A named location within an organization. */
export interface NamedLocation {
  /**
   * Names of locations are guaranteed to be unique per organization.
   * This means that the name can be used as a unique identifier for CRUD operations.
   */
  name: string;
  definition?:
    | { $case: "location"; location: Location }
    | { $case: "area"; area: Area }
    | { $case: "shape"; shape: Shape }
    | undefined;
  /** When the alarm was created. */
  createdAt:
    | Timestamp
    | undefined;
  /** When the alarm was updated. */
  updatedAt:
    | Timestamp
    | undefined;
  /** Alarms that have one or more location checks that reference this named location. */
  referencedByAlarms: NamedLocation_AlarmReference[];
}

/** Alarm check that uses the named location. */
export interface NamedLocation_AlarmReference {
  alarmIdentifier: string;
  description: string;
  check: string;
}

/** Look up named locations by name, or by overlap with a given selection. */
export interface NamedLocationSelection {
  /** Free text search in the named location names. */
  search?:
    | string
    | undefined;
  /** Select the named locations with the given names. */
  names: string[];
  /** Select all named locations that overlap with the given LocationOverlapSelection. */
  overlaps?:
    | LocationOverlapSelection
    | undefined;
  /** Select named locations that are used by any of the given alarm identifiers. */
  referencedByAlarms: string[];
}

/**
 * Selection for overlapping with (saved) locations.
 *
 * For example:
 * - Select everything that overlaps with named location "my-area":
 *     { overlaps = { saved = "my-area" } }
 * - Select everything that overlaps with a custom shape:
 *     { overlaps = { shape = { path = ... } } }
 * - Select everything that overlaps with the intersection of a named location and a custom area:
 *     { overlaps_all = [{ saved = "my-saved-location" }, { area = ... }] }
 * - Recursive selection using a combination of any and all by adding deeper selections:
 *     { overlaps_any = [{ overlap_all = { ... } }, { saved = { .. } }] }
 */
export interface LocationOverlapSelection {
  element?:
    | { $case: "location"; location: Location }
    | { $case: "area"; area: Area }
    | { $case: "shape"; shape: Shape }
    | { $case: "name"; name: string }
    | undefined;
  /**
   * Must overlap with all items.
   * If used in combination with element or overlaps_any, they must all match.
   */
  overlapsAll: LocationOverlapSelection[];
  /**
   * Must overlap with at least one of the items.
   * If used in combination with element or overlaps_all, they must all match.
   */
  overlapsAny: LocationOverlapSelection[];
}

export interface ListNamedLocations {
}

export interface ListNamedLocations_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection?: NamedLocationSelection | undefined;
  pagination?: Pagination | undefined;
}

export interface ListNamedLocations_Response {
  namedLocations: NamedLocation[];
  pagination: Pagination_Result | undefined;
  request: ListNamedLocations_Request | undefined;
}

export interface CreateNamedLocations {
}

export interface CreateNamedLocations_CreateNamedLocation {
  name: string;
  definition?: { $case: "location"; location: Location } | { $case: "area"; area: Area } | {
    $case: "shape";
    shape: Shape;
  } | undefined;
}

export interface CreateNamedLocations_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The locations to save. If the name for any of the given locations already exists, the request fails. */
  create: CreateNamedLocations_CreateNamedLocation[];
  /** @deprecated */
  deprecatedCreate: NamedLocation[];
}

export interface CreateNamedLocations_Response {
  added: NamedLocation[];
}

/** Update for a named location. */
export interface UpdateNamedLocation {
  /** If not empty, replaces the name of the location. */
  updateName: string;
  replaceDefinition?: { $case: "location"; location: Location } | { $case: "area"; area: Area } | {
    $case: "shape";
    shape: Shape;
  } | undefined;
}

export interface UpdateNamedLocation_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The name of the named location to update. */
  name: string;
  /** The update for the named location. */
  updated: UpdateNamedLocation | undefined;
}

export interface UpdateNamedLocation_Response {
  updated: NamedLocation | undefined;
}

/**
 * Delete a named location.
 * Note that a named location that is referenced by one or more alarm location checks cannot be deleted without
 * deleting those alarm checks first. See the AlarmService for more information.
 */
export interface DeleteNamedLocation {
}

export interface DeleteNamedLocation_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The name of the named location to delete. */
  name: string;
}

export interface DeleteNamedLocation_Response {
}

function createBaseNamedLocation(): NamedLocation {
  return { name: "", definition: undefined, createdAt: undefined, updatedAt: undefined, referencedByAlarms: [] };
}

export const NamedLocation = {
  encode(message: NamedLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    switch (message.definition?.$case) {
      case "location":
        Location.encode(message.definition.location, writer.uint32(18).fork()).ldelim();
        break;
      case "area":
        Area.encode(message.definition.area, writer.uint32(26).fork()).ldelim();
        break;
      case "shape":
        Shape.encode(message.definition.shape, writer.uint32(34).fork()).ldelim();
        break;
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.referencedByAlarms) {
      NamedLocation_AlarmReference.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedLocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.definition = { $case: "location", location: Location.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.definition = { $case: "area", area: Area.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.definition = { $case: "shape", shape: Shape.decode(reader, reader.uint32()) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.referencedByAlarms.push(NamedLocation_AlarmReference.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<NamedLocation>): NamedLocation {
    return NamedLocation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<NamedLocation>): NamedLocation {
    const message = createBaseNamedLocation();
    message.name = object.name ?? "";
    if (
      object.definition?.$case === "location" &&
      object.definition?.location !== undefined &&
      object.definition?.location !== null
    ) {
      message.definition = { $case: "location", location: Location.fromPartial(object.definition.location) };
    }
    if (
      object.definition?.$case === "area" && object.definition?.area !== undefined && object.definition?.area !== null
    ) {
      message.definition = { $case: "area", area: Area.fromPartial(object.definition.area) };
    }
    if (
      object.definition?.$case === "shape" &&
      object.definition?.shape !== undefined &&
      object.definition?.shape !== null
    ) {
      message.definition = { $case: "shape", shape: Shape.fromPartial(object.definition.shape) };
    }
    message.createdAt = (object.createdAt !== undefined && object.createdAt !== null)
      ? Timestamp.fromPartial(object.createdAt)
      : undefined;
    message.updatedAt = (object.updatedAt !== undefined && object.updatedAt !== null)
      ? Timestamp.fromPartial(object.updatedAt)
      : undefined;
    message.referencedByAlarms = object.referencedByAlarms?.map((e) => NamedLocation_AlarmReference.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseNamedLocation_AlarmReference(): NamedLocation_AlarmReference {
  return { alarmIdentifier: "", description: "", check: "" };
}

export const NamedLocation_AlarmReference = {
  encode(message: NamedLocation_AlarmReference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.alarmIdentifier !== "") {
      writer.uint32(10).string(message.alarmIdentifier);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.check !== "") {
      writer.uint32(26).string(message.check);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedLocation_AlarmReference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedLocation_AlarmReference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.alarmIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.check = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<NamedLocation_AlarmReference>): NamedLocation_AlarmReference {
    return NamedLocation_AlarmReference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<NamedLocation_AlarmReference>): NamedLocation_AlarmReference {
    const message = createBaseNamedLocation_AlarmReference();
    message.alarmIdentifier = object.alarmIdentifier ?? "";
    message.description = object.description ?? "";
    message.check = object.check ?? "";
    return message;
  },
};

function createBaseNamedLocationSelection(): NamedLocationSelection {
  return { search: undefined, names: [], overlaps: undefined, referencedByAlarms: [] };
}

export const NamedLocationSelection = {
  encode(message: NamedLocationSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    if (message.overlaps !== undefined) {
      LocationOverlapSelection.encode(message.overlaps, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.referencedByAlarms) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedLocationSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedLocationSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.overlaps = LocationOverlapSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.referencedByAlarms.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<NamedLocationSelection>): NamedLocationSelection {
    return NamedLocationSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<NamedLocationSelection>): NamedLocationSelection {
    const message = createBaseNamedLocationSelection();
    message.search = object.search ?? undefined;
    message.names = object.names?.map((e) => e) || [];
    message.overlaps = (object.overlaps !== undefined && object.overlaps !== null)
      ? LocationOverlapSelection.fromPartial(object.overlaps)
      : undefined;
    message.referencedByAlarms = object.referencedByAlarms?.map((e) => e) || [];
    return message;
  },
};

function createBaseLocationOverlapSelection(): LocationOverlapSelection {
  return { element: undefined, overlapsAll: [], overlapsAny: [] };
}

export const LocationOverlapSelection = {
  encode(message: LocationOverlapSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.element?.$case) {
      case "location":
        Location.encode(message.element.location, writer.uint32(10).fork()).ldelim();
        break;
      case "area":
        Area.encode(message.element.area, writer.uint32(18).fork()).ldelim();
        break;
      case "shape":
        Shape.encode(message.element.shape, writer.uint32(26).fork()).ldelim();
        break;
      case "name":
        writer.uint32(34).string(message.element.name);
        break;
    }
    for (const v of message.overlapsAll) {
      LocationOverlapSelection.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.overlapsAny) {
      LocationOverlapSelection.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationOverlapSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationOverlapSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.element = { $case: "location", location: Location.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.element = { $case: "area", area: Area.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.element = { $case: "shape", shape: Shape.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.element = { $case: "name", name: reader.string() };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.overlapsAll.push(LocationOverlapSelection.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.overlapsAny.push(LocationOverlapSelection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LocationOverlapSelection>): LocationOverlapSelection {
    return LocationOverlapSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LocationOverlapSelection>): LocationOverlapSelection {
    const message = createBaseLocationOverlapSelection();
    if (
      object.element?.$case === "location" &&
      object.element?.location !== undefined &&
      object.element?.location !== null
    ) {
      message.element = { $case: "location", location: Location.fromPartial(object.element.location) };
    }
    if (object.element?.$case === "area" && object.element?.area !== undefined && object.element?.area !== null) {
      message.element = { $case: "area", area: Area.fromPartial(object.element.area) };
    }
    if (object.element?.$case === "shape" && object.element?.shape !== undefined && object.element?.shape !== null) {
      message.element = { $case: "shape", shape: Shape.fromPartial(object.element.shape) };
    }
    if (object.element?.$case === "name" && object.element?.name !== undefined && object.element?.name !== null) {
      message.element = { $case: "name", name: object.element.name };
    }
    message.overlapsAll = object.overlapsAll?.map((e) => LocationOverlapSelection.fromPartial(e)) || [];
    message.overlapsAny = object.overlapsAny?.map((e) => LocationOverlapSelection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListNamedLocations(): ListNamedLocations {
  return {};
}

export const ListNamedLocations = {
  encode(_: ListNamedLocations, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListNamedLocations {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListNamedLocations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListNamedLocations>): ListNamedLocations {
    return ListNamedLocations.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListNamedLocations>): ListNamedLocations {
    const message = createBaseListNamedLocations();
    return message;
  },
};

function createBaseListNamedLocations_Request(): ListNamedLocations_Request {
  return { organization: undefined, selection: undefined, pagination: undefined };
}

export const ListNamedLocations_Request = {
  encode(message: ListNamedLocations_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      NamedLocationSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListNamedLocations_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListNamedLocations_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = NamedLocationSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListNamedLocations_Request>): ListNamedLocations_Request {
    return ListNamedLocations_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListNamedLocations_Request>): ListNamedLocations_Request {
    const message = createBaseListNamedLocations_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? NamedLocationSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListNamedLocations_Response(): ListNamedLocations_Response {
  return { namedLocations: [], pagination: undefined, request: undefined };
}

export const ListNamedLocations_Response = {
  encode(message: ListNamedLocations_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.namedLocations) {
      NamedLocation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListNamedLocations_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListNamedLocations_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListNamedLocations_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.namedLocations.push(NamedLocation.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = ListNamedLocations_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListNamedLocations_Response>): ListNamedLocations_Response {
    return ListNamedLocations_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListNamedLocations_Response>): ListNamedLocations_Response {
    const message = createBaseListNamedLocations_Response();
    message.namedLocations = object.namedLocations?.map((e) => NamedLocation.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? ListNamedLocations_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseCreateNamedLocations(): CreateNamedLocations {
  return {};
}

export const CreateNamedLocations = {
  encode(_: CreateNamedLocations, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateNamedLocations {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateNamedLocations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateNamedLocations>): CreateNamedLocations {
    return CreateNamedLocations.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<CreateNamedLocations>): CreateNamedLocations {
    const message = createBaseCreateNamedLocations();
    return message;
  },
};

function createBaseCreateNamedLocations_CreateNamedLocation(): CreateNamedLocations_CreateNamedLocation {
  return { name: "", definition: undefined };
}

export const CreateNamedLocations_CreateNamedLocation = {
  encode(message: CreateNamedLocations_CreateNamedLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    switch (message.definition?.$case) {
      case "location":
        Location.encode(message.definition.location, writer.uint32(18).fork()).ldelim();
        break;
      case "area":
        Area.encode(message.definition.area, writer.uint32(26).fork()).ldelim();
        break;
      case "shape":
        Shape.encode(message.definition.shape, writer.uint32(34).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateNamedLocations_CreateNamedLocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateNamedLocations_CreateNamedLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.definition = { $case: "location", location: Location.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.definition = { $case: "area", area: Area.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.definition = { $case: "shape", shape: Shape.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateNamedLocations_CreateNamedLocation>): CreateNamedLocations_CreateNamedLocation {
    return CreateNamedLocations_CreateNamedLocation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateNamedLocations_CreateNamedLocation>): CreateNamedLocations_CreateNamedLocation {
    const message = createBaseCreateNamedLocations_CreateNamedLocation();
    message.name = object.name ?? "";
    if (
      object.definition?.$case === "location" &&
      object.definition?.location !== undefined &&
      object.definition?.location !== null
    ) {
      message.definition = { $case: "location", location: Location.fromPartial(object.definition.location) };
    }
    if (
      object.definition?.$case === "area" && object.definition?.area !== undefined && object.definition?.area !== null
    ) {
      message.definition = { $case: "area", area: Area.fromPartial(object.definition.area) };
    }
    if (
      object.definition?.$case === "shape" &&
      object.definition?.shape !== undefined &&
      object.definition?.shape !== null
    ) {
      message.definition = { $case: "shape", shape: Shape.fromPartial(object.definition.shape) };
    }
    return message;
  },
};

function createBaseCreateNamedLocations_Request(): CreateNamedLocations_Request {
  return { organization: undefined, create: [], deprecatedCreate: [] };
}

export const CreateNamedLocations_Request = {
  encode(message: CreateNamedLocations_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.create) {
      CreateNamedLocations_CreateNamedLocation.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.deprecatedCreate) {
      NamedLocation.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateNamedLocations_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateNamedLocations_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.create.push(CreateNamedLocations_CreateNamedLocation.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deprecatedCreate.push(NamedLocation.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateNamedLocations_Request>): CreateNamedLocations_Request {
    return CreateNamedLocations_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateNamedLocations_Request>): CreateNamedLocations_Request {
    const message = createBaseCreateNamedLocations_Request();
    message.organization = object.organization ?? undefined;
    message.create = object.create?.map((e) => CreateNamedLocations_CreateNamedLocation.fromPartial(e)) || [];
    message.deprecatedCreate = object.deprecatedCreate?.map((e) => NamedLocation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateNamedLocations_Response(): CreateNamedLocations_Response {
  return { added: [] };
}

export const CreateNamedLocations_Response = {
  encode(message: CreateNamedLocations_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.added) {
      NamedLocation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateNamedLocations_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateNamedLocations_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.added.push(NamedLocation.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateNamedLocations_Response>): CreateNamedLocations_Response {
    return CreateNamedLocations_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateNamedLocations_Response>): CreateNamedLocations_Response {
    const message = createBaseCreateNamedLocations_Response();
    message.added = object.added?.map((e) => NamedLocation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateNamedLocation(): UpdateNamedLocation {
  return { updateName: "", replaceDefinition: undefined };
}

export const UpdateNamedLocation = {
  encode(message: UpdateNamedLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updateName !== "") {
      writer.uint32(10).string(message.updateName);
    }
    switch (message.replaceDefinition?.$case) {
      case "location":
        Location.encode(message.replaceDefinition.location, writer.uint32(18).fork()).ldelim();
        break;
      case "area":
        Area.encode(message.replaceDefinition.area, writer.uint32(26).fork()).ldelim();
        break;
      case "shape":
        Shape.encode(message.replaceDefinition.shape, writer.uint32(34).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNamedLocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNamedLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updateName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.replaceDefinition = { $case: "location", location: Location.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.replaceDefinition = { $case: "area", area: Area.decode(reader, reader.uint32()) };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.replaceDefinition = { $case: "shape", shape: Shape.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateNamedLocation>): UpdateNamedLocation {
    return UpdateNamedLocation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateNamedLocation>): UpdateNamedLocation {
    const message = createBaseUpdateNamedLocation();
    message.updateName = object.updateName ?? "";
    if (
      object.replaceDefinition?.$case === "location" &&
      object.replaceDefinition?.location !== undefined &&
      object.replaceDefinition?.location !== null
    ) {
      message.replaceDefinition = {
        $case: "location",
        location: Location.fromPartial(object.replaceDefinition.location),
      };
    }
    if (
      object.replaceDefinition?.$case === "area" &&
      object.replaceDefinition?.area !== undefined &&
      object.replaceDefinition?.area !== null
    ) {
      message.replaceDefinition = { $case: "area", area: Area.fromPartial(object.replaceDefinition.area) };
    }
    if (
      object.replaceDefinition?.$case === "shape" &&
      object.replaceDefinition?.shape !== undefined &&
      object.replaceDefinition?.shape !== null
    ) {
      message.replaceDefinition = { $case: "shape", shape: Shape.fromPartial(object.replaceDefinition.shape) };
    }
    return message;
  },
};

function createBaseUpdateNamedLocation_Request(): UpdateNamedLocation_Request {
  return { organization: undefined, name: "", updated: undefined };
}

export const UpdateNamedLocation_Request = {
  encode(message: UpdateNamedLocation_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.updated !== undefined) {
      UpdateNamedLocation.encode(message.updated, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNamedLocation_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNamedLocation_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = UpdateNamedLocation.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateNamedLocation_Request>): UpdateNamedLocation_Request {
    return UpdateNamedLocation_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateNamedLocation_Request>): UpdateNamedLocation_Request {
    const message = createBaseUpdateNamedLocation_Request();
    message.organization = object.organization ?? undefined;
    message.name = object.name ?? "";
    message.updated = (object.updated !== undefined && object.updated !== null)
      ? UpdateNamedLocation.fromPartial(object.updated)
      : undefined;
    return message;
  },
};

function createBaseUpdateNamedLocation_Response(): UpdateNamedLocation_Response {
  return { updated: undefined };
}

export const UpdateNamedLocation_Response = {
  encode(message: UpdateNamedLocation_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== undefined) {
      NamedLocation.encode(message.updated, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNamedLocation_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNamedLocation_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updated = NamedLocation.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateNamedLocation_Response>): UpdateNamedLocation_Response {
    return UpdateNamedLocation_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateNamedLocation_Response>): UpdateNamedLocation_Response {
    const message = createBaseUpdateNamedLocation_Response();
    message.updated = (object.updated !== undefined && object.updated !== null)
      ? NamedLocation.fromPartial(object.updated)
      : undefined;
    return message;
  },
};

function createBaseDeleteNamedLocation(): DeleteNamedLocation {
  return {};
}

export const DeleteNamedLocation = {
  encode(_: DeleteNamedLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNamedLocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNamedLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteNamedLocation>): DeleteNamedLocation {
    return DeleteNamedLocation.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteNamedLocation>): DeleteNamedLocation {
    const message = createBaseDeleteNamedLocation();
    return message;
  },
};

function createBaseDeleteNamedLocation_Request(): DeleteNamedLocation_Request {
  return { organization: undefined, name: "" };
}

export const DeleteNamedLocation_Request = {
  encode(message: DeleteNamedLocation_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNamedLocation_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNamedLocation_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteNamedLocation_Request>): DeleteNamedLocation_Request {
    return DeleteNamedLocation_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteNamedLocation_Request>): DeleteNamedLocation_Request {
    const message = createBaseDeleteNamedLocation_Request();
    message.organization = object.organization ?? undefined;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseDeleteNamedLocation_Response(): DeleteNamedLocation_Response {
  return {};
}

export const DeleteNamedLocation_Response = {
  encode(_: DeleteNamedLocation_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNamedLocation_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNamedLocation_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteNamedLocation_Response>): DeleteNamedLocation_Response {
    return DeleteNamedLocation_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteNamedLocation_Response>): DeleteNamedLocation_Response {
    const message = createBaseDeleteNamedLocation_Response();
    return message;
  },
};

/**
 * Manage named locations for your organization.
 * This allows you to save locations to be displayed on the map or used in alarms.
 * Note that when a named location is used in an alarm, it may be exposed to child organization when the alarm
 * is available to child organizations.
 */
export type NamedLocationServiceDefinition = typeof NamedLocationServiceDefinition;
export const NamedLocationServiceDefinition = {
  name: "NamedLocationService",
  fullName: "hiber.organization.location.NamedLocationService",
  methods: {
    list: {
      name: "List",
      requestType: ListNamedLocations_Request,
      requestStream: false,
      responseType: ListNamedLocations_Response,
      responseStream: false,
      options: {},
    },
    create: {
      name: "Create",
      requestType: CreateNamedLocations_Request,
      requestStream: false,
      responseType: CreateNamedLocations_Response,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: UpdateNamedLocation_Request,
      requestStream: false,
      responseType: UpdateNamedLocation_Response,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteNamedLocation_Request,
      requestStream: false,
      responseType: DeleteNamedLocation_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
