import CoreModule from '@/store/core/modules/auth';

const Auth = {
  namespaced: true,
  state: {
    ...CoreModule.state,
  },
  actions: {
    ...CoreModule.actions,
  },
  mutations: {
    ...CoreModule.mutations,
  },
  getters: {
    ...CoreModule.getters,
  },
};

export default Auth;
