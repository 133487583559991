/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import {
  BytesOrHex,
  DateMessage,
  Duration,
  Location,
  Pagination,
  Pagination_Result,
  TimeRange,
  Timestamp,
} from "./base";
import { Struct } from "./google/protobuf/struct";
import { ModemSelection } from "./modem";
import { Tag } from "./tag";
import { Value } from "./value";

export const protobufPackage = "hiber.message";

/** Message management. */

/**
 * Message received from a device in the field.
 * Messages have a number of default field, like sent and received time, and location. In addition, depending on the
 * assigned body parsers, messages have a number of body fields with values.
 */
export interface Message {
  /** Unique message identifier. */
  messageId: number;
  /** Modem number of the modem that sent the message. */
  modemNumber: string;
  /** The name of the modem that sent the message. */
  modemName: string;
  /** The device identifier for the modem that sent the message (e.g. a MAC address). */
  modemIdentifier: string;
  /** Time the message was sent from the modem. */
  sentAt:
    | Timestamp
    | undefined;
  /** Time the message was received on the server. */
  receivedAt:
    | Timestamp
    | undefined;
  /** Modem location when the message was sent. */
  location:
    | Location
    | undefined;
  /**
   * Message body.
   *
   * @deprecated
   */
  body: Uint8Array;
  /** Message body convenience object. */
  bodyBytes:
    | BytesOrHex
    | undefined;
  /** A list of applied body parsers and their results. */
  bodyParsed: Message_ParsedBody[];
  /** Convenience flag marking whether the body was parsed successfully by at least one parser. */
  bodyParsedSuccessfully: boolean;
  /** True if the the TEST source is in the sources. */
  isTest: boolean;
  /** Whether this message contains other messages. */
  isGatewayMessage: boolean;
  /** The gateway message this message was sent in. */
  viaGatewayMessage: number;
  /** Message metadata, defined by the modem or gateway. */
  metadata:
    | { [key: string]: any }
    | undefined;
  /**
   * Flattened results of all successful body parsers.
   * This is a convenience to access the fields from body_parsed, but if any fields are present in
   * multiple body_parsed results, it is not defined which field will be used in this Struct.
   * This may change in the future.
   */
  bodyFields:
    | { [key: string]: any }
    | undefined;
  /** The tags of the modem that sent the message. */
  tags: Tag[];
  /** The names of the tags of the modem that sent the message. */
  tagNames: string[];
}

/**
 * Parsed message body.
 * If any parsers are configured for the modem, they are applied to the message.
 * The result is stored either as a json object or an error string.
 */
export interface Message_ParsedBody {
  /** Globally unique identifier of the parser that was used. */
  parserIdentifier: string;
  /** Name of the parser that was used. */
  parserName: string;
  parsed?:
    | { $case: "error"; error: string }
    | { $case: "result"; result: { [key: string]: any } | undefined }
    | undefined;
}

/**
 * Selection object for modem messages.
 * Filter messages by modem and time sent (note that this is not the time the message was received)
 */
export interface MessageSelection {
  /** Select the modems to return messages for. Optional, when omitted or empty everything is included. */
  modemSelection?:
    | ModemSelection
    | undefined;
  /** Filter message by time range. */
  timeRange?:
    | TimeRange
    | undefined;
  /** Include messages by id */
  modemMessageIds: number[];
  /**
   * Replace whatever time zone was specified in the start and end time with the modem time zone.
   * This means you may get values outside of the original time range, but can be useful when requesting
   * data for a date, for example.
   * For example: start.textual = 2022-01-01, end.textual = 2022-02-01 would return a month of data in UTC time.
   * When combined with this flag, it would return that month of data in the time zone of the modem, which may
   * even be different per modem.
   */
  overrideTimeRangeWithModemTimeZone?: boolean | undefined;
}

export interface ListMessages {
}

export interface ListMessages_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the messages to list. Optional, when omitted or empty everything is included. */
  selection?: MessageSelection | undefined;
  pagination?: Pagination | undefined;
}

export interface ListMessages_Response {
  messages: Message[];
  request: ListMessages_Request | undefined;
  pagination: Pagination_Result | undefined;
}

export interface CountMessages {
}

export interface CountMessages_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the messages to count. Optional, when omitted or empty everything is included. */
  selection?:
    | MessageSelection
    | undefined;
  /** Numeric timezone offset for day grouping. Optional. */
  timeZoneOffset?:
    | number
    | undefined;
  /** Timezone string for day grouping. Optional. */
  timeZone?: string | undefined;
}

export interface CountMessages_Response {
  messageCountPerDay: CountMessages_Response_MessageCount[];
  request: CountMessages_Request | undefined;
}

export interface CountMessages_Response_MessageCount {
  date: DateMessage | undefined;
  count: number;
}

/**
 * List the history for a single field, and optionally apply an aggregation and/or grouping to it.
 *
 * @deprecated
 */
export interface MessageBodyFieldHistory {
}

/**
 * Request to get the history of (a) field(s), for the selected modems in the organization.
 *
 * @deprecated
 */
export interface MessageBodyFieldHistory_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the modem(s) to get the history for. Optional, when omitted or empty everything is included. */
  selection?:
    | ModemSelection
    | undefined;
  /**
   * Paginate the returned values.
   * This may not be relevant, depending on the aggregation (which may result in a single value) and the
   * time range.
   */
  pagination?:
    | Pagination
    | undefined;
  /** The time to view the history for. */
  timeRange?:
    | TimeRange
    | undefined;
  /** How to aggregate the data. */
  aggregation: MessageBodyFieldHistory_Request_Aggregation;
  sort: MessageBodyFieldHistory_Request_Sort;
  group?:
    | { $case: "splitByDuration"; splitByDuration: Duration }
    | { $case: "reduceToMaxSize"; reduceToMaxSize: number }
    | undefined;
  /**
   * Get the history for the selected fields.
   * Text and Enum fields cannot be summed, or averaged, they always use LAST aggregation when aggregating.
   */
  messageBodyFields: string[];
  /**
   * Get the history for the location.
   * Locations cannot be summed, or averaged, they always use LAST aggregation when aggregating.
   */
  includeLocation?:
    | boolean
    | undefined;
  /** Whether to exclude empty groups when grouping. Empty groups are included by default. */
  excludeEmptyGroups?: boolean | undefined;
}

/**
 * Options to aggregate the history data points (in a group).
 *
 * @deprecated
 */
export enum MessageBodyFieldHistory_Request_Aggregation {
  /** NONE - Do not aggregate the history data points, just list all of them. */
  NONE = "NONE",
  /** AVERAGE - Average value of all history data points (in a group). */
  AVERAGE = "AVERAGE",
  /** SUM - Sum all history data points (in a group). */
  SUM = "SUM",
  /** LAST - Just take the last value (in a group). */
  LAST = "LAST",
  /** MINIMUM - Take the lowest value (in a group). */
  MINIMUM = "MINIMUM",
  /** MAXIMUM - Take the highest value (in a group). */
  MAXIMUM = "MAXIMUM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function messageBodyFieldHistory_Request_AggregationFromJSON(
  object: any,
): MessageBodyFieldHistory_Request_Aggregation {
  switch (object) {
    case 0:
    case "NONE":
      return MessageBodyFieldHistory_Request_Aggregation.NONE;
    case 1:
    case "AVERAGE":
      return MessageBodyFieldHistory_Request_Aggregation.AVERAGE;
    case 2:
    case "SUM":
      return MessageBodyFieldHistory_Request_Aggregation.SUM;
    case 3:
    case "LAST":
      return MessageBodyFieldHistory_Request_Aggregation.LAST;
    case 4:
    case "MINIMUM":
      return MessageBodyFieldHistory_Request_Aggregation.MINIMUM;
    case 5:
    case "MAXIMUM":
      return MessageBodyFieldHistory_Request_Aggregation.MAXIMUM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MessageBodyFieldHistory_Request_Aggregation.UNRECOGNIZED;
  }
}

export function messageBodyFieldHistory_Request_AggregationToNumber(
  object: MessageBodyFieldHistory_Request_Aggregation,
): number {
  switch (object) {
    case MessageBodyFieldHistory_Request_Aggregation.NONE:
      return 0;
    case MessageBodyFieldHistory_Request_Aggregation.AVERAGE:
      return 1;
    case MessageBodyFieldHistory_Request_Aggregation.SUM:
      return 2;
    case MessageBodyFieldHistory_Request_Aggregation.LAST:
      return 3;
    case MessageBodyFieldHistory_Request_Aggregation.MINIMUM:
      return 4;
    case MessageBodyFieldHistory_Request_Aggregation.MAXIMUM:
      return 5;
    case MessageBodyFieldHistory_Request_Aggregation.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** How to sort the returned values. */
export enum MessageBodyFieldHistory_Request_Sort {
  TIME_DESCENDING = "TIME_DESCENDING",
  TIME_ASCENDING = "TIME_ASCENDING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function messageBodyFieldHistory_Request_SortFromJSON(object: any): MessageBodyFieldHistory_Request_Sort {
  switch (object) {
    case 0:
    case "TIME_DESCENDING":
      return MessageBodyFieldHistory_Request_Sort.TIME_DESCENDING;
    case 1:
    case "TIME_ASCENDING":
      return MessageBodyFieldHistory_Request_Sort.TIME_ASCENDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MessageBodyFieldHistory_Request_Sort.UNRECOGNIZED;
  }
}

export function messageBodyFieldHistory_Request_SortToNumber(object: MessageBodyFieldHistory_Request_Sort): number {
  switch (object) {
    case MessageBodyFieldHistory_Request_Sort.TIME_DESCENDING:
      return 0;
    case MessageBodyFieldHistory_Request_Sort.TIME_ASCENDING:
      return 1;
    case MessageBodyFieldHistory_Request_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * Response with the (aggregated) history of (a) field(s), for the selected modems in the organization.
 *
 * @deprecated
 */
export interface MessageBodyFieldHistory_Response {
  /**
   * The processed historical data points.
   * For example, when applying the SUM aggregation to all data points, this list would only contains a
   * single value, the sum of values.
   */
  values: MessageBodyFieldHistory_Response_TimedValue[];
  /** The pagination result, containing information about amounts and pages. */
  pagination:
    | Pagination_Result
    | undefined;
  /** The request that was received, corrected and used to produce this result. */
  request: MessageBodyFieldHistory_Request | undefined;
}

/** Processed historical data point. If this is a group, it will have a time range to denote the group. */
export interface MessageBodyFieldHistory_Response_TimedValue {
  /**
   * When not grouping, time of the individual point.
   * When grouping would return an exact data point (i.e. not an average), the time of that point.
   * When grouping would not return an exact data point (i.e. average), the end of the time range.
   */
  timestamp:
    | Timestamp
    | undefined;
  /** When grouping, the start and end time for the group. */
  timeRange:
    | TimeRange
    | undefined;
  /**
   * The values for the given fields (if available).
   * When the user has unit preferences set, they are applied to these values.
   */
  fieldValues:
    | { [key: string]: any }
    | undefined;
  /** The values for the given fields (if available), with additional unit information. */
  fieldValueDetails: { [key: string]: Value };
  /** The location of the modem at the timestamp, or the last location in the time range. */
  location: Location | undefined;
}

export interface MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry {
  key: string;
  value: Value | undefined;
}

function createBaseMessage(): Message {
  return {
    messageId: 0,
    modemNumber: "",
    modemName: "",
    modemIdentifier: "",
    sentAt: undefined,
    receivedAt: undefined,
    location: undefined,
    body: new Uint8Array(0),
    bodyBytes: undefined,
    bodyParsed: [],
    bodyParsedSuccessfully: false,
    isTest: false,
    isGatewayMessage: false,
    viaGatewayMessage: 0,
    metadata: undefined,
    bodyFields: undefined,
    tags: [],
    tagNames: [],
  };
}

export const Message = {
  encode(message: Message, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.messageId !== 0) {
      writer.uint32(8).uint64(message.messageId);
    }
    if (message.modemNumber !== "") {
      writer.uint32(18).string(message.modemNumber);
    }
    if (message.modemName !== "") {
      writer.uint32(26).string(message.modemName);
    }
    if (message.modemIdentifier !== "") {
      writer.uint32(34).string(message.modemIdentifier);
    }
    if (message.sentAt !== undefined) {
      Timestamp.encode(message.sentAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.receivedAt !== undefined) {
      Timestamp.encode(message.receivedAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(58).fork()).ldelim();
    }
    if (message.body.length !== 0) {
      writer.uint32(66).bytes(message.body);
    }
    if (message.bodyBytes !== undefined) {
      BytesOrHex.encode(message.bodyBytes, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.bodyParsed) {
      Message_ParsedBody.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.bodyParsedSuccessfully === true) {
      writer.uint32(88).bool(message.bodyParsedSuccessfully);
    }
    if (message.isTest === true) {
      writer.uint32(96).bool(message.isTest);
    }
    if (message.isGatewayMessage === true) {
      writer.uint32(104).bool(message.isGatewayMessage);
    }
    if (message.viaGatewayMessage !== 0) {
      writer.uint32(112).uint64(message.viaGatewayMessage);
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(122).fork()).ldelim();
    }
    if (message.bodyFields !== undefined) {
      Struct.encode(Struct.wrap(message.bodyFields), writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    for (const v of message.tagNames) {
      writer.uint32(162).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.messageId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modemName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.modemIdentifier = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sentAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.receivedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.body = reader.bytes();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.bodyBytes = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.bodyParsed.push(Message_ParsedBody.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.bodyParsedSuccessfully = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isTest = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isGatewayMessage = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.viaGatewayMessage = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.bodyFields = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.tagNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Message>): Message {
    return Message.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Message>): Message {
    const message = createBaseMessage();
    message.messageId = object.messageId ?? 0;
    message.modemNumber = object.modemNumber ?? "";
    message.modemName = object.modemName ?? "";
    message.modemIdentifier = object.modemIdentifier ?? "";
    message.sentAt = (object.sentAt !== undefined && object.sentAt !== null)
      ? Timestamp.fromPartial(object.sentAt)
      : undefined;
    message.receivedAt = (object.receivedAt !== undefined && object.receivedAt !== null)
      ? Timestamp.fromPartial(object.receivedAt)
      : undefined;
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.body = object.body ?? new Uint8Array(0);
    message.bodyBytes = (object.bodyBytes !== undefined && object.bodyBytes !== null)
      ? BytesOrHex.fromPartial(object.bodyBytes)
      : undefined;
    message.bodyParsed = object.bodyParsed?.map((e) => Message_ParsedBody.fromPartial(e)) || [];
    message.bodyParsedSuccessfully = object.bodyParsedSuccessfully ?? false;
    message.isTest = object.isTest ?? false;
    message.isGatewayMessage = object.isGatewayMessage ?? false;
    message.viaGatewayMessage = object.viaGatewayMessage ?? 0;
    message.metadata = object.metadata ?? undefined;
    message.bodyFields = object.bodyFields ?? undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.tagNames = object.tagNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseMessage_ParsedBody(): Message_ParsedBody {
  return { parserIdentifier: "", parserName: "", parsed: undefined };
}

export const Message_ParsedBody = {
  encode(message: Message_ParsedBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parserIdentifier !== "") {
      writer.uint32(50).string(message.parserIdentifier);
    }
    if (message.parserName !== "") {
      writer.uint32(18).string(message.parserName);
    }
    switch (message.parsed?.$case) {
      case "error":
        writer.uint32(34).string(message.parsed.error);
        break;
      case "result":
        Struct.encode(Struct.wrap(message.parsed.result), writer.uint32(42).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message_ParsedBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage_ParsedBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 50) {
            break;
          }

          message.parserIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parserName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.parsed = { $case: "error", error: reader.string() };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.parsed = { $case: "result", result: Struct.unwrap(Struct.decode(reader, reader.uint32())) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Message_ParsedBody>): Message_ParsedBody {
    return Message_ParsedBody.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Message_ParsedBody>): Message_ParsedBody {
    const message = createBaseMessage_ParsedBody();
    message.parserIdentifier = object.parserIdentifier ?? "";
    message.parserName = object.parserName ?? "";
    if (object.parsed?.$case === "error" && object.parsed?.error !== undefined && object.parsed?.error !== null) {
      message.parsed = { $case: "error", error: object.parsed.error };
    }
    if (object.parsed?.$case === "result" && object.parsed?.result !== undefined && object.parsed?.result !== null) {
      message.parsed = { $case: "result", result: object.parsed.result };
    }
    return message;
  },
};

function createBaseMessageSelection(): MessageSelection {
  return {
    modemSelection: undefined,
    timeRange: undefined,
    modemMessageIds: [],
    overrideTimeRangeWithModemTimeZone: undefined,
  };
}

export const MessageSelection = {
  encode(message: MessageSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modemSelection !== undefined) {
      ModemSelection.encode(message.modemSelection, writer.uint32(10).fork()).ldelim();
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.modemMessageIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.overrideTimeRangeWithModemTimeZone !== undefined) {
      writer.uint32(24).bool(message.overrideTimeRangeWithModemTimeZone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modemSelection = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.modemMessageIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.modemMessageIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.overrideTimeRangeWithModemTimeZone = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MessageSelection>): MessageSelection {
    return MessageSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MessageSelection>): MessageSelection {
    const message = createBaseMessageSelection();
    message.modemSelection = (object.modemSelection !== undefined && object.modemSelection !== null)
      ? ModemSelection.fromPartial(object.modemSelection)
      : undefined;
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.modemMessageIds = object.modemMessageIds?.map((e) => e) || [];
    message.overrideTimeRangeWithModemTimeZone = object.overrideTimeRangeWithModemTimeZone ?? undefined;
    return message;
  },
};

function createBaseListMessages(): ListMessages {
  return {};
}

export const ListMessages = {
  encode(_: ListMessages, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMessages {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMessages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListMessages>): ListMessages {
    return ListMessages.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListMessages>): ListMessages {
    const message = createBaseListMessages();
    return message;
  },
};

function createBaseListMessages_Request(): ListMessages_Request {
  return { organization: undefined, selection: undefined, pagination: undefined };
}

export const ListMessages_Request = {
  encode(message: ListMessages_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      MessageSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMessages_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMessages_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = MessageSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListMessages_Request>): ListMessages_Request {
    return ListMessages_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMessages_Request>): ListMessages_Request {
    const message = createBaseListMessages_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? MessageSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListMessages_Response(): ListMessages_Response {
  return { messages: [], request: undefined, pagination: undefined };
}

export const ListMessages_Response = {
  encode(message: ListMessages_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.messages) {
      Message.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListMessages_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMessages_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMessages_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messages.push(Message.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListMessages_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListMessages_Response>): ListMessages_Response {
    return ListMessages_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMessages_Response>): ListMessages_Response {
    const message = createBaseListMessages_Response();
    message.messages = object.messages?.map((e) => Message.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListMessages_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseCountMessages(): CountMessages {
  return {};
}

export const CountMessages = {
  encode(_: CountMessages, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountMessages {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountMessages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CountMessages>): CountMessages {
    return CountMessages.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<CountMessages>): CountMessages {
    const message = createBaseCountMessages();
    return message;
  },
};

function createBaseCountMessages_Request(): CountMessages_Request {
  return { organization: undefined, selection: undefined, timeZoneOffset: undefined, timeZone: undefined };
}

export const CountMessages_Request = {
  encode(message: CountMessages_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      MessageSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.timeZoneOffset !== undefined) {
      writer.uint32(40).int32(message.timeZoneOffset);
    }
    if (message.timeZone !== undefined) {
      writer.uint32(50).string(message.timeZone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountMessages_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountMessages_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = MessageSelection.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.timeZoneOffset = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.timeZone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CountMessages_Request>): CountMessages_Request {
    return CountMessages_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CountMessages_Request>): CountMessages_Request {
    const message = createBaseCountMessages_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? MessageSelection.fromPartial(object.selection)
      : undefined;
    message.timeZoneOffset = object.timeZoneOffset ?? undefined;
    message.timeZone = object.timeZone ?? undefined;
    return message;
  },
};

function createBaseCountMessages_Response(): CountMessages_Response {
  return { messageCountPerDay: [], request: undefined };
}

export const CountMessages_Response = {
  encode(message: CountMessages_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.messageCountPerDay) {
      CountMessages_Response_MessageCount.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      CountMessages_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountMessages_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountMessages_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messageCountPerDay.push(CountMessages_Response_MessageCount.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = CountMessages_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CountMessages_Response>): CountMessages_Response {
    return CountMessages_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CountMessages_Response>): CountMessages_Response {
    const message = createBaseCountMessages_Response();
    message.messageCountPerDay =
      object.messageCountPerDay?.map((e) => CountMessages_Response_MessageCount.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? CountMessages_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseCountMessages_Response_MessageCount(): CountMessages_Response_MessageCount {
  return { date: undefined, count: 0 };
}

export const CountMessages_Response_MessageCount = {
  encode(message: CountMessages_Response_MessageCount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      DateMessage.encode(message.date, writer.uint32(10).fork()).ldelim();
    }
    if (message.count !== 0) {
      writer.uint32(16).int32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountMessages_Response_MessageCount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountMessages_Response_MessageCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = DateMessage.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CountMessages_Response_MessageCount>): CountMessages_Response_MessageCount {
    return CountMessages_Response_MessageCount.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CountMessages_Response_MessageCount>): CountMessages_Response_MessageCount {
    const message = createBaseCountMessages_Response_MessageCount();
    message.date = (object.date !== undefined && object.date !== null)
      ? DateMessage.fromPartial(object.date)
      : undefined;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseMessageBodyFieldHistory(): MessageBodyFieldHistory {
  return {};
}

export const MessageBodyFieldHistory = {
  encode(_: MessageBodyFieldHistory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageBodyFieldHistory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageBodyFieldHistory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MessageBodyFieldHistory>): MessageBodyFieldHistory {
    return MessageBodyFieldHistory.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<MessageBodyFieldHistory>): MessageBodyFieldHistory {
    const message = createBaseMessageBodyFieldHistory();
    return message;
  },
};

function createBaseMessageBodyFieldHistory_Request(): MessageBodyFieldHistory_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    timeRange: undefined,
    aggregation: MessageBodyFieldHistory_Request_Aggregation.NONE,
    sort: MessageBodyFieldHistory_Request_Sort.TIME_DESCENDING,
    group: undefined,
    messageBodyFields: [],
    includeLocation: undefined,
    excludeEmptyGroups: undefined,
  };
}

export const MessageBodyFieldHistory_Request = {
  encode(message: MessageBodyFieldHistory_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      ModemSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(106).fork()).ldelim();
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(26).fork()).ldelim();
    }
    if (message.aggregation !== MessageBodyFieldHistory_Request_Aggregation.NONE) {
      writer.uint32(32).int32(messageBodyFieldHistory_Request_AggregationToNumber(message.aggregation));
    }
    if (message.sort !== MessageBodyFieldHistory_Request_Sort.TIME_DESCENDING) {
      writer.uint32(40).int32(messageBodyFieldHistory_Request_SortToNumber(message.sort));
    }
    switch (message.group?.$case) {
      case "splitByDuration":
        Duration.encode(message.group.splitByDuration, writer.uint32(50).fork()).ldelim();
        break;
      case "reduceToMaxSize":
        writer.uint32(56).uint32(message.group.reduceToMaxSize);
        break;
    }
    for (const v of message.messageBodyFields) {
      writer.uint32(66).string(v!);
    }
    if (message.includeLocation !== undefined) {
      writer.uint32(72).bool(message.includeLocation);
    }
    if (message.excludeEmptyGroups !== undefined) {
      writer.uint32(80).bool(message.excludeEmptyGroups);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageBodyFieldHistory_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageBodyFieldHistory_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.aggregation = messageBodyFieldHistory_Request_AggregationFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sort = messageBodyFieldHistory_Request_SortFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.group = { $case: "splitByDuration", splitByDuration: Duration.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.group = { $case: "reduceToMaxSize", reduceToMaxSize: reader.uint32() };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.messageBodyFields.push(reader.string());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.includeLocation = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.excludeEmptyGroups = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MessageBodyFieldHistory_Request>): MessageBodyFieldHistory_Request {
    return MessageBodyFieldHistory_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MessageBodyFieldHistory_Request>): MessageBodyFieldHistory_Request {
    const message = createBaseMessageBodyFieldHistory_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ModemSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.aggregation = object.aggregation ?? MessageBodyFieldHistory_Request_Aggregation.NONE;
    message.sort = object.sort ?? MessageBodyFieldHistory_Request_Sort.TIME_DESCENDING;
    if (
      object.group?.$case === "splitByDuration" &&
      object.group?.splitByDuration !== undefined &&
      object.group?.splitByDuration !== null
    ) {
      message.group = { $case: "splitByDuration", splitByDuration: Duration.fromPartial(object.group.splitByDuration) };
    }
    if (
      object.group?.$case === "reduceToMaxSize" &&
      object.group?.reduceToMaxSize !== undefined &&
      object.group?.reduceToMaxSize !== null
    ) {
      message.group = { $case: "reduceToMaxSize", reduceToMaxSize: object.group.reduceToMaxSize };
    }
    message.messageBodyFields = object.messageBodyFields?.map((e) => e) || [];
    message.includeLocation = object.includeLocation ?? undefined;
    message.excludeEmptyGroups = object.excludeEmptyGroups ?? undefined;
    return message;
  },
};

function createBaseMessageBodyFieldHistory_Response(): MessageBodyFieldHistory_Response {
  return { values: [], pagination: undefined, request: undefined };
}

export const MessageBodyFieldHistory_Response = {
  encode(message: MessageBodyFieldHistory_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.values) {
      MessageBodyFieldHistory_Response_TimedValue.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      MessageBodyFieldHistory_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageBodyFieldHistory_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageBodyFieldHistory_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.values.push(MessageBodyFieldHistory_Response_TimedValue.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = MessageBodyFieldHistory_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MessageBodyFieldHistory_Response>): MessageBodyFieldHistory_Response {
    return MessageBodyFieldHistory_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MessageBodyFieldHistory_Response>): MessageBodyFieldHistory_Response {
    const message = createBaseMessageBodyFieldHistory_Response();
    message.values = object.values?.map((e) => MessageBodyFieldHistory_Response_TimedValue.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? MessageBodyFieldHistory_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseMessageBodyFieldHistory_Response_TimedValue(): MessageBodyFieldHistory_Response_TimedValue {
  return {
    timestamp: undefined,
    timeRange: undefined,
    fieldValues: undefined,
    fieldValueDetails: {},
    location: undefined,
  };
}

export const MessageBodyFieldHistory_Response_TimedValue = {
  encode(message: MessageBodyFieldHistory_Response_TimedValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== undefined) {
      Timestamp.encode(message.timestamp, writer.uint32(10).fork()).ldelim();
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(18).fork()).ldelim();
    }
    if (message.fieldValues !== undefined) {
      Struct.encode(Struct.wrap(message.fieldValues), writer.uint32(26).fork()).ldelim();
    }
    Object.entries(message.fieldValueDetails).forEach(([key, value]) => {
      MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry.encode(
        { key: key as any, value },
        writer.uint32(42).fork(),
      ).ldelim();
    });
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessageBodyFieldHistory_Response_TimedValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageBodyFieldHistory_Response_TimedValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.timestamp = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fieldValues = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry5.value !== undefined) {
            message.fieldValueDetails[entry5.key] = entry5.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<MessageBodyFieldHistory_Response_TimedValue>): MessageBodyFieldHistory_Response_TimedValue {
    return MessageBodyFieldHistory_Response_TimedValue.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<MessageBodyFieldHistory_Response_TimedValue>,
  ): MessageBodyFieldHistory_Response_TimedValue {
    const message = createBaseMessageBodyFieldHistory_Response_TimedValue();
    message.timestamp = (object.timestamp !== undefined && object.timestamp !== null)
      ? Timestamp.fromPartial(object.timestamp)
      : undefined;
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.fieldValues = object.fieldValues ?? undefined;
    message.fieldValueDetails = Object.entries(object.fieldValueDetails ?? {}).reduce<{ [key: string]: Value }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Value.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    return message;
  },
};

function createBaseMessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry(): MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry {
  return { key: "", value: undefined };
}

export const MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry = {
  encode(
    message: MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry>,
  ): MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry {
    return MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry>,
  ): MessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry {
    const message = createBaseMessageBodyFieldHistory_Response_TimedValue_FieldValueDetailsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Value.fromPartial(object.value) : undefined;
    return message;
  },
};

/** This service contains calls to list and manage messages. */
export type MessageServiceDefinition = typeof MessageServiceDefinition;
export const MessageServiceDefinition = {
  name: "MessageService",
  fullName: "hiber.message.MessageService",
  methods: {
    /** List messages for the selected modems. */
    list: {
      name: "List",
      requestType: ListMessages_Request,
      requestStream: false,
      responseType: ListMessages_Response,
      responseStream: false,
      options: {},
    },
    /** Count messages for the selected modems. */
    count: {
      name: "Count",
      requestType: CountMessages_Request,
      requestStream: false,
      responseType: CountMessages_Response,
      responseStream: false,
      options: {},
    },
    /** @deprecated */
    history: {
      name: "History",
      requestType: MessageBodyFieldHistory_Request,
      requestStream: false,
      responseType: MessageBodyFieldHistory_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
