<template>
  <div>
    <span>
      {{ message }} {{ category.substring(0, category.length - 1 ) }}: {{ item.name ? item.name : item.description }}
    </span>
    <span
      class='btn--undo'
      @click='undoDelete'> Undo </span>
    <span
      class='log__skip'
      @click='close(true)'>
      skip
    </span>
  </div>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    message: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: null,
    },
    category: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timeout: 0,
      forceDestroyHandler: () => {
        this.close(true);
      },
    };
  },
  mounted() {
    this.$store.commit(`${this.getCategory()}/temporarilyRemoveItems`, { id: this.item.id, category: this.category });
    this.timeout = setTimeout(() => {
      this.close(true);
    }, this.duration);
    window.addEventListener('beforeunload', this.forceDestroyHandler, { capture: true });
  },
  methods: {
    undoDelete() {
      if (this.getCategory !== '') {
        this.$store.commit(`${this.getCategory()}/clearTemporarilyRemovedItems`, { id: this.item.id, category: this.category });
      }
      clearTimeout(this.timeout);
      this.timeout = 0;
      this.close();
    },
    getCategory() {
      if (this.category === 'webhooks' || this.category === 'tokens' || this.category === 'slacks' || this.category === 'mqtts') {
        return 'Integrations';
      }
      if (this.category === 'certificates') {
        return 'Certificates';
      }
      if (this.category === 'users') {
        return 'User';
      }
      return '';
    },
    close(deleteBeforeClosing) {
      window.removeEventListener('beforeunload', this.forceDestroyHandler, { capture: true });
      if (deleteBeforeClosing) {
        clearTimeout(this.timeout);
        this.destructiveEvent(this.category, this.item.id);
      }
      this.$emit('close');
    },
    destructiveEvent(type, id) {
      switch (type) {
        case 'tokens':
          this.$store.dispatch('Integrations/deleteTokenRequest', { id }).then(() => {
            this.$store.dispatch('Integrations/getTokensList').then(() => this.$store.commit(`${this.getCategory()}/clearTemporarilyRemovedItems`, { id: this.item.id, category: this.category }));
            this.$store.dispatch('Tables/setSelection', { name: 'integrations/tokens', rows: [] });
            this.$store.dispatch('App/log', { message: `${this.item.name} deleted successfully`, type: 'success' }, { root: true });
          });
          break;
        case 'webhooks':
          this.$store.dispatch('Integrations/deleteWebhookRequest', { id }).then(() => {
            this.$store.dispatch('Integrations/forceWebhookRefresh').then(() => this.$store.commit(`${this.getCategory()}/clearTemporarilyRemovedItems`, { id: this.item.id, category: this.category }));
            this.$store.dispatch('Tables/setSelection', { name: 'integrations/webhooks', rows: [] });
            this.$store.dispatch('App/log', { message: `${this.item.description} deleted successfully`, type: 'success' }, { root: true });
          });
          break;
        case 'slacks':
          this.$store.dispatch('Integrations/deleteSlackRequest', { id }).then(() => {
            this.$store.dispatch('Tables/setSelection', { name: 'integrations/slacks', rows: [] });
            this.$store.dispatch('Integrations/getSlackList').then(() => this.$store.commit(`${this.getCategory()}/clearTemporarilyRemovedItems`, { id: this.item.id, category: this.category }));
            this.$store.dispatch('App/log', { message: `${this.item.description} deleted successfully`, type: 'success' }, { root: true });
          });
          break;
        case 'mqtts':
          this.$store.dispatch('Integrations/deleteMQTTRequest', { id }).then(() => {
            this.$store.dispatch('Tables/setSelection', { name: 'integrations/mqtts', rows: [] });
            this.$store.dispatch('Integrations/getMQTTList').then(() => this.$store.commit(`${this.getCategory()}/clearTemporarilyRemovedItems`, { id: this.item.id, category: this.category }));
            this.$store.dispatch('App/log', { message: `${this.item.description} deleted successfully`, type: 'success' }, { root: true });
          });
          break;
        case 'certificates':
          this.$store.dispatch('Certificates/deleteCertificateRequest', { id }).then(() => {
            this.$store.dispatch('Tables/setSelection', { name: 'integrations/mqtts', rows: [] });
            this.$store.dispatch('Certificates/getCertificatesList').then(() => this.$store.commit(`${this.getCategory()}/clearTemporarilyRemovedItems`, { id: this.item.id, category: this.category }));
            this.$store.dispatch('App/log', { message: `${this.item.description} deleted successfully`, type: 'success' }, { root: true });
          });
          break;
        case 'users':
          this.$store.dispatch('User/declineUserRequests', [id]).then(() => {
            this.$store.dispatch('User/getUsers');
            this.$store.dispatch('User/getRequestedUsers').then(() => this.$store.commit(`${this.getCategory() ? '' : this.getCategory()}/clearTemporarilyRemovedItems`, { id: this.item.id, category: this.category }));

            this.$store.dispatch('App/log', { message: `${this.item.name} removed / declined successfully`, type: 'success' }, { root: true });

            this.$store.dispatch('Tables/setSelection', { name: 'users/requests', rows: [] });
            this.$store.dispatch('Tables/setAllSelected', { name: 'users/requests', state: false });
            this.$store.dispatch('Tables/setSelection', { name: 'users/approved', rows: [] });
            this.$store.dispatch('Tables/setAllSelected', { name: 'users/approved', state: false });
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
