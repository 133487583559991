<template>
  <div class='dialog'>
    <div class='dialog__header'>
      {{ title }}
    </div>
    <div class='dialog__content'>
      <div>
        {{ message }}
      </div>
    </div>
    <div class='dialog__footer'>
      <div
        v-for='button in buttons'
        :key='button.title'>
        <h-the-btn
          :variant='button.variant'
          @click='buttonHandler(button.handler)'>
          {{ button.title }}
        </h-the-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    title: { type: String, required: true },
    message: { type: String, required: true },
    onCancel: { type: Function, required: false, default: () => {} },
    onConfirm: { type: Function, required: false, default: () => {} },
    buttons: {
      type: Array,
      required: false,
      default() {
        return [
          {
            title: 'CANCEL',
            variant: 'positive',
            handler: 'onCancel',
          },
          {
            title: 'CONFIRM',
            variant: 'positive',
            handler: 'onConfirm',
          },
        ];
      },
    },
  },
  methods: {
    buttonHandler(handler) {
      if (typeof handler === 'function') {
        handler();
      } else if (typeof this.$props[handler] === 'function') {
        this.$props[handler]();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.dialog {
  @apply tw-h-full tw-flex tw-flex-col;
  padding: 40px;

  &__header {
    @apply tw-font-bold tw-text-header tw-font-europa;
    @apply tw-text-primary;

    padding-bottom: 30px;
  }

  &__content {
    @apply tw-flex-1;
    @apply tw-border-t tw-border-b tw-border-neutral-2;
    @apply tw-font-light;

    font-size: 16px;
    padding: 40px 0;
  }

  &__footer {
    @apply tw-flex tw-justify-end tw-space-x-6 tw-w-full;
    padding-top: 40px;
  }
}
</style>
