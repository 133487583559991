/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  OverrideRoles_Request,
  OverrideRoles_Response,
  OverrideRoles_Restore_Request,
  OverrideRoles_Restore_Response,
} from "./currentuser";
import { Role, RoleSelection } from "./role";

export const protobufPackage = "hiber.role";

export interface ListRoles {
}

export interface ListRoles_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the roles to list. Optional, when omitted or empty everything is included. */
  selection?: RoleSelection | undefined;
}

export interface ListRoles_Response {
  roles: Role[];
  request: ListRoles_Request | undefined;
}

function createBaseListRoles(): ListRoles {
  return {};
}

export const ListRoles = {
  encode(_: ListRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListRoles>): ListRoles {
    return ListRoles.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListRoles>): ListRoles {
    const message = createBaseListRoles();
    return message;
  },
};

function createBaseListRoles_Request(): ListRoles_Request {
  return { organization: undefined, selection: undefined };
}

export const ListRoles_Request = {
  encode(message: ListRoles_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      RoleSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRoles_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRoles_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = RoleSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListRoles_Request>): ListRoles_Request {
    return ListRoles_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRoles_Request>): ListRoles_Request {
    const message = createBaseListRoles_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? RoleSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseListRoles_Response(): ListRoles_Response {
  return { roles: [], request: undefined };
}

export const ListRoles_Response = {
  encode(message: ListRoles_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      Role.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListRoles_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRoles_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRoles_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(Role.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListRoles_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListRoles_Response>): ListRoles_Response {
    return ListRoles_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRoles_Response>): ListRoles_Response {
    const message = createBaseListRoles_Response();
    message.roles = object.roles?.map((e) => Role.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListRoles_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

export type RoleServiceDefinition = typeof RoleServiceDefinition;
export const RoleServiceDefinition = {
  name: "RoleService",
  fullName: "hiber.role.RoleService",
  methods: {
    list: {
      name: "List",
      requestType: ListRoles_Request,
      requestStream: false,
      responseType: ListRoles_Response,
      responseStream: false,
      options: {},
    },
    override: {
      name: "Override",
      requestType: OverrideRoles_Request,
      requestStream: false,
      responseType: OverrideRoles_Response,
      responseStream: false,
      options: {},
    },
    restore: {
      name: "Restore",
      requestType: OverrideRoles_Restore_Request,
      requestStream: false,
      responseType: OverrideRoles_Restore_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
