<template>
  <div>
    <label class='h-text-field'>
      <span v-if='label'>{{ label }}:</span>
      <div class='tw-flex tw-flex-1 tw-flex-col tw-gap-1'>
        <input
          :value='value'
          v-bind='$attrs'
          :class='{ "has-error": !!$attrs.error }'
          v-on='inputListeners'>
        <span
          v-if='$attrs.error && errorMessage'
          class='error-message'>{{ errorMessage }}</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    label: { type: String, required: false, default: '' },
    value: {
      type: [String, Number],
      default: '',
    },
    errorMessage: { type: String, required: false, default: null },
  },
  computed: {
    // Required for v-model to work on <h-input />. See Vue docs
    inputListeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit('input', event.target.value);
        },
      };
    },
  },
};
</script>

<style lang='scss'>
.h-text-field {
  @apply tw-flex tw-items-center tw-flex-wrap;

  span {
    @apply tw-text-neutral-3 tw-italic tw-font-normal;
    @apply tw-font-lora tw-text-small tw-text-left tw-tracking-tighter;
    @apply tw-min-w-[200px];
  }

  .error-message{
    @apply tw-text-alert-red;
  }

  input, textarea {
    @apply tw-min-w-[200px];
    @apply tw-transition-shadow tw-transition-colors tw-ease-in-out tw-duration-150;

    &.has-error {
      @apply tw-border tw-border-alert-red;

      &:hover,
      &:focus {
        @apply tw-shadow-input-error tw-border-opacity-50;
      }
    }
  }
}
</style>
