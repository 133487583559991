import { defaultErrorHandler } from '@/store/core/utils';
import {
  createNamedLocation,
  deleteNamedLocation,
  fetchNamedLocations,
  updateNamedLocation,
} from '@/services/named_locations';

const NamedLocations = {
  namespaced: true,
  state: {
    pagination: null,
    list: null,
  },
  actions: {
    fetchNamedLocations(context, payload) {
      const pagination = payload?.pagination;
      return new Promise((resolve, reject) => {
        fetchNamedLocations({ pagination }).then((res) => {
          context.commit('setNamedLocations', {
            pagination: res.pagination,
            list: res.namedLocations,
          });
          resolve(res);
          return res;
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    deleteNamedLocation(context, payload) {
      return new Promise((resolve, reject) => {
        deleteNamedLocation({ name: payload.name }).then((res) => {
          resolve(res);
          return res;
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    createNamedLocation(context, payload) {
      const { name, path } = payload;
      return new Promise((resolve, reject) => {
        createNamedLocation({ name, path }).then((res) => {
          resolve(res);
          return res;
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    updateNamedLocation(context, payload) {
      const { oldName, newName, path } = payload;
      return new Promise((resolve, reject) => {
        updateNamedLocation({ name: oldName, updated: { name: newName, path } }).then((res) => {
          resolve(res);
          return res;
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    setNamedLocations(state, res) {
      state.list = res.list;
      state.pagination = res.pagination;
    },
  },
  getters: {
    getLocations(state) {
      return () => state.list;
    },
  },
};
export default NamedLocations;
