import { getGrpcClient } from '@/services/app';
import {
  MessageBodyFieldHistory_Request,
  MessageBodyFieldHistory_Request_Aggregation,
  MessageServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/message';

export function fetchHistory(
  modems: string[],
  dateRange: { start: string, end: string },
  fields: string[],
  pagination: { size: number, page: number } = { size: 1000, page: 0 },
  aggregation: MessageBodyFieldHistory_Request_Aggregation = MessageBodyFieldHistory_Request_Aggregation.LAST,
) {
  const req = MessageBodyFieldHistory_Request.fromPartial({
    pagination,
    timeRange: { start: { textual: dateRange.start }, end: { textual: dateRange.end } },
    aggregation,
    group: { $case: 'reduceToMaxSize', reduceToMaxSize: pagination.size },
    selection: { modems: { include: modems } },
    messageBodyFields: fields,
  });

  const client = getGrpcClient<typeof MessageServiceDefinition>(MessageServiceDefinition);
  return client.history(req);
}
