import CoreModule from '@/store/core/modules/organization';

import { defaultErrorHandler, successHandler, createUint8Array } from '@/store/core/utils';
import {
  uploadOrganizationAvatar,
} from '@/services/organization';

const Organization = {
  namespaced: true,
  state: {
    ...CoreModule.state,
  },
  actions: {
    ...CoreModule.actions,
    uploadOrganizationAvatar(context, form) {
      const payload = {
        ...form,
      };
      payload.form.fileBytes = createUint8Array(payload.form.file);
      const logMessage = {
        category: 'Logo',
        name: payload.form.name,
        action: 'uploaded',
      };

      return new Promise((resolve, reject) => {
        uploadOrganizationAvatar(
          payload.form,
        ).then((res) => {
          successHandler(res, resolve, context, logMessage);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    ...CoreModule.mutations,
  },
  getters: {
    ...CoreModule.getters,
  },
};

export default Organization;
