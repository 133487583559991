/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Filter_Roles } from "./base";
import {
  OrganizationPermission,
  organizationPermissionFromJSON,
  organizationPermissionToNumber,
  SupportPermission,
  supportPermissionFromJSON,
  supportPermissionToNumber,
} from "./permission";

export const protobufPackage = "hiber.role";

export interface Role {
  /** Unique identifier for the role. */
  identifier: string;
  /** Display name for the role. */
  displayName: string;
  /** Description for the role. */
  description: string;
  /** The organization permissions the role grants. */
  permissions: OrganizationPermission[];
  /**
   * The support permissions the role grants.
   * This is not typically available in any organizations other than the Hiber organization.
   */
  supportPermissions: SupportPermission[];
}

export interface RoleSelection {
  search?: string | undefined;
  roles?: Filter_Roles | undefined;
}

function createBaseRole(): Role {
  return { identifier: "", displayName: "", description: "", permissions: [], supportPermissions: [] };
}

export const Role = {
  encode(message: Role, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    writer.uint32(26).fork();
    for (const v of message.permissions) {
      writer.int32(organizationPermissionToNumber(v));
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.supportPermissions) {
      writer.int32(supportPermissionToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Role {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.permissions.push(organizationPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.permissions.push(organizationPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.supportPermissions.push(supportPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.supportPermissions.push(supportPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Role>): Role {
    return Role.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Role>): Role {
    const message = createBaseRole();
    message.identifier = object.identifier ?? "";
    message.displayName = object.displayName ?? "";
    message.description = object.description ?? "";
    message.permissions = object.permissions?.map((e) => e) || [];
    message.supportPermissions = object.supportPermissions?.map((e) => e) || [];
    return message;
  },
};

function createBaseRoleSelection(): RoleSelection {
  return { search: undefined, roles: undefined };
}

export const RoleSelection = {
  encode(message: RoleSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    if (message.roles !== undefined) {
      Filter_Roles.encode(message.roles, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoleSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoleSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roles = Filter_Roles.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RoleSelection>): RoleSelection {
    return RoleSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RoleSelection>): RoleSelection {
    const message = createBaseRoleSelection();
    message.search = object.search ?? undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? Filter_Roles.fromPartial(object.roles)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
