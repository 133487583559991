// Base
import { Area, Filter_ChildOrganizations, Location, LocationSelection } from 'hiber-grpc/src/customer_compiled/base';
// Modem
import {
  ListModemsRequest,
  ListModemsRequest_Sort,
  listModemsRequest_SortFromJSON,
  ListModemMessagesRequest,
  ModemMessage_Source,
  ModemSelection,
  ModemServiceDefinition,
  UpdateModemTagsRequest,
} from 'hiber-grpc/src/customer_compiled/modem';

// Testing
import {
  PushModemMessagesRequest,
  PushModemMessagesRequest_MessageToPush,
  TestingServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/testing';

import { getGrpcClient } from '@/services/app';
import { convertTimeToIso } from '@/store/core/utils';

export interface ILocationSelection {
  area?: {
    bottomLeft: {
      latitude: number,
      longitude: number,
    },
    topRight: {
      latitude: number,
      longitude: number,
    }
  },
  shape?: {
    pathList: Location[],
  },
  level: number,
}

export interface IGetModems {
  pagination: {
    size: number,
    page: number,
  },
  sortBy: ListModemsRequest_Sort[] | string,
  filters: {
    search?: string,
    activatedRange?: {
      active: boolean,
      startDate: number,
      endDate: number
    },
    lastMessageRange?: {
      active: boolean,
      startDate: number,
      endDate: number,
    },
    health?: {
      active: boolean,
      include: string[],
    },
    lifecycle?: any[],
    types?: {
      active: boolean,
      include: any[],
      exclude: any[],
    },
    tags?: {
      active: boolean,
      include?: number[],
      exclude?: number[],
    },
    gatewaysOnly?: {
      active: boolean,
    },
    gatewayDevices?: {
      search: string[],
      active: boolean,
    },
    includeChildOrgData?: {
      active: boolean,
      include: string[],
      exclude: string[],
    },
  },
  locationSelection?: ILocationSelection,
  modemNrs?: string[],
}

export function asLocationSelection(selection: IGetModems) {
  return LocationSelection.fromPartial({
    areas: [Area.fromPartial({
      bottomLeft: selection.locationSelection?.area?.bottomLeft,
      topRight: selection.locationSelection?.area?.topRight,
    })],
    shapes: selection.locationSelection?.shape?.pathList,
  });
}

export function asModemSelection(selection: IGetModems) {
  const activatedIn = selection.filters?.activatedRange?.active
    ? {
      start: {
        textual: convertTimeToIso(selection.filters?.activatedRange?.startDate),
      },
      end: {
        textual: convertTimeToIso(selection.filters?.activatedRange?.endDate),
      },
    } : undefined;
  return ModemSelection.fromPartial({
    modems: {
      include: selection.modemNrs,
    },
    freeTextSearch: selection.filters?.search,
    activatedIn,
    withLastMessageIn: {
      start: {
        textual: selection.filters?.lastMessageRange?.active ? convertTimeToIso(selection.filters?.lastMessageRange?.startDate) : '',
      },
      end: {
        textual: selection.filters?.lastMessageRange?.active ? convertTimeToIso(selection.filters?.lastMessageRange?.endDate) : '',
      },
    },
    healthLevels: selection.filters?.health?.include || [],
    lifecycles: selection.filters?.lifecycle || [],
    includeTypes: selection.filters?.types?.include || [],
    excludeTypes: selection.filters?.types?.exclude || [],
    filterByTags: {
      filter: {
        include: selection.filters?.tags?.include || [],
        exclude: selection.filters?.tags?.exclude || [],
      },
    },
    connectedToGateways: { include: selection.filters?.gatewayDevices?.search || [] },
    onlyHasExternalDeviceIds: selection.filters?.gatewayDevices?.active,
    onlyGateways: selection.filters?.gatewaysOnly?.active,
  });
}

export function asOrganizationFilter(selection: IGetModems) {
  return Filter_ChildOrganizations.fromPartial({
    includeAll: selection.filters.includeChildOrgData?.active
      && !selection.filters.includeChildOrgData?.include?.length
      && !selection.filters.includeChildOrgData?.exclude?.length,
    include: selection.filters.includeChildOrgData?.include,
    exclude: selection.filters.includeChildOrgData?.exclude,
  });
}

function ModemListRequestFromSelection(selection: IGetModems) {
  const { pagination } = selection;
  return ListModemsRequest.fromPartial({
    pagination,
    selection: asModemSelection(selection),
    childOrganizations: asOrganizationFilter(selection),
    sortBy: (typeof selection.sortBy === 'string')
      ? [listModemsRequest_SortFromJSON(selection.sortBy)] : selection.sortBy,
    locationSelection: selection.locationSelection ? asLocationSelection(selection) : undefined,
  });
}

export function getModems(selection: IGetModems) {
  const request = ModemListRequestFromSelection(selection);
  const client = getGrpcClient<typeof ModemServiceDefinition>(ModemServiceDefinition);
  return client.list(request);
}

export function updateTagsOnModems(
  tagIdsToAdd: number[],
  tagIdsToRemove: number[],
  tagsToCreateFromLabels: string[],
  modemNrs: string[],
) {
  const request = UpdateModemTagsRequest.fromPartial({
    selection: { modems: { include: modemNrs } },
    update: { tagIdsToAdd, tagIdsToRemove, newTagsToAdd: tagsToCreateFromLabels.map(name => ({ name })) },
  });
  const client = getGrpcClient<typeof ModemServiceDefinition>(ModemServiceDefinition);
  return client.updateTags(request);
}

export function getModemMessages(
  modemNrs: string[],
  organization: string,
  timerange?: any,
  testMessages?: boolean,
  size: number = 100,
) {
  const request = ListModemMessagesRequest.fromPartial({
    pagination: { size },
    organization,
    selection: {
      modemSelection: { modems: { include: modemNrs } },
      filterBySources: { exclude: !testMessages ? [ModemMessage_Source.TEST] : [] },
      timeRange: { start: { textual: timerange.start }, end: { textual: timerange.end } },
    },
  });
  const client = getGrpcClient<typeof ModemServiceDefinition>(ModemServiceDefinition);
  return client.messages(request);
}

export function pushModemTestMessages(messages: PushModemMessagesRequest_MessageToPush[]) {
  const request = PushModemMessagesRequest.fromPartial({ messages });
  const client = getGrpcClient<typeof TestingServiceDefinition>(TestingServiceDefinition);
  return client.pushModemMessages(request);
}
