import {
  createModemAlarm,
  deleteModemAlarm,
  fetchModemAlarms,
  UI_IDENTIFIER_PREFIX,
  updateModemAlarm,
} from '@/services/modem_alarms';
import { defaultErrorHandler } from '@/store/core/utils';

const ModemAlarms = {
  namespaced: true,
  state: {
    pagination: null,
    list: null,
  },
  actions: {
    fetchModemAlarms(context, payload) {
      const pagination = payload?.pagination;
      return new Promise((resolve, reject) => {
        fetchModemAlarms({ pagination }).then((res) => {
          context.commit('setModemAlarms', {
            pagination: res.pagination,
            list: res.modemAlarms,
          });
          resolve(res);
          return res;
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    updateModemAlarm(context, payload) {
      const { alarm, checks, deletedChecks } = payload;

      // newly created checks would have the name prefixed with UI_IDENTIFIER_PREFIX
      // those update have backend generated names
      const createdChecks = checks.filter((elem) => elem.name.startsWith(UI_IDENTIFIER_PREFIX));
      const updatedChecks = checks.filter((elem) => !elem.name.startsWith(UI_IDENTIFIER_PREFIX));

      return new Promise((resolve, reject) => {
        updateModemAlarm({
          name: alarm.name,
          identifier: alarm.identifier,
          triggerCondition: alarm.triggerCondition,
          description: alarm.description,
          deletedChecks,
          updatedChecks,
          createdChecks,
        }).then((res) => {
          resolve(res);
          return res;
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    createModemAlarm(context, payload) {
      const { alarm } = payload;
      return new Promise((resolve, reject) => {
        createModemAlarm(alarm).then(res => {
          resolve(res);
          return res;
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    deleteModemAlarm(context, payload) {
      const { identifier } = payload;
      return new Promise((resolve, reject) => {
        deleteModemAlarm({ identifier }).then((res) => {
          resolve(res);
          return res;
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    setModemAlarms(state, res) {
      state.list = res.list;
      state.pagination = res.pagination;
    },
  },
  getters: {
    getModemAlarms(state) {
      return () => state.list;
    },
  },
};
export default ModemAlarms;
