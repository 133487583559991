import { theme as defaultTheme } from '../../../tailwind.config';

const disabled_yaxis_cookie_name = 'hilo_disabled_yaxis';

const disabledYAxes = JSON.parse(localStorage.getItem(disabled_yaxis_cookie_name));

const saveToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // user might be blocking us from writing on localstorage
    // eslint-disable-next-line no-console
    console.warn('There was an error trying to write to local storage');
  }
};

const Hilo = {
  namespaced: true,
  state: {
    fields: {
      pressure: {
        color: defaultTheme.colors.primary,
      },
      temperature: {
        color: defaultTheme.colors.mauve,
      },
      battery: {
        color: defaultTheme.colors.turquiose,
      },
    },
    preferences: {
      dateRangeInDays: 3,
    },
    colors: {
      // for next 3 except pressure, temperature, battery:
      fallBackColors: ['#0059B8', '#FBB44C', '#FF7121'],
      default: '#454849', // use this for more data sets
    },
    disabledYAxes: disabledYAxes || {},
  },
  actions: {
    toggleYAxisDisplay: ({ commit }, payload) => {
      commit('toggleYAxisDisplay', payload);
    },
  },
  mutations: {
    toggleYAxisDisplay(state, payload) {
      const { isVisible, modem, yAxisId } = payload;

      if (!state.disabledYAxes[modem]) {
        state.disabledYAxes[modem] = [yAxisId];
      } else if (isVisible) {
        const hiddenAxes = state.disabledYAxes[modem].filter(el => el !== yAxisId);
        if (hiddenAxes.length > 0) {
          state.disabledYAxes[modem] = hiddenAxes;
        } else {
          delete state.disabledYAxes[modem];
        }
      } else {
        state.disabledYAxes[modem] = Array.from(new Set(state.disabledYAxes[modem]).add(yAxisId));
      }
      saveToLocalStorage(disabled_yaxis_cookie_name, state.disabledYAxes);
    },
  },
  getters: {
    getFieldsOptions(state) {
      return state.fields;
    },
    getStoreSettings(state) {
      return state;
    },
    getDisabledYAxisForModem: (state) => (modem) => state.disabledYAxes[modem],
  },
};

export default Hilo;
