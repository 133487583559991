/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import {
  BytesOrHex,
  Duration,
  Filter_Events,
  Filter_Events_Update,
  Filter_Modems,
  Filter_Modems_Update,
  Filter_Publishers,
  Filter_Tags,
  Filter_Tags_Update,
  Health,
  healthFromJSON,
  healthToNumber,
  Pagination,
  Pagination_Result,
  TimeRange,
  Timestamp,
  UpdateBoolean,
  UpdateClearableString,
  UpdateZeroableInt,
} from "./base";
import { Tag, TagSelection, UpdateTagsForItem } from "./tag";

export const protobufPackage = "hiber.integration.slack";

export interface SlackPublisher {
  id: number;
  description: string;
  /** The uid of the user that created this publisher */
  createdBy: string;
  data: SlackPublisher_Data | undefined;
  filterEventTypes: Filter_Events | undefined;
  filterModemNumbers: Filter_Modems | undefined;
  filterTags:
    | Filter_Tags
    | undefined;
  /** Filter events by health level caused. */
  filterHealthLevels: string[];
  health: Health;
  healthConfig: SlackPublisher_HealthConfig | undefined;
  tags: Tag[];
}

export interface SlackPublisher_Data {
  /** The slack webhook URL. See https://api.slack.com/messaging/webhooks for information on Slack webhooks. */
  url: string;
  /**
   * The channel the webhook is configured to send to. This is used purely for display purposes,
   * since Slack webhooks are bound to a single channel.
   */
  channel: string;
  disabled: boolean;
}

/** Health configuration for the slack integration. Defines how the health is calculated. */
export interface SlackPublisher_HealthConfig {
  /** Period to consider when determining health from warning events. Warning events cannot be resolved. */
  healthWarningPeriod:
    | Duration
    | undefined;
  /**
   * Allowed percentage of failures.
   * If the failure percentage is higher, within the warning period, the health is switched to WARNING.
   */
  healthWarningFailurePercentage: number;
}

export interface SlackMessage {
  time: Timestamp | undefined;
  publisherData: SlackPublisher_Data | undefined;
  message: BytesOrHex | undefined;
  successful: boolean;
  error: string;
}

export interface SlackPublisherSelection {
  /** Filter by id. */
  publishers?:
    | Filter_Publishers
    | undefined;
  /** Partial text match on the description. */
  description?:
    | string
    | undefined;
  /** Partial text match on the url. */
  searchUrl?:
    | string
    | undefined;
  /** Partial text match on the channel. */
  searchChannel?: string | undefined;
  tags?: TagSelection | undefined;
  health: Health[];
  /** Search in the all available text, like description and url. */
  search?: string | undefined;
}

export interface ListSlackPublishersRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection?: SlackPublisherSelection | undefined;
  pagination?: Pagination | undefined;
  sort: ListSlackPublishersRequest_Sort[];
}

export enum ListSlackPublishersRequest_Sort {
  /** DEFAULT - Sort by id. */
  DEFAULT = "DEFAULT",
  /** ID_DESC - Sort by id, high to low. */
  ID_DESC = "ID_DESC",
  /** DESCRIPTION - Sort by description. */
  DESCRIPTION = "DESCRIPTION",
  /** DESCRIPTION_DESC - Sort by description, z to a. */
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  /** URL - Sort by url. */
  URL = "URL",
  /** URL_DESC - Sort by url, z to a. */
  URL_DESC = "URL_DESC",
  /** CHANNEL - Sort by channel. */
  CHANNEL = "CHANNEL",
  /** CHANNEL_DESC - Sort by channel, z to a. */
  CHANNEL_DESC = "CHANNEL_DESC",
  /** HEALTH - Sort unhealthy webhooks before health webhooks. */
  HEALTH = "HEALTH",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function listSlackPublishersRequest_SortFromJSON(object: any): ListSlackPublishersRequest_Sort {
  switch (object) {
    case 0:
    case "DEFAULT":
      return ListSlackPublishersRequest_Sort.DEFAULT;
    case 1:
    case "ID_DESC":
      return ListSlackPublishersRequest_Sort.ID_DESC;
    case 2:
    case "DESCRIPTION":
      return ListSlackPublishersRequest_Sort.DESCRIPTION;
    case 3:
    case "DESCRIPTION_DESC":
      return ListSlackPublishersRequest_Sort.DESCRIPTION_DESC;
    case 4:
    case "URL":
      return ListSlackPublishersRequest_Sort.URL;
    case 5:
    case "URL_DESC":
      return ListSlackPublishersRequest_Sort.URL_DESC;
    case 6:
    case "CHANNEL":
      return ListSlackPublishersRequest_Sort.CHANNEL;
    case 7:
    case "CHANNEL_DESC":
      return ListSlackPublishersRequest_Sort.CHANNEL_DESC;
    case 8:
    case "HEALTH":
      return ListSlackPublishersRequest_Sort.HEALTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListSlackPublishersRequest_Sort.UNRECOGNIZED;
  }
}

export function listSlackPublishersRequest_SortToNumber(object: ListSlackPublishersRequest_Sort): number {
  switch (object) {
    case ListSlackPublishersRequest_Sort.DEFAULT:
      return 0;
    case ListSlackPublishersRequest_Sort.ID_DESC:
      return 1;
    case ListSlackPublishersRequest_Sort.DESCRIPTION:
      return 2;
    case ListSlackPublishersRequest_Sort.DESCRIPTION_DESC:
      return 3;
    case ListSlackPublishersRequest_Sort.URL:
      return 4;
    case ListSlackPublishersRequest_Sort.URL_DESC:
      return 5;
    case ListSlackPublishersRequest_Sort.CHANNEL:
      return 6;
    case ListSlackPublishersRequest_Sort.CHANNEL_DESC:
      return 7;
    case ListSlackPublishersRequest_Sort.HEALTH:
      return 8;
    case ListSlackPublishersRequest_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListSlackPublishersRequest_Response {
  publishers: SlackPublisher[];
  request: ListSlackPublishersRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface SlackPublisherHistorySelection {
  timeRange?: TimeRange | undefined;
  onlyFailures?: boolean | undefined;
}

export interface SlackPublisherHistoryRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  id: number;
  selection?: SlackPublisherHistorySelection | undefined;
  pagination?: Pagination | undefined;
}

export interface SlackPublisherHistoryRequest_Response {
  items: SlackMessage[];
  request: SlackPublisherHistoryRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface CreateSlackPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  description: string;
  filterEventTypes?: Filter_Events | undefined;
  filterModemNumbers?: Filter_Modems | undefined;
  filterTags?:
    | Filter_Tags
    | undefined;
  /** Filter events by health level caused. */
  filterHealthLevels: string[];
  url: string;
  channel?: string | undefined;
  disabled?: boolean | undefined;
  tags: number[];
}

/** Enable a disabled publisher or re-enable a publisher that's failed and is in cooldown. */
export interface EnableSlackPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection: SlackPublisherSelection | undefined;
}

export interface EnableSlackPublisherRequest_Response {
  publishers: SlackPublisher[];
  request: EnableSlackPublisherRequest | undefined;
}

export interface DisableSlackPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  selection: SlackPublisherSelection | undefined;
}

export interface DisableSlackPublisherRequest_Response {
  publishers: SlackPublisher[];
  request: DisableSlackPublisherRequest | undefined;
}

export interface UpdateSlackPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  id: number;
  /** @deprecated */
  deprecatedFilterEventTypes?: Filter_Events_Update | undefined;
  filterEventTypes?:
    | Filter_Events
    | undefined;
  /** @deprecated */
  deprecatedFilterModemNumbers?: Filter_Modems_Update | undefined;
  filterModemNumbers?:
    | Filter_Modems
    | undefined;
  /** @deprecated */
  deprecatedFilterTags?: Filter_Tags_Update | undefined;
  filterTags?:
    | Filter_Tags
    | undefined;
  /** Add health levels to the health levels filter. */
  addHealthLevelsToFilter: string[];
  /** Remove health levels from the health levels filter. */
  removeHealthLevelsFromFilter: string[];
  description?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedDescription?: UpdateClearableString | undefined;
  url?: string | undefined;
  channel?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedChannel?: UpdateClearableString | undefined;
  active?:
    | boolean
    | undefined;
  /** @deprecated */
  deprecatedActive?:
    | UpdateBoolean
    | undefined;
  /**
   * Period to consider when determining health from warning events. Warning events cannot be resolved.
   * Set this to 0 to disable warnings based on failure percentage.
   */
  healthWarningPeriod?:
    | Duration
    | undefined;
  /**
   * Allowed percentage of call failures.
   * If the failure percentage is higher, within the warning period, the health is switched to WARNING.
   */
  healthWarningFailurePercentage?:
    | number
    | undefined;
  /** @deprecated */
  deprecatedHealthWarningFailurePercentage?: UpdateZeroableInt | undefined;
}

export interface UpdateSlackPublisherTagsRequest {
  organization?: string | undefined;
  selection: SlackPublisherSelection | undefined;
  update: UpdateTagsForItem | undefined;
}

export interface UpdateSlackPublisherTagsRequest_Response {
  slackPublishers: SlackPublisher[];
}

export interface DeleteSlackPublisherRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  id: number;
}

export interface DeleteSlackPublisherRequest_Response {
}

function createBaseSlackPublisher(): SlackPublisher {
  return {
    id: 0,
    description: "",
    createdBy: "",
    data: undefined,
    filterEventTypes: undefined,
    filterModemNumbers: undefined,
    filterTags: undefined,
    filterHealthLevels: [],
    health: Health.OK,
    healthConfig: undefined,
    tags: [],
  };
}

export const SlackPublisher = {
  encode(message: SlackPublisher, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.createdBy !== "") {
      writer.uint32(82).string(message.createdBy);
    }
    if (message.data !== undefined) {
      SlackPublisher_Data.encode(message.data, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterEventTypes !== undefined) {
      Filter_Events.encode(message.filterEventTypes, writer.uint32(34).fork()).ldelim();
    }
    if (message.filterModemNumbers !== undefined) {
      Filter_Modems.encode(message.filterModemNumbers, writer.uint32(42).fork()).ldelim();
    }
    if (message.filterTags !== undefined) {
      Filter_Tags.encode(message.filterTags, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.filterHealthLevels) {
      writer.uint32(90).string(v!);
    }
    if (message.health !== Health.OK) {
      writer.uint32(56).int32(healthToNumber(message.health));
    }
    if (message.healthConfig !== undefined) {
      SlackPublisher_HealthConfig.encode(message.healthConfig, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackPublisher {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackPublisher();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = SlackPublisher_Data.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filterEventTypes = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.filterModemNumbers = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.filterTags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.filterHealthLevels.push(reader.string());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.health = healthFromJSON(reader.int32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.healthConfig = SlackPublisher_HealthConfig.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SlackPublisher>): SlackPublisher {
    return SlackPublisher.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SlackPublisher>): SlackPublisher {
    const message = createBaseSlackPublisher();
    message.id = object.id ?? 0;
    message.description = object.description ?? "";
    message.createdBy = object.createdBy ?? "";
    message.data = (object.data !== undefined && object.data !== null)
      ? SlackPublisher_Data.fromPartial(object.data)
      : undefined;
    message.filterEventTypes = (object.filterEventTypes !== undefined && object.filterEventTypes !== null)
      ? Filter_Events.fromPartial(object.filterEventTypes)
      : undefined;
    message.filterModemNumbers = (object.filterModemNumbers !== undefined && object.filterModemNumbers !== null)
      ? Filter_Modems.fromPartial(object.filterModemNumbers)
      : undefined;
    message.filterTags = (object.filterTags !== undefined && object.filterTags !== null)
      ? Filter_Tags.fromPartial(object.filterTags)
      : undefined;
    message.filterHealthLevels = object.filterHealthLevels?.map((e) => e) || [];
    message.health = object.health ?? Health.OK;
    message.healthConfig = (object.healthConfig !== undefined && object.healthConfig !== null)
      ? SlackPublisher_HealthConfig.fromPartial(object.healthConfig)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSlackPublisher_Data(): SlackPublisher_Data {
  return { url: "", channel: "", disabled: false };
}

export const SlackPublisher_Data = {
  encode(message: SlackPublisher_Data, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.channel !== "") {
      writer.uint32(18).string(message.channel);
    }
    if (message.disabled === true) {
      writer.uint32(24).bool(message.disabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackPublisher_Data {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackPublisher_Data();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.channel = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.disabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SlackPublisher_Data>): SlackPublisher_Data {
    return SlackPublisher_Data.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SlackPublisher_Data>): SlackPublisher_Data {
    const message = createBaseSlackPublisher_Data();
    message.url = object.url ?? "";
    message.channel = object.channel ?? "";
    message.disabled = object.disabled ?? false;
    return message;
  },
};

function createBaseSlackPublisher_HealthConfig(): SlackPublisher_HealthConfig {
  return { healthWarningPeriod: undefined, healthWarningFailurePercentage: 0 };
}

export const SlackPublisher_HealthConfig = {
  encode(message: SlackPublisher_HealthConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.healthWarningPeriod !== undefined) {
      Duration.encode(message.healthWarningPeriod, writer.uint32(18).fork()).ldelim();
    }
    if (message.healthWarningFailurePercentage !== 0) {
      writer.uint32(24).int32(message.healthWarningFailurePercentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackPublisher_HealthConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackPublisher_HealthConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.healthWarningPeriod = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.healthWarningFailurePercentage = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SlackPublisher_HealthConfig>): SlackPublisher_HealthConfig {
    return SlackPublisher_HealthConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SlackPublisher_HealthConfig>): SlackPublisher_HealthConfig {
    const message = createBaseSlackPublisher_HealthConfig();
    message.healthWarningPeriod = (object.healthWarningPeriod !== undefined && object.healthWarningPeriod !== null)
      ? Duration.fromPartial(object.healthWarningPeriod)
      : undefined;
    message.healthWarningFailurePercentage = object.healthWarningFailurePercentage ?? 0;
    return message;
  },
};

function createBaseSlackMessage(): SlackMessage {
  return { time: undefined, publisherData: undefined, message: undefined, successful: false, error: "" };
}

export const SlackMessage = {
  encode(message: SlackMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(10).fork()).ldelim();
    }
    if (message.publisherData !== undefined) {
      SlackPublisher_Data.encode(message.publisherData, writer.uint32(18).fork()).ldelim();
    }
    if (message.message !== undefined) {
      BytesOrHex.encode(message.message, writer.uint32(26).fork()).ldelim();
    }
    if (message.successful === true) {
      writer.uint32(32).bool(message.successful);
    }
    if (message.error !== "") {
      writer.uint32(42).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.publisherData = SlackPublisher_Data.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = BytesOrHex.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.successful = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.error = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SlackMessage>): SlackMessage {
    return SlackMessage.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SlackMessage>): SlackMessage {
    const message = createBaseSlackMessage();
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.publisherData = (object.publisherData !== undefined && object.publisherData !== null)
      ? SlackPublisher_Data.fromPartial(object.publisherData)
      : undefined;
    message.message = (object.message !== undefined && object.message !== null)
      ? BytesOrHex.fromPartial(object.message)
      : undefined;
    message.successful = object.successful ?? false;
    message.error = object.error ?? "";
    return message;
  },
};

function createBaseSlackPublisherSelection(): SlackPublisherSelection {
  return {
    publishers: undefined,
    description: undefined,
    searchUrl: undefined,
    searchChannel: undefined,
    tags: undefined,
    health: [],
    search: undefined,
  };
}

export const SlackPublisherSelection = {
  encode(message: SlackPublisherSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publishers !== undefined) {
      Filter_Publishers.encode(message.publishers, writer.uint32(10).fork()).ldelim();
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.searchUrl !== undefined) {
      writer.uint32(26).string(message.searchUrl);
    }
    if (message.searchChannel !== undefined) {
      writer.uint32(34).string(message.searchChannel);
    }
    if (message.tags !== undefined) {
      TagSelection.encode(message.tags, writer.uint32(42).fork()).ldelim();
    }
    writer.uint32(50).fork();
    for (const v of message.health) {
      writer.int32(healthToNumber(v));
    }
    writer.ldelim();
    if (message.search !== undefined) {
      writer.uint32(58).string(message.search);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackPublisherSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackPublisherSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishers = Filter_Publishers.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.searchChannel = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags = TagSelection.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag === 48) {
            message.health.push(healthFromJSON(reader.int32()));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.health.push(healthFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.search = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SlackPublisherSelection>): SlackPublisherSelection {
    return SlackPublisherSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SlackPublisherSelection>): SlackPublisherSelection {
    const message = createBaseSlackPublisherSelection();
    message.publishers = (object.publishers !== undefined && object.publishers !== null)
      ? Filter_Publishers.fromPartial(object.publishers)
      : undefined;
    message.description = object.description ?? undefined;
    message.searchUrl = object.searchUrl ?? undefined;
    message.searchChannel = object.searchChannel ?? undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagSelection.fromPartial(object.tags)
      : undefined;
    message.health = object.health?.map((e) => e) || [];
    message.search = object.search ?? undefined;
    return message;
  },
};

function createBaseListSlackPublishersRequest(): ListSlackPublishersRequest {
  return { organization: undefined, selection: undefined, pagination: undefined, sort: [] };
}

export const ListSlackPublishersRequest = {
  encode(message: ListSlackPublishersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      SlackPublisherSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sort) {
      writer.int32(listSlackPublishersRequest_SortToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSlackPublishersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSlackPublishersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = SlackPublisherSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sort.push(listSlackPublishersRequest_SortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sort.push(listSlackPublishersRequest_SortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListSlackPublishersRequest>): ListSlackPublishersRequest {
    return ListSlackPublishersRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListSlackPublishersRequest>): ListSlackPublishersRequest {
    const message = createBaseListSlackPublishersRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? SlackPublisherSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort?.map((e) => e) || [];
    return message;
  },
};

function createBaseListSlackPublishersRequest_Response(): ListSlackPublishersRequest_Response {
  return { publishers: [], request: undefined, pagination: undefined };
}

export const ListSlackPublishersRequest_Response = {
  encode(message: ListSlackPublishersRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.publishers) {
      SlackPublisher.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListSlackPublishersRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSlackPublishersRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSlackPublishersRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishers.push(SlackPublisher.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListSlackPublishersRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListSlackPublishersRequest_Response>): ListSlackPublishersRequest_Response {
    return ListSlackPublishersRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListSlackPublishersRequest_Response>): ListSlackPublishersRequest_Response {
    const message = createBaseListSlackPublishersRequest_Response();
    message.publishers = object.publishers?.map((e) => SlackPublisher.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListSlackPublishersRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseSlackPublisherHistorySelection(): SlackPublisherHistorySelection {
  return { timeRange: undefined, onlyFailures: undefined };
}

export const SlackPublisherHistorySelection = {
  encode(message: SlackPublisherHistorySelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(10).fork()).ldelim();
    }
    if (message.onlyFailures !== undefined) {
      writer.uint32(16).bool(message.onlyFailures);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackPublisherHistorySelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackPublisherHistorySelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.onlyFailures = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SlackPublisherHistorySelection>): SlackPublisherHistorySelection {
    return SlackPublisherHistorySelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SlackPublisherHistorySelection>): SlackPublisherHistorySelection {
    const message = createBaseSlackPublisherHistorySelection();
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    message.onlyFailures = object.onlyFailures ?? undefined;
    return message;
  },
};

function createBaseSlackPublisherHistoryRequest(): SlackPublisherHistoryRequest {
  return { organization: undefined, id: 0, selection: undefined, pagination: undefined };
}

export const SlackPublisherHistoryRequest = {
  encode(message: SlackPublisherHistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    if (message.selection !== undefined) {
      SlackPublisherHistorySelection.encode(message.selection, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackPublisherHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackPublisherHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selection = SlackPublisherHistorySelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SlackPublisherHistoryRequest>): SlackPublisherHistoryRequest {
    return SlackPublisherHistoryRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SlackPublisherHistoryRequest>): SlackPublisherHistoryRequest {
    const message = createBaseSlackPublisherHistoryRequest();
    message.organization = object.organization ?? undefined;
    message.id = object.id ?? 0;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? SlackPublisherHistorySelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseSlackPublisherHistoryRequest_Response(): SlackPublisherHistoryRequest_Response {
  return { items: [], request: undefined, pagination: undefined };
}

export const SlackPublisherHistoryRequest_Response = {
  encode(message: SlackPublisherHistoryRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      SlackMessage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      SlackPublisherHistoryRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SlackPublisherHistoryRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSlackPublisherHistoryRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(SlackMessage.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = SlackPublisherHistoryRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SlackPublisherHistoryRequest_Response>): SlackPublisherHistoryRequest_Response {
    return SlackPublisherHistoryRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SlackPublisherHistoryRequest_Response>): SlackPublisherHistoryRequest_Response {
    const message = createBaseSlackPublisherHistoryRequest_Response();
    message.items = object.items?.map((e) => SlackMessage.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? SlackPublisherHistoryRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseCreateSlackPublisherRequest(): CreateSlackPublisherRequest {
  return {
    organization: undefined,
    description: "",
    filterEventTypes: undefined,
    filterModemNumbers: undefined,
    filterTags: undefined,
    filterHealthLevels: [],
    url: "",
    channel: undefined,
    disabled: undefined,
    tags: [],
  };
}

export const CreateSlackPublisherRequest = {
  encode(message: CreateSlackPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.filterEventTypes !== undefined) {
      Filter_Events.encode(message.filterEventTypes, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterModemNumbers !== undefined) {
      Filter_Modems.encode(message.filterModemNumbers, writer.uint32(34).fork()).ldelim();
    }
    if (message.filterTags !== undefined) {
      Filter_Tags.encode(message.filterTags, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.filterHealthLevels) {
      writer.uint32(90).string(v!);
    }
    if (message.url !== "") {
      writer.uint32(50).string(message.url);
    }
    if (message.channel !== undefined) {
      writer.uint32(58).string(message.channel);
    }
    if (message.disabled !== undefined) {
      writer.uint32(64).bool(message.disabled);
    }
    writer.uint32(82).fork();
    for (const v of message.tags) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSlackPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSlackPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterEventTypes = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filterModemNumbers = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.filterTags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.filterHealthLevels.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.url = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.channel = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 10:
          if (tag === 80) {
            message.tags.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tags.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateSlackPublisherRequest>): CreateSlackPublisherRequest {
    return CreateSlackPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateSlackPublisherRequest>): CreateSlackPublisherRequest {
    const message = createBaseCreateSlackPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.description = object.description ?? "";
    message.filterEventTypes = (object.filterEventTypes !== undefined && object.filterEventTypes !== null)
      ? Filter_Events.fromPartial(object.filterEventTypes)
      : undefined;
    message.filterModemNumbers = (object.filterModemNumbers !== undefined && object.filterModemNumbers !== null)
      ? Filter_Modems.fromPartial(object.filterModemNumbers)
      : undefined;
    message.filterTags = (object.filterTags !== undefined && object.filterTags !== null)
      ? Filter_Tags.fromPartial(object.filterTags)
      : undefined;
    message.filterHealthLevels = object.filterHealthLevels?.map((e) => e) || [];
    message.url = object.url ?? "";
    message.channel = object.channel ?? undefined;
    message.disabled = object.disabled ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    return message;
  },
};

function createBaseEnableSlackPublisherRequest(): EnableSlackPublisherRequest {
  return { organization: undefined, selection: undefined };
}

export const EnableSlackPublisherRequest = {
  encode(message: EnableSlackPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      SlackPublisherSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnableSlackPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnableSlackPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = SlackPublisherSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EnableSlackPublisherRequest>): EnableSlackPublisherRequest {
    return EnableSlackPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EnableSlackPublisherRequest>): EnableSlackPublisherRequest {
    const message = createBaseEnableSlackPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? SlackPublisherSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseEnableSlackPublisherRequest_Response(): EnableSlackPublisherRequest_Response {
  return { publishers: [], request: undefined };
}

export const EnableSlackPublisherRequest_Response = {
  encode(message: EnableSlackPublisherRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.publishers) {
      SlackPublisher.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      EnableSlackPublisherRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnableSlackPublisherRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnableSlackPublisherRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishers.push(SlackPublisher.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = EnableSlackPublisherRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EnableSlackPublisherRequest_Response>): EnableSlackPublisherRequest_Response {
    return EnableSlackPublisherRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EnableSlackPublisherRequest_Response>): EnableSlackPublisherRequest_Response {
    const message = createBaseEnableSlackPublisherRequest_Response();
    message.publishers = object.publishers?.map((e) => SlackPublisher.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? EnableSlackPublisherRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseDisableSlackPublisherRequest(): DisableSlackPublisherRequest {
  return { organization: undefined, selection: undefined };
}

export const DisableSlackPublisherRequest = {
  encode(message: DisableSlackPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      SlackPublisherSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DisableSlackPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisableSlackPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = SlackPublisherSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DisableSlackPublisherRequest>): DisableSlackPublisherRequest {
    return DisableSlackPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DisableSlackPublisherRequest>): DisableSlackPublisherRequest {
    const message = createBaseDisableSlackPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? SlackPublisherSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseDisableSlackPublisherRequest_Response(): DisableSlackPublisherRequest_Response {
  return { publishers: [], request: undefined };
}

export const DisableSlackPublisherRequest_Response = {
  encode(message: DisableSlackPublisherRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.publishers) {
      SlackPublisher.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      DisableSlackPublisherRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DisableSlackPublisherRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisableSlackPublisherRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishers.push(SlackPublisher.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = DisableSlackPublisherRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DisableSlackPublisherRequest_Response>): DisableSlackPublisherRequest_Response {
    return DisableSlackPublisherRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DisableSlackPublisherRequest_Response>): DisableSlackPublisherRequest_Response {
    const message = createBaseDisableSlackPublisherRequest_Response();
    message.publishers = object.publishers?.map((e) => SlackPublisher.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? DisableSlackPublisherRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUpdateSlackPublisherRequest(): UpdateSlackPublisherRequest {
  return {
    organization: undefined,
    id: 0,
    deprecatedFilterEventTypes: undefined,
    filterEventTypes: undefined,
    deprecatedFilterModemNumbers: undefined,
    filterModemNumbers: undefined,
    deprecatedFilterTags: undefined,
    filterTags: undefined,
    addHealthLevelsToFilter: [],
    removeHealthLevelsFromFilter: [],
    description: undefined,
    deprecatedDescription: undefined,
    url: undefined,
    channel: undefined,
    deprecatedChannel: undefined,
    active: undefined,
    deprecatedActive: undefined,
    healthWarningPeriod: undefined,
    healthWarningFailurePercentage: undefined,
    deprecatedHealthWarningFailurePercentage: undefined,
  };
}

export const UpdateSlackPublisherRequest = {
  encode(message: UpdateSlackPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    if (message.deprecatedFilterEventTypes !== undefined) {
      Filter_Events_Update.encode(message.deprecatedFilterEventTypes, writer.uint32(26).fork()).ldelim();
    }
    if (message.filterEventTypes !== undefined) {
      Filter_Events.encode(message.filterEventTypes, writer.uint32(186).fork()).ldelim();
    }
    if (message.deprecatedFilterModemNumbers !== undefined) {
      Filter_Modems_Update.encode(message.deprecatedFilterModemNumbers, writer.uint32(34).fork()).ldelim();
    }
    if (message.filterModemNumbers !== undefined) {
      Filter_Modems.encode(message.filterModemNumbers, writer.uint32(194).fork()).ldelim();
    }
    if (message.deprecatedFilterTags !== undefined) {
      Filter_Tags_Update.encode(message.deprecatedFilterTags, writer.uint32(42).fork()).ldelim();
    }
    if (message.filterTags !== undefined) {
      Filter_Tags.encode(message.filterTags, writer.uint32(202).fork()).ldelim();
    }
    for (const v of message.addHealthLevelsToFilter) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.removeHealthLevelsFromFilter) {
      writer.uint32(58).string(v!);
    }
    if (message.description !== undefined) {
      writer.uint32(154).string(message.description);
    }
    if (message.deprecatedDescription !== undefined) {
      UpdateClearableString.encode(message.deprecatedDescription, writer.uint32(82).fork()).ldelim();
    }
    if (message.url !== undefined) {
      writer.uint32(90).string(message.url);
    }
    if (message.channel !== undefined) {
      writer.uint32(162).string(message.channel);
    }
    if (message.deprecatedChannel !== undefined) {
      UpdateClearableString.encode(message.deprecatedChannel, writer.uint32(98).fork()).ldelim();
    }
    if (message.active !== undefined) {
      writer.uint32(168).bool(message.active);
    }
    if (message.deprecatedActive !== undefined) {
      UpdateBoolean.encode(message.deprecatedActive, writer.uint32(122).fork()).ldelim();
    }
    if (message.healthWarningPeriod !== undefined) {
      Duration.encode(message.healthWarningPeriod, writer.uint32(138).fork()).ldelim();
    }
    if (message.healthWarningFailurePercentage !== undefined) {
      writer.uint32(208).uint32(message.healthWarningFailurePercentage);
    }
    if (message.deprecatedHealthWarningFailurePercentage !== undefined) {
      UpdateZeroableInt.encode(message.deprecatedHealthWarningFailurePercentage, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSlackPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSlackPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedFilterEventTypes = Filter_Events_Update.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.filterEventTypes = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deprecatedFilterModemNumbers = Filter_Modems_Update.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.filterModemNumbers = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedFilterTags = Filter_Tags_Update.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.filterTags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.addHealthLevelsToFilter.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.removeHealthLevelsFromFilter.push(reader.string());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.description = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deprecatedDescription = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.url = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.channel = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.deprecatedChannel = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.deprecatedActive = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.healthWarningPeriod = Duration.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.healthWarningFailurePercentage = reader.uint32();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.deprecatedHealthWarningFailurePercentage = UpdateZeroableInt.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateSlackPublisherRequest>): UpdateSlackPublisherRequest {
    return UpdateSlackPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateSlackPublisherRequest>): UpdateSlackPublisherRequest {
    const message = createBaseUpdateSlackPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.id = object.id ?? 0;
    message.deprecatedFilterEventTypes =
      (object.deprecatedFilterEventTypes !== undefined && object.deprecatedFilterEventTypes !== null)
        ? Filter_Events_Update.fromPartial(object.deprecatedFilterEventTypes)
        : undefined;
    message.filterEventTypes = (object.filterEventTypes !== undefined && object.filterEventTypes !== null)
      ? Filter_Events.fromPartial(object.filterEventTypes)
      : undefined;
    message.deprecatedFilterModemNumbers =
      (object.deprecatedFilterModemNumbers !== undefined && object.deprecatedFilterModemNumbers !== null)
        ? Filter_Modems_Update.fromPartial(object.deprecatedFilterModemNumbers)
        : undefined;
    message.filterModemNumbers = (object.filterModemNumbers !== undefined && object.filterModemNumbers !== null)
      ? Filter_Modems.fromPartial(object.filterModemNumbers)
      : undefined;
    message.deprecatedFilterTags = (object.deprecatedFilterTags !== undefined && object.deprecatedFilterTags !== null)
      ? Filter_Tags_Update.fromPartial(object.deprecatedFilterTags)
      : undefined;
    message.filterTags = (object.filterTags !== undefined && object.filterTags !== null)
      ? Filter_Tags.fromPartial(object.filterTags)
      : undefined;
    message.addHealthLevelsToFilter = object.addHealthLevelsToFilter?.map((e) => e) || [];
    message.removeHealthLevelsFromFilter = object.removeHealthLevelsFromFilter?.map((e) => e) || [];
    message.description = object.description ?? undefined;
    message.deprecatedDescription =
      (object.deprecatedDescription !== undefined && object.deprecatedDescription !== null)
        ? UpdateClearableString.fromPartial(object.deprecatedDescription)
        : undefined;
    message.url = object.url ?? undefined;
    message.channel = object.channel ?? undefined;
    message.deprecatedChannel = (object.deprecatedChannel !== undefined && object.deprecatedChannel !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedChannel)
      : undefined;
    message.active = object.active ?? undefined;
    message.deprecatedActive = (object.deprecatedActive !== undefined && object.deprecatedActive !== null)
      ? UpdateBoolean.fromPartial(object.deprecatedActive)
      : undefined;
    message.healthWarningPeriod = (object.healthWarningPeriod !== undefined && object.healthWarningPeriod !== null)
      ? Duration.fromPartial(object.healthWarningPeriod)
      : undefined;
    message.healthWarningFailurePercentage = object.healthWarningFailurePercentage ?? undefined;
    message.deprecatedHealthWarningFailurePercentage =
      (object.deprecatedHealthWarningFailurePercentage !== undefined &&
          object.deprecatedHealthWarningFailurePercentage !== null)
        ? UpdateZeroableInt.fromPartial(object.deprecatedHealthWarningFailurePercentage)
        : undefined;
    return message;
  },
};

function createBaseUpdateSlackPublisherTagsRequest(): UpdateSlackPublisherTagsRequest {
  return { organization: undefined, selection: undefined, update: undefined };
}

export const UpdateSlackPublisherTagsRequest = {
  encode(message: UpdateSlackPublisherTagsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      SlackPublisherSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.update !== undefined) {
      UpdateTagsForItem.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSlackPublisherTagsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSlackPublisherTagsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = SlackPublisherSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = UpdateTagsForItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateSlackPublisherTagsRequest>): UpdateSlackPublisherTagsRequest {
    return UpdateSlackPublisherTagsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateSlackPublisherTagsRequest>): UpdateSlackPublisherTagsRequest {
    const message = createBaseUpdateSlackPublisherTagsRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? SlackPublisherSelection.fromPartial(object.selection)
      : undefined;
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateTagsForItem.fromPartial(object.update)
      : undefined;
    return message;
  },
};

function createBaseUpdateSlackPublisherTagsRequest_Response(): UpdateSlackPublisherTagsRequest_Response {
  return { slackPublishers: [] };
}

export const UpdateSlackPublisherTagsRequest_Response = {
  encode(message: UpdateSlackPublisherTagsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.slackPublishers) {
      SlackPublisher.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSlackPublisherTagsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSlackPublisherTagsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.slackPublishers.push(SlackPublisher.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateSlackPublisherTagsRequest_Response>): UpdateSlackPublisherTagsRequest_Response {
    return UpdateSlackPublisherTagsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateSlackPublisherTagsRequest_Response>): UpdateSlackPublisherTagsRequest_Response {
    const message = createBaseUpdateSlackPublisherTagsRequest_Response();
    message.slackPublishers = object.slackPublishers?.map((e) => SlackPublisher.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteSlackPublisherRequest(): DeleteSlackPublisherRequest {
  return { organization: undefined, id: 0 };
}

export const DeleteSlackPublisherRequest = {
  encode(message: DeleteSlackPublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSlackPublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSlackPublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteSlackPublisherRequest>): DeleteSlackPublisherRequest {
    return DeleteSlackPublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteSlackPublisherRequest>): DeleteSlackPublisherRequest {
    const message = createBaseDeleteSlackPublisherRequest();
    message.organization = object.organization ?? undefined;
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseDeleteSlackPublisherRequest_Response(): DeleteSlackPublisherRequest_Response {
  return {};
}

export const DeleteSlackPublisherRequest_Response = {
  encode(_: DeleteSlackPublisherRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSlackPublisherRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSlackPublisherRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteSlackPublisherRequest_Response>): DeleteSlackPublisherRequest_Response {
    return DeleteSlackPublisherRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteSlackPublisherRequest_Response>): DeleteSlackPublisherRequest_Response {
    const message = createBaseDeleteSlackPublisherRequest_Response();
    return message;
  },
};

export type SlackIntegrationServiceDefinition = typeof SlackIntegrationServiceDefinition;
export const SlackIntegrationServiceDefinition = {
  name: "SlackIntegrationService",
  fullName: "hiber.integration.slack.SlackIntegrationService",
  methods: {
    list: {
      name: "List",
      requestType: ListSlackPublishersRequest,
      requestStream: false,
      responseType: ListSlackPublishersRequest_Response,
      responseStream: false,
      options: {},
    },
    create: {
      name: "Create",
      requestType: CreateSlackPublisherRequest,
      requestStream: false,
      responseType: SlackPublisher,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: UpdateSlackPublisherRequest,
      requestStream: false,
      responseType: SlackPublisher,
      responseStream: false,
      options: {},
    },
    updateTags: {
      name: "UpdateTags",
      requestType: UpdateSlackPublisherTagsRequest,
      requestStream: false,
      responseType: UpdateSlackPublisherTagsRequest_Response,
      responseStream: false,
      options: {},
    },
    enable: {
      name: "Enable",
      requestType: EnableSlackPublisherRequest,
      requestStream: false,
      responseType: EnableSlackPublisherRequest_Response,
      responseStream: false,
      options: {},
    },
    disable: {
      name: "Disable",
      requestType: DisableSlackPublisherRequest,
      requestStream: false,
      responseType: DisableSlackPublisherRequest_Response,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteSlackPublisherRequest,
      requestStream: false,
      responseType: DeleteSlackPublisherRequest_Response,
      responseStream: false,
      options: {},
    },
    history: {
      name: "History",
      requestType: SlackPublisherHistoryRequest,
      requestStream: false,
      responseType: SlackPublisherHistoryRequest_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
