/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logo-text': {
    width: 16,
    height: 16,
    viewBox: '0 0 430.46 124.62',
    data: '<path pid="0" d="M88.49 62.62v61.31h-24V68.61c0-13.58-8.39-19.37-18.78-19.37C33.37 49.24 27 61.62 27 61.62v62.31H3V-1.68h24v37.13s8.39-8.58 26.76-8.58 34.73 12.98 34.73 35.75zm50.32-64.13h24v39.43s8.79-11 26.76-11c24.56 0 46.13 20.57 46.13 49.73s-21.57 49.72-46.13 49.72c-20.37 0-28.56-12.26-28.56-12.26v9.87h-22.2zm24 92.27s6.79 13.2 22.56 13.2c16.18 0 26-11.78 26-27.36s-9.79-27.36-26-27.36c-15.77 0-22.56 15.14-22.56 15.14zm173.3-14.56c0 1.79 0 5.39-.2 9h-70.69c1.8 8.38 11.58 19.17 26.56 19.17 16.57 0 26-9.59 26-9.59l12 18a60.63 60.63 0 01-37.94 13.58c-28.75 0-50.92-22-50.92-49.72S261 26.87 288.39 26.87c25.96 0 47.72 20.13 47.72 49.33zm-24-8.39c-.4-8.19-8-19.17-23.76-19.17-15.35 0-22.35 10.78-23.13 19.17zm96.46-37.94l-8.39 21.57a27.49 27.49 0 00-11.39-2.2c-14.53 0-21.12 12.76-21.12 12.76v61.91h-24V29.27h22.76v9.82s8.19-12.22 25.77-12.22a51.47 51.47 0 0116.41 3zm7.82 71.59a11.08 11.08 0 11-11.08 11.08 11 11 0 0111.12-11.08zm0 19.89a8.84 8.84 0 10-8.81-8.81 8.83 8.83 0 008.85 8.81zM413 106.66h3.73a3.53 3.53 0 013.85 3.55 3.37 3.37 0 01-2.63 3.43l3 4.53h-2.32l-2.82-4.34H415v4.34h-2zm3.54 5.33c1.35 0 2.21-.61 2.21-1.78s-.86-1.77-2.21-1.77H415V112zM101.8 29.27h24v94.66h-24zM98.91-1.54a14.56 14.56 0 004.29 10.13 14.64 14.64 0 0020.72 0 14.61 14.61 0 004.29-10.13q-14.65.62-29.3 0z" _fill="#002d3c"/>'
  }
})
