import { defaultErrorHandler, successHandler, createUint8Array } from '@/store/core/utils';
import {
  getCertificatesList, uploadCertificateRequest, deleteCertificateRequest,
} from '@/services/certificates';

const Certificates = {
  namespaced: true,
  state: {
    certificates: {
      certificates: [],
      pagination: {
        size: 100,
        page: 0,
      },
    },
    undo: {
      certificates: {
        ids: [],
      },
    },
  },
  actions: {
    getCertificatesList(context, target) {
      return new Promise((resolve, reject) => {
        getCertificatesList(
          target ? target.pagination.size : 100,
          target ? target.pagination.page : 0,
        ).then((res) => {
          context.commit('getCertificatesList', res);
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    uploadCertificate(context, form) {
      const payload = {
        ...form,
      };
      payload.form.certificateBytes = createUint8Array(payload.form.certificate);
      const logMessage = {
        category: 'Certificate',
        name: payload.form.name,
        action: 'uploaded',
      };

      return new Promise((resolve, reject) => {
        uploadCertificateRequest(
          payload.form,
        ).then((res) => {
          successHandler(res, resolve, context, logMessage);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    deleteCertificateRequest(context, id) {
      const payload = {
        ...id,
      };
      return new Promise((resolve, reject) => {
        deleteCertificateRequest(
          payload.id,
        ).then(() => {
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    getCertificatesList(state, res) {
      state.certificates = res;
    },
    temporarilyRemoveItems(state, payload) {
      state.undo[payload.category].ids.push(payload.id);
    },
    clearTemporarilyRemovedItems(state, payload) {
      const index = state.undo[payload.category].ids.indexOf(payload.id);
      if (index > -1) {
        state.undo[payload.category].ids.splice(index, 1);
      }
    },
  },
  getters: {
  },
};

export default Certificates;
