import Firebase from 'firebase/app';
import 'firebase/auth';
import { defaultErrorHandler } from '@/store/core/utils';

const Auth = {
  namespaced: true,
  state: {
    preLoginRoute: '/',
  },
  actions: {
    OAuthSignIn(context) {
      return new Promise((resolve, reject) => {
        const provider = new Firebase.auth.GoogleAuthProvider();
        Firebase.auth().signInWithPopup(provider).then(() => {
          resolve();
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    saveRedirect(state, res) {
      state.preLoginRoute = res;
    },
  },
  getters: {

  },
};

export default Auth;
