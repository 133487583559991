import DeviceService from 'hiber-grpc/src/services/device';
import { getGrpcClient, getSupportGrpcClient } from '@/services/app';
import { defaultErrorHandler } from '@/store/core/utils';
import DeviceTypeSupportService from 'hiber-grpc/src/services/device-type-support';

const Device = {
  namespaced: true,
  state: {},
  actions: {
    updateDevice(context, payload) {
      return new Promise((resolve, reject) => {
        DeviceService.getInstance(getGrpcClient).updateDevices([payload.updatedDevices], payload.organization)
          .then((result) => {
            resolve(result);
          }).catch((error) => defaultErrorHandler(error, reject, context));
      });
    },
    assignDeviceType(context, payload) {
      return new Promise((resolve, reject) => {
        DeviceTypeSupportService.getInstance(getSupportGrpcClient).assignDeviceType({
          deviceType: payload.deviceType,
          force: true,
          modems: payload.modems,
        }).then((result) => {
          resolve(result);
        }).catch((error) => defaultErrorHandler(error, reject, context));
      });
    },
  },
};
export default Device;
