import moment from 'moment-timezone';
import { EventType } from 'hiber-grpc/src/customer_compiled/base';
import {
  OrganizationPermission,
  organizationPermissionToNumber,
  userPermissionToNumber,
} from 'hiber-grpc/src/customer_compiled/permission';
import { Organization_Feature } from 'hiber-grpc/src/customer_compiled/organization';

export const App = {
  name: 'Mission Control',
  code: 'mission-control',
};

export const EM_DASH = '—';
export function getLabelForModemDataColumn(column) {
  return {
    'number': 'Device #',
    'name': 'Device name',
    'well': 'Well',
    'site': 'Site',
    'production-area': 'Production area',
    'organization': 'Owner',
    'connected': 'Gateway',
    'battery-type': 'Battery type',
    'lifecycle': 'Lifecycle status',
    'device': 'Device',
    'device-type': 'Device type',
    'brand': 'Brand',
    'application': 'Application',
    'category': 'Category',
    'version': 'Version',
    'external-device': 'External device IDs',
    'transmission-interval': 'Transmission interval',
    'last-timestamp': 'Last timestamp',
    'health': 'Health status',
    'notes': 'Notes',
  }[column];
}

export function getReturnPeripheralMapItem(peripheralsMap, type) {
  if (!peripheralsMap) return 'Unknown';

  return peripheralsMap[type] || 'Unknown';
}

export function getExternalDeviceIdsListAsString(externalDeviceIdsList) {
  return externalDeviceIdsList.length > 0 ? externalDeviceIdsList.join(', ') : 'Unknown';
}

export function getRemainingTags(tagsList) {
  const tagNamesArray = tagsList.map(i => i.label.name);
  const [, ...remainingTags] = tagNamesArray;
  return remainingTags;
}

export function getModemTime(time, tzTimezone, appTimestamp) {
  if (!time.textual) {
    return 'Waiting for first message';
  }

  const textualTime = time.textual;
  if (textualTime.length && !!tzTimezone) {
    return moment(textualTime).tz(tzTimezone).format(appTimestamp);
  }
  if (typeof textualTime !== 'undefined' && textualTime._isAMomentObject && !appTimestamp) {
    return textualTime.format(appTimestamp);
  }

  return 'Unknown';
}

export function getModemHealthTooltip(modem, now) {
  const lastMessage = modem.lastMessageReceivedAt && moment(modem.lastMessageReceivedAt.textual);
  const dayDiff = now.diff(lastMessage, 'days');

  if (dayDiff >= modem.maximumInactivityPeriod) {
    return `No messages for <b>${dayDiff} days</b>`;
  }

  return '';
}

export function getAntennaNameByType(type, customText) {
  return customText || 'Custom';
}

export const eventsByCategory = {
  modems: {
    title: 'Modem',
    totalNumber: 0,
    events: [],
  },
  message_processing: {
    title: 'Message Processing',
    totalNumber: 0,
    events: [],
  },
  admin: {
    title: 'Admin',
    totalNumber: 0,
    events: [],
  },
  integrations: {
    title: 'Integrations',
    totalNumber: 0,
    events: [],

  },
  transfers: {
    title: 'Transfers',
    totalNumber: 0,
    events: [],
  },
};

// Can't find if [deprecated = true] in .proto files is being compiled to anything TS usable
export const deprecatedEvents = [49, 50, 51, 52, 53];

/* eslint-disable quotes */
export const eventCategories = {
  modems: {
    'Message Parsed': {
      id: [EventType.MESSAGE_BODY_PARSED],
      description: 'This event is generated whenever a message is parsed successfully by an assigned body parser. If multiple body parsers are assigned to a modem, multiple ModemMessageParsedEvent events will be produced by an incoming message.',
    },
    'Message Cannot Be Parsed': {
      id: [EventType.MESSAGE_CANNOT_BE_PARSED],
      description: 'Triggered when a message cannot be parsed. This can have multiple reasons, for example, an invalid message version.',
    },
    'Modem Created': {
      id: [EventType.DEVICE_CREATED],
    },
    'Modem Installed': {
      id: [EventType.DEVICE_ACTIVATED],
      description: 'When the modem was marked Installed.',
    },
    'Modem Updated': {
      id: [EventType.DEVICE_UPDATED],
    },
    'Message Received': {
      id: [EventType.MESSAGE_RECEIVED],
      description: 'This event is generated whenever a message comes in, after is has been decrypted.<br />It contains the relevant data in the message object, including location and the user-defined body. If any body parser(s) are configured for the modem, the modem message object also contains a ParsedBody for each of them.',
    },
    'Message Body Received': {
      id: [EventType.MESSAGE_BODY_RECEIVED],
      description: 'This lightweight event is generated whenever a message comes in, after is has been decrypted.',
    },
    'Location Updated': {
      id: [EventType.DEVICE_LOCATION_UPDATED],
      description: 'When a message comes in, the modem\'s location is updated automatically. This event is generated whenever the modem\'s location is updated',
    },
  },
  message_processing: {
    'Message Body Parser Created': {
      id: [EventType.MESSAGE_BODY_PARSER_CREATED],
    },
    'Message Body Parser Updated': {
      id: [EventType.MESSAGE_BODY_PARSER_UPDATED],
    },
    'Message Body Parser Deleted': {
      id: [EventType.MESSAGE_BODY_PARSER_DELETED],
    },
    'Modem Alarm Triggered Event': {
      id: [EventType.ALARM_TRIGGERED],
    },
    'Modem Alarm Created': {
      id: [EventType.ALARM_CREATED],
    },
    'Modem Alarm Updated': {
      id: [EventType.ALARM_UPDATED],
    },
    'Modem Alarm Deleted': {
      id: [EventType.ALARM_DELETED],
    },
  },
  admin: {
    'Organization Created': {
      id: [EventType.ORGANIZATION_CREATED],
    },
    'Organization Updated': {
      id: [EventType.ORGANIZATION_UPDATED],
    },
    'Organization Deleted': {
      id: [EventType.ORGANIZATION_DELETED],
    },
    'Access Request': {
      id: [EventType.USER_ACCESS_REQUEST],
    },
    'User Invited': {
      id: [EventType.USER_INVITED],
    },
    'User Added': {
      id: [EventType.USER_ADDED],
    },
    'User Removed': {
      id: [EventType.USER_REMOVED],
    },
    'User Validation Updated': {
      id: [EventType.USER_VALIDATION_UPDATED],
    },
  },
  integrations: {
    'Token Created': {
      id: [EventType.TOKEN_CREATED],
    },
    'Token Deleted': {
      id: [EventType.TOKEN_DELETED],
    },
    'Token Expiry Warning': {
      id: [EventType.TOKEN_EXPIRY_WARNING],
    },
    'Token Expired': {
      id: [EventType.TOKEN_EXPIRED],
    },
    'Publisher Created': {
      id: [EventType.PUBLISHER_CREATED],
    },
    'Publisher Deleted': {
      id: [EventType.PUBLISHER_DELETED],
    },
    'Publisher Failed': {
      id: [EventType.PUBLISHER_FAILED],
    },
    'Publisher Updated': {
      id: [EventType.PUBLISHER_UPDATED],
    },
    'Publisher Auto-Disabled': {
      id: [EventType.PUBLISHER_AUTO_DISABLED],
    },
  },
  transfers: {
    Transfer: {
      id: [EventType.TRANSFER],
    },
  },
};

export const MENU_ITEMS = Object.freeze({
  HILO: {
    name: 'HiberHilo Dashboard',
    icon: 'hilo',
    route: 'hiberhilo',
    permissions: [],
    fa: false,
    feature: Organization_Feature.HILO,
  },
  MODEMS: {
    name: 'Devices',
    icon: 'modems',
    route: 'list/modems',
    permissions: [OrganizationPermission.MODEMS],
    fa: false,
  },
  NOTIFICATIONS: {
    name: 'Notifications',
    icon: 'bell',
    route: 'events',
    permissions: [],
    fa: true,
  },
  TRANSFERS: {
    name: 'Transfers',
    icon: 'dolly',
    route: 'transfers',
    permissions: [OrganizationPermission.TRANSFERS],
    fa: true,
  },
  DEVTOOLS: {
    name: 'Developer Tools',
    icon: 'brackets-curly',
    route: 'developers/',
    permissions: [OrganizationPermission.PUBLISHERS, OrganizationPermission.TOKENS],
    fa: true,
  },
  DASHBOARD: {
    name: 'Map',
    icon: 'map-marked-alt',
    route: 'list/map',
    exact: true,
    permissions: [],
    fa: true,
  },
  SETTINGS: {
    name: 'Settings',
    icon: 'cog',
    route: 'settings',
    permissions: [],
    fa: true,
  },
});

export const REDIRECT_MAPPING = [
  {
    from: '/ep/assets/edit',
    to: [MENU_ITEMS.DASHBOARD.route],
  },
];

export const EP_TABLE = 'ep/assets';

export function getOrgPermissionsByType(type) {
  switch (organizationPermissionToNumber(type)) {
    case 0:
      return {
        title: 'Default',
        desc: 'Unknown user permission',
      };
    case 1:
      return {
        title: 'Organization create',
        desc: 'Create a new child organization',
      };
    case 2:
      return {
        title: 'Organization update',
        desc: 'Update the organizations data, such as billing information, address, and contact.',
      };
    case 3:
      return {
        title: 'Organization delete',
        desc: 'Delete child organizations. You cannot delete your own organization. To delete your organization, contact support.',
      };
    case 10:
      return {
        title: 'Modems',
        desc: 'List modems, see their details and health',
      };
    case 11:
      return {
        title: 'Modems update',
        desc: 'Update modems, such as their peripherals, display name and tags. (Includes modems permission.)',
      };
    case 12:
      return {
        title: 'Modems license keys',
        desc: 'Show and regenerate license keys. (Includes modems permission.)',
      };
    case 17:
      return {
        title: 'Modem values',
        desc: 'Read modem(s) values',
      };
    case 15:
      return {
        title: 'Modem messages',
        desc: 'Read modem messages.',
      };
    case 16:
      return {
        title: 'Modem messages send test messages',
        desc: 'Send modem messages using the TestingService. (Does not include modems or messages permission.)',
      };
    case 20:
      return {
        title: 'Modem transfers',
        desc: 'See modem transfers, inbound and outbound modems. (Includes modems permission.)',
      };
    case 21:
      return {
        title: 'Modem transfers send',
        desc: 'Transfer modems to another organization, cancel open transfers and send return transfers. (Includes modems transfers permission.)',
      };
    case 22:
      return {
        title: 'Modem transfers process',
        desc: 'Mark transfers as received, prepare modems for return. This does not include actually sending the return transfer. (Includes modems_transfers permission.)',
      };
    case 25:
      return {
        title: 'Modem claim',
        desc: 'Claiming modems.',
      };
    case 30:
      return {
        title: 'Users',
        desc: 'List all users, see their names and email addresses.',
      };
    case 31:
      return {
        title: 'Users manage',
        desc: 'Approve or create new users, remove users from the organization. (Includes users permission.)',
      };
    case 35:
      return {
        title: 'Publishers',
        desc: 'Manage publishers.',
      };
    case 36:
      return {
        title: 'Tokens',
        desc: 'Manage tokens.',
      };
    case 37:
      return {
        title: 'Lorawan',
        desc: 'Manage LoRaWAN integration.',
      };
    case 38:
      return {
        title: 'Certificates',
        desc: 'Read and use uploaded certificates (i.e. for publishers).',
      };
    case 39:
      return {
        title: 'Certificates manage',
        desc: 'Upload certificates, and update or delete uploaded certificates.',
      };
    case 41:
      return {
        title: 'Modem messages send real messages',
        desc: 'Send modem messages using real message sources, i.e. a gateway with an internet connection. (Does not include modems or messages permission.)',
      };
    case 42:
      return {
        title: 'Secure Modem notes',
        desc: 'See and update secure notes on a modem. (Includes modems permission.)',
      };
    case 43:
      return {
        title: 'Modem message body parsers',
        desc: 'Manage and assign message body parsers. Includes MODEMS permission.',
      };
    case 44:
      return {
        title: 'Modems alarms',
        desc: 'Manage and assign message alerts. Includes MODEMS permission.',
      };
    case 45:
      return {
        title: 'Assignments',
        desc: 'Manage assignments using the assignment services and individual calls on, for example, ModemService.',
      };
    case 46:
      return {
        title: 'Simulation manage',
        desc: 'Manage simulations, like modem message simulation.',
      };
    case 47:
      return {
        title: 'Health manage',
        desc: 'Manage custom health levels.',
      };
    case 48:
      return {
        title: 'Locations manage',
        desc: 'Manage saved locations using the OrganizationLocationService.',
      };
    case 49:
      return {
        title: 'Export',
        desc: 'Create, access and download exports.',
      };
    case 50:
      return {
        title: 'Modems create',
        desc: 'Create new modems. Includes MODEMS permission.',
      };
    case 51:
      return {
        title: 'Modem downlink messages',
        desc: 'Send modem downlink messages.',
      };
    default:
      return {
        title: 'Unknown organization permission',
        desc: 'Unknown organization permission',
      };
  }
}

export function getUserPermissionsByType(type) {
  switch (userPermissionToNumber(type)) {
    case 0:
      return {
        title: 'Read',
        desc: 'Read your name, email, linked organizations and mission-control settings.',
      };
    case 1:
      return {
        title: 'Update',
        desc: 'Update your personal information, mission-control settings and default organization. Includes read permission.',
      };
    case 2:
      return {
        title: 'Request Access',
        desc: 'Request access to an organization and cancel open requests. Does not include read permission.',
      };
    case 3:
      return {
        title: 'Delete',
        desc: 'Delete your user account permanently. Includes read permission',
      };
    default:
      return {
        title: 'Unknown user permission',
        desc: 'Unknown user permission',
      };
  }
}

// https://github.com/grpc/grpc-go/blob/master/codes/codes.go
export function getGrpcCode(type) {
  switch (type) {
    case 0:
      return 'OK';
    case 1:
      return 'Canceled';
    case 2:
      return 'Unknown';
    case 3:
      return 'InvalidArgument';
    case 4:
      return 'DeadlineExceeded';
    case 5:
      return 'NotFound';
    case 6:
      return 'AlreadyExists';
    case 7:
      return 'PermissionDenied';
    case 8:
      return 'ResourceExhausted';
    case 9:
      return 'FailedPrecondition';
    case 10:
      return 'Aborted';
    case 11:
      return 'OutOfRange';
    case 12:
      return 'Unimplemented';
    case 13:
      return 'InternalError';
    case 14:
      return 'Unavailable';
    case 15:
      return 'DataLoss';
    case 16:
      return 'Unauthenticated';
    default:
      return 'Unknown GRPC code';
  }
}
