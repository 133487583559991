import { useQuery } from '@tanstack/vue-query';
import useStore from '@/store/useStore';
import { computed, unref } from 'vue';
import RoleService from 'hiber-grpc/src/services/role';
import { getGrpcClient } from '@/services/app';

// eslint-disable-next-line import/prefer-default-export
export function useRolesQuery() {
  const { $store } = useStore();
  const org = computed(() => $store.value.state.Organization.organizationImpersonation);
  const enabled = computed(() => $store.value.state.Organization?.organization?.organization?.length > 0);

  return useQuery({
    queryKey: ['roles', org],
    queryFn: () => RoleService.getInstance(getGrpcClient).list({}),
    staleTime: 30 * 60 * 1000, // roles might not change often - stale time 30 minutes
    enabled: unref(enabled),
  });
}
