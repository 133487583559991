import store from '@/store/index';
// @ts-ignore
import domainconfig from '../../config/domainconfig';
import { getGrpcCode } from '@/constants';
import Firebase from '../injections/firebase';
import * as Sentry from '@sentry/vue';
import {
  CallOptions,
  Client,
  ClientError,
  ClientMiddlewareCall,
  CompatServiceDefinition,
  createChannel,
  createClientFactory,
  Metadata,
} from 'nice-grpc-web';

const hasGrpcWebDevTools = (window) => (window && typeof window.__GRPCWEB_DEVTOOLS__ === 'function');

// API endpoints
export const host = domainconfig.host;

export function getAuth() {
  return new Promise((resolve, reject) => {
    Firebase.auth()?.currentUser?.getIdToken().then((res: string) => {
      const auth = `Bearer ${ res }`;
      resolve(auth);
    }).catch((res: any) => {
      Sentry.captureException(res, {
        extra: { call: 'getAuth' },
      });
      reject();
    });
  });
}

// eslint-disable-next-line import/no-mutable-exports
export let organizationToImpersonate = '';

export function impersonateOrg() {
  return new Promise((resolve) => {
    organizationToImpersonate = store.state.Organization.organizationImpersonation;
    resolve(organizationToImpersonate);
  });
}

// @Auth
export function userCheck() {
  return new Promise((resolve, reject) => {
    if (Firebase.auth().currentUser) {
      getAuth().then((auth) => {
        resolve(auth);
      }).catch(() => {
        reject();
      });
    } else {
      reject();
    }
  });
}

async function* grpcDevToolsMiddleware<Request, Response>(
  call: ClientMiddlewareCall<Request, Response>,
  options: CallOptions,
) {
  const { path } = call.method;

  try{
    const response = yield* call.next(call.request, options);

    if (hasGrpcWebDevTools(window)) {
      const { request, ...rest } = (response as any);
      window.postMessage(
        {
          type: '__GRPCWEB_DEVTOOLS__',
          method: path,
          methodType: 'unary',
          request: call.request,
          response: rest,
        },
        '*',
      );
    }

    return response;
  } catch (error) {
    if (hasGrpcWebDevTools(window)) {
      window.postMessage(
        {
          type: '__GRPCWEB_DEVTOOLS__',
          method: path,
          methodType: 'unary',
          request: call.request,
          response: error,
          error: error,
        },
        '*',
      );
    }
    throw error;
  }

}

async function* loggingMiddleware<Request, Response>(
  call: ClientMiddlewareCall<Request, Response>,
  options: CallOptions,
) {
  // Log these things by default as extra info
  const extraLog = {
    appVersion: store.state.version,
    organization: store.state.Organization.organization.organization,
    userId: store.state.User.currentUser.id,
    organizationImpersonation: '',
  };
  if (store.state.Organization.organizationImpersonation !== '') {
    extraLog.organizationImpersonation = store.state.Organization.organizationImpersonation;
  }
  const { path } = call.method;
  try {
    return yield* call.next(call.request, options);
  } catch (error) {
    if (error instanceof ClientError) {
      console.group('gRPC Request failed');
      console.log(
        `%cThe call to ${ path } has failed!`,
        'color: #E02F2F; font-weight: bold; font-size: 12px',
      );
      console.log(`request: %O`, JSON.stringify(call.request, null, 2));
      console.groupEnd();
      if (error.message === 'Response closed without headers') {
        // Connection error
        Sentry.captureException('Connection error: Response closed without headers.', {
          extra: { ...extraLog, call: path },
        });
      } else if (!error.message) {
        // List under its GRPC code error.
        Sentry.captureException(`${ getGrpcCode(error.code) } error with no message`, {
          extra: { ...extraLog, call: path },
        });
      } else {
        // Regular error
        Sentry.captureException(error.stack, {
          extra: { ...extraLog, call: path },
        });
      }
    } else {
      Sentry.captureException(error.stack, {
        extra: { call: path },
      });
    }

    // Log extra data to console for debugging by screenshot
    console.warn(`v: ${ extraLog.appVersion }, user: ${ extraLog.userId }, org: ${ extraLog.organization }`);
    if (store.state.Organization.organizationImpersonation !== '') {
      console.warn(`Impersonating organization: ${ store.state.Organization.organizationImpersonation }`);
    }
    throw error;
  }
}

async function* authMiddleware<Request, Response>(
  call: ClientMiddlewareCall<Request, Response>,
  options: CallOptions,
) {
  try {
    const auth = await userCheck();
    await impersonateOrg();

    options.metadata = Metadata({ 'authorization': auth } as any);
    const { organization, groups, groupContent } = call.request as any;

    // special case when the request includes group requests {groups:{}, groupContent:{}}
    const isGroupRequest = groups && groupContent &&
      Object.prototype.hasOwnProperty.call(groups, 'organization') &&
      Object.prototype.hasOwnProperty.call(groupContent, 'organization');

    if (isGroupRequest) {
      groups.organization = organizationToImpersonate;
      groupContent.organization = organizationToImpersonate;
    }

    if (!organization) {
      (call.request as any).organization = organizationToImpersonate;
    }

    return yield* call.next(call.request, options);
  } catch (e) {
    throw e;
  }
}

const grpcClientFactory = createClientFactory().use(grpcDevToolsMiddleware).use(loggingMiddleware).use(authMiddleware);

const channel = createChannel(host.customer);
const supportChannel = createChannel(host.support);

let grpcClient;
export const getGrpcClient = <T extends CompatServiceDefinition>(service): Client<T> => {
  grpcClient = grpcClientFactory.create(service, channel);
  return grpcClient;
};
export const getSupportGrpcClient = <T extends CompatServiceDefinition>(service): Client<T> => {
  grpcClient = grpcClientFactory.create(service, supportChannel);
  return grpcClient;
};

