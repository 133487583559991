import {
  createOrganizationRequest,
  getOrganizationAvatar,
  listChildOrganizations,
  validateCreationToken,
} from '@/services/organization';
import { defaultErrorHandler, successHandler } from '@/store/core/utils';
import OrganizationService from 'hiber-grpc/src/services/organization';
import { getGrpcClient } from '@/services/app';

const urlSearchParams = new URLSearchParams(window.location.search);
const toImpersonate = urlSearchParams.get('organization');

const Organization = {
  namespaced: true,
  state: {
    listSearch: '',
    organization: {},
    organizationAvatar: [],
    organizationImpersonation: toImpersonate || '',
    childOrganizations: {
      childOrganizations: [],
      pagination: {
        size: 100,
        page: 0,
      },
    },
  },
  actions: {
    getOrganization(context) {
      return new Promise((resolve, reject) => {
        const currentOrg = context.state.organization;
        const orgToImpersonate = context.state.organizationImpersonation;
        if (currentOrg.organization === orgToImpersonate) {
          resolve(currentOrg);
          return;
        }
        OrganizationService.getInstance(getGrpcClient).getOrganization().then((res) => {
          context.commit('getOrganization', res);
          context.commit('setOrganizationToImpersonate', res.organization);
          context.dispatch('getCurrentOrganizationAvatar', { organizations: [context.state.organization.organization] });
          resolve(res);
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    getCurrentOrganizationAvatar(context, organizations) {
      const payload = {
        ...organizations,
      };
      return new Promise((resolve, reject) => {
        getOrganizationAvatar(payload.organizations).then((res) => {
          context.commit('setOrganizationAvatar', res);
          resolve();
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    getOrganizationAvatar(context, organizations) {
      const payload = {
        ...organizations,
      };
      return new Promise((resolve, reject) => {
        getOrganizationAvatar(payload.organizations).then((res) => {
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    listChildOrganizations(context, payload) {
      return new Promise((resolve, reject) => {
        listChildOrganizations(
          context.state.listSearch,
          payload ? payload.pagination.size : 100,
          payload ? payload.pagination.page : 0,
        ).then((res) => {
          context.commit('listChildOrganizations', res);
          resolve();
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    validateCreationToken(context, token) {
      return new Promise((resolve, reject) => {
        validateCreationToken(token).then((res) => {
          // this is handled on the side of the input form
          // Auth/OrganizationRequest
          if (res.valid) {
            resolve(res);
          } else {
            defaultErrorHandler(res, reject, context);
          }
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    createOrganizationRequest(context, createForm) {
      const logMessage = {
        category: 'Child organization',
        name: createForm.display,
        action: 'created',
      };

      return new Promise((resolve, reject) => {
        createOrganizationRequest(createForm, context.state.organizationImpersonation).then((res) => {
          context.commit('createOrganizationRequest', res);
          successHandler(res, resolve, context, logMessage);
        }).catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    changeOrganization(context, organization) {
      return new Promise((resolve) => {
        context.commit('setOrganizationToImpersonate', organization);
        resolve();
      });
    },
  },
  mutations: {
    updateSearch(state, res) {
      state.listSearch = res;
    },
    getOrganization(state, res) {
      state.organization = res;
    },
    setOrganizationAvatar(state, res) {
      if (Object.values(res.avatars).length) {
        const urlOrImage = Object.values(res.avatars)[0].urlOrImage;
        state.organizationAvatar = urlOrImage;
      } else {
        state.organizationAvatar = [];
      }
    },
    listChildOrganizations(state, res) {
      state.childOrganizations = res;
    },
    createOrganizationRequest() {
      // left empty for vuex actions logging
    },
    setOrganizationToImpersonate(state, res) {
      state.organizationImpersonation = res;
    },
  },
  getters: {
    featuresList(state) {
      return state.organization.features || [];
    },
    hasOrganizationPermissionIds(state) {
      return (permissionIds) => {
        // always true for en empty set
        if (permissionIds === undefined || !permissionIds.length) {
          return true;
        }

        const grantedOrganizationPermissions = state.organization.features;
        return permissionIds.every(val => grantedOrganizationPermissions?.includes(val));
      };
    },
  },
};

export default Organization;
