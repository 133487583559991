import { defaultErrorHandler } from '@/store/core/utils';
import { getTileMap, getLocationHistory } from '@/services/map';
import moment from 'moment';
import Cookies from 'js-cookie';

const MAP_SETTINGS_COOKIE_NAME = 'h_map_settings';
const Map = {
  namespaced: true,
  state: {
    _latestSelectedModemId: null,
    _latestSelectedModemName: null,
    resetMap: false,
    reinitMap: false,
    latestMapSettings: {},
    tileMap: {
      satellites: [],
      groundStations: [],
      mapItems: [],
    },
    dateRange: {
      start: moment().startOf('day'),
      end: moment(),
    },
  },
  actions: {
    setLatestSelectedModem(context, latestModem) {
      return new Promise((resolve, reject) => {
        if (latestModem.id || latestModem.name) {
          if (latestModem.id) {
            context.commit('setLatestSelectedModem', latestModem.id);
          }
          if (latestModem.name) {
            context.commit('setLatestModemName', latestModem.name);
          }
          resolve();
        } else {
          reject();
        }
      });
    },
    getTileMap(context, selection) {
      return new Promise((resolve, reject) => {
        // See `@/services/map.ts` for the selection interface
        // ILocationSelection
        getTileMap(selection)
          .then(res => {
            context.commit('getTileMap', res);
            resolve();
          })
          .catch(res => defaultErrorHandler(res, reject, context));
      });
    },
    getLocationHistory(context, sel) {
      return new Promise((resolve, reject) => {
        getLocationHistory(sel.modem_number, sel.startDate, sel.endDate, sel.level)
          .then(res => {
            resolve(res.history);
          })
          .catch(res => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    setLatestSelectedModem(state, latestSelectedModemId) {
      state._latestSelectedModemId = latestSelectedModemId;
    },
    setLatestModemName(state, latestSelectedModemName) {
      state._latestSelectedModemName = latestSelectedModemName;
    },
    getTileMap(state, res) {
      state.tileMap = res;
    },
    setResetMap(state, res) {
      state.resetMap = res;
    },
    setReinitMap(state, res) {
      state.reinitMap = res;
    },
    setMapSettings(state, { mapName, mapSettings }) {
      state.latestMapSettings[mapName] = mapSettings;
      Cookies.set(MAP_SETTINGS_COOKIE_NAME, JSON.stringify({ ...state.latestMapSettings }));
    },
    setMapDateRange(state, res) {
      state.dateRange = res;
    },
  },
  getters: {
    getLatestSelectedModemId(state) {
      return state._latestSelectedModemId;
    },
    getLatestSelectedModemName(state) {
      return state._latestSelectedModemName;
    },
    getLatestMapSettings: (state) => (mapName) => {
      const mapSettingsJson = Cookies.get(MAP_SETTINGS_COOKIE_NAME);
      if (mapSettingsJson) {
        const mapSettings = JSON.parse(mapSettingsJson);
        if (mapSettings && mapSettings[mapName]) return mapSettings[mapName];
      }
      return state.latestMapSettings[mapName];
    },
    getMapDateRange: (state) => state.dateRange,
  },
};

export default Map;
