import { Organization_Feature } from 'hiber-grpc/src/customer_compiled/organization';
import store from '@/store';
import { MENU_ITEMS } from '@/constants';
import { OrganizationPermission } from 'hiber-grpc/src/customer_compiled/permission';

type MenuItem = {
  name: string;
  icon: string;
  route: string;
  permissions: OrganizationPermission[];
  fa: boolean;
  feature?: Organization_Feature;
};
export function getMenuItems(): Array<MenuItem> {
  const { HILO, DASHBOARD, MODEMS, NOTIFICATIONS, TRANSFERS, SETTINGS, DEVTOOLS } = MENU_ITEMS;
  const features = store.getters['Organization/featuresList'];

  const items: MenuItem[] = [];
  const aggregatedItems = items
    .concat([HILO])
    .concat([DASHBOARD])
    .concat([MODEMS])
    .concat([NOTIFICATIONS])
    .concat([SETTINGS])
    .concat([TRANSFERS, DEVTOOLS]);

  return aggregatedItems.filter(el => store.getters['User/hasOrganizationPermissionIds'](el.permissions))
    .filter(el => el.feature ? !features.length || features.includes(el.feature) : true);
}
