import { getGrpcClient } from '@/services/app';

import {
  LocationHistory_Request,
  MapServiceDefinition,
  TileMapRequest,
  tileMapRequest_DensityFromJSON,
} from 'hiber-grpc/src/customer_compiled/map';
import { Area } from 'hiber-grpc/src/customer_compiled/base';

/*
Determine density of markers on the map
from map.proto
  DEFAULT = 0; Fills a tile with 8x8 icons.
  DENSE = 1;   Fills a tile with 16x16 icons.
  SPARSE = 2;   Fills a tile with 4x4 icons.
  VERY_SPARSE = 3;   Fills a tile with 2x2 icons.
  SINGLE = 4;   Fills a tile with 1 icon.
*/
const getDensity =  (zoomLevel) => {
  switch (true) {
    case (zoomLevel <= 4):
      return 3;
    case (zoomLevel <= 6):
      return 2;
    case (zoomLevel <= 8):
      return 1;
    default:
      return 0;
  }
};

export function getTileMap(selection) {
  const request = TileMapRequest.fromPartial({
    density: tileMapRequest_DensityFromJSON(getDensity(selection?.locationSelection?.level || 2)),
    level: selection.locationSelection?.level || 2,
    selection: {
      areas: [Area.fromPartial({
        bottomLeft: selection.locationSelection?.area?.bottomLeft,
        topRight: selection.locationSelection?.area?.topRight,
      })],
      shapes: selection.locationSelection?.shape?.pathList,
    },
    modemSelection: {
      modems: { include: selection.modemNrs },
      freeTextSearch: selection.filters?.search || '',
    },
    expanded: { $case: 'includeHealth', includeHealth: true },
  });
  const client = getGrpcClient<typeof MapServiceDefinition>(MapServiceDefinition);
  return client.tileMap(request);
}

export function getLocationHistory(modem_number: string, startDate: string, endDate: string, zoomLevel: number) {
  const request = LocationHistory_Request.fromPartial({
    timeRange: { start: { textual: startDate }, end: { textual: endDate } },
    level: zoomLevel,
    modemNumber: modem_number,
  });

  const client = getGrpcClient<typeof MapServiceDefinition>(MapServiceDefinition);
  return client.locationHistory(request);
}
