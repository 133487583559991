import { createExport } from '@/services/export';
import { defaultErrorHandler } from '@/store/core/utils';

const Export = {
  namespaced: true,
  actions: {
    createExport(context, payload) {
      return new Promise((resolve, reject) => {
        createExport(payload.modemNrs, payload.range, payload.type, payload.organization).then(res => {
          resolve(res);
        }).catch((err) => defaultErrorHandler(err, reject, context));
      });
    },
  },
};

export default Export;
