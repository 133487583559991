/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import {
  DateMessage,
  Filter_OrganizationPermissions,
  Filter_Roles,
  Filter_UserPermissions,
  Filter_Users,
  Pagination,
  Pagination_Result,
  Timestamp,
} from "./base";
import {
  OrganizationPermission,
  organizationPermissionFromJSON,
  organizationPermissionToNumber,
  UserPermission,
  userPermissionFromJSON,
  userPermissionToNumber,
} from "./permission";

export const protobufPackage = "hiber.token";

export interface Token {
  id: number;
  name: string;
  userId: string;
  userDetails: Token_UserDetails | undefined;
  organization: string;
  expiresAt: Timestamp | undefined;
  userPermissions: UserPermission[];
  organizationPermissions: OrganizationPermission[];
  roles: string[];
  type: Token_Type;
  /** Date that the token was last used. */
  lastUsed?:
    | DateMessage
    | undefined;
  /** Set if the current request is made with this token. */
  usedForThisCall: boolean;
}

export enum Token_Type {
  /** SPECIFIC - A token with a specific set of permissions, given when the token is created. */
  SPECIFIC = "SPECIFIC",
  /**
   * PERSONAL - A personal access token has the permissions of the creator.
   * When the creator gains or loses permissions, this applies to the token as well.
   */
  PERSONAL = "PERSONAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function token_TypeFromJSON(object: any): Token_Type {
  switch (object) {
    case 0:
    case "SPECIFIC":
      return Token_Type.SPECIFIC;
    case 1:
    case "PERSONAL":
      return Token_Type.PERSONAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Token_Type.UNRECOGNIZED;
  }
}

export function token_TypeToNumber(object: Token_Type): number {
  switch (object) {
    case Token_Type.SPECIFIC:
      return 0;
    case Token_Type.PERSONAL:
      return 1;
    case Token_Type.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Token_UserDetails {
  id: string;
  email: string;
  name: string;
}

export interface TokenSelection {
  users?: Filter_Users | undefined;
  name?: string | undefined;
  includeExpired?: boolean | undefined;
  roles?: Filter_Roles | undefined;
  type: Token_Type[];
}

export interface ListTokensRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the tokens to list. Optional, when omitted or empty everything is included. */
  selection?: TokenSelection | undefined;
  pagination?: Pagination | undefined;
}

export interface ListTokensRequest_Response {
  tokens: Token[];
  request: ListTokensRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface CreateTokenRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  name: string;
  type: Token_Type;
  expiresAt: Timestamp | undefined;
  userPermissions?:
    | Filter_UserPermissions
    | undefined;
  /** Permissions the new token should get. */
  organizationPermissions:
    | Filter_OrganizationPermissions
    | undefined;
  /** Roles the new token should get. */
  roles?:
    | Filter_Roles
    | undefined;
  /**
   * Optionally, if you have the USERS_MANAGE permission, you can make a token for another user.
   * If you do, you cannot grant it permissions they do not have, not can you grant it any user permissions.
   */
  forUserId?: string | undefined;
}

export interface CreateTokenRequest_Response {
  token: string;
  created: Token | undefined;
}

export interface DeleteTokenRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  tokenId: number;
}

export interface DeleteTokenRequest_Response {
}

export interface UpdateTokenOrganizationPermissionsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  tokenIds: number[];
  /** Completely replace the organization permission for the selected token(s) with this set. */
  replaceOrganizationPermissions?:
    | Filter_OrganizationPermissions
    | undefined;
  /** Add organization permissions to the token. */
  addOrganizationPermissions: OrganizationPermission[];
  /**
   * Remove organization permissions from the token.
   * Ensures the permissions is no longer on the token (even if you also add it using add_organization_permissions).
   */
  removeOrganizationPermissions: OrganizationPermission[];
}

export interface UpdateTokenOrganizationPermissionsRequest_Response {
}

export interface UpdateTokenUserPermissionsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  tokenIds: number[];
  /** Completely replace the user permission for the selected token(s) with this set. */
  replaceUserPermissions?:
    | Filter_UserPermissions
    | undefined;
  /** Add user permissions to the token. */
  addUserPermissions: UserPermission[];
  /** Remove user permissions from the token. */
  removeUserPermissions: UserPermission[];
}

export interface UpdateTokenUserPermissionsRequest_Response {
}

export interface UpdateTokenRoles {
}

export interface UpdateTokenRoles_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  tokenIds: number[];
  update?: { $case: "modify"; modify: UpdateTokenRoles_Request_ModifyRoles } | {
    $case: "replace";
    replace: UpdateTokenRoles_Request_ReplaceRoles;
  } | undefined;
}

/** Grant and remove roles on the current roles the tokens have. */
export interface UpdateTokenRoles_Request_ModifyRoles {
  /** Grant roles in addition to the current roles the tokens have. */
  add: string[];
  /** Remove roles from the current roles the tokens have. */
  remove: string[];
}

/** Completely replace the roles the tokens have. */
export interface UpdateTokenRoles_Request_ReplaceRoles {
  roles: string[];
}

export interface UpdateTokenRoles_Response {
}

function createBaseToken(): Token {
  return {
    id: 0,
    name: "",
    userId: "",
    userDetails: undefined,
    organization: "",
    expiresAt: undefined,
    userPermissions: [],
    organizationPermissions: [],
    roles: [],
    type: Token_Type.SPECIFIC,
    lastUsed: undefined,
    usedForThisCall: false,
  };
}

export const Token = {
  encode(message: Token, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.userId !== "") {
      writer.uint32(26).string(message.userId);
    }
    if (message.userDetails !== undefined) {
      Token_UserDetails.encode(message.userDetails, writer.uint32(90).fork()).ldelim();
    }
    if (message.organization !== "") {
      writer.uint32(34).string(message.organization);
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(58).fork();
    for (const v of message.userPermissions) {
      writer.int32(userPermissionToNumber(v));
    }
    writer.ldelim();
    writer.uint32(66).fork();
    for (const v of message.organizationPermissions) {
      writer.int32(organizationPermissionToNumber(v));
    }
    writer.ldelim();
    for (const v of message.roles) {
      writer.uint32(74).string(v!);
    }
    if (message.type !== Token_Type.SPECIFIC) {
      writer.uint32(80).int32(token_TypeToNumber(message.type));
    }
    if (message.lastUsed !== undefined) {
      DateMessage.encode(message.lastUsed, writer.uint32(106).fork()).ldelim();
    }
    if (message.usedForThisCall === true) {
      writer.uint32(96).bool(message.usedForThisCall);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Token {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.userDetails = Token_UserDetails.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag === 56) {
            message.userPermissions.push(userPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.userPermissions.push(userPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 8:
          if (tag === 64) {
            message.organizationPermissions.push(organizationPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.organizationPermissions.push(organizationPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.roles.push(reader.string());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.type = token_TypeFromJSON(reader.int32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.lastUsed = DateMessage.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.usedForThisCall = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Token>): Token {
    return Token.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Token>): Token {
    const message = createBaseToken();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.userId = object.userId ?? "";
    message.userDetails = (object.userDetails !== undefined && object.userDetails !== null)
      ? Token_UserDetails.fromPartial(object.userDetails)
      : undefined;
    message.organization = object.organization ?? "";
    message.expiresAt = (object.expiresAt !== undefined && object.expiresAt !== null)
      ? Timestamp.fromPartial(object.expiresAt)
      : undefined;
    message.userPermissions = object.userPermissions?.map((e) => e) || [];
    message.organizationPermissions = object.organizationPermissions?.map((e) => e) || [];
    message.roles = object.roles?.map((e) => e) || [];
    message.type = object.type ?? Token_Type.SPECIFIC;
    message.lastUsed = (object.lastUsed !== undefined && object.lastUsed !== null)
      ? DateMessage.fromPartial(object.lastUsed)
      : undefined;
    message.usedForThisCall = object.usedForThisCall ?? false;
    return message;
  },
};

function createBaseToken_UserDetails(): Token_UserDetails {
  return { id: "", email: "", name: "" };
}

export const Token_UserDetails = {
  encode(message: Token_UserDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Token_UserDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToken_UserDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Token_UserDetails>): Token_UserDetails {
    return Token_UserDetails.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Token_UserDetails>): Token_UserDetails {
    const message = createBaseToken_UserDetails();
    message.id = object.id ?? "";
    message.email = object.email ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseTokenSelection(): TokenSelection {
  return { users: undefined, name: undefined, includeExpired: undefined, roles: undefined, type: [] };
}

export const TokenSelection = {
  encode(message: TokenSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.users !== undefined) {
      Filter_Users.encode(message.users, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.includeExpired !== undefined) {
      writer.uint32(32).bool(message.includeExpired);
    }
    if (message.roles !== undefined) {
      Filter_Roles.encode(message.roles, writer.uint32(42).fork()).ldelim();
    }
    writer.uint32(50).fork();
    for (const v of message.type) {
      writer.int32(token_TypeToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TokenSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTokenSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users = Filter_Users.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeExpired = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.roles = Filter_Roles.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag === 48) {
            message.type.push(token_TypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.type.push(token_TypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TokenSelection>): TokenSelection {
    return TokenSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TokenSelection>): TokenSelection {
    const message = createBaseTokenSelection();
    message.users = (object.users !== undefined && object.users !== null)
      ? Filter_Users.fromPartial(object.users)
      : undefined;
    message.name = object.name ?? undefined;
    message.includeExpired = object.includeExpired ?? undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? Filter_Roles.fromPartial(object.roles)
      : undefined;
    message.type = object.type?.map((e) => e) || [];
    return message;
  },
};

function createBaseListTokensRequest(): ListTokensRequest {
  return { organization: undefined, selection: undefined, pagination: undefined };
}

export const ListTokensRequest = {
  encode(message: ListTokensRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      TokenSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTokensRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTokensRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = TokenSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTokensRequest>): ListTokensRequest {
    return ListTokensRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTokensRequest>): ListTokensRequest {
    const message = createBaseListTokensRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? TokenSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListTokensRequest_Response(): ListTokensRequest_Response {
  return { tokens: [], request: undefined, pagination: undefined };
}

export const ListTokensRequest_Response = {
  encode(message: ListTokensRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tokens) {
      Token.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListTokensRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTokensRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTokensRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokens.push(Token.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListTokensRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTokensRequest_Response>): ListTokensRequest_Response {
    return ListTokensRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTokensRequest_Response>): ListTokensRequest_Response {
    const message = createBaseListTokensRequest_Response();
    message.tokens = object.tokens?.map((e) => Token.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListTokensRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseCreateTokenRequest(): CreateTokenRequest {
  return {
    organization: undefined,
    name: "",
    type: Token_Type.SPECIFIC,
    expiresAt: undefined,
    userPermissions: undefined,
    organizationPermissions: undefined,
    roles: undefined,
    forUserId: undefined,
  };
}

export const CreateTokenRequest = {
  encode(message: CreateTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== Token_Type.SPECIFIC) {
      writer.uint32(72).int32(token_TypeToNumber(message.type));
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(34).fork()).ldelim();
    }
    if (message.userPermissions !== undefined) {
      Filter_UserPermissions.encode(message.userPermissions, writer.uint32(42).fork()).ldelim();
    }
    if (message.organizationPermissions !== undefined) {
      Filter_OrganizationPermissions.encode(message.organizationPermissions, writer.uint32(50).fork()).ldelim();
    }
    if (message.roles !== undefined) {
      Filter_Roles.encode(message.roles, writer.uint32(66).fork()).ldelim();
    }
    if (message.forUserId !== undefined) {
      writer.uint32(82).string(message.forUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.type = token_TypeFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userPermissions = Filter_UserPermissions.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.organizationPermissions = Filter_OrganizationPermissions.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.roles = Filter_Roles.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.forUserId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateTokenRequest>): CreateTokenRequest {
    return CreateTokenRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateTokenRequest>): CreateTokenRequest {
    const message = createBaseCreateTokenRequest();
    message.organization = object.organization ?? undefined;
    message.name = object.name ?? "";
    message.type = object.type ?? Token_Type.SPECIFIC;
    message.expiresAt = (object.expiresAt !== undefined && object.expiresAt !== null)
      ? Timestamp.fromPartial(object.expiresAt)
      : undefined;
    message.userPermissions = (object.userPermissions !== undefined && object.userPermissions !== null)
      ? Filter_UserPermissions.fromPartial(object.userPermissions)
      : undefined;
    message.organizationPermissions =
      (object.organizationPermissions !== undefined && object.organizationPermissions !== null)
        ? Filter_OrganizationPermissions.fromPartial(object.organizationPermissions)
        : undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? Filter_Roles.fromPartial(object.roles)
      : undefined;
    message.forUserId = object.forUserId ?? undefined;
    return message;
  },
};

function createBaseCreateTokenRequest_Response(): CreateTokenRequest_Response {
  return { token: "", created: undefined };
}

export const CreateTokenRequest_Response = {
  encode(message: CreateTokenRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.created !== undefined) {
      Token.encode(message.created, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTokenRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTokenRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = Token.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateTokenRequest_Response>): CreateTokenRequest_Response {
    return CreateTokenRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateTokenRequest_Response>): CreateTokenRequest_Response {
    const message = createBaseCreateTokenRequest_Response();
    message.token = object.token ?? "";
    message.created = (object.created !== undefined && object.created !== null)
      ? Token.fromPartial(object.created)
      : undefined;
    return message;
  },
};

function createBaseDeleteTokenRequest(): DeleteTokenRequest {
  return { organization: undefined, tokenId: 0 };
}

export const DeleteTokenRequest = {
  encode(message: DeleteTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.tokenId !== 0) {
      writer.uint32(16).int64(message.tokenId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tokenId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteTokenRequest>): DeleteTokenRequest {
    return DeleteTokenRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteTokenRequest>): DeleteTokenRequest {
    const message = createBaseDeleteTokenRequest();
    message.organization = object.organization ?? undefined;
    message.tokenId = object.tokenId ?? 0;
    return message;
  },
};

function createBaseDeleteTokenRequest_Response(): DeleteTokenRequest_Response {
  return {};
}

export const DeleteTokenRequest_Response = {
  encode(_: DeleteTokenRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTokenRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTokenRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteTokenRequest_Response>): DeleteTokenRequest_Response {
    return DeleteTokenRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteTokenRequest_Response>): DeleteTokenRequest_Response {
    const message = createBaseDeleteTokenRequest_Response();
    return message;
  },
};

function createBaseUpdateTokenOrganizationPermissionsRequest(): UpdateTokenOrganizationPermissionsRequest {
  return {
    organization: undefined,
    tokenIds: [],
    replaceOrganizationPermissions: undefined,
    addOrganizationPermissions: [],
    removeOrganizationPermissions: [],
  };
}

export const UpdateTokenOrganizationPermissionsRequest = {
  encode(message: UpdateTokenOrganizationPermissionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    writer.uint32(18).fork();
    for (const v of message.tokenIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.replaceOrganizationPermissions !== undefined) {
      Filter_OrganizationPermissions.encode(message.replaceOrganizationPermissions, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.addOrganizationPermissions) {
      writer.int32(organizationPermissionToNumber(v));
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.removeOrganizationPermissions) {
      writer.int32(organizationPermissionToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenOrganizationPermissionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenOrganizationPermissionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.tokenIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tokenIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.replaceOrganizationPermissions = Filter_OrganizationPermissions.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag === 40) {
            message.addOrganizationPermissions.push(organizationPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.addOrganizationPermissions.push(organizationPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 6:
          if (tag === 48) {
            message.removeOrganizationPermissions.push(organizationPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.removeOrganizationPermissions.push(organizationPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTokenOrganizationPermissionsRequest>): UpdateTokenOrganizationPermissionsRequest {
    return UpdateTokenOrganizationPermissionsRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateTokenOrganizationPermissionsRequest>,
  ): UpdateTokenOrganizationPermissionsRequest {
    const message = createBaseUpdateTokenOrganizationPermissionsRequest();
    message.organization = object.organization ?? undefined;
    message.tokenIds = object.tokenIds?.map((e) => e) || [];
    message.replaceOrganizationPermissions =
      (object.replaceOrganizationPermissions !== undefined && object.replaceOrganizationPermissions !== null)
        ? Filter_OrganizationPermissions.fromPartial(object.replaceOrganizationPermissions)
        : undefined;
    message.addOrganizationPermissions = object.addOrganizationPermissions?.map((e) => e) || [];
    message.removeOrganizationPermissions = object.removeOrganizationPermissions?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateTokenOrganizationPermissionsRequest_Response(): UpdateTokenOrganizationPermissionsRequest_Response {
  return {};
}

export const UpdateTokenOrganizationPermissionsRequest_Response = {
  encode(_: UpdateTokenOrganizationPermissionsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenOrganizationPermissionsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenOrganizationPermissionsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateTokenOrganizationPermissionsRequest_Response>,
  ): UpdateTokenOrganizationPermissionsRequest_Response {
    return UpdateTokenOrganizationPermissionsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<UpdateTokenOrganizationPermissionsRequest_Response>,
  ): UpdateTokenOrganizationPermissionsRequest_Response {
    const message = createBaseUpdateTokenOrganizationPermissionsRequest_Response();
    return message;
  },
};

function createBaseUpdateTokenUserPermissionsRequest(): UpdateTokenUserPermissionsRequest {
  return {
    organization: undefined,
    tokenIds: [],
    replaceUserPermissions: undefined,
    addUserPermissions: [],
    removeUserPermissions: [],
  };
}

export const UpdateTokenUserPermissionsRequest = {
  encode(message: UpdateTokenUserPermissionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    writer.uint32(18).fork();
    for (const v of message.tokenIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.replaceUserPermissions !== undefined) {
      Filter_UserPermissions.encode(message.replaceUserPermissions, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.addUserPermissions) {
      writer.int32(userPermissionToNumber(v));
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.removeUserPermissions) {
      writer.int32(userPermissionToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenUserPermissionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenUserPermissionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.tokenIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tokenIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.replaceUserPermissions = Filter_UserPermissions.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag === 40) {
            message.addUserPermissions.push(userPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.addUserPermissions.push(userPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 6:
          if (tag === 48) {
            message.removeUserPermissions.push(userPermissionFromJSON(reader.int32()));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.removeUserPermissions.push(userPermissionFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTokenUserPermissionsRequest>): UpdateTokenUserPermissionsRequest {
    return UpdateTokenUserPermissionsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateTokenUserPermissionsRequest>): UpdateTokenUserPermissionsRequest {
    const message = createBaseUpdateTokenUserPermissionsRequest();
    message.organization = object.organization ?? undefined;
    message.tokenIds = object.tokenIds?.map((e) => e) || [];
    message.replaceUserPermissions =
      (object.replaceUserPermissions !== undefined && object.replaceUserPermissions !== null)
        ? Filter_UserPermissions.fromPartial(object.replaceUserPermissions)
        : undefined;
    message.addUserPermissions = object.addUserPermissions?.map((e) => e) || [];
    message.removeUserPermissions = object.removeUserPermissions?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateTokenUserPermissionsRequest_Response(): UpdateTokenUserPermissionsRequest_Response {
  return {};
}

export const UpdateTokenUserPermissionsRequest_Response = {
  encode(_: UpdateTokenUserPermissionsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenUserPermissionsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenUserPermissionsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTokenUserPermissionsRequest_Response>): UpdateTokenUserPermissionsRequest_Response {
    return UpdateTokenUserPermissionsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateTokenUserPermissionsRequest_Response>): UpdateTokenUserPermissionsRequest_Response {
    const message = createBaseUpdateTokenUserPermissionsRequest_Response();
    return message;
  },
};

function createBaseUpdateTokenRoles(): UpdateTokenRoles {
  return {};
}

export const UpdateTokenRoles = {
  encode(_: UpdateTokenRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTokenRoles>): UpdateTokenRoles {
    return UpdateTokenRoles.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateTokenRoles>): UpdateTokenRoles {
    const message = createBaseUpdateTokenRoles();
    return message;
  },
};

function createBaseUpdateTokenRoles_Request(): UpdateTokenRoles_Request {
  return { organization: undefined, tokenIds: [], update: undefined };
}

export const UpdateTokenRoles_Request = {
  encode(message: UpdateTokenRoles_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    writer.uint32(18).fork();
    for (const v of message.tokenIds) {
      writer.int64(v);
    }
    writer.ldelim();
    switch (message.update?.$case) {
      case "modify":
        UpdateTokenRoles_Request_ModifyRoles.encode(message.update.modify, writer.uint32(26).fork()).ldelim();
        break;
      case "replace":
        UpdateTokenRoles_Request_ReplaceRoles.encode(message.update.replace, writer.uint32(34).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenRoles_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenRoles_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.tokenIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tokenIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = {
            $case: "modify",
            modify: UpdateTokenRoles_Request_ModifyRoles.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.update = {
            $case: "replace",
            replace: UpdateTokenRoles_Request_ReplaceRoles.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTokenRoles_Request>): UpdateTokenRoles_Request {
    return UpdateTokenRoles_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateTokenRoles_Request>): UpdateTokenRoles_Request {
    const message = createBaseUpdateTokenRoles_Request();
    message.organization = object.organization ?? undefined;
    message.tokenIds = object.tokenIds?.map((e) => e) || [];
    if (object.update?.$case === "modify" && object.update?.modify !== undefined && object.update?.modify !== null) {
      message.update = {
        $case: "modify",
        modify: UpdateTokenRoles_Request_ModifyRoles.fromPartial(object.update.modify),
      };
    }
    if (object.update?.$case === "replace" && object.update?.replace !== undefined && object.update?.replace !== null) {
      message.update = {
        $case: "replace",
        replace: UpdateTokenRoles_Request_ReplaceRoles.fromPartial(object.update.replace),
      };
    }
    return message;
  },
};

function createBaseUpdateTokenRoles_Request_ModifyRoles(): UpdateTokenRoles_Request_ModifyRoles {
  return { add: [], remove: [] };
}

export const UpdateTokenRoles_Request_ModifyRoles = {
  encode(message: UpdateTokenRoles_Request_ModifyRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.add) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.remove) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenRoles_Request_ModifyRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenRoles_Request_ModifyRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.add.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.remove.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTokenRoles_Request_ModifyRoles>): UpdateTokenRoles_Request_ModifyRoles {
    return UpdateTokenRoles_Request_ModifyRoles.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateTokenRoles_Request_ModifyRoles>): UpdateTokenRoles_Request_ModifyRoles {
    const message = createBaseUpdateTokenRoles_Request_ModifyRoles();
    message.add = object.add?.map((e) => e) || [];
    message.remove = object.remove?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateTokenRoles_Request_ReplaceRoles(): UpdateTokenRoles_Request_ReplaceRoles {
  return { roles: [] };
}

export const UpdateTokenRoles_Request_ReplaceRoles = {
  encode(message: UpdateTokenRoles_Request_ReplaceRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenRoles_Request_ReplaceRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenRoles_Request_ReplaceRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTokenRoles_Request_ReplaceRoles>): UpdateTokenRoles_Request_ReplaceRoles {
    return UpdateTokenRoles_Request_ReplaceRoles.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateTokenRoles_Request_ReplaceRoles>): UpdateTokenRoles_Request_ReplaceRoles {
    const message = createBaseUpdateTokenRoles_Request_ReplaceRoles();
    message.roles = object.roles?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateTokenRoles_Response(): UpdateTokenRoles_Response {
  return {};
}

export const UpdateTokenRoles_Response = {
  encode(_: UpdateTokenRoles_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTokenRoles_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTokenRoles_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTokenRoles_Response>): UpdateTokenRoles_Response {
    return UpdateTokenRoles_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateTokenRoles_Response>): UpdateTokenRoles_Response {
    const message = createBaseUpdateTokenRoles_Response();
    return message;
  },
};

export type TokenServiceDefinition = typeof TokenServiceDefinition;
export const TokenServiceDefinition = {
  name: "TokenService",
  fullName: "hiber.token.TokenService",
  methods: {
    list: {
      name: "List",
      requestType: ListTokensRequest,
      requestStream: false,
      responseType: ListTokensRequest_Response,
      responseStream: false,
      options: {},
    },
    create: {
      name: "Create",
      requestType: CreateTokenRequest,
      requestStream: false,
      responseType: CreateTokenRequest_Response,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteTokenRequest,
      requestStream: false,
      responseType: DeleteTokenRequest_Response,
      responseStream: false,
      options: {},
    },
    updateTokenOrganizationPermissions: {
      name: "UpdateTokenOrganizationPermissions",
      requestType: UpdateTokenOrganizationPermissionsRequest,
      requestStream: false,
      responseType: UpdateTokenOrganizationPermissionsRequest_Response,
      responseStream: false,
      options: {},
    },
    updateTokenUserPermissions: {
      name: "UpdateTokenUserPermissions",
      requestType: UpdateTokenUserPermissionsRequest,
      requestStream: false,
      responseType: UpdateTokenUserPermissionsRequest_Response,
      responseStream: false,
      options: {},
    },
    updateTokenRoles: {
      name: "UpdateTokenRoles",
      requestType: UpdateTokenRoles_Request,
      requestStream: false,
      responseType: UpdateTokenRoles_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
