/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logo-bear': {
    width: 16,
    height: 16,
    viewBox: '0 0 388 403',
    data: '<path pid="0" d="M149.84 63.6a43.84 43.84 0 0087.67 0q-43.81 1.84-87.67 0z" _fill="#002d3c"/><path pid="1" d="M388.33 80.83A43.75 43.75 0 00374.1 48.5q-26.3 4.61-52.76 7.86-27.6-27.58-54.48-55.87l-4 .08L194.21 6 120.38.53 118 .48Q91.15 28.8 63.5 56.39q-25.06-3.15-50-7.51c-.33.31-.67.62-1 .94a43.85 43.85 0 0035.55 74.62c-.15 2.74-.3 5.49-.46 8.22l-.36 5.72q-.6 9.76-1.27 19.5c-.09 1.34-.19 2.67-.28 4Q41.53 221.34 35 280.14q76.71 64 157.77 122.86 80.84-58.61 157.38-122.53-6.57-59-10.75-118.79c-.08-1.18-.17-2.35-.25-3.53q-.67-9.92-1.29-19.84c-.11-1.83-.23-3.66-.34-5.49-.18-2.93-.34-5.86-.5-8.78a44.61 44.61 0 007.46.64 43.85 43.85 0 0043.85-43.85zM206 200.29c-4.51-5.38-9-11.52-13.44-16.85-4.57 5.45-9.63 11.35-14.27 16.79a82.18 82.18 0 1127.71.06z" _fill="#002d3c"/>'
  }
})
