/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import {
  Duration,
  Filter_Events,
  Filter_Events_Update,
  Filter_Modems,
  Filter_Modems_Update,
  Filter_Tags,
  Filter_Tags_Update,
  Filter_Webhooks,
  Health,
  healthFromJSON,
  healthToNumber,
  Pagination,
  Pagination_Result,
  TimeRange,
  Timestamp,
  UpdateBoolean,
  UpdateClearableString,
  UpdateOptionalId,
  UpdateZeroableInt,
} from "./base";
import { Tag, TagSelection, UpdateTagsForItem } from "./tag";

export const protobufPackage = "hiber.webhook";

/**
 * Webhook publisher that sends events to a webhook.
 *
 * When the webhook call fails, it enters cooldown, so as not to overload a failing server
 * or spend unnecessary time on an incorrectly configured webhook.
 * When the first call after the cooldown time has passed fails again, the cooldown is increased as follows:
 * 1m, 2m, 5m, 10m, 15m, 30m, 1h, 3h, 6h, 12h, 24h.
 *
 * To disable the cooldown, use EnableWebhookRequest to re-enable it.
 */
export interface Webhook {
  id: number;
  organization: string;
  createdBy: string;
  description: string;
  data: Webhook_WebhookData | undefined;
  filters: Webhook_WebhookFilters | undefined;
  tags: Tag[];
  health: Health;
  healthConfig:
    | Webhook_HealthConfig
    | undefined;
  /** The time the cooldown ends. */
  inCooldownUntil: Timestamp | undefined;
}

export enum Webhook_ContentType {
  DEFAULT = "DEFAULT",
  JSON = "JSON",
  PROTO = "PROTO",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function webhook_ContentTypeFromJSON(object: any): Webhook_ContentType {
  switch (object) {
    case 0:
    case "DEFAULT":
      return Webhook_ContentType.DEFAULT;
    case 1:
    case "JSON":
      return Webhook_ContentType.JSON;
    case 2:
    case "PROTO":
      return Webhook_ContentType.PROTO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Webhook_ContentType.UNRECOGNIZED;
  }
}

export function webhook_ContentTypeToNumber(object: Webhook_ContentType): number {
  switch (object) {
    case Webhook_ContentType.DEFAULT:
      return 0;
    case Webhook_ContentType.JSON:
      return 1;
    case Webhook_ContentType.PROTO:
      return 2;
    case Webhook_ContentType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Webhook_WebhookData {
  url: string;
  /**
   * Used to generate the HMAC-SHA256 header on every webhook call, which you can use to verify the message.
   * The HMAC-SHA256 header is calculated with the message body and this secret.
   * There are many examples of how to do this in different languages, for example:
   * https://github.com/danharper/hmac-examples
   */
  secret?:
    | string
    | undefined;
  /** The header that the hmac value is placed in. Defaults to X-Hub-Signature. */
  hmacHeaderName: string;
  contentType: Webhook_ContentType;
  disabled: boolean;
  certificateId?: number | undefined;
  certificateName?: string | undefined;
  caCertificateId?: number | undefined;
  caCertificateName?:
    | string
    | undefined;
  /** Custom headers to add to every call. */
  customHeaders: { [key: string]: string };
}

export interface Webhook_WebhookData_CustomHeadersEntry {
  key: string;
  value: string;
}

export interface Webhook_WebhookFilters {
  eventTypes?: Filter_Events | undefined;
  modemNumbers?: Filter_Modems | undefined;
  tags?:
    | Filter_Tags
    | undefined;
  /** Filter events by health level caused. */
  healthLevels: string[];
}

/** Health configuration for the webhook. Defines how the health is calculated. */
export interface Webhook_HealthConfig {
  /** Period to consider when determining health from warning events. Warning events cannot be resolved. */
  healthWarningPeriod:
    | Duration
    | undefined;
  /**
   * Allowed percentage of failures.
   * If the failure percentage is higher, within the warning period, the health is switched to WARNING.
   */
  healthWarningFailurePercentage: number;
}

export interface WebhookSelection {
  description?: string | undefined;
  url?: string | undefined;
  webhooks?: Filter_Webhooks | undefined;
  tags?: TagSelection | undefined;
  health: Health[];
  certificateIds: number[];
  /** Search in the all available text, like description and url. */
  search?: string | undefined;
}

export interface WebhookCall {
  time: Timestamp | undefined;
  url: string;
  headers: { [key: string]: string };
  bodyProto: Uint8Array;
  bodyJson: string;
  successful: boolean;
  error: string;
}

export interface WebhookCall_HeadersEntry {
  key: string;
  value: string;
}

export interface WebhookHistorySelection {
  onlyFailures?: boolean | undefined;
  timeRange?: TimeRange | undefined;
}

export interface ListWebhooksRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the webhooks to list. Optional, when omitted or empty everything is included. */
  selection?: WebhookSelection | undefined;
  pagination?: Pagination | undefined;
  sort: ListWebhooksRequest_Sort[];
}

export enum ListWebhooksRequest_Sort {
  /** DEFAULT - Sort by id. */
  DEFAULT = "DEFAULT",
  /** ID_DESC - Sort by id, high to low. */
  ID_DESC = "ID_DESC",
  /** DESCRIPTION - Sort by description. */
  DESCRIPTION = "DESCRIPTION",
  /** DESCRIPTION_DESC - Sort by description, z to a. */
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  /** URL - Sort by url. */
  URL = "URL",
  /** URL_DESC - Sort by url, z to a. */
  URL_DESC = "URL_DESC",
  /** HEALTH - Sort unhealthy webhooks before health webhooks. */
  HEALTH = "HEALTH",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function listWebhooksRequest_SortFromJSON(object: any): ListWebhooksRequest_Sort {
  switch (object) {
    case 0:
    case "DEFAULT":
      return ListWebhooksRequest_Sort.DEFAULT;
    case 1:
    case "ID_DESC":
      return ListWebhooksRequest_Sort.ID_DESC;
    case 2:
    case "DESCRIPTION":
      return ListWebhooksRequest_Sort.DESCRIPTION;
    case 3:
    case "DESCRIPTION_DESC":
      return ListWebhooksRequest_Sort.DESCRIPTION_DESC;
    case 4:
    case "URL":
      return ListWebhooksRequest_Sort.URL;
    case 5:
    case "URL_DESC":
      return ListWebhooksRequest_Sort.URL_DESC;
    case 6:
    case "HEALTH":
      return ListWebhooksRequest_Sort.HEALTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListWebhooksRequest_Sort.UNRECOGNIZED;
  }
}

export function listWebhooksRequest_SortToNumber(object: ListWebhooksRequest_Sort): number {
  switch (object) {
    case ListWebhooksRequest_Sort.DEFAULT:
      return 0;
    case ListWebhooksRequest_Sort.ID_DESC:
      return 1;
    case ListWebhooksRequest_Sort.DESCRIPTION:
      return 2;
    case ListWebhooksRequest_Sort.DESCRIPTION_DESC:
      return 3;
    case ListWebhooksRequest_Sort.URL:
      return 4;
    case ListWebhooksRequest_Sort.URL_DESC:
      return 5;
    case ListWebhooksRequest_Sort.HEALTH:
      return 6;
    case ListWebhooksRequest_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListWebhooksRequest_Response {
  webhooks: Webhook[];
  request: ListWebhooksRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface WebhookHistoryRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  webhookId: number;
  selection?: WebhookHistorySelection | undefined;
  pagination?: Pagination | undefined;
}

export interface WebhookHistoryRequest_Response {
  calls: WebhookCall[];
  request: WebhookHistoryRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface CreateWebhookRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  description: string;
  data: Webhook_WebhookData | undefined;
  filters?:
    | Webhook_WebhookFilters
    | undefined;
  /** Filter events by health level caused. */
  healthLevels: string[];
  tags: number[];
  /**
   * Optionally, a client certificate can be used for the webhook call.
   * See the CertificateService for certificate management options.
   */
  certificateId?: number | undefined;
}

export interface GetWebhookRequest {
  id: number;
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization: string;
}

export interface EnableWebhookRequest {
  id: number;
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization: string;
}

export interface DisableWebhookRequest {
  id: number;
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization: string;
}

export interface UpdateWebhookFilterRequest {
  id: number;
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization: string;
  /** @deprecated */
  deprecatedEventFilter?: Filter_Events_Update | undefined;
  eventFilter?:
    | Filter_Events
    | undefined;
  /** @deprecated */
  deprecatedModemFilter?: Filter_Modems_Update | undefined;
  modemFilter?:
    | Filter_Modems
    | undefined;
  /** @deprecated */
  deprecatedTagFilter?: Filter_Tags_Update | undefined;
  tagFilter?:
    | Filter_Tags
    | undefined;
  /** Add health levels to the health levels filter. */
  addHealthLevelsToFilter: string[];
  /** Remove health levels from the health levels filter. */
  removeHealthLevelsFromFilter: string[];
}

export interface UpdateWebhookRequest {
  id: number;
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization: string;
  update: UpdateWebhookRequest_UpdateWebhook | undefined;
}

export interface UpdateWebhookRequest_UpdateWebhook {
  url: string;
  /** @deprecated */
  deprecatedSecret?: UpdateClearableString | undefined;
  secret?: string | undefined;
  contentType?:
    | Webhook_ContentType
    | undefined;
  /** @deprecated */
  deprecatedDescription?: UpdateClearableString | undefined;
  description?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedEventFilter?: Filter_Events_Update | undefined;
  eventFilter?:
    | Filter_Events
    | undefined;
  /** @deprecated */
  deprecatedModemFilter?: Filter_Modems_Update | undefined;
  modemFilter?:
    | Filter_Modems
    | undefined;
  /** @deprecated */
  deprecatedTagFilter?: Filter_Tags_Update | undefined;
  tagFilter?:
    | Filter_Tags
    | undefined;
  /** Add health levels to the health levels filter. */
  addHealthLevels: string[];
  /** Remove health levels from the health levels filter. */
  removeHealthLevels: string[];
  /** @deprecated */
  deprecatedActive?: UpdateBoolean | undefined;
  active?:
    | boolean
    | undefined;
  /**
   * A value of 0 removes the certificate
   *
   * @deprecated
   */
  deprecatedCertificateId?: UpdateOptionalId | undefined;
  certificateId?:
    | number
    | undefined;
  /**
   * Period to consider when determining health from warning events. Warning events cannot be resolved.
   * Set this to 0 to disable warnings based on failure percentage.
   */
  healthWarningPeriod?:
    | Duration
    | undefined;
  /**
   * Allowed percentage of call failures.
   * If the failure percentage is higher, within the warning period, the health is switched to WARNING.
   */
  healthWarningFailurePercentage?:
    | number
    | undefined;
  /** @deprecated */
  deprecatedHealthWarningFailurePercentage?:
    | UpdateZeroableInt
    | undefined;
  /** Update the custom hmac header, or clear to reset to default. */
  updateHmacHeaderName?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedUpdateHmacHeaderName?:
    | UpdateClearableString
    | undefined;
  /** Custom headers to add to every call. */
  addCustomHeaders: { [key: string]: string };
  /** Remove previously configured custom headers. */
  removeCustomHeaders: string[];
  /** Replace the custom headers to add to every call. If set, remove_custom_headers is ignored. */
  replaceCustomHeaders: { [key: string]: string };
}

/** @deprecated */
export interface UpdateWebhookRequest_UpdateWebhook_UpdateEvents {
  updated: boolean;
  value: Filter_Events | undefined;
}

/** @deprecated */
export interface UpdateWebhookRequest_UpdateWebhook_UpdateModems {
  updated: boolean;
  value: Filter_Modems | undefined;
}

/** @deprecated */
export interface UpdateWebhookRequest_UpdateWebhook_UpdateTags {
  updated: boolean;
  value: Filter_Tags | undefined;
}

export interface UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry {
  key: string;
  value: string;
}

export interface UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry {
  key: string;
  value: string;
}

export interface UpdateWebhooksRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  webhookUpdates: UpdateWebhooksRequest_Update[];
}

export interface UpdateWebhooksRequest_Update {
  id: number;
  update: UpdateWebhookRequest_UpdateWebhook | undefined;
}

export interface UpdateWebhooksRequest_Response {
  webhooks: Webhook[];
  request: UpdateWebhooksRequest | undefined;
}

export interface UpdateWebhookTagsRequest {
  organization?: string | undefined;
  webhookIds: number[];
  update: UpdateTagsForItem | undefined;
}

export interface UpdateWebhookTagsRequest_Response {
  webhooks: Webhook[];
}

export interface DeleteWebhookRequest {
  id: number;
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization: string;
}

export interface DeleteWebhookRequest_Response {
}

function createBaseWebhook(): Webhook {
  return {
    id: 0,
    organization: "",
    createdBy: "",
    description: "",
    data: undefined,
    filters: undefined,
    tags: [],
    health: Health.OK,
    healthConfig: undefined,
    inCooldownUntil: undefined,
  };
}

export const Webhook = {
  encode(message: Webhook, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    if (message.createdBy !== "") {
      writer.uint32(82).string(message.createdBy);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.data !== undefined) {
      Webhook_WebhookData.encode(message.data, writer.uint32(34).fork()).ldelim();
    }
    if (message.filters !== undefined) {
      Webhook_WebhookFilters.encode(message.filters, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.health !== Health.OK) {
      writer.uint32(56).int32(healthToNumber(message.health));
    }
    if (message.healthConfig !== undefined) {
      Webhook_HealthConfig.encode(message.healthConfig, writer.uint32(74).fork()).ldelim();
    }
    if (message.inCooldownUntil !== undefined) {
      Timestamp.encode(message.inCooldownUntil, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Webhook {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhook();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.data = Webhook_WebhookData.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.filters = Webhook_WebhookFilters.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.health = healthFromJSON(reader.int32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.healthConfig = Webhook_HealthConfig.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.inCooldownUntil = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Webhook>): Webhook {
    return Webhook.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Webhook>): Webhook {
    const message = createBaseWebhook();
    message.id = object.id ?? 0;
    message.organization = object.organization ?? "";
    message.createdBy = object.createdBy ?? "";
    message.description = object.description ?? "";
    message.data = (object.data !== undefined && object.data !== null)
      ? Webhook_WebhookData.fromPartial(object.data)
      : undefined;
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? Webhook_WebhookFilters.fromPartial(object.filters)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.health = object.health ?? Health.OK;
    message.healthConfig = (object.healthConfig !== undefined && object.healthConfig !== null)
      ? Webhook_HealthConfig.fromPartial(object.healthConfig)
      : undefined;
    message.inCooldownUntil = (object.inCooldownUntil !== undefined && object.inCooldownUntil !== null)
      ? Timestamp.fromPartial(object.inCooldownUntil)
      : undefined;
    return message;
  },
};

function createBaseWebhook_WebhookData(): Webhook_WebhookData {
  return {
    url: "",
    secret: undefined,
    hmacHeaderName: "",
    contentType: Webhook_ContentType.DEFAULT,
    disabled: false,
    certificateId: undefined,
    certificateName: undefined,
    caCertificateId: undefined,
    caCertificateName: undefined,
    customHeaders: {},
  };
}

export const Webhook_WebhookData = {
  encode(message: Webhook_WebhookData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.secret !== undefined) {
      writer.uint32(18).string(message.secret);
    }
    if (message.hmacHeaderName !== "") {
      writer.uint32(74).string(message.hmacHeaderName);
    }
    if (message.contentType !== Webhook_ContentType.DEFAULT) {
      writer.uint32(24).int32(webhook_ContentTypeToNumber(message.contentType));
    }
    if (message.disabled === true) {
      writer.uint32(32).bool(message.disabled);
    }
    if (message.certificateId !== undefined) {
      writer.uint32(40).int64(message.certificateId);
    }
    if (message.certificateName !== undefined) {
      writer.uint32(50).string(message.certificateName);
    }
    if (message.caCertificateId !== undefined) {
      writer.uint32(56).int64(message.caCertificateId);
    }
    if (message.caCertificateName !== undefined) {
      writer.uint32(66).string(message.caCertificateName);
    }
    Object.entries(message.customHeaders).forEach(([key, value]) => {
      Webhook_WebhookData_CustomHeadersEntry.encode({ key: key as any, value }, writer.uint32(82).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Webhook_WebhookData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhook_WebhookData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.secret = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.hmacHeaderName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.contentType = webhook_ContentTypeFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.certificateName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.caCertificateId = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.caCertificateName = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          const entry10 = Webhook_WebhookData_CustomHeadersEntry.decode(reader, reader.uint32());
          if (entry10.value !== undefined) {
            message.customHeaders[entry10.key] = entry10.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Webhook_WebhookData>): Webhook_WebhookData {
    return Webhook_WebhookData.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Webhook_WebhookData>): Webhook_WebhookData {
    const message = createBaseWebhook_WebhookData();
    message.url = object.url ?? "";
    message.secret = object.secret ?? undefined;
    message.hmacHeaderName = object.hmacHeaderName ?? "";
    message.contentType = object.contentType ?? Webhook_ContentType.DEFAULT;
    message.disabled = object.disabled ?? false;
    message.certificateId = object.certificateId ?? undefined;
    message.certificateName = object.certificateName ?? undefined;
    message.caCertificateId = object.caCertificateId ?? undefined;
    message.caCertificateName = object.caCertificateName ?? undefined;
    message.customHeaders = Object.entries(object.customHeaders ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseWebhook_WebhookData_CustomHeadersEntry(): Webhook_WebhookData_CustomHeadersEntry {
  return { key: "", value: "" };
}

export const Webhook_WebhookData_CustomHeadersEntry = {
  encode(message: Webhook_WebhookData_CustomHeadersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Webhook_WebhookData_CustomHeadersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhook_WebhookData_CustomHeadersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Webhook_WebhookData_CustomHeadersEntry>): Webhook_WebhookData_CustomHeadersEntry {
    return Webhook_WebhookData_CustomHeadersEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Webhook_WebhookData_CustomHeadersEntry>): Webhook_WebhookData_CustomHeadersEntry {
    const message = createBaseWebhook_WebhookData_CustomHeadersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseWebhook_WebhookFilters(): Webhook_WebhookFilters {
  return { eventTypes: undefined, modemNumbers: undefined, tags: undefined, healthLevels: [] };
}

export const Webhook_WebhookFilters = {
  encode(message: Webhook_WebhookFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.eventTypes !== undefined) {
      Filter_Events.encode(message.eventTypes, writer.uint32(10).fork()).ldelim();
    }
    if (message.modemNumbers !== undefined) {
      Filter_Modems.encode(message.modemNumbers, writer.uint32(18).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      Filter_Tags.encode(message.tags, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.healthLevels) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Webhook_WebhookFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhook_WebhookFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eventTypes = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumbers = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.healthLevels.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Webhook_WebhookFilters>): Webhook_WebhookFilters {
    return Webhook_WebhookFilters.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Webhook_WebhookFilters>): Webhook_WebhookFilters {
    const message = createBaseWebhook_WebhookFilters();
    message.eventTypes = (object.eventTypes !== undefined && object.eventTypes !== null)
      ? Filter_Events.fromPartial(object.eventTypes)
      : undefined;
    message.modemNumbers = (object.modemNumbers !== undefined && object.modemNumbers !== null)
      ? Filter_Modems.fromPartial(object.modemNumbers)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? Filter_Tags.fromPartial(object.tags)
      : undefined;
    message.healthLevels = object.healthLevels?.map((e) => e) || [];
    return message;
  },
};

function createBaseWebhook_HealthConfig(): Webhook_HealthConfig {
  return { healthWarningPeriod: undefined, healthWarningFailurePercentage: 0 };
}

export const Webhook_HealthConfig = {
  encode(message: Webhook_HealthConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.healthWarningPeriod !== undefined) {
      Duration.encode(message.healthWarningPeriod, writer.uint32(18).fork()).ldelim();
    }
    if (message.healthWarningFailurePercentage !== 0) {
      writer.uint32(24).int32(message.healthWarningFailurePercentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Webhook_HealthConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhook_HealthConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.healthWarningPeriod = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.healthWarningFailurePercentage = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Webhook_HealthConfig>): Webhook_HealthConfig {
    return Webhook_HealthConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Webhook_HealthConfig>): Webhook_HealthConfig {
    const message = createBaseWebhook_HealthConfig();
    message.healthWarningPeriod = (object.healthWarningPeriod !== undefined && object.healthWarningPeriod !== null)
      ? Duration.fromPartial(object.healthWarningPeriod)
      : undefined;
    message.healthWarningFailurePercentage = object.healthWarningFailurePercentage ?? 0;
    return message;
  },
};

function createBaseWebhookSelection(): WebhookSelection {
  return {
    description: undefined,
    url: undefined,
    webhooks: undefined,
    tags: undefined,
    health: [],
    certificateIds: [],
    search: undefined,
  };
}

export const WebhookSelection = {
  encode(message: WebhookSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== undefined) {
      writer.uint32(10).string(message.description);
    }
    if (message.url !== undefined) {
      writer.uint32(18).string(message.url);
    }
    if (message.webhooks !== undefined) {
      Filter_Webhooks.encode(message.webhooks, writer.uint32(26).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      TagSelection.encode(message.tags, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.health) {
      writer.int32(healthToNumber(v));
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.certificateIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.search !== undefined) {
      writer.uint32(58).string(message.search);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.webhooks = Filter_Webhooks.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags = TagSelection.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag === 40) {
            message.health.push(healthFromJSON(reader.int32()));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.health.push(healthFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 6:
          if (tag === 48) {
            message.certificateIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.certificateIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.search = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<WebhookSelection>): WebhookSelection {
    return WebhookSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WebhookSelection>): WebhookSelection {
    const message = createBaseWebhookSelection();
    message.description = object.description ?? undefined;
    message.url = object.url ?? undefined;
    message.webhooks = (object.webhooks !== undefined && object.webhooks !== null)
      ? Filter_Webhooks.fromPartial(object.webhooks)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagSelection.fromPartial(object.tags)
      : undefined;
    message.health = object.health?.map((e) => e) || [];
    message.certificateIds = object.certificateIds?.map((e) => e) || [];
    message.search = object.search ?? undefined;
    return message;
  },
};

function createBaseWebhookCall(): WebhookCall {
  return {
    time: undefined,
    url: "",
    headers: {},
    bodyProto: new Uint8Array(0),
    bodyJson: "",
    successful: false,
    error: "",
  };
}

export const WebhookCall = {
  encode(message: WebhookCall, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(10).fork()).ldelim();
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    Object.entries(message.headers).forEach(([key, value]) => {
      WebhookCall_HeadersEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    if (message.bodyProto.length !== 0) {
      writer.uint32(34).bytes(message.bodyProto);
    }
    if (message.bodyJson !== "") {
      writer.uint32(58).string(message.bodyJson);
    }
    if (message.successful === true) {
      writer.uint32(40).bool(message.successful);
    }
    if (message.error !== "") {
      writer.uint32(50).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookCall {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookCall();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = WebhookCall_HeadersEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.headers[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bodyProto = reader.bytes();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.bodyJson = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.successful = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.error = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<WebhookCall>): WebhookCall {
    return WebhookCall.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WebhookCall>): WebhookCall {
    const message = createBaseWebhookCall();
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.url = object.url ?? "";
    message.headers = Object.entries(object.headers ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.bodyProto = object.bodyProto ?? new Uint8Array(0);
    message.bodyJson = object.bodyJson ?? "";
    message.successful = object.successful ?? false;
    message.error = object.error ?? "";
    return message;
  },
};

function createBaseWebhookCall_HeadersEntry(): WebhookCall_HeadersEntry {
  return { key: "", value: "" };
}

export const WebhookCall_HeadersEntry = {
  encode(message: WebhookCall_HeadersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookCall_HeadersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookCall_HeadersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<WebhookCall_HeadersEntry>): WebhookCall_HeadersEntry {
    return WebhookCall_HeadersEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WebhookCall_HeadersEntry>): WebhookCall_HeadersEntry {
    const message = createBaseWebhookCall_HeadersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseWebhookHistorySelection(): WebhookHistorySelection {
  return { onlyFailures: undefined, timeRange: undefined };
}

export const WebhookHistorySelection = {
  encode(message: WebhookHistorySelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.onlyFailures !== undefined) {
      writer.uint32(16).bool(message.onlyFailures);
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookHistorySelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookHistorySelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.onlyFailures = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<WebhookHistorySelection>): WebhookHistorySelection {
    return WebhookHistorySelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WebhookHistorySelection>): WebhookHistorySelection {
    const message = createBaseWebhookHistorySelection();
    message.onlyFailures = object.onlyFailures ?? undefined;
    message.timeRange = (object.timeRange !== undefined && object.timeRange !== null)
      ? TimeRange.fromPartial(object.timeRange)
      : undefined;
    return message;
  },
};

function createBaseListWebhooksRequest(): ListWebhooksRequest {
  return { organization: undefined, selection: undefined, pagination: undefined, sort: [] };
}

export const ListWebhooksRequest = {
  encode(message: ListWebhooksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      WebhookSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sort) {
      writer.int32(listWebhooksRequest_SortToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListWebhooksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListWebhooksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = WebhookSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sort.push(listWebhooksRequest_SortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sort.push(listWebhooksRequest_SortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListWebhooksRequest>): ListWebhooksRequest {
    return ListWebhooksRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListWebhooksRequest>): ListWebhooksRequest {
    const message = createBaseListWebhooksRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? WebhookSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort?.map((e) => e) || [];
    return message;
  },
};

function createBaseListWebhooksRequest_Response(): ListWebhooksRequest_Response {
  return { webhooks: [], request: undefined, pagination: undefined };
}

export const ListWebhooksRequest_Response = {
  encode(message: ListWebhooksRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.webhooks) {
      Webhook.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListWebhooksRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListWebhooksRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListWebhooksRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhooks.push(Webhook.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListWebhooksRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListWebhooksRequest_Response>): ListWebhooksRequest_Response {
    return ListWebhooksRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListWebhooksRequest_Response>): ListWebhooksRequest_Response {
    const message = createBaseListWebhooksRequest_Response();
    message.webhooks = object.webhooks?.map((e) => Webhook.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListWebhooksRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseWebhookHistoryRequest(): WebhookHistoryRequest {
  return { organization: undefined, webhookId: 0, selection: undefined, pagination: undefined };
}

export const WebhookHistoryRequest = {
  encode(message: WebhookHistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.webhookId !== 0) {
      writer.uint32(16).int64(message.webhookId);
    }
    if (message.selection !== undefined) {
      WebhookHistorySelection.encode(message.selection, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.webhookId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.selection = WebhookHistorySelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<WebhookHistoryRequest>): WebhookHistoryRequest {
    return WebhookHistoryRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WebhookHistoryRequest>): WebhookHistoryRequest {
    const message = createBaseWebhookHistoryRequest();
    message.organization = object.organization ?? undefined;
    message.webhookId = object.webhookId ?? 0;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? WebhookHistorySelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseWebhookHistoryRequest_Response(): WebhookHistoryRequest_Response {
  return { calls: [], request: undefined, pagination: undefined };
}

export const WebhookHistoryRequest_Response = {
  encode(message: WebhookHistoryRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.calls) {
      WebhookCall.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      WebhookHistoryRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WebhookHistoryRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookHistoryRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.calls.push(WebhookCall.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = WebhookHistoryRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<WebhookHistoryRequest_Response>): WebhookHistoryRequest_Response {
    return WebhookHistoryRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WebhookHistoryRequest_Response>): WebhookHistoryRequest_Response {
    const message = createBaseWebhookHistoryRequest_Response();
    message.calls = object.calls?.map((e) => WebhookCall.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? WebhookHistoryRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseCreateWebhookRequest(): CreateWebhookRequest {
  return {
    organization: undefined,
    description: "",
    data: undefined,
    filters: undefined,
    healthLevels: [],
    tags: [],
    certificateId: undefined,
  };
}

export const CreateWebhookRequest = {
  encode(message: CreateWebhookRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.data !== undefined) {
      Webhook_WebhookData.encode(message.data, writer.uint32(26).fork()).ldelim();
    }
    if (message.filters !== undefined) {
      Webhook_WebhookFilters.encode(message.filters, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.healthLevels) {
      writer.uint32(58).string(v!);
    }
    writer.uint32(42).fork();
    for (const v of message.tags) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.certificateId !== undefined) {
      writer.uint32(48).int64(message.certificateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWebhookRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWebhookRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = Webhook_WebhookData.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filters = Webhook_WebhookFilters.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.healthLevels.push(reader.string());
          continue;
        case 5:
          if (tag === 40) {
            message.tags.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tags.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateWebhookRequest>): CreateWebhookRequest {
    return CreateWebhookRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateWebhookRequest>): CreateWebhookRequest {
    const message = createBaseCreateWebhookRequest();
    message.organization = object.organization ?? undefined;
    message.description = object.description ?? "";
    message.data = (object.data !== undefined && object.data !== null)
      ? Webhook_WebhookData.fromPartial(object.data)
      : undefined;
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? Webhook_WebhookFilters.fromPartial(object.filters)
      : undefined;
    message.healthLevels = object.healthLevels?.map((e) => e) || [];
    message.tags = object.tags?.map((e) => e) || [];
    message.certificateId = object.certificateId ?? undefined;
    return message;
  },
};

function createBaseGetWebhookRequest(): GetWebhookRequest {
  return { id: 0, organization: "" };
}

export const GetWebhookRequest = {
  encode(message: GetWebhookRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWebhookRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWebhookRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GetWebhookRequest>): GetWebhookRequest {
    return GetWebhookRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetWebhookRequest>): GetWebhookRequest {
    const message = createBaseGetWebhookRequest();
    message.id = object.id ?? 0;
    message.organization = object.organization ?? "";
    return message;
  },
};

function createBaseEnableWebhookRequest(): EnableWebhookRequest {
  return { id: 0, organization: "" };
}

export const EnableWebhookRequest = {
  encode(message: EnableWebhookRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnableWebhookRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnableWebhookRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<EnableWebhookRequest>): EnableWebhookRequest {
    return EnableWebhookRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<EnableWebhookRequest>): EnableWebhookRequest {
    const message = createBaseEnableWebhookRequest();
    message.id = object.id ?? 0;
    message.organization = object.organization ?? "";
    return message;
  },
};

function createBaseDisableWebhookRequest(): DisableWebhookRequest {
  return { id: 0, organization: "" };
}

export const DisableWebhookRequest = {
  encode(message: DisableWebhookRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DisableWebhookRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisableWebhookRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DisableWebhookRequest>): DisableWebhookRequest {
    return DisableWebhookRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DisableWebhookRequest>): DisableWebhookRequest {
    const message = createBaseDisableWebhookRequest();
    message.id = object.id ?? 0;
    message.organization = object.organization ?? "";
    return message;
  },
};

function createBaseUpdateWebhookFilterRequest(): UpdateWebhookFilterRequest {
  return {
    id: 0,
    organization: "",
    deprecatedEventFilter: undefined,
    eventFilter: undefined,
    deprecatedModemFilter: undefined,
    modemFilter: undefined,
    deprecatedTagFilter: undefined,
    tagFilter: undefined,
    addHealthLevelsToFilter: [],
    removeHealthLevelsFromFilter: [],
  };
}

export const UpdateWebhookFilterRequest = {
  encode(message: UpdateWebhookFilterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    if (message.deprecatedEventFilter !== undefined) {
      Filter_Events_Update.encode(message.deprecatedEventFilter, writer.uint32(58).fork()).ldelim();
    }
    if (message.eventFilter !== undefined) {
      Filter_Events.encode(message.eventFilter, writer.uint32(98).fork()).ldelim();
    }
    if (message.deprecatedModemFilter !== undefined) {
      Filter_Modems_Update.encode(message.deprecatedModemFilter, writer.uint32(66).fork()).ldelim();
    }
    if (message.modemFilter !== undefined) {
      Filter_Modems.encode(message.modemFilter, writer.uint32(106).fork()).ldelim();
    }
    if (message.deprecatedTagFilter !== undefined) {
      Filter_Tags_Update.encode(message.deprecatedTagFilter, writer.uint32(74).fork()).ldelim();
    }
    if (message.tagFilter !== undefined) {
      Filter_Tags.encode(message.tagFilter, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.addHealthLevelsToFilter) {
      writer.uint32(82).string(v!);
    }
    for (const v of message.removeHealthLevelsFromFilter) {
      writer.uint32(90).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookFilterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookFilterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.deprecatedEventFilter = Filter_Events_Update.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.eventFilter = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.deprecatedModemFilter = Filter_Modems_Update.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.modemFilter = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deprecatedTagFilter = Filter_Tags_Update.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.tagFilter = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.addHealthLevelsToFilter.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.removeHealthLevelsFromFilter.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateWebhookFilterRequest>): UpdateWebhookFilterRequest {
    return UpdateWebhookFilterRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateWebhookFilterRequest>): UpdateWebhookFilterRequest {
    const message = createBaseUpdateWebhookFilterRequest();
    message.id = object.id ?? 0;
    message.organization = object.organization ?? "";
    message.deprecatedEventFilter =
      (object.deprecatedEventFilter !== undefined && object.deprecatedEventFilter !== null)
        ? Filter_Events_Update.fromPartial(object.deprecatedEventFilter)
        : undefined;
    message.eventFilter = (object.eventFilter !== undefined && object.eventFilter !== null)
      ? Filter_Events.fromPartial(object.eventFilter)
      : undefined;
    message.deprecatedModemFilter =
      (object.deprecatedModemFilter !== undefined && object.deprecatedModemFilter !== null)
        ? Filter_Modems_Update.fromPartial(object.deprecatedModemFilter)
        : undefined;
    message.modemFilter = (object.modemFilter !== undefined && object.modemFilter !== null)
      ? Filter_Modems.fromPartial(object.modemFilter)
      : undefined;
    message.deprecatedTagFilter = (object.deprecatedTagFilter !== undefined && object.deprecatedTagFilter !== null)
      ? Filter_Tags_Update.fromPartial(object.deprecatedTagFilter)
      : undefined;
    message.tagFilter = (object.tagFilter !== undefined && object.tagFilter !== null)
      ? Filter_Tags.fromPartial(object.tagFilter)
      : undefined;
    message.addHealthLevelsToFilter = object.addHealthLevelsToFilter?.map((e) => e) || [];
    message.removeHealthLevelsFromFilter = object.removeHealthLevelsFromFilter?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateWebhookRequest(): UpdateWebhookRequest {
  return { id: 0, organization: "", update: undefined };
}

export const UpdateWebhookRequest = {
  encode(message: UpdateWebhookRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    if (message.update !== undefined) {
      UpdateWebhookRequest_UpdateWebhook.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = UpdateWebhookRequest_UpdateWebhook.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateWebhookRequest>): UpdateWebhookRequest {
    return UpdateWebhookRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateWebhookRequest>): UpdateWebhookRequest {
    const message = createBaseUpdateWebhookRequest();
    message.id = object.id ?? 0;
    message.organization = object.organization ?? "";
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateWebhookRequest_UpdateWebhook.fromPartial(object.update)
      : undefined;
    return message;
  },
};

function createBaseUpdateWebhookRequest_UpdateWebhook(): UpdateWebhookRequest_UpdateWebhook {
  return {
    url: "",
    deprecatedSecret: undefined,
    secret: undefined,
    contentType: undefined,
    deprecatedDescription: undefined,
    description: undefined,
    deprecatedEventFilter: undefined,
    eventFilter: undefined,
    deprecatedModemFilter: undefined,
    modemFilter: undefined,
    deprecatedTagFilter: undefined,
    tagFilter: undefined,
    addHealthLevels: [],
    removeHealthLevels: [],
    deprecatedActive: undefined,
    active: undefined,
    deprecatedCertificateId: undefined,
    certificateId: undefined,
    healthWarningPeriod: undefined,
    healthWarningFailurePercentage: undefined,
    deprecatedHealthWarningFailurePercentage: undefined,
    updateHmacHeaderName: undefined,
    deprecatedUpdateHmacHeaderName: undefined,
    addCustomHeaders: {},
    removeCustomHeaders: [],
    replaceCustomHeaders: {},
  };
}

export const UpdateWebhookRequest_UpdateWebhook = {
  encode(message: UpdateWebhookRequest_UpdateWebhook, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.deprecatedSecret !== undefined) {
      UpdateClearableString.encode(message.deprecatedSecret, writer.uint32(18).fork()).ldelim();
    }
    if (message.secret !== undefined) {
      writer.uint32(178).string(message.secret);
    }
    if (message.contentType !== undefined) {
      writer.uint32(24).int32(webhook_ContentTypeToNumber(message.contentType));
    }
    if (message.deprecatedDescription !== undefined) {
      UpdateClearableString.encode(message.deprecatedDescription, writer.uint32(34).fork()).ldelim();
    }
    if (message.description !== undefined) {
      writer.uint32(186).string(message.description);
    }
    if (message.deprecatedEventFilter !== undefined) {
      Filter_Events_Update.encode(message.deprecatedEventFilter, writer.uint32(82).fork()).ldelim();
    }
    if (message.eventFilter !== undefined) {
      Filter_Events.encode(message.eventFilter, writer.uint32(194).fork()).ldelim();
    }
    if (message.deprecatedModemFilter !== undefined) {
      Filter_Modems_Update.encode(message.deprecatedModemFilter, writer.uint32(90).fork()).ldelim();
    }
    if (message.modemFilter !== undefined) {
      Filter_Modems.encode(message.modemFilter, writer.uint32(202).fork()).ldelim();
    }
    if (message.deprecatedTagFilter !== undefined) {
      Filter_Tags_Update.encode(message.deprecatedTagFilter, writer.uint32(98).fork()).ldelim();
    }
    if (message.tagFilter !== undefined) {
      Filter_Tags.encode(message.tagFilter, writer.uint32(210).fork()).ldelim();
    }
    for (const v of message.addHealthLevels) {
      writer.uint32(130).string(v!);
    }
    for (const v of message.removeHealthLevels) {
      writer.uint32(138).string(v!);
    }
    if (message.deprecatedActive !== undefined) {
      UpdateBoolean.encode(message.deprecatedActive, writer.uint32(66).fork()).ldelim();
    }
    if (message.active !== undefined) {
      writer.uint32(216).bool(message.active);
    }
    if (message.deprecatedCertificateId !== undefined) {
      UpdateOptionalId.encode(message.deprecatedCertificateId, writer.uint32(74).fork()).ldelim();
    }
    if (message.certificateId !== undefined) {
      writer.uint32(224).int64(message.certificateId);
    }
    if (message.healthWarningPeriod !== undefined) {
      Duration.encode(message.healthWarningPeriod, writer.uint32(114).fork()).ldelim();
    }
    if (message.healthWarningFailurePercentage !== undefined) {
      writer.uint32(232).uint32(message.healthWarningFailurePercentage);
    }
    if (message.deprecatedHealthWarningFailurePercentage !== undefined) {
      UpdateZeroableInt.encode(message.deprecatedHealthWarningFailurePercentage, writer.uint32(122).fork()).ldelim();
    }
    if (message.updateHmacHeaderName !== undefined) {
      writer.uint32(242).string(message.updateHmacHeaderName);
    }
    if (message.deprecatedUpdateHmacHeaderName !== undefined) {
      UpdateClearableString.encode(message.deprecatedUpdateHmacHeaderName, writer.uint32(146).fork()).ldelim();
    }
    Object.entries(message.addCustomHeaders).forEach(([key, value]) => {
      UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry.encode(
        { key: key as any, value },
        writer.uint32(154).fork(),
      ).ldelim();
    });
    for (const v of message.removeCustomHeaders) {
      writer.uint32(162).string(v!);
    }
    Object.entries(message.replaceCustomHeaders).forEach(([key, value]) => {
      UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry.encode(
        { key: key as any, value },
        writer.uint32(170).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookRequest_UpdateWebhook {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookRequest_UpdateWebhook();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deprecatedSecret = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.secret = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.contentType = webhook_ContentTypeFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deprecatedDescription = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.description = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deprecatedEventFilter = Filter_Events_Update.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.eventFilter = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.deprecatedModemFilter = Filter_Modems_Update.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.modemFilter = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.deprecatedTagFilter = Filter_Tags_Update.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.tagFilter = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.addHealthLevels.push(reader.string());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.removeHealthLevels.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.deprecatedActive = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deprecatedCertificateId = UpdateOptionalId.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.healthWarningPeriod = Duration.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.healthWarningFailurePercentage = reader.uint32();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.deprecatedHealthWarningFailurePercentage = UpdateZeroableInt.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.updateHmacHeaderName = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.deprecatedUpdateHmacHeaderName = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          const entry19 = UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry.decode(reader, reader.uint32());
          if (entry19.value !== undefined) {
            message.addCustomHeaders[entry19.key] = entry19.value;
          }
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.removeCustomHeaders.push(reader.string());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          const entry21 = UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry.decode(reader, reader.uint32());
          if (entry21.value !== undefined) {
            message.replaceCustomHeaders[entry21.key] = entry21.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateWebhookRequest_UpdateWebhook>): UpdateWebhookRequest_UpdateWebhook {
    return UpdateWebhookRequest_UpdateWebhook.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateWebhookRequest_UpdateWebhook>): UpdateWebhookRequest_UpdateWebhook {
    const message = createBaseUpdateWebhookRequest_UpdateWebhook();
    message.url = object.url ?? "";
    message.deprecatedSecret = (object.deprecatedSecret !== undefined && object.deprecatedSecret !== null)
      ? UpdateClearableString.fromPartial(object.deprecatedSecret)
      : undefined;
    message.secret = object.secret ?? undefined;
    message.contentType = object.contentType ?? undefined;
    message.deprecatedDescription =
      (object.deprecatedDescription !== undefined && object.deprecatedDescription !== null)
        ? UpdateClearableString.fromPartial(object.deprecatedDescription)
        : undefined;
    message.description = object.description ?? undefined;
    message.deprecatedEventFilter =
      (object.deprecatedEventFilter !== undefined && object.deprecatedEventFilter !== null)
        ? Filter_Events_Update.fromPartial(object.deprecatedEventFilter)
        : undefined;
    message.eventFilter = (object.eventFilter !== undefined && object.eventFilter !== null)
      ? Filter_Events.fromPartial(object.eventFilter)
      : undefined;
    message.deprecatedModemFilter =
      (object.deprecatedModemFilter !== undefined && object.deprecatedModemFilter !== null)
        ? Filter_Modems_Update.fromPartial(object.deprecatedModemFilter)
        : undefined;
    message.modemFilter = (object.modemFilter !== undefined && object.modemFilter !== null)
      ? Filter_Modems.fromPartial(object.modemFilter)
      : undefined;
    message.deprecatedTagFilter = (object.deprecatedTagFilter !== undefined && object.deprecatedTagFilter !== null)
      ? Filter_Tags_Update.fromPartial(object.deprecatedTagFilter)
      : undefined;
    message.tagFilter = (object.tagFilter !== undefined && object.tagFilter !== null)
      ? Filter_Tags.fromPartial(object.tagFilter)
      : undefined;
    message.addHealthLevels = object.addHealthLevels?.map((e) => e) || [];
    message.removeHealthLevels = object.removeHealthLevels?.map((e) => e) || [];
    message.deprecatedActive = (object.deprecatedActive !== undefined && object.deprecatedActive !== null)
      ? UpdateBoolean.fromPartial(object.deprecatedActive)
      : undefined;
    message.active = object.active ?? undefined;
    message.deprecatedCertificateId =
      (object.deprecatedCertificateId !== undefined && object.deprecatedCertificateId !== null)
        ? UpdateOptionalId.fromPartial(object.deprecatedCertificateId)
        : undefined;
    message.certificateId = object.certificateId ?? undefined;
    message.healthWarningPeriod = (object.healthWarningPeriod !== undefined && object.healthWarningPeriod !== null)
      ? Duration.fromPartial(object.healthWarningPeriod)
      : undefined;
    message.healthWarningFailurePercentage = object.healthWarningFailurePercentage ?? undefined;
    message.deprecatedHealthWarningFailurePercentage =
      (object.deprecatedHealthWarningFailurePercentage !== undefined &&
          object.deprecatedHealthWarningFailurePercentage !== null)
        ? UpdateZeroableInt.fromPartial(object.deprecatedHealthWarningFailurePercentage)
        : undefined;
    message.updateHmacHeaderName = object.updateHmacHeaderName ?? undefined;
    message.deprecatedUpdateHmacHeaderName =
      (object.deprecatedUpdateHmacHeaderName !== undefined && object.deprecatedUpdateHmacHeaderName !== null)
        ? UpdateClearableString.fromPartial(object.deprecatedUpdateHmacHeaderName)
        : undefined;
    message.addCustomHeaders = Object.entries(object.addCustomHeaders ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.removeCustomHeaders = object.removeCustomHeaders?.map((e) => e) || [];
    message.replaceCustomHeaders = Object.entries(object.replaceCustomHeaders ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUpdateWebhookRequest_UpdateWebhook_UpdateEvents(): UpdateWebhookRequest_UpdateWebhook_UpdateEvents {
  return { updated: false, value: undefined };
}

export const UpdateWebhookRequest_UpdateWebhook_UpdateEvents = {
  encode(
    message: UpdateWebhookRequest_UpdateWebhook_UpdateEvents,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.updated === true) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Filter_Events.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookRequest_UpdateWebhook_UpdateEvents {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_UpdateEvents();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Filter_Events.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateWebhookRequest_UpdateWebhook_UpdateEvents>,
  ): UpdateWebhookRequest_UpdateWebhook_UpdateEvents {
    return UpdateWebhookRequest_UpdateWebhook_UpdateEvents.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateWebhookRequest_UpdateWebhook_UpdateEvents>,
  ): UpdateWebhookRequest_UpdateWebhook_UpdateEvents {
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_UpdateEvents();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Filter_Events.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseUpdateWebhookRequest_UpdateWebhook_UpdateModems(): UpdateWebhookRequest_UpdateWebhook_UpdateModems {
  return { updated: false, value: undefined };
}

export const UpdateWebhookRequest_UpdateWebhook_UpdateModems = {
  encode(
    message: UpdateWebhookRequest_UpdateWebhook_UpdateModems,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.updated === true) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Filter_Modems.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookRequest_UpdateWebhook_UpdateModems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_UpdateModems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Filter_Modems.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateWebhookRequest_UpdateWebhook_UpdateModems>,
  ): UpdateWebhookRequest_UpdateWebhook_UpdateModems {
    return UpdateWebhookRequest_UpdateWebhook_UpdateModems.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateWebhookRequest_UpdateWebhook_UpdateModems>,
  ): UpdateWebhookRequest_UpdateWebhook_UpdateModems {
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_UpdateModems();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Filter_Modems.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseUpdateWebhookRequest_UpdateWebhook_UpdateTags(): UpdateWebhookRequest_UpdateWebhook_UpdateTags {
  return { updated: false, value: undefined };
}

export const UpdateWebhookRequest_UpdateWebhook_UpdateTags = {
  encode(message: UpdateWebhookRequest_UpdateWebhook_UpdateTags, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated === true) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Filter_Tags.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookRequest_UpdateWebhook_UpdateTags {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_UpdateTags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Filter_Tags.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateWebhookRequest_UpdateWebhook_UpdateTags>,
  ): UpdateWebhookRequest_UpdateWebhook_UpdateTags {
    return UpdateWebhookRequest_UpdateWebhook_UpdateTags.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateWebhookRequest_UpdateWebhook_UpdateTags>,
  ): UpdateWebhookRequest_UpdateWebhook_UpdateTags {
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_UpdateTags();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Filter_Tags.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseUpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry(): UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry {
  return { key: "", value: "" };
}

export const UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry = {
  encode(
    message: UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry>,
  ): UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry {
    return UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry>,
  ): UpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry {
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_AddCustomHeadersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry(): UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry {
  return { key: "", value: "" };
}

export const UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry = {
  encode(
    message: UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry>,
  ): UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry {
    return UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry>,
  ): UpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry {
    const message = createBaseUpdateWebhookRequest_UpdateWebhook_ReplaceCustomHeadersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUpdateWebhooksRequest(): UpdateWebhooksRequest {
  return { organization: undefined, webhookUpdates: [] };
}

export const UpdateWebhooksRequest = {
  encode(message: UpdateWebhooksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.webhookUpdates) {
      UpdateWebhooksRequest_Update.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhooksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhooksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.webhookUpdates.push(UpdateWebhooksRequest_Update.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateWebhooksRequest>): UpdateWebhooksRequest {
    return UpdateWebhooksRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateWebhooksRequest>): UpdateWebhooksRequest {
    const message = createBaseUpdateWebhooksRequest();
    message.organization = object.organization ?? undefined;
    message.webhookUpdates = object.webhookUpdates?.map((e) => UpdateWebhooksRequest_Update.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateWebhooksRequest_Update(): UpdateWebhooksRequest_Update {
  return { id: 0, update: undefined };
}

export const UpdateWebhooksRequest_Update = {
  encode(message: UpdateWebhooksRequest_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.update !== undefined) {
      UpdateWebhookRequest_UpdateWebhook.encode(message.update, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhooksRequest_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhooksRequest_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.update = UpdateWebhookRequest_UpdateWebhook.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateWebhooksRequest_Update>): UpdateWebhooksRequest_Update {
    return UpdateWebhooksRequest_Update.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateWebhooksRequest_Update>): UpdateWebhooksRequest_Update {
    const message = createBaseUpdateWebhooksRequest_Update();
    message.id = object.id ?? 0;
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateWebhookRequest_UpdateWebhook.fromPartial(object.update)
      : undefined;
    return message;
  },
};

function createBaseUpdateWebhooksRequest_Response(): UpdateWebhooksRequest_Response {
  return { webhooks: [], request: undefined };
}

export const UpdateWebhooksRequest_Response = {
  encode(message: UpdateWebhooksRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.webhooks) {
      Webhook.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UpdateWebhooksRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhooksRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhooksRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhooks.push(Webhook.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = UpdateWebhooksRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateWebhooksRequest_Response>): UpdateWebhooksRequest_Response {
    return UpdateWebhooksRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateWebhooksRequest_Response>): UpdateWebhooksRequest_Response {
    const message = createBaseUpdateWebhooksRequest_Response();
    message.webhooks = object.webhooks?.map((e) => Webhook.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? UpdateWebhooksRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUpdateWebhookTagsRequest(): UpdateWebhookTagsRequest {
  return { organization: undefined, webhookIds: [], update: undefined };
}

export const UpdateWebhookTagsRequest = {
  encode(message: UpdateWebhookTagsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    writer.uint32(18).fork();
    for (const v of message.webhookIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.update !== undefined) {
      UpdateTagsForItem.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookTagsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookTagsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.webhookIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.webhookIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = UpdateTagsForItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateWebhookTagsRequest>): UpdateWebhookTagsRequest {
    return UpdateWebhookTagsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateWebhookTagsRequest>): UpdateWebhookTagsRequest {
    const message = createBaseUpdateWebhookTagsRequest();
    message.organization = object.organization ?? undefined;
    message.webhookIds = object.webhookIds?.map((e) => e) || [];
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateTagsForItem.fromPartial(object.update)
      : undefined;
    return message;
  },
};

function createBaseUpdateWebhookTagsRequest_Response(): UpdateWebhookTagsRequest_Response {
  return { webhooks: [] };
}

export const UpdateWebhookTagsRequest_Response = {
  encode(message: UpdateWebhookTagsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.webhooks) {
      Webhook.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWebhookTagsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWebhookTagsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhooks.push(Webhook.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateWebhookTagsRequest_Response>): UpdateWebhookTagsRequest_Response {
    return UpdateWebhookTagsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateWebhookTagsRequest_Response>): UpdateWebhookTagsRequest_Response {
    const message = createBaseUpdateWebhookTagsRequest_Response();
    message.webhooks = object.webhooks?.map((e) => Webhook.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteWebhookRequest(): DeleteWebhookRequest {
  return { id: 0, organization: "" };
}

export const DeleteWebhookRequest = {
  encode(message: DeleteWebhookRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.organization !== "") {
      writer.uint32(18).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteWebhookRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteWebhookRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteWebhookRequest>): DeleteWebhookRequest {
    return DeleteWebhookRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteWebhookRequest>): DeleteWebhookRequest {
    const message = createBaseDeleteWebhookRequest();
    message.id = object.id ?? 0;
    message.organization = object.organization ?? "";
    return message;
  },
};

function createBaseDeleteWebhookRequest_Response(): DeleteWebhookRequest_Response {
  return {};
}

export const DeleteWebhookRequest_Response = {
  encode(_: DeleteWebhookRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteWebhookRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteWebhookRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteWebhookRequest_Response>): DeleteWebhookRequest_Response {
    return DeleteWebhookRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteWebhookRequest_Response>): DeleteWebhookRequest_Response {
    const message = createBaseDeleteWebhookRequest_Response();
    return message;
  },
};

export type WebhookServiceDefinition = typeof WebhookServiceDefinition;
export const WebhookServiceDefinition = {
  name: "WebhookService",
  fullName: "hiber.webhook.WebhookService",
  methods: {
    list: {
      name: "List",
      requestType: ListWebhooksRequest,
      requestStream: false,
      responseType: ListWebhooksRequest_Response,
      responseStream: false,
      options: {},
    },
    history: {
      name: "History",
      requestType: WebhookHistoryRequest,
      requestStream: false,
      responseType: WebhookHistoryRequest_Response,
      responseStream: false,
      options: {},
    },
    create: {
      name: "Create",
      requestType: CreateWebhookRequest,
      requestStream: false,
      responseType: Webhook,
      responseStream: false,
      options: {},
    },
    enable: {
      name: "Enable",
      requestType: EnableWebhookRequest,
      requestStream: false,
      responseType: Webhook,
      responseStream: false,
      options: {},
    },
    disable: {
      name: "Disable",
      requestType: DisableWebhookRequest,
      requestStream: false,
      responseType: Webhook,
      responseStream: false,
      options: {},
    },
    updateFilter: {
      name: "UpdateFilter",
      requestType: UpdateWebhookFilterRequest,
      requestStream: false,
      responseType: Webhook,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: UpdateWebhookRequest,
      requestStream: false,
      responseType: Webhook,
      responseStream: false,
      options: {},
    },
    updateWebhooks: {
      name: "UpdateWebhooks",
      requestType: UpdateWebhooksRequest,
      requestStream: false,
      responseType: UpdateWebhooksRequest_Response,
      responseStream: false,
      options: {},
    },
    updateTags: {
      name: "UpdateTags",
      requestType: UpdateWebhookTagsRequest,
      requestStream: false,
      responseType: UpdateWebhookTagsRequest_Response,
      responseStream: false,
      options: {},
    },
    get: {
      name: "Get",
      requestType: GetWebhookRequest,
      requestStream: false,
      responseType: Webhook,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteWebhookRequest,
      requestStream: false,
      responseType: DeleteWebhookRequest_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
