import { getGrpcClient } from '@/services/app';

// Tokens
import {
  CreateTokenRequest,
  DeleteTokenRequest,
  ListTokensRequest,
  Token_Type,
  TokenServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/token';

// Webhooks
import {
  CreateWebhookRequest,
  DeleteWebhookRequest,
  EnableWebhookRequest,
  ListWebhooksRequest,
  UpdateWebhookRequest,
  UpdateWebhookRequest_UpdateWebhook,
  Webhook_WebhookFilters,
  WebhookHistoryRequest,
  WebhookServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/webhook';

// Slack
import {
  CreateSlackPublisherRequest,
  DeleteSlackPublisherRequest,
  ListSlackPublishersRequest,
  SlackIntegrationServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/integration_slack';
import {
  CreateMQTTPublisherRequest,
  DeleteMQTTPublisherRequest,
  ListMQTTPublishersRequest,
  MQTTServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/integration_mqtt';
import { OrganizationPermission, UserPermission } from 'hiber-grpc/src/customer_compiled/permission';

export function fetchTokens(size: number, page: number) {
  const request = ListTokensRequest.fromPartial({
    pagination: { size, page },
  });
  const client = getGrpcClient<typeof TokenServiceDefinition>(TokenServiceDefinition);
  return client.list(request);
}

export function createToken(
  name: string,
  expiresAt: string,
  orgPermissionsArray: OrganizationPermission[],
  userPermissionsArray: UserPermission[],
  tokenType: Token_Type,
  userId: string,
) {
  const request = CreateTokenRequest.fromPartial({
    name,
    expiresAt: { textual: expiresAt },
    organizationPermissions: tokenType === Token_Type.SPECIFIC ? { include: orgPermissionsArray } : undefined,
    userPermissions: tokenType === Token_Type.SPECIFIC ? { include: userPermissionsArray } : undefined,
    type: tokenType,
    forUserId: userId,
  });
  const client = getGrpcClient<typeof TokenServiceDefinition>(TokenServiceDefinition);
  return client.create(request);
}

export function deleteToken(id: number) {
  const request = DeleteTokenRequest.fromPartial({ tokenId: id });
  const client = getGrpcClient<typeof TokenServiceDefinition>(TokenServiceDefinition);
  return client.delete(request);
}

export function fetchWebhooks(size: number, page: number) {
  const request = ListWebhooksRequest.fromPartial({ pagination: { size, page } });
  const client = getGrpcClient<typeof WebhookServiceDefinition>(WebhookServiceDefinition);
  return client.list(request);
}

export function fetchWebhookHistory(id: number, size: number, page: number) {
  const request = WebhookHistoryRequest.fromPartial({
    pagination: { size, page },
    webhookId: id,
  });
  const client = getGrpcClient<typeof WebhookServiceDefinition>(WebhookServiceDefinition);
  return client.history(request);
}

export function createWebhook(settings) {
  const request = CreateWebhookRequest.fromPartial({
    description: settings.name,
    data: {
      url: settings.url,
      contentType: settings.contentType,
      hmacHeaderName: settings.hmacHeaderName,
      secret: settings.secret,
      customHeaders: settings.customHeaders,
    },
    filters: Webhook_WebhookFilters.fromPartial({
      tags: { include: settings.filter.tags || [] },
      modemNumbers: { include: settings.filter.modems || [] },
      eventTypes: { include: settings.filter.events },
    }),
  });
  const client = getGrpcClient<typeof WebhookServiceDefinition>(WebhookServiceDefinition);
  return client.create(request);
}

export function updateWebhook(settings, ids: number[]) {
  const requests = ids.map((itemId) => {
    const request = UpdateWebhookRequest.fromPartial({
      id: itemId,
      update: UpdateWebhookRequest_UpdateWebhook.fromPartial({
        contentType: settings.contentType,
        eventFilter: { include: settings.filter.events },
        modemFilter: { include: settings.filter.modems || [] },
        tagFilter: { include: settings.filter.tags || [] },
        replaceCustomHeaders: settings.customHeaders,
        removeCustomHeaders: settings.removeCustomHeaders,
      }),
    });

    const { update } = request;
    if (update) {
      if (settings.hmacHeaderName !== 'Multiple...') {
        update.updateHmacHeaderName = settings.hmacHeaderName;
      }
      if (settings.url !== 'Multiple...') {
        update.url = settings.url;
      }
      if (ids.length === 1) {
        update.description = settings.name;
        update.secret = settings.secret;
      }
    }

    const client = getGrpcClient<typeof WebhookServiceDefinition>(WebhookServiceDefinition);
    return client.update(request);
  });
  return Promise.all(requests);
}

export function enableWebhook(id: number) {
  const request = EnableWebhookRequest.fromPartial({ id });
  const client = getGrpcClient<typeof WebhookServiceDefinition>(WebhookServiceDefinition);
  return client.enable(request);
}

export function deleteWebhook(id: number) {
  const request = DeleteWebhookRequest.fromPartial({ id });
  const client = getGrpcClient<typeof WebhookServiceDefinition>(WebhookServiceDefinition);
  return client.delete(request);
}

export function fetchSlackList(size: number, page: number) {
  const request = ListSlackPublishersRequest.fromPartial({
    pagination: { size, page },
  });
  const client = getGrpcClient<typeof SlackIntegrationServiceDefinition>(SlackIntegrationServiceDefinition);
  return client.list(request);
}

export function createSlackIntegrationRequest(settings) {
  const request = CreateSlackPublisherRequest.fromPartial({
    description: settings.name,
    url: settings.url,
    channel: settings.channel,
    filterEventTypes: { include: settings.filter.events },
    filterTags: { include: settings.filter.tags || [] },
    filterModemNumbers: { include: settings.filter.modemFilter || [] },
  });

  const client = getGrpcClient<typeof SlackIntegrationServiceDefinition>(SlackIntegrationServiceDefinition);
  return client.create(request);
}

export function deleteSlackRequest(id: number) {
  const request = DeleteSlackPublisherRequest.fromPartial({
    id,
  });
  const client = getGrpcClient<typeof SlackIntegrationServiceDefinition>(SlackIntegrationServiceDefinition);
  return client.delete(request);
}

export function fetchMQTT(size: number, page: number) {
  const request = ListMQTTPublishersRequest.fromPartial({
    pagination: { size, page },
  });
  const client = getGrpcClient<typeof MQTTServiceDefinition>(MQTTServiceDefinition);
  return client.list(request);
}

export function createMQTTIntegration(settings) {
  const request = CreateMQTTPublisherRequest.fromPartial({
    description: settings.name,
    url: settings.url,
    contentType: settings.contentType,
    topic: settings.topic,
    qos: settings.qos,
    identifier: settings.identifier || undefined,
    username: settings.username || undefined,
    password: settings.password || undefined,
    certificateId: settings.certificateId || undefined,
    filterEventTypes: { include: settings.filter.events },
    filterTags: { include: settings.filter.tags || [] },
    filterModemNumbers: { include: settings.filter.modemFilter || [] },
  });
  const client = getGrpcClient<typeof MQTTServiceDefinition>(MQTTServiceDefinition);
  return client.create(request);
}

export function deleteMQTT(id: number) {
  const request = DeleteMQTTPublisherRequest.fromPartial({ id });
  const client = getGrpcClient<typeof MQTTServiceDefinition>(MQTTServiceDefinition);
  return client.delete(request);
}
