import CoreModule from '@/store/core/modules/app';
import { tablesObject } from '@/store/modules/tables';

const featureFlagLorawanIsWorking = false;

const App = {
  namespaced: true,
  state: {
    ...CoreModule.state,
    autoRefresh: 30000, // 30 seconds
  },
  actions: {
    ...CoreModule.actions,
    async getBase({ dispatch }) {
      await dispatch('Organization/listChildOrganizations', null, { root: true });
      await dispatch('Modem/getTags', null, { root: true });
      await dispatch('Events/getEvents', null, { root: true });
      if (featureFlagLorawanIsWorking) {
        await dispatch('Lorawan/getConfiguration', null, { root: true });
      }
    },
    async getAll({ dispatch }) {
      dispatch('resetData');
      await dispatch('User/getCurrentUser', true, { root: true });
      await dispatch('Organization/getOrganization', null, { root: true });
      await dispatch('Organization/listChildOrganizations', null, { root: true });

      /*
      // I don't think we need all these calls when switching orgs
      // lets leave them commented out and see what happens
      // could be that only getOrganization is enough to know if user can impersonate

      await dispatch('Modem/getModemsDynamic', null, { root: true });
      await dispatch('Modem/fetchTags', null, { root: true });
      await dispatch('Dashboard/getDashboard', null, { root: true });
      await dispatch('Dashboard/getStatus', null, { root: true });
      await dispatch('Events/getEvents', { forceUpdate: true }, { root: true });
      await dispatch('Transfers/getModemInboundTransfers', null, { root: true });
      await dispatch('Transfers/getModemOutboundTransfers', null, { root: true });
      await dispatch('User/getUsers', null, { root: true });
      await dispatch('User/getInvitedUsers', null, { root: true });
      await dispatch('Integrations/getTokensList', null, { root: true });
      await dispatch('Integrations/forceWebhookRefresh', null, { root: true });
      await dispatch('Integrations/getSlackList', null, { root: true });
      await dispatch('Integrations/getMQTTList', null, { root: true });
      if (featureFlagLorawanIsWorking) {
        await dispatch('Lorawan/getConfiguration', null, { root: true });
      }

      */
    },
    resetData({ dispatch }) {
      const tableNames = Object.keys(tablesObject);
      tableNames.forEach((name) => {
        dispatch('Tables/setSelection', { name, rows: [], data: {} }, { root: true });
        dispatch('Tables/setAllSelected', { name, state: false }, { root: true });
      });
    },
    refresh(context) {
      return Promise.all([
        context.dispatch('Events/getEvents', null, { root: true }),
      ]);
    },
  },
  mutations: {
    ...CoreModule.mutations,
  },
  getters: {
    ...CoreModule.getters,
  },
};

export default App;
