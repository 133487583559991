/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  Area,
  BytesOrHex,
  BytesOrHex_Update,
  Duration,
  Location,
  Pagination,
  Pagination_Result,
  Timestamp,
  UpdateBoolean,
} from "./base";
import { Struct } from "./google/protobuf/struct";
import { ModemSelection } from "./modem";

export const protobufPackage = "hiber.testing";

/**
 * Push any number of modem messages. These are marked as test messages, which means they will be treated
 * differently in a few places (i.e. message sequence, to avoid errors on the next real messages).
 * The message is handled like a real message, it's encrypted as if sent from a modem, then goes through
 * the decryption process and all relevant events are triggered (including webhooks calls caused by the events).
 */
export interface PushModemMessagesRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  messages: PushModemMessagesRequest_MessageToPush[];
}

export interface PushModemMessagesRequest_Response {
  request: PushModemMessagesRequest | undefined;
}

export interface PushModemMessagesRequest_MessageToPush {
  modemNumber: string;
  time?: Timestamp | undefined;
  location?: Location | undefined;
  content?:
    | Uint8Array
    | undefined;
  /** Metadata for this message. For example: LoRaWAN values like fPort. */
  metadata?: { [key: string]: any } | undefined;
}

/**
 * Simulation settings for this organization.
 *
 * The simulation settings for an organization can be used to configure some organization-wide setting for
 * specific simulations, like a default for the number of messages to simulate per enabled modem), or configuring
 * a percentage of simulated messages to trigger a delayed event.
 */
export interface Simulation {
  organization: string;
  /** Whether or not all simulations for this organization are enabled. */
  enabled: boolean;
  /** Configuration for simulating modem inactivity. */
  modemInactivity:
    | Simulation_ModemInactivitySimulation
    | undefined;
  /** Configuration for simulating delayed messages. */
  delayedMessages:
    | Simulation_DelayedMessageSimulation
    | undefined;
  /** Default configuration for the modem message simulation. */
  modemMessageSimulationDefaults:
    | Simulation_ModemMessageSimulation_Defaults
    | undefined;
  /** Summary of modems with message simulation. */
  modemMessageSimulation: Simulation_ModemMessageSimulation_Summary | undefined;
}

/**
 * Simulate modem inactivity randomly.
 *
 * Requires modems with message simulation.
 * For all those modems, randomly picks modems up to the configured percentage to be inactive.
 * Those modems are excluded from the simulation for a period between period_min and period_max.
 * When a modem is no longer inactive, another modem is randomly picked to be inactive. This allows you to test
 * inactivity detection, health, etc.
 *
 * This does not affect messages that are not simulated (like test messages or real messages).
 */
export interface Simulation_ModemInactivitySimulation {
  percentageOfSimulatingModems: number;
  periodMin: Duration | undefined;
  periodMax: Duration | undefined;
}

/**
 * Simulate delayed messages.
 *
 * Requires modems with message simulation.
 * Adds a random delay between minimum_message_delay and maximum_message_delay to a percentage of messages
 * generated by the modem message simulation.
 * The messages themselves are not actually delayed; this just sets the sent time further into the past.
 */
export interface Simulation_DelayedMessageSimulation {
  percentageOfSimulatedMessages: number;
  minimumMessageDelay: Duration | undefined;
  maximumMessageDelay: Duration | undefined;
}

export interface Simulation_GetRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
}

export interface Simulation_UpdateRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedEnabled: UpdateBoolean | undefined;
  enabled?: boolean | undefined;
  simulateModemInactivity?: Simulation_ModemInactivitySimulation | undefined;
  simulateDelayedMessages?: Simulation_DelayedMessageSimulation | undefined;
  modemMessageSimulationDefaults?: Simulation_ModemMessageSimulation_Defaults | undefined;
}

export interface Simulation_ModemMessageSimulation {
  modemNumber: string;
  /**
   * The number of simulated messages per day for this modem.
   * This value is either the custom amount per day for this modem or the default value
   */
  messagesPerInterval: number;
  /** Interval messages should be simulated at (i.e. once every hour). */
  simulationInterval: Duration | undefined;
  messageBodies?:
    | { $case: "messageBody"; messageBody: BytesOrHex }
    | { $case: "messageBodyRotation"; messageBodyRotation: Simulation_ModemMessageSimulation_MessageBodyRotation }
    | undefined;
  /** The indices of bytes in the message body that should be randomized (to have variable values). */
  messageBodyVariableByteIndices: number[];
  location?:
    | { $case: "randomLocationInArea"; randomLocationInArea: Area }
    | { $case: "locationRotation"; locationRotation: Simulation_ModemMessageSimulation_LocationRotation }
    | undefined;
  /**
   * Timestamp after which the next simulation run will be triggered (not guaranteed to be exactly at this time).
   * If further in the future, message simulation for this modem is inactive until this time.
   * This may be set to a time in the future manually, or by the ModemInactivitySimulation.
   */
  nextSimulationRunAfter: Timestamp | undefined;
}

/** Rotate through a list of message bodies. */
export interface Simulation_ModemMessageSimulation_MessageBodyRotation {
  /** The rotating list of message bodies to use when simulating messages. */
  messageBodies: BytesOrHex[];
}

/** Rotate through a list of locations. */
export interface Simulation_ModemMessageSimulation_LocationRotation {
  /** The rotating list of locations to use when simulating messages. */
  locations: Location[];
}

export interface Simulation_ModemMessageSimulation_Defaults {
  /**
   * Default for the amount of messages to simulate per interval for a modem that has simulation enabled.
   * Must be between 1 and 24 (for a maximum of 240 message per day).
   */
  messagesPerInterval: number;
  /** Default for interval messages should be simulated at (i.e. once every hour). */
  simulationInterval: Duration | undefined;
}

export interface Simulation_ModemMessageSimulation_Summary {
  /** The number of modems for which message simulation has been enabled. */
  enabledModems: number;
  /** The number of modems that are actively simulating messages. */
  activeModems: number;
  /** The number of modems that have been marked inactive (by ModemInactivitySimulation or a user). */
  inactiveModems: number;
  /** The total amount of messages that will be simulated in this organization per day. */
  totalMessagesPerDay: number;
  /** The number of modem message simulation runs for this organization per day. */
  totalSimulationsPerDay: number;
}

export interface Simulation_ModemMessageSimulation_List {
}

export interface Simulation_ModemMessageSimulation_List_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  modems: ModemSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Include modems for which message simulation is disabled. */
  includeDisabled: boolean;
}

export interface Simulation_ModemMessageSimulation_List_Response {
  modems: Simulation_ModemMessageSimulation[];
  request: Simulation_ModemMessageSimulation_List_Request | undefined;
  pagination: Pagination_Result | undefined;
}

/** Create or update the modem message simulation for a selection of modems. */
export interface Simulation_ModemMessageSimulation_Update {
}

export interface Simulation_ModemMessageSimulation_Update_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Modems to create or update the message simulation for. */
  modems:
    | ModemSelection
    | undefined;
  /**
   * The number of simulated messages per simulation interval for these modems.
   * This value is either the custom amount per day for these modems or the default value
   */
  messagesPerInterval: number;
  /** Interval messages should be simulated at (i.e. once every hour). */
  simulationInterval: Duration | undefined;
  messageBody?:
    | { $case: "updateMessageBody"; updateMessageBody: BytesOrHex_Update }
    | {
      $case: "replaceMessageBodyRotation";
      replaceMessageBodyRotation: Simulation_ModemMessageSimulation_MessageBodyRotation;
    }
    | undefined;
  /** Add indices of bytes in the message body that should be randomized (to have variable values). */
  messageBodyAddVariableByteIndices: number[];
  /** Remove indices of bytes in the message body that should be randomized (to have variable values). */
  messageBodyRemoveVariableByteIndices: number[];
  location?:
    | { $case: "randomLocationInArea"; randomLocationInArea: Area }
    | { $case: "replaceLocationRotation"; replaceLocationRotation: Simulation_ModemMessageSimulation_LocationRotation }
    | undefined;
  /** Disable the message simulation until the given date (UTC). */
  setInactiveUntil: Timestamp | undefined;
  pagination?: Pagination | undefined;
}

export interface Simulation_ModemMessageSimulation_Update_Response {
  modems: Simulation_ModemMessageSimulation[];
  request: Simulation_ModemMessageSimulation_Update_Request | undefined;
  pagination: Pagination_Result | undefined;
}

export interface Simulation_ModemMessageSimulation_Delete {
}

export interface Simulation_ModemMessageSimulation_Delete_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  modems: ModemSelection | undefined;
}

export interface Simulation_ModemMessageSimulation_Delete_Response {
  deleted: number;
  request: Simulation_ModemMessageSimulation_Delete_Request | undefined;
}

function createBasePushModemMessagesRequest(): PushModemMessagesRequest {
  return { organization: undefined, messages: [] };
}

export const PushModemMessagesRequest = {
  encode(message: PushModemMessagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.messages) {
      PushModemMessagesRequest_MessageToPush.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PushModemMessagesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePushModemMessagesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.messages.push(PushModemMessagesRequest_MessageToPush.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<PushModemMessagesRequest>): PushModemMessagesRequest {
    return PushModemMessagesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PushModemMessagesRequest>): PushModemMessagesRequest {
    const message = createBasePushModemMessagesRequest();
    message.organization = object.organization ?? undefined;
    message.messages = object.messages?.map((e) => PushModemMessagesRequest_MessageToPush.fromPartial(e)) || [];
    return message;
  },
};

function createBasePushModemMessagesRequest_Response(): PushModemMessagesRequest_Response {
  return { request: undefined };
}

export const PushModemMessagesRequest_Response = {
  encode(message: PushModemMessagesRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      PushModemMessagesRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PushModemMessagesRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePushModemMessagesRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = PushModemMessagesRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<PushModemMessagesRequest_Response>): PushModemMessagesRequest_Response {
    return PushModemMessagesRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PushModemMessagesRequest_Response>): PushModemMessagesRequest_Response {
    const message = createBasePushModemMessagesRequest_Response();
    message.request = (object.request !== undefined && object.request !== null)
      ? PushModemMessagesRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBasePushModemMessagesRequest_MessageToPush(): PushModemMessagesRequest_MessageToPush {
  return { modemNumber: "", time: undefined, location: undefined, content: undefined, metadata: undefined };
}

export const PushModemMessagesRequest_MessageToPush = {
  encode(message: PushModemMessagesRequest_MessageToPush, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modemNumber !== "") {
      writer.uint32(10).string(message.modemNumber);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(18).fork()).ldelim();
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(26).fork()).ldelim();
    }
    if (message.content !== undefined) {
      writer.uint32(34).bytes(message.content);
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PushModemMessagesRequest_MessageToPush {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePushModemMessagesRequest_MessageToPush();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.content = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<PushModemMessagesRequest_MessageToPush>): PushModemMessagesRequest_MessageToPush {
    return PushModemMessagesRequest_MessageToPush.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PushModemMessagesRequest_MessageToPush>): PushModemMessagesRequest_MessageToPush {
    const message = createBasePushModemMessagesRequest_MessageToPush();
    message.modemNumber = object.modemNumber ?? "";
    message.time = (object.time !== undefined && object.time !== null) ? Timestamp.fromPartial(object.time) : undefined;
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.content = object.content ?? undefined;
    message.metadata = object.metadata ?? undefined;
    return message;
  },
};

function createBaseSimulation(): Simulation {
  return {
    organization: "",
    enabled: false,
    modemInactivity: undefined,
    delayedMessages: undefined,
    modemMessageSimulationDefaults: undefined,
    modemMessageSimulation: undefined,
  };
}

export const Simulation = {
  encode(message: Simulation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.enabled === true) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.modemInactivity !== undefined) {
      Simulation_ModemInactivitySimulation.encode(message.modemInactivity, writer.uint32(26).fork()).ldelim();
    }
    if (message.delayedMessages !== undefined) {
      Simulation_DelayedMessageSimulation.encode(message.delayedMessages, writer.uint32(34).fork()).ldelim();
    }
    if (message.modemMessageSimulationDefaults !== undefined) {
      Simulation_ModemMessageSimulation_Defaults.encode(
        message.modemMessageSimulationDefaults,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.modemMessageSimulation !== undefined) {
      Simulation_ModemMessageSimulation_Summary.encode(message.modemMessageSimulation, writer.uint32(50).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modemInactivity = Simulation_ModemInactivitySimulation.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.delayedMessages = Simulation_DelayedMessageSimulation.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modemMessageSimulationDefaults = Simulation_ModemMessageSimulation_Defaults.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.modemMessageSimulation = Simulation_ModemMessageSimulation_Summary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation>): Simulation {
    return Simulation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Simulation>): Simulation {
    const message = createBaseSimulation();
    message.organization = object.organization ?? "";
    message.enabled = object.enabled ?? false;
    message.modemInactivity = (object.modemInactivity !== undefined && object.modemInactivity !== null)
      ? Simulation_ModemInactivitySimulation.fromPartial(object.modemInactivity)
      : undefined;
    message.delayedMessages = (object.delayedMessages !== undefined && object.delayedMessages !== null)
      ? Simulation_DelayedMessageSimulation.fromPartial(object.delayedMessages)
      : undefined;
    message.modemMessageSimulationDefaults =
      (object.modemMessageSimulationDefaults !== undefined && object.modemMessageSimulationDefaults !== null)
        ? Simulation_ModemMessageSimulation_Defaults.fromPartial(object.modemMessageSimulationDefaults)
        : undefined;
    message.modemMessageSimulation =
      (object.modemMessageSimulation !== undefined && object.modemMessageSimulation !== null)
        ? Simulation_ModemMessageSimulation_Summary.fromPartial(object.modemMessageSimulation)
        : undefined;
    return message;
  },
};

function createBaseSimulation_ModemInactivitySimulation(): Simulation_ModemInactivitySimulation {
  return { percentageOfSimulatingModems: 0, periodMin: undefined, periodMax: undefined };
}

export const Simulation_ModemInactivitySimulation = {
  encode(message: Simulation_ModemInactivitySimulation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.percentageOfSimulatingModems !== 0) {
      writer.uint32(8).uint32(message.percentageOfSimulatingModems);
    }
    if (message.periodMin !== undefined) {
      Duration.encode(message.periodMin, writer.uint32(18).fork()).ldelim();
    }
    if (message.periodMax !== undefined) {
      Duration.encode(message.periodMax, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemInactivitySimulation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemInactivitySimulation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.percentageOfSimulatingModems = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.periodMin = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.periodMax = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_ModemInactivitySimulation>): Simulation_ModemInactivitySimulation {
    return Simulation_ModemInactivitySimulation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Simulation_ModemInactivitySimulation>): Simulation_ModemInactivitySimulation {
    const message = createBaseSimulation_ModemInactivitySimulation();
    message.percentageOfSimulatingModems = object.percentageOfSimulatingModems ?? 0;
    message.periodMin = (object.periodMin !== undefined && object.periodMin !== null)
      ? Duration.fromPartial(object.periodMin)
      : undefined;
    message.periodMax = (object.periodMax !== undefined && object.periodMax !== null)
      ? Duration.fromPartial(object.periodMax)
      : undefined;
    return message;
  },
};

function createBaseSimulation_DelayedMessageSimulation(): Simulation_DelayedMessageSimulation {
  return { percentageOfSimulatedMessages: 0, minimumMessageDelay: undefined, maximumMessageDelay: undefined };
}

export const Simulation_DelayedMessageSimulation = {
  encode(message: Simulation_DelayedMessageSimulation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.percentageOfSimulatedMessages !== 0) {
      writer.uint32(8).uint32(message.percentageOfSimulatedMessages);
    }
    if (message.minimumMessageDelay !== undefined) {
      Duration.encode(message.minimumMessageDelay, writer.uint32(18).fork()).ldelim();
    }
    if (message.maximumMessageDelay !== undefined) {
      Duration.encode(message.maximumMessageDelay, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_DelayedMessageSimulation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_DelayedMessageSimulation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.percentageOfSimulatedMessages = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.minimumMessageDelay = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maximumMessageDelay = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_DelayedMessageSimulation>): Simulation_DelayedMessageSimulation {
    return Simulation_DelayedMessageSimulation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Simulation_DelayedMessageSimulation>): Simulation_DelayedMessageSimulation {
    const message = createBaseSimulation_DelayedMessageSimulation();
    message.percentageOfSimulatedMessages = object.percentageOfSimulatedMessages ?? 0;
    message.minimumMessageDelay = (object.minimumMessageDelay !== undefined && object.minimumMessageDelay !== null)
      ? Duration.fromPartial(object.minimumMessageDelay)
      : undefined;
    message.maximumMessageDelay = (object.maximumMessageDelay !== undefined && object.maximumMessageDelay !== null)
      ? Duration.fromPartial(object.maximumMessageDelay)
      : undefined;
    return message;
  },
};

function createBaseSimulation_GetRequest(): Simulation_GetRequest {
  return { organization: undefined };
}

export const Simulation_GetRequest = {
  encode(message: Simulation_GetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_GetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_GetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_GetRequest>): Simulation_GetRequest {
    return Simulation_GetRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Simulation_GetRequest>): Simulation_GetRequest {
    const message = createBaseSimulation_GetRequest();
    message.organization = object.organization ?? undefined;
    return message;
  },
};

function createBaseSimulation_UpdateRequest(): Simulation_UpdateRequest {
  return {
    organization: undefined,
    deprecatedEnabled: undefined,
    enabled: undefined,
    simulateModemInactivity: undefined,
    simulateDelayedMessages: undefined,
    modemMessageSimulationDefaults: undefined,
  };
}

export const Simulation_UpdateRequest = {
  encode(message: Simulation_UpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.deprecatedEnabled !== undefined) {
      UpdateBoolean.encode(message.deprecatedEnabled, writer.uint32(18).fork()).ldelim();
    }
    if (message.enabled !== undefined) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.simulateModemInactivity !== undefined) {
      Simulation_ModemInactivitySimulation.encode(message.simulateModemInactivity, writer.uint32(26).fork()).ldelim();
    }
    if (message.simulateDelayedMessages !== undefined) {
      Simulation_DelayedMessageSimulation.encode(message.simulateDelayedMessages, writer.uint32(34).fork()).ldelim();
    }
    if (message.modemMessageSimulationDefaults !== undefined) {
      Simulation_ModemMessageSimulation_Defaults.encode(
        message.modemMessageSimulationDefaults,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_UpdateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_UpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deprecatedEnabled = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.simulateModemInactivity = Simulation_ModemInactivitySimulation.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.simulateDelayedMessages = Simulation_DelayedMessageSimulation.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modemMessageSimulationDefaults = Simulation_ModemMessageSimulation_Defaults.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_UpdateRequest>): Simulation_UpdateRequest {
    return Simulation_UpdateRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Simulation_UpdateRequest>): Simulation_UpdateRequest {
    const message = createBaseSimulation_UpdateRequest();
    message.organization = object.organization ?? undefined;
    message.deprecatedEnabled = (object.deprecatedEnabled !== undefined && object.deprecatedEnabled !== null)
      ? UpdateBoolean.fromPartial(object.deprecatedEnabled)
      : undefined;
    message.enabled = object.enabled ?? undefined;
    message.simulateModemInactivity =
      (object.simulateModemInactivity !== undefined && object.simulateModemInactivity !== null)
        ? Simulation_ModemInactivitySimulation.fromPartial(object.simulateModemInactivity)
        : undefined;
    message.simulateDelayedMessages =
      (object.simulateDelayedMessages !== undefined && object.simulateDelayedMessages !== null)
        ? Simulation_DelayedMessageSimulation.fromPartial(object.simulateDelayedMessages)
        : undefined;
    message.modemMessageSimulationDefaults =
      (object.modemMessageSimulationDefaults !== undefined && object.modemMessageSimulationDefaults !== null)
        ? Simulation_ModemMessageSimulation_Defaults.fromPartial(object.modemMessageSimulationDefaults)
        : undefined;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation(): Simulation_ModemMessageSimulation {
  return {
    modemNumber: "",
    messagesPerInterval: 0,
    simulationInterval: undefined,
    messageBodies: undefined,
    messageBodyVariableByteIndices: [],
    location: undefined,
    nextSimulationRunAfter: undefined,
  };
}

export const Simulation_ModemMessageSimulation = {
  encode(message: Simulation_ModemMessageSimulation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modemNumber !== "") {
      writer.uint32(10).string(message.modemNumber);
    }
    if (message.messagesPerInterval !== 0) {
      writer.uint32(16).uint32(message.messagesPerInterval);
    }
    if (message.simulationInterval !== undefined) {
      Duration.encode(message.simulationInterval, writer.uint32(26).fork()).ldelim();
    }
    switch (message.messageBodies?.$case) {
      case "messageBody":
        BytesOrHex.encode(message.messageBodies.messageBody, writer.uint32(34).fork()).ldelim();
        break;
      case "messageBodyRotation":
        Simulation_ModemMessageSimulation_MessageBodyRotation.encode(
          message.messageBodies.messageBodyRotation,
          writer.uint32(66).fork(),
        ).ldelim();
        break;
    }
    writer.uint32(42).fork();
    for (const v of message.messageBodyVariableByteIndices) {
      writer.uint32(v);
    }
    writer.ldelim();
    switch (message.location?.$case) {
      case "randomLocationInArea":
        Area.encode(message.location.randomLocationInArea, writer.uint32(58).fork()).ldelim();
        break;
      case "locationRotation":
        Simulation_ModemMessageSimulation_LocationRotation.encode(
          message.location.locationRotation,
          writer.uint32(74).fork(),
        ).ldelim();
        break;
    }
    if (message.nextSimulationRunAfter !== undefined) {
      Timestamp.encode(message.nextSimulationRunAfter, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.messagesPerInterval = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.simulationInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.messageBodies = { $case: "messageBody", messageBody: BytesOrHex.decode(reader, reader.uint32()) };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.messageBodies = {
            $case: "messageBodyRotation",
            messageBodyRotation: Simulation_ModemMessageSimulation_MessageBodyRotation.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag === 40) {
            message.messageBodyVariableByteIndices.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.messageBodyVariableByteIndices.push(reader.uint32());
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.location = {
            $case: "randomLocationInArea",
            randomLocationInArea: Area.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.location = {
            $case: "locationRotation",
            locationRotation: Simulation_ModemMessageSimulation_LocationRotation.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.nextSimulationRunAfter = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_ModemMessageSimulation>): Simulation_ModemMessageSimulation {
    return Simulation_ModemMessageSimulation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Simulation_ModemMessageSimulation>): Simulation_ModemMessageSimulation {
    const message = createBaseSimulation_ModemMessageSimulation();
    message.modemNumber = object.modemNumber ?? "";
    message.messagesPerInterval = object.messagesPerInterval ?? 0;
    message.simulationInterval = (object.simulationInterval !== undefined && object.simulationInterval !== null)
      ? Duration.fromPartial(object.simulationInterval)
      : undefined;
    if (
      object.messageBodies?.$case === "messageBody" &&
      object.messageBodies?.messageBody !== undefined &&
      object.messageBodies?.messageBody !== null
    ) {
      message.messageBodies = {
        $case: "messageBody",
        messageBody: BytesOrHex.fromPartial(object.messageBodies.messageBody),
      };
    }
    if (
      object.messageBodies?.$case === "messageBodyRotation" &&
      object.messageBodies?.messageBodyRotation !== undefined &&
      object.messageBodies?.messageBodyRotation !== null
    ) {
      message.messageBodies = {
        $case: "messageBodyRotation",
        messageBodyRotation: Simulation_ModemMessageSimulation_MessageBodyRotation.fromPartial(
          object.messageBodies.messageBodyRotation,
        ),
      };
    }
    message.messageBodyVariableByteIndices = object.messageBodyVariableByteIndices?.map((e) => e) || [];
    if (
      object.location?.$case === "randomLocationInArea" &&
      object.location?.randomLocationInArea !== undefined &&
      object.location?.randomLocationInArea !== null
    ) {
      message.location = {
        $case: "randomLocationInArea",
        randomLocationInArea: Area.fromPartial(object.location.randomLocationInArea),
      };
    }
    if (
      object.location?.$case === "locationRotation" &&
      object.location?.locationRotation !== undefined &&
      object.location?.locationRotation !== null
    ) {
      message.location = {
        $case: "locationRotation",
        locationRotation: Simulation_ModemMessageSimulation_LocationRotation.fromPartial(
          object.location.locationRotation,
        ),
      };
    }
    message.nextSimulationRunAfter =
      (object.nextSimulationRunAfter !== undefined && object.nextSimulationRunAfter !== null)
        ? Timestamp.fromPartial(object.nextSimulationRunAfter)
        : undefined;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_MessageBodyRotation(): Simulation_ModemMessageSimulation_MessageBodyRotation {
  return { messageBodies: [] };
}

export const Simulation_ModemMessageSimulation_MessageBodyRotation = {
  encode(
    message: Simulation_ModemMessageSimulation_MessageBodyRotation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.messageBodies) {
      BytesOrHex.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_MessageBodyRotation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_MessageBodyRotation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messageBodies.push(BytesOrHex.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Simulation_ModemMessageSimulation_MessageBodyRotation>,
  ): Simulation_ModemMessageSimulation_MessageBodyRotation {
    return Simulation_ModemMessageSimulation_MessageBodyRotation.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_MessageBodyRotation>,
  ): Simulation_ModemMessageSimulation_MessageBodyRotation {
    const message = createBaseSimulation_ModemMessageSimulation_MessageBodyRotation();
    message.messageBodies = object.messageBodies?.map((e) => BytesOrHex.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_LocationRotation(): Simulation_ModemMessageSimulation_LocationRotation {
  return { locations: [] };
}

export const Simulation_ModemMessageSimulation_LocationRotation = {
  encode(
    message: Simulation_ModemMessageSimulation_LocationRotation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.locations) {
      Location.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_LocationRotation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_LocationRotation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locations.push(Location.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Simulation_ModemMessageSimulation_LocationRotation>,
  ): Simulation_ModemMessageSimulation_LocationRotation {
    return Simulation_ModemMessageSimulation_LocationRotation.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_LocationRotation>,
  ): Simulation_ModemMessageSimulation_LocationRotation {
    const message = createBaseSimulation_ModemMessageSimulation_LocationRotation();
    message.locations = object.locations?.map((e) => Location.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_Defaults(): Simulation_ModemMessageSimulation_Defaults {
  return { messagesPerInterval: 0, simulationInterval: undefined };
}

export const Simulation_ModemMessageSimulation_Defaults = {
  encode(message: Simulation_ModemMessageSimulation_Defaults, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.messagesPerInterval !== 0) {
      writer.uint32(8).uint32(message.messagesPerInterval);
    }
    if (message.simulationInterval !== undefined) {
      Duration.encode(message.simulationInterval, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_Defaults {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_Defaults();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.messagesPerInterval = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.simulationInterval = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_ModemMessageSimulation_Defaults>): Simulation_ModemMessageSimulation_Defaults {
    return Simulation_ModemMessageSimulation_Defaults.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_Defaults>,
  ): Simulation_ModemMessageSimulation_Defaults {
    const message = createBaseSimulation_ModemMessageSimulation_Defaults();
    message.messagesPerInterval = object.messagesPerInterval ?? 0;
    message.simulationInterval = (object.simulationInterval !== undefined && object.simulationInterval !== null)
      ? Duration.fromPartial(object.simulationInterval)
      : undefined;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_Summary(): Simulation_ModemMessageSimulation_Summary {
  return { enabledModems: 0, activeModems: 0, inactiveModems: 0, totalMessagesPerDay: 0, totalSimulationsPerDay: 0 };
}

export const Simulation_ModemMessageSimulation_Summary = {
  encode(message: Simulation_ModemMessageSimulation_Summary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabledModems !== 0) {
      writer.uint32(8).uint32(message.enabledModems);
    }
    if (message.activeModems !== 0) {
      writer.uint32(16).uint32(message.activeModems);
    }
    if (message.inactiveModems !== 0) {
      writer.uint32(24).uint32(message.inactiveModems);
    }
    if (message.totalMessagesPerDay !== 0) {
      writer.uint32(32).uint32(message.totalMessagesPerDay);
    }
    if (message.totalSimulationsPerDay !== 0) {
      writer.uint32(40).uint32(message.totalSimulationsPerDay);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_Summary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_Summary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabledModems = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.activeModems = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.inactiveModems = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.totalMessagesPerDay = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalSimulationsPerDay = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_ModemMessageSimulation_Summary>): Simulation_ModemMessageSimulation_Summary {
    return Simulation_ModemMessageSimulation_Summary.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_Summary>,
  ): Simulation_ModemMessageSimulation_Summary {
    const message = createBaseSimulation_ModemMessageSimulation_Summary();
    message.enabledModems = object.enabledModems ?? 0;
    message.activeModems = object.activeModems ?? 0;
    message.inactiveModems = object.inactiveModems ?? 0;
    message.totalMessagesPerDay = object.totalMessagesPerDay ?? 0;
    message.totalSimulationsPerDay = object.totalSimulationsPerDay ?? 0;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_List(): Simulation_ModemMessageSimulation_List {
  return {};
}

export const Simulation_ModemMessageSimulation_List = {
  encode(_: Simulation_ModemMessageSimulation_List, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_List {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_List();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_ModemMessageSimulation_List>): Simulation_ModemMessageSimulation_List {
    return Simulation_ModemMessageSimulation_List.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Simulation_ModemMessageSimulation_List>): Simulation_ModemMessageSimulation_List {
    const message = createBaseSimulation_ModemMessageSimulation_List();
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_List_Request(): Simulation_ModemMessageSimulation_List_Request {
  return { organization: undefined, modems: undefined, pagination: undefined, includeDisabled: false };
}

export const Simulation_ModemMessageSimulation_List_Request = {
  encode(
    message: Simulation_ModemMessageSimulation_List_Request,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.modems !== undefined) {
      ModemSelection.encode(message.modems, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeDisabled === true) {
      writer.uint32(32).bool(message.includeDisabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_List_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_List_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modems = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeDisabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Simulation_ModemMessageSimulation_List_Request>,
  ): Simulation_ModemMessageSimulation_List_Request {
    return Simulation_ModemMessageSimulation_List_Request.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_List_Request>,
  ): Simulation_ModemMessageSimulation_List_Request {
    const message = createBaseSimulation_ModemMessageSimulation_List_Request();
    message.organization = object.organization ?? undefined;
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? ModemSelection.fromPartial(object.modems)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeDisabled = object.includeDisabled ?? false;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_List_Response(): Simulation_ModemMessageSimulation_List_Response {
  return { modems: [], request: undefined, pagination: undefined };
}

export const Simulation_ModemMessageSimulation_List_Response = {
  encode(
    message: Simulation_ModemMessageSimulation_List_Response,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.modems) {
      Simulation_ModemMessageSimulation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      Simulation_ModemMessageSimulation_List_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_List_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_List_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems.push(Simulation_ModemMessageSimulation.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = Simulation_ModemMessageSimulation_List_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Simulation_ModemMessageSimulation_List_Response>,
  ): Simulation_ModemMessageSimulation_List_Response {
    return Simulation_ModemMessageSimulation_List_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_List_Response>,
  ): Simulation_ModemMessageSimulation_List_Response {
    const message = createBaseSimulation_ModemMessageSimulation_List_Response();
    message.modems = object.modems?.map((e) => Simulation_ModemMessageSimulation.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? Simulation_ModemMessageSimulation_List_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_Update(): Simulation_ModemMessageSimulation_Update {
  return {};
}

export const Simulation_ModemMessageSimulation_Update = {
  encode(_: Simulation_ModemMessageSimulation_Update, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_Update {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_ModemMessageSimulation_Update>): Simulation_ModemMessageSimulation_Update {
    return Simulation_ModemMessageSimulation_Update.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Simulation_ModemMessageSimulation_Update>): Simulation_ModemMessageSimulation_Update {
    const message = createBaseSimulation_ModemMessageSimulation_Update();
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_Update_Request(): Simulation_ModemMessageSimulation_Update_Request {
  return {
    organization: undefined,
    modems: undefined,
    messagesPerInterval: 0,
    simulationInterval: undefined,
    messageBody: undefined,
    messageBodyAddVariableByteIndices: [],
    messageBodyRemoveVariableByteIndices: [],
    location: undefined,
    setInactiveUntil: undefined,
    pagination: undefined,
  };
}

export const Simulation_ModemMessageSimulation_Update_Request = {
  encode(
    message: Simulation_ModemMessageSimulation_Update_Request,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.modems !== undefined) {
      ModemSelection.encode(message.modems, writer.uint32(18).fork()).ldelim();
    }
    if (message.messagesPerInterval !== 0) {
      writer.uint32(24).uint32(message.messagesPerInterval);
    }
    if (message.simulationInterval !== undefined) {
      Duration.encode(message.simulationInterval, writer.uint32(34).fork()).ldelim();
    }
    switch (message.messageBody?.$case) {
      case "updateMessageBody":
        BytesOrHex_Update.encode(message.messageBody.updateMessageBody, writer.uint32(42).fork()).ldelim();
        break;
      case "replaceMessageBodyRotation":
        Simulation_ModemMessageSimulation_MessageBodyRotation.encode(
          message.messageBody.replaceMessageBodyRotation,
          writer.uint32(98).fork(),
        ).ldelim();
        break;
    }
    writer.uint32(50).fork();
    for (const v of message.messageBodyAddVariableByteIndices) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(58).fork();
    for (const v of message.messageBodyRemoveVariableByteIndices) {
      writer.uint32(v);
    }
    writer.ldelim();
    switch (message.location?.$case) {
      case "randomLocationInArea":
        Area.encode(message.location.randomLocationInArea, writer.uint32(90).fork()).ldelim();
        break;
      case "replaceLocationRotation":
        Simulation_ModemMessageSimulation_LocationRotation.encode(
          message.location.replaceLocationRotation,
          writer.uint32(106).fork(),
        ).ldelim();
        break;
    }
    if (message.setInactiveUntil !== undefined) {
      Timestamp.encode(message.setInactiveUntil, writer.uint32(66).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_Update_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_Update_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modems = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.messagesPerInterval = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.simulationInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.messageBody = {
            $case: "updateMessageBody",
            updateMessageBody: BytesOrHex_Update.decode(reader, reader.uint32()),
          };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.messageBody = {
            $case: "replaceMessageBodyRotation",
            replaceMessageBodyRotation: Simulation_ModemMessageSimulation_MessageBodyRotation.decode(
              reader,
              reader.uint32(),
            ),
          };
          continue;
        case 6:
          if (tag === 48) {
            message.messageBodyAddVariableByteIndices.push(reader.uint32());

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.messageBodyAddVariableByteIndices.push(reader.uint32());
            }

            continue;
          }

          break;
        case 7:
          if (tag === 56) {
            message.messageBodyRemoveVariableByteIndices.push(reader.uint32());

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.messageBodyRemoveVariableByteIndices.push(reader.uint32());
            }

            continue;
          }

          break;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.location = {
            $case: "randomLocationInArea",
            randomLocationInArea: Area.decode(reader, reader.uint32()),
          };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.location = {
            $case: "replaceLocationRotation",
            replaceLocationRotation: Simulation_ModemMessageSimulation_LocationRotation.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.setInactiveUntil = Timestamp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Simulation_ModemMessageSimulation_Update_Request>,
  ): Simulation_ModemMessageSimulation_Update_Request {
    return Simulation_ModemMessageSimulation_Update_Request.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_Update_Request>,
  ): Simulation_ModemMessageSimulation_Update_Request {
    const message = createBaseSimulation_ModemMessageSimulation_Update_Request();
    message.organization = object.organization ?? undefined;
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? ModemSelection.fromPartial(object.modems)
      : undefined;
    message.messagesPerInterval = object.messagesPerInterval ?? 0;
    message.simulationInterval = (object.simulationInterval !== undefined && object.simulationInterval !== null)
      ? Duration.fromPartial(object.simulationInterval)
      : undefined;
    if (
      object.messageBody?.$case === "updateMessageBody" &&
      object.messageBody?.updateMessageBody !== undefined &&
      object.messageBody?.updateMessageBody !== null
    ) {
      message.messageBody = {
        $case: "updateMessageBody",
        updateMessageBody: BytesOrHex_Update.fromPartial(object.messageBody.updateMessageBody),
      };
    }
    if (
      object.messageBody?.$case === "replaceMessageBodyRotation" &&
      object.messageBody?.replaceMessageBodyRotation !== undefined &&
      object.messageBody?.replaceMessageBodyRotation !== null
    ) {
      message.messageBody = {
        $case: "replaceMessageBodyRotation",
        replaceMessageBodyRotation: Simulation_ModemMessageSimulation_MessageBodyRotation.fromPartial(
          object.messageBody.replaceMessageBodyRotation,
        ),
      };
    }
    message.messageBodyAddVariableByteIndices = object.messageBodyAddVariableByteIndices?.map((e) => e) || [];
    message.messageBodyRemoveVariableByteIndices = object.messageBodyRemoveVariableByteIndices?.map((e) => e) || [];
    if (
      object.location?.$case === "randomLocationInArea" &&
      object.location?.randomLocationInArea !== undefined &&
      object.location?.randomLocationInArea !== null
    ) {
      message.location = {
        $case: "randomLocationInArea",
        randomLocationInArea: Area.fromPartial(object.location.randomLocationInArea),
      };
    }
    if (
      object.location?.$case === "replaceLocationRotation" &&
      object.location?.replaceLocationRotation !== undefined &&
      object.location?.replaceLocationRotation !== null
    ) {
      message.location = {
        $case: "replaceLocationRotation",
        replaceLocationRotation: Simulation_ModemMessageSimulation_LocationRotation.fromPartial(
          object.location.replaceLocationRotation,
        ),
      };
    }
    message.setInactiveUntil = (object.setInactiveUntil !== undefined && object.setInactiveUntil !== null)
      ? Timestamp.fromPartial(object.setInactiveUntil)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_Update_Response(): Simulation_ModemMessageSimulation_Update_Response {
  return { modems: [], request: undefined, pagination: undefined };
}

export const Simulation_ModemMessageSimulation_Update_Response = {
  encode(
    message: Simulation_ModemMessageSimulation_Update_Response,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.modems) {
      Simulation_ModemMessageSimulation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      Simulation_ModemMessageSimulation_Update_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_Update_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_Update_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems.push(Simulation_ModemMessageSimulation.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = Simulation_ModemMessageSimulation_Update_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Simulation_ModemMessageSimulation_Update_Response>,
  ): Simulation_ModemMessageSimulation_Update_Response {
    return Simulation_ModemMessageSimulation_Update_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_Update_Response>,
  ): Simulation_ModemMessageSimulation_Update_Response {
    const message = createBaseSimulation_ModemMessageSimulation_Update_Response();
    message.modems = object.modems?.map((e) => Simulation_ModemMessageSimulation.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? Simulation_ModemMessageSimulation_Update_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_Delete(): Simulation_ModemMessageSimulation_Delete {
  return {};
}

export const Simulation_ModemMessageSimulation_Delete = {
  encode(_: Simulation_ModemMessageSimulation_Delete, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_Delete {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_Delete();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Simulation_ModemMessageSimulation_Delete>): Simulation_ModemMessageSimulation_Delete {
    return Simulation_ModemMessageSimulation_Delete.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Simulation_ModemMessageSimulation_Delete>): Simulation_ModemMessageSimulation_Delete {
    const message = createBaseSimulation_ModemMessageSimulation_Delete();
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_Delete_Request(): Simulation_ModemMessageSimulation_Delete_Request {
  return { organization: undefined, modems: undefined };
}

export const Simulation_ModemMessageSimulation_Delete_Request = {
  encode(
    message: Simulation_ModemMessageSimulation_Delete_Request,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.modems !== undefined) {
      ModemSelection.encode(message.modems, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_Delete_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_Delete_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modems = ModemSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Simulation_ModemMessageSimulation_Delete_Request>,
  ): Simulation_ModemMessageSimulation_Delete_Request {
    return Simulation_ModemMessageSimulation_Delete_Request.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_Delete_Request>,
  ): Simulation_ModemMessageSimulation_Delete_Request {
    const message = createBaseSimulation_ModemMessageSimulation_Delete_Request();
    message.organization = object.organization ?? undefined;
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? ModemSelection.fromPartial(object.modems)
      : undefined;
    return message;
  },
};

function createBaseSimulation_ModemMessageSimulation_Delete_Response(): Simulation_ModemMessageSimulation_Delete_Response {
  return { deleted: 0, request: undefined };
}

export const Simulation_ModemMessageSimulation_Delete_Response = {
  encode(
    message: Simulation_ModemMessageSimulation_Delete_Response,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deleted !== 0) {
      writer.uint32(8).uint32(message.deleted);
    }
    if (message.request !== undefined) {
      Simulation_ModemMessageSimulation_Delete_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Simulation_ModemMessageSimulation_Delete_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulation_ModemMessageSimulation_Delete_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deleted = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = Simulation_ModemMessageSimulation_Delete_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<Simulation_ModemMessageSimulation_Delete_Response>,
  ): Simulation_ModemMessageSimulation_Delete_Response {
    return Simulation_ModemMessageSimulation_Delete_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Simulation_ModemMessageSimulation_Delete_Response>,
  ): Simulation_ModemMessageSimulation_Delete_Response {
    const message = createBaseSimulation_ModemMessageSimulation_Delete_Response();
    message.deleted = object.deleted ?? 0;
    message.request = (object.request !== undefined && object.request !== null)
      ? Simulation_ModemMessageSimulation_Delete_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

/**
 * Method to test the system in different ways, meant for customer access. For example, you can use this to test
 * whether everything works from message to webhook call, and further in your own system.
 */
export type TestingServiceDefinition = typeof TestingServiceDefinition;
export const TestingServiceDefinition = {
  name: "TestingService",
  fullName: "hiber.testing.TestingService",
  methods: {
    pushModemMessages: {
      name: "PushModemMessages",
      requestType: PushModemMessagesRequest,
      requestStream: false,
      responseType: PushModemMessagesRequest_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

/**
 * Simulation service to manage active simulations.
 * Simulation can help fill in the gaps until real data is present, by, for example, simulating messages from a device.
 */
export type SimulationServiceDefinition = typeof SimulationServiceDefinition;
export const SimulationServiceDefinition = {
  name: "SimulationService",
  fullName: "hiber.testing.SimulationService",
  methods: {
    get: {
      name: "Get",
      requestType: Simulation_GetRequest,
      requestStream: false,
      responseType: Simulation,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: Simulation_UpdateRequest,
      requestStream: false,
      responseType: Simulation,
      responseStream: false,
      options: {},
    },
    listModemMessageSimulations: {
      name: "ListModemMessageSimulations",
      requestType: Simulation_ModemMessageSimulation_List_Request,
      requestStream: false,
      responseType: Simulation_ModemMessageSimulation_List_Response,
      responseStream: false,
      options: {},
    },
    updateModemMessageSimulations: {
      name: "UpdateModemMessageSimulations",
      requestType: Simulation_ModemMessageSimulation_Update_Request,
      requestStream: false,
      responseType: Simulation_ModemMessageSimulation_Update_Response,
      responseStream: false,
      options: {},
    },
    disableModemMessageSimulations: {
      name: "DisableModemMessageSimulations",
      requestType: Simulation_ModemMessageSimulation_Delete_Request,
      requestStream: false,
      responseType: Simulation_ModemMessageSimulation_Delete_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
