/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { DoubleRange, Duration, Pagination, Pagination_Result, Timestamp, UpdateClearableString } from "./base";
import { Field_Numeric_Unit } from "./field";
import { Struct, Value } from "./google/protobuf/struct";
import { ModemSelection } from "./modem";

export const protobufPackage = "hiber.modem.alarm";

/**
 * Service to specify alarm for a modem, monitoring message data, location or activity.
 *
 * An alarm is a collection of checks that validate the correctness of a modem. For example, an alarm might
 * check that the modem is in a given location, or that a field in its messages is between certain values.
 *
 * Alarms are created in isolation, and need to be assigned to a modem before they do anything.
 * This can be done using the Assign and Unassign methods in the service, or using the AssignmentService for more
 * advanced use cases, like assigning to a tag.
 */

/**
 * Alarm for a modem, monitoring message data, location or activity.
 *
 * An alarm is a collection of checks that validate the correctness of a modem. For example, an alarm might
 * check that the modem is in a given location, or that a field in its messages is between certain values.
 *
 * Health for an alarm event is determined by taking the most severe health level from the health_levels configured
 * on the failing checks, using this default for any checks that do not have a health_level configured.
 * This can be changed on assignment with the default_health_level parameter, to fit the needs of the organization.
 *
 * Note, when an alarm is inherited the health levels may not match yours. If the health level matches one of
 * your health levels, that level is used. Otherwise, the catch-all health level is used.
 * See the health definition for more information on the catch-all health level (typically the most severe).
 * Note that the health level displayed here is the result of the steps above.
 *
 * When assigned to a (set of) modem(s), an alarm can be customized using its parameters.
 * Parameters are based on the check fields, and are also used in the check error message template.
 * For the alarm parameters, check parameters are prefixed with the check identifier.
 *
 * For example: An alarm with check A (field "healthy" equals 1) and check B (field "state" oneof ["OK", "ACTIVE"])
 * would have the following parameters:
 * {
 *   "A": {
 *     "expected": 1,
 *     "field.path": "healthy",
 *     "field.equals.expected": 1
 *   },
 *   "B": {
 *     "expected": ["OK", "ACTIVE"],
 *     "field.path": "state",
 *     "field.oneof.expected": ["OK", "ACTIVE"]
 *   }
 * }
 */
export interface ModemAlarm {
  /**
   * The identifier for this alarm.
   * This identifier is globally unique.
   */
  identifier: string;
  /** Short name for this alarm (optional). */
  name: string;
  /** Longer description for this alarm (optional). */
  description: string;
  /** When this alarm was created. */
  createdAt:
    | Timestamp
    | undefined;
  /** When this alarm was last updated. */
  updatedAt:
    | Timestamp
    | undefined;
  /** Condition determining when an alarm is triggered if it has multiple checks. */
  triggerCondition: ModemAlarm_TriggerCondition;
  /** The default health level for checks in this alarm, if they have no health_level configured. */
  defaultHealthLevel: string;
  /**
   * Allow the alarm to cause a health level for the modem even after it has been resolved.
   * By configuring this, you can specify the modem health should be affected for a longer period.
   * For example, when using an inactivity check, this could be used to configure modem health ERROR while
   * inactive, lowering to INVESTIGATE for a day after a new message comes in.
   */
  healthLevelAfterResolved:
    | ModemAlarm_HealthLevelAfterResolved
    | undefined;
  /** The checks in this alarm, that validate the state of the modem. */
  checks: ModemAlarm_Check[];
  /**
   * Parameters for this alarm.
   * This field displays all the parameters that can be set for the alarm on assignment, with their current value.
   */
  alarmParameters: { [key: string]: any } | undefined;
}

/** Condition determining when an alarm is triggered if it has multiple checks. */
export enum ModemAlarm_TriggerCondition {
  /** DEFAULT - Defaults to the current value when updating, or to ANY_CHECK_FAILED when creating. */
  DEFAULT = "DEFAULT",
  /**
   * ANY_CHECK_FAILED - Trigger the alarm when any of the checks fail.
   * This is useful when providing a single device health alarm, that checks, for example, battery,
   * operating temperature, etc. and should trigger when any of those are outside the expected range.
   */
  ANY_CHECK_FAILED = "ANY_CHECK_FAILED",
  /**
   * ALL_CHECKS_FAILED - Trigger the alarm only when all checks fail.
   * This is useful when creating a combined alarm, where several data points factor into the decisions to
   * trigger the alarm, for example, combining a check on the current value with a delta check on the
   * historical values, to trigger only if the value is high for a longer period.
   */
  ALL_CHECKS_FAILED = "ALL_CHECKS_FAILED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function modemAlarm_TriggerConditionFromJSON(object: any): ModemAlarm_TriggerCondition {
  switch (object) {
    case 0:
    case "DEFAULT":
      return ModemAlarm_TriggerCondition.DEFAULT;
    case 1:
    case "ANY_CHECK_FAILED":
      return ModemAlarm_TriggerCondition.ANY_CHECK_FAILED;
    case 2:
    case "ALL_CHECKS_FAILED":
      return ModemAlarm_TriggerCondition.ALL_CHECKS_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ModemAlarm_TriggerCondition.UNRECOGNIZED;
  }
}

export function modemAlarm_TriggerConditionToNumber(object: ModemAlarm_TriggerCondition): number {
  switch (object) {
    case ModemAlarm_TriggerCondition.DEFAULT:
      return 0;
    case ModemAlarm_TriggerCondition.ANY_CHECK_FAILED:
      return 1;
    case ModemAlarm_TriggerCondition.ALL_CHECKS_FAILED:
      return 2;
    case ModemAlarm_TriggerCondition.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * A check is a specification of how things should be, i.e. "a value should be within this range".
 * When it fails, it produces an event with:
 * - a custom health level, or the default defined in the alarm
 * - an error message, define from a template in the check
 *
 * The error message template is a string with parameters for the relevant data.
 * The parameters are included as {parameter}, which gets replaced with the value.
 *
 * The supported parameters are different per check, but the parameters below are always supported:
 * - modem:name: the modem name.
 * - modem:number: the modem number.
 * - message: the id of the message, if any.
 * - expected: a shortcut for the expected value(s), depending on the check:
 *   - inactivity check: expected duration
 *   - location check: expected area as [(bottom left), (top right)], and shape as [(point), (point), ..., (point)]
 *   - equals/minimum/maximum check: expected value
 *   - oneof check (allowed/blocked): expected values as [a, b, c]
 *   - threshold/delta check: expected range as minimum..maximum, extended with duration for delta check
 * - actual: the invalid actual value(s) for this check.
 *
 * The checks below define other available parameters.
 *
 * For example (using some parameters for specific checks):
 * - "Your device {modem} has left its designated area! It is now at {actual}."
 * - "Your device battery is at {actual}%, which is below the recommended minimum of {field.threshold.minimum}%."
 * - "Your device battery is draining faster than expected: {actual}% over the past {field.delta.period}."
 * - "Your device temperature has exceeded {value.threshold.maximum} degrees: {actual}."
 * - "Your device reported an unhealthy state {actual}. Healthy states are: {expected}."
 * - "Your device reported an unhealthy state {actual}. Healthy states are: {field.oneof.expected}."
 * - "Your device reported an unhealthy state {actual}. Please set it back to {expected}."
 * - "Your device reported an unhealthy state {actual}. Please set it back to {field.equals.expected}."
 * - "Unexpected value {actual} for field {field.path}!"
 *
 * Numeric values can be formatted with an extra postfix on the parameters
 * - Round numeric values by adding ":.2f" (for 2 decimals). For example: "{actual:.3f}" (rounds to 3 decimals)
 * - Always sign numeric values (when rounded) by prefixing the format with a plus. For example: `{actual:+.3f}`
 * - This is applied to numeric fields and fields that can be numeric, like `{actual}` and `{expected}`.
 */
export interface ModemAlarm_Check {
  /**
   * Name of this check, unique within the alarm.
   * This is used to update or remove the check, and to determine the destination for any parameters.
   * If omitted, a random name is generated based on the type of check.
   */
  name: string;
  /** Longer description for this check (optional). */
  description?:
    | string
    | undefined;
  /**
   * The health level that this check would cause for a modem, when it fails.
   * If not set, the alarm default is used.
   */
  healthLevel?:
    | string
    | undefined;
  /** The error message template for this check, with parameters that will be filled in based on the check. */
  errorMessageTemplate?: string | undefined;
  check?:
    | { $case: "location"; location: ModemAlarm_Check_LocationCheck }
    | { $case: "field"; field: ModemAlarm_Check_FieldCheck }
    | { $case: "inactivity"; inactivity: ModemAlarm_Check_InactivityCheck }
    | { $case: "delay"; delay: ModemAlarm_Check_DelayCheck }
    | undefined;
}

/**
 * Check that the device location is within a given area.
 *
 * Has the following parameters:
 * - location.expected: replace the referenced named location
 * - location.named: replace the referenced named location
 */
export interface ModemAlarm_Check_LocationCheck {
  /** Specify the name of a named location (NamedLocation) that the device must be in. */
  named: string;
}

/**
 * Check whether the device exceeds inactivity limits.
 *
 * Has the following parameters:
 * - inactivity.maximum: the maximum allowed inactivity
 */
export interface ModemAlarm_Check_InactivityCheck {
  /** The maximum value for the modem's inactivity (time since last message was received on the server). */
  maximum: Duration | undefined;
}

/**
 * Check whether the message was delayed more than a configured limit.
 * The delay is the time between the message was sent (ModemMessage.sent_at) and the time it was
 * received on the server (ModemMessage.received_at).
 *
 * Has the following parameters:
 * - delay.maximum: the maximum allowed delay
 */
export interface ModemAlarm_Check_DelayCheck {
  /** The maximum value for the message delay (duration between sent and received time). */
  maximum: Duration | undefined;
}

/**
 * A check that evaluates each new message, and checks selected field(s) in the parsed body.
 *
 * When multiple fields are selected, the checks are applied to all of them individually.
 *
 * See the Json Path documentation at https://goessner.net/articles/JsonPath/ for details on json path.
 * We currently do not allow filter expressions.
 *
 * Simple examples selecting a field:
 * - $.my_field: a field in the root of the parsed object
 * - $.my_obj.my_field: a field in a deeper structure
 * - $.my_array[x].my_field: the field my_field of the element at index x is selected
 *
 * Complex use cases are also possible, but they require a bit more understanding of json path logic:
 * - $.my_array.length(): the length of my_array is selected. Combine with an equals or threshold check,
 * to require that an array has a certain length.
 * - $.my_array..my_field: the array of my_field values (for all objects in my_array) is selected
 * - $.my_array[*].my_field: the array of my_field values (for all objects in my_array) is selected
 *
 * Note that for the examples above, if they return an array, the entire array is used as the value
 * in the comparison for equals and oneof.
 *
 * A check of this type has the following parameters (matches the fields):
 * - field.path: replace the path for this field
 * - field.ignoreFieldNotFound: replace the value for ignore_field_not_found
 *
 * For the equals check:
 * - field.equals.expected: iff this is an equals check, replace the expected value
 *
 * For the allowed check:
 * - field.allowed.allowed: iff this is an allowed check, replace the expected values
 *
 * For the blocked check:
 * - field.blocked.blocked: iff this is a blocked check, replace the expected values
 *
 * For the threshold check:
 * - field.threshold.expected: iff this is a threshold check, replace the expected range
 * - field.threshold.minimum: iff this is a threshold check, replace the expected minimum
 * - field.threshold.maximum: iff this is a threshold check, replace the expected maximum
 *
 * For the delta check:
 * - field.delta.period: iff this is a delta check, replace the expected duration
 * - field.delta.threshold.expected: iff this is a delta check, replace the expected range
 * - field.delta.threshold.minimum: iff this is a delta check, replace the expected minimum
 * - field.delta.threshold.maximum: iff this is a delta check, replace the expected maximum
 *
 * All of the parameters above can be used in the error message template.
 *
 * The delta check also adds a few additional error message variables:
 * - {field.delta.previous}: the previous value for the field
 * - {field.delta.difference} (also {actual}): the difference between previous and current value for the field
 * - {field.delta.current}: the current value for the field
 */
export interface ModemAlarm_Check_FieldCheck {
  /** Select the field(s) that this check is applied to, using a json path. */
  path: string;
  /**
   * Whether to ignore this check if the field is not found.
   * This can be useful if your path selects multiple values in an array, like my_array[*].value,
   * and not all entries have the field, or when fields are omitted if they have a default value.
   */
  ignoreFieldNotFound: boolean;
  /**
   * The unit that this alarm check is using.
   * The field's values will automatically be converted into this unit before the check is applied.
   * Note: unit is not currently available in the alarm_parameters.
   */
  unit?: Field_Numeric_Unit | undefined;
  check?:
    | { $case: "equals"; equals: ModemAlarm_Check_FieldCheck_EqualsCheck }
    | { $case: "allowed"; allowed: ModemAlarm_Check_FieldCheck_AllowedCheck }
    | { $case: "blocked"; blocked: ModemAlarm_Check_FieldCheck_BlockedCheck }
    | { $case: "minimum"; minimum: ModemAlarm_Check_FieldCheck_MinimumCheck }
    | { $case: "maximum"; maximum: ModemAlarm_Check_FieldCheck_MaximumCheck }
    | { $case: "threshold"; threshold: ModemAlarm_Check_FieldCheck_ThresholdCheck }
    | { $case: "blockedRange"; blockedRange: ModemAlarm_Check_FieldCheck_BlockedRangeCheck }
    | { $case: "delta"; delta: ModemAlarm_Check_FieldCheck_DeltaCheck }
    | undefined;
}

/** Check that the field is equal to the given value. */
export interface ModemAlarm_Check_FieldCheck_EqualsCheck {
  /** The expected value a field should have. */
  expected: any | undefined;
}

/** Check that the field is in a set of expected values. */
export interface ModemAlarm_Check_FieldCheck_AllowedCheck {
  /** The list of allowed values, one of which should match the field value. */
  allowed: any[];
}

/** Check that the field is not in a set of blocked values. */
export interface ModemAlarm_Check_FieldCheck_BlockedCheck {
  /** The list of blocked values; the field should not match any of them. */
  blocked: any[];
}

/** Check that the field is higher than the given value. */
export interface ModemAlarm_Check_FieldCheck_MinimumCheck {
  /** The minimum numeric value the field should have. */
  minimum: number;
}

/** Check that the field is lower than the given value. */
export interface ModemAlarm_Check_FieldCheck_MaximumCheck {
  /** The maximum numeric value the field should have. */
  maximum: number;
}

/**
 * Check that the field is above a minimum threshold and under a maximum threshold.
 * If the minimum is higher than the maximum (i.e. 30..10), this is automatically converted into a
 * BlockedRangeCheck.
 */
export interface ModemAlarm_Check_FieldCheck_ThresholdCheck {
  /** The range the value must be in. */
  expected:
    | DoubleRange
    | undefined;
  /** The minimum expected value, available separately for convenience and check parameters. */
  minimum: number;
  /** The maximum expected value, available separately for convenience and check parameters. */
  maximum: number;
}

/**
 * Check that the field is outside of a range.
 * If the minimum is higher than the maximum (i.e. 30..10), this is automatically converted into a
 * ThresholdCheck.
 */
export interface ModemAlarm_Check_FieldCheck_BlockedRangeCheck {
  /** The range the value must not be in. */
  blocked: DoubleRange | undefined;
}

/** A check that evaluates the differences in values over time, for the selected field(s) in the parsed body. */
export interface ModemAlarm_Check_FieldCheck_DeltaCheck {
  /** The period to evaluate when determining the value difference. */
  period:
    | Duration
    | undefined;
  /** Check that the delta is within a given numeric range. */
  threshold:
    | ModemAlarm_Check_FieldCheck_ThresholdCheck
    | undefined;
  /**
   * Whether the field data should be encrypted in cache storage.
   * Delta checks use a cache for field from messages that are affected by a delta check.
   * Encrypting the individual field values is (relatively) computationally expensive and may lead to
   * a slightly delayed alarm event. In the future, some delta features may only
   * be available to unencrypted values due to performance issues.
   */
  encrypted: boolean;
  /** Whether to ignore this check if the previous value is not found (i.e. there is no history). */
  ignorePreviousValueNotFound: boolean;
}

/**
 * Allow the alarm to cause a health level for the modem even after a new message has come in.
 *
 * Typically, an alarm event only affects the modem health while it is from the last message from that modem.
 * By configuring this, you can specify the modem health should be affected for a longer period.
 *
 * For example, when using an inactivity check, this could be used to configure modem health ERROR while
 * inactive, lowering to INVESTIGATE for a day after a new message comes in.
 */
export interface ModemAlarm_HealthLevelAfterResolved {
  /** The health level that this check would cause for a modem, when the original failure is resolved. */
  healthLevel: string;
  /** The amount of time that this health level would be active. */
  period: Duration | undefined;
}

/**
 * Selection criteria for listing modem alarms in an organization. If no options are provided, all modem alarms
 * are valid.
 *
 * If values are provided both for identifiers and search, then only alarms are selected that match both criteria.
 */
export interface ModemAlarmSelection {
  /** Selects alarms by the given list of alarm identifiers. */
  identifiers: string[];
  /** Search for the given string in identifier, description, fields and values. */
  search?: string | undefined;
}

/** List modem alarms in an organization. */
export interface ListModemAlarms {
}

export interface ListModemAlarms_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Selection criteria for listing modem alarms. Optional, when omitted or empty everything is included. */
  selection?:
    | ModemAlarmSelection
    | undefined;
  /** Pagination for the returned alarms. */
  pagination?:
    | Pagination
    | undefined;
  /**
   * Apply your UnitPreferences to the alarm checks.
   * For example, if a temperature check is configured in kelvin, but your unit preferences specify celsius for
   * temperature, the check value will be converted to celsius instead.
   */
  applyUnitPreferences?: boolean | undefined;
}

export interface ListModemAlarms_Response {
  /** A list of modem alarms. */
  modemAlarms: ModemAlarm[];
  /** A pagination object, which can be used to go through the alarms. */
  pagination:
    | Pagination_Result
    | undefined;
  /** The request made to list the given alarms. */
  request: ListModemAlarms_Request | undefined;
}

/**
 * Create a new alarm.
 *
 * The request contains the option to add checks as well.
 * This is a shortcut for creating an alarm and then adding checks, and as such can result in multiple events:
 * - a created event on the alarm
 * - an update event on the alarm checks, iff any checks were added
 */
export interface CreateModemAlarm {
}

export interface CreateModemAlarm_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** A name for the alarm. */
  name: string;
  /** A short description of what the alarm should do. */
  description?:
    | string
    | undefined;
  /** Condition determining when an alarm is triggered if it has multiple checks. */
  triggerCondition: ModemAlarm_TriggerCondition;
  /** The checks to add to this alarm. Shortcut for creating an alarm and then adding checks to it. */
  checks: ModemAlarm_Check[];
  /** The default health level for this alarm. See ModemAlarm.default_health_level for more information. */
  defaultHealthLevel?:
    | string
    | undefined;
  /**
   * The health level this alarm should cause after it is resolved.
   * See ModemAlarm.health_level_after_resolved for more information.
   */
  healthLevelAfterResolved?: ModemAlarm_HealthLevelAfterResolved | undefined;
}

export interface CreateModemAlarm_Response {
  /** The created modem alarm. */
  created: ModemAlarm | undefined;
}

/**
 * Update the alarm, iff you are the owner or can impersonate the owner organization.
 *
 * The request contains the option to add, remove or update checks as well.
 * This is a shortcut for updating an alarm and then adding, removing and/or updating checks,
 * and as such can result in multiple events:
 * - an update event on the alarm, iff there were any non-check changes to the alarm.
 * - an update event on the alarm checks, iff there were any check addition, updates or deletions
 */
export interface UpdateModemAlarm {
}

export interface UpdateModemAlarm_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifiers of the alarm to update */
  identifier: string;
  /**
   * Update the name, optionally.
   *
   * @deprecated
   */
  updateName?: UpdateClearableString | undefined;
  name?:
    | string
    | undefined;
  /**
   * Update the description, optionally.
   *
   * @deprecated
   */
  updateDescription?: UpdateClearableString | undefined;
  description?:
    | string
    | undefined;
  /** Update the trigger condition, optionally. */
  updateTriggerCondition?:
    | ModemAlarm_TriggerCondition
    | undefined;
  /**
   * Update the default health level, optionally.
   *
   * @deprecated
   */
  updateDefaultHealthLevel?: UpdateClearableString | undefined;
  defaultHealthLevel?:
    | string
    | undefined;
  /** Update the health after resolved, optionally. */
  updateHealthLevelAfterResolved?:
    | ModemAlarm_HealthLevelAfterResolved
    | undefined;
  /** Remove the health after resolved, optionally. */
  removeHealthLevelAfterResolved?:
    | boolean
    | undefined;
  /** The checks to add to this alarm. Shortcut for updating an alarm and then adding checks to it. */
  addChecks: ModemAlarm_Check[];
  /** The checks to update in this alarm. Shortcut for updating an alarm and then updating checks. */
  updateChecks: { [key: string]: ModemAlarm_Check };
  /** The checks to remove from this alarm. Shortcut for updating an alarm and then removing checks. */
  deleteChecks: string[];
}

export interface UpdateModemAlarm_Request_UpdateChecksEntry {
  key: string;
  value: ModemAlarm_Check | undefined;
}

export interface UpdateModemAlarm_Response {
  updated: ModemAlarm | undefined;
}

/** Add a check to the alarm, iff you are the owner or can impersonate the owner organization. */
export interface UpdateModemAlarmAddCheck {
}

export interface UpdateModemAlarmAddCheck_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the alarm to which the check is added. */
  alarmIdentifier: string;
  /** The check to add to the Modem Alarm. Identifier of the check must be unique within the alarm. */
  check: ModemAlarm_Check | undefined;
}

export interface UpdateModemAlarmAddCheck_Response {
  updated: ModemAlarm | undefined;
}

/**
 * Update a check of an alarm.
 *
 * Only the values of a check can be updated, e.g., it is not possible to change an inactivity check to a location
 * check.
 */
export interface UpdateModemAlarmUpdateCheck {
}

export interface UpdateModemAlarmUpdateCheck_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the alarm of which to update the check. */
  alarmIdentifier: string;
  /** The identifier of the check to update. */
  checkIdentifier: string;
  /** The new values for the check of this alarm. */
  updateCheck:
    | ModemAlarm_Check
    | undefined;
  /**
   * If set, the update is not actually saved, but only applied and returned.
   * This is a convenience to easily test parameters for a check similar to TestModemAlarmTestParameters.
   */
  testParametersOnly: boolean;
}

export interface UpdateModemAlarmUpdateCheck_Response {
  updated: ModemAlarm | undefined;
}

/** Remove a check from an alarm. */
export interface UpdateModemAlarmRemoveCheck {
}

export interface UpdateModemAlarmRemoveCheck_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the alarm from which to remove the check. */
  alarmIdentifier: string;
  /** The identifier of the check to remove. */
  checkIdentifier: string;
}

export interface UpdateModemAlarmRemoveCheck_Response {
  updated: ModemAlarm | undefined;
}

/** Delete an alarm. */
export interface DeleteModemAlarm {
}

export interface DeleteModemAlarm_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Identifier of the modem alarm to delete. */
  identifier: string;
}

export interface DeleteModemAlarm_Response {
}

/** Test a set of parameters on a modem alarm, to see the result when they are applied during assignment. */
export interface TestModemAlarmTestParameters {
}

export interface TestModemAlarmTestParameters_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier of the alarm on which to test parameters. */
  alarmIdentifier: string;
  /** The parameters of the alarm that are changed. */
  parameters: { [key: string]: any } | undefined;
}

export interface TestModemAlarmTestParameters_Response {
  result: ModemAlarm | undefined;
}

/** Directly assigned modem alarm to a modem. */
export interface AssignedModemAlarm {
  alarmIdentifier: string;
  modemNumber: string;
}

/**
 * Add direct assignments.
 * If an overlapping assignment using a rule exists, it is shadowed by the direct assignment.
 *
 * Simplified version of assign.AssignDirectly.
 */
export interface AssignModemAlarms {
}

export interface AssignModemAlarms_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  alarms: ModemAlarmSelection | undefined;
  modems:
    | ModemSelection
    | undefined;
  /** The alarm parameters, by alarm identifier, if any, overriding any default values in the alarm(s). */
  parameters: { [key: string]: { [key: string]: any } | undefined };
}

export interface AssignModemAlarms_Request_ParametersEntry {
  key: string;
  value: { [key: string]: any } | undefined;
}

export interface AssignModemAlarms_Response {
  created: AssignedModemAlarm[];
  request: AssignModemAlarms_Request | undefined;
}

/**
 * Remove a direct assignment.
 * If an overlapping assignment using a rule exists, it is not affected, except that it is longer shadowed.
 *
 * Simplified version of assign.UnassignDirectly.
 */
export interface UnassignModemAlarms {
}

export interface UnassignModemAlarms_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  alarms: ModemAlarmSelection | undefined;
  modems: ModemSelection | undefined;
}

export interface UnassignModemAlarms_Response {
  removedDirectAssignments: AssignedModemAlarm[];
  request: UnassignModemAlarms_Request | undefined;
}

function createBaseModemAlarm(): ModemAlarm {
  return {
    identifier: "",
    name: "",
    description: "",
    createdAt: undefined,
    updatedAt: undefined,
    triggerCondition: ModemAlarm_TriggerCondition.DEFAULT,
    defaultHealthLevel: "",
    healthLevelAfterResolved: undefined,
    checks: [],
    alarmParameters: undefined,
  };
}

export const ModemAlarm = {
  encode(message: ModemAlarm, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.name !== "") {
      writer.uint32(74).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(82).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(90).fork()).ldelim();
    }
    if (message.triggerCondition !== ModemAlarm_TriggerCondition.DEFAULT) {
      writer.uint32(32).int32(modemAlarm_TriggerConditionToNumber(message.triggerCondition));
    }
    if (message.defaultHealthLevel !== "") {
      writer.uint32(58).string(message.defaultHealthLevel);
    }
    if (message.healthLevelAfterResolved !== undefined) {
      ModemAlarm_HealthLevelAfterResolved.encode(message.healthLevelAfterResolved, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.checks) {
      ModemAlarm_Check.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.alarmParameters !== undefined) {
      Struct.encode(Struct.wrap(message.alarmParameters), writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.triggerCondition = modemAlarm_TriggerConditionFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.defaultHealthLevel = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.healthLevelAfterResolved = ModemAlarm_HealthLevelAfterResolved.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.checks.push(ModemAlarm_Check.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.alarmParameters = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm>): ModemAlarm {
    return ModemAlarm.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm>): ModemAlarm {
    const message = createBaseModemAlarm();
    message.identifier = object.identifier ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.createdAt = (object.createdAt !== undefined && object.createdAt !== null)
      ? Timestamp.fromPartial(object.createdAt)
      : undefined;
    message.updatedAt = (object.updatedAt !== undefined && object.updatedAt !== null)
      ? Timestamp.fromPartial(object.updatedAt)
      : undefined;
    message.triggerCondition = object.triggerCondition ?? ModemAlarm_TriggerCondition.DEFAULT;
    message.defaultHealthLevel = object.defaultHealthLevel ?? "";
    message.healthLevelAfterResolved =
      (object.healthLevelAfterResolved !== undefined && object.healthLevelAfterResolved !== null)
        ? ModemAlarm_HealthLevelAfterResolved.fromPartial(object.healthLevelAfterResolved)
        : undefined;
    message.checks = object.checks?.map((e) => ModemAlarm_Check.fromPartial(e)) || [];
    message.alarmParameters = object.alarmParameters ?? undefined;
    return message;
  },
};

function createBaseModemAlarm_Check(): ModemAlarm_Check {
  return {
    name: "",
    description: undefined,
    healthLevel: undefined,
    errorMessageTemplate: undefined,
    check: undefined,
  };
}

export const ModemAlarm_Check = {
  encode(message: ModemAlarm_Check, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(66).string(message.description);
    }
    if (message.healthLevel !== undefined) {
      writer.uint32(18).string(message.healthLevel);
    }
    if (message.errorMessageTemplate !== undefined) {
      writer.uint32(26).string(message.errorMessageTemplate);
    }
    switch (message.check?.$case) {
      case "location":
        ModemAlarm_Check_LocationCheck.encode(message.check.location, writer.uint32(34).fork()).ldelim();
        break;
      case "field":
        ModemAlarm_Check_FieldCheck.encode(message.check.field, writer.uint32(42).fork()).ldelim();
        break;
      case "inactivity":
        ModemAlarm_Check_InactivityCheck.encode(message.check.inactivity, writer.uint32(50).fork()).ldelim();
        break;
      case "delay":
        ModemAlarm_Check_DelayCheck.encode(message.check.delay, writer.uint32(58).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.healthLevel = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorMessageTemplate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.check = {
            $case: "location",
            location: ModemAlarm_Check_LocationCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.check = { $case: "field", field: ModemAlarm_Check_FieldCheck.decode(reader, reader.uint32()) };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.check = {
            $case: "inactivity",
            inactivity: ModemAlarm_Check_InactivityCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.check = { $case: "delay", delay: ModemAlarm_Check_DelayCheck.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check>): ModemAlarm_Check {
    return ModemAlarm_Check.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check>): ModemAlarm_Check {
    const message = createBaseModemAlarm_Check();
    message.name = object.name ?? "";
    message.description = object.description ?? undefined;
    message.healthLevel = object.healthLevel ?? undefined;
    message.errorMessageTemplate = object.errorMessageTemplate ?? undefined;
    if (object.check?.$case === "location" && object.check?.location !== undefined && object.check?.location !== null) {
      message.check = {
        $case: "location",
        location: ModemAlarm_Check_LocationCheck.fromPartial(object.check.location),
      };
    }
    if (object.check?.$case === "field" && object.check?.field !== undefined && object.check?.field !== null) {
      message.check = { $case: "field", field: ModemAlarm_Check_FieldCheck.fromPartial(object.check.field) };
    }
    if (
      object.check?.$case === "inactivity" &&
      object.check?.inactivity !== undefined &&
      object.check?.inactivity !== null
    ) {
      message.check = {
        $case: "inactivity",
        inactivity: ModemAlarm_Check_InactivityCheck.fromPartial(object.check.inactivity),
      };
    }
    if (object.check?.$case === "delay" && object.check?.delay !== undefined && object.check?.delay !== null) {
      message.check = { $case: "delay", delay: ModemAlarm_Check_DelayCheck.fromPartial(object.check.delay) };
    }
    return message;
  },
};

function createBaseModemAlarm_Check_LocationCheck(): ModemAlarm_Check_LocationCheck {
  return { named: "" };
}

export const ModemAlarm_Check_LocationCheck = {
  encode(message: ModemAlarm_Check_LocationCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.named !== "") {
      writer.uint32(26).string(message.named);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_LocationCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_LocationCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.named = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_LocationCheck>): ModemAlarm_Check_LocationCheck {
    return ModemAlarm_Check_LocationCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_LocationCheck>): ModemAlarm_Check_LocationCheck {
    const message = createBaseModemAlarm_Check_LocationCheck();
    message.named = object.named ?? "";
    return message;
  },
};

function createBaseModemAlarm_Check_InactivityCheck(): ModemAlarm_Check_InactivityCheck {
  return { maximum: undefined };
}

export const ModemAlarm_Check_InactivityCheck = {
  encode(message: ModemAlarm_Check_InactivityCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.maximum !== undefined) {
      Duration.encode(message.maximum, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_InactivityCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_InactivityCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.maximum = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_InactivityCheck>): ModemAlarm_Check_InactivityCheck {
    return ModemAlarm_Check_InactivityCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_InactivityCheck>): ModemAlarm_Check_InactivityCheck {
    const message = createBaseModemAlarm_Check_InactivityCheck();
    message.maximum = (object.maximum !== undefined && object.maximum !== null)
      ? Duration.fromPartial(object.maximum)
      : undefined;
    return message;
  },
};

function createBaseModemAlarm_Check_DelayCheck(): ModemAlarm_Check_DelayCheck {
  return { maximum: undefined };
}

export const ModemAlarm_Check_DelayCheck = {
  encode(message: ModemAlarm_Check_DelayCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.maximum !== undefined) {
      Duration.encode(message.maximum, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_DelayCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_DelayCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.maximum = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_DelayCheck>): ModemAlarm_Check_DelayCheck {
    return ModemAlarm_Check_DelayCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_DelayCheck>): ModemAlarm_Check_DelayCheck {
    const message = createBaseModemAlarm_Check_DelayCheck();
    message.maximum = (object.maximum !== undefined && object.maximum !== null)
      ? Duration.fromPartial(object.maximum)
      : undefined;
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck(): ModemAlarm_Check_FieldCheck {
  return { path: "", ignoreFieldNotFound: false, unit: undefined, check: undefined };
}

export const ModemAlarm_Check_FieldCheck = {
  encode(message: ModemAlarm_Check_FieldCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(10).string(message.path);
    }
    if (message.ignoreFieldNotFound === true) {
      writer.uint32(16).bool(message.ignoreFieldNotFound);
    }
    if (message.unit !== undefined) {
      Field_Numeric_Unit.encode(message.unit, writer.uint32(74).fork()).ldelim();
    }
    switch (message.check?.$case) {
      case "equals":
        ModemAlarm_Check_FieldCheck_EqualsCheck.encode(message.check.equals, writer.uint32(26).fork()).ldelim();
        break;
      case "allowed":
        ModemAlarm_Check_FieldCheck_AllowedCheck.encode(message.check.allowed, writer.uint32(34).fork()).ldelim();
        break;
      case "blocked":
        ModemAlarm_Check_FieldCheck_BlockedCheck.encode(message.check.blocked, writer.uint32(82).fork()).ldelim();
        break;
      case "minimum":
        ModemAlarm_Check_FieldCheck_MinimumCheck.encode(message.check.minimum, writer.uint32(58).fork()).ldelim();
        break;
      case "maximum":
        ModemAlarm_Check_FieldCheck_MaximumCheck.encode(message.check.maximum, writer.uint32(66).fork()).ldelim();
        break;
      case "threshold":
        ModemAlarm_Check_FieldCheck_ThresholdCheck.encode(message.check.threshold, writer.uint32(42).fork()).ldelim();
        break;
      case "blockedRange":
        ModemAlarm_Check_FieldCheck_BlockedRangeCheck.encode(message.check.blockedRange, writer.uint32(90).fork())
          .ldelim();
        break;
      case "delta":
        ModemAlarm_Check_FieldCheck_DeltaCheck.encode(message.check.delta, writer.uint32(50).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ignoreFieldNotFound = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.unit = Field_Numeric_Unit.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.check = {
            $case: "equals",
            equals: ModemAlarm_Check_FieldCheck_EqualsCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.check = {
            $case: "allowed",
            allowed: ModemAlarm_Check_FieldCheck_AllowedCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.check = {
            $case: "blocked",
            blocked: ModemAlarm_Check_FieldCheck_BlockedCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.check = {
            $case: "minimum",
            minimum: ModemAlarm_Check_FieldCheck_MinimumCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.check = {
            $case: "maximum",
            maximum: ModemAlarm_Check_FieldCheck_MaximumCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.check = {
            $case: "threshold",
            threshold: ModemAlarm_Check_FieldCheck_ThresholdCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.check = {
            $case: "blockedRange",
            blockedRange: ModemAlarm_Check_FieldCheck_BlockedRangeCheck.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.check = {
            $case: "delta",
            delta: ModemAlarm_Check_FieldCheck_DeltaCheck.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_FieldCheck>): ModemAlarm_Check_FieldCheck {
    return ModemAlarm_Check_FieldCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_FieldCheck>): ModemAlarm_Check_FieldCheck {
    const message = createBaseModemAlarm_Check_FieldCheck();
    message.path = object.path ?? "";
    message.ignoreFieldNotFound = object.ignoreFieldNotFound ?? false;
    message.unit = (object.unit !== undefined && object.unit !== null)
      ? Field_Numeric_Unit.fromPartial(object.unit)
      : undefined;
    if (object.check?.$case === "equals" && object.check?.equals !== undefined && object.check?.equals !== null) {
      message.check = {
        $case: "equals",
        equals: ModemAlarm_Check_FieldCheck_EqualsCheck.fromPartial(object.check.equals),
      };
    }
    if (object.check?.$case === "allowed" && object.check?.allowed !== undefined && object.check?.allowed !== null) {
      message.check = {
        $case: "allowed",
        allowed: ModemAlarm_Check_FieldCheck_AllowedCheck.fromPartial(object.check.allowed),
      };
    }
    if (object.check?.$case === "blocked" && object.check?.blocked !== undefined && object.check?.blocked !== null) {
      message.check = {
        $case: "blocked",
        blocked: ModemAlarm_Check_FieldCheck_BlockedCheck.fromPartial(object.check.blocked),
      };
    }
    if (object.check?.$case === "minimum" && object.check?.minimum !== undefined && object.check?.minimum !== null) {
      message.check = {
        $case: "minimum",
        minimum: ModemAlarm_Check_FieldCheck_MinimumCheck.fromPartial(object.check.minimum),
      };
    }
    if (object.check?.$case === "maximum" && object.check?.maximum !== undefined && object.check?.maximum !== null) {
      message.check = {
        $case: "maximum",
        maximum: ModemAlarm_Check_FieldCheck_MaximumCheck.fromPartial(object.check.maximum),
      };
    }
    if (
      object.check?.$case === "threshold" && object.check?.threshold !== undefined && object.check?.threshold !== null
    ) {
      message.check = {
        $case: "threshold",
        threshold: ModemAlarm_Check_FieldCheck_ThresholdCheck.fromPartial(object.check.threshold),
      };
    }
    if (
      object.check?.$case === "blockedRange" &&
      object.check?.blockedRange !== undefined &&
      object.check?.blockedRange !== null
    ) {
      message.check = {
        $case: "blockedRange",
        blockedRange: ModemAlarm_Check_FieldCheck_BlockedRangeCheck.fromPartial(object.check.blockedRange),
      };
    }
    if (object.check?.$case === "delta" && object.check?.delta !== undefined && object.check?.delta !== null) {
      message.check = { $case: "delta", delta: ModemAlarm_Check_FieldCheck_DeltaCheck.fromPartial(object.check.delta) };
    }
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck_EqualsCheck(): ModemAlarm_Check_FieldCheck_EqualsCheck {
  return { expected: undefined };
}

export const ModemAlarm_Check_FieldCheck_EqualsCheck = {
  encode(message: ModemAlarm_Check_FieldCheck_EqualsCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expected !== undefined) {
      Value.encode(Value.wrap(message.expected), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck_EqualsCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck_EqualsCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expected = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_FieldCheck_EqualsCheck>): ModemAlarm_Check_FieldCheck_EqualsCheck {
    return ModemAlarm_Check_FieldCheck_EqualsCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_FieldCheck_EqualsCheck>): ModemAlarm_Check_FieldCheck_EqualsCheck {
    const message = createBaseModemAlarm_Check_FieldCheck_EqualsCheck();
    message.expected = object.expected ?? undefined;
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck_AllowedCheck(): ModemAlarm_Check_FieldCheck_AllowedCheck {
  return { allowed: [] };
}

export const ModemAlarm_Check_FieldCheck_AllowedCheck = {
  encode(message: ModemAlarm_Check_FieldCheck_AllowedCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.allowed) {
      Value.encode(Value.wrap(v!), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck_AllowedCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck_AllowedCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.allowed.push(Value.unwrap(Value.decode(reader, reader.uint32())));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_FieldCheck_AllowedCheck>): ModemAlarm_Check_FieldCheck_AllowedCheck {
    return ModemAlarm_Check_FieldCheck_AllowedCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_FieldCheck_AllowedCheck>): ModemAlarm_Check_FieldCheck_AllowedCheck {
    const message = createBaseModemAlarm_Check_FieldCheck_AllowedCheck();
    message.allowed = object.allowed?.map((e) => e) || [];
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck_BlockedCheck(): ModemAlarm_Check_FieldCheck_BlockedCheck {
  return { blocked: [] };
}

export const ModemAlarm_Check_FieldCheck_BlockedCheck = {
  encode(message: ModemAlarm_Check_FieldCheck_BlockedCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.blocked) {
      Value.encode(Value.wrap(v!), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck_BlockedCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck_BlockedCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.blocked.push(Value.unwrap(Value.decode(reader, reader.uint32())));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_FieldCheck_BlockedCheck>): ModemAlarm_Check_FieldCheck_BlockedCheck {
    return ModemAlarm_Check_FieldCheck_BlockedCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_FieldCheck_BlockedCheck>): ModemAlarm_Check_FieldCheck_BlockedCheck {
    const message = createBaseModemAlarm_Check_FieldCheck_BlockedCheck();
    message.blocked = object.blocked?.map((e) => e) || [];
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck_MinimumCheck(): ModemAlarm_Check_FieldCheck_MinimumCheck {
  return { minimum: 0 };
}

export const ModemAlarm_Check_FieldCheck_MinimumCheck = {
  encode(message: ModemAlarm_Check_FieldCheck_MinimumCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.minimum !== 0) {
      writer.uint32(9).double(message.minimum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck_MinimumCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck_MinimumCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.minimum = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_FieldCheck_MinimumCheck>): ModemAlarm_Check_FieldCheck_MinimumCheck {
    return ModemAlarm_Check_FieldCheck_MinimumCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_FieldCheck_MinimumCheck>): ModemAlarm_Check_FieldCheck_MinimumCheck {
    const message = createBaseModemAlarm_Check_FieldCheck_MinimumCheck();
    message.minimum = object.minimum ?? 0;
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck_MaximumCheck(): ModemAlarm_Check_FieldCheck_MaximumCheck {
  return { maximum: 0 };
}

export const ModemAlarm_Check_FieldCheck_MaximumCheck = {
  encode(message: ModemAlarm_Check_FieldCheck_MaximumCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.maximum !== 0) {
      writer.uint32(25).double(message.maximum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck_MaximumCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck_MaximumCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 25) {
            break;
          }

          message.maximum = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_FieldCheck_MaximumCheck>): ModemAlarm_Check_FieldCheck_MaximumCheck {
    return ModemAlarm_Check_FieldCheck_MaximumCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_FieldCheck_MaximumCheck>): ModemAlarm_Check_FieldCheck_MaximumCheck {
    const message = createBaseModemAlarm_Check_FieldCheck_MaximumCheck();
    message.maximum = object.maximum ?? 0;
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck_ThresholdCheck(): ModemAlarm_Check_FieldCheck_ThresholdCheck {
  return { expected: undefined, minimum: 0, maximum: 0 };
}

export const ModemAlarm_Check_FieldCheck_ThresholdCheck = {
  encode(message: ModemAlarm_Check_FieldCheck_ThresholdCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expected !== undefined) {
      DoubleRange.encode(message.expected, writer.uint32(10).fork()).ldelim();
    }
    if (message.minimum !== 0) {
      writer.uint32(17).double(message.minimum);
    }
    if (message.maximum !== 0) {
      writer.uint32(25).double(message.maximum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck_ThresholdCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck_ThresholdCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expected = DoubleRange.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.minimum = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.maximum = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_FieldCheck_ThresholdCheck>): ModemAlarm_Check_FieldCheck_ThresholdCheck {
    return ModemAlarm_Check_FieldCheck_ThresholdCheck.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ModemAlarm_Check_FieldCheck_ThresholdCheck>,
  ): ModemAlarm_Check_FieldCheck_ThresholdCheck {
    const message = createBaseModemAlarm_Check_FieldCheck_ThresholdCheck();
    message.expected = (object.expected !== undefined && object.expected !== null)
      ? DoubleRange.fromPartial(object.expected)
      : undefined;
    message.minimum = object.minimum ?? 0;
    message.maximum = object.maximum ?? 0;
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck_BlockedRangeCheck(): ModemAlarm_Check_FieldCheck_BlockedRangeCheck {
  return { blocked: undefined };
}

export const ModemAlarm_Check_FieldCheck_BlockedRangeCheck = {
  encode(message: ModemAlarm_Check_FieldCheck_BlockedRangeCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.blocked !== undefined) {
      DoubleRange.encode(message.blocked, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck_BlockedRangeCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck_BlockedRangeCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.blocked = DoubleRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ModemAlarm_Check_FieldCheck_BlockedRangeCheck>,
  ): ModemAlarm_Check_FieldCheck_BlockedRangeCheck {
    return ModemAlarm_Check_FieldCheck_BlockedRangeCheck.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ModemAlarm_Check_FieldCheck_BlockedRangeCheck>,
  ): ModemAlarm_Check_FieldCheck_BlockedRangeCheck {
    const message = createBaseModemAlarm_Check_FieldCheck_BlockedRangeCheck();
    message.blocked = (object.blocked !== undefined && object.blocked !== null)
      ? DoubleRange.fromPartial(object.blocked)
      : undefined;
    return message;
  },
};

function createBaseModemAlarm_Check_FieldCheck_DeltaCheck(): ModemAlarm_Check_FieldCheck_DeltaCheck {
  return { period: undefined, threshold: undefined, encrypted: false, ignorePreviousValueNotFound: false };
}

export const ModemAlarm_Check_FieldCheck_DeltaCheck = {
  encode(message: ModemAlarm_Check_FieldCheck_DeltaCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.period !== undefined) {
      Duration.encode(message.period, writer.uint32(10).fork()).ldelim();
    }
    if (message.threshold !== undefined) {
      ModemAlarm_Check_FieldCheck_ThresholdCheck.encode(message.threshold, writer.uint32(18).fork()).ldelim();
    }
    if (message.encrypted === true) {
      writer.uint32(24).bool(message.encrypted);
    }
    if (message.ignorePreviousValueNotFound === true) {
      writer.uint32(32).bool(message.ignorePreviousValueNotFound);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_Check_FieldCheck_DeltaCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_Check_FieldCheck_DeltaCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.period = Duration.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.threshold = ModemAlarm_Check_FieldCheck_ThresholdCheck.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.encrypted = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.ignorePreviousValueNotFound = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_Check_FieldCheck_DeltaCheck>): ModemAlarm_Check_FieldCheck_DeltaCheck {
    return ModemAlarm_Check_FieldCheck_DeltaCheck.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_Check_FieldCheck_DeltaCheck>): ModemAlarm_Check_FieldCheck_DeltaCheck {
    const message = createBaseModemAlarm_Check_FieldCheck_DeltaCheck();
    message.period = (object.period !== undefined && object.period !== null)
      ? Duration.fromPartial(object.period)
      : undefined;
    message.threshold = (object.threshold !== undefined && object.threshold !== null)
      ? ModemAlarm_Check_FieldCheck_ThresholdCheck.fromPartial(object.threshold)
      : undefined;
    message.encrypted = object.encrypted ?? false;
    message.ignorePreviousValueNotFound = object.ignorePreviousValueNotFound ?? false;
    return message;
  },
};

function createBaseModemAlarm_HealthLevelAfterResolved(): ModemAlarm_HealthLevelAfterResolved {
  return { healthLevel: "", period: undefined };
}

export const ModemAlarm_HealthLevelAfterResolved = {
  encode(message: ModemAlarm_HealthLevelAfterResolved, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.healthLevel !== "") {
      writer.uint32(10).string(message.healthLevel);
    }
    if (message.period !== undefined) {
      Duration.encode(message.period, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarm_HealthLevelAfterResolved {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarm_HealthLevelAfterResolved();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.healthLevel = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.period = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarm_HealthLevelAfterResolved>): ModemAlarm_HealthLevelAfterResolved {
    return ModemAlarm_HealthLevelAfterResolved.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarm_HealthLevelAfterResolved>): ModemAlarm_HealthLevelAfterResolved {
    const message = createBaseModemAlarm_HealthLevelAfterResolved();
    message.healthLevel = object.healthLevel ?? "";
    message.period = (object.period !== undefined && object.period !== null)
      ? Duration.fromPartial(object.period)
      : undefined;
    return message;
  },
};

function createBaseModemAlarmSelection(): ModemAlarmSelection {
  return { identifiers: [], search: undefined };
}

export const ModemAlarmSelection = {
  encode(message: ModemAlarmSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.identifiers) {
      writer.uint32(10).string(v!);
    }
    if (message.search !== undefined) {
      writer.uint32(18).string(message.search);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModemAlarmSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemAlarmSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifiers.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.search = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ModemAlarmSelection>): ModemAlarmSelection {
    return ModemAlarmSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemAlarmSelection>): ModemAlarmSelection {
    const message = createBaseModemAlarmSelection();
    message.identifiers = object.identifiers?.map((e) => e) || [];
    message.search = object.search ?? undefined;
    return message;
  },
};

function createBaseListModemAlarms(): ListModemAlarms {
  return {};
}

export const ListModemAlarms = {
  encode(_: ListModemAlarms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemAlarms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemAlarms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemAlarms>): ListModemAlarms {
    return ListModemAlarms.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListModemAlarms>): ListModemAlarms {
    const message = createBaseListModemAlarms();
    return message;
  },
};

function createBaseListModemAlarms_Request(): ListModemAlarms_Request {
  return { organization: undefined, selection: undefined, pagination: undefined, applyUnitPreferences: undefined };
}

export const ListModemAlarms_Request = {
  encode(message: ListModemAlarms_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      ModemAlarmSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.applyUnitPreferences !== undefined) {
      writer.uint32(32).bool(message.applyUnitPreferences);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemAlarms_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemAlarms_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = ModemAlarmSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.applyUnitPreferences = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemAlarms_Request>): ListModemAlarms_Request {
    return ListModemAlarms_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemAlarms_Request>): ListModemAlarms_Request {
    const message = createBaseListModemAlarms_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? ModemAlarmSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.applyUnitPreferences = object.applyUnitPreferences ?? undefined;
    return message;
  },
};

function createBaseListModemAlarms_Response(): ListModemAlarms_Response {
  return { modemAlarms: [], pagination: undefined, request: undefined };
}

export const ListModemAlarms_Response = {
  encode(message: ListModemAlarms_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modemAlarms) {
      ModemAlarm.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListModemAlarms_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemAlarms_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemAlarms_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modemAlarms.push(ModemAlarm.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = ListModemAlarms_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemAlarms_Response>): ListModemAlarms_Response {
    return ListModemAlarms_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemAlarms_Response>): ListModemAlarms_Response {
    const message = createBaseListModemAlarms_Response();
    message.modemAlarms = object.modemAlarms?.map((e) => ModemAlarm.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? ListModemAlarms_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseCreateModemAlarm(): CreateModemAlarm {
  return {};
}

export const CreateModemAlarm = {
  encode(_: CreateModemAlarm, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateModemAlarm {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateModemAlarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateModemAlarm>): CreateModemAlarm {
    return CreateModemAlarm.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<CreateModemAlarm>): CreateModemAlarm {
    const message = createBaseCreateModemAlarm();
    return message;
  },
};

function createBaseCreateModemAlarm_Request(): CreateModemAlarm_Request {
  return {
    organization: undefined,
    name: "",
    description: undefined,
    triggerCondition: ModemAlarm_TriggerCondition.DEFAULT,
    checks: [],
    defaultHealthLevel: undefined,
    healthLevelAfterResolved: undefined,
  };
}

export const CreateModemAlarm_Request = {
  encode(message: CreateModemAlarm_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.name !== "") {
      writer.uint32(58).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(18).string(message.description);
    }
    if (message.triggerCondition !== ModemAlarm_TriggerCondition.DEFAULT) {
      writer.uint32(24).int32(modemAlarm_TriggerConditionToNumber(message.triggerCondition));
    }
    for (const v of message.checks) {
      ModemAlarm_Check.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.defaultHealthLevel !== undefined) {
      writer.uint32(42).string(message.defaultHealthLevel);
    }
    if (message.healthLevelAfterResolved !== undefined) {
      ModemAlarm_HealthLevelAfterResolved.encode(message.healthLevelAfterResolved, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateModemAlarm_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateModemAlarm_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.triggerCondition = modemAlarm_TriggerConditionFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.checks.push(ModemAlarm_Check.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.defaultHealthLevel = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.healthLevelAfterResolved = ModemAlarm_HealthLevelAfterResolved.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateModemAlarm_Request>): CreateModemAlarm_Request {
    return CreateModemAlarm_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateModemAlarm_Request>): CreateModemAlarm_Request {
    const message = createBaseCreateModemAlarm_Request();
    message.organization = object.organization ?? undefined;
    message.name = object.name ?? "";
    message.description = object.description ?? undefined;
    message.triggerCondition = object.triggerCondition ?? ModemAlarm_TriggerCondition.DEFAULT;
    message.checks = object.checks?.map((e) => ModemAlarm_Check.fromPartial(e)) || [];
    message.defaultHealthLevel = object.defaultHealthLevel ?? undefined;
    message.healthLevelAfterResolved =
      (object.healthLevelAfterResolved !== undefined && object.healthLevelAfterResolved !== null)
        ? ModemAlarm_HealthLevelAfterResolved.fromPartial(object.healthLevelAfterResolved)
        : undefined;
    return message;
  },
};

function createBaseCreateModemAlarm_Response(): CreateModemAlarm_Response {
  return { created: undefined };
}

export const CreateModemAlarm_Response = {
  encode(message: CreateModemAlarm_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.created !== undefined) {
      ModemAlarm.encode(message.created, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateModemAlarm_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateModemAlarm_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.created = ModemAlarm.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateModemAlarm_Response>): CreateModemAlarm_Response {
    return CreateModemAlarm_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateModemAlarm_Response>): CreateModemAlarm_Response {
    const message = createBaseCreateModemAlarm_Response();
    message.created = (object.created !== undefined && object.created !== null)
      ? ModemAlarm.fromPartial(object.created)
      : undefined;
    return message;
  },
};

function createBaseUpdateModemAlarm(): UpdateModemAlarm {
  return {};
}

export const UpdateModemAlarm = {
  encode(_: UpdateModemAlarm, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarm {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarm>): UpdateModemAlarm {
    return UpdateModemAlarm.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateModemAlarm>): UpdateModemAlarm {
    const message = createBaseUpdateModemAlarm();
    return message;
  },
};

function createBaseUpdateModemAlarm_Request(): UpdateModemAlarm_Request {
  return {
    organization: undefined,
    identifier: "",
    updateName: undefined,
    name: undefined,
    updateDescription: undefined,
    description: undefined,
    updateTriggerCondition: undefined,
    updateDefaultHealthLevel: undefined,
    defaultHealthLevel: undefined,
    updateHealthLevelAfterResolved: undefined,
    removeHealthLevelAfterResolved: undefined,
    addChecks: [],
    updateChecks: {},
    deleteChecks: [],
  };
}

export const UpdateModemAlarm_Request = {
  encode(message: UpdateModemAlarm_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    if (message.updateName !== undefined) {
      UpdateClearableString.encode(message.updateName, writer.uint32(90).fork()).ldelim();
    }
    if (message.name !== undefined) {
      writer.uint32(98).string(message.name);
    }
    if (message.updateDescription !== undefined) {
      UpdateClearableString.encode(message.updateDescription, writer.uint32(26).fork()).ldelim();
    }
    if (message.description !== undefined) {
      writer.uint32(106).string(message.description);
    }
    if (message.updateTriggerCondition !== undefined) {
      writer.uint32(32).int32(modemAlarm_TriggerConditionToNumber(message.updateTriggerCondition));
    }
    if (message.updateDefaultHealthLevel !== undefined) {
      UpdateClearableString.encode(message.updateDefaultHealthLevel, writer.uint32(42).fork()).ldelim();
    }
    if (message.defaultHealthLevel !== undefined) {
      writer.uint32(114).string(message.defaultHealthLevel);
    }
    if (message.updateHealthLevelAfterResolved !== undefined) {
      ModemAlarm_HealthLevelAfterResolved.encode(message.updateHealthLevelAfterResolved, writer.uint32(50).fork())
        .ldelim();
    }
    if (message.removeHealthLevelAfterResolved !== undefined) {
      writer.uint32(56).bool(message.removeHealthLevelAfterResolved);
    }
    for (const v of message.addChecks) {
      ModemAlarm_Check.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    Object.entries(message.updateChecks).forEach(([key, value]) => {
      UpdateModemAlarm_Request_UpdateChecksEntry.encode({ key: key as any, value }, writer.uint32(74).fork()).ldelim();
    });
    for (const v of message.deleteChecks) {
      writer.uint32(82).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarm_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarm_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.updateName = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updateDescription = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.updateTriggerCondition = modemAlarm_TriggerConditionFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updateDefaultHealthLevel = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.defaultHealthLevel = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updateHealthLevelAfterResolved = ModemAlarm_HealthLevelAfterResolved.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.removeHealthLevelAfterResolved = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.addChecks.push(ModemAlarm_Check.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          const entry9 = UpdateModemAlarm_Request_UpdateChecksEntry.decode(reader, reader.uint32());
          if (entry9.value !== undefined) {
            message.updateChecks[entry9.key] = entry9.value;
          }
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deleteChecks.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarm_Request>): UpdateModemAlarm_Request {
    return UpdateModemAlarm_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemAlarm_Request>): UpdateModemAlarm_Request {
    const message = createBaseUpdateModemAlarm_Request();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    message.updateName = (object.updateName !== undefined && object.updateName !== null)
      ? UpdateClearableString.fromPartial(object.updateName)
      : undefined;
    message.name = object.name ?? undefined;
    message.updateDescription = (object.updateDescription !== undefined && object.updateDescription !== null)
      ? UpdateClearableString.fromPartial(object.updateDescription)
      : undefined;
    message.description = object.description ?? undefined;
    message.updateTriggerCondition = object.updateTriggerCondition ?? undefined;
    message.updateDefaultHealthLevel =
      (object.updateDefaultHealthLevel !== undefined && object.updateDefaultHealthLevel !== null)
        ? UpdateClearableString.fromPartial(object.updateDefaultHealthLevel)
        : undefined;
    message.defaultHealthLevel = object.defaultHealthLevel ?? undefined;
    message.updateHealthLevelAfterResolved =
      (object.updateHealthLevelAfterResolved !== undefined && object.updateHealthLevelAfterResolved !== null)
        ? ModemAlarm_HealthLevelAfterResolved.fromPartial(object.updateHealthLevelAfterResolved)
        : undefined;
    message.removeHealthLevelAfterResolved = object.removeHealthLevelAfterResolved ?? undefined;
    message.addChecks = object.addChecks?.map((e) => ModemAlarm_Check.fromPartial(e)) || [];
    message.updateChecks = Object.entries(object.updateChecks ?? {}).reduce<{ [key: string]: ModemAlarm_Check }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ModemAlarm_Check.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.deleteChecks = object.deleteChecks?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateModemAlarm_Request_UpdateChecksEntry(): UpdateModemAlarm_Request_UpdateChecksEntry {
  return { key: "", value: undefined };
}

export const UpdateModemAlarm_Request_UpdateChecksEntry = {
  encode(message: UpdateModemAlarm_Request_UpdateChecksEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ModemAlarm_Check.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarm_Request_UpdateChecksEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarm_Request_UpdateChecksEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ModemAlarm_Check.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarm_Request_UpdateChecksEntry>): UpdateModemAlarm_Request_UpdateChecksEntry {
    return UpdateModemAlarm_Request_UpdateChecksEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateModemAlarm_Request_UpdateChecksEntry>,
  ): UpdateModemAlarm_Request_UpdateChecksEntry {
    const message = createBaseUpdateModemAlarm_Request_UpdateChecksEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ModemAlarm_Check.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseUpdateModemAlarm_Response(): UpdateModemAlarm_Response {
  return { updated: undefined };
}

export const UpdateModemAlarm_Response = {
  encode(message: UpdateModemAlarm_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== undefined) {
      ModemAlarm.encode(message.updated, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarm_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarm_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updated = ModemAlarm.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarm_Response>): UpdateModemAlarm_Response {
    return UpdateModemAlarm_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemAlarm_Response>): UpdateModemAlarm_Response {
    const message = createBaseUpdateModemAlarm_Response();
    message.updated = (object.updated !== undefined && object.updated !== null)
      ? ModemAlarm.fromPartial(object.updated)
      : undefined;
    return message;
  },
};

function createBaseUpdateModemAlarmAddCheck(): UpdateModemAlarmAddCheck {
  return {};
}

export const UpdateModemAlarmAddCheck = {
  encode(_: UpdateModemAlarmAddCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmAddCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmAddCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmAddCheck>): UpdateModemAlarmAddCheck {
    return UpdateModemAlarmAddCheck.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateModemAlarmAddCheck>): UpdateModemAlarmAddCheck {
    const message = createBaseUpdateModemAlarmAddCheck();
    return message;
  },
};

function createBaseUpdateModemAlarmAddCheck_Request(): UpdateModemAlarmAddCheck_Request {
  return { organization: undefined, alarmIdentifier: "", check: undefined };
}

export const UpdateModemAlarmAddCheck_Request = {
  encode(message: UpdateModemAlarmAddCheck_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.alarmIdentifier !== "") {
      writer.uint32(18).string(message.alarmIdentifier);
    }
    if (message.check !== undefined) {
      ModemAlarm_Check.encode(message.check, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmAddCheck_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmAddCheck_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarmIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.check = ModemAlarm_Check.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmAddCheck_Request>): UpdateModemAlarmAddCheck_Request {
    return UpdateModemAlarmAddCheck_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemAlarmAddCheck_Request>): UpdateModemAlarmAddCheck_Request {
    const message = createBaseUpdateModemAlarmAddCheck_Request();
    message.organization = object.organization ?? undefined;
    message.alarmIdentifier = object.alarmIdentifier ?? "";
    message.check = (object.check !== undefined && object.check !== null)
      ? ModemAlarm_Check.fromPartial(object.check)
      : undefined;
    return message;
  },
};

function createBaseUpdateModemAlarmAddCheck_Response(): UpdateModemAlarmAddCheck_Response {
  return { updated: undefined };
}

export const UpdateModemAlarmAddCheck_Response = {
  encode(message: UpdateModemAlarmAddCheck_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== undefined) {
      ModemAlarm.encode(message.updated, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmAddCheck_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmAddCheck_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updated = ModemAlarm.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmAddCheck_Response>): UpdateModemAlarmAddCheck_Response {
    return UpdateModemAlarmAddCheck_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemAlarmAddCheck_Response>): UpdateModemAlarmAddCheck_Response {
    const message = createBaseUpdateModemAlarmAddCheck_Response();
    message.updated = (object.updated !== undefined && object.updated !== null)
      ? ModemAlarm.fromPartial(object.updated)
      : undefined;
    return message;
  },
};

function createBaseUpdateModemAlarmUpdateCheck(): UpdateModemAlarmUpdateCheck {
  return {};
}

export const UpdateModemAlarmUpdateCheck = {
  encode(_: UpdateModemAlarmUpdateCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmUpdateCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmUpdateCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmUpdateCheck>): UpdateModemAlarmUpdateCheck {
    return UpdateModemAlarmUpdateCheck.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateModemAlarmUpdateCheck>): UpdateModemAlarmUpdateCheck {
    const message = createBaseUpdateModemAlarmUpdateCheck();
    return message;
  },
};

function createBaseUpdateModemAlarmUpdateCheck_Request(): UpdateModemAlarmUpdateCheck_Request {
  return {
    organization: undefined,
    alarmIdentifier: "",
    checkIdentifier: "",
    updateCheck: undefined,
    testParametersOnly: false,
  };
}

export const UpdateModemAlarmUpdateCheck_Request = {
  encode(message: UpdateModemAlarmUpdateCheck_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.alarmIdentifier !== "") {
      writer.uint32(18).string(message.alarmIdentifier);
    }
    if (message.checkIdentifier !== "") {
      writer.uint32(26).string(message.checkIdentifier);
    }
    if (message.updateCheck !== undefined) {
      ModemAlarm_Check.encode(message.updateCheck, writer.uint32(34).fork()).ldelim();
    }
    if (message.testParametersOnly === true) {
      writer.uint32(48).bool(message.testParametersOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmUpdateCheck_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmUpdateCheck_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarmIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.checkIdentifier = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateCheck = ModemAlarm_Check.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.testParametersOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmUpdateCheck_Request>): UpdateModemAlarmUpdateCheck_Request {
    return UpdateModemAlarmUpdateCheck_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemAlarmUpdateCheck_Request>): UpdateModemAlarmUpdateCheck_Request {
    const message = createBaseUpdateModemAlarmUpdateCheck_Request();
    message.organization = object.organization ?? undefined;
    message.alarmIdentifier = object.alarmIdentifier ?? "";
    message.checkIdentifier = object.checkIdentifier ?? "";
    message.updateCheck = (object.updateCheck !== undefined && object.updateCheck !== null)
      ? ModemAlarm_Check.fromPartial(object.updateCheck)
      : undefined;
    message.testParametersOnly = object.testParametersOnly ?? false;
    return message;
  },
};

function createBaseUpdateModemAlarmUpdateCheck_Response(): UpdateModemAlarmUpdateCheck_Response {
  return { updated: undefined };
}

export const UpdateModemAlarmUpdateCheck_Response = {
  encode(message: UpdateModemAlarmUpdateCheck_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== undefined) {
      ModemAlarm.encode(message.updated, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmUpdateCheck_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmUpdateCheck_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updated = ModemAlarm.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmUpdateCheck_Response>): UpdateModemAlarmUpdateCheck_Response {
    return UpdateModemAlarmUpdateCheck_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemAlarmUpdateCheck_Response>): UpdateModemAlarmUpdateCheck_Response {
    const message = createBaseUpdateModemAlarmUpdateCheck_Response();
    message.updated = (object.updated !== undefined && object.updated !== null)
      ? ModemAlarm.fromPartial(object.updated)
      : undefined;
    return message;
  },
};

function createBaseUpdateModemAlarmRemoveCheck(): UpdateModemAlarmRemoveCheck {
  return {};
}

export const UpdateModemAlarmRemoveCheck = {
  encode(_: UpdateModemAlarmRemoveCheck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmRemoveCheck {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmRemoveCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmRemoveCheck>): UpdateModemAlarmRemoveCheck {
    return UpdateModemAlarmRemoveCheck.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateModemAlarmRemoveCheck>): UpdateModemAlarmRemoveCheck {
    const message = createBaseUpdateModemAlarmRemoveCheck();
    return message;
  },
};

function createBaseUpdateModemAlarmRemoveCheck_Request(): UpdateModemAlarmRemoveCheck_Request {
  return { organization: undefined, alarmIdentifier: "", checkIdentifier: "" };
}

export const UpdateModemAlarmRemoveCheck_Request = {
  encode(message: UpdateModemAlarmRemoveCheck_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.alarmIdentifier !== "") {
      writer.uint32(18).string(message.alarmIdentifier);
    }
    if (message.checkIdentifier !== "") {
      writer.uint32(26).string(message.checkIdentifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmRemoveCheck_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmRemoveCheck_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarmIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.checkIdentifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmRemoveCheck_Request>): UpdateModemAlarmRemoveCheck_Request {
    return UpdateModemAlarmRemoveCheck_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemAlarmRemoveCheck_Request>): UpdateModemAlarmRemoveCheck_Request {
    const message = createBaseUpdateModemAlarmRemoveCheck_Request();
    message.organization = object.organization ?? undefined;
    message.alarmIdentifier = object.alarmIdentifier ?? "";
    message.checkIdentifier = object.checkIdentifier ?? "";
    return message;
  },
};

function createBaseUpdateModemAlarmRemoveCheck_Response(): UpdateModemAlarmRemoveCheck_Response {
  return { updated: undefined };
}

export const UpdateModemAlarmRemoveCheck_Response = {
  encode(message: UpdateModemAlarmRemoveCheck_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated !== undefined) {
      ModemAlarm.encode(message.updated, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateModemAlarmRemoveCheck_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateModemAlarmRemoveCheck_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updated = ModemAlarm.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateModemAlarmRemoveCheck_Response>): UpdateModemAlarmRemoveCheck_Response {
    return UpdateModemAlarmRemoveCheck_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateModemAlarmRemoveCheck_Response>): UpdateModemAlarmRemoveCheck_Response {
    const message = createBaseUpdateModemAlarmRemoveCheck_Response();
    message.updated = (object.updated !== undefined && object.updated !== null)
      ? ModemAlarm.fromPartial(object.updated)
      : undefined;
    return message;
  },
};

function createBaseDeleteModemAlarm(): DeleteModemAlarm {
  return {};
}

export const DeleteModemAlarm = {
  encode(_: DeleteModemAlarm, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteModemAlarm {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteModemAlarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteModemAlarm>): DeleteModemAlarm {
    return DeleteModemAlarm.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteModemAlarm>): DeleteModemAlarm {
    const message = createBaseDeleteModemAlarm();
    return message;
  },
};

function createBaseDeleteModemAlarm_Request(): DeleteModemAlarm_Request {
  return { organization: undefined, identifier: "" };
}

export const DeleteModemAlarm_Request = {
  encode(message: DeleteModemAlarm_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteModemAlarm_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteModemAlarm_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteModemAlarm_Request>): DeleteModemAlarm_Request {
    return DeleteModemAlarm_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteModemAlarm_Request>): DeleteModemAlarm_Request {
    const message = createBaseDeleteModemAlarm_Request();
    message.organization = object.organization ?? undefined;
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseDeleteModemAlarm_Response(): DeleteModemAlarm_Response {
  return {};
}

export const DeleteModemAlarm_Response = {
  encode(_: DeleteModemAlarm_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteModemAlarm_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteModemAlarm_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteModemAlarm_Response>): DeleteModemAlarm_Response {
    return DeleteModemAlarm_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteModemAlarm_Response>): DeleteModemAlarm_Response {
    const message = createBaseDeleteModemAlarm_Response();
    return message;
  },
};

function createBaseTestModemAlarmTestParameters(): TestModemAlarmTestParameters {
  return {};
}

export const TestModemAlarmTestParameters = {
  encode(_: TestModemAlarmTestParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestModemAlarmTestParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestModemAlarmTestParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TestModemAlarmTestParameters>): TestModemAlarmTestParameters {
    return TestModemAlarmTestParameters.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<TestModemAlarmTestParameters>): TestModemAlarmTestParameters {
    const message = createBaseTestModemAlarmTestParameters();
    return message;
  },
};

function createBaseTestModemAlarmTestParameters_Request(): TestModemAlarmTestParameters_Request {
  return { organization: undefined, alarmIdentifier: "", parameters: undefined };
}

export const TestModemAlarmTestParameters_Request = {
  encode(message: TestModemAlarmTestParameters_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.alarmIdentifier !== "") {
      writer.uint32(18).string(message.alarmIdentifier);
    }
    if (message.parameters !== undefined) {
      Struct.encode(Struct.wrap(message.parameters), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestModemAlarmTestParameters_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestModemAlarmTestParameters_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarmIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parameters = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TestModemAlarmTestParameters_Request>): TestModemAlarmTestParameters_Request {
    return TestModemAlarmTestParameters_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TestModemAlarmTestParameters_Request>): TestModemAlarmTestParameters_Request {
    const message = createBaseTestModemAlarmTestParameters_Request();
    message.organization = object.organization ?? undefined;
    message.alarmIdentifier = object.alarmIdentifier ?? "";
    message.parameters = object.parameters ?? undefined;
    return message;
  },
};

function createBaseTestModemAlarmTestParameters_Response(): TestModemAlarmTestParameters_Response {
  return { result: undefined };
}

export const TestModemAlarmTestParameters_Response = {
  encode(message: TestModemAlarmTestParameters_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== undefined) {
      ModemAlarm.encode(message.result, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestModemAlarmTestParameters_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestModemAlarmTestParameters_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.result = ModemAlarm.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TestModemAlarmTestParameters_Response>): TestModemAlarmTestParameters_Response {
    return TestModemAlarmTestParameters_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TestModemAlarmTestParameters_Response>): TestModemAlarmTestParameters_Response {
    const message = createBaseTestModemAlarmTestParameters_Response();
    message.result = (object.result !== undefined && object.result !== null)
      ? ModemAlarm.fromPartial(object.result)
      : undefined;
    return message;
  },
};

function createBaseAssignedModemAlarm(): AssignedModemAlarm {
  return { alarmIdentifier: "", modemNumber: "" };
}

export const AssignedModemAlarm = {
  encode(message: AssignedModemAlarm, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.alarmIdentifier !== "") {
      writer.uint32(10).string(message.alarmIdentifier);
    }
    if (message.modemNumber !== "") {
      writer.uint32(18).string(message.modemNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignedModemAlarm {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignedModemAlarm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.alarmIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignedModemAlarm>): AssignedModemAlarm {
    return AssignedModemAlarm.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignedModemAlarm>): AssignedModemAlarm {
    const message = createBaseAssignedModemAlarm();
    message.alarmIdentifier = object.alarmIdentifier ?? "";
    message.modemNumber = object.modemNumber ?? "";
    return message;
  },
};

function createBaseAssignModemAlarms(): AssignModemAlarms {
  return {};
}

export const AssignModemAlarms = {
  encode(_: AssignModemAlarms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignModemAlarms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignModemAlarms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignModemAlarms>): AssignModemAlarms {
    return AssignModemAlarms.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AssignModemAlarms>): AssignModemAlarms {
    const message = createBaseAssignModemAlarms();
    return message;
  },
};

function createBaseAssignModemAlarms_Request(): AssignModemAlarms_Request {
  return { organization: undefined, alarms: undefined, modems: undefined, parameters: {} };
}

export const AssignModemAlarms_Request = {
  encode(message: AssignModemAlarms_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.alarms !== undefined) {
      ModemAlarmSelection.encode(message.alarms, writer.uint32(18).fork()).ldelim();
    }
    if (message.modems !== undefined) {
      ModemSelection.encode(message.modems, writer.uint32(26).fork()).ldelim();
    }
    Object.entries(message.parameters).forEach(([key, value]) => {
      if (value !== undefined) {
        AssignModemAlarms_Request_ParametersEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).ldelim();
      }
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignModemAlarms_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignModemAlarms_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarms = ModemAlarmSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modems = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = AssignModemAlarms_Request_ParametersEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.parameters[entry4.key] = entry4.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignModemAlarms_Request>): AssignModemAlarms_Request {
    return AssignModemAlarms_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignModemAlarms_Request>): AssignModemAlarms_Request {
    const message = createBaseAssignModemAlarms_Request();
    message.organization = object.organization ?? undefined;
    message.alarms = (object.alarms !== undefined && object.alarms !== null)
      ? ModemAlarmSelection.fromPartial(object.alarms)
      : undefined;
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? ModemSelection.fromPartial(object.modems)
      : undefined;
    message.parameters = Object.entries(object.parameters ?? {}).reduce<
      { [key: string]: { [key: string]: any } | undefined }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseAssignModemAlarms_Request_ParametersEntry(): AssignModemAlarms_Request_ParametersEntry {
  return { key: "", value: undefined };
}

export const AssignModemAlarms_Request_ParametersEntry = {
  encode(message: AssignModemAlarms_Request_ParametersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Struct.encode(Struct.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignModemAlarms_Request_ParametersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignModemAlarms_Request_ParametersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignModemAlarms_Request_ParametersEntry>): AssignModemAlarms_Request_ParametersEntry {
    return AssignModemAlarms_Request_ParametersEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<AssignModemAlarms_Request_ParametersEntry>,
  ): AssignModemAlarms_Request_ParametersEntry {
    const message = createBaseAssignModemAlarms_Request_ParametersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseAssignModemAlarms_Response(): AssignModemAlarms_Response {
  return { created: [], request: undefined };
}

export const AssignModemAlarms_Response = {
  encode(message: AssignModemAlarms_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.created) {
      AssignedModemAlarm.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      AssignModemAlarms_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignModemAlarms_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignModemAlarms_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.created.push(AssignedModemAlarm.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = AssignModemAlarms_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignModemAlarms_Response>): AssignModemAlarms_Response {
    return AssignModemAlarms_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignModemAlarms_Response>): AssignModemAlarms_Response {
    const message = createBaseAssignModemAlarms_Response();
    message.created = object.created?.map((e) => AssignedModemAlarm.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? AssignModemAlarms_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUnassignModemAlarms(): UnassignModemAlarms {
  return {};
}

export const UnassignModemAlarms = {
  encode(_: UnassignModemAlarms, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignModemAlarms {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignModemAlarms();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignModemAlarms>): UnassignModemAlarms {
    return UnassignModemAlarms.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UnassignModemAlarms>): UnassignModemAlarms {
    const message = createBaseUnassignModemAlarms();
    return message;
  },
};

function createBaseUnassignModemAlarms_Request(): UnassignModemAlarms_Request {
  return { organization: undefined, alarms: undefined, modems: undefined };
}

export const UnassignModemAlarms_Request = {
  encode(message: UnassignModemAlarms_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.alarms !== undefined) {
      ModemAlarmSelection.encode(message.alarms, writer.uint32(18).fork()).ldelim();
    }
    if (message.modems !== undefined) {
      ModemSelection.encode(message.modems, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignModemAlarms_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignModemAlarms_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarms = ModemAlarmSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modems = ModemSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignModemAlarms_Request>): UnassignModemAlarms_Request {
    return UnassignModemAlarms_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnassignModemAlarms_Request>): UnassignModemAlarms_Request {
    const message = createBaseUnassignModemAlarms_Request();
    message.organization = object.organization ?? undefined;
    message.alarms = (object.alarms !== undefined && object.alarms !== null)
      ? ModemAlarmSelection.fromPartial(object.alarms)
      : undefined;
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? ModemSelection.fromPartial(object.modems)
      : undefined;
    return message;
  },
};

function createBaseUnassignModemAlarms_Response(): UnassignModemAlarms_Response {
  return { removedDirectAssignments: [], request: undefined };
}

export const UnassignModemAlarms_Response = {
  encode(message: UnassignModemAlarms_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.removedDirectAssignments) {
      AssignedModemAlarm.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UnassignModemAlarms_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignModemAlarms_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignModemAlarms_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.removedDirectAssignments.push(AssignedModemAlarm.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = UnassignModemAlarms_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignModemAlarms_Response>): UnassignModemAlarms_Response {
    return UnassignModemAlarms_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnassignModemAlarms_Response>): UnassignModemAlarms_Response {
    const message = createBaseUnassignModemAlarms_Response();
    message.removedDirectAssignments = object.removedDirectAssignments?.map((e) => AssignedModemAlarm.fromPartial(e)) ||
      [];
    message.request = (object.request !== undefined && object.request !== null)
      ? UnassignModemAlarms_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

export type ModemAlarmServiceDefinition = typeof ModemAlarmServiceDefinition;
export const ModemAlarmServiceDefinition = {
  name: "ModemAlarmService",
  fullName: "hiber.modem.alarm.ModemAlarmService",
  methods: {
    /** List available modem alarms (including inherited from your parent organizations). */
    list: {
      name: "List",
      requestType: ListModemAlarms_Request,
      requestStream: false,
      responseType: ListModemAlarms_Response,
      responseStream: false,
      options: {},
    },
    /** Create a new modem alarm with any number of checks. */
    create: {
      name: "Create",
      requestType: CreateModemAlarm_Request,
      requestStream: false,
      responseType: CreateModemAlarm_Response,
      responseStream: false,
      options: {},
    },
    /** Update a modem alarm and its checks, if you are the owner. */
    update: {
      name: "Update",
      requestType: UpdateModemAlarm_Request,
      requestStream: false,
      responseType: UpdateModemAlarm_Response,
      responseStream: false,
      options: {},
    },
    /** Delete a modem alarm, if you are the owner. */
    delete: {
      name: "Delete",
      requestType: DeleteModemAlarm_Request,
      requestStream: false,
      responseType: DeleteModemAlarm_Response,
      responseStream: false,
      options: {},
    },
    /** Add a check to a modem alarm, if you are the owner. */
    addCheck: {
      name: "AddCheck",
      requestType: UpdateModemAlarmAddCheck_Request,
      requestStream: false,
      responseType: UpdateModemAlarmAddCheck_Response,
      responseStream: false,
      options: {},
    },
    /** Update a check in a modem alarm, if you are the owner. */
    updateCheck: {
      name: "UpdateCheck",
      requestType: UpdateModemAlarmUpdateCheck_Request,
      requestStream: false,
      responseType: UpdateModemAlarmUpdateCheck_Response,
      responseStream: false,
      options: {},
    },
    /** Remove a check from a modem alarm, if you are the owner. */
    removeCheck: {
      name: "RemoveCheck",
      requestType: UpdateModemAlarmRemoveCheck_Request,
      requestStream: false,
      responseType: UpdateModemAlarmRemoveCheck_Response,
      responseStream: false,
      options: {},
    },
    /** Test a set of parameters on a modem alarm, to see the result when they are applied during assignment. */
    testParameters: {
      name: "TestParameters",
      requestType: TestModemAlarmTestParameters_Request,
      requestStream: false,
      responseType: TestModemAlarmTestParameters_Response,
      responseStream: false,
      options: {},
    },
    /** Assign a modem alarm to a modem. */
    assign: {
      name: "Assign",
      requestType: AssignModemAlarms_Request,
      requestStream: false,
      responseType: AssignModemAlarms_Response,
      responseStream: false,
      options: {},
    },
    /** Unassign a modem alarm from a modem. */
    unassign: {
      name: "Unassign",
      requestType: UnassignModemAlarms_Request,
      requestStream: false,
      responseType: UnassignModemAlarms_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
