import { Client } from 'nice-grpc-web';
import { GrpcClientFactoryType } from './types';
import { DeepPartial } from '../customer_compiled/health';
import { ListRoles_Request, ListRoles_Response, RoleServiceDefinition } from '../customer_compiled/role_service';

export default class RoleService {
  private static instance: RoleService;
  private readonly client: Client<typeof RoleServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof RoleServiceDefinition>(
      RoleServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!RoleService.instance) {
      RoleService.instance = new RoleService(grpcClientFactory);
    }

    return RoleService.instance;
  }

  list(payload: DeepPartial<ListRoles_Request>): Promise<ListRoles_Response> {
    const request = ListRoles_Request.fromPartial(payload);
    return this.client.list(request);
  }
}
