/**
 * Keeps track of all displayed data with tables. That includes selected states.
 * 'name' in the params refers to the tableName used in "initTable"-mutation.
 */
import Vue from 'vue';
import { defaultErrorHandler } from '@/store/core/utils';
import Table from '../Table';

const Tables = {
  namespaced: true,
  state: {
    // Please declare used table names here as a 'new Table()'. This is to get Vuex reactivity working.
    // For an overview of what will be dynamically added to a table object, see "initTable"-mutation
    // -- Example:
    // 'tableNamespace/tableName': new Table();
  },
  actions: {
    /**
     * Create a new store entry for a table.
     * name = Table name used to keep track of what table to apply mutations to
     * rowUid = Data property name used to differentiate between rows, which can be used to find the row by this ID.
     * @param { name: string, rowUid: string } payload
     */
    initTable(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.name !== '' && payload.rowUid !== '') {
          // Check if the table name is declared
          if (typeof context.state[payload.name] !== 'undefined') {
            // No need to actually initialise it twice
            if (context.state[payload.name].hasBeenInitialised === false) {
              context.commit('initTable', payload);
              resolve(payload);
            }
            // Do nothing
            resolve(payload);
          } else {
            defaultErrorHandler(
              { statusMessage: `For proper reactivity, please initialise ${payload.name} in tables.js initial state as empty object.` },
              reject,
              context,
            );
          }
        } else {
          defaultErrorHandler(
            { statusMessage: 'Fill in a name and rowUid for the table!' },
            reject,
            context,
          );
        }
      });
    },
    /**
     * Add to selected list, if not already selected
     * @param { name: string, rows: (uids)string[], data: object[] } payload
     */
    addToSelection(context, payload) {
      return new Promise((resolve) => {
        // Only add them if they are not already selected
        const rowUidsToAdd = payload.rows.filter(uid => !context.getters.isSelected(payload.name, uid));
        const data = payload.data || [];
        if (rowUidsToAdd.length > 0) {
          context.commit('addToSelection', {
            name: payload.name,
            rows: rowUidsToAdd,
            data: data.length > 0 ? data : [],
          });
        }
        resolve();
      });
    },
    /**
     * Remove from selected list, if not already selected
     * @param { name: string, rows: (uids)string[], removeData: boolean } payload
     */
    removeFromSelection(context, payload) {
      return new Promise((resolve) => {
        // Only remove them if they are selected
        const rowUidsToRemove = payload.rows.filter(uid => context.getters.isSelected(payload.name, uid));
        const removeData = payload.removeData || true;
        if (rowUidsToRemove.length > 0) {
          context.commit('removeFromSelection', { name: payload.name, rows: rowUidsToRemove, removeData });
        }
        resolve();
      });
    },
    /**
     * Toggle selected state (NOTE: Single row only!)
     * If data is truthy, either save or remove data depending on toggle stat
     * @param { name: string, row: (uid)string, data: object / boolean } payload
     */
    toggleSelection(context, payload) {
      return new Promise((resolve) => {
        if (context.getters.isSelected(payload.name, payload.row)) {
          const removeData = payload.data || true;
          context.commit('removeFromSelection', { name: payload.name, rows: [payload.row], removeData });
        } else {
          const data = payload.data ? [payload.data] : [];
          context.commit('addToSelection', { name: payload.name, rows: [payload.row], data });
        }
        resolve();
      });
    },
    /**
     * Set selected items, completely replacing current state
     * @param { name: string, rows: (uids)string[], data: object } payload
     */
    setSelection(context, payload) {
      return new Promise((resolve) => {
        context.commit('setSelection', payload);
        resolve();
      });
    },
    /**
     * Toggle all-selected state
     * @param { name: string } payload
     */
    toggleAllSelected(context, payload) {
      return new Promise((resolve) => {
        if (typeof context.state[payload.name] !== 'undefined') {
          context.commit('setAllSelected', { name: payload.name, state: !context.state[payload.name].selectedAll });
          resolve();
        }
      });
    },
    /**
     * Set all-selected toggle
     * @param { name: string, state: bool } payload
     */
    setAllSelected(context, payload) {
      return new Promise((resolve) => {
        context.commit('setAllSelected', payload);
        resolve();
      });
    },
  },
  mutations: {
    // payload: (name: string, rowUid: string)
    initTable(state, payload) {
      state[payload.name] = {
        hasBeenInitialised: true,
        name: payload.name,
        rowUid: payload.rowUid,
        selected: [],
        selectedAll: false,
        selectedData: {},
      };
    },
    // payload: (name: string, rows: (uids)string[], data: object[])
    addToSelection(state, payload) {
      const tableState = state[payload.name];
      // Add payload to existing selection
      Vue.set(state[payload.name], 'selected', [...tableState.selected, ...payload.rows]);

      // Save data by the data's rowUid value
      if (payload.data.length > 0) {
        payload.data.forEach((rowData) => {
          const rowUid = state[payload.name].rowUid;
          Vue.set(state[payload.name].selectedData, rowData[rowUid], rowData);
        });
      }
    },
    // payload: (name: string, rows: (uids)string[], removeData: boolean)
    removeFromSelection(state, payload) {
      // Returns an array with uids to filter
      const selectedToRemove = payload.rows.reduce((filtered, uid) => {
        filtered.push(uid);
        return filtered;
      }, []);

      // ...so we can filter easily without splicing anything.
      const activeSelections = state[payload.name].selected;
      Vue.set(state[payload.name], 'selected', activeSelections.filter(uid => selectedToRemove.indexOf(uid) < 0));

      // Remove data by the rowUid
      if (payload.removeData) {
        payload.rows.forEach((row) => {
          Vue.delete(state[payload.name].selectedData, row);
        });
      }
    },
    // payload: (name: string, rows: (uids)string[], data: object)
    setSelection(state, payload) {
      Vue.set(state[payload.name], 'selected', payload.rows);

      if (payload.data) {
        Vue.set(state[payload.name], 'selectedData', payload.data);
      }
    },
    // payload: (name: string, state: bool)
    setAllSelected(state, payload) {
      Vue.set(state[payload.name], 'selectedAll', payload.state);
    },
  },
  getters: {
    /**
     * RECOMMENDED:
     * Get Table Helper class, which hosts the getters. See 'src/store/Table'
     * Usage: const table = this.$store.getters['Tables/get']('table-name');
     */
    get(state) {
      return (name) => {
        const table = new Table(name);
        const tableState = state[name];
        table.__applyState(tableState); // eslint-disable-line no-underscore-dangle

        return table;
      };
    },
    /**
     * Returns a function to check if a row is selected (incl. if "selectAll" is set)
     * Usage: const isSelected = this.$store.getters['Tables/isSelected']('table-name', '0001 AE02');
     */
    isSelected(state) {
      // name: Table name
      // targetUid: Row value for the table's rowUid
      return (name, targetUid) => {
        const table = state[name];

        if (table) {
          if (table.selected) {
            // Find the row (saved by rowUid) which matches targetUid
            const rowIndex = table.selected.findIndex(rowUid => rowUid === targetUid);

            // If selected, rowIndex >= 0, and it's also selected when everything is.
            return rowIndex !== -1 || state.selectedAll;
          }
        }

        return false;
      };
    },
    /**
     * Get table selection amount, or 'all' if selectedAll
     * Usage: const selectionAmount = this.$store.getters['Tables/getSelectionAmount']('table-name');
     */
    getSelectionAmount(state) {
      return (name) => {
        const table = state[name];

        if (table) {
          if (table.selected) {
            if (table.selectedAll) {
              return 'all';
            }
            return table.selected.length;
          }
        }
        return 0;
      };
    },
    /**
     * Get row data from uids and origin
     * @param origin: Array of original row data based on store state.
     * @param uids: Array of uids of rows you want to get the data from.
     * @param key: String of the key that defines what row attribute is its uid.
     * Usage:
     *  const originData = this.$store.state.Modem.modems.modemsList; (used in creating the rows of the table)
     *  const rowData = this.$store.getters['Tables/getDataFromUids'](originData, ['0005 0D77', '0006 7D19'], 'number');
     */
    getDataFromUids() {
      // Go through the origin list. Filter the rows where the row[key] is in the uids array (index >= 0).
      return (origin, uids, key) => origin.filter(row => uids.findIndex(el => el === row[key]) >= 0);
    },
  },
};

export default Tables;
