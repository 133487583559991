import { getGrpcClient } from '@/services/app';

// Base
import { EventType, Pagination } from 'hiber-grpc/src/customer_compiled/base';
// Events
import {
  EventServiceDefinition,
  ListEventsRequest,
  ModemHealthEvents_Request,
  ResolveEvent_Request,
} from 'hiber-grpc/src/customer_compiled/event';

interface ListEventsPayload {
  pagination?: Pagination,
  modems?: { include?: string[], exclude: string[] },
  events?: { include?: EventType[], exclude: EventType[] },
  tags?: { include?: number[], exclude?: number[] },
  timeRange?: { start?: string, end?: string },
  healthLevels?: string[],
  unbundledEvents?: boolean,
  organization?: string,
}

export function fetchEventsHistory(size: number, page: number, modemNrs?: string[], health?: string[]) {
  const request = ListEventsRequest.fromPartial({
    pagination: { size, page },
    selection: {
      modems: { include: modemNrs },
      healthLevels: health,
    },
    unbundledEvents: true,
  });
  const client = getGrpcClient<typeof EventServiceDefinition>(EventServiceDefinition);
  return client.history(request);
}

export function getModemHealthEvents(payload: ListEventsPayload) {
  const { pagination, modems, events, timeRange, tags } = payload;
  const request = ModemHealthEvents_Request.fromPartial({
    pagination,
    organization: payload.organization,
    selection: {
      modems,
      events,
      tags,
      healthLevels: payload.healthLevels || [],
      timeRange: {
        start: { textual: timeRange?.start },
        end: { textual: timeRange?.end },
      },
    },
  });

  const client = getGrpcClient<typeof EventServiceDefinition>(EventServiceDefinition);
  return client.modemHealth(request);
}

export function getModemEvents(payload: ListEventsPayload) {
  const { pagination, modems, events, timeRange, tags, unbundledEvents = true } = payload;
  const request = ListEventsRequest.fromPartial({
    pagination,
    selection: {
      modems,
      events,
      tags,
      healthLevels: payload.healthLevels || [],
      unbundledEvents,
      timeRange: {
        start: { textual: timeRange?.start },
        end: { textual: timeRange?.end },
      },
    },
  });

  const client = getGrpcClient<typeof EventServiceDefinition>(EventServiceDefinition);
  return client.list(request);
}

export function resolveEvent(resolveIdentifier: string) {
  const request = ResolveEvent_Request.fromPartial({
    resolveIdentifier,
  });
  const client = getGrpcClient<typeof EventServiceDefinition>(EventServiceDefinition);
  return client.resolve(request);
}
