<template>
  <div
    :class='[
      `log-wrapper`,
      (log.type ? `log--` + log.type : ``),
      (shown ? `log--show` : `log--hidden`)]'
    @mouseover='disableAnimation()'
    @mouseleave='enableAnimation()'>
    <div
      v-if='log.type !== "undo"'
      class='log'>
      <span class='tw-whitespace-pre-line'>{{ log.message }}</span>
      <div
        class='log__close'
        @click='close()' />
    </div>

    <div
      v-else
      class='log'>
      <h-undo
        :item='log.item'
        :duration='log.duration'
        :message='log.message'
        :category='log.category'
        @close='shown = false' />
    </div>

    <div
      class='log__remaining'
      :style='timeRemainingStyle' />
  </div>
</template>

<script>
import Undo from './Undo';

export default {
  components: {
    'h-undo': Undo,
  },
  props: {
    log: {
      type: Object,
      default: () => ({
        message: '',
        type: '',
        duration: 5000, // 5 seconds
        category: '',
        id: -1,
        timestamp: 0,
        filter: '',
        item: {},
      }),
    },
  },
  data() {
    return {
      shown: false,
      timeout: 0,
      remainingTimeout: 0,
      isAnimating: true,
      timeProgress: 0,
      timeOffset: null,
      timeRemainingStyle: '',
    };
  },
  mounted() {
    this.open();

    if (this.log.duration > 0) {
      this.enableAnimation();
    }
  },
  beforeDestroy() {
    this.disableAnimation();
  },
  methods: {
    open() {
      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    enableAnimation() {
      if (this.log.duration <= 0) return;
      this.isAnimating = true;
      requestAnimationFrame(this.animate);
    },
    disableAnimation() {
      this.isAnimating = false;
      this.timeOffset = null;
    },
    animate(t) {
      if (this.isAnimating) {
        if (!this.timeOffset) {
          this.timeOffset = t;
        }

        const dt = t - this.timeOffset;
        this.timeOffset = t;

        this.timeProgress += dt;

        if (this.timeProgress > this.log.duration) {
          this.close();
        } else {
          this.render();
          requestAnimationFrame(this.animate);
        }
      }
    },
    render() {
      this.timeRemainingStyle = `width: ${100 - ((this.timeProgress / this.log.duration) * 100)}%;`;
    },
  },
};
</script>

<style lang='scss'>
</style>
