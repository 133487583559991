/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'modems': {
    width: 16,
    height: 16,
    viewBox: '0 0 96 96',
    data: '<path pid="0" d="M49.5 45.6c8.1-.9 14.3-7.8 14.3-15.7 0-8.5-7-15.8-15.8-15.8s-15.8 7.3-15.8 15.8c0 7.9 6.2 14.8 14.3 15.7h3zm-1.5-3c-7 0-12.7-5.7-12.7-12.7 0-7 5.7-12.7 12.7-12.7 3.4 0 6.6 1.3 9 3.7 2.4 2.4 3.7 5.6 3.7 9 0 7-5.7 12.7-12.7 12.7zm0 3h1.5V92.4c0 .9-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5V45.9v-.2c.5-.1 1-.1 1.5-.1zm-22.4.3c.2.4.2.8.1 1.2-.1.4-.4.7-.7.9-.3.2-.6.2-.9.2-.6 0-1.1-.3-1.4-.8-3-5.5-4.6-11.8-4.6-18.1-.1-1.1-.1-2.2 0-3.2.5-5 1.9-9.9 4.3-14.4.4-.8 1.3-1.1 2.1-.7.8.4 1.1 1.3.7 2.1-2.2 4.1-3.5 8.7-3.9 13.3v3c.1 5.7 1.5 11.5 4.3 16.5zm-9.2 8.8c.4.8.1 1.7-.6 2.1-.2.1-.5.2-.8.2-.6 0-1.1-.3-1.4-.8-4.5-8.3-6.8-17.5-6.8-26.9 0-1.5 0-3.2.2-4.8C7.7 17 9.8 9.7 13.3 3c.4-.8 1.3-1 2.1-.7.7.4 1 1.3.6 2.1-3.3 6.3-5.3 13.2-5.9 20.2 0 1.5-.2 3.1-.2 4.6.1 8.9 2.3 17.7 6.5 25.5zm72.7-25.4c0 9.4-2.3 18.6-6.8 26.9-.2.5-.8.8-1.3.8-.2.1-.5.1-.7 0-.4-.2-.6-.5-.8-.9-.1-.4-.1-.8.1-1.2 4.2-7.8 6.4-16.5 6.4-25.4 0-2.2-.1-4.5-.4-6.7-.8-6.4-2.8-12.7-5.8-18.4-.2-.4-.3-.8-.2-1.2.1-.4.4-.8.8-1 .8-.4 1.7-.1 2.1.6 4.4 8.2 6.6 17.3 6.6 26.5zm-11.4-4.7c.2 1.6.3 3.2.3 4.7 0 6.3-1.6 12.5-4.6 18.1-.3.5-.9.9-1.5.8-.3 0-.5-.1-.8-.2-.7-.4-1-1.4-.6-2.1 2.7-5.1 4.2-10.8 4.2-16.6 0-1.5-.1-2.9-.3-4.3-.5-4.2-1.7-8.2-3.7-12-.4-.8-.1-1.7.6-2.1.4-.2.8-.2 1.2-.1.4.1.7.4.9.8 2.3 4 3.7 8.4 4.3 13z" _fill="#1ef5f0" _stroke="#1ef5f0" stroke-width="2" stroke-miterlimit="10"/>'
  }
})
