<template>
  <div
    class='input'
    @click='focus()'>
    <div
      v-if='overlay === "left" || overlay === "both"'
      class='input__overlay input__overlay--left'>
      <slot name='overlay-left' />
    </div>

    <div
      v-if='overlay === "right" || overlay === "both"'
      class='input__overlay input__overlay--right'>
      <slot name='overlay-right' />
    </div>

    <input
      ref='field'
      v-bind='$attrs'
      :value='value'
      :type='inputType'
      :class='[
        {
          "has-overlay-left": overlay === "left" || overlay === "both",
          "has-overlay-right": overlay === "right" || overlay === "both",
        },
        inputClass]'
      class='input__field'
      v-on='inputListeners'>
    <div
      v-if='password'
      :title='iconTypeTooltip'
      class='input__password-toggle'
      data-action-name='input-password-visibility-toggle'
      @click='switchVisibility()'>
      <font-awesome-icon :icon='`fal ${iconType}`' />
    </div>

    <p
      v-if='error'
      class='error__message'>
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    overlay: {
      type: String,
      default: '', // '' || 'left' || 'right' || 'both'
    },
    inputClass: {
      type: [String, Object],
      default: '', // Regular class gets put on container .input
    },
    password: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      passwordFieldType: 'password',
    };
  },
  computed: {
    // Required for v-model to work on <h-input />. See Vue docs
    inputListeners() {
      const vm = this;
      return { ...this.$listeners,
        input: (event) => {
          vm.$emit('input', event.target.value);
        },
      };
    },

    inputType() {
      if (this.password) {
        return this.passwordFieldType;
      }
      return this.type;
    },

    iconType() {
      return this.inputType === 'password' ? 'fa-eye' : 'fa-eye-slash';
    },

    iconTypeTooltip() {
      return this.inputType === 'password' ? 'Show password' : 'Hide password';
    },
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },

    focus() {
      this.$refs.field.focus();
    },
  },
};
</script>

<style lang='scss'>
</style>
