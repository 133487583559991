import moment from 'moment-timezone';

let lastLog;
const logColors = {
  default: { color: 'black', background: 'cyan' },
  pending: { color: 'black', background: 'lightgrey' },
  error: { color: 'white', background: 'red' },
  warning: { color: 'black', background: 'orange' },
  success: { color: 'white', background: 'green' },
};

const App = {
  namespaced: true,
  state: {
    logs: [],
    platform: {},
    timezone: moment.tz.guess(),
    timestamp: 'D MMM \'YY HH:mm',
  },
  actions: {
    setPlatform(context, platform) {
      return new Promise((resolve) => {
        context.commit('setPlatform', platform);
        resolve();
      });
    },
    log(context, payload) {
      /**
       * Payload:
       * {
       *  message:  "String",
       *  type:     "default" | "error" | "warning" | "success"
       *  duration: (optional) number of seconds  OR  -1 for infinite (must close manually)
       *  filter:   (optional) "global" (default) | "..."
       *  id:       (generated)
       *  timestamp: (generated)
       * }
       */
      return new Promise((resolve) => {
        if (!payload) {
          // eslint-disable-next-line no-console
          console.error('App/log payload is missing');
          return;
        }
        // eslint-disable-next-line no-console
        if (!payload.message) { console.log('App/log payload is missing `message`', payload); }
        // eslint-disable-next-line no-console
        if (!payload.type) { console.log('App/log payload is missing `type`', payload); }
        if (payload.message && payload.type) {
          if (lastLog) {
            if (lastLog.message === payload.message
              && lastLog.type === payload.type
              && payload.type !== 'undo'
              && Date.now() - lastLog.__timestamp < 2000) {
              // Same log as last attempt, don't commit a log but instead log to dev console.
              // eslint-disable-next-line no-console
              console.log(`%c${payload.type}`, `color: ${logColors[payload.type].color}; background-color: ${logColors[payload.type].background}; padding: 1px 5px; border-radius: 10px; font-weight:700`, payload.message);
            } else {
              context.commit('log', payload);
            }
          } else {
            context.commit('log', payload);
          }
          lastLog = { ...payload, __timestamp: Date.now() };
        }
        resolve();
      });
    },
  },
  mutations: {
    setPlatform(state, res) {
      state.platform = res;
    },
    log(state, res) {
      const id = state.logs.length;
      const timestamp = Date.now();
      const duration = res.duration ? res.duration : 5000;
      const filter = res.filter ? res.filter : 'global';
      const log = {
        ...res, id, timestamp, duration, filter,
      };
      state.logs = [...state.logs, log];
    },
    updateTimestamp(state, res) {
      state.timestamp = res;
    },
  },
  getters: {
  },
};

export default App;
