/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Field_Numeric_Format } from "./field";
import {
  Value_Numeric_Flow_FlowUnit,
  value_Numeric_Flow_FlowUnitFromJSON,
  value_Numeric_Flow_FlowUnitToNumber,
  Value_Numeric_FuelEfficiency_FuelEfficiencyUnit,
  value_Numeric_FuelEfficiency_FuelEfficiencyUnitFromJSON,
  value_Numeric_FuelEfficiency_FuelEfficiencyUnitToNumber,
  Value_Numeric_Pressure_PressureUnit,
  value_Numeric_Pressure_PressureUnitFromJSON,
  value_Numeric_Pressure_PressureUnitToNumber,
  Value_Numeric_RotationSpeed_RotationSpeedUnit,
  value_Numeric_RotationSpeed_RotationSpeedUnitFromJSON,
  value_Numeric_RotationSpeed_RotationSpeedUnitToNumber,
  Value_Numeric_Temperature_TemperatureUnit,
  value_Numeric_Temperature_TemperatureUnitFromJSON,
  value_Numeric_Temperature_TemperatureUnitToNumber,
  Value_Numeric_Voltage_VoltageUnit,
  value_Numeric_Voltage_VoltageUnitFromJSON,
  value_Numeric_Voltage_VoltageUnitToNumber,
} from "./value";

export const protobufPackage = "hiber.value";

/** The preferred units for the current user. */
export interface UnitPreferences {
  /** Optional distance preference for the current user. */
  distancePreference?:
    | UnitPreferences_DistancePreference
    | undefined;
  /** Optional mass preference for the current user. */
  flowPreference?:
    | UnitPreferences_FlowPreference
    | undefined;
  /** Optional fuel efficiency preference for the current user. */
  fuelEfficiencyPreference?:
    | UnitPreferences_FuelEfficiencyPreference
    | undefined;
  /** Optional mass preference for the current user. */
  massPreference?:
    | UnitPreferences_MassPreference
    | undefined;
  /** Optional percentage preference for the current user. */
  percentagePreference?:
    | UnitPreferences_PercentagePreference
    | undefined;
  /** Optional pressure preference for the current user. */
  pressurePreference?:
    | UnitPreferences_PressurePreference
    | undefined;
  /** Optional speed preference for the current user. */
  speedPreference?:
    | UnitPreferences_SpeedPreference
    | undefined;
  /** Optional temperature preference for the current user. */
  temperaturePreference?:
    | UnitPreferences_TemperaturePreference
    | undefined;
  /** Optional voltage preference for the current user. */
  voltagePreference?:
    | UnitPreferences_VoltagePreference
    | undefined;
  /** Optional volume preference for the current user. */
  volumePreference?: UnitPreferences_VolumePreference | undefined;
  rotationSpeedPreference?: UnitPreferences_RotationSpeedPreference | undefined;
}

export enum UnitPreferences_UnitCategory {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function unitPreferences_UnitCategoryFromJSON(object: any): UnitPreferences_UnitCategory {
  switch (object) {
    case 0:
    case "METRIC":
      return UnitPreferences_UnitCategory.METRIC;
    case 1:
    case "IMPERIAL":
      return UnitPreferences_UnitCategory.IMPERIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UnitPreferences_UnitCategory.UNRECOGNIZED;
  }
}

export function unitPreferences_UnitCategoryToNumber(object: UnitPreferences_UnitCategory): number {
  switch (object) {
    case UnitPreferences_UnitCategory.METRIC:
      return 0;
    case UnitPreferences_UnitCategory.IMPERIAL:
      return 1;
    case UnitPreferences_UnitCategory.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** User preferences for distance values. */
export interface UnitPreferences_DistancePreference {
  /** Unit category (metric / imperial) to convert distance values to. */
  preference: UnitPreferences_UnitCategory;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for mass values. */
export interface UnitPreferences_FlowPreference {
  preference: Value_Numeric_Flow_FlowUnit;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for fuel efficiency values. */
export interface UnitPreferences_FuelEfficiencyPreference {
  preference: Value_Numeric_FuelEfficiency_FuelEfficiencyUnit;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for mass values. */
export interface UnitPreferences_MassPreference {
  /** Unit category (metric / imperial) to convert mass values to. */
  preference: UnitPreferences_UnitCategory;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for percentage values. */
export interface UnitPreferences_PercentagePreference {
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for pressure values. */
export interface UnitPreferences_PressurePreference {
  preference: Value_Numeric_Pressure_PressureUnit;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for speed values. */
export interface UnitPreferences_SpeedPreference {
  /** Unit category (metric / imperial) to convert speed values to. */
  preference: UnitPreferences_UnitCategory;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for temperature values. */
export interface UnitPreferences_TemperaturePreference {
  preference: Value_Numeric_Temperature_TemperatureUnit;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for voltage values. */
export interface UnitPreferences_VoltagePreference {
  preference: Value_Numeric_Voltage_VoltageUnit;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for volume values. */
export interface UnitPreferences_VolumePreference {
  /** Unit category (metric / imperial) to convert volume values to. */
  preference: UnitPreferences_UnitCategory;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

/** User preferences for rotation speed values. */
export interface UnitPreferences_RotationSpeedPreference {
  preference: Value_Numeric_RotationSpeed_RotationSpeedUnit;
  /** Optional formatting options for values (rounding, etc). */
  format: Field_Numeric_Format | undefined;
}

export interface UnitPreferences_Request {
  distance?: { $case: "updateDistancePreference"; updateDistancePreference: UnitPreferences_DistancePreference } | {
    $case: "removeDistancePreference";
    removeDistancePreference: boolean;
  } | undefined;
  flow?: { $case: "updateFlowPreference"; updateFlowPreference: UnitPreferences_FlowPreference } | {
    $case: "removeFlowPreference";
    removeFlowPreference: boolean;
  } | undefined;
  fuelEfficiency?:
    | {
      $case: "updateFuelEfficiencyPreference";
      updateFuelEfficiencyPreference: UnitPreferences_FuelEfficiencyPreference;
    }
    | { $case: "removeFuelEfficiencyPreference"; removeFuelEfficiencyPreference: boolean }
    | undefined;
  mass?: { $case: "updateMassPreference"; updateMassPreference: UnitPreferences_MassPreference } | {
    $case: "removeMassPreference";
    removeMassPreference: boolean;
  } | undefined;
  percentage?:
    | { $case: "updatePercentagePreference"; updatePercentagePreference: UnitPreferences_PercentagePreference }
    | { $case: "removePercentagePreference"; removePercentagePreference: boolean }
    | undefined;
  pressure?: { $case: "updatePressurePreference"; updatePressurePreference: UnitPreferences_PressurePreference } | {
    $case: "removePressurePreference";
    removePressurePreference: boolean;
  } | undefined;
  speed?: { $case: "updateSpeedPreference"; updateSpeedPreference: UnitPreferences_SpeedPreference } | {
    $case: "removeSpeedPreference";
    removeSpeedPreference: boolean;
  } | undefined;
  temperature?:
    | { $case: "updateTemperaturePreference"; updateTemperaturePreference: UnitPreferences_TemperaturePreference }
    | { $case: "removeTemperaturePreference"; removeTemperaturePreference: boolean }
    | undefined;
  voltage?: { $case: "updateVoltagePreference"; updateVoltagePreference: UnitPreferences_VoltagePreference } | {
    $case: "removeVoltagePreference";
    removeVoltagePreference: boolean;
  } | undefined;
  volume?: { $case: "updateVolumePreference"; updateVolumePreference: UnitPreferences_VolumePreference } | {
    $case: "removeVolumePreference";
    removeVolumePreference: boolean;
  } | undefined;
  rotationSpeed?:
    | { $case: "updateRotationSpeedPreference"; updateRotationSpeedPreference: UnitPreferences_RotationSpeedPreference }
    | { $case: "removeRotationSpeedPreference"; removeRotationSpeedPreference: boolean }
    | undefined;
}

export interface UnitPreferences_Response {
  preferences: UnitPreferences | undefined;
}

function createBaseUnitPreferences(): UnitPreferences {
  return {
    distancePreference: undefined,
    flowPreference: undefined,
    fuelEfficiencyPreference: undefined,
    massPreference: undefined,
    percentagePreference: undefined,
    pressurePreference: undefined,
    speedPreference: undefined,
    temperaturePreference: undefined,
    voltagePreference: undefined,
    volumePreference: undefined,
    rotationSpeedPreference: undefined,
  };
}

export const UnitPreferences = {
  encode(message: UnitPreferences, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.distancePreference !== undefined) {
      UnitPreferences_DistancePreference.encode(message.distancePreference, writer.uint32(10).fork()).ldelim();
    }
    if (message.flowPreference !== undefined) {
      UnitPreferences_FlowPreference.encode(message.flowPreference, writer.uint32(82).fork()).ldelim();
    }
    if (message.fuelEfficiencyPreference !== undefined) {
      UnitPreferences_FuelEfficiencyPreference.encode(message.fuelEfficiencyPreference, writer.uint32(18).fork())
        .ldelim();
    }
    if (message.massPreference !== undefined) {
      UnitPreferences_MassPreference.encode(message.massPreference, writer.uint32(74).fork()).ldelim();
    }
    if (message.percentagePreference !== undefined) {
      UnitPreferences_PercentagePreference.encode(message.percentagePreference, writer.uint32(26).fork()).ldelim();
    }
    if (message.pressurePreference !== undefined) {
      UnitPreferences_PressurePreference.encode(message.pressurePreference, writer.uint32(34).fork()).ldelim();
    }
    if (message.speedPreference !== undefined) {
      UnitPreferences_SpeedPreference.encode(message.speedPreference, writer.uint32(42).fork()).ldelim();
    }
    if (message.temperaturePreference !== undefined) {
      UnitPreferences_TemperaturePreference.encode(message.temperaturePreference, writer.uint32(50).fork()).ldelim();
    }
    if (message.voltagePreference !== undefined) {
      UnitPreferences_VoltagePreference.encode(message.voltagePreference, writer.uint32(58).fork()).ldelim();
    }
    if (message.volumePreference !== undefined) {
      UnitPreferences_VolumePreference.encode(message.volumePreference, writer.uint32(66).fork()).ldelim();
    }
    if (message.rotationSpeedPreference !== undefined) {
      UnitPreferences_RotationSpeedPreference.encode(message.rotationSpeedPreference, writer.uint32(170).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.distancePreference = UnitPreferences_DistancePreference.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.flowPreference = UnitPreferences_FlowPreference.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fuelEfficiencyPreference = UnitPreferences_FuelEfficiencyPreference.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.massPreference = UnitPreferences_MassPreference.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.percentagePreference = UnitPreferences_PercentagePreference.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pressurePreference = UnitPreferences_PressurePreference.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.speedPreference = UnitPreferences_SpeedPreference.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.temperaturePreference = UnitPreferences_TemperaturePreference.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.voltagePreference = UnitPreferences_VoltagePreference.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.volumePreference = UnitPreferences_VolumePreference.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.rotationSpeedPreference = UnitPreferences_RotationSpeedPreference.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences>): UnitPreferences {
    return UnitPreferences.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences>): UnitPreferences {
    const message = createBaseUnitPreferences();
    message.distancePreference = (object.distancePreference !== undefined && object.distancePreference !== null)
      ? UnitPreferences_DistancePreference.fromPartial(object.distancePreference)
      : undefined;
    message.flowPreference = (object.flowPreference !== undefined && object.flowPreference !== null)
      ? UnitPreferences_FlowPreference.fromPartial(object.flowPreference)
      : undefined;
    message.fuelEfficiencyPreference =
      (object.fuelEfficiencyPreference !== undefined && object.fuelEfficiencyPreference !== null)
        ? UnitPreferences_FuelEfficiencyPreference.fromPartial(object.fuelEfficiencyPreference)
        : undefined;
    message.massPreference = (object.massPreference !== undefined && object.massPreference !== null)
      ? UnitPreferences_MassPreference.fromPartial(object.massPreference)
      : undefined;
    message.percentagePreference = (object.percentagePreference !== undefined && object.percentagePreference !== null)
      ? UnitPreferences_PercentagePreference.fromPartial(object.percentagePreference)
      : undefined;
    message.pressurePreference = (object.pressurePreference !== undefined && object.pressurePreference !== null)
      ? UnitPreferences_PressurePreference.fromPartial(object.pressurePreference)
      : undefined;
    message.speedPreference = (object.speedPreference !== undefined && object.speedPreference !== null)
      ? UnitPreferences_SpeedPreference.fromPartial(object.speedPreference)
      : undefined;
    message.temperaturePreference =
      (object.temperaturePreference !== undefined && object.temperaturePreference !== null)
        ? UnitPreferences_TemperaturePreference.fromPartial(object.temperaturePreference)
        : undefined;
    message.voltagePreference = (object.voltagePreference !== undefined && object.voltagePreference !== null)
      ? UnitPreferences_VoltagePreference.fromPartial(object.voltagePreference)
      : undefined;
    message.volumePreference = (object.volumePreference !== undefined && object.volumePreference !== null)
      ? UnitPreferences_VolumePreference.fromPartial(object.volumePreference)
      : undefined;
    message.rotationSpeedPreference =
      (object.rotationSpeedPreference !== undefined && object.rotationSpeedPreference !== null)
        ? UnitPreferences_RotationSpeedPreference.fromPartial(object.rotationSpeedPreference)
        : undefined;
    return message;
  },
};

function createBaseUnitPreferences_DistancePreference(): UnitPreferences_DistancePreference {
  return { preference: UnitPreferences_UnitCategory.METRIC, format: undefined };
}

export const UnitPreferences_DistancePreference = {
  encode(message: UnitPreferences_DistancePreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== UnitPreferences_UnitCategory.METRIC) {
      writer.uint32(8).int32(unitPreferences_UnitCategoryToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_DistancePreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_DistancePreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = unitPreferences_UnitCategoryFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_DistancePreference>): UnitPreferences_DistancePreference {
    return UnitPreferences_DistancePreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_DistancePreference>): UnitPreferences_DistancePreference {
    const message = createBaseUnitPreferences_DistancePreference();
    message.preference = object.preference ?? UnitPreferences_UnitCategory.METRIC;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_FlowPreference(): UnitPreferences_FlowPreference {
  return { preference: Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_HOUR, format: undefined };
}

export const UnitPreferences_FlowPreference = {
  encode(message: UnitPreferences_FlowPreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_HOUR) {
      writer.uint32(8).int32(value_Numeric_Flow_FlowUnitToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_FlowPreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_FlowPreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = value_Numeric_Flow_FlowUnitFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_FlowPreference>): UnitPreferences_FlowPreference {
    return UnitPreferences_FlowPreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_FlowPreference>): UnitPreferences_FlowPreference {
    const message = createBaseUnitPreferences_FlowPreference();
    message.preference = object.preference ?? Value_Numeric_Flow_FlowUnit.CUBIC_METER_PER_HOUR;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_FuelEfficiencyPreference(): UnitPreferences_FuelEfficiencyPreference {
  return { preference: Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.LITER_PER_100_KILOMETER, format: undefined };
}

export const UnitPreferences_FuelEfficiencyPreference = {
  encode(message: UnitPreferences_FuelEfficiencyPreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.LITER_PER_100_KILOMETER) {
      writer.uint32(8).int32(value_Numeric_FuelEfficiency_FuelEfficiencyUnitToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_FuelEfficiencyPreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_FuelEfficiencyPreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = value_Numeric_FuelEfficiency_FuelEfficiencyUnitFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_FuelEfficiencyPreference>): UnitPreferences_FuelEfficiencyPreference {
    return UnitPreferences_FuelEfficiencyPreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_FuelEfficiencyPreference>): UnitPreferences_FuelEfficiencyPreference {
    const message = createBaseUnitPreferences_FuelEfficiencyPreference();
    message.preference = object.preference ?? Value_Numeric_FuelEfficiency_FuelEfficiencyUnit.LITER_PER_100_KILOMETER;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_MassPreference(): UnitPreferences_MassPreference {
  return { preference: UnitPreferences_UnitCategory.METRIC, format: undefined };
}

export const UnitPreferences_MassPreference = {
  encode(message: UnitPreferences_MassPreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== UnitPreferences_UnitCategory.METRIC) {
      writer.uint32(8).int32(unitPreferences_UnitCategoryToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_MassPreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_MassPreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = unitPreferences_UnitCategoryFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_MassPreference>): UnitPreferences_MassPreference {
    return UnitPreferences_MassPreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_MassPreference>): UnitPreferences_MassPreference {
    const message = createBaseUnitPreferences_MassPreference();
    message.preference = object.preference ?? UnitPreferences_UnitCategory.METRIC;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_PercentagePreference(): UnitPreferences_PercentagePreference {
  return { format: undefined };
}

export const UnitPreferences_PercentagePreference = {
  encode(message: UnitPreferences_PercentagePreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_PercentagePreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_PercentagePreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_PercentagePreference>): UnitPreferences_PercentagePreference {
    return UnitPreferences_PercentagePreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_PercentagePreference>): UnitPreferences_PercentagePreference {
    const message = createBaseUnitPreferences_PercentagePreference();
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_PressurePreference(): UnitPreferences_PressurePreference {
  return { preference: Value_Numeric_Pressure_PressureUnit.BAR, format: undefined };
}

export const UnitPreferences_PressurePreference = {
  encode(message: UnitPreferences_PressurePreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== Value_Numeric_Pressure_PressureUnit.BAR) {
      writer.uint32(8).int32(value_Numeric_Pressure_PressureUnitToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_PressurePreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_PressurePreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = value_Numeric_Pressure_PressureUnitFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_PressurePreference>): UnitPreferences_PressurePreference {
    return UnitPreferences_PressurePreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_PressurePreference>): UnitPreferences_PressurePreference {
    const message = createBaseUnitPreferences_PressurePreference();
    message.preference = object.preference ?? Value_Numeric_Pressure_PressureUnit.BAR;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_SpeedPreference(): UnitPreferences_SpeedPreference {
  return { preference: UnitPreferences_UnitCategory.METRIC, format: undefined };
}

export const UnitPreferences_SpeedPreference = {
  encode(message: UnitPreferences_SpeedPreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== UnitPreferences_UnitCategory.METRIC) {
      writer.uint32(8).int32(unitPreferences_UnitCategoryToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_SpeedPreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_SpeedPreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = unitPreferences_UnitCategoryFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_SpeedPreference>): UnitPreferences_SpeedPreference {
    return UnitPreferences_SpeedPreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_SpeedPreference>): UnitPreferences_SpeedPreference {
    const message = createBaseUnitPreferences_SpeedPreference();
    message.preference = object.preference ?? UnitPreferences_UnitCategory.METRIC;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_TemperaturePreference(): UnitPreferences_TemperaturePreference {
  return { preference: Value_Numeric_Temperature_TemperatureUnit.KELVIN, format: undefined };
}

export const UnitPreferences_TemperaturePreference = {
  encode(message: UnitPreferences_TemperaturePreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== Value_Numeric_Temperature_TemperatureUnit.KELVIN) {
      writer.uint32(8).int32(value_Numeric_Temperature_TemperatureUnitToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_TemperaturePreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_TemperaturePreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = value_Numeric_Temperature_TemperatureUnitFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_TemperaturePreference>): UnitPreferences_TemperaturePreference {
    return UnitPreferences_TemperaturePreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_TemperaturePreference>): UnitPreferences_TemperaturePreference {
    const message = createBaseUnitPreferences_TemperaturePreference();
    message.preference = object.preference ?? Value_Numeric_Temperature_TemperatureUnit.KELVIN;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_VoltagePreference(): UnitPreferences_VoltagePreference {
  return { preference: Value_Numeric_Voltage_VoltageUnit.MILLIVOLT, format: undefined };
}

export const UnitPreferences_VoltagePreference = {
  encode(message: UnitPreferences_VoltagePreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== Value_Numeric_Voltage_VoltageUnit.MILLIVOLT) {
      writer.uint32(8).int32(value_Numeric_Voltage_VoltageUnitToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_VoltagePreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_VoltagePreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = value_Numeric_Voltage_VoltageUnitFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_VoltagePreference>): UnitPreferences_VoltagePreference {
    return UnitPreferences_VoltagePreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_VoltagePreference>): UnitPreferences_VoltagePreference {
    const message = createBaseUnitPreferences_VoltagePreference();
    message.preference = object.preference ?? Value_Numeric_Voltage_VoltageUnit.MILLIVOLT;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_VolumePreference(): UnitPreferences_VolumePreference {
  return { preference: UnitPreferences_UnitCategory.METRIC, format: undefined };
}

export const UnitPreferences_VolumePreference = {
  encode(message: UnitPreferences_VolumePreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== UnitPreferences_UnitCategory.METRIC) {
      writer.uint32(8).int32(unitPreferences_UnitCategoryToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_VolumePreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_VolumePreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = unitPreferences_UnitCategoryFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_VolumePreference>): UnitPreferences_VolumePreference {
    return UnitPreferences_VolumePreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_VolumePreference>): UnitPreferences_VolumePreference {
    const message = createBaseUnitPreferences_VolumePreference();
    message.preference = object.preference ?? UnitPreferences_UnitCategory.METRIC;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_RotationSpeedPreference(): UnitPreferences_RotationSpeedPreference {
  return { preference: Value_Numeric_RotationSpeed_RotationSpeedUnit.REVOLUTIONS_PER_MINUTE, format: undefined };
}

export const UnitPreferences_RotationSpeedPreference = {
  encode(message: UnitPreferences_RotationSpeedPreference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preference !== Value_Numeric_RotationSpeed_RotationSpeedUnit.REVOLUTIONS_PER_MINUTE) {
      writer.uint32(8).int32(value_Numeric_RotationSpeed_RotationSpeedUnitToNumber(message.preference));
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_RotationSpeedPreference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_RotationSpeedPreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preference = value_Numeric_RotationSpeed_RotationSpeedUnitFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_RotationSpeedPreference>): UnitPreferences_RotationSpeedPreference {
    return UnitPreferences_RotationSpeedPreference.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_RotationSpeedPreference>): UnitPreferences_RotationSpeedPreference {
    const message = createBaseUnitPreferences_RotationSpeedPreference();
    message.preference = object.preference ?? Value_Numeric_RotationSpeed_RotationSpeedUnit.REVOLUTIONS_PER_MINUTE;
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    return message;
  },
};

function createBaseUnitPreferences_Request(): UnitPreferences_Request {
  return {
    distance: undefined,
    flow: undefined,
    fuelEfficiency: undefined,
    mass: undefined,
    percentage: undefined,
    pressure: undefined,
    speed: undefined,
    temperature: undefined,
    voltage: undefined,
    volume: undefined,
    rotationSpeed: undefined,
  };
}

export const UnitPreferences_Request = {
  encode(message: UnitPreferences_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.distance?.$case) {
      case "updateDistancePreference":
        UnitPreferences_DistancePreference.encode(message.distance.updateDistancePreference, writer.uint32(10).fork())
          .ldelim();
        break;
      case "removeDistancePreference":
        writer.uint32(88).bool(message.distance.removeDistancePreference);
        break;
    }
    switch (message.flow?.$case) {
      case "updateFlowPreference":
        UnitPreferences_FlowPreference.encode(message.flow.updateFlowPreference, writer.uint32(82).fork()).ldelim();
        break;
      case "removeFlowPreference":
        writer.uint32(160).bool(message.flow.removeFlowPreference);
        break;
    }
    switch (message.fuelEfficiency?.$case) {
      case "updateFuelEfficiencyPreference":
        UnitPreferences_FuelEfficiencyPreference.encode(
          message.fuelEfficiency.updateFuelEfficiencyPreference,
          writer.uint32(18).fork(),
        ).ldelim();
        break;
      case "removeFuelEfficiencyPreference":
        writer.uint32(96).bool(message.fuelEfficiency.removeFuelEfficiencyPreference);
        break;
    }
    switch (message.mass?.$case) {
      case "updateMassPreference":
        UnitPreferences_MassPreference.encode(message.mass.updateMassPreference, writer.uint32(74).fork()).ldelim();
        break;
      case "removeMassPreference":
        writer.uint32(152).bool(message.mass.removeMassPreference);
        break;
    }
    switch (message.percentage?.$case) {
      case "updatePercentagePreference":
        UnitPreferences_PercentagePreference.encode(
          message.percentage.updatePercentagePreference,
          writer.uint32(26).fork(),
        ).ldelim();
        break;
      case "removePercentagePreference":
        writer.uint32(104).bool(message.percentage.removePercentagePreference);
        break;
    }
    switch (message.pressure?.$case) {
      case "updatePressurePreference":
        UnitPreferences_PressurePreference.encode(message.pressure.updatePressurePreference, writer.uint32(34).fork())
          .ldelim();
        break;
      case "removePressurePreference":
        writer.uint32(112).bool(message.pressure.removePressurePreference);
        break;
    }
    switch (message.speed?.$case) {
      case "updateSpeedPreference":
        UnitPreferences_SpeedPreference.encode(message.speed.updateSpeedPreference, writer.uint32(42).fork()).ldelim();
        break;
      case "removeSpeedPreference":
        writer.uint32(120).bool(message.speed.removeSpeedPreference);
        break;
    }
    switch (message.temperature?.$case) {
      case "updateTemperaturePreference":
        UnitPreferences_TemperaturePreference.encode(
          message.temperature.updateTemperaturePreference,
          writer.uint32(50).fork(),
        ).ldelim();
        break;
      case "removeTemperaturePreference":
        writer.uint32(128).bool(message.temperature.removeTemperaturePreference);
        break;
    }
    switch (message.voltage?.$case) {
      case "updateVoltagePreference":
        UnitPreferences_VoltagePreference.encode(message.voltage.updateVoltagePreference, writer.uint32(58).fork())
          .ldelim();
        break;
      case "removeVoltagePreference":
        writer.uint32(136).bool(message.voltage.removeVoltagePreference);
        break;
    }
    switch (message.volume?.$case) {
      case "updateVolumePreference":
        UnitPreferences_VolumePreference.encode(message.volume.updateVolumePreference, writer.uint32(66).fork())
          .ldelim();
        break;
      case "removeVolumePreference":
        writer.uint32(144).bool(message.volume.removeVolumePreference);
        break;
    }
    switch (message.rotationSpeed?.$case) {
      case "updateRotationSpeedPreference":
        UnitPreferences_RotationSpeedPreference.encode(
          message.rotationSpeed.updateRotationSpeedPreference,
          writer.uint32(170).fork(),
        ).ldelim();
        break;
      case "removeRotationSpeedPreference":
        writer.uint32(248).bool(message.rotationSpeed.removeRotationSpeedPreference);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.distance = {
            $case: "updateDistancePreference",
            updateDistancePreference: UnitPreferences_DistancePreference.decode(reader, reader.uint32()),
          };
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.distance = { $case: "removeDistancePreference", removeDistancePreference: reader.bool() };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.flow = {
            $case: "updateFlowPreference",
            updateFlowPreference: UnitPreferences_FlowPreference.decode(reader, reader.uint32()),
          };
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.flow = { $case: "removeFlowPreference", removeFlowPreference: reader.bool() };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fuelEfficiency = {
            $case: "updateFuelEfficiencyPreference",
            updateFuelEfficiencyPreference: UnitPreferences_FuelEfficiencyPreference.decode(reader, reader.uint32()),
          };
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.fuelEfficiency = {
            $case: "removeFuelEfficiencyPreference",
            removeFuelEfficiencyPreference: reader.bool(),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.mass = {
            $case: "updateMassPreference",
            updateMassPreference: UnitPreferences_MassPreference.decode(reader, reader.uint32()),
          };
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.mass = { $case: "removeMassPreference", removeMassPreference: reader.bool() };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.percentage = {
            $case: "updatePercentagePreference",
            updatePercentagePreference: UnitPreferences_PercentagePreference.decode(reader, reader.uint32()),
          };
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.percentage = { $case: "removePercentagePreference", removePercentagePreference: reader.bool() };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pressure = {
            $case: "updatePressurePreference",
            updatePressurePreference: UnitPreferences_PressurePreference.decode(reader, reader.uint32()),
          };
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.pressure = { $case: "removePressurePreference", removePressurePreference: reader.bool() };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.speed = {
            $case: "updateSpeedPreference",
            updateSpeedPreference: UnitPreferences_SpeedPreference.decode(reader, reader.uint32()),
          };
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.speed = { $case: "removeSpeedPreference", removeSpeedPreference: reader.bool() };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.temperature = {
            $case: "updateTemperaturePreference",
            updateTemperaturePreference: UnitPreferences_TemperaturePreference.decode(reader, reader.uint32()),
          };
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.temperature = { $case: "removeTemperaturePreference", removeTemperaturePreference: reader.bool() };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.voltage = {
            $case: "updateVoltagePreference",
            updateVoltagePreference: UnitPreferences_VoltagePreference.decode(reader, reader.uint32()),
          };
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.voltage = { $case: "removeVoltagePreference", removeVoltagePreference: reader.bool() };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.volume = {
            $case: "updateVolumePreference",
            updateVolumePreference: UnitPreferences_VolumePreference.decode(reader, reader.uint32()),
          };
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.volume = { $case: "removeVolumePreference", removeVolumePreference: reader.bool() };
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.rotationSpeed = {
            $case: "updateRotationSpeedPreference",
            updateRotationSpeedPreference: UnitPreferences_RotationSpeedPreference.decode(reader, reader.uint32()),
          };
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.rotationSpeed = {
            $case: "removeRotationSpeedPreference",
            removeRotationSpeedPreference: reader.bool(),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_Request>): UnitPreferences_Request {
    return UnitPreferences_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_Request>): UnitPreferences_Request {
    const message = createBaseUnitPreferences_Request();
    if (
      object.distance?.$case === "updateDistancePreference" &&
      object.distance?.updateDistancePreference !== undefined &&
      object.distance?.updateDistancePreference !== null
    ) {
      message.distance = {
        $case: "updateDistancePreference",
        updateDistancePreference: UnitPreferences_DistancePreference.fromPartial(
          object.distance.updateDistancePreference,
        ),
      };
    }
    if (
      object.distance?.$case === "removeDistancePreference" &&
      object.distance?.removeDistancePreference !== undefined &&
      object.distance?.removeDistancePreference !== null
    ) {
      message.distance = {
        $case: "removeDistancePreference",
        removeDistancePreference: object.distance.removeDistancePreference,
      };
    }
    if (
      object.flow?.$case === "updateFlowPreference" &&
      object.flow?.updateFlowPreference !== undefined &&
      object.flow?.updateFlowPreference !== null
    ) {
      message.flow = {
        $case: "updateFlowPreference",
        updateFlowPreference: UnitPreferences_FlowPreference.fromPartial(object.flow.updateFlowPreference),
      };
    }
    if (
      object.flow?.$case === "removeFlowPreference" &&
      object.flow?.removeFlowPreference !== undefined &&
      object.flow?.removeFlowPreference !== null
    ) {
      message.flow = { $case: "removeFlowPreference", removeFlowPreference: object.flow.removeFlowPreference };
    }
    if (
      object.fuelEfficiency?.$case === "updateFuelEfficiencyPreference" &&
      object.fuelEfficiency?.updateFuelEfficiencyPreference !== undefined &&
      object.fuelEfficiency?.updateFuelEfficiencyPreference !== null
    ) {
      message.fuelEfficiency = {
        $case: "updateFuelEfficiencyPreference",
        updateFuelEfficiencyPreference: UnitPreferences_FuelEfficiencyPreference.fromPartial(
          object.fuelEfficiency.updateFuelEfficiencyPreference,
        ),
      };
    }
    if (
      object.fuelEfficiency?.$case === "removeFuelEfficiencyPreference" &&
      object.fuelEfficiency?.removeFuelEfficiencyPreference !== undefined &&
      object.fuelEfficiency?.removeFuelEfficiencyPreference !== null
    ) {
      message.fuelEfficiency = {
        $case: "removeFuelEfficiencyPreference",
        removeFuelEfficiencyPreference: object.fuelEfficiency.removeFuelEfficiencyPreference,
      };
    }
    if (
      object.mass?.$case === "updateMassPreference" &&
      object.mass?.updateMassPreference !== undefined &&
      object.mass?.updateMassPreference !== null
    ) {
      message.mass = {
        $case: "updateMassPreference",
        updateMassPreference: UnitPreferences_MassPreference.fromPartial(object.mass.updateMassPreference),
      };
    }
    if (
      object.mass?.$case === "removeMassPreference" &&
      object.mass?.removeMassPreference !== undefined &&
      object.mass?.removeMassPreference !== null
    ) {
      message.mass = { $case: "removeMassPreference", removeMassPreference: object.mass.removeMassPreference };
    }
    if (
      object.percentage?.$case === "updatePercentagePreference" &&
      object.percentage?.updatePercentagePreference !== undefined &&
      object.percentage?.updatePercentagePreference !== null
    ) {
      message.percentage = {
        $case: "updatePercentagePreference",
        updatePercentagePreference: UnitPreferences_PercentagePreference.fromPartial(
          object.percentage.updatePercentagePreference,
        ),
      };
    }
    if (
      object.percentage?.$case === "removePercentagePreference" &&
      object.percentage?.removePercentagePreference !== undefined &&
      object.percentage?.removePercentagePreference !== null
    ) {
      message.percentage = {
        $case: "removePercentagePreference",
        removePercentagePreference: object.percentage.removePercentagePreference,
      };
    }
    if (
      object.pressure?.$case === "updatePressurePreference" &&
      object.pressure?.updatePressurePreference !== undefined &&
      object.pressure?.updatePressurePreference !== null
    ) {
      message.pressure = {
        $case: "updatePressurePreference",
        updatePressurePreference: UnitPreferences_PressurePreference.fromPartial(
          object.pressure.updatePressurePreference,
        ),
      };
    }
    if (
      object.pressure?.$case === "removePressurePreference" &&
      object.pressure?.removePressurePreference !== undefined &&
      object.pressure?.removePressurePreference !== null
    ) {
      message.pressure = {
        $case: "removePressurePreference",
        removePressurePreference: object.pressure.removePressurePreference,
      };
    }
    if (
      object.speed?.$case === "updateSpeedPreference" &&
      object.speed?.updateSpeedPreference !== undefined &&
      object.speed?.updateSpeedPreference !== null
    ) {
      message.speed = {
        $case: "updateSpeedPreference",
        updateSpeedPreference: UnitPreferences_SpeedPreference.fromPartial(object.speed.updateSpeedPreference),
      };
    }
    if (
      object.speed?.$case === "removeSpeedPreference" &&
      object.speed?.removeSpeedPreference !== undefined &&
      object.speed?.removeSpeedPreference !== null
    ) {
      message.speed = { $case: "removeSpeedPreference", removeSpeedPreference: object.speed.removeSpeedPreference };
    }
    if (
      object.temperature?.$case === "updateTemperaturePreference" &&
      object.temperature?.updateTemperaturePreference !== undefined &&
      object.temperature?.updateTemperaturePreference !== null
    ) {
      message.temperature = {
        $case: "updateTemperaturePreference",
        updateTemperaturePreference: UnitPreferences_TemperaturePreference.fromPartial(
          object.temperature.updateTemperaturePreference,
        ),
      };
    }
    if (
      object.temperature?.$case === "removeTemperaturePreference" &&
      object.temperature?.removeTemperaturePreference !== undefined &&
      object.temperature?.removeTemperaturePreference !== null
    ) {
      message.temperature = {
        $case: "removeTemperaturePreference",
        removeTemperaturePreference: object.temperature.removeTemperaturePreference,
      };
    }
    if (
      object.voltage?.$case === "updateVoltagePreference" &&
      object.voltage?.updateVoltagePreference !== undefined &&
      object.voltage?.updateVoltagePreference !== null
    ) {
      message.voltage = {
        $case: "updateVoltagePreference",
        updateVoltagePreference: UnitPreferences_VoltagePreference.fromPartial(object.voltage.updateVoltagePreference),
      };
    }
    if (
      object.voltage?.$case === "removeVoltagePreference" &&
      object.voltage?.removeVoltagePreference !== undefined &&
      object.voltage?.removeVoltagePreference !== null
    ) {
      message.voltage = {
        $case: "removeVoltagePreference",
        removeVoltagePreference: object.voltage.removeVoltagePreference,
      };
    }
    if (
      object.volume?.$case === "updateVolumePreference" &&
      object.volume?.updateVolumePreference !== undefined &&
      object.volume?.updateVolumePreference !== null
    ) {
      message.volume = {
        $case: "updateVolumePreference",
        updateVolumePreference: UnitPreferences_VolumePreference.fromPartial(object.volume.updateVolumePreference),
      };
    }
    if (
      object.volume?.$case === "removeVolumePreference" &&
      object.volume?.removeVolumePreference !== undefined &&
      object.volume?.removeVolumePreference !== null
    ) {
      message.volume = {
        $case: "removeVolumePreference",
        removeVolumePreference: object.volume.removeVolumePreference,
      };
    }
    if (
      object.rotationSpeed?.$case === "updateRotationSpeedPreference" &&
      object.rotationSpeed?.updateRotationSpeedPreference !== undefined &&
      object.rotationSpeed?.updateRotationSpeedPreference !== null
    ) {
      message.rotationSpeed = {
        $case: "updateRotationSpeedPreference",
        updateRotationSpeedPreference: UnitPreferences_RotationSpeedPreference.fromPartial(
          object.rotationSpeed.updateRotationSpeedPreference,
        ),
      };
    }
    if (
      object.rotationSpeed?.$case === "removeRotationSpeedPreference" &&
      object.rotationSpeed?.removeRotationSpeedPreference !== undefined &&
      object.rotationSpeed?.removeRotationSpeedPreference !== null
    ) {
      message.rotationSpeed = {
        $case: "removeRotationSpeedPreference",
        removeRotationSpeedPreference: object.rotationSpeed.removeRotationSpeedPreference,
      };
    }
    return message;
  },
};

function createBaseUnitPreferences_Response(): UnitPreferences_Response {
  return { preferences: undefined };
}

export const UnitPreferences_Response = {
  encode(message: UnitPreferences_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preferences !== undefined) {
      UnitPreferences.encode(message.preferences, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnitPreferences_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnitPreferences_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.preferences = UnitPreferences.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnitPreferences_Response>): UnitPreferences_Response {
    return UnitPreferences_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnitPreferences_Response>): UnitPreferences_Response {
    const message = createBaseUnitPreferences_Response();
    message.preferences = (object.preferences !== undefined && object.preferences !== null)
      ? UnitPreferences.fromPartial(object.preferences)
      : undefined;
    return message;
  },
};

export type UnitPreferencesServiceDefinition = typeof UnitPreferencesServiceDefinition;
export const UnitPreferencesServiceDefinition = {
  name: "UnitPreferencesService",
  fullName: "hiber.value.UnitPreferencesService",
  methods: {
    unitPreferences: {
      name: "UnitPreferences",
      requestType: UnitPreferences_Request,
      requestStream: false,
      responseType: UnitPreferences_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
