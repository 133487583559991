/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { AssetSelection } from "./asset";
import { Filter_ChildOrganizations, Pagination, Pagination_Result, Timestamp } from "./base";
import { Struct } from "./google/protobuf/struct";
import { ModemSelection } from "./modem";
import { ModemAlarm, ModemAlarmSelection } from "./modem_alarm";
import { ModemMessageBodyParser, ModemMessageBodyParserSelection } from "./modem_message_body_parser";
import { Tag, TagSelection } from "./tag";

export const protobufPackage = "hiber.assign";

/** The types of assignment available, like assigning a message body parser to a modem. */
export enum AssignmentType {
  DEFAULT = "DEFAULT",
  /** ASSIGNS_MESSAGE_BODY_PARSER_TO_MODEM - Assignment that assigns a message body parser to a modem. */
  ASSIGNS_MESSAGE_BODY_PARSER_TO_MODEM = "ASSIGNS_MESSAGE_BODY_PARSER_TO_MODEM",
  /** ASSIGNS_ALARM_TO_MODEM - Assignment that assigns a modem alarm to a modem. */
  ASSIGNS_ALARM_TO_MODEM = "ASSIGNS_ALARM_TO_MODEM",
  /** ASSIGNS_MODEM_TO_TAG - Assignment that assigns a modem to a tag. */
  ASSIGNS_MODEM_TO_TAG = "ASSIGNS_MODEM_TO_TAG",
  /** ASSIGNS_MESSAGE_BODY_PARSER_TO_TAG - Assignment that assigns a message body parser to a tag, effectively assigning it to all modems with that tag. */
  ASSIGNS_MESSAGE_BODY_PARSER_TO_TAG = "ASSIGNS_MESSAGE_BODY_PARSER_TO_TAG",
  /** ASSIGNS_ALARM_TO_TAG - Assignment that assigns a modem alarm to a tag, effectively assigning it to all modems with that tag. */
  ASSIGNS_ALARM_TO_TAG = "ASSIGNS_ALARM_TO_TAG",
  /** ASSIGNS_ASSET_TO_TAG - Assignment that assigns an asset to a tag. */
  ASSIGNS_ASSET_TO_TAG = "ASSIGNS_ASSET_TO_TAG",
  /** ASSIGNS_ASSET_TO_DEVICE - Assignment that assigns an asset to a device. */
  ASSIGNS_ASSET_TO_DEVICE = "ASSIGNS_ASSET_TO_DEVICE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function assignmentTypeFromJSON(object: any): AssignmentType {
  switch (object) {
    case 0:
    case "DEFAULT":
      return AssignmentType.DEFAULT;
    case 1:
    case "ASSIGNS_MESSAGE_BODY_PARSER_TO_MODEM":
      return AssignmentType.ASSIGNS_MESSAGE_BODY_PARSER_TO_MODEM;
    case 2:
    case "ASSIGNS_ALARM_TO_MODEM":
      return AssignmentType.ASSIGNS_ALARM_TO_MODEM;
    case 4:
    case "ASSIGNS_MODEM_TO_TAG":
      return AssignmentType.ASSIGNS_MODEM_TO_TAG;
    case 5:
    case "ASSIGNS_MESSAGE_BODY_PARSER_TO_TAG":
      return AssignmentType.ASSIGNS_MESSAGE_BODY_PARSER_TO_TAG;
    case 6:
    case "ASSIGNS_ALARM_TO_TAG":
      return AssignmentType.ASSIGNS_ALARM_TO_TAG;
    case 7:
    case "ASSIGNS_ASSET_TO_TAG":
      return AssignmentType.ASSIGNS_ASSET_TO_TAG;
    case 8:
    case "ASSIGNS_ASSET_TO_DEVICE":
      return AssignmentType.ASSIGNS_ASSET_TO_DEVICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssignmentType.UNRECOGNIZED;
  }
}

export function assignmentTypeToNumber(object: AssignmentType): number {
  switch (object) {
    case AssignmentType.DEFAULT:
      return 0;
    case AssignmentType.ASSIGNS_MESSAGE_BODY_PARSER_TO_MODEM:
      return 1;
    case AssignmentType.ASSIGNS_ALARM_TO_MODEM:
      return 2;
    case AssignmentType.ASSIGNS_MODEM_TO_TAG:
      return 4;
    case AssignmentType.ASSIGNS_MESSAGE_BODY_PARSER_TO_TAG:
      return 5;
    case AssignmentType.ASSIGNS_ALARM_TO_TAG:
      return 6;
    case AssignmentType.ASSIGNS_ASSET_TO_TAG:
      return 7;
    case AssignmentType.ASSIGNS_ASSET_TO_DEVICE:
      return 8;
    case AssignmentType.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * An assignment assigning one thing to another.
 *
 * Assignment is transitive: when an alarm or parser is assigned to a tag, and the tag is assigned to a modem,
 *   the alarm or parser is considered assigned to that modem.
 * This only works in one direction, though. Assigning the alarm or parser to the modem directly does not also assign
 * the tag.
 *
 * Assignment can have a custom start and end time.
 * This can extend into the past, but would not have effect in the past for assignments like parsers.
 * It would however work for assets having access to device data.
 * This can also extend into the future, but while the unassigned event will be produced, the assignment will
 * still be visible until the given time.
 *
 * Assignments that are no longer active (end time is in the past) and that no longer have an effect
 * (currently only asset device assignment has an ongoing effect) may be cleaned up automatically.
 */
export interface Assignment {
  /** The type of assignment. This is a helper enum to indicate which fields are set. */
  type: AssignmentType;
  /** Time this assignment started. This is always in the past. */
  start:
    | Timestamp
    | undefined;
  /**
   * Time this assignment ended, if it has ended.
   * Inactive assignments that no longer have an effect may be cleaned up automatically.
   */
  end?: Timestamp | undefined;
  assign?:
    | { $case: "assignParser"; assignParser: Assignment_ModemMessageBodyParserAssignment }
    | { $case: "assignAlarm"; assignAlarm: Assignment_ModemAlarmAssignment }
    | { $case: "assignModem"; assignModem: string }
    | { $case: "assignAsset"; assignAsset: string }
    | undefined;
  to?:
    | { $case: "toModem"; toModem: string }
    | { $case: "toParser"; toParser: Assignment_ModemMessageBodyParserAssignment }
    | { $case: "toTag"; toTag: Tag }
    | { $case: "toAsset"; toAsset: string }
    | undefined;
}

export interface Assignment_ModemAlarmAssignment {
  identifier: string;
  name: string;
  description: string;
  parameters: { [key: string]: any } | undefined;
}

export interface Assignment_ModemMessageBodyParserAssignment {
  identifier: string;
  name: string;
  ownerOrganization: string;
}

export interface AssignmentSelection {
  /** Select the assets to return the assignments for. Optional, when omitted or empty everything is included. */
  assets?:
    | AssetSelection
    | undefined;
  /** Select the modems to return the assignments for. Optional, when omitted or empty everything is included. */
  modems?:
    | ModemSelection
    | undefined;
  /** Select the alarms to return the assignments for. Optional, when omitted or empty everything is included. */
  modemAlarms?:
    | ModemAlarmSelection
    | undefined;
  /**
   * Select the message body parsers to return the assignments for.
   * Optional, when omitted or empty everything is included.
   */
  modemMessageBodyParsers?:
    | ModemMessageBodyParserSelection
    | undefined;
  /** Select the tags to return the assignments for. Optional, when omitted or empty everything is included. */
  tags?:
    | TagSelection
    | undefined;
  /** Select by type of assignment. */
  types?: AssignmentSelection_AssignmentTypes | undefined;
}

export interface AssignmentSelection_AssignmentTypes {
  include: AssignmentType[];
  exclude: AssignmentType[];
}

export interface ListAssignments {
}

export interface ListAssignments_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the assignments to list. Optional, when omitted or empty everything is included. */
  selection?: AssignmentSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Include assignments that are no longer active. */
  includeInactiveAssignments?: boolean | undefined;
}

export interface ListAssignments_Response {
  /** The assignments as identifiers in the selection. */
  assignments: Assignment[];
  request: ListAssignments_Request | undefined;
  pagination: Pagination_Result | undefined;
}

/** Add assignments. */
export interface Assign {
}

export interface Assign_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  assign?:
    | { $case: "assignParsers"; assignParsers: ModemMessageBodyParserSelection }
    | { $case: "assignAlarms"; assignAlarms: ModemAlarmSelection }
    | { $case: "assignModems"; assignModems: ModemSelection }
    | { $case: "assignAssets"; assignAssets: AssetSelection }
    | undefined;
  to?:
    | { $case: "toModems"; toModems: ModemSelection }
    | { $case: "toTags"; toTags: TagSelection }
    | { $case: "toAssets"; toAssets: AssetSelection }
    | undefined;
  /** The alarm parameters, by alarm identifier, if any, overriding any default values in the alarm(s). */
  alarmParameters: { [key: string]: { [key: string]: any } | undefined };
  /**
   * Time that the assignment should be active.
   * This sets the assignment to start in the past, but would not have effect in the past for assignments like
   * parsers and alarms (they will only be triggered for new messages / values).
   * It would however work for assets having access to device data.
   * This is not allowed to be a value in the future at the moment.
   */
  overrideTime?:
    | Timestamp
    | undefined;
  /**
   * Time that the assignment ended.
   * This marks the assignment as ended at the given moment in the past, but would not have effect in the past
   * for assignments like parsers and alarms (i.e. no alarm events are removed).
   * It would however work for assets having access to device data.
   * This is not allowed to be a value in the future at the moment.
   */
  endTime?: Timestamp | undefined;
}

export interface Assign_Request_AlarmParametersEntry {
  key: string;
  value: { [key: string]: any } | undefined;
}

export interface Assign_Response {
  assigned: Assignment[];
  request: Assign_Request | undefined;
}

/** Remove a assignment. */
export interface Unassign {
}

export interface Unassign_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  unassign?:
    | { $case: "unassignParsers"; unassignParsers: ModemMessageBodyParserSelection }
    | { $case: "unassignAlarms"; unassignAlarms: ModemAlarmSelection }
    | { $case: "unassignModems"; unassignModems: ModemSelection }
    | { $case: "unassignAssets"; unassignAssets: AssetSelection }
    | undefined;
  from?:
    | { $case: "fromModems"; fromModems: ModemSelection }
    | { $case: "fromTags"; fromTags: TagSelection }
    | { $case: "fromAssets"; fromAssets: AssetSelection }
    | undefined;
  /**
   * Time that the assignment ended.
   * This marks the assignment as ended at the given moment in the past, but would not have effect in the past
   * for assignments like parsers and alarms (i.e. no alarm events are removed).
   * It would however work for assets having access to device data.
   * This is not allowed to be a value in the future at the moment.
   */
  overrideTime?: Timestamp | undefined;
}

export interface Unassign_Response {
  unassigned: Assignment[];
  request: Unassign_Request | undefined;
}

export interface ListAssetAssignments {
}

export interface ListAssetAssignments_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the assignments to list. Optional, when omitted or empty everything is included. */
  selection?: AssignmentSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Whether to include assets that are in the selection and have no assignments. */
  includeAssetsWithoutAssignments?: boolean | undefined;
}

export interface ListAssetAssignments_Response {
  assets: ListAssetAssignments_Response_AssetAssignment[];
  request: ListAssetAssignments_Request | undefined;
  pagination: Pagination_Result | undefined;
}

/** Things that an alarm is assigned to. */
export interface ListAssetAssignments_Response_AssetAssignment {
  organization: string;
  assetIdentifier: string;
  /** The devices assigned to this asset. */
  devices: string[];
  /** The tags for this asset. */
  tags: Tag[];
}

export interface ListModemAssignments {
}

export interface ListModemAssignments_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the assignments to list. Optional, when omitted or empty everything is included. */
  selection?: AssignmentSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Whether to include the full alarms that are assigned, instead of just assignment. */
  includeAlarmDetails?:
    | boolean
    | undefined;
  /** Whether to include the full parsers that are assigned, instead of just assignment. */
  includeMessageBodyParserDetails?:
    | boolean
    | undefined;
  /**
   * Whether to include, for example, the message body parser ksy content in the result.
   * Excluded by default to save data.
   */
  includeMessageBodyParserContent?:
    | boolean
    | undefined;
  /** Whether to include modems that are in the selection and have no assignments. */
  includeModemsWithoutAssignments?:
    | boolean
    | undefined;
  /**
   * Apply your UnitPreferences to the alarm checks.
   * For example, if a temperature check is configured in kelvin, but your unit preferences specify celsius for
   * temperature, the check value will be converted to celsius instead.
   */
  applyUnitPreferences?: boolean | undefined;
}

export interface ListModemAssignments_Response {
  modems: ListModemAssignments_Response_ModemAssignment[];
  request: ListModemAssignments_Request | undefined;
  pagination: Pagination_Result | undefined;
}

/** Things that are assigned to a modem. */
export interface ListModemAssignments_Response_ModemAssignment {
  organization: string;
  modemNumber: string;
  /** The identifiers of the message body parsers that are assigned to this modem. */
  messageBodyParsers: Assignment_ModemMessageBodyParserAssignment[];
  /** The identifiers and parameters of the alarms that are assigned to this modem. */
  alarms: Assignment_ModemAlarmAssignment[];
  /** The tags for this modem. */
  tags: Tag[];
  /** The details of the parsers assigned to this modem. */
  messageBodyParserDetails: ModemMessageBodyParser[];
  /** The alarms that are assigned to this modem, if you have permission to view them. */
  alarmDetails: ModemAlarm[];
  /** The assets assigned to this device. */
  assetIdentifiers: string[];
}

export interface ListTagAssignments {
}

export interface ListTagAssignments_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the assignments to list. Optional, when omitted or empty everything is included. */
  selection?: AssignmentSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Whether to include the full alarms that are assigned, instead of just assignment. */
  includeAlarmDetails?:
    | boolean
    | undefined;
  /** Whether to include the full parsers that are assigned, instead of just assignment. */
  includeMessageBodyParserDetails?:
    | boolean
    | undefined;
  /**
   * Whether to include, for example, the message body parser ksy content in the result.
   * Excluded by default to save data.
   */
  includeMessageBodyParserContent?:
    | boolean
    | undefined;
  /** Whether to include alarms that are in the selection and have no assignments. */
  includeTagsWithoutAssignments?:
    | boolean
    | undefined;
  /**
   * Apply your UnitPreferences to the alarm checks.
   * For example, if a temperature check is configured in kelvin, but your unit preferences specify celsius for
   * temperature, the check value will be converted to celsius instead.
   */
  applyUnitPreferences?: boolean | undefined;
}

export interface ListTagAssignments_Response {
  tags: ListTagAssignments_Response_TagAssignment[];
  request: ListTagAssignments_Request | undefined;
  pagination: Pagination_Result | undefined;
}

/** Things that an alarm is assigned to. */
export interface ListTagAssignments_Response_TagAssignment {
  organization: string;
  tag:
    | Tag
    | undefined;
  /** The modems with this tag. */
  modems: string[];
  /** The identifiers of the message body parsers that are assigned to this tag. */
  messageBodyParsers: Assignment_ModemMessageBodyParserAssignment[];
  /** The identifiers and parameters of the alarms that are assigned to this tag. */
  alarms: Assignment_ModemAlarmAssignment[];
  /** The details of the parsers assigned to this tag. */
  messageBodyParserDetails: ModemMessageBodyParser[];
  /** The alarms that are assigned to this tag, if you have permission to view them. */
  alarmDetails: ModemAlarm[];
  /** The assets assigned to this tag. */
  assetIdentifiers: string[];
}

export interface ListAlarmAssignments {
}

export interface ListAlarmAssignments_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the assignments to list. Optional, when omitted or empty everything is included. */
  selection?: AssignmentSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Whether to include modems from child organizations in this list (and which organizations). */
  includeChildOrganizations?:
    | Filter_ChildOrganizations
    | undefined;
  /** Whether to include alarms that are in the selection and have no assignments. */
  includeAlarmsWithoutAssignments?:
    | boolean
    | undefined;
  /**
   * Apply your UnitPreferences to the alarm checks.
   * For example, if a temperature check is configured in kelvin, but your unit preferences specify celsius for
   * temperature, the check value will be converted to celsius instead.
   */
  applyUnitPreferences?: boolean | undefined;
}

export interface ListAlarmAssignments_Response {
  alarms: ListAlarmAssignments_Response_AlarmAssignment[];
  request: ListAlarmAssignments_Request | undefined;
  pagination: Pagination_Result | undefined;
}

/** Things that an alarm is assigned to. */
export interface ListAlarmAssignments_Response_AlarmAssignment {
  organization: string;
  alarm:
    | ModemAlarm
    | undefined;
  /** The modem numbers this alarm is assigned to, with the alarm parameters. */
  modems: ListAlarmAssignments_Response_AlarmAssignment_ToModem[];
  /** The tags this alarm is assigned to, with the alarm parameters. */
  tags: ListAlarmAssignments_Response_AlarmAssignment_ToTag[];
}

export interface ListAlarmAssignments_Response_AlarmAssignment_ToModem {
  modemNumber: string;
  parameters: { [key: string]: any } | undefined;
}

export interface ListAlarmAssignments_Response_AlarmAssignment_ToTag {
  tag: Tag | undefined;
  parameters: { [key: string]: any } | undefined;
}

export interface ListModemMessageBodyParserAssignments {
}

export interface ListModemMessageBodyParserAssignments_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the assignments to list. Optional, when omitted or empty everything is included. */
  selection?: AssignmentSelection | undefined;
  pagination?:
    | Pagination
    | undefined;
  /** Whether to include modems from child organizations in this list (and which organizations). */
  includeChildOrganizations?:
    | Filter_ChildOrganizations
    | undefined;
  /** Whether to include alarms that are in the selection and have no assignments. */
  includeParserWithoutAssignments?: boolean | undefined;
}

export interface ListModemMessageBodyParserAssignments_Response {
  parsers: ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment[];
  request: ListModemMessageBodyParserAssignments_Request | undefined;
  pagination: Pagination_Result | undefined;
}

/** Things that an alarm is assigned to. */
export interface ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment {
  organization: string;
  messageBodyParser:
    | ModemMessageBodyParser
    | undefined;
  /** The modem numbers this alarm is assigned to, with the alarm parameters. */
  modems: string[];
  /** The tags this alarm is assigned to, with the alarm parameters. */
  tags: Tag[];
}

function createBaseAssignment(): Assignment {
  return { type: AssignmentType.DEFAULT, start: undefined, end: undefined, assign: undefined, to: undefined };
}

export const Assignment = {
  encode(message: Assignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== AssignmentType.DEFAULT) {
      writer.uint32(8).int32(assignmentTypeToNumber(message.type));
    }
    if (message.start !== undefined) {
      Timestamp.encode(message.start, writer.uint32(74).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Timestamp.encode(message.end, writer.uint32(82).fork()).ldelim();
    }
    switch (message.assign?.$case) {
      case "assignParser":
        Assignment_ModemMessageBodyParserAssignment.encode(message.assign.assignParser, writer.uint32(26).fork())
          .ldelim();
        break;
      case "assignAlarm":
        Assignment_ModemAlarmAssignment.encode(message.assign.assignAlarm, writer.uint32(42).fork()).ldelim();
        break;
      case "assignModem":
        writer.uint32(50).string(message.assign.assignModem);
        break;
      case "assignAsset":
        writer.uint32(66).string(message.assign.assignAsset);
        break;
    }
    switch (message.to?.$case) {
      case "toModem":
        writer.uint32(18).string(message.to.toModem);
        break;
      case "toParser":
        Assignment_ModemMessageBodyParserAssignment.encode(message.to.toParser, writer.uint32(34).fork()).ldelim();
        break;
      case "toTag":
        Tag.encode(message.to.toTag, writer.uint32(58).fork()).ldelim();
        break;
      case "toAsset":
        writer.uint32(90).string(message.to.toAsset);
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = assignmentTypeFromJSON(reader.int32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.start = Timestamp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.end = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.assign = {
            $case: "assignParser",
            assignParser: Assignment_ModemMessageBodyParserAssignment.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.assign = {
            $case: "assignAlarm",
            assignAlarm: Assignment_ModemAlarmAssignment.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.assign = { $case: "assignModem", assignModem: reader.string() };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.assign = { $case: "assignAsset", assignAsset: reader.string() };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = { $case: "toModem", toModem: reader.string() };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.to = {
            $case: "toParser",
            toParser: Assignment_ModemMessageBodyParserAssignment.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.to = { $case: "toTag", toTag: Tag.decode(reader, reader.uint32()) };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.to = { $case: "toAsset", toAsset: reader.string() };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Assignment>): Assignment {
    return Assignment.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Assignment>): Assignment {
    const message = createBaseAssignment();
    message.type = object.type ?? AssignmentType.DEFAULT;
    message.start = (object.start !== undefined && object.start !== null)
      ? Timestamp.fromPartial(object.start)
      : undefined;
    message.end = (object.end !== undefined && object.end !== null) ? Timestamp.fromPartial(object.end) : undefined;
    if (
      object.assign?.$case === "assignParser" &&
      object.assign?.assignParser !== undefined &&
      object.assign?.assignParser !== null
    ) {
      message.assign = {
        $case: "assignParser",
        assignParser: Assignment_ModemMessageBodyParserAssignment.fromPartial(object.assign.assignParser),
      };
    }
    if (
      object.assign?.$case === "assignAlarm" &&
      object.assign?.assignAlarm !== undefined &&
      object.assign?.assignAlarm !== null
    ) {
      message.assign = {
        $case: "assignAlarm",
        assignAlarm: Assignment_ModemAlarmAssignment.fromPartial(object.assign.assignAlarm),
      };
    }
    if (
      object.assign?.$case === "assignModem" &&
      object.assign?.assignModem !== undefined &&
      object.assign?.assignModem !== null
    ) {
      message.assign = { $case: "assignModem", assignModem: object.assign.assignModem };
    }
    if (
      object.assign?.$case === "assignAsset" &&
      object.assign?.assignAsset !== undefined &&
      object.assign?.assignAsset !== null
    ) {
      message.assign = { $case: "assignAsset", assignAsset: object.assign.assignAsset };
    }
    if (object.to?.$case === "toModem" && object.to?.toModem !== undefined && object.to?.toModem !== null) {
      message.to = { $case: "toModem", toModem: object.to.toModem };
    }
    if (object.to?.$case === "toParser" && object.to?.toParser !== undefined && object.to?.toParser !== null) {
      message.to = {
        $case: "toParser",
        toParser: Assignment_ModemMessageBodyParserAssignment.fromPartial(object.to.toParser),
      };
    }
    if (object.to?.$case === "toTag" && object.to?.toTag !== undefined && object.to?.toTag !== null) {
      message.to = { $case: "toTag", toTag: Tag.fromPartial(object.to.toTag) };
    }
    if (object.to?.$case === "toAsset" && object.to?.toAsset !== undefined && object.to?.toAsset !== null) {
      message.to = { $case: "toAsset", toAsset: object.to.toAsset };
    }
    return message;
  },
};

function createBaseAssignment_ModemAlarmAssignment(): Assignment_ModemAlarmAssignment {
  return { identifier: "", name: "", description: "", parameters: undefined };
}

export const Assignment_ModemAlarmAssignment = {
  encode(message: Assignment_ModemAlarmAssignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.parameters !== undefined) {
      Struct.encode(Struct.wrap(message.parameters), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assignment_ModemAlarmAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignment_ModemAlarmAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parameters = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Assignment_ModemAlarmAssignment>): Assignment_ModemAlarmAssignment {
    return Assignment_ModemAlarmAssignment.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Assignment_ModemAlarmAssignment>): Assignment_ModemAlarmAssignment {
    const message = createBaseAssignment_ModemAlarmAssignment();
    message.identifier = object.identifier ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.parameters = object.parameters ?? undefined;
    return message;
  },
};

function createBaseAssignment_ModemMessageBodyParserAssignment(): Assignment_ModemMessageBodyParserAssignment {
  return { identifier: "", name: "", ownerOrganization: "" };
}

export const Assignment_ModemMessageBodyParserAssignment = {
  encode(message: Assignment_ModemMessageBodyParserAssignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.ownerOrganization !== "") {
      writer.uint32(26).string(message.ownerOrganization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assignment_ModemMessageBodyParserAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignment_ModemMessageBodyParserAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ownerOrganization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Assignment_ModemMessageBodyParserAssignment>): Assignment_ModemMessageBodyParserAssignment {
    return Assignment_ModemMessageBodyParserAssignment.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<Assignment_ModemMessageBodyParserAssignment>,
  ): Assignment_ModemMessageBodyParserAssignment {
    const message = createBaseAssignment_ModemMessageBodyParserAssignment();
    message.identifier = object.identifier ?? "";
    message.name = object.name ?? "";
    message.ownerOrganization = object.ownerOrganization ?? "";
    return message;
  },
};

function createBaseAssignmentSelection(): AssignmentSelection {
  return {
    assets: undefined,
    modems: undefined,
    modemAlarms: undefined,
    modemMessageBodyParsers: undefined,
    tags: undefined,
    types: undefined,
  };
}

export const AssignmentSelection = {
  encode(message: AssignmentSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.assets !== undefined) {
      AssetSelection.encode(message.assets, writer.uint32(50).fork()).ldelim();
    }
    if (message.modems !== undefined) {
      ModemSelection.encode(message.modems, writer.uint32(10).fork()).ldelim();
    }
    if (message.modemAlarms !== undefined) {
      ModemAlarmSelection.encode(message.modemAlarms, writer.uint32(18).fork()).ldelim();
    }
    if (message.modemMessageBodyParsers !== undefined) {
      ModemMessageBodyParserSelection.encode(message.modemMessageBodyParsers, writer.uint32(26).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      TagSelection.encode(message.tags, writer.uint32(42).fork()).ldelim();
    }
    if (message.types !== undefined) {
      AssignmentSelection_AssignmentTypes.encode(message.types, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignmentSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignmentSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 50) {
            break;
          }

          message.assets = AssetSelection.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemAlarms = ModemAlarmSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modemMessageBodyParsers = ModemMessageBodyParserSelection.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags = TagSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.types = AssignmentSelection_AssignmentTypes.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignmentSelection>): AssignmentSelection {
    return AssignmentSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignmentSelection>): AssignmentSelection {
    const message = createBaseAssignmentSelection();
    message.assets = (object.assets !== undefined && object.assets !== null)
      ? AssetSelection.fromPartial(object.assets)
      : undefined;
    message.modems = (object.modems !== undefined && object.modems !== null)
      ? ModemSelection.fromPartial(object.modems)
      : undefined;
    message.modemAlarms = (object.modemAlarms !== undefined && object.modemAlarms !== null)
      ? ModemAlarmSelection.fromPartial(object.modemAlarms)
      : undefined;
    message.modemMessageBodyParsers =
      (object.modemMessageBodyParsers !== undefined && object.modemMessageBodyParsers !== null)
        ? ModemMessageBodyParserSelection.fromPartial(object.modemMessageBodyParsers)
        : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagSelection.fromPartial(object.tags)
      : undefined;
    message.types = (object.types !== undefined && object.types !== null)
      ? AssignmentSelection_AssignmentTypes.fromPartial(object.types)
      : undefined;
    return message;
  },
};

function createBaseAssignmentSelection_AssignmentTypes(): AssignmentSelection_AssignmentTypes {
  return { include: [], exclude: [] };
}

export const AssignmentSelection_AssignmentTypes = {
  encode(message: AssignmentSelection_AssignmentTypes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.include) {
      writer.int32(assignmentTypeToNumber(v));
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.exclude) {
      writer.int32(assignmentTypeToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignmentSelection_AssignmentTypes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignmentSelection_AssignmentTypes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.include.push(assignmentTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.include.push(assignmentTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.exclude.push(assignmentTypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exclude.push(assignmentTypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignmentSelection_AssignmentTypes>): AssignmentSelection_AssignmentTypes {
    return AssignmentSelection_AssignmentTypes.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignmentSelection_AssignmentTypes>): AssignmentSelection_AssignmentTypes {
    const message = createBaseAssignmentSelection_AssignmentTypes();
    message.include = object.include?.map((e) => e) || [];
    message.exclude = object.exclude?.map((e) => e) || [];
    return message;
  },
};

function createBaseListAssignments(): ListAssignments {
  return {};
}

export const ListAssignments = {
  encode(_: ListAssignments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAssignments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssignments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAssignments>): ListAssignments {
    return ListAssignments.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListAssignments>): ListAssignments {
    const message = createBaseListAssignments();
    return message;
  },
};

function createBaseListAssignments_Request(): ListAssignments_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    includeInactiveAssignments: undefined,
  };
}

export const ListAssignments_Request = {
  encode(message: ListAssignments_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssignmentSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeInactiveAssignments !== undefined) {
      writer.uint32(32).bool(message.includeInactiveAssignments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAssignments_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssignments_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssignmentSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeInactiveAssignments = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAssignments_Request>): ListAssignments_Request {
    return ListAssignments_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAssignments_Request>): ListAssignments_Request {
    const message = createBaseListAssignments_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssignmentSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeInactiveAssignments = object.includeInactiveAssignments ?? undefined;
    return message;
  },
};

function createBaseListAssignments_Response(): ListAssignments_Response {
  return { assignments: [], request: undefined, pagination: undefined };
}

export const ListAssignments_Response = {
  encode(message: ListAssignments_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assignments) {
      Assignment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListAssignments_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAssignments_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssignments_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assignments.push(Assignment.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListAssignments_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAssignments_Response>): ListAssignments_Response {
    return ListAssignments_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAssignments_Response>): ListAssignments_Response {
    const message = createBaseListAssignments_Response();
    message.assignments = object.assignments?.map((e) => Assignment.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListAssignments_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseAssign(): Assign {
  return {};
}

export const Assign = {
  encode(_: Assign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assign {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssign();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Assign>): Assign {
    return Assign.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Assign>): Assign {
    const message = createBaseAssign();
    return message;
  },
};

function createBaseAssign_Request(): Assign_Request {
  return {
    organization: undefined,
    assign: undefined,
    to: undefined,
    alarmParameters: {},
    overrideTime: undefined,
    endTime: undefined,
  };
}

export const Assign_Request = {
  encode(message: Assign_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    switch (message.assign?.$case) {
      case "assignParsers":
        ModemMessageBodyParserSelection.encode(message.assign.assignParsers, writer.uint32(18).fork()).ldelim();
        break;
      case "assignAlarms":
        ModemAlarmSelection.encode(message.assign.assignAlarms, writer.uint32(34).fork()).ldelim();
        break;
      case "assignModems":
        ModemSelection.encode(message.assign.assignModems, writer.uint32(58).fork()).ldelim();
        break;
      case "assignAssets":
        AssetSelection.encode(message.assign.assignAssets, writer.uint32(74).fork()).ldelim();
        break;
    }
    switch (message.to?.$case) {
      case "toModems":
        ModemSelection.encode(message.to.toModems, writer.uint32(42).fork()).ldelim();
        break;
      case "toTags":
        TagSelection.encode(message.to.toTags, writer.uint32(66).fork()).ldelim();
        break;
      case "toAssets":
        AssetSelection.encode(message.to.toAssets, writer.uint32(98).fork()).ldelim();
        break;
    }
    Object.entries(message.alarmParameters).forEach(([key, value]) => {
      if (value !== undefined) {
        Assign_Request_AlarmParametersEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim();
      }
    });
    if (message.overrideTime !== undefined) {
      Timestamp.encode(message.overrideTime, writer.uint32(82).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(message.endTime, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assign_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssign_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assign = {
            $case: "assignParsers",
            assignParsers: ModemMessageBodyParserSelection.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.assign = { $case: "assignAlarms", assignAlarms: ModemAlarmSelection.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.assign = { $case: "assignModems", assignModems: ModemSelection.decode(reader, reader.uint32()) };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.assign = { $case: "assignAssets", assignAssets: AssetSelection.decode(reader, reader.uint32()) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.to = { $case: "toModems", toModems: ModemSelection.decode(reader, reader.uint32()) };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.to = { $case: "toTags", toTags: TagSelection.decode(reader, reader.uint32()) };
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.to = { $case: "toAssets", toAssets: AssetSelection.decode(reader, reader.uint32()) };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = Assign_Request_AlarmParametersEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.alarmParameters[entry6.key] = entry6.value;
          }
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.overrideTime = Timestamp.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.endTime = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Assign_Request>): Assign_Request {
    return Assign_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Assign_Request>): Assign_Request {
    const message = createBaseAssign_Request();
    message.organization = object.organization ?? undefined;
    if (
      object.assign?.$case === "assignParsers" &&
      object.assign?.assignParsers !== undefined &&
      object.assign?.assignParsers !== null
    ) {
      message.assign = {
        $case: "assignParsers",
        assignParsers: ModemMessageBodyParserSelection.fromPartial(object.assign.assignParsers),
      };
    }
    if (
      object.assign?.$case === "assignAlarms" &&
      object.assign?.assignAlarms !== undefined &&
      object.assign?.assignAlarms !== null
    ) {
      message.assign = {
        $case: "assignAlarms",
        assignAlarms: ModemAlarmSelection.fromPartial(object.assign.assignAlarms),
      };
    }
    if (
      object.assign?.$case === "assignModems" &&
      object.assign?.assignModems !== undefined &&
      object.assign?.assignModems !== null
    ) {
      message.assign = { $case: "assignModems", assignModems: ModemSelection.fromPartial(object.assign.assignModems) };
    }
    if (
      object.assign?.$case === "assignAssets" &&
      object.assign?.assignAssets !== undefined &&
      object.assign?.assignAssets !== null
    ) {
      message.assign = { $case: "assignAssets", assignAssets: AssetSelection.fromPartial(object.assign.assignAssets) };
    }
    if (object.to?.$case === "toModems" && object.to?.toModems !== undefined && object.to?.toModems !== null) {
      message.to = { $case: "toModems", toModems: ModemSelection.fromPartial(object.to.toModems) };
    }
    if (object.to?.$case === "toTags" && object.to?.toTags !== undefined && object.to?.toTags !== null) {
      message.to = { $case: "toTags", toTags: TagSelection.fromPartial(object.to.toTags) };
    }
    if (object.to?.$case === "toAssets" && object.to?.toAssets !== undefined && object.to?.toAssets !== null) {
      message.to = { $case: "toAssets", toAssets: AssetSelection.fromPartial(object.to.toAssets) };
    }
    message.alarmParameters = Object.entries(object.alarmParameters ?? {}).reduce<
      { [key: string]: { [key: string]: any } | undefined }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    message.overrideTime = (object.overrideTime !== undefined && object.overrideTime !== null)
      ? Timestamp.fromPartial(object.overrideTime)
      : undefined;
    message.endTime = (object.endTime !== undefined && object.endTime !== null)
      ? Timestamp.fromPartial(object.endTime)
      : undefined;
    return message;
  },
};

function createBaseAssign_Request_AlarmParametersEntry(): Assign_Request_AlarmParametersEntry {
  return { key: "", value: undefined };
}

export const Assign_Request_AlarmParametersEntry = {
  encode(message: Assign_Request_AlarmParametersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Struct.encode(Struct.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assign_Request_AlarmParametersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssign_Request_AlarmParametersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Assign_Request_AlarmParametersEntry>): Assign_Request_AlarmParametersEntry {
    return Assign_Request_AlarmParametersEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Assign_Request_AlarmParametersEntry>): Assign_Request_AlarmParametersEntry {
    const message = createBaseAssign_Request_AlarmParametersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseAssign_Response(): Assign_Response {
  return { assigned: [], request: undefined };
}

export const Assign_Response = {
  encode(message: Assign_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assigned) {
      Assignment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      Assign_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assign_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssign_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assigned.push(Assignment.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = Assign_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Assign_Response>): Assign_Response {
    return Assign_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Assign_Response>): Assign_Response {
    const message = createBaseAssign_Response();
    message.assigned = object.assigned?.map((e) => Assignment.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? Assign_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUnassign(): Unassign {
  return {};
}

export const Unassign = {
  encode(_: Unassign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Unassign {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassign();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Unassign>): Unassign {
    return Unassign.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<Unassign>): Unassign {
    const message = createBaseUnassign();
    return message;
  },
};

function createBaseUnassign_Request(): Unassign_Request {
  return { organization: undefined, unassign: undefined, from: undefined, overrideTime: undefined };
}

export const Unassign_Request = {
  encode(message: Unassign_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    switch (message.unassign?.$case) {
      case "unassignParsers":
        ModemMessageBodyParserSelection.encode(message.unassign.unassignParsers, writer.uint32(18).fork()).ldelim();
        break;
      case "unassignAlarms":
        ModemAlarmSelection.encode(message.unassign.unassignAlarms, writer.uint32(34).fork()).ldelim();
        break;
      case "unassignModems":
        ModemSelection.encode(message.unassign.unassignModems, writer.uint32(50).fork()).ldelim();
        break;
      case "unassignAssets":
        AssetSelection.encode(message.unassign.unassignAssets, writer.uint32(74).fork()).ldelim();
        break;
    }
    switch (message.from?.$case) {
      case "fromModems":
        ModemSelection.encode(message.from.fromModems, writer.uint32(42).fork()).ldelim();
        break;
      case "fromTags":
        TagSelection.encode(message.from.fromTags, writer.uint32(58).fork()).ldelim();
        break;
      case "fromAssets":
        AssetSelection.encode(message.from.fromAssets, writer.uint32(90).fork()).ldelim();
        break;
    }
    if (message.overrideTime !== undefined) {
      Timestamp.encode(message.overrideTime, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Unassign_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassign_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.unassign = {
            $case: "unassignParsers",
            unassignParsers: ModemMessageBodyParserSelection.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.unassign = {
            $case: "unassignAlarms",
            unassignAlarms: ModemAlarmSelection.decode(reader, reader.uint32()),
          };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.unassign = {
            $case: "unassignModems",
            unassignModems: ModemSelection.decode(reader, reader.uint32()),
          };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.unassign = {
            $case: "unassignAssets",
            unassignAssets: AssetSelection.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.from = { $case: "fromModems", fromModems: ModemSelection.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.from = { $case: "fromTags", fromTags: TagSelection.decode(reader, reader.uint32()) };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.from = { $case: "fromAssets", fromAssets: AssetSelection.decode(reader, reader.uint32()) };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.overrideTime = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Unassign_Request>): Unassign_Request {
    return Unassign_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Unassign_Request>): Unassign_Request {
    const message = createBaseUnassign_Request();
    message.organization = object.organization ?? undefined;
    if (
      object.unassign?.$case === "unassignParsers" &&
      object.unassign?.unassignParsers !== undefined &&
      object.unassign?.unassignParsers !== null
    ) {
      message.unassign = {
        $case: "unassignParsers",
        unassignParsers: ModemMessageBodyParserSelection.fromPartial(object.unassign.unassignParsers),
      };
    }
    if (
      object.unassign?.$case === "unassignAlarms" &&
      object.unassign?.unassignAlarms !== undefined &&
      object.unassign?.unassignAlarms !== null
    ) {
      message.unassign = {
        $case: "unassignAlarms",
        unassignAlarms: ModemAlarmSelection.fromPartial(object.unassign.unassignAlarms),
      };
    }
    if (
      object.unassign?.$case === "unassignModems" &&
      object.unassign?.unassignModems !== undefined &&
      object.unassign?.unassignModems !== null
    ) {
      message.unassign = {
        $case: "unassignModems",
        unassignModems: ModemSelection.fromPartial(object.unassign.unassignModems),
      };
    }
    if (
      object.unassign?.$case === "unassignAssets" &&
      object.unassign?.unassignAssets !== undefined &&
      object.unassign?.unassignAssets !== null
    ) {
      message.unassign = {
        $case: "unassignAssets",
        unassignAssets: AssetSelection.fromPartial(object.unassign.unassignAssets),
      };
    }
    if (
      object.from?.$case === "fromModems" && object.from?.fromModems !== undefined && object.from?.fromModems !== null
    ) {
      message.from = { $case: "fromModems", fromModems: ModemSelection.fromPartial(object.from.fromModems) };
    }
    if (object.from?.$case === "fromTags" && object.from?.fromTags !== undefined && object.from?.fromTags !== null) {
      message.from = { $case: "fromTags", fromTags: TagSelection.fromPartial(object.from.fromTags) };
    }
    if (
      object.from?.$case === "fromAssets" && object.from?.fromAssets !== undefined && object.from?.fromAssets !== null
    ) {
      message.from = { $case: "fromAssets", fromAssets: AssetSelection.fromPartial(object.from.fromAssets) };
    }
    message.overrideTime = (object.overrideTime !== undefined && object.overrideTime !== null)
      ? Timestamp.fromPartial(object.overrideTime)
      : undefined;
    return message;
  },
};

function createBaseUnassign_Response(): Unassign_Response {
  return { unassigned: [], request: undefined };
}

export const Unassign_Response = {
  encode(message: Unassign_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.unassigned) {
      Assignment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      Unassign_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Unassign_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassign_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.unassigned.push(Assignment.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = Unassign_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Unassign_Response>): Unassign_Response {
    return Unassign_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Unassign_Response>): Unassign_Response {
    const message = createBaseUnassign_Response();
    message.unassigned = object.unassigned?.map((e) => Assignment.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? Unassign_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseListAssetAssignments(): ListAssetAssignments {
  return {};
}

export const ListAssetAssignments = {
  encode(_: ListAssetAssignments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAssetAssignments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssetAssignments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAssetAssignments>): ListAssetAssignments {
    return ListAssetAssignments.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListAssetAssignments>): ListAssetAssignments {
    const message = createBaseListAssetAssignments();
    return message;
  },
};

function createBaseListAssetAssignments_Request(): ListAssetAssignments_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    includeAssetsWithoutAssignments: undefined,
  };
}

export const ListAssetAssignments_Request = {
  encode(message: ListAssetAssignments_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssignmentSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeAssetsWithoutAssignments !== undefined) {
      writer.uint32(32).bool(message.includeAssetsWithoutAssignments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAssetAssignments_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssetAssignments_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssignmentSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeAssetsWithoutAssignments = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAssetAssignments_Request>): ListAssetAssignments_Request {
    return ListAssetAssignments_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAssetAssignments_Request>): ListAssetAssignments_Request {
    const message = createBaseListAssetAssignments_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssignmentSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeAssetsWithoutAssignments = object.includeAssetsWithoutAssignments ?? undefined;
    return message;
  },
};

function createBaseListAssetAssignments_Response(): ListAssetAssignments_Response {
  return { assets: [], request: undefined, pagination: undefined };
}

export const ListAssetAssignments_Response = {
  encode(message: ListAssetAssignments_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assets) {
      ListAssetAssignments_Response_AssetAssignment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListAssetAssignments_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAssetAssignments_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssetAssignments_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assets.push(ListAssetAssignments_Response_AssetAssignment.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListAssetAssignments_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAssetAssignments_Response>): ListAssetAssignments_Response {
    return ListAssetAssignments_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAssetAssignments_Response>): ListAssetAssignments_Response {
    const message = createBaseListAssetAssignments_Response();
    message.assets = object.assets?.map((e) => ListAssetAssignments_Response_AssetAssignment.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListAssetAssignments_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListAssetAssignments_Response_AssetAssignment(): ListAssetAssignments_Response_AssetAssignment {
  return { organization: "", assetIdentifier: "", devices: [], tags: [] };
}

export const ListAssetAssignments_Response_AssetAssignment = {
  encode(message: ListAssetAssignments_Response_AssetAssignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.assetIdentifier !== "") {
      writer.uint32(18).string(message.assetIdentifier);
    }
    for (const v of message.devices) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAssetAssignments_Response_AssetAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssetAssignments_Response_AssetAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assetIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.devices.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListAssetAssignments_Response_AssetAssignment>,
  ): ListAssetAssignments_Response_AssetAssignment {
    return ListAssetAssignments_Response_AssetAssignment.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListAssetAssignments_Response_AssetAssignment>,
  ): ListAssetAssignments_Response_AssetAssignment {
    const message = createBaseListAssetAssignments_Response_AssetAssignment();
    message.organization = object.organization ?? "";
    message.assetIdentifier = object.assetIdentifier ?? "";
    message.devices = object.devices?.map((e) => e) || [];
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListModemAssignments(): ListModemAssignments {
  return {};
}

export const ListModemAssignments = {
  encode(_: ListModemAssignments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemAssignments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemAssignments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemAssignments>): ListModemAssignments {
    return ListModemAssignments.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListModemAssignments>): ListModemAssignments {
    const message = createBaseListModemAssignments();
    return message;
  },
};

function createBaseListModemAssignments_Request(): ListModemAssignments_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    includeAlarmDetails: undefined,
    includeMessageBodyParserDetails: undefined,
    includeMessageBodyParserContent: undefined,
    includeModemsWithoutAssignments: undefined,
    applyUnitPreferences: undefined,
  };
}

export const ListModemAssignments_Request = {
  encode(message: ListModemAssignments_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssignmentSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeAlarmDetails !== undefined) {
      writer.uint32(32).bool(message.includeAlarmDetails);
    }
    if (message.includeMessageBodyParserDetails !== undefined) {
      writer.uint32(40).bool(message.includeMessageBodyParserDetails);
    }
    if (message.includeMessageBodyParserContent !== undefined) {
      writer.uint32(48).bool(message.includeMessageBodyParserContent);
    }
    if (message.includeModemsWithoutAssignments !== undefined) {
      writer.uint32(64).bool(message.includeModemsWithoutAssignments);
    }
    if (message.applyUnitPreferences !== undefined) {
      writer.uint32(72).bool(message.applyUnitPreferences);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemAssignments_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemAssignments_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssignmentSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeAlarmDetails = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMessageBodyParserDetails = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.includeMessageBodyParserContent = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.includeModemsWithoutAssignments = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.applyUnitPreferences = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemAssignments_Request>): ListModemAssignments_Request {
    return ListModemAssignments_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemAssignments_Request>): ListModemAssignments_Request {
    const message = createBaseListModemAssignments_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssignmentSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeAlarmDetails = object.includeAlarmDetails ?? undefined;
    message.includeMessageBodyParserDetails = object.includeMessageBodyParserDetails ?? undefined;
    message.includeMessageBodyParserContent = object.includeMessageBodyParserContent ?? undefined;
    message.includeModemsWithoutAssignments = object.includeModemsWithoutAssignments ?? undefined;
    message.applyUnitPreferences = object.applyUnitPreferences ?? undefined;
    return message;
  },
};

function createBaseListModemAssignments_Response(): ListModemAssignments_Response {
  return { modems: [], request: undefined, pagination: undefined };
}

export const ListModemAssignments_Response = {
  encode(message: ListModemAssignments_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modems) {
      ListModemAssignments_Response_ModemAssignment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListModemAssignments_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemAssignments_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemAssignments_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modems.push(ListModemAssignments_Response_ModemAssignment.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListModemAssignments_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemAssignments_Response>): ListModemAssignments_Response {
    return ListModemAssignments_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListModemAssignments_Response>): ListModemAssignments_Response {
    const message = createBaseListModemAssignments_Response();
    message.modems = object.modems?.map((e) => ListModemAssignments_Response_ModemAssignment.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListModemAssignments_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListModemAssignments_Response_ModemAssignment(): ListModemAssignments_Response_ModemAssignment {
  return {
    organization: "",
    modemNumber: "",
    messageBodyParsers: [],
    alarms: [],
    tags: [],
    messageBodyParserDetails: [],
    alarmDetails: [],
    assetIdentifiers: [],
  };
}

export const ListModemAssignments_Response_ModemAssignment = {
  encode(message: ListModemAssignments_Response_ModemAssignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.modemNumber !== "") {
      writer.uint32(18).string(message.modemNumber);
    }
    for (const v of message.messageBodyParsers) {
      Assignment_ModemMessageBodyParserAssignment.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.alarms) {
      Assignment_ModemAlarmAssignment.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.messageBodyParserDetails) {
      ModemMessageBodyParser.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.alarmDetails) {
      ModemAlarm.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.assetIdentifiers) {
      writer.uint32(74).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemAssignments_Response_ModemAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemAssignments_Response_ModemAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.messageBodyParsers.push(Assignment_ModemMessageBodyParserAssignment.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.alarms.push(Assignment_ModemAlarmAssignment.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.messageBodyParserDetails.push(ModemMessageBodyParser.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.alarmDetails.push(ModemAlarm.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.assetIdentifiers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListModemAssignments_Response_ModemAssignment>,
  ): ListModemAssignments_Response_ModemAssignment {
    return ListModemAssignments_Response_ModemAssignment.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListModemAssignments_Response_ModemAssignment>,
  ): ListModemAssignments_Response_ModemAssignment {
    const message = createBaseListModemAssignments_Response_ModemAssignment();
    message.organization = object.organization ?? "";
    message.modemNumber = object.modemNumber ?? "";
    message.messageBodyParsers =
      object.messageBodyParsers?.map((e) => Assignment_ModemMessageBodyParserAssignment.fromPartial(e)) || [];
    message.alarms = object.alarms?.map((e) => Assignment_ModemAlarmAssignment.fromPartial(e)) || [];
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.messageBodyParserDetails =
      object.messageBodyParserDetails?.map((e) => ModemMessageBodyParser.fromPartial(e)) || [];
    message.alarmDetails = object.alarmDetails?.map((e) => ModemAlarm.fromPartial(e)) || [];
    message.assetIdentifiers = object.assetIdentifiers?.map((e) => e) || [];
    return message;
  },
};

function createBaseListTagAssignments(): ListTagAssignments {
  return {};
}

export const ListTagAssignments = {
  encode(_: ListTagAssignments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagAssignments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagAssignments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTagAssignments>): ListTagAssignments {
    return ListTagAssignments.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListTagAssignments>): ListTagAssignments {
    const message = createBaseListTagAssignments();
    return message;
  },
};

function createBaseListTagAssignments_Request(): ListTagAssignments_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    includeAlarmDetails: undefined,
    includeMessageBodyParserDetails: undefined,
    includeMessageBodyParserContent: undefined,
    includeTagsWithoutAssignments: undefined,
    applyUnitPreferences: undefined,
  };
}

export const ListTagAssignments_Request = {
  encode(message: ListTagAssignments_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssignmentSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeAlarmDetails !== undefined) {
      writer.uint32(32).bool(message.includeAlarmDetails);
    }
    if (message.includeMessageBodyParserDetails !== undefined) {
      writer.uint32(40).bool(message.includeMessageBodyParserDetails);
    }
    if (message.includeMessageBodyParserContent !== undefined) {
      writer.uint32(48).bool(message.includeMessageBodyParserContent);
    }
    if (message.includeTagsWithoutAssignments !== undefined) {
      writer.uint32(64).bool(message.includeTagsWithoutAssignments);
    }
    if (message.applyUnitPreferences !== undefined) {
      writer.uint32(72).bool(message.applyUnitPreferences);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagAssignments_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagAssignments_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssignmentSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeAlarmDetails = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMessageBodyParserDetails = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.includeMessageBodyParserContent = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.includeTagsWithoutAssignments = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.applyUnitPreferences = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTagAssignments_Request>): ListTagAssignments_Request {
    return ListTagAssignments_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTagAssignments_Request>): ListTagAssignments_Request {
    const message = createBaseListTagAssignments_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssignmentSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeAlarmDetails = object.includeAlarmDetails ?? undefined;
    message.includeMessageBodyParserDetails = object.includeMessageBodyParserDetails ?? undefined;
    message.includeMessageBodyParserContent = object.includeMessageBodyParserContent ?? undefined;
    message.includeTagsWithoutAssignments = object.includeTagsWithoutAssignments ?? undefined;
    message.applyUnitPreferences = object.applyUnitPreferences ?? undefined;
    return message;
  },
};

function createBaseListTagAssignments_Response(): ListTagAssignments_Response {
  return { tags: [], request: undefined, pagination: undefined };
}

export const ListTagAssignments_Response = {
  encode(message: ListTagAssignments_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tags) {
      ListTagAssignments_Response_TagAssignment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListTagAssignments_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagAssignments_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagAssignments_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tags.push(ListTagAssignments_Response_TagAssignment.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListTagAssignments_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTagAssignments_Response>): ListTagAssignments_Response {
    return ListTagAssignments_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTagAssignments_Response>): ListTagAssignments_Response {
    const message = createBaseListTagAssignments_Response();
    message.tags = object.tags?.map((e) => ListTagAssignments_Response_TagAssignment.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListTagAssignments_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListTagAssignments_Response_TagAssignment(): ListTagAssignments_Response_TagAssignment {
  return {
    organization: "",
    tag: undefined,
    modems: [],
    messageBodyParsers: [],
    alarms: [],
    messageBodyParserDetails: [],
    alarmDetails: [],
    assetIdentifiers: [],
  };
}

export const ListTagAssignments_Response_TagAssignment = {
  encode(message: ListTagAssignments_Response_TagAssignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.tag !== undefined) {
      Tag.encode(message.tag, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.modems) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.messageBodyParsers) {
      Assignment_ModemMessageBodyParserAssignment.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.alarms) {
      Assignment_ModemAlarmAssignment.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.messageBodyParserDetails) {
      ModemMessageBodyParser.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.alarmDetails) {
      ModemAlarm.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.assetIdentifiers) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagAssignments_Response_TagAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagAssignments_Response_TagAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tag = Tag.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modems.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.messageBodyParsers.push(Assignment_ModemMessageBodyParserAssignment.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.alarms.push(Assignment_ModemAlarmAssignment.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.messageBodyParserDetails.push(ModemMessageBodyParser.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.alarmDetails.push(ModemAlarm.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.assetIdentifiers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTagAssignments_Response_TagAssignment>): ListTagAssignments_Response_TagAssignment {
    return ListTagAssignments_Response_TagAssignment.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListTagAssignments_Response_TagAssignment>,
  ): ListTagAssignments_Response_TagAssignment {
    const message = createBaseListTagAssignments_Response_TagAssignment();
    message.organization = object.organization ?? "";
    message.tag = (object.tag !== undefined && object.tag !== null) ? Tag.fromPartial(object.tag) : undefined;
    message.modems = object.modems?.map((e) => e) || [];
    message.messageBodyParsers =
      object.messageBodyParsers?.map((e) => Assignment_ModemMessageBodyParserAssignment.fromPartial(e)) || [];
    message.alarms = object.alarms?.map((e) => Assignment_ModemAlarmAssignment.fromPartial(e)) || [];
    message.messageBodyParserDetails =
      object.messageBodyParserDetails?.map((e) => ModemMessageBodyParser.fromPartial(e)) || [];
    message.alarmDetails = object.alarmDetails?.map((e) => ModemAlarm.fromPartial(e)) || [];
    message.assetIdentifiers = object.assetIdentifiers?.map((e) => e) || [];
    return message;
  },
};

function createBaseListAlarmAssignments(): ListAlarmAssignments {
  return {};
}

export const ListAlarmAssignments = {
  encode(_: ListAlarmAssignments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAlarmAssignments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAlarmAssignments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAlarmAssignments>): ListAlarmAssignments {
    return ListAlarmAssignments.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListAlarmAssignments>): ListAlarmAssignments {
    const message = createBaseListAlarmAssignments();
    return message;
  },
};

function createBaseListAlarmAssignments_Request(): ListAlarmAssignments_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    includeChildOrganizations: undefined,
    includeAlarmsWithoutAssignments: undefined,
    applyUnitPreferences: undefined,
  };
}

export const ListAlarmAssignments_Request = {
  encode(message: ListAlarmAssignments_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssignmentSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeChildOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.includeChildOrganizations, writer.uint32(34).fork()).ldelim();
    }
    if (message.includeAlarmsWithoutAssignments !== undefined) {
      writer.uint32(40).bool(message.includeAlarmsWithoutAssignments);
    }
    if (message.applyUnitPreferences !== undefined) {
      writer.uint32(48).bool(message.applyUnitPreferences);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAlarmAssignments_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAlarmAssignments_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssignmentSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.includeChildOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeAlarmsWithoutAssignments = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.applyUnitPreferences = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAlarmAssignments_Request>): ListAlarmAssignments_Request {
    return ListAlarmAssignments_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAlarmAssignments_Request>): ListAlarmAssignments_Request {
    const message = createBaseListAlarmAssignments_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssignmentSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeChildOrganizations =
      (object.includeChildOrganizations !== undefined && object.includeChildOrganizations !== null)
        ? Filter_ChildOrganizations.fromPartial(object.includeChildOrganizations)
        : undefined;
    message.includeAlarmsWithoutAssignments = object.includeAlarmsWithoutAssignments ?? undefined;
    message.applyUnitPreferences = object.applyUnitPreferences ?? undefined;
    return message;
  },
};

function createBaseListAlarmAssignments_Response(): ListAlarmAssignments_Response {
  return { alarms: [], request: undefined, pagination: undefined };
}

export const ListAlarmAssignments_Response = {
  encode(message: ListAlarmAssignments_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.alarms) {
      ListAlarmAssignments_Response_AlarmAssignment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListAlarmAssignments_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAlarmAssignments_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAlarmAssignments_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.alarms.push(ListAlarmAssignments_Response_AlarmAssignment.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListAlarmAssignments_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAlarmAssignments_Response>): ListAlarmAssignments_Response {
    return ListAlarmAssignments_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAlarmAssignments_Response>): ListAlarmAssignments_Response {
    const message = createBaseListAlarmAssignments_Response();
    message.alarms = object.alarms?.map((e) => ListAlarmAssignments_Response_AlarmAssignment.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListAlarmAssignments_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListAlarmAssignments_Response_AlarmAssignment(): ListAlarmAssignments_Response_AlarmAssignment {
  return { organization: "", alarm: undefined, modems: [], tags: [] };
}

export const ListAlarmAssignments_Response_AlarmAssignment = {
  encode(message: ListAlarmAssignments_Response_AlarmAssignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.alarm !== undefined) {
      ModemAlarm.encode(message.alarm, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.modems) {
      ListAlarmAssignments_Response_AlarmAssignment_ToModem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tags) {
      ListAlarmAssignments_Response_AlarmAssignment_ToTag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAlarmAssignments_Response_AlarmAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAlarmAssignments_Response_AlarmAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alarm = ModemAlarm.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modems.push(ListAlarmAssignments_Response_AlarmAssignment_ToModem.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(ListAlarmAssignments_Response_AlarmAssignment_ToTag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListAlarmAssignments_Response_AlarmAssignment>,
  ): ListAlarmAssignments_Response_AlarmAssignment {
    return ListAlarmAssignments_Response_AlarmAssignment.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListAlarmAssignments_Response_AlarmAssignment>,
  ): ListAlarmAssignments_Response_AlarmAssignment {
    const message = createBaseListAlarmAssignments_Response_AlarmAssignment();
    message.organization = object.organization ?? "";
    message.alarm = (object.alarm !== undefined && object.alarm !== null)
      ? ModemAlarm.fromPartial(object.alarm)
      : undefined;
    message.modems = object.modems?.map((e) => ListAlarmAssignments_Response_AlarmAssignment_ToModem.fromPartial(e)) ||
      [];
    message.tags = object.tags?.map((e) => ListAlarmAssignments_Response_AlarmAssignment_ToTag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListAlarmAssignments_Response_AlarmAssignment_ToModem(): ListAlarmAssignments_Response_AlarmAssignment_ToModem {
  return { modemNumber: "", parameters: undefined };
}

export const ListAlarmAssignments_Response_AlarmAssignment_ToModem = {
  encode(
    message: ListAlarmAssignments_Response_AlarmAssignment_ToModem,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.modemNumber !== "") {
      writer.uint32(10).string(message.modemNumber);
    }
    if (message.parameters !== undefined) {
      Struct.encode(Struct.wrap(message.parameters), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAlarmAssignments_Response_AlarmAssignment_ToModem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAlarmAssignments_Response_AlarmAssignment_ToModem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modemNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parameters = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListAlarmAssignments_Response_AlarmAssignment_ToModem>,
  ): ListAlarmAssignments_Response_AlarmAssignment_ToModem {
    return ListAlarmAssignments_Response_AlarmAssignment_ToModem.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListAlarmAssignments_Response_AlarmAssignment_ToModem>,
  ): ListAlarmAssignments_Response_AlarmAssignment_ToModem {
    const message = createBaseListAlarmAssignments_Response_AlarmAssignment_ToModem();
    message.modemNumber = object.modemNumber ?? "";
    message.parameters = object.parameters ?? undefined;
    return message;
  },
};

function createBaseListAlarmAssignments_Response_AlarmAssignment_ToTag(): ListAlarmAssignments_Response_AlarmAssignment_ToTag {
  return { tag: undefined, parameters: undefined };
}

export const ListAlarmAssignments_Response_AlarmAssignment_ToTag = {
  encode(
    message: ListAlarmAssignments_Response_AlarmAssignment_ToTag,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.tag !== undefined) {
      Tag.encode(message.tag, writer.uint32(10).fork()).ldelim();
    }
    if (message.parameters !== undefined) {
      Struct.encode(Struct.wrap(message.parameters), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAlarmAssignments_Response_AlarmAssignment_ToTag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAlarmAssignments_Response_AlarmAssignment_ToTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = Tag.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parameters = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListAlarmAssignments_Response_AlarmAssignment_ToTag>,
  ): ListAlarmAssignments_Response_AlarmAssignment_ToTag {
    return ListAlarmAssignments_Response_AlarmAssignment_ToTag.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListAlarmAssignments_Response_AlarmAssignment_ToTag>,
  ): ListAlarmAssignments_Response_AlarmAssignment_ToTag {
    const message = createBaseListAlarmAssignments_Response_AlarmAssignment_ToTag();
    message.tag = (object.tag !== undefined && object.tag !== null) ? Tag.fromPartial(object.tag) : undefined;
    message.parameters = object.parameters ?? undefined;
    return message;
  },
};

function createBaseListModemMessageBodyParserAssignments(): ListModemMessageBodyParserAssignments {
  return {};
}

export const ListModemMessageBodyParserAssignments = {
  encode(_: ListModemMessageBodyParserAssignments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemMessageBodyParserAssignments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemMessageBodyParserAssignments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListModemMessageBodyParserAssignments>): ListModemMessageBodyParserAssignments {
    return ListModemMessageBodyParserAssignments.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListModemMessageBodyParserAssignments>): ListModemMessageBodyParserAssignments {
    const message = createBaseListModemMessageBodyParserAssignments();
    return message;
  },
};

function createBaseListModemMessageBodyParserAssignments_Request(): ListModemMessageBodyParserAssignments_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    includeChildOrganizations: undefined,
    includeParserWithoutAssignments: undefined,
  };
}

export const ListModemMessageBodyParserAssignments_Request = {
  encode(message: ListModemMessageBodyParserAssignments_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssignmentSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.includeChildOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.includeChildOrganizations, writer.uint32(34).fork()).ldelim();
    }
    if (message.includeParserWithoutAssignments !== undefined) {
      writer.uint32(40).bool(message.includeParserWithoutAssignments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemMessageBodyParserAssignments_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemMessageBodyParserAssignments_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssignmentSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.includeChildOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeParserWithoutAssignments = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListModemMessageBodyParserAssignments_Request>,
  ): ListModemMessageBodyParserAssignments_Request {
    return ListModemMessageBodyParserAssignments_Request.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListModemMessageBodyParserAssignments_Request>,
  ): ListModemMessageBodyParserAssignments_Request {
    const message = createBaseListModemMessageBodyParserAssignments_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssignmentSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.includeChildOrganizations =
      (object.includeChildOrganizations !== undefined && object.includeChildOrganizations !== null)
        ? Filter_ChildOrganizations.fromPartial(object.includeChildOrganizations)
        : undefined;
    message.includeParserWithoutAssignments = object.includeParserWithoutAssignments ?? undefined;
    return message;
  },
};

function createBaseListModemMessageBodyParserAssignments_Response(): ListModemMessageBodyParserAssignments_Response {
  return { parsers: [], request: undefined, pagination: undefined };
}

export const ListModemMessageBodyParserAssignments_Response = {
  encode(
    message: ListModemMessageBodyParserAssignments_Response,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.parsers) {
      ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.request !== undefined) {
      ListModemMessageBodyParserAssignments_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListModemMessageBodyParserAssignments_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemMessageBodyParserAssignments_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parsers.push(
            ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment.decode(
              reader,
              reader.uint32(),
            ),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListModemMessageBodyParserAssignments_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListModemMessageBodyParserAssignments_Response>,
  ): ListModemMessageBodyParserAssignments_Response {
    return ListModemMessageBodyParserAssignments_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListModemMessageBodyParserAssignments_Response>,
  ): ListModemMessageBodyParserAssignments_Response {
    const message = createBaseListModemMessageBodyParserAssignments_Response();
    message.parsers =
      object.parsers?.map((e) =>
        ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment.fromPartial(e)
      ) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListModemMessageBodyParserAssignments_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment(): ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment {
  return { organization: "", messageBodyParser: undefined, modems: [], tags: [] };
}

export const ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment = {
  encode(
    message: ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.messageBodyParser !== undefined) {
      ModemMessageBodyParser.encode(message.messageBodyParser, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.modems) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.messageBodyParser = ModemMessageBodyParser.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modems.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment>,
  ): ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment {
    return ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment>,
  ): ListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment {
    const message = createBaseListModemMessageBodyParserAssignments_Response_ModemMessageBodyParserAssignment();
    message.organization = object.organization ?? "";
    message.messageBodyParser = (object.messageBodyParser !== undefined && object.messageBodyParser !== null)
      ? ModemMessageBodyParser.fromPartial(object.messageBodyParser)
      : undefined;
    message.modems = object.modems?.map((e) => e) || [];
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

export type AssignmentServiceDefinition = typeof AssignmentServiceDefinition;
export const AssignmentServiceDefinition = {
  name: "AssignmentService",
  fullName: "hiber.assign.AssignmentService",
  methods: {
    /** List assignments. */
    list: {
      name: "List",
      requestType: ListAssignments_Request,
      requestStream: false,
      responseType: ListAssignments_Response,
      responseStream: false,
      options: {},
    },
    /** Assign something, like an alarm to a modem. */
    assign: {
      name: "Assign",
      requestType: Assign_Request,
      requestStream: false,
      responseType: Assign_Response,
      responseStream: false,
      options: {},
    },
    /** Remove an assignment, like an alarm to a modem. */
    unassign: {
      name: "Unassign",
      requestType: Unassign_Request,
      requestStream: false,
      responseType: Unassign_Response,
      responseStream: false,
      options: {},
    },
    /** List assets with the devices and tags they are assigned to. */
    assetAssignments: {
      name: "AssetAssignments",
      requestType: ListAssetAssignments_Request,
      requestStream: false,
      responseType: ListAssetAssignments_Response,
      responseStream: false,
      options: {},
    },
    /** List modems with tags, alarms and parsers assigned to them. */
    modemAssignments: {
      name: "ModemAssignments",
      requestType: ListModemAssignments_Request,
      requestStream: false,
      responseType: ListModemAssignments_Response,
      responseStream: false,
      options: {},
    },
    /** List tags with the modems, alarms and parsers they are assigned to. */
    tagAssignments: {
      name: "TagAssignments",
      requestType: ListTagAssignments_Request,
      requestStream: false,
      responseType: ListTagAssignments_Response,
      responseStream: false,
      options: {},
    },
    /** List alarms with the modems and parsers they are assigned to. */
    alarmAssignments: {
      name: "AlarmAssignments",
      requestType: ListAlarmAssignments_Request,
      requestStream: false,
      responseType: ListAlarmAssignments_Response,
      responseStream: false,
      options: {},
    },
    /** List alarms with the modems and parsers they are assigned to. */
    modemMessageBodyParserAssignments: {
      name: "ModemMessageBodyParserAssignments",
      requestType: ListModemMessageBodyParserAssignments_Request,
      requestStream: false,
      responseType: ListModemMessageBodyParserAssignments_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
