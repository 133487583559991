/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'menu-background': {
    width: 270,
    height: 373,
    viewBox: '0 0 270 373',
    data: '<defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_menu-background_a"><stop stop-color="#40FFF9" offset="0%"/><stop stop-color="#1EFFF0" offset="100%"/></linearGradient></defs><path pid="0" d="M-284.25 3.48c.116.08.245.137.38.169L88.946 93.868c1.443.349 2.045-1.764.634-2.227L-374.781-60.935c-1.275-.42-2.123 1.303-1.014 2.059L-284.25 3.48zM-75 160c-2.584-76.821 57.05 248.22 58.5 248 .966-.147 129.132 1.353 384.5 4.5 1.272-.192 1.161-217.238-.096-217.511L-646-25.61V70.75c.087.037.174.077.271.092L-75 160zm563.765 11.6c1.376.47.81 2.532-.613 2.232l-218.196-46.005a1.097 1.097 0 01-.237-.078L10.777 10.765c-1.343-.606-.542-2.63.851-2.153L488.765 171.6z" transform="translate(0 -8)" _fill="url(#svgicon_menu-background_a)" fill-rule="evenodd"/>'
  }
})
