/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Filter_Events, Filter_Modems, Filter_Tags, Health, healthFromJSON, healthToNumber, Timestamp } from "./base";
import { EmailNotificationPreferences } from "./email_notifications";
import { MQTTPublisher_Data } from "./integration_mqtt";
import { SlackPublisher_Data } from "./integration_slack";
import { Tag } from "./tag";
import { Webhook_WebhookData } from "./webhook";

export const protobufPackage = "hiber.publisher";

/**
 * Generic publisher. A Publisher is a generic parent of the
 * - webhook publisher
 * - MQTT publisher
 * - email publisher
 *
 * As such, it has common data and can have a configuration for one of those types.
 *
 * Used to have it's own API encompassing everything, but this has been split up to its individual parts in
 * - WebhookService
 * - MQTTService
 * - EmailNotificationPreferencesService
 *
 * Now, this types is only used in the relevant events.
 */
export interface Publisher {
  id: number;
  description: string;
  /**
   * This field remains for backwards compatibility, but it should not be used.
   *
   * @deprecated
   */
  deprecatedData: Publisher_Data | undefined;
  filters: Publisher_Filters | undefined;
  tags: Tag[];
  health: Health;
  type: Publisher_Type;
  inCooldownUntil: Timestamp | undefined;
  disabled: boolean;
  /** Id of the user that created this publisher */
  createdBy: string;
  data?:
    | { $case: "http"; http: Webhook_WebhookData }
    | { $case: "mqtt"; mqtt: MQTTPublisher_Data }
    | { $case: "email"; email: EmailNotificationPreferences }
    | { $case: "slack"; slack: SlackPublisher_Data }
    | { $case: "shellSsip"; shellSsip: Publisher_Data_ShellSsipConfig }
    | undefined;
}

/**
 * This type remains for backwards compatibility, but it should not be used.
 *
 * @deprecated
 */
export enum Publisher_ContentType {
  DEFAULT = "DEFAULT",
  JSON = "JSON",
  PROTO = "PROTO",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function publisher_ContentTypeFromJSON(object: any): Publisher_ContentType {
  switch (object) {
    case 0:
    case "DEFAULT":
      return Publisher_ContentType.DEFAULT;
    case 1:
    case "JSON":
      return Publisher_ContentType.JSON;
    case 2:
    case "PROTO":
      return Publisher_ContentType.PROTO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Publisher_ContentType.UNRECOGNIZED;
  }
}

export function publisher_ContentTypeToNumber(object: Publisher_ContentType): number {
  switch (object) {
    case Publisher_ContentType.DEFAULT:
      return 0;
    case Publisher_ContentType.JSON:
      return 1;
    case Publisher_ContentType.PROTO:
      return 2;
    case Publisher_ContentType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum Publisher_Type {
  HTTP = "HTTP",
  MQTT = "MQTT",
  EMAIL = "EMAIL",
  SLACK = "SLACK",
  SHELL_SSIP = "SHELL_SSIP",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function publisher_TypeFromJSON(object: any): Publisher_Type {
  switch (object) {
    case 0:
    case "HTTP":
      return Publisher_Type.HTTP;
    case 1:
    case "MQTT":
      return Publisher_Type.MQTT;
    case 3:
    case "EMAIL":
      return Publisher_Type.EMAIL;
    case 4:
    case "SLACK":
      return Publisher_Type.SLACK;
    case 5:
    case "SHELL_SSIP":
      return Publisher_Type.SHELL_SSIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Publisher_Type.UNRECOGNIZED;
  }
}

export function publisher_TypeToNumber(object: Publisher_Type): number {
  switch (object) {
    case Publisher_Type.HTTP:
      return 0;
    case Publisher_Type.MQTT:
      return 1;
    case Publisher_Type.EMAIL:
      return 3;
    case Publisher_Type.SLACK:
      return 4;
    case Publisher_Type.SHELL_SSIP:
      return 5;
    case Publisher_Type.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * This type remains for backwards compatibility, but it should not be used.
 *
 * @deprecated
 */
export interface Publisher_Data {
  url: string;
  contentType: Publisher_ContentType;
  disabled: boolean;
  certificateId: number;
  certificateName: string;
  caCertificateId: number;
  caCertificateName: string;
  config?:
    | { $case: "http"; http: Publisher_Data_HTTPConfig }
    | { $case: "mqtt"; mqtt: Publisher_Data_MQTTConfig }
    | undefined;
}

/**
 * This field remains for backwards compatibility, but it should not be used.
 *
 * @deprecated
 */
export interface Publisher_Data_MQTTConfig {
  topic: string;
  qos: Publisher_Data_MQTTConfig_QoS;
  /** Optional username to authenticate with. */
  username: string;
  /** Optional password to authenticate with. Requires username to be set. */
  password: string;
  /** Identifier used by the MQTT client. Defaults to "hiber". */
  identifier: string;
}

export enum Publisher_Data_MQTTConfig_QoS {
  DEFAULT = "DEFAULT",
  /** AT_MOST_ONCE - Qos level 0 */
  AT_MOST_ONCE = "AT_MOST_ONCE",
  /** AT_LEAST_ONCE - Qos level 1 */
  AT_LEAST_ONCE = "AT_LEAST_ONCE",
  /** EXACTLY_ONCE - Qos level 2 */
  EXACTLY_ONCE = "EXACTLY_ONCE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function publisher_Data_MQTTConfig_QoSFromJSON(object: any): Publisher_Data_MQTTConfig_QoS {
  switch (object) {
    case 0:
    case "DEFAULT":
      return Publisher_Data_MQTTConfig_QoS.DEFAULT;
    case 1:
    case "AT_MOST_ONCE":
      return Publisher_Data_MQTTConfig_QoS.AT_MOST_ONCE;
    case 2:
    case "AT_LEAST_ONCE":
      return Publisher_Data_MQTTConfig_QoS.AT_LEAST_ONCE;
    case 3:
    case "EXACTLY_ONCE":
      return Publisher_Data_MQTTConfig_QoS.EXACTLY_ONCE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Publisher_Data_MQTTConfig_QoS.UNRECOGNIZED;
  }
}

export function publisher_Data_MQTTConfig_QoSToNumber(object: Publisher_Data_MQTTConfig_QoS): number {
  switch (object) {
    case Publisher_Data_MQTTConfig_QoS.DEFAULT:
      return 0;
    case Publisher_Data_MQTTConfig_QoS.AT_MOST_ONCE:
      return 1;
    case Publisher_Data_MQTTConfig_QoS.AT_LEAST_ONCE:
      return 2;
    case Publisher_Data_MQTTConfig_QoS.EXACTLY_ONCE:
      return 3;
    case Publisher_Data_MQTTConfig_QoS.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * This field remains for backwards compatibility, but it should not be used.
 *
 * @deprecated
 */
export interface Publisher_Data_HTTPConfig {
  /**
   * Used to generate the HMAC-SHA256 header on every publisher call, which you can use to verify the message.
   * The HMAC-SHA256 header is calculated with the message body and this secret.
   * There are many examples of how to do this in different languages, for example:
   * https://github.com/danharper/hmac-examples
   */
  secret: string;
}

export interface Publisher_Data_ShellSsipConfig {
  url: string;
  tenantId: string;
  resourceId: string;
}

export interface Publisher_Filters {
  eventTypes: Filter_Events | undefined;
  modemNumbers: Filter_Modems | undefined;
  tags: Filter_Tags | undefined;
}

/** @deprecated */
export interface UpdatePublisherRequest {
}

/** @deprecated */
export interface UpdatePublisherRequest_UpdateModems {
  updated: boolean;
  value: Filter_Modems | undefined;
}

function createBasePublisher(): Publisher {
  return {
    id: 0,
    description: "",
    deprecatedData: undefined,
    filters: undefined,
    tags: [],
    health: Health.OK,
    type: Publisher_Type.HTTP,
    inCooldownUntil: undefined,
    disabled: false,
    createdBy: "",
    data: undefined,
  };
}

export const Publisher = {
  encode(message: Publisher, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.deprecatedData !== undefined) {
      Publisher_Data.encode(message.deprecatedData, writer.uint32(26).fork()).ldelim();
    }
    if (message.filters !== undefined) {
      Publisher_Filters.encode(message.filters, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.health !== Health.OK) {
      writer.uint32(48).int32(healthToNumber(message.health));
    }
    if (message.type !== Publisher_Type.HTTP) {
      writer.uint32(56).int32(publisher_TypeToNumber(message.type));
    }
    if (message.inCooldownUntil !== undefined) {
      Timestamp.encode(message.inCooldownUntil, writer.uint32(66).fork()).ldelim();
    }
    if (message.disabled === true) {
      writer.uint32(72).bool(message.disabled);
    }
    if (message.createdBy !== "") {
      writer.uint32(122).string(message.createdBy);
    }
    switch (message.data?.$case) {
      case "http":
        Webhook_WebhookData.encode(message.data.http, writer.uint32(82).fork()).ldelim();
        break;
      case "mqtt":
        MQTTPublisher_Data.encode(message.data.mqtt, writer.uint32(90).fork()).ldelim();
        break;
      case "email":
        EmailNotificationPreferences.encode(message.data.email, writer.uint32(106).fork()).ldelim();
        break;
      case "slack":
        SlackPublisher_Data.encode(message.data.slack, writer.uint32(114).fork()).ldelim();
        break;
      case "shellSsip":
        Publisher_Data_ShellSsipConfig.encode(message.data.shellSsip, writer.uint32(130).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Publisher {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublisher();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedData = Publisher_Data.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filters = Publisher_Filters.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.health = healthFromJSON(reader.int32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = publisher_TypeFromJSON(reader.int32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.inCooldownUntil = Timestamp.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.data = { $case: "http", http: Webhook_WebhookData.decode(reader, reader.uint32()) };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.data = { $case: "mqtt", mqtt: MQTTPublisher_Data.decode(reader, reader.uint32()) };
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.data = { $case: "email", email: EmailNotificationPreferences.decode(reader, reader.uint32()) };
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.data = { $case: "slack", slack: SlackPublisher_Data.decode(reader, reader.uint32()) };
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.data = {
            $case: "shellSsip",
            shellSsip: Publisher_Data_ShellSsipConfig.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Publisher>): Publisher {
    return Publisher.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Publisher>): Publisher {
    const message = createBasePublisher();
    message.id = object.id ?? 0;
    message.description = object.description ?? "";
    message.deprecatedData = (object.deprecatedData !== undefined && object.deprecatedData !== null)
      ? Publisher_Data.fromPartial(object.deprecatedData)
      : undefined;
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? Publisher_Filters.fromPartial(object.filters)
      : undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.health = object.health ?? Health.OK;
    message.type = object.type ?? Publisher_Type.HTTP;
    message.inCooldownUntil = (object.inCooldownUntil !== undefined && object.inCooldownUntil !== null)
      ? Timestamp.fromPartial(object.inCooldownUntil)
      : undefined;
    message.disabled = object.disabled ?? false;
    message.createdBy = object.createdBy ?? "";
    if (object.data?.$case === "http" && object.data?.http !== undefined && object.data?.http !== null) {
      message.data = { $case: "http", http: Webhook_WebhookData.fromPartial(object.data.http) };
    }
    if (object.data?.$case === "mqtt" && object.data?.mqtt !== undefined && object.data?.mqtt !== null) {
      message.data = { $case: "mqtt", mqtt: MQTTPublisher_Data.fromPartial(object.data.mqtt) };
    }
    if (object.data?.$case === "email" && object.data?.email !== undefined && object.data?.email !== null) {
      message.data = { $case: "email", email: EmailNotificationPreferences.fromPartial(object.data.email) };
    }
    if (object.data?.$case === "slack" && object.data?.slack !== undefined && object.data?.slack !== null) {
      message.data = { $case: "slack", slack: SlackPublisher_Data.fromPartial(object.data.slack) };
    }
    if (object.data?.$case === "shellSsip" && object.data?.shellSsip !== undefined && object.data?.shellSsip !== null) {
      message.data = {
        $case: "shellSsip",
        shellSsip: Publisher_Data_ShellSsipConfig.fromPartial(object.data.shellSsip),
      };
    }
    return message;
  },
};

function createBasePublisher_Data(): Publisher_Data {
  return {
    url: "",
    contentType: Publisher_ContentType.DEFAULT,
    disabled: false,
    certificateId: 0,
    certificateName: "",
    caCertificateId: 0,
    caCertificateName: "",
    config: undefined,
  };
}

export const Publisher_Data = {
  encode(message: Publisher_Data, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.contentType !== Publisher_ContentType.DEFAULT) {
      writer.uint32(16).int32(publisher_ContentTypeToNumber(message.contentType));
    }
    if (message.disabled === true) {
      writer.uint32(24).bool(message.disabled);
    }
    if (message.certificateId !== 0) {
      writer.uint32(32).int64(message.certificateId);
    }
    if (message.certificateName !== "") {
      writer.uint32(42).string(message.certificateName);
    }
    if (message.caCertificateId !== 0) {
      writer.uint32(48).int64(message.caCertificateId);
    }
    if (message.caCertificateName !== "") {
      writer.uint32(58).string(message.caCertificateName);
    }
    switch (message.config?.$case) {
      case "http":
        Publisher_Data_HTTPConfig.encode(message.config.http, writer.uint32(66).fork()).ldelim();
        break;
      case "mqtt":
        Publisher_Data_MQTTConfig.encode(message.config.mqtt, writer.uint32(74).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Publisher_Data {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublisher_Data();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.contentType = publisher_ContentTypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.certificateId = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.certificateName = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.caCertificateId = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.caCertificateName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.config = { $case: "http", http: Publisher_Data_HTTPConfig.decode(reader, reader.uint32()) };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.config = { $case: "mqtt", mqtt: Publisher_Data_MQTTConfig.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Publisher_Data>): Publisher_Data {
    return Publisher_Data.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Publisher_Data>): Publisher_Data {
    const message = createBasePublisher_Data();
    message.url = object.url ?? "";
    message.contentType = object.contentType ?? Publisher_ContentType.DEFAULT;
    message.disabled = object.disabled ?? false;
    message.certificateId = object.certificateId ?? 0;
    message.certificateName = object.certificateName ?? "";
    message.caCertificateId = object.caCertificateId ?? 0;
    message.caCertificateName = object.caCertificateName ?? "";
    if (object.config?.$case === "http" && object.config?.http !== undefined && object.config?.http !== null) {
      message.config = { $case: "http", http: Publisher_Data_HTTPConfig.fromPartial(object.config.http) };
    }
    if (object.config?.$case === "mqtt" && object.config?.mqtt !== undefined && object.config?.mqtt !== null) {
      message.config = { $case: "mqtt", mqtt: Publisher_Data_MQTTConfig.fromPartial(object.config.mqtt) };
    }
    return message;
  },
};

function createBasePublisher_Data_MQTTConfig(): Publisher_Data_MQTTConfig {
  return { topic: "", qos: Publisher_Data_MQTTConfig_QoS.DEFAULT, username: "", password: "", identifier: "" };
}

export const Publisher_Data_MQTTConfig = {
  encode(message: Publisher_Data_MQTTConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.topic !== "") {
      writer.uint32(10).string(message.topic);
    }
    if (message.qos !== Publisher_Data_MQTTConfig_QoS.DEFAULT) {
      writer.uint32(16).int32(publisher_Data_MQTTConfig_QoSToNumber(message.qos));
    }
    if (message.username !== "") {
      writer.uint32(26).string(message.username);
    }
    if (message.password !== "") {
      writer.uint32(34).string(message.password);
    }
    if (message.identifier !== "") {
      writer.uint32(42).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Publisher_Data_MQTTConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublisher_Data_MQTTConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.topic = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.qos = publisher_Data_MQTTConfig_QoSFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.username = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Publisher_Data_MQTTConfig>): Publisher_Data_MQTTConfig {
    return Publisher_Data_MQTTConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Publisher_Data_MQTTConfig>): Publisher_Data_MQTTConfig {
    const message = createBasePublisher_Data_MQTTConfig();
    message.topic = object.topic ?? "";
    message.qos = object.qos ?? Publisher_Data_MQTTConfig_QoS.DEFAULT;
    message.username = object.username ?? "";
    message.password = object.password ?? "";
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBasePublisher_Data_HTTPConfig(): Publisher_Data_HTTPConfig {
  return { secret: "" };
}

export const Publisher_Data_HTTPConfig = {
  encode(message: Publisher_Data_HTTPConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secret !== "") {
      writer.uint32(10).string(message.secret);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Publisher_Data_HTTPConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublisher_Data_HTTPConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secret = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Publisher_Data_HTTPConfig>): Publisher_Data_HTTPConfig {
    return Publisher_Data_HTTPConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Publisher_Data_HTTPConfig>): Publisher_Data_HTTPConfig {
    const message = createBasePublisher_Data_HTTPConfig();
    message.secret = object.secret ?? "";
    return message;
  },
};

function createBasePublisher_Data_ShellSsipConfig(): Publisher_Data_ShellSsipConfig {
  return { url: "", tenantId: "", resourceId: "" };
}

export const Publisher_Data_ShellSsipConfig = {
  encode(message: Publisher_Data_ShellSsipConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.resourceId !== "") {
      writer.uint32(26).string(message.resourceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Publisher_Data_ShellSsipConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublisher_Data_ShellSsipConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.resourceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Publisher_Data_ShellSsipConfig>): Publisher_Data_ShellSsipConfig {
    return Publisher_Data_ShellSsipConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Publisher_Data_ShellSsipConfig>): Publisher_Data_ShellSsipConfig {
    const message = createBasePublisher_Data_ShellSsipConfig();
    message.url = object.url ?? "";
    message.tenantId = object.tenantId ?? "";
    message.resourceId = object.resourceId ?? "";
    return message;
  },
};

function createBasePublisher_Filters(): Publisher_Filters {
  return { eventTypes: undefined, modemNumbers: undefined, tags: undefined };
}

export const Publisher_Filters = {
  encode(message: Publisher_Filters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.eventTypes !== undefined) {
      Filter_Events.encode(message.eventTypes, writer.uint32(10).fork()).ldelim();
    }
    if (message.modemNumbers !== undefined) {
      Filter_Modems.encode(message.modemNumbers, writer.uint32(18).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      Filter_Tags.encode(message.tags, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Publisher_Filters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublisher_Filters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eventTypes = Filter_Events.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemNumbers = Filter_Modems.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags = Filter_Tags.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Publisher_Filters>): Publisher_Filters {
    return Publisher_Filters.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Publisher_Filters>): Publisher_Filters {
    const message = createBasePublisher_Filters();
    message.eventTypes = (object.eventTypes !== undefined && object.eventTypes !== null)
      ? Filter_Events.fromPartial(object.eventTypes)
      : undefined;
    message.modemNumbers = (object.modemNumbers !== undefined && object.modemNumbers !== null)
      ? Filter_Modems.fromPartial(object.modemNumbers)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? Filter_Tags.fromPartial(object.tags)
      : undefined;
    return message;
  },
};

function createBaseUpdatePublisherRequest(): UpdatePublisherRequest {
  return {};
}

export const UpdatePublisherRequest = {
  encode(_: UpdatePublisherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePublisherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePublisherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdatePublisherRequest>): UpdatePublisherRequest {
    return UpdatePublisherRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdatePublisherRequest>): UpdatePublisherRequest {
    const message = createBaseUpdatePublisherRequest();
    return message;
  },
};

function createBaseUpdatePublisherRequest_UpdateModems(): UpdatePublisherRequest_UpdateModems {
  return { updated: false, value: undefined };
}

export const UpdatePublisherRequest_UpdateModems = {
  encode(message: UpdatePublisherRequest_UpdateModems, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updated === true) {
      writer.uint32(8).bool(message.updated);
    }
    if (message.value !== undefined) {
      Filter_Modems.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePublisherRequest_UpdateModems {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePublisherRequest_UpdateModems();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.updated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Filter_Modems.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdatePublisherRequest_UpdateModems>): UpdatePublisherRequest_UpdateModems {
    return UpdatePublisherRequest_UpdateModems.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdatePublisherRequest_UpdateModems>): UpdatePublisherRequest_UpdateModems {
    const message = createBaseUpdatePublisherRequest_UpdateModems();
    message.updated = object.updated ?? false;
    message.value = (object.value !== undefined && object.value !== null)
      ? Filter_Modems.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
