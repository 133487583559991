/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logo-text-animate': {
    width: 425,
    height: 125,
    viewBox: '0 0 425 125',
    data: '<path pid="0" d="M413.4 101.5c6.1 0 11.1 5 11.1 11.1s-5 11.1-11.1 11.1-11.1-5-11.1-11.1 4.8-11 10.9-11.1h.2zm0 19.8c4.9 0 8.9-3.9 8.9-8.8s-3.9-8.9-8.8-8.9-8.9 3.9-8.9 8.8v.1c0 4.9 4 8.9 8.8 8.8zm-3.4-14.6h3.7c1.9-.2 3.7 1.3 3.8 3.2v.4c.1 1.6-1 3.1-2.6 3.4l3 4.5h-2.3l-2.8-4.3h-.8v4.3h-2v-11.5zm3.5 5.3c1.4 0 2.2-.6 2.2-1.8 0-1.2-.9-1.8-2.2-1.8H412v3.6h1.5zM405.6 29.9l-8.4 21.6c-3.6-1.5-7.5-2.3-11.4-2.2-14.5 0-21.1 12.8-21.1 12.8V124h-24V29.3h22.8v9.8s8.2-12.2 25.8-12.2c5.5.1 11 1.1 16.3 3zM333.1 76.2c0 1.8 0 5.4-.2 9h-70.7c1.8 8.4 11.6 19.2 26.6 19.2 16.6 0 26-9.6 26-9.6l12 18c-10.7 8.7-24.1 13.5-37.9 13.6-28.8 0-50.9-22-50.9-49.7 0-27.7 20.1-49.8 47.5-49.8 25.9 0 47.6 20.1 47.6 49.3zm-24-8.4c-.4-8.2-8-19.2-23.8-19.2-15.3 0-22.3 10.8-23.1 19.2h46.9zM135.8-1.5h24v39.4s8.8-11 26.8-11c24.6 0 46.1 20.6 46.1 49.7s-21.6 49.7-46.1 49.7c-20.4 0-28.6-12.3-28.6-12.3v9.9h-22.2V-1.5v0zm24 92.3s6.8 13.2 22.6 13.2c16.2 0 26-11.8 26-27.4 0-15.6-9.8-27.4-26-27.4-15.8 0-22.6 15.1-22.6 15.1v26.5zM122.8 29.3h-24V124h24V29.3zM95.9-1.5c.1 3.8 1.6 7.4 4.3 10.1 5.7 5.7 15 5.7 20.7 0 2.7-2.7 4.2-6.3 4.3-10.1-9.8.4-19.5.4-29.3 0zM85.5 62.6v61.3h-24V68.6c0-13.6-8.4-19.4-18.8-19.4C30.4 49.2 24 61.6 24 61.6v62.3H0V-1.7h24v37.1s8.4-8.6 26.8-8.6 34.7 13 34.7 35.8z" _stroke="#1FF5F0" stroke-width="5"/>'
  }
})
