/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hilo': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M13.636.129a.723.723 0 01.958.12 8.257 8.257 0 011.538 8.048.726.726 0 01-1.295.177 25.065 25.065 0 01-1.483-2.6l-1.664.7a2.688 2.688 0 01-.603 1.5l3.293 7.29h3.02a.6.6 0 01.6.6v1.2a.6.6 0 01-.6.6H.6a.6.6 0 01-.6-.6v-1.2a.6.6 0 01.6-.6h.6v-.6a.9.9 0 01.9-.9h.3V10.2a.93.93 0 01-.114-1.686l4.015-2.182a2.694 2.694 0 014.136-2.25l1.724-.937c.03-.725.172-1.44.42-2.123a.712.712 0 01.267-.348zM17.4 15.963H.6v1.2h16.8v-1.2zM7.373 8.513l-3.095 6.85h1.315l1.948-4.312.004-.01.917-2.032a2.687 2.687 0 01-1.089-.496zm3.967 5.95H6.658l-.406.9h5.494l-.406-.9zm-.713-5.95a2.687 2.687 0 01-1.09.496l1.595 3.535.002.005 1.27 2.814h1.318zM3.3 14.463H2.1a.3.3 0 00-.3.3v.6h1.8v-.6a.3.3 0 00-.3-.3zm3.33-5.76l-3.538 1.489A.935.935 0 013 10.22v3.643h.3a.897.897 0 01.785.47l2.545-5.63zm4.033 4.26H7.335l-.405.9h4.14l-.407-.9zm4.637-3.29c.167 0 .326.075.432.204.212.28 1.068 1.55 1.068 2.207a1.5 1.5 0 01-3 0c0-.656.856-1.927 1.068-2.207a.559.559 0 01.432-.204zm0 .629c-.379.518-.9 1.45-.9 1.782a.89.89 0 00.9.88.89.89 0 00.9-.88c0-.332-.521-1.264-.9-1.782zm-5.314 1.161H8.01l-.406.9h2.786l-.405-.9zM9 9.276l-.717 1.587h1.433L9 9.276zm-2.627-2.3l-3.8 2.066a.332.332 0 00.287.596l3.973-1.673a2.682 2.682 0 01-.46-.99zM9 4.262A2.098 2.098 0 007.528 7.86l.02.017A2.1 2.1 0 109 4.263zm5.142-3.62a.124.124 0 00-.164-.02l-.788.546a.117.117 0 00-.045.057 6.37 6.37 0 00-.387 2.03l1.004 2.092c.464.967.99 1.9 1.575 2.796a.123.123 0 00.164.038.13.13 0 00.06-.07 7.658 7.658 0 00-1.42-7.469zM9.018 4.856l.143.008a1.5 1.5 0 011.339 1.5 1.483 1.483 0 01-.2.735c-.264.47-.76.762-1.3.764a1.48 1.48 0 01-.46-.08 1.5 1.5 0 01.62-2.919zM9 5.463a.9.9 0 100 1.8.9.9 0 000-1.8zm3.325-1.725l-1.384.753c.382.395.634.899.722 1.442l1.427-.6-.765-1.595z" _fill="#1EF5F0" fill-rule="nonzero"/>'
  }
})
