import { getGrpcClient } from '@/services/app';
import {
  Assign_Request,
  AssignmentSelection,
  AssignmentServiceDefinition,
  ListModemAssignments_Request,
  Unassign_Request,
} from 'hiber-grpc/src/customer_compiled/assignment';

import { ModemSelection } from 'hiber-grpc/src/customer_compiled/modem';
import { ModemAlarm as IModemAlarm } from 'hiber-grpc/src/customer_compiled/modem_alarm';

interface AssignmentInclude {
  alarmDetails?: boolean;
  messageBodyParserDetails?: boolean;
  messageBodyParserContent?: boolean;
  modemsWithoutAssignments?: boolean;
}

function getModemsAssignmentsRequest(modems: string[], organization = '', {
  alarmDetails,
  messageBodyParserDetails,
  messageBodyParserContent,
  modemsWithoutAssignments,
}: AssignmentInclude = {
  alarmDetails: false,
  messageBodyParserDetails: false,
  messageBodyParserContent: false,
  modemsWithoutAssignments: false,
}) {
  return ListModemAssignments_Request.fromPartial({
    organization,
    includeAlarmDetails: alarmDetails || false,
    includeMessageBodyParserDetails: messageBodyParserDetails || false,
    includeModemsWithoutAssignments: modemsWithoutAssignments || false,
    includeMessageBodyParserContent: messageBodyParserContent || false,
    applyUnitPreferences: true,
    selection: AssignmentSelection.fromPartial({
      modems: ModemSelection.fromPartial({
        modems: { include: modems },
      }),
    }),
  });
}

export function fetchModemsAssignments(modems: string[], include: AssignmentInclude, organization = '') {
  const request = getModemsAssignmentsRequest(
    modems,
    organization,
    include,
  );
  const client = getGrpcClient<typeof AssignmentServiceDefinition>(AssignmentServiceDefinition);
  return client.modemAssignments(request);
}

function assignRequest(modems: string[], entities: string[], statement: string) {
  const propPrefixes = statement.split(' ');
  const action = propPrefixes[0];
  const object = propPrefixes[1];
  const preposition = propPrefixes[2];
  const actionProp = `${action}${object[0].toUpperCase()}${object.slice(1)}`;
  const client = getGrpcClient<typeof AssignmentServiceDefinition>(AssignmentServiceDefinition);
  const Request = {
    assign: Assign_Request,
    unassign: Unassign_Request,
  };

  const request = Request[action].fromPartial({
    [action]: {
      $case: actionProp,
      [actionProp]: { identifiers: entities },
    },
    [preposition]: {
      $case: `${preposition}Modems`,
      [`${preposition}Modems`]: { modems: { include: modems } },
    },
  });

  return client[action](request);
}

export function unassignAlarmsRequest(modems: string[], alarms: string[]) {
  return assignRequest(modems, alarms, 'unassign alarms from');
}

export function assignAlarmsRequest(modems: string[], alarms: string[]) {
  return assignRequest(modems, alarms, 'assign alarms to');
}

export function unassignParsersRequest(modems: string[], parsers: string[]) {
  return assignRequest(modems, parsers, 'unassign parsers from');
}

export function assignParsersRequest(modems: string[], parsers: string[]) {
  return assignRequest(modems, parsers, 'assign parsers to');
}

export function extractFieldChecksThresholds(alarmList: IModemAlarm[]) {
  const checks: any[] = [];

  alarmList.forEach((alarm) => {
    alarm.checks.forEach(({ check }) => {
      if (!check) return;
      if (check.$case === 'field') {
        let values = [];

        switch (check.field.check?.$case) {
          case 'threshold':
          case 'maximum':
          case 'minimum':
          case 'equals':
            values = Object.values(check.field.check[check.field.check?.$case]);
            break;
          default:
        }

        if (check.field.path) {
          checks.push({
            field: check.field.path,
            values,
          });
        }
      }
    });
  });
  return checks;
}
