import { getGrpcClient } from '@/services/app';
import {
  ListWebhooksRequest,
  WebhookHistoryRequest,
  WebhookServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/webhook';

export function fetchWebhooks(size: number, page: number) {
  const listRequest = ListWebhooksRequest.fromPartial({ pagination: { page, size } });
  const client = getGrpcClient<typeof WebhookServiceDefinition>(WebhookServiceDefinition);
  return client.list(listRequest);
}

export function fetchWebhookHistory(webhookId: number, size: number, page: number) {
  const historyRequest = WebhookHistoryRequest.fromPartial({ webhookId, pagination: { page, size } });
  const client = getGrpcClient<typeof WebhookServiceDefinition>(WebhookServiceDefinition);
  return client.history(historyRequest);
}
