import { getGrpcClient } from '@/services/app';
import {
  CreateExport_Request,
  Export_Configuration,
  Export_Format,
  Export_Format_Csv,
  Export_Format_Json,
  Export_Format_Type,
  export_Format_TypeFromJSON,
  ExportServiceDefinition,
} from 'hiber-grpc/src/customer_compiled/export';

export function createExport(
  includeModems: string[],
  timeRange: { start: string, end: string },
  type: Export_Format_Type,
  organization: string = '',
  expires: string = '1 week',
) {
  const configuration: any = type === Export_Format_Type.CSV
    ? {
      $case: type.toLowerCase(),
      configuration: Export_Format_Csv.fromPartial({}),
    }
    : {
      $case: type.toLowerCase(),
      configuration: Export_Format_Json.fromPartial({}),
    };

  const request = CreateExport_Request.fromPartial({
    organization,
    name: `HiberData_${timeRange.start}_to_${timeRange.end}`,
    format: Export_Format.fromPartial({
      type: export_Format_TypeFromJSON(type),
    }),
    emailSubscribe: true,
    configuration: Export_Configuration.fromPartial({
      expiresIn: { textual: expires },
      type: {
        $case: 'modemMessage',
        modemMessage: {
          modems: includeModems,
          timeRange: { start: { textual: timeRange.start }, end: { textual: timeRange.end } },
        },
      },
    }),
  });

  if (request.format) {
    request.format.configuration = configuration;
  }

  const client = getGrpcClient<typeof ExportServiceDefinition>(ExportServiceDefinition);
  return client.create(request);
}
