import CoreModule from '@/store/core/modules/user';

import { defaultErrorHandler } from '@/store/core/utils';
import {
  getAccessibleOrganizations,
} from '@/services/user';

const User = {
  namespaced: true,
  state: {
    ...CoreModule.state,
    accessibleOrganizations: {
      organizations: [],
      pagination: {
        size: 100,
        page: 0,
      },
    },
    defaultUserSettings: {
      layout: 'portrait',
      mapStyle: 'classic',
      percentage: 50,
      defaultscreen: 'map',
      timeformat: 'twentyfour',
      dateformat: 'daymonth',
    },
    missionControlSettings: {
      settings: {},
    },
    undo: {
      ids: [],
    },
  },
  actions: {
    ...CoreModule.actions,
    getAccessibleOrganizations(context, target) {
      return new Promise((resolve, reject) => {
        getAccessibleOrganizations(
          target ? target.pagination.size : 100,
          target ? target.pagination.page : 0,
          target.search,
        ).then((res) => {
          context.commit('getAccessibleOrganizations', res);
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    ...CoreModule.mutations,
    getAccessibleOrganizations(state, res) {
      state.accessibleOrganizations = res;
    },
    temporarilyRemoveItems(state, payload) {
      state.undo.ids.push(payload.id);
    },
    clearTemporarilyRemovedItems(state, payload) {
      const index = state.undo.ids.indexOf(payload.id);
      if (index > -1) {
        state.undo.ids.splice(index, 1);
      }
    },
    resetTemporarilyRemovedItems(state) {
      state.undo = { ids: [] };
    },
  },
  getters: {
    ...CoreModule.getters,
  },
};

export default User;
