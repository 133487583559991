import { defaultErrorHandler } from '@/store/core/utils';
import { fetchParsers } from '@/services/modem_parsers';

const ModemParsers = {
  namespaced: true,

  state: {
    pagination: null,
    list: null,
  },

  actions: {
    getParsers(context, pagination) {
      return new Promise((resolve, reject) => {
        fetchParsers(pagination).then((response) => {
          context.commit('setParsers', response);
          resolve(response);
        }).catch((error) => defaultErrorHandler(error, reject, context));
      });
    },
  },

  mutations: {
    setParsers(state, { parsers, pagination }) {
      state.list = parsers;
      state.pagination = pagination;
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
  },
};
export default ModemParsers;
