import { defaultErrorHandler } from '@/store/core/utils';
import { zendeskToken } from '@/services/sso';

const SSO = {
  namespaced: true,
  actions: {
    zendeskToken(context) {
      return new Promise((resolve, reject) => {
        zendeskToken().then((res) => {
          context.commit('zendeskToken', res);
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
  },
  mutations: {
    zendeskToken() {
      // Empty on purpose -- lists the action in Vue Devtools.
    },
  },
};

export default SSO;
