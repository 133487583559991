import Vue from 'vue';
import moment from 'moment-timezone';

import { convertTimeToIso, defaultErrorHandler } from '@/store/core/utils';

import { fetchEventsHistory, getModemEvents, getModemHealthEvents, resolveEvent } from '@/services/events';
import { deprecatedEvents } from '@/constants';
import { eventTypeFromJSON } from 'hiber-grpc/src/customer_compiled/base';

const Events = {
  namespaced: true,
  state: {
    bundled: {
      events: [],
      request: {
        pagination: {
          size: 50,
          page: 0,
          total: 50,
          totalPages: 1,
        },
      },
    },
    unbundled: {
      unbundledEvents: {},
    },
    modems: {},
    unreadEvents: {},
    latestReadTimeStamp: null,
    hasNewAlerts: false,
    intervalId: null,
  },
  actions: {
    listenForNewAlerts(context, needsImmediateFetch) {
      const payload = {
        pagination: {
          size: 1,
          page: 0,
        },
      };

      function checkModemEvents() {
        context.dispatch('fetchModemEvents', payload).then((res) => {
          if (res && res[0]) {
            if (res[0].time.timestamp !== context.state.latestReadTimeStamp) {
              context.commit('setHasNewAlerts', true);
              if (context.state.intervalId) {
                clearInterval(context.state.intervalId);
              }
            }
          } else {
            context.commit('setHasNewAlerts', false);
          }
        });
      }

      // only checks when !hasNewAlerts
      if (!context.state.hasNewAlerts) {
        if (needsImmediateFetch) {
          checkModemEvents();
        }
        // only setInterval when first check is false
        context.state.intervalId = setInterval(() => { // eslint-disable-line no-param-reassign
          checkModemEvents();
        }, 60000);
      }
    },
    async fetchModemEvents(context, target) {
      const { size, page } = target.pagination;
      const pagination = {
        size: size || 100,
        page: page || 0,
      };

      const modemNrs = target.modemNrs || [];
      const health = await this.dispatch('Health/getHealthLevelsList', { root: true });

      return new Promise((resolve, reject) => {
        fetchEventsHistory(
          pagination.size,
          pagination.page,
          modemNrs,
          health,
        ).then((res) => {
          const alerts = res?.events;
          resolve(alerts);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    // TODO replace deprecated values
    getEvents(context, payload) {
      return new Promise((resolve, reject) => {
        const options = payload || {};

        let startDate;
        // If we have already gotten some events, query only for new ones
        let addToOldState = false;
        if (context.state.bundled.events.length > 0 && typeof options.forceUpdate === 'undefined') {
          addToOldState = true;

          // Get last event and query from there
          const latestBundle = context.state.bundled.events.reduce((latest, current) => {
            const currentSeconds = current.deprecatedLastEvent?.timestamp;
            const latestSeconds = latest.deprecatedLastEvent?.timestamp;
            const currentIsLatest = currentSeconds >= latestSeconds;
            return currentIsLatest ? current : latest;
          });

          // Set only the start range so we query from the last event on
          // Add one second to exclude the lastEvent itself
          // eslint-disable-next-line no-unsafe-optional-chaining
          if (latestBundle.deprecatedLastEvent?.timestamp) {
            startDate = convertTimeToIso(moment(latestBundle.deprecatedLastEvent?.timestamp).add(1, 'second'));
          }
        }

        // Get the events
        getModemEvents({
          pagination: context.state.bundled.request.pagination,
          timeRange: { start: startDate },
          unbundledEvents: false,
        }).then((res) => {
          // Check unread messages based on localStorage until backend supports it
          const count = {};
          res.events.forEach((bundled) => {
            if (bundled?.deprecatedLastEvent?.timestamp) {
              count[bundled.type] = {
                amount: bundled.deprecatedAmount,
                timestamp: bundled.deprecatedLastEvent?.timestamp,
              };
            }
          });

          // Get saved data or an empty object if no data is found
          let stored = localStorage.getItem(context.getters.storageName);
          stored = stored ? JSON.parse(stored) : {};

          // Calculate unread events
          const unreadEvents = {};
          Object.keys(count).forEach((type) => {
            const old = {
              amount: stored[type]?.amount || 0,
              timestamp: stored[type]?.timestamp || 0,
            };
            const current = count[type];
            if (current.amount > old.amount || current.timestamp > old.timestamp) {
              unreadEvents[type] = {
                amount: current.amount - old.amount,
                timestamp: current.timestamp - old.timestamp,
              };
            }
          });

          // If call is to refresh events, add the new state to the old one
          if (addToOldState) {
            const old = context.state.unreadEvents;
            Object.keys(old).forEach((type) => {
              // If unreadEvents already has this key, add old amount to it, otherwise just put old amount
              unreadEvents[type] = {
                // eslint-disable-next-line no-unsafe-optional-chaining
                amount: unreadEvents[type]?.amount ? (unreadEvents[type].amount + old[type]?.amount || 0)
                  : (old[type]?.amount || 0),
                // eslint-disable-next-line no-unsafe-optional-chaining
                timestamp: unreadEvents[type]?.timestamp ? (unreadEvents[type].timestamp + old[type]?.timestamp || 0)
                  : (old[type]?.timestamp || 0),
              };
            });
          }

          context.commit('getEvents', { res, options });

          // Commit if necessary
          if (Object.keys(unreadEvents).length > 0) {
            context.commit('setUnreadEvents', unreadEvents);
          }

          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    markEventsAsRead(context, payload) {
      const storageName = context.getters.storageName;

      return new Promise((resolve) => {
        // Old data
        let stored = localStorage.getItem(storageName);
        stored = stored ? JSON.parse(stored) : {};

        // Update view counts
        if (payload) {
          Object.keys(payload).forEach((type) => {
            stored[type] = {
              amount: payload[type].amount,
              timestamp: payload[type].timestamp,
            };
          });
        }

        // Save updated data to localStorage
        localStorage.setItem(storageName, JSON.stringify(stored));

        // Directly update store
        context.commit('markEventsAsRead', Object.keys(payload));
        resolve();
      });
    },
    getUnbundledEvents(context, target) {
      let types;
      if (target.data) {
        types = target.data.types;
      }

      let pagination = { size: 50, page: 0 };
      if (target.pagination) {
        pagination = target.pagination;
      }

      return new Promise((resolve, reject) => {
        getModemEvents({ pagination, events: { include: types } })
          .then((res) => {
            context.commit('getUnbundledEvents', res);
            resolve(res);
          }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    getModemEvents(context, target) {
      let pagination = { size: 50, page: 0 };
      if (target.pagination) {
        pagination = target.pagination;
      }

      const modemNrs = target.modemNrs || [];
      const tagIds = target.tagIds || [];
      const healths = target.health || '';
      const organization = target.organization || '';
      // Set only the start range, so we query from the last event on
      const daysBeforeNow = target.daysBeforeNow || 30;
      // Start of the 'last modem events'
      const start = convertTimeToIso(moment().subtract(daysBeforeNow, 'days').toDate());

      return new Promise((resolve, reject) => {
        getModemHealthEvents({
          pagination,
          organization,
          modems: { include: modemNrs },
          timeRange: { start },
          tags: { include: tagIds },
          healthLevels: healths,
          events: { exclude: deprecatedEvents.map(event => eventTypeFromJSON(event)) },
        }).then((res) => {
          context.commit('getModemEvents', { modemNrs, res });
          resolve(res);
        }).catch((res) => defaultErrorHandler(res, reject, context));
      });
    },
    resolveEvent(context, payload) {
      const { resolveIdentifier } = payload;
      return new Promise((resolve, reject) => {
        resolveEvent(resolveIdentifier)
          .then(response => resolve(response))
          .catch(response => defaultErrorHandler(response, reject, context));
      });
    },
  },
  mutations: {
    getEvents(state, payload) {
      let newState = payload.res;

      // If we don't have to forceUpdate, append the new events list to the current list
      if (typeof payload.options.forceUpdate === 'undefined') {
        const oldEvents = [...state.bundled.events];
        payload.res.events.forEach((eventType) => {
          const oldEventType = oldEvents.find((i) => i.type === eventType.type);
          // If the type already exists, add the new data
          if (oldEventType) {
            oldEventType.deprecatedAmount += eventType.deprecatedAmount;
            oldEventType.deprecatedLastEvent = eventType.deprecatedLastEvent;
          } else {
            // Otherwise, add the new data as a new entry
            oldEvents.push(eventType);
          }
        });

        newState = { ...payload.res, events: oldEvents };
      }

      state.bundled = newState;
    },
    getUnbundledEvents(state, res) {
      const oldEvents = { ...state.unbundled };
      res.unbundledEvents.forEach((eventType) => {
        // If event type already exists, add the new data instead of replacing
        if (oldEvents[eventType.type]) {
          oldEvents[eventType.type].unbundledEvents.unshift(...eventType.unbundledEvents);
          // Check pagination limit and remove events that fall out of this limit
          oldEvents[eventType.type].unbundledEvents = oldEvents[eventType.type].unbundledEvents
            .slice(0, eventType.pagination.size);
        } else {
          // Set new data
          oldEvents[eventType.type] = eventType;
        }
      });
      state.unbundled = { ...res, unbundledEvents: oldEvents };
    },
    getModemEvents(state, payload) {
      // Store the events by modem nr rather than event type
      const byModemNr = payload.res.events.reduce((acc, curr) => {
        const currentModemEvents = acc[curr.modem.number] || [];
        return {
          ...acc,
          [curr.modem.number]: [...currentModemEvents, { ...curr, modemName: curr.modem.name }],
        };
      }, {});

      Object.keys(byModemNr).forEach((modemNr) => {
        byModemNr[modemNr].sort((a, b) => b.time.timestamp - a.time.timestamp);
      });

      Vue.set(state, 'modems', byModemNr);
    },
    setUnreadEvents(state, unread) {
      state.unreadEvents = unread;
    },
    markEventsAsRead(state, eventTypes) {
      const updated = { ...state.unreadEvents };

      // Delete 'unread' for each of the eventTypes now marked as 'read'
      eventTypes.forEach((type) => {
        if (typeof updated[type] !== 'undefined') {
          delete updated[type];
        }
      });

      state.unreadEvents = updated;
    },
    setAlertAsLatestRead(state, event) {
      state.latestReadTimeStamp = event.time.timestamp;
    },
    setHasNewAlerts(state, event) {
      state.hasNewAlerts = event;
    },
  },
  getters: {
    storageName(state, getters, rootState) {
      return `h_eventcount_${rootState.Organization.organization.organization}`;
    },
  },
};

export default Events;
