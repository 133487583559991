import Vue from 'vue';
import Vuex from 'vuex';

// Global
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

// Modules
import App from './modules/app';
import Assignment from './modules/assignment';
import Auth from './modules/auth';
import Certificates from './modules/certificates';
import Developers from './modules/developers';
import NamedLocations from './modules/named_locations';
import EmailNotifications from './modules/email_notifications';
import Events from './modules/events';
import Field from './modules/field';
import Health from './modules/health';
import Integrations from './modules/integrations';
import Journey from './modules/journey';
import Map from './modules/map';
import Modem from './modules/modem';
import Organization from './modules/organization';
import SSO from './modules/sso';
import Tables from './modules/tables';
import User from './modules/user';
import Export from './modules/export';
import Alerts from './modules/alerts';
import ModemAlarms from './modules/modem_alarms';
import ModemParsers from './modules/modem_parsers';
import Message from './modules/message';
import Hilo from './modules/hilo';
import UnitPreferences from './modules/unit_preferences';
import Tags from './modules/tags';
import Device from './modules/device';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    App,
    Assignment,
    Auth,
    Certificates,
    Developers,
    NamedLocations,
    EmailNotifications,
    Events,
    Field,
    Health,
    Integrations,
    Journey,
    Map,
    Modem,
    Message,
    Organization,
    SSO,
    Tables,
    User,
    Export,
    Alerts,
    ModemAlarms,
    ModemParsers,
    Hilo,
    UnitPreferences,
    Tags,
    Device,
  },
  // Note: Strict mode seems bugged?
  /*
  so basically you pre-set the state of the store to default values
  and then when you send a mutation like `setUser` that basically does `state.user = newUser`
  it repeatedly gives this error that you must only change the store in state mutation handlers
  even though it is in a mutation handler
  but then when I *don't* define `state.user` before-hand, and let it be created the first mutation, then it's fine
  */
  // strict: process.env.NODE_ENV !== 'production',
});
